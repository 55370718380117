import classNames from "classnames";

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    hasError?: boolean;
    hasFocus?: boolean;
    hasValue?: boolean;
    isChecked?: boolean;
    isCentred?: boolean;
    isDisabled?: boolean;
    errorMessage?: string;
    label?: string;
    name?: string;
    rows?: number;
}

export const TextInput = ({
    hasError,
    isDisabled,
    hasFocus,
    id = "defaultInput",
    label = "",
    errorMessage = `Enter a valid ${label.toLowerCase()}`,
    type = "text",
    value = "",
    onChange,
}: TextInputProps) => {
    const inputClass = classNames("cp-input", { "cp-input--error": hasError });
    const labelClass = classNames("cp-label", { "cp-label--error": hasError });

    return (
        <div className="cp-form-element">
            <input
                type={type}
                id={id}
                autoComplete="off"
                maxLength={100}
                placeholder=" "
                disabled={isDisabled}
                defaultValue={hasError ? "user@example" : value}
                autoFocus={hasFocus}
                className={inputClass}
                onChange={onChange}
            />
            <label
                htmlFor={id}
                className={labelClass}
            >
                {hasError ? errorMessage : label}
            </label>
        </div>
    );
};
