import ChatMessageTextView from "@/components/messages/ChatMessageTextView";
import MentionView from "@/components/messages/MentionView";
import { isMentionTargetId } from "@/domain/mentions";
import { JSONNode } from "@/domain/richtext/tiptap/node";
import { isString, Optional } from "@/misc/types";
import { createElement, FC, memo } from "react";

// region Types & utilities

type NodeType = string;
type NodeAttrs = Optional<Record<string, any>>;
type NodeMarks = Optional<Record<string, any>[]>;

type NodeAdapterProps = { attrs: NodeAttrs; };
type NodeAdapter = FC<NodeAdapterProps>;

type ElementType = string | FC<NodeAdapterProps>;
type ElementTypeGetter = (attrs: NodeAttrs) => ElementType;
type DeferredElementType =
    | ElementType
    | { get: ElementTypeGetter; };

const deferElementType = (getter: ElementTypeGetter): DeferredElementType => ({ get: getter });
const elementTypeEvaluator = (attrs: NodeAttrs) => (d: DeferredElementType) =>
    (typeof d == "object" && "get" in d) ? d.get(attrs) : d;

const isVoidElement = (elementType: ElementType): boolean =>
    isString(elementType) &&
    [
        "area",
        "base",
        "br",
        "col",
        "embed",
        "hr",
        "img",
        "input",
        "link",
        "meta",
        "param",
        "source",
        "track",
        "wbr",
    ].includes(elementType);

// region Deferred e-types
// Deferred element types are evaluated when the attrs for the node are known

const getHeadingTag = deferElementType(
    (attrs: NodeAttrs): string => `h${attrs?.level ?? 1}`,
);

// region Node adapters
// Node adapters translate node attributes to props for an underlying component

const MentionViewAdapter: NodeAdapter = ({ attrs }) =>
    (attrs === undefined || !isString(attrs.text) || !isMentionTargetId(attrs.target)) ?
        "@???" :
        <MentionView text={attrs.text} mention={{ target: attrs.target }} />;

// region E-type config
// Node and mark types can be configured with the desired rendered element types here

const nodeElements: Record<string, DeferredElementType[]> = {
    paragraph: ["p"],
    blockquote: ["blockquote"],
    bulletList: ["ul"],
    codeBlock: ["code", "pre"],
    heading: [getHeadingTag],
    horizontalRule: ["hr"],
    mention: [MentionViewAdapter],
    listItem: ["li"],
    orderedList: ["ol"],
};

const inlineNodeElements: Record<string, DeferredElementType[]> = {
    blockquote: ["q"],
    codeBlock: ["code"],
    heading: ["strong"],
    mention: [MentionViewAdapter],
};

const markElements: Record<string, ElementType[]> = {
    bold: ["b"],
    italic: ["i"],
    strike: ["s"],
    code: ["code"],
};

const getNodeElements = (
    type: NodeType,
    attrs: NodeAttrs,
    marks: NodeMarks,
    inline: boolean,
): ElementType[] => [
    ...marks?.flatMap(mark => markElements[mark.type] ?? []) ?? [],
    ...(inline ? inlineNodeElements : nodeElements)[type]
        ?.map(elementTypeEvaluator(attrs))
        ?? [],
];

const getAttrs = (elementType: ElementType, node: any) =>
    isString(elementType) ? null : { attrs: node.attrs };

// region Components

interface NodeViewProps {
    node: any;
    inline: boolean;
}

const NodeView: FC<NodeViewProps> = ({ node, inline }) =>
    getNodeElements(node.type, node.attrs, node.marks, inline).reduce(
        (children, elementType) =>
            createElement(
                elementType,
                getAttrs(elementType, node),
                isVoidElement(elementType) ? undefined : children,
            ),
        ("text" in node) ?
            <ChatMessageTextView text={node.text} /> :
            node.content?.map?.((child: any, i: number) => (
                <NodeView key={i} node={child} inline={inline} />
            )) ?? <></>,
    );

interface TiptapDocViewProps {
    root: JSONNode;
    inline: boolean;
}

const TiptapDocViewInternal: FC<TiptapDocViewProps> = ({ root, inline }) => (
    <NodeView node={root.json} inline={inline} />
);

export const TiptapDocView = memo(TiptapDocViewInternal);

export default TiptapDocView;
