import {
    RichTextEditorChangeHandler,
    RichTextEditorOps,
} from "@/components/richtext/RichTextEditor";
import { DraftTarget } from "@/domain/draftTarget";
import { changeBridge, docBridge } from "@/domain/richtext/bridge";
import { Format, Provider } from "@/domain/richtext/types";
import {
    selectDraft,
    selectDraftDoc,
    selectDraftLastApiChange,
    updateDraftDoc,
} from "@/features/channels";
import usePrevious from "@/hooks/usePrevious";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { useAppDispatch } from "@/store/redux";
import { RefObject, useCallback, useLayoutEffect } from "react";

export const useDocSync = (
    draftTarget: DraftTarget,
    editorRef: RefObject<RichTextEditorOps<Provider>>,
    onEditorUserChange?: () => void,
): RichTextEditorChangeHandler<Provider> => {
    const dispatch = useAppDispatch();

    const draft = useSelectorArgs(selectDraft, draftTarget);
    const doc = useSelectorArgs(selectDraftDoc, draftTarget);
    const prevDoc = usePrevious(doc);
    const lastApiChange = useSelectorArgs(selectDraftLastApiChange, draftTarget);

    // Handle direct changes to the store that need to be returned to the editor
    useLayoutEffect(() => {
        // If we don't have an editor ref, we can't push any changes anyway
        if (editorRef.current === null) return;

        // If the draft doc has not changed, there is nothing to do
        const currentDocBridge = docBridge.from(doc);
        if (prevDoc !== undefined && currentDocBridge.getEquals(prevDoc)) return;

        // If there is a draft, but the last change was not an api change, there is nothing to do. The
        // case where draft is undefined handles the deletion of the draft (e.g. sending a message), while
        // the case where lastApiChange is undefined handles a programmatic change to the draft (e.g.
        // inserting a mention); in both of these cases we need the change to be reflected in the editor
        if (draft !== undefined && lastApiChange === undefined) return;

        if (lastApiChange === undefined) {
            editorRef.current.setContent(currentDocBridge);
        }
        else {
            editorRef.current.updateContent(changeBridge.from(lastApiChange));
        }
    }, [draft, doc, prevDoc, lastApiChange, editorRef]);

    // Set the text and check for any style changes
    const onEditorChange: RichTextEditorChangeHandler<Provider> = useCallback(
        (change, isApiChange) => {
            if (isApiChange) return;

            dispatch(
                updateDraftDoc({
                    draftTarget,
                    update: changeBridge.from(change).get(Format.JSON),
                    isApiChange,
                }),
            );

            onEditorUserChange?.();
        },
        [dispatch, draftTarget, onEditorUserChange],
    );

    return onEditorChange;
};

export default useDocSync;
