import log from "@/misc/log";
import { type Optional, TypedEntries } from "@/misc/types";
import type { Connection, IdbStore, IPersist, RWTransaction, Transaction } from "@/persist/types";

export const indexedDbAvailable = () => "indexedDB" in self;

export const fetchIdbStore = async <K extends string, V>(
    conn: Connection,
    store: IdbStore<K, V>,
    withTx?: RWTransaction | Transaction,
): Promise<Record<K, V>> => {
    try {
        const tx = withTx ?? conn.transaction(store.name, "readonly");
        const m = {} as Record<K, V>;

        const objectStore = tx.objectStore(store.name);
        let cursor = await objectStore.openCursor();
        while (cursor) {
            m[cursor.key as K] = cursor.value;
            cursor = await cursor.continue();
        }

        return m;
    }
    catch (error) {
        log.warn(`Error fetching from ${store.name}`, error);
        return {} as Record<K, V>;
    }
};

export const fetchKeyedIdbStore = async <K extends string, V>(
    conn: Connection,
    store: IdbStore<K, V>,
    withTx?: RWTransaction | Transaction,
): Promise<V[]> => {
    try {
        const tx = withTx ?? conn.transaction(store.name, "readonly");
        const objectStore = tx.objectStore(store.name);
        return objectStore.getAll();
    }
    catch (error) {
        log.warn(`Error fetching from ${store.name}`, error);
        return [];
    }
};

export const simpleIdbTranslate = async <K1 extends string, K2 extends string, V1, V2>(
    tx: RWTransaction,
    fromStore: IdbStore<K1, V1>,
    toStore: IdbStore<K2, V2>,
    translateFn: ([k, v]: [K1, Optional<V1>]) => Optional<[K2, V2]>,
) => {
    const oldValues = await fetchIdbStore(tx.db, fromStore, tx);
    const newValues = TypedEntries(oldValues).map(translateFn).filter(o => !!o);
    const objectStore = tx.objectStore(toStore.name);
    const promises = newValues.map(([k, v]) => objectStore.put(v, k));
    await Promise.all(promises);
};

/** Type-check a persistor object.
 *
 * This means that direct imports can still get the full type information
 * for the stores, but we still get the guarantees of the IPersist interface.
 */
export const checkPersistor = <T>(_: IPersist<T>) => {};

export { idbStore, localStorageStore, sessionStorageStore } from "@/persist/store";

export { clearKey } from "@/persist/updates";
