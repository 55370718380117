import {
    Change,
    ChangeBridge,
    Doc,
    DocBridgeWithoutConvert,
    Format,
    Position,
    PositionRange,
    Provider,
} from "../types";
import {
    AnyChangeDelta,
    AnyMarkupDelta,
    asChangeDelta,
    breakMentionsChange,
    ChangeDelta,
    changeDeltaBuilder,
    clearChange,
    compactChangeDelta,
    compactMarkupDelta,
    compareDeltas,
    getMentions,
    markupDeltaBuilder,
    markupDeltaIsEmpty,
    mentionDiff,
    parseChangeDelta,
    parseMarkupDelta,
    renderDeltaToText,
    replaceChange,
    trimWhitespaceChange,
} from "./delta";

export const quillEmptyDocBridge = () => quillDocBridge(markupDeltaBuilder().build());

export const quillDocBridgeFromText = (text: string) =>
    quillDocBridge(markupDeltaBuilder().insert(text).build());

export const quillDocBridge = (rawDoc: AnyMarkupDelta): DocBridgeWithoutConvert<Provider.Quill> => {
    const bridge: DocBridgeWithoutConvert<Provider.Quill> = {
        getIsEmpty: () => markupDeltaIsEmpty(rawDoc),
        getEquals: other => compareDeltas(rawDoc, other.rawDoc),
        getMentions: () => getMentions(rawDoc),
        getMentionCount: () => bridge.getMentions().length,
        getMentionDiff: other => mentionDiff(rawDoc, other.rawDoc),
        getText: expandMentions => renderDeltaToText(rawDoc, expandMentions),

        get: <F extends Format>(format: F): Doc<Provider.Quill, F> => {
            if (format == Format.JSON) {
                const result: Doc<Provider.Quill, Format.JSON> = {
                    rawDoc: compactMarkupDelta(rawDoc),
                    p: Provider.Quill,
                    f: format,
                };
                return result as Doc<Provider.Quill, F>;
            }
            if (format == Format.Internal) {
                const result: Doc<Provider.Quill, Format.Internal> = {
                    rawDoc: parseMarkupDelta(rawDoc),
                    p: Provider.Quill,
                    f: format,
                };
                return result as Doc<Provider.Quill, F>;
            }

            throw new Error(`Unsupported format ${format} for get`);
        },

        p: Provider.Quill,
    };
    return bridge;
};

export const quillContinueChangeBridge = (
    fromChange: AnyChangeDelta,
): ChangeBridge<Provider.Quill> => {
    const parsedChange = parseChangeDelta(fromChange);

    let rawDoc = parsedChange.result;
    let rawChange = parsedChange.change;
    const composeWith = (nextChange: ChangeDelta) => {
        rawDoc = rawDoc.compose(nextChange.change);
        rawChange = rawChange.compose(nextChange.change);
        return bridge;
    };

    const bridge: ChangeBridge<Provider.Quill> = {
        spliceText: (text, start, end) =>
            composeWith(
                changeDeltaBuilder(rawDoc)
                    .retain(start.rawPosition)
                    .delete(end.rawPosition - start.rawPosition)
                    .insert(text)
                    .build(),
            ),

        spliceMention: (mention, text, start, end) =>
            composeWith(
                changeDeltaBuilder(rawDoc)
                    .retain(start.rawPosition)
                    .delete(end.rawPosition - start.rawPosition)
                    .insert({ mention: { ...mention, text } })
                    .insert(" ")
                    .build(),
            ),

        breakMentions: target => composeWith(breakMentionsChange(parseMarkupDelta(rawDoc), target)),

        trimWhitespace: () => composeWith(trimWhitespaceChange(parseMarkupDelta(rawDoc))),

        replace: other => composeWith(replaceChange(rawDoc, other.rawDoc)),

        clear: () => composeWith(clearChange(rawDoc)),

        get: <F extends Format>(
            format: F,
        ): { doc: Doc<Provider.Quill, F>; change: Change<Provider.Quill, F>; } => {
            const doc = quillDocBridge(rawDoc).get(format);
            const internalChange = asChangeDelta(rawDoc, rawChange);
            const change = {
                rawChange: (format == Format.Internal) ?
                    internalChange :
                    compactChangeDelta(internalChange),
                p: Provider.Quill,
                f: format,
            } as Change<Provider.Quill, F>;

            return { doc, change };
        },

        p: Provider.Quill,
    };
    return bridge;
};

export const quillChangeOnDocBridge = (rawDoc: AnyMarkupDelta): ChangeBridge<Provider.Quill> =>
    quillContinueChangeBridge(changeDeltaBuilder(rawDoc).build());

export const quillInitialPosition = (): Position<Provider.Quill> => ({
    rawPosition: 0,
    p: Provider.Quill,
});

export const quillInitialPositionRange = (): PositionRange<Provider.Quill> => ({
    start: quillInitialPosition(),
    end: quillInitialPosition(),
});
