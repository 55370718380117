import { AudienceMember } from "@/domain/audience";
import { Mention } from "@/domain/mentions";
import { deepFreeze } from "@/misc/deepFreeze";
import { ExpandType, isString, Optional } from "@/misc/types";
import isEqual from "lodash.isequal";
import omit from "lodash.omit";
import Delta, { Op } from "quill-delta";
import type { EmitterSource } from "quill/core";
import { MentionDiff } from "../types";

// Unfortunately we can't do the following:
//
// import Emitter from "quill/core/emitter";
// const EmitterSources = Emitter.sources;
//
// because Quill's setup code calls `document.addEventListener`, and
// `document` is undefined in SharedWorker contexts. (One could debate
// the merits of stubbing it, but let's just avoid it for now.)
enum EmitterSources {
    API = "api",
    SILENT = "silent",
    USER = "user",
}
export { EmitterSources, Op, type EmitterSource };

// region Types

/**
 * Object with string keys containing attributes associated with an Op
 */
export type OpAttributes = Record<string, unknown>;

/**
 * An operation which inserts either text or an embed, optionally providing associated attributes
 */
export interface InsertOp {
    insert: string | Record<string, unknown>;
    attributes?: OpAttributes;
}

/**
 * An operation which inserts text, optionally providing associated attributes
 */
export type TextInsertOp = Omit<InsertOp, "insert"> & { insert: string; };

/**
 * An operation which retains some number of characters, optionally updating the attributes
 */
export interface RetainOp {
    retain?: number | Record<string, unknown>;
    attributes?: OpAttributes;
}

/**
 * An operation which deletes some number of characters
 */
export interface DeleteOp {
    delete: number;
}
/**
 * Helper type which contains a single line of markup and any attributes associated with that line
 */
export interface MarkupLine {
    ops: InsertOp[];
    lineAttributes?: OpAttributes;
}

/**
 * A markup delta, which describes a document, and MUST only contain insert operations
 */
export type MarkupDelta = Delta;

/**
 * An array of insert operations which can be converted to a `MarkupDelta` instance
 *
 * This type is used for markup that is stored in the Redux store and sent over the wire
 */
export type CompactMarkupDelta = InsertOp[];

/**
 * A change delta, along with the markup on which it should be applied
 */
export type ChangeDelta = {
    result: MarkupDelta;
    change: Delta;
};

/**
 * An array of operations which can be converted to a `ChangeDelta` instance
 *
 * This type is used for changes that are stored in the Redux store and sent over the wire
 */
export type CompactChangeDelta = {
    result: CompactMarkupDelta;
    change: (InsertOp | RetainOp | DeleteOp)[];
};

type AnyCompactDelta = CompactMarkupDelta | CompactChangeDelta;
export type AnyMarkupDelta = MarkupDelta | CompactMarkupDelta;
export type AnyChangeDelta = ChangeDelta | CompactChangeDelta;
type AnyDelta = AnyMarkupDelta | AnyChangeDelta;

type DeltaInstance = InstanceType<typeof Delta>;
type DeltaInsertParameters = Parameters<DeltaInstance["insert"]>;
type DeltaRetainParameters = Parameters<DeltaInstance["retain"]>;
type DeltaDeleteParameters = Parameters<DeltaInstance["delete"]>;

// region Custom embeds

/**
 * A value which uniquely specifies a mention to be embedded in markup
 */
export type MentionOpValue = ExpandType<Mention & { text: string; }>;

/**
 * Narrowing of `InsertOp` which specifically inserts a mention
 */
export type MentionOp = ExpandType<
    & Omit<InsertOp, "insert">
    & { insert: { mention: MentionOpValue; }; }
>;

// region Type guards

const isOp = (data: unknown): data is Op =>
    typeof data == "object" && data !== null &&
    ("insert" in data || "retain" in data || "delete" in data);

const isOpArray = (data: unknown): data is Op[] => Array.isArray(data) && data.every(d => isOp(d));

export const isAnyDelta = (data: unknown): data is AnyDelta =>
    data instanceof Delta || isOpArray(data) ||
    typeof data == "object" &&
        data !== null &&
        "result" in data &&
        (data.result instanceof Delta || isOpArray(data.result)) &&
        "change" in data &&
        (data.change instanceof Delta || isOpArray(data.change));

export const isAnyCompactDelta = (delta: AnyDelta): delta is AnyCompactDelta =>
    isOpArray(delta) ||
    typeof delta == "object" &&
        "result" in delta &&
        isOpArray(delta.result) &&
        "change" in delta &&
        isOpArray(delta.change);

export const isInsertOp = (op: Op): op is InsertOp =>
    typeof op.insert == "object" || isString(op.insert);

export const isMentionOp = (op: Op): op is MentionOp =>
    typeof op.insert == "object" && "mention" in op.insert;

export const isTextInsertOp = (op: Op): op is TextInsertOp => isString(op.insert);

// region Converters

export const asChangeDelta = (
    result: Delta,
    change: Delta,
): ChangeDelta => ({
    result,
    change,
});

/**
 * Convert a `MarkupDelta` or `CompactMarkupDelta` instance to a `CompactMarkupDelta`, or build a new
 * empty delta if the input is undefined
 *
 * The ops will not be deep copied, so the original delta should not be modified after the call
 */
export const compactMarkupDelta = (
    delta: Optional<AnyMarkupDelta>,
): CompactMarkupDelta => {
    if (delta === undefined) {
        return markupDeltaBuilder().buildCompact();
    }
    else if (isAnyCompactDelta(delta)) {
        return delta;
    }
    else {
        // This cast is safe so long as the markup delta was built using
        // markupDeltaBuilder, which prevents non-insert ops being added
        return delta.ops as CompactMarkupDelta;
    }
};

/**
 * Convert a `ChangeDelta` or `CompactChangeDelta` instance to a `CompactChangeDelta`, or build a new
 * empty delta if the input is undefined
 *
 * The ops will not be deep copied, so the original delta should not be modified after the call
 */
export const compactChangeDelta = (
    delta: Optional<AnyChangeDelta>,
): CompactChangeDelta => {
    if (delta === undefined) {
        return changeDeltaBuilder(emptyMarkupDelta).buildCompact();
    }
    else if (isAnyCompactDelta(delta)) {
        return delta;
    }
    else {
        return {
            result: compactMarkupDelta(delta.result),
            change: delta.change.ops as (InsertOp | RetainOp | DeleteOp)[],
        };
    }
};

/**
 * Convert a `MarkupDelta` or `CompactMarkupDelta` instance to a `MarkupDelta`, or return an empty instance
 * if the input is undefined
 *
 * The ops will not be deep copied, so the original delta should not be modified after the call
 */
export const parseMarkupDelta = (
    delta: Optional<AnyMarkupDelta>,
): MarkupDelta => {
    if (delta === undefined) {
        return emptyMarkupDelta;
    }
    else if (isAnyCompactDelta(delta)) {
        return markupDeltaBuilder()
            .pushAll(delta)
            .build();
    }
    else {
        return markupDeltaBuilder()
            .pushAll(delta.ops as InsertOp[])
            .build();
    }
};

/**
 * Convert a `ChangeDelta` or `CompactChangeDelta` instance to a `ChangeDelta`, or return an empty instance
 * if the input is undefined
 *
 * The ops will not be deep copied, so the original delta should not be modified after the call
 */
export const parseChangeDelta = (
    delta: Optional<AnyChangeDelta>,
): ChangeDelta => {
    if (delta === undefined) {
        return emptyChangeDelta;
    }
    else if (isAnyCompactDelta(delta)) {
        return {
            result: parseMarkupDelta(delta.result),
            change: new Delta(delta.change),
        };
    }
    else {
        return {
            result: parseMarkupDelta(delta.result),
            change: delta.change,
        };
    }
};

// region Builders

/**
 * Interface for creating a delta instance using the builder pattern
 */
interface MarkupDeltaBuilder {
    build: () => MarkupDelta;
    buildCompact: () => CompactMarkupDelta;
    insert: (...args: DeltaInsertParameters) => MarkupDeltaBuilder;
    pushAll: (ops: InsertOp[]) => MarkupDeltaBuilder;
}

/**
 * Interface for creating a delta instance using the builder pattern
 */
interface ChangeDeltaBuilder {
    build: () => ChangeDelta;
    buildCompact: () => CompactChangeDelta;
    insert: (...args: DeltaInsertParameters) => ChangeDeltaBuilder;
    retain: (...args: DeltaRetainParameters) => ChangeDeltaBuilder;
    delete: (...args: DeltaDeleteParameters) => ChangeDeltaBuilder;
    pushAll: (ops: (InsertOp | RetainOp | DeleteOp)[]) => ChangeDeltaBuilder;
}

/**
 * Return a `MarkupDeltaBuilder` instance which can be used to build a delta instance
 */
export const markupDeltaBuilder = (): MarkupDeltaBuilder => {
    const delta = new Delta();
    const builder = {
        build: (): MarkupDelta => {
            // Append a terminal newline if it doesn't already exist
            const lastOp = delta.ops.at(-1);
            if (!isString(lastOp?.insert) || !lastOp.insert.endsWith("\n")) {
                delta.insert("\n");
            }
            return delta;
        },
        buildCompact: (): CompactMarkupDelta => compactMarkupDelta(builder.build()),
        insert: (...args: DeltaInsertParameters) => {
            delta.insert(...args);
            return builder;
        },
        pushAll: (ops: InsertOp[]) => {
            ops.forEach(op => delta.push(op));
            return builder;
        },
    };
    return builder;
};

/**
 * Return a `ChangeDeltaBuilder` instance which can be used to build a delta instance
 */
export const changeDeltaBuilder = (before: AnyMarkupDelta): ChangeDeltaBuilder => {
    const delta = new Delta();
    const builder = {
        build: (): ChangeDelta => {
            const choppedDelta = delta.chop();
            return {
                result: new Delta(
                    parseMarkupDelta(before)
                        .compose(choppedDelta)
                        .filter(isInsertOp),
                ),
                change: choppedDelta,
            };
        },
        buildCompact: (): CompactChangeDelta => compactChangeDelta(builder.build()),
        insert: (...args: DeltaInsertParameters) => {
            delta.insert(...args);
            return builder;
        },
        retain: (...args: DeltaRetainParameters) => {
            delta.retain(...args);
            return builder;
        },
        delete: (...args: DeltaDeleteParameters) => {
            delta.delete(...args);
            return builder;
        },
        pushAll: (ops: (InsertOp | RetainOp | DeleteOp)[]) => {
            ops.forEach(op => delta.push(op));
            return builder;
        },
    };
    return builder;
};

const emptyMarkupDelta = deepFreeze(markupDeltaBuilder().build());
const emptyChangeDelta = deepFreeze(changeDeltaBuilder(emptyMarkupDelta).build());

// region Operations

/**
 * Return true if all the operations of two deltas match
 */
export const compareDeltas = (a: Optional<AnyDelta>, b: Optional<AnyDelta>): boolean =>
    a === b ||
    a !== undefined &&
        b !== undefined &&
        isEqual(getOps(a), getOps(b));

/**
 * Return true if the given markup delta is empty; i.e. contains only the terminal newline
 */
export const markupDeltaIsEmpty = (markup: AnyMarkupDelta): boolean =>
    compareDeltas(markup, emptyMarkupDelta);

/**
 * Convert any delta instance to text
 *
 * @param expandMentions Whether to include the text comprising mentions
 */
export const renderDeltaToText = (
    delta: AnyMarkupDelta,
    expandMentions: boolean,
): string => {
    const opToString = (op: Op | InsertOp) => {
        if (op.insert === undefined) {
            throw new TypeError(`only "insert" operations can be transformed`);
        }

        if (isString(op.insert)) {
            return op.insert;
        }

        if (expandMentions && isMentionOp(op)) {
            return op.insert.mention.text;
        }

        return " ";
    };

    if (delta === undefined) {
        return "";
    }
    else if (isAnyCompactDelta(delta)) {
        return delta
            .map(opToString)
            .join("")
            .replace(/\n$/, "");
    }
    else {
        return delta.ops
            .map(opToString)
            .join("")
            .replace(/\n$/, "");
    }
};

/**
 * Return the length of an insert operation in characters
 *
 * @param expandMentions if true, use the text content of the mention, otherwise return 1
 */
export const insertOpLength = (op: InsertOp, expandMentions: boolean) =>
    isString(op.insert) ?
        op.insert.length :
        ((expandMentions && isMentionOp(op)) ?
            op.insert.mention.text.length :
            1);

/**
 * Annotate a markup delta with position indices representing the start position of that insert
 *
 * @param expandMentions if true, use the text content of the mention to determine the length
 */
export const annotateMarkupDeltaOps = (
    delta: Optional<MarkupDelta>,
    expandMentions: boolean = true,
): [InsertOp, number][] => {
    if (delta === undefined) return [];

    let acc = 0;
    return delta.ops.map(op => {
        const index = acc;
        acc += insertOpLength(op as InsertOp, expandMentions);
        return [op as InsertOp, index];
    });
};

/**
 * Split a `CompactMarkupDelta` into a list of lines. Each line includes a list of
 * inserts that build the line, excluding the newline character, and an optional
 * collection of attributes associated with the whole line
 */
export const splitLines = (ops: CompactMarkupDelta): MarkupLine[] => {
    const lines: MarkupLine[] = [];
    let currentLine: MarkupLine = { ops: [] };
    ops.forEach(op => {
        if (isString(op.insert)) {
            // Split text ops by the delimiter, appending the first field to the last
            // partial line, and creating new lines for any additional fields
            let appending = true;
            const fields = op.insert.split("\n");
            fields.forEach((field, i) => {
                // If this is the first split field in the op, we haven't seen any newline chars yet,
                // so we should append to the current line. Otherwise, add the current line to the
                // list and create a new blank line
                if (appending) {
                    appending = false;
                }
                else {
                    lines.push(currentLine);
                    currentLine = { ops: [] };
                }

                // The attributes in this op apply to any lines that were completed by the
                // op. The final split field in the op is part of a line that will be
                // completed in a later op, so we should skip that one
                if (i != fields.length - 1) {
                    currentLine.lineAttributes = op.attributes;
                }

                // If there is text content in the split field, push a new op to the current line
                if (field != "") {
                    currentLine.ops.push({
                        insert: field,
                        attributes: op.attributes,
                    });
                }
            });
        }
        else {
            // Add non-text ops to the current line and continue
            currentLine.ops.push(op);
        }
    });
    return lines;
};

/**
 * Produce a delta which will replace the contents of one delta with a given other
 */
export const replaceChange = (oldMarkup: AnyMarkupDelta, newMarkup: AnyMarkupDelta): ChangeDelta =>
    changeDeltaBuilder(oldMarkup)
        .delete(parseMarkupDelta(oldMarkup).length())
        .pushAll(parseMarkupDelta(newMarkup).ops)
        .build();

/**
 * Produce a delta which will clear the contents of another
 */
export const clearChange = (oldMarkup: AnyMarkupDelta): ChangeDelta =>
    replaceChange(oldMarkup, emptyMarkupDelta);

/**
 * Produce a delta which breaks all mentions that reference an `AudienceMember`
 */
export const breakMentionsChange = (markup: MarkupDelta, member: AudienceMember): ChangeDelta =>
    changeDeltaBuilder(markup)
        .pushAll(
            annotateMarkupDeltaOps(markup, false)
                .windows(2)
                .flatMap(([[op, start], [_, end]]): Op[] =>
                    (isMentionOp(op) && op.insert.mention.target == member) ?
                        [
                            { delete: end - start },
                            { insert: op.insert.mention.text.slice(1) },
                        ] :
                        [
                            { retain: end - start },
                        ]
                ),
        )
        .build();

/**
 * Produce a delta which trims the whitespace from the start and end of a given markup delta
 */
export const trimWhitespaceChange = (markup: MarkupDelta): ChangeDelta => {
    const firstIfInsert = markup.ops.at(0)?.insert;
    const lastIfInsert = markup.ops.at(-1)?.insert;

    const start = (typeof firstIfInsert === "string") ?
        (firstIfInsert.length - firstIfInsert.trimStart().length) :
        0;
    const endTrimLength = (typeof lastIfInsert === "string") ?
        (lastIfInsert.length - lastIfInsert.trimEnd().length) :
        0;
    const end = markup.length() - endTrimLength;

    return changeDeltaBuilder(markup)
        .delete(start)
        .retain(end - start)
        .delete(markup.length() - 1 - end) // -1 to account for the terminal newline which must remain
        .build();
};

const getOps = (delta: Optional<AnyDelta>): Op[] =>
    (delta === undefined) ? [] :
        Array.isArray(delta) ? delta :
        delta instanceof Delta ? delta.ops :
        delta.change instanceof Delta ? delta.change.ops :
        delta.change;

/**
 * Return a list of mentions extracted from `MentionOp`s in a given delta
 */
export const getMentions = (delta: Optional<AnyDelta>): Mention[] =>
    getOps(delta)
        .filter(isMentionOp)
        .map(op => omit(op.insert.mention, "text"));

/**
 * Return a list of mentions that were added between two markup deltas, along
 * with a list of mentions that were removed for the last time (i.e. there are
 * no remaining mentions with the same target)
 */
export const mentionDiff = (oldMarkup: AnyMarkupDelta, newMarkup: AnyMarkupDelta): MentionDiff => {
    const parsedOldMarkup = parseMarkupDelta(oldMarkup);
    const parsedNewMarkup = parseMarkupDelta(newMarkup);

    const forwardChange = parsedOldMarkup.diff(parsedNewMarkup);
    const backwardChange = parsedNewMarkup.diff(parsedOldMarkup);

    const addedMentions = getMentions(forwardChange);
    const removedMentions = getMentions(backwardChange);
    const remainingMentions = getMentions(parsedNewMarkup);

    const lastRemovedMentions = removedMentions.filter(m =>
        !remainingMentions.some(r => m.target == r.target)
    );

    return {
        addedMentions,
        lastRemovedMentions,
    };
};
