import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";

import { BondCardPrivacyDomain } from "@/components/BondCard";
import Avatar from "@/components/gui/Avatar";
import * as d from "@/domain/domain";
import { BondInvite, InviteTarget } from "@/domain/invites";
import { viewStackUtils, viewUtils } from "@/domain/views";
import { selectCurrentPersonId, selectCurrentUserId, updateAuthInviteCode } from "@/features/auth";
import {
    redeemBondInviteThunk,
    rejectBondInviteThunk,
    retrieveBondInviteThunk,
    selectBondById,
    selectBondTitle,
} from "@/features/bonds";
import { selectPerson, selectUser } from "@/features/users";
import { useInterestedInvitedBond, useInterestedPersons } from "@/hooks/interest/useInterest";
import useViewStackNavigate from "@/hooks/navigation/useViewStackNavigate";
import useAddressParams from "@/hooks/useAddressParams";
import { useColourExtraction } from "@/hooks/useColourExtraction";
import { useConnectedEffect } from "@/hooks/useConnectedEffect";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import log from "@/misc/log";
import { isMobileBrowser } from "@/misc/mobile";
import { Optional } from "@/misc/types";
import { useAppDispatch } from "@/store/redux";

enum InviteStatus {
    Unknown,
    RetrievingDetails,
    ConfirmingJoin,
    Redeeming,
    Rejecting,
    Failed,
    Expired,
    Unauthorized,
    Rejected,
    AlreadyAccepted,
}

function UnauthorizedInvite(props: { target?: InviteTarget; }): React.JSX.Element {
    const invitee = props.target?.case === "invitee" ? props.target.invitee : undefined;
    const personId = invitee?.case === "personId" ? invitee.personId : undefined;
    const emailArg = invitee?.case === "email" ? invitee.email : undefined;

    useInterestedPersons(personId);
    const person = useSelectorArgs(selectPerson, personId);
    const maybeEmail = emailArg ?? person?.email;

    // In the future we may have some way to request access from the inviter. For now,
    // just hide the buttons but keep the structure there to show how it's intended
    // to work.
    // eslint-disable-next-line no-constant-binary-expression
    const showRequestAccess = false && maybeEmail !== undefined;

    return (
        <section className="c-content c-content--notification">
            <div className="c-access-error">
                <div className="c-access-error__icon"></div>
                <h1 className="c-access-error__title">You don't have access</h1>
                {maybeEmail && (
                    <>
                        <p className="c-access-error__text">
                            This invitation is for <strong>{maybeEmail}</strong>
                        </p>
                        {showRequestAccess && (
                            <p className="c-access-error__text">
                                Verify that <strong>{maybeEmail}</strong>{" "}
                                is your email to gain access or request access.
                            </p>
                        )}
                    </>
                )}
                {!maybeEmail && (
                    <p className="c-access-error__text">
                        This invitation is for another user.
                    </p>
                )}
                {showRequestAccess && (
                    <div className="c-access-error__buttons">
                        <button className="c-btn-access">Request access</button>
                        <button className="c-btn-access">Verify email</button>
                    </div>
                )}
            </div>
        </section>
    );
}

export default function InviteRedemptionView(): React.JSX.Element {
    const { opaqueInviteCode } = useAddressParams();
    const dispatch = useAppDispatch();
    const { navigateReplace } = useViewStackNavigate();

    const [inviteStatus, setInviteStatus] = useState<InviteStatus>(
        InviteStatus.RetrievingDetails,
    );

    const [inviteTarget, setInviteTarget] = useState<Optional<InviteTarget>>(undefined);

    const currentPersonId = useSelectorArgs(selectCurrentPersonId);
    const currentUserId = useSelectorArgs(selectCurrentUserId);
    const [retrievedBondId, setRetrievedBondId] = useState<Optional<d.BondId>>(undefined);
    const retrievedBond = useSelectorArgs(selectBondById, retrievedBondId);

    const alreadyInBond = useMemo(() => {
        return retrievedBondId && retrievedBond && currentUserId &&
            retrievedBond.followers.includes(currentUserId);
    }, [currentUserId, retrievedBond, retrievedBondId]);

    const { email: currentUserEmail } = useSelectorArgs(selectUser, currentUserId) || {};

    useEffect(() => {
        if (opaqueInviteCode) {
            dispatch(updateAuthInviteCode(opaqueInviteCode));
        }

        return () => {
            dispatch(updateAuthInviteCode(undefined));
        };
    }, [dispatch, opaqueInviteCode]);

    // Stream live information about the bond specified by the invite
    useInterestedInvitedBond(retrievedBondId);

    const inviteIsForCurrentPerson = useCallback((bi: BondInvite) => {
        if (bi.body.target.case == "common") {
            return true;
        }

        const invitee = bi.body.target.invitee;
        if (invitee.case === "personId") {
            return invitee.personId === currentPersonId;
        }
        else {
            return invitee.email === currentUserEmail;
        }
    }, [currentPersonId, currentUserEmail]);

    const retrieveInvite = useCallback(async () => {
        if (!opaqueInviteCode || !currentUserId || !currentUserEmail) {
            return;
        }

        log.info(`Retrieving bond invite code: '${opaqueInviteCode}'`);

        const bondInvite = await dispatch(
            retrieveBondInviteThunk({ opaqueCode: opaqueInviteCode }),
        ).unwrap().catch(e => {
            throw new Error("Failed to find invite: " + e);
        });

        if (!bondInvite) {
            return;
        }

        setInviteTarget(bondInvite.body.target);

        if (!inviteIsForCurrentPerson(bondInvite)) {
            setInviteStatus(InviteStatus.Unauthorized);
            return;
        }

        if (bondInvite.body.rejected) {
            setInviteStatus(InviteStatus.Rejected);
            return;
        }
        else if (bondInvite.body.accepted) {
            setInviteStatus(InviteStatus.AlreadyAccepted);
            setRetrievedBondId(bondInvite.bondId);
            return;
        }

        if (bondInvite.body.expiry.expired) {
            setInviteStatus(InviteStatus.Expired);
            return;
        }

        setInviteStatus(InviteStatus.ConfirmingJoin);
        setRetrievedBondId(bondInvite.bondId);
    }, [dispatch, opaqueInviteCode, currentUserId, currentUserEmail, inviteIsForCurrentPerson]);

    const redeemAndRedirect = useCallback(async () => {
        if (!opaqueInviteCode) return;

        const bondId = await dispatch(
            redeemBondInviteThunk({ opaqueCode: opaqueInviteCode }),
        ).unwrap().catch(e => {
            throw new Error("Failed to redeem invite: " + e);
        });

        if (!bondId) return;

        const stack = viewStackUtils.defaultStackForView(viewUtils.singleBond(bondId));
        navigateReplace(stack);
    }, [dispatch, navigateReplace, opaqueInviteCode]);

    const rejectAndRedirect = useCallback(async () => {
        if (!retrievedBondId) return;

        await dispatch(
            rejectBondInviteThunk({ bondId: retrievedBondId }),
        ).unwrap().catch(e => {
            throw new Error("Failed to reject invite: " + e);
        });

        const stack = viewStackUtils.defaultStackForView(viewUtils.inbox());
        navigateReplace(stack);
    }, [dispatch, navigateReplace, retrievedBondId]);

    // State machine for the invitation flow
    useConnectedEffect(() => {
        switch (inviteStatus) {
            case InviteStatus.RetrievingDetails:
                retrieveInvite().catch(e => {
                    log.error(e);
                    setInviteStatus(InviteStatus.Unknown);
                });
                break;

            case InviteStatus.ConfirmingJoin: {
                if (retrievedBondId && alreadyInBond) {
                    const view = viewUtils.singleBond(retrievedBondId);
                    const stack = viewStackUtils.defaultStackForView(view);
                    log.debug("Redirecting into already-joined bond");
                    navigateReplace(stack, { replace: true });
                }
                break;
            }

            case InviteStatus.Redeeming:
                redeemAndRedirect().catch(e => {
                    log.error(e);
                    setInviteStatus(InviteStatus.Failed);
                });
                break;

            case InviteStatus.Rejecting:
                rejectAndRedirect().catch(e => {
                    log.error(e);
                    setInviteStatus(InviteStatus.Failed);
                });
                break;

            case InviteStatus.Failed:
                break;

            case InviteStatus.Expired:
                break;

            case InviteStatus.Unauthorized:
                break;

            case InviteStatus.Rejected:
                break;

            case InviteStatus.AlreadyAccepted:
                break;

            case InviteStatus.Unknown:
                break;
        }
    }, [
        navigateReplace,
        inviteStatus,
        opaqueInviteCode,
        currentUserId,
        currentUserEmail,
        alreadyInBond,
        retrievedBondId,
        redeemAndRedirect,
        rejectAndRedirect,
        retrieveInvite,
    ]);

    const joinBondCb = useCallback(() => {
        setInviteStatus(InviteStatus.Redeeming);
    }, []);

    const rejectInviteCb = useCallback(() => {
        setInviteStatus(InviteStatus.Rejecting);
    }, []);

    // The invitation flow state determines what to render
    const htmlForCurrentState = useMemo((): React.JSX.Element => {
        switch (inviteStatus) {
            case InviteStatus.RetrievingDetails:
                return <>Retrieving bond for invite...</>;

            case InviteStatus.ConfirmingJoin:
                return (
                    <JoinBondConfirmation
                        retrievedBondId={retrievedBondId}
                        joinCb={joinBondCb}
                        rejectCb={rejectInviteCb}
                        inviteIsCommon={inviteTarget?.case === "common"}
                    />
                );

            case InviteStatus.Redeeming:
                return <>Joining bond...</>;

            case InviteStatus.Rejecting:
                return <>Rejecting bond invite...</>;

            case InviteStatus.Failed:
                return <>Failed to redeem bond invite.</>;

            case InviteStatus.Expired:
                return <>This bond invite has expired.</>;

            case InviteStatus.Unauthorized:
                return <UnauthorizedInvite target={inviteTarget} />;

            case InviteStatus.Rejected:
                return <>You have dismissed this invite.</>;

            case InviteStatus.AlreadyAccepted:
                return (
                    <JoinBondConfirmation
                        retrievedBondId={retrievedBondId}
                        joinCb={joinBondCb}
                        rejectCb={rejectInviteCb}
                        alreadyAccepted={true}
                        inviteIsCommon={inviteTarget?.case === "common"}
                    />
                );

            case InviteStatus.Unknown:
                return <>An error occurred.</>;
        }
    }, [inviteStatus, retrievedBondId, inviteTarget, joinBondCb, rejectInviteCb]);

    return htmlForCurrentState;
}

const defaultBackgroundColour = "rgb(0, 0, 0, 0)";

function JoinBondConfirmation(
    props: {
        retrievedBondId?: d.BondId;
        joinCb: () => void;
        rejectCb: () => void;
        inviteIsCommon?: boolean;
        alreadyAccepted?: boolean;
    },
): React.JSX.Element {
    const { retrievedBondId, joinCb, rejectCb } = props;
    const inviteIsCommon = props.inviteIsCommon ?? false;
    const alreadyAccepted = props.alreadyAccepted ?? false;
    const isMobile = isMobileBrowser();
    const { navigatePop } = useViewStackNavigate();

    const retrievedBond = useSelectorArgs(selectBondById, retrievedBondId);
    const retrievedBondTitle = useSelectorArgs(selectBondTitle, retrievedBondId);

    const bondMembers = retrievedBond?.followers;

    const cardImageWrapperClasses = classNames("c-card-redemption__image-wrapper");
    const cardImageClasses = classNames("c-card-redemption__image");

    const closeButtonClasses = classNames("c-btn-return", {
        "c-btn-return--desktop": !isMobile,
    });
    const headerClasses = classNames("c-header", {
        "c-header--desktop": !isMobile,
    });

    const loadImageAndExtractColour = useColourExtraction(defaultBackgroundColour);
    const [backgroundColour, setBackgroundColour] = useState<string>(
        defaultBackgroundColour,
    );

    useEffect(() => {
        if (!retrievedBond) return;
        loadImageAndExtractColour(retrievedBond.knowledge.imageUrl).then(c => {
            if (c) setBackgroundColour(c);
        });
    }, [retrievedBond, loadImageAndExtractColour]);

    const header = (
        <div className="c-header-wrapper c-header-wrapper--invite-redemption">
            <div className={headerClasses}>
                <button
                    className={closeButtonClasses}
                    onClick={() => navigatePop()}
                    title="Return"
                >
                    Return
                </button>
                {retrievedBondId ? (
                    <>
                        {retrievedBondTitle.title}
                        <BondCardPrivacyDomain id={retrievedBondId} includeInvited={false} />
                    </>
                ) : (
                    `Bond Invitation`
                )}
            </div>
        </div>
    );

    const body = (
        <div className="c-content__bond-suggestion">
            <div className="c-content__bond c-content__bond--invite-redemption">
                <div className="c-card-redemption">
                    {retrievedBond ? (
                        <>
                            <div className="c-card-redemption__content">
                                {bondMembers?.map(member => (
                                    <Avatar
                                        id={member}
                                        key={member}
                                        showPresence={false}
                                        size="default"
                                        context={{ isMobile: isMobile }}
                                    />
                                ))}
                            </div>
                            {retrievedBond.knowledge.imageUrl && (
                                <figure className={cardImageWrapperClasses}>
                                    <img
                                        src={retrievedBond.knowledge.imageUrl}
                                        alt=""
                                        className={cardImageClasses}
                                    />
                                </figure>
                            )}
                            <div className="c-card-redemption__content">
                                {/* TODO: This should be a list of bullet points. */}
                                <p className="c-bond-invite__summary">
                                    "{retrievedBond.knowledge.summary}"
                                </p>
                            </div>
                        </>
                    ) : (
                        <div className="c-card-redemption__content">
                            <p className="c-bond-invite__summary">
                                Failed to load details for ({retrievedBondId}).
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <div className="c-redemption-footer">
                {!(inviteIsCommon || alreadyAccepted) && (
                    <button
                        className="c-btn-invite-join reject"
                        onClick={rejectCb}
                    >
                        Dismiss
                    </button>
                )}
                <button
                    className="c-btn-invite-join"
                    onClick={joinCb}
                >
                    {alreadyAccepted ? "Rejoin" : "Join"}
                </button>
            </div>
        </div>
    );

    return (
        <main className="l-main" style={{ backgroundColor: backgroundColour }}>
            {header}
            <section className="c-content c-content--invite-redemption">
                {body}
            </section>
        </main>
    );
}
