import { z } from "zod";

export const emailSchema = z.string().email();

export const zodAddIssue = (ctx: z.RefinementCtx, message: string, path?: (string | number)[]) => {
    ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message,
        path,
    });
};

/**
 * Create a schema for a discriminated union value.
 * @param caseName - The name of the case.
 * @param caseSchema - The schema for the case.
 * @returns A schema for the discriminated union value.
 */
export const discriminatedUnionValueSchemaBuilder = <
    S extends z.ZodTypeAny,
    T extends string = string,
>(caseName: T, caseSchema: S) =>
    z.object({
        case: z.literal(caseName),
        value: z.object({ [caseName]: caseSchema }).transform(x => x[caseName]),
    });
