import classNames from "classnames";
import { Link } from "react-router-dom";

import LinkToView from "@/components/navigation/LinkToView";
import { SentryReportButton } from "@/components/SentryReportButton";
import { AppView, viewUtils } from "@/domain/views";
import {
    selectCurrentSquadIdsOrderedBySquadName,
    selectSquadIdFromViewStack,
} from "@/features/squads";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { isMobileBrowser } from "@/misc/mobile";
import { Optional } from "@/misc/types";
import { useAppSelector } from "@/store/redux";
import { useEffect, useMemo, useState } from "react";
import { FeatureFlagged } from "./FeatureFlags";

const mediaSettingsView = viewUtils.mediaSettings();
const avatarSettingsView = viewUtils.profileSettings();

export function SidebarLinks(): React.JSX.Element {
    const isMobile = isMobileBrowser();

    const navClasses = classNames("c-links", {
        "c-links--desktop": !isMobile,
    });

    const sortedSquads = useAppSelector(selectCurrentSquadIdsOrderedBySquadName);
    const firstSquadView = useMemo(
        () => sortedSquads[0] ? viewUtils.squadSettings(sortedSquads[0]) : undefined,
        [sortedSquads],
    );

    const selectedSquadId = useSelectorArgs(selectSquadIdFromViewStack);
    const [squadSettingsView, setSquadSettingsView] = useState<Optional<AppView>>(firstSquadView);

    useEffect(() => {
        if (selectedSquadId) {
            setSquadSettingsView(viewUtils.squadSettings(selectedSquadId));
        }
        else if (firstSquadView) {
            setSquadSettingsView(firstSquadView);
        }
    }, [selectedSquadId, firstSquadView]);

    return (
        <nav className={navClasses}>
            <LinkToView view={mediaSettingsView} className="c-links__item">
                Preferences
            </LinkToView>
            <FeatureFlagged flag={"show-squads-management-frontend"} match={true}>
                {squadSettingsView && (
                    <LinkToView view={squadSettingsView} className="c-links__item">
                        Squads
                    </LinkToView>
                )}
            </FeatureFlagged>
            <LinkToView view={avatarSettingsView} className="c-links__item">
                Profile
            </LinkToView>
            <SentryReportButton buttonClassNames="c-links__item c-links__item--report" />
            <Link to="/logout" className="c-links__item">Sign out</Link>
        </nav>
    );
}
