import { BlobId, BondId, CallId, MessageId, UserId } from "@/domain/domain";
import { Optional } from "@/misc/types";

export type SearchResult = {
    score: bigint;
    type: string;
    content: unknown;
};

export type Highlight = {
    snippet: string;
    matchedTokens: string[];
};

//
//

// Bond

export type BondSearchResult = SearchResult & {
    type: "bond";
    content: BondResult;
};

type BondResult = {
    highlight: BondHighlight;
    bondId: BondId;
};

export type BondHighlight = {
    title: Highlight;
    summary: Highlight;
    detailedSummary: Highlight;
};

// Message

export type MessageSearchResult = SearchResult & {
    type: "message";
    content: MessageResult;
};

type MessageResult = {
    highlight: MessageHighlight;
    bondId: BondId;
    messageId: MessageId;
    authorId: UserId;
};

export type MessageHighlight = {
    text: Highlight;
};

// Call

export type CallSearchResult = SearchResult & {
    score: number;
    type: "call";
    content: CallResult;
};

type CallResult = {
    highlight: CallHighlight;
    bondId: BondId;
    callId: CallId;
    startMessageId: MessageId;
    endMessageId: Optional<MessageId>;
};

export type CallHighlight = {
    title: Highlight;
    shortSummary: Highlight;
    detailedSummary: Highlight;
};

// Attachment

export type AttachmentSearchResult = SearchResult & {
    score: number;
    type: "attachment";
    content: AttachmentResult;
};

type AttachmentResult = {
    highlight: AttachmentHighlight;
    messageId: MessageId;
    blobId: BlobId;
    bondId: BondId;
    mimeType: string;
};

export type AttachmentHighlight = {
    filename: Highlight;
    shortDescription: Highlight;
    longDescription: Highlight;
};

export const isBondSearchResult = (r: Optional<SearchResult>): r is BondSearchResult =>
    r?.type === "bond";
export const isMessageSearchResult = (r: Optional<SearchResult>): r is MessageSearchResult =>
    r?.type === "message";
export const isCallSearchResult = (r: Optional<SearchResult>): r is CallSearchResult =>
    r?.type === "call";
export const isAttachmentSearchResult = (r: Optional<SearchResult>): r is AttachmentSearchResult =>
    r?.type === "attachment";
