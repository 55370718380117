import { markRead, subBondMsgSeqNums } from "@/api/channels";
import {
    fetchMessages,
    flattenOfficialMessageAndAttachments,
    getMessage,
    OfficialMessagesBundle,
} from "@/api/chats";
import { UserSquadLastRead } from "@/api/squads";
import { isAnyLocalAttachment, ProposedAttachment } from "@/domain/attachments";
import { BondOverview, BondOverviewPredicate } from "@/domain/bonds";
import {
    DeletedSequenceNumberWrapper,
    SequenceNumberOrDeleted,
    SequenceNumberWrapper,
    unreadMessageCount,
} from "@/domain/channels";
import * as d from "@/domain/domain";
import {
    convertDraftContentToV3,
    convertDraftProvider,
    getDraftLastApiChange,
    getDraftMentions,
    getDraftMessageDoc,
    getDraftMessageText,
    withChange,
    withDoc,
} from "@/domain/draftChatContent";
import {
    DraftTarget,
    DraftType,
    getChannelOfDraftTarget,
    isBondCreationDraftTarget,
    isChannelDraftTarget,
    WithDraftTarget,
} from "@/domain/draftTarget";
import { filterMentions } from "@/domain/mentions";
import {
    AnyUnsentLocalMessage,
    convertDraftToUnsentMessage,
    determineOfficialMessageContributors,
    DraftChatMessage,
    emptyDraftChatMessage,
    isErroredMsg,
    isOfficialChatMessage,
    isUnsentMsg,
    messageIsSendable,
    messageMentionsAny,
    MessageSendableStatus,
    UnsentChatMessage,
} from "@/domain/messages";
import * as migrateV30 from "@/domain/migrate/v30";
import { docBridge } from "@/domain/richtext/bridge";
import {
    Change,
    defaultProvider,
    Doc,
    Format,
    Provider,
    providerFromString,
    providerToString,
} from "@/domain/richtext/types";
import { userSortByBondActivity } from "@/domain/users";
import * as cm from "@/ds/ChannelMap";
import {
    createHAMT,
    createHAMTIndex,
    hamtEntries,
    hamtGet,
    hamtKeys,
    hamtValues,
    ImmutableHAMT,
} from "@/ds/hamt";
import { resetStore, selectCurrentUserId } from "@/features/auth";
import {
    clearDraftThunk,
    createBondFromMessageThunk,
    selectBondCreationAudience,
    selectBondCreationDraft,
} from "@/features/bondCreation";
import { archiveBond, selectBonds, streamedBonds } from "@/features/bonds";
import {
    deleteAttachmentFromDraftThunk,
    selectLocalAttachment,
    selectUnsentMessages,
    sendMessageThunk,
    streamedMessages,
    testInsertUnsentMessage,
} from "@/features/chats";
import { createProxiedAsyncThunk } from "@/features/proxiedThunk";
import { createSelectorPair, memoizeOptions } from "@/features/selectors";
import { selectCurrentSquadIds, selectSquadLatestActivity } from "@/features/squads";
import { createStreamingAsyncThunk } from "@/features/streamingThunk";
import { unaryThunkHandler } from "@/features/thunk";
import { selectUsers } from "@/features/users";
import log from "@/misc/log";
import type { Diff, Optional } from "@/misc/types";
import { TypedEntries } from "@/misc/types";
import { optionalToList, range, separateDiscriminatedUnion } from "@/misc/utils";
import {
    checkPersistor,
    fetchIdbStore,
    fetchKeyedIdbStore,
    idbStore,
    localStorageStore,
    simpleIdbTranslate,
} from "@/persist/shared";
import { storageRead } from "@/persist/storage";
import type {
    BrowserStoreUpdate,
    HydrateArgs,
    IdbStoreUpdate,
    PersistenceUpdate,
    RWTransaction,
} from "@/persist/types";
import { valueUpdateToStoreUpdate } from "@/persist/updates";
import { tagActionCreator, tagLocalAction } from "@/store/locations";
import { createAppSelector } from "@/store/redux";
import type { RootState } from "@/store/types";
import {
    ActionCreatorWithPayload,
    createSlice,
    current,
    original,
    PayloadAction,
} from "@reduxjs/toolkit";
import { castDraft, WritableDraft } from "immer";
import { selectUnsentMessageById } from "./chats";

export const catchupMessages = createProxiedAsyncThunk(
    "messages/catchup",
    async (
        { channelId }: { channelId: d.ChannelId; },
        thunkAPI,
    ): Promise<OfficialMessagesBundle> => {
        const state = thunkAPI.getState();
        const localSequenceNumber = selectLocalSequenceNumber(state, channelId);
        // Note: the sequence numbers of messages start at 1, not 0(!)
        const from = (localSequenceNumber ?? 0) + 1;

        const bundles = await unaryThunkHandler(
            thunkAPI,
            fetchMessages(channelId, from),
            `fetchMessages ${channelId} from ${from}`,
        );

        if (bundles.length === 0) {
            throw thunkAPI.rejectWithValue({
                error: "no relevant messages in range",
                at: Date.now(),
            });
        }

        return flattenOfficialMessageAndAttachments(bundles, channelId);
    },
);

export const getMessageThunk = createProxiedAsyncThunk(
    "messages/getMessage",
    async (
        { messageId, channelId }: { messageId: d.MessageId; channelId: d.ChannelId; },
        thunkAPI,
    ): Promise<OfficialMessagesBundle> => {
        const message = await unaryThunkHandler(
            thunkAPI,
            getMessage(messageId),
            `get message ${messageId}`,
        );

        return flattenOfficialMessageAndAttachments([message], channelId);
    },
);

type StageMessageForChannelResult = [d.ChannelId, UnsentChatMessage];

export const stageMessageForChannel = createProxiedAsyncThunk(
    "messages/stageForChannel",
    async (draftMsg: DraftChatMessage, thunkAPI): Promise<StageMessageForChannelResult> => {
        if (!isChannelDraftTarget(draftMsg.draftTarget)) {
            log.warn(`Cannot stage a message without a channel draft target`, draftMsg);
            throw thunkAPI.rejectWithValue({
                error: `stageMessage requires a channel draft target`,
            });
        }

        const channelId = getChannelOfDraftTarget(draftMsg.draftTarget);

        const unsentMsg = convertDraftToUnsentMessage(draftMsg, Date.now());

        return [channelId, unsentMsg];
    },
);

export const subChannelMsgSeqNumsThunk = createStreamingAsyncThunk<
    AsyncIterableIterator<Diff<d.BondId>>,
    SequenceNumberOrDeleted
>(
    "messages/subBondMsgSeqNums",
    {
        rpc: ({ arg, state, signal }) => {
            const userId = selectCurrentUserId(state)!;
            return subBondMsgSeqNums(arg, { userId }, signal);
        },
        parser: ({ thunkAPI, content }) => {
            const [deleted, seqNosWrapped] = separateDiscriminatedUnion<
                DeletedSequenceNumberWrapper,
                SequenceNumberWrapper
            >(msg => msg.case === "deletedId", content);

            if (deleted.length) {
                const ids = deleted.map(msg => msg.value);
                thunkAPI.dispatch(deletePublishedSequenceNumbers(ids));
            }

            if (seqNosWrapped.length) {
                const seqNos = seqNosWrapped
                    .map(msg => msg.value)
                    .filterLatest(sn => sn.channelId);
                thunkAPI.dispatch(
                    updatePublishedSequenceNumbers(seqNos),
                );
            }
        },
    },
);

export const updateUserReadSequenceNumber = createProxiedAsyncThunk(
    "messages/markRead",
    async (args: ChannelSequenceNumber, thunkAPI) => {
        if (!args.channelId) {
            throw thunkAPI.rejectWithValue({ error: `Tried to markRead for the empty channel` });
        }

        const store = thunkAPI.getState();
        const userId = selectCurrentUserId(store)!;

        await unaryThunkHandler(
            thunkAPI,
            markRead({
                ...args,
                userId,
            }),
            `markRead ${args.channelId}:${args.sequenceNumber}`,
        );
    },
);

type ChannelUpdate =
    | IdbStoreUpdate<typeof stores.map>
    | IdbStoreUpdate<typeof stores.drafts>
    | IdbStoreUpdate<typeof stores.pubSeqNos>
    | IdbStoreUpdate<typeof stores.stagedSeqNos>
    | BrowserStoreUpdate<typeof stores.richTextProvider>;

type ChannelSequenceNumber = {
    channelId: d.ChannelId;
    sequenceNumber: number;
};

const hamts = {
    channelMap: createHAMT(({ channelId }: cm.PerChannelData) => channelId),
    drafts: createHAMT(({ draftTarget }: DraftChatMessage) => {
        if (!isChannelDraftTarget(draftTarget)) {
            throw new Error(`Incorrect draft target ${JSON.stringify(draftTarget)} for channel`);
        }
        return draftTarget.channelId;
    }),
    seqNos: createHAMTIndex(
        ({ channelId }: ChannelSequenceNumber) => channelId,
        ({ sequenceNumber }) => sequenceNumber,
    ),
    squadReadTimes: createHAMTIndex(
        ({ squadId }: UserSquadLastRead) => squadId,
        ({ lastRead }) => lastRead,
    ),
};

export interface ChannelsState {
    map: ImmutableHAMT<d.ChannelId, cm.PerChannelData>;
    richTextProvider: Provider;
    drafts: ImmutableHAMT<d.ChannelId, DraftChatMessage>;
    // Server-side record of latest-read-message-sequence-number.
    publishedSequenceNumbers: ImmutableHAMT<d.ChannelId, number>;
    // Local record of latest-read-message-sequence-number.
    stagedSequenceNumbers: ImmutableHAMT<d.ChannelId, number>;
    // Local record of latest-message-sequence-number-on-server.
    maxSequenceNumbers: ImmutableHAMT<d.ChannelId, number>;
    // Record of when squads are marked as read
    squadReadTimes: ImmutableHAMT<d.SquadId, number>;
    updates: ChannelUpdate[];
}

export interface UpdateDraftDocArgs {
    draftTarget: DraftTarget;
    update:
        | { doc: Doc<Provider, Format.JSON>; change?: Change<Provider, Format.JSON>; }
        | { doc?: Doc<Provider, Format.JSON>; change: Change<Provider, Format.JSON>; };
    isApiChange: boolean;
}

export interface TransferDraftArgs {
    from: DraftTarget;
    to: DraftTarget;
    content: DraftChatMessage;
}

const recordToArray =
    <K extends string, V, Out>(f: (k: K, v: V) => Out) => (r: Optional<Record<K, V>>): Out[] =>
        TypedEntries(r ?? {} as Record<K, V>).map(([k, v]) => f(k, v));

const channelSequenceToArray = recordToArray((channelId: d.ChannelId, sequenceNumber: number) => ({
    channelId,
    sequenceNumber,
}));

const getInitialState = (
    props?: {
        map?: Array<cm.PerChannelData>;
        richTextProvider?: Provider;
        drafts?: Array<DraftChatMessage>;
        pubSeqNos?: Record<d.ChannelId, number>;
        stagedSeqNos?: Record<d.ChannelId, number>;
        maxSeqNos?: Record<d.ChannelId, number>;
        squadReadTimes?: Record<d.SquadId, number>;
    },
): ChannelsState => {
    const pubSeqNos = channelSequenceToArray(props?.pubSeqNos);
    const stagedSeqNos = channelSequenceToArray(props?.stagedSeqNos);
    const maxSeqNos = channelSequenceToArray(props?.maxSeqNos);
    const squadReadTimes = recordToArray((
        squadId: d.SquadId,
        lastRead: number,
    ): UserSquadLastRead => ({
        squadId,
        userId: undefined as any as d.UserId, // shut up
        lastRead,
    }))(props?.squadReadTimes);

    return ({
        map: hamts.channelMap.initial(props?.map),
        richTextProvider: props?.richTextProvider ?? defaultProvider,
        drafts: hamts.drafts.initial(props?.drafts),
        publishedSequenceNumbers: hamts.seqNos.initial(pubSeqNos),
        stagedSequenceNumbers: hamts.seqNos.initial(stagedSeqNos),
        maxSequenceNumbers: hamts.seqNos.initial(maxSeqNos),
        squadReadTimes: hamts.squadReadTimes.initial(squadReadTimes),
        updates: [],
    });
};

/*
    ******************* Sequence number glossary *******************
    Local sequence number:
    For a given channel/bond, the maximum sequence number of a message available
    locally.
    e.g. a bond with 7 messages in total, all available in the store, would have
    a local sequence number of 7.

    Maximum sequence number:
    The largest sequence number of a message that exists within a channel, that is,
    the largest sequence number present within the backend. This information exists
    on the BondOverview.
    e.g. Arun sends a message into a channel, and the backend gives that message
    a sequence number of 10. The maximum sequence number of that channel is then 10
    until a further message is processed and accepted by the backend for the channel.

    Published sequence number:
    For a specific user and channel, the published sequence number is the sequence
    number of the 'latest read' message distributed by the backend.
    e.g. If the backend believes Arun had read up to and including the message
    with sequence number 3 on a channel, then the published sequence number
    would be 3 for Arun on that channel.

    Staged sequence number:
    For a specific user and channel, the staged sequence number is a specific
    frontend's local knowledge of what messages have been read by the user on
    that channel. It is used to determine how many messages are unread on a
    channel, and can both update and be updated by the backend.
    e.g. If the backend publishes sequence number 3 for Arun and a channel, then
    Arun's client goes offline before he reads the message with the sequence
    number 4, the published sequence number will be 3 and the staged will be 4.
    The MessageReadUpdater will then attempt to notify the backend because of
    the discrepancy.
    Further to this, if Arun logs in and the backend publishes sequence number 3
    for Arun and a channel, then the redux store will have the published and
    staged sequence numbers for that channel as 3. If the backend then publishes
    sequence number 4, then both will be updated to 4.

    Hopefully helpful diagram: dots = messages in store, underscores = messages
    not in store

                                published      staged            local       max
                                  seqno        seqno             seqno     seqno
                                    v            v                 v           v
                      ..............................................____________
    Server unaware of read status - ^^^^^^^^^^^^^ ^^^^^^^^^^^^^^^^^ ^^^^^^^^^^^^
                                                  /                   Client not
                                Client not yet read                   downloaded

    `local == max` when we have downloaded all available messages.
    `staged == local` when we a client has read all the messages it has.
    `published == staged` when the backend is aware of our read status.
*/

const updateFromBonds = (
    state: ChannelsState,
    bos: BondOverview[],
): ChannelsState => {
    const currentState = current(state);

    state.maxSequenceNumbers = hamts.seqNos.setMany(
        currentState.maxSequenceNumbers,
        bos.map(({ channelId, maxSequenceNumber: sequenceNumber }) => ({
            channelId,
            sequenceNumber,
        })),
    );

    state.map = hamts.channelMap.updateMany(
        currentState.map,
        bos.map(({ channelId }) => channelId),
        (k, v) => v ?? cm.getDefaultData(k),
        valueUpdateToStoreUpdate(state.updates, stores.map),
    );

    return state;
};

const updateStagedSequenceNumber = (
    state: WritableDraft<ChannelsState>,
    currentState: ChannelsState,
    csns: ChannelSequenceNumber[],
    force?: boolean,
) => {
    const transient = csns.reduce(
        (t, { channelId, sequenceNumber }) =>
            hamts.seqNos.update(t, channelId, currentStaged => {
                if (force || currentStaged === undefined || currentStaged < sequenceNumber) {
                    return sequenceNumber;
                }
                return currentStaged;
            }),
        hamts.seqNos.toTransient(currentState.stagedSequenceNumbers),
    );

    state.stagedSequenceNumbers = hamts.seqNos.toImmutable(
        transient,
        valueUpdateToStoreUpdate(state.updates, stores.stagedSeqNos),
    );
};

export const channelsSlice = createSlice({
    name: "channels",
    initialState: getInitialState(),
    selectors: {
        richTextProvider: state => state.richTextProvider,
        draftByChannelId: (state, id: Optional<d.ChannelId>): Optional<DraftChatMessage> =>
            id && hamtGet(state.drafts, id),
        publishedSequenceNumber: (state, id: Optional<d.ChannelId>): Optional<number> =>
            id && hamtGet(state.publishedSequenceNumbers, id),
        stagedSequenceNumber: (state, id: Optional<d.ChannelId>): Optional<number> =>
            id && hamtGet(state.stagedSequenceNumbers, id),
        localSequenceNumber: (state, id: Optional<d.ChannelId>): Optional<number> =>
            id && hamtGet(state.map, id)?.localSequenceNumber,
        maxSequenceNumber: (state, id: Optional<d.ChannelId>): Optional<number> =>
            id && hamtGet(state.maxSequenceNumbers, id),
        channelMap: (state, id: Optional<d.ChannelId>): Optional<cm.PerChannelData> =>
            id && hamtGet(state.map, id),
        allChannelMaps: state => state.map,
        allStagedSequenceNumbers: state => state.stagedSequenceNumbers,
        squadReadTime: (state, id: Optional<d.SquadId>): Optional<number> =>
            id && hamtGet(state.squadReadTimes, id),
    },
    reducers: {
        setRichTextProvider: (state, { payload }: PayloadAction<Provider>) => {
            if (state.richTextProvider == payload) return;

            const originalDrafts = original(state.drafts);
            if (originalDrafts === undefined) return;

            // Convert any existing drafts to the new provider
            state.updates = [];

            let drafts = hamts.drafts.toTransient(originalDrafts);
            for (const channelId of hamtKeys(originalDrafts)) {
                console.log(channelId);
                drafts = hamts.drafts.update(
                    drafts,
                    channelId,
                    draft => ({
                        ...draft!,
                        content: convertDraftProvider(payload, draft!.content),
                    }),
                );
            }

            state.drafts = hamts.drafts.toImmutable(
                drafts,
                valueUpdateToStoreUpdate(state.updates, stores.drafts),
            );

            // Store the new provider value in the slice
            state.richTextProvider = payload;
            state.updates.push([stores.richTextProvider, providerToString(state.richTextProvider)]);
        },
        updateDraftDoc: (state, { payload }: PayloadAction<UpdateDraftDocArgs>) => {
            if (!isChannelDraftTarget(payload.draftTarget)) return;

            const {
                draftTarget,
                update: { doc, change },
                isApiChange,
            } = payload;

            const { channelId } = draftTarget;

            state.updates = [];

            state.drafts = hamts.drafts.update(current(state).drafts, channelId, draft => {
                draft ??= emptyDraftChatMessage(draftTarget, state.richTextProvider);
                const content = convertDraftContentToV3(state.richTextProvider, draft.content);

                return {
                    ...draft,
                    content: change ?
                        withChange(content, change, isApiChange) :
                        withDoc(content, doc!, isApiChange),
                };
            }, valueUpdateToStoreUpdate(state.updates, stores.drafts));
        },
        updatePublishedSequenceNumber: (
            state,
            { payload }: PayloadAction<ChannelSequenceNumber>,
        ) => {
            const currentState = current(state);
            const { channelId, sequenceNumber } = payload;

            state.updates = [];

            state.publishedSequenceNumbers = hamts.seqNos.set(
                currentState.publishedSequenceNumbers,
                payload,
                valueUpdateToStoreUpdate(state.updates, stores.pubSeqNos),
            );

            state.map = hamts.channelMap.update(
                currentState.map,
                channelId,
                pcd => cm.publishedSeqNoUpdated(pcd, channelId, sequenceNumber),
                valueUpdateToStoreUpdate(state.updates, stores.map),
            );

            updateStagedSequenceNumber(state, currentState, [payload]);
        },
        updatePublishedSequenceNumbers: (
            state,
            { payload: seqNos }: PayloadAction<ChannelSequenceNumber[]>,
        ) => {
            if (seqNos.length === 0) return;

            state.updates = [];

            const currentState = current(state);

            state.publishedSequenceNumbers = hamts.seqNos.setMany(
                currentState.publishedSequenceNumbers,
                seqNos,
                valueUpdateToStoreUpdate(state.updates, stores.pubSeqNos),
            );

            // TODO: updateByObject?
            const transient = seqNos.reduce(
                (t, { channelId, sequenceNumber }) =>
                    hamts.channelMap.update(
                        t,
                        channelId,
                        pcd => cm.publishedSeqNoUpdated(pcd, channelId, sequenceNumber),
                    ),
                hamts.channelMap.toTransient(currentState.map),
            );

            state.map = castDraft(hamts.channelMap.toImmutable(
                transient,
                valueUpdateToStoreUpdate(state.updates, stores.map),
            ));

            updateStagedSequenceNumber(state, currentState, seqNos);
        },
        deletePublishedSequenceNumber: (
            state,
            { payload: channelId }: PayloadAction<d.ChannelId>,
        ) => {
            state.updates = [];

            const currentState = current(state);

            state.publishedSequenceNumbers = hamts.seqNos.remove(
                currentState.publishedSequenceNumbers,
                channelId,
                valueUpdateToStoreUpdate(state.updates, stores.pubSeqNos),
            );

            state.stagedSequenceNumbers = hamts.seqNos.remove(
                currentState.stagedSequenceNumbers,
                channelId,
                valueUpdateToStoreUpdate(state.updates, stores.stagedSeqNos),
            );
        },
        deletePublishedSequenceNumbers: (state, { payload: ids }: PayloadAction<d.ChannelId[]>) => {
            if (ids.length === 0) return;

            state.updates = [];

            const currentState = current(state);

            state.publishedSequenceNumbers = hamts.seqNos.removeMany(
                currentState.publishedSequenceNumbers,
                ids,
                valueUpdateToStoreUpdate(state.updates, stores.pubSeqNos),
            );

            state.stagedSequenceNumbers = hamts.seqNos.removeMany(
                currentState.stagedSequenceNumbers,
                ids,
                valueUpdateToStoreUpdate(state.updates, stores.stagedSeqNos),
            );
        },
        updateStagedSequenceNumberToLocalMax: (
            state,
            { payload: channelId }: PayloadAction<d.ChannelId>,
        ) => {
            const currentState = current(state);
            const sequenceNumber = hamtGet(currentState.map, channelId)?.localSequenceNumber;
            if (!sequenceNumber) return;

            updateStagedSequenceNumber(state, currentState, [{ channelId, sequenceNumber }]);
        },
        updateStagedSequenceNumberForTests: (
            state,
            { payload }: PayloadAction<ChannelSequenceNumber>,
        ) => {
            updateStagedSequenceNumber(state, current(state), [payload], true);
        },
        updateStagedSequenceNumberIfGreater: (
            state,
            { payload }: PayloadAction<ChannelSequenceNumber>,
        ) => {
            updateStagedSequenceNumber(state, current(state), [payload], false);
        },
        addAttachmentsToDraft: (
            state,
            { payload: attachments }: PayloadAction<ProposedAttachment[]>,
        ) => {
            if (attachments.length === 0) return;

            state.updates = [];

            let drafts = hamts.drafts.toTransient(current(state).drafts);

            attachments.forEach(attachment => {
                if (!isChannelDraftTarget(attachment.draftTarget)) return;

                const { localId, draftTarget } = attachment;
                const { channelId } = draftTarget;

                drafts = hamts.drafts.update(drafts, channelId, draft => {
                    draft ??= emptyDraftChatMessage(draftTarget, state.richTextProvider);
                    if (draft.attachmentIds.includes(localId)) return draft;
                    return {
                        ...draft,
                        attachmentIds: [...draft.attachmentIds, localId],
                    };
                });
            });

            state.drafts = hamts.drafts.toImmutable(
                drafts,
                valueUpdateToStoreUpdate(state.updates, stores.drafts),
            );
        },
        clearAttachmentsFromDraft: (
            state,
            { payload: draftTarget }: PayloadAction<DraftTarget>,
        ) => {
            if (!isChannelDraftTarget(draftTarget)) return;

            const channelId = draftTarget.channelId;

            state.updates = [];

            state.drafts = hamts.drafts.update(current(state).drafts, channelId, draft => {
                draft ??= emptyDraftChatMessage(draftTarget, state.richTextProvider);

                if (draft.attachmentIds.length === 0) return draft;

                return {
                    ...draft,
                    attachmentIds: [],
                };
            }, valueUpdateToStoreUpdate(state.updates, stores.drafts));
        },
        streamedSquadLastReads: (
            state,
            { payload: lastReads }: PayloadAction<UserSquadLastRead[]>,
        ) => {
            if (lastReads.length === 0) return;

            state.updates = [];
            const currentState = current(state).squadReadTimes;

            state.squadReadTimes = hamts.squadReadTimes.setMany(
                currentState,
                lastReads,
                valueUpdateToStoreUpdate(state.updates, stores.squadReadTimes),
            );
        },
    },
    extraReducers: builder => {
        builder.addCase(
            deleteAttachmentFromDraftThunk.fulfilled,
            (state, { payload: { localId, draftTarget } }) => {
                if (!isChannelDraftTarget(draftTarget)) return;
                const { channelId } = draftTarget;

                state.updates = [];

                state.drafts = hamts.drafts.update(current(state).drafts, channelId, draft => {
                    if (!draft) return undefined;

                    const index = draft.attachmentIds.indexOf(localId);
                    if (index === -1) return draft;

                    const attachmentIds = Array.from(draft.attachmentIds);
                    attachmentIds.splice(index, 1);

                    return {
                        ...draft,
                        attachmentIds,
                    };
                }, valueUpdateToStoreUpdate(state.updates, stores.drafts));
            },
        );
        builder.addCase(
            stageMessageForChannel.fulfilled,
            (
                state,
                { payload: [channelId, unsentMsg] }: PayloadAction<StageMessageForChannelResult>,
            ) => {
                const currentState = current(state);

                state.updates = [];

                state.map = hamts.channelMap.update(
                    currentState.map,
                    channelId,
                    pcd => cm.addUnsentToChannel(pcd, channelId, unsentMsg),
                    valueUpdateToStoreUpdate(state.updates, stores.map),
                );

                state.drafts = hamts.drafts.remove(
                    currentState.drafts,
                    channelId,
                    valueUpdateToStoreUpdate(state.updates, stores.drafts),
                );
            },
        );
        builder.addCase(testInsertUnsentMessage, (
            state,
            { payload: [channelId, msg] }: PayloadAction<[d.ChannelId, AnyUnsentLocalMessage]>,
        ) => {
            state.updates = [];

            state.map = hamts.channelMap.update(
                current(state).map,
                channelId,
                pcd => cm.addUnsentToChannel(pcd, channelId, msg),
                valueUpdateToStoreUpdate(state.updates, stores.map),
            );
        });
        builder.addCase(sendMessageThunk.fulfilled, (state, { payload: [channelId, msg] }) => {
            if (isErroredMsg(msg) || !msg.messageId) return;

            state.updates = [];
            state.map = hamts.channelMap.update(
                current(state).map,
                channelId,
                pcd => cm.messageSent(pcd, channelId, msg),
                valueUpdateToStoreUpdate(state.updates, stores.map),
            );
        });
        builder.addCase(streamedMessages, (state, { payload: { msgs } }) => {
            if (!msgs || msgs.length === 0) return;

            const channelId = msgs[0].channelId;

            state.updates = [];
            state.map = hamts.channelMap.update(
                current(state).map,
                channelId,
                pcd => cm.addToChannel(pcd, channelId, msgs),
                valueUpdateToStoreUpdate(state.updates, stores.map),
            );
        });
        builder.addCase(
            catchupMessages.fulfilled,
            (state, { payload: { channelId, msgs } }: PayloadAction<OfficialMessagesBundle>) => {
                if (!msgs || msgs.length === 0) return;

                state.updates = [];
                state.map = hamts.channelMap.update(
                    current(state).map,
                    channelId,
                    pcd => cm.addToChannel(pcd, channelId, msgs),
                    valueUpdateToStoreUpdate(state.updates, stores.map),
                );
            },
        );
        builder.addCase(streamedBonds, (state, { payload: bos }: PayloadAction<BondOverview[]>) => {
            if (bos.length === 0) return;

            state.updates = [];
            return updateFromBonds(state, bos);
        });
        builder.addCase(createBondFromMessageThunk.fulfilled, (state, { payload: bo }) => {
            state.updates = [];
            return updateFromBonds(state, [bo]);
        });
        builder.addCase(
            clearDraftThunk.fulfilled,
            (state, { payload: { draftTarget } }) => {
                if (!isChannelDraftTarget(draftTarget)) return;

                const { channelId } = draftTarget;

                state.updates = [];
                state.drafts = hamts.drafts.remove(
                    current(state).drafts,
                    channelId,
                    valueUpdateToStoreUpdate(state.updates, stores.drafts),
                );
            },
        );
        builder.addCase(archiveBond.fulfilled, (state, action) => {
            const args = action.meta.arg;
            if (!args || !args.channelId || !args.archive) {
                return;
            }

            const currentState = current(state);

            const channelId = args.channelId;
            const sequenceNumber = hamtGet(currentState.map, channelId)?.localSequenceNumber;
            if (!sequenceNumber) return;

            state.stagedSequenceNumbers = hamts.seqNos.set(
                currentState.stagedSequenceNumbers,
                { channelId, sequenceNumber },
                valueUpdateToStoreUpdate(state.updates, stores.stagedSeqNos),
            );
        });

        builder.addCase(resetStore, _state => {
            return getInitialState();
        });
    },
});

const {
    updatePublishedSequenceNumbers,
    deletePublishedSequenceNumbers,
} = channelsSlice.actions;

export const {
    setRichTextProvider,
    updateDraftDoc: updateDraftDocInternal,

    updatePublishedSequenceNumber,
    updateStagedSequenceNumberToLocalMax,
    updateStagedSequenceNumberForTests,
    updateStagedSequenceNumberIfGreater,

    addAttachmentsToDraft,
    clearAttachmentsFromDraft,
} = channelsSlice.actions;

const tagLocalForBondCreationDraftTarget = <
    P extends WithDraftTarget,
    T extends string,
>(
    creator: ActionCreatorWithPayload<P, T>,
) => tagActionCreator<P, T, typeof creator>(
    creator,
    tagLocalAction,
    p => isBondCreationDraftTarget(p.draftTarget),
);

export const updateDraftDoc = tagLocalForBondCreationDraftTarget(updateDraftDocInternal);

const selectors = channelsSlice.getSelectors((state: RootState) => state.channels);

export const {
    richTextProvider: selectRichTextProvider,
    draftByChannelId: selectDraftByChannelId,
    localSequenceNumber: selectLocalSequenceNumber,
    allChannelMaps: selectAllChannelMaps,
    publishedSequenceNumber: selectPublishedSequenceNumber,
    allStagedSequenceNumbers: selectAllStagedSequenceNumbers,
    squadReadTime: selectSquadReadTime,
    maxSequenceNumber: selectMaxSequenceNumber,
} = selectors;

export const bondIsUnread =
    (stagedSequenceNumberMap: ImmutableHAMT<d.ChannelId, number>): BondOverviewPredicate =>
    ({ channelId, maxSequenceNumber }) => {
        const staged = channelId && hamtGet(stagedSequenceNumberMap, channelId);
        return unreadMessageCount(staged, maxSequenceNumber) > 0;
    };

export const selectDraftMessageSendableStatus = (
    state: RootState,
    draftTarget: DraftTarget,
    requireAudienceForNewBonds: boolean = true,
): MessageSendableStatus => {
    const message = selectDraft(state, draftTarget);
    if (!message) return MessageSendableStatus.Denied;

    const isNewBond = isBondCreationDraftTarget(draftTarget);
    const attachments = selectLocalAttachmentsDraftForMessage(state, draftTarget);
    const newBondAudience = isNewBond ? selectBondCreationAudience(state, draftTarget) : undefined;

    return messageIsSendable(
        message,
        requireAudienceForNewBonds && isNewBond,
        attachments,
        newBondAudience,
    );
};

export const selectUnreadMessageCount = createAppSelector(
    [selectors.stagedSequenceNumber, selectors.maxSequenceNumber],
    unreadMessageCount,
    memoizeOptions.weakMapShallow,
);

export const selectIsRead = createAppSelector(
    [selectUnreadMessageCount],
    count => count === undefined || count === 0,
);

const selectUnreadMessages = createAppSelector(
    [
        selectors.stagedSequenceNumber,
        selectLocalSequenceNumber,
        selectors.channelMap,
    ],
    (stagedSeqNo, localSeqNo, cm) => {
        if (!cm) return [];

        const local = localSeqNo ?? 0;
        const staged = stagedSeqNo ?? 0;
        const n = Math.max(local - staged, 0);

        return range(n)
            // Start from the sequence number *after* stagedSeqNo
            .map(i => cm.unreadMessagesIndex[staged + 1 + i]);
    },
    memoizeOptions.weakMapShallow,
);

const selectUserIdsWithUnread = createAppSelector(
    [selectUnreadMessages],
    msgs => msgs.flatMap(determineOfficialMessageContributors).removeDuplicates(),
    memoizeOptions.weakMapShallow,
);

export const selectUserIdSetWithUnread = createAppSelector(
    [selectUserIdsWithUnread],
    msgs => msgs.toSet(),
    memoizeOptions.weakMapShallow,
);

const isUserId = (userId: Optional<d.UserId>): userId is d.UserId => !!userId;

// Bodge to fix import cycle in tests
const selectCurrentSquadIdsDelayed = (state: RootState) => selectCurrentSquadIds(state);

export const selectUserIdSetWithMentions = createAppSelector(
    [selectUnreadMessages, selectCurrentUserId, selectCurrentSquadIdsDelayed],
    (msgs, currentUserId, currentSquadIds): Set<d.UserId> =>
        msgs
            .filter(isOfficialChatMessage)
            .filter(msg => messageMentionsAny(msg, optionalToList(currentUserId), currentSquadIds))
            .map(msg => msg.senderId)
            .filter(isUserId)
            .toSet(),
    memoizeOptions.weakMapShallow,
);

export const selectHasMentions = createAppSelector(
    [selectUnreadMessages, selectCurrentUserId, selectCurrentSquadIdsDelayed],
    (msgs, currentUserId, currentSquadIds): boolean =>
        msgs.some(msg => messageMentionsAny(msg, optionalToList(currentUserId), currentSquadIds)),
    memoizeOptions.weakMapShallow,
);

const selectChannelActivityAdjustments = createAppSelector(
    [selectUserIdSetWithMentions, selectUserIdSetWithUnread],
    (mentionUserSet, unreadUserSet) => ({
        sort: userSortByBondActivity({
            mentionUserSet,
            unreadUserSet,
        }),
    }),
    memoizeOptions.weakMap,
);

const selectSortedUsersWithUnread = (state: RootState, id: Optional<d.ChannelId>) => {
    const usersWithUnread = selectUserIdsWithUnread(state, id);
    const adjustments = selectChannelActivityAdjustments(state, id);
    return selectUsers(state, usersWithUnread, adjustments);
};

export const selectSortedUserIdsWithUnreadPair = createSelectorPair(
    selectUserIdsWithUnread,
    createAppSelector(
        [selectSortedUsersWithUnread],
        uos => uos.map(uo => uo.id),
        memoizeOptions.weakMapShallow,
    ),
);

export const selectSortedUserIdSetWithUnread = createAppSelector(
    [selectUserIdsWithUnread],
    uIds => uIds.toSet(),
    memoizeOptions.weakMapShallow,
);

export const selectChannelInfosForSeqNumUpdate = createAppSelector(
    [
        state => state.channels.stagedSequenceNumbers,
        state => state.channels.publishedSequenceNumbers,
    ],
    (staged, published) =>
        Object.fromEntries(
            Array.from(hamtEntries(staged))
                .filter(([channelId, stagedSeqNum]) =>
                    (hamtGet(published, channelId) ?? -1) < stagedSeqNum
                ),
        ) as Partial<Record<d.ChannelId, number>>,
);

export const selectDebugSequenceNumbers = createAppSelector(
    [
        selectPublishedSequenceNumber,
        selectors.stagedSequenceNumber,
        selectLocalSequenceNumber,
        selectors.maxSequenceNumber,
    ],
    (published, staged, local, max) => ({
        published,
        staged,
        local,
        max,
    }),
);

export const selectMessageIdsByChannelId = createAppSelector(
    [selectors.channelMap],
    channel => channel?.curatedList ?? [],
);

export const selectUnsentQueue = (id: d.ChannelId) => (state: RootState) =>
    selectors.channelMap(state, id)?.unsent ?? [];

export const selectDraft = (
    state: RootState,
    dt: DraftTarget,
): Optional<DraftChatMessage> => {
    switch (dt.type) {
        case DraftType.Channel:
            return selectDraftByChannelId(state, dt.channelId);
        case DraftType.BondCreation:
            return selectBondCreationDraft(state, dt)?.message;
    }
};

export const selectDraftText = createAppSelector(
    [selectDraft],
    draft => getDraftMessageText(draft?.content) ?? "",
);

export const selectDraftDoc = createAppSelector(
    [selectDraft, selectors.richTextProvider],
    (draft, provider) =>
        getDraftMessageDoc(draft?.content) ??
            docBridge.empty(provider).get(Format.JSON),
);

export const selectDraftLastApiChange = createAppSelector(
    [selectDraft],
    draft => getDraftLastApiChange(draft?.content),
);

export const selectDraftMentions = createAppSelector(
    [selectDraft],
    draft => getDraftMentions(draft?.content) ?? [],
    memoizeOptions.weakMapShallow,
);

const selectFilteredMentions = createAppSelector(
    [selectDraftMentions],
    filterMentions,
    memoizeOptions.weakMapShallow,
);

export const selectDraftUserMentions = createAppSelector(
    [selectFilteredMentions],
    ({ userIds }) => userIds,
    memoizeOptions.weakMapShallow,
);

export const selectDraftSquadMentions = createAppSelector(
    [selectFilteredMentions],
    ({ squadIds }) => squadIds,
    memoizeOptions.weakMapShallow,
);

export const selectLocalAttachmentsDraftForMessage = createAppSelector(
    [state => state, selectDraft],
    (state, msg) =>
        msg?.attachmentIds.map(a => selectLocalAttachment(state, a)).filter(isAnyLocalAttachment),
    memoizeOptions.weakMapShallow,
);

const nextAttempt = (msg: UnsentChatMessage) => msg.backoffState?.nextAttempt;
const nextSendAttemptOrder = (a: UnsentChatMessage, b: UnsentChatMessage): number => {
    const hA = nextAttempt(a);
    const hB = nextAttempt(b);

    if (!hA) return !hB ? a.clientTxTs - b.clientTxTs : 1;
    if (!hB) return -1;

    return hA - hB;
};

export const selectFirstUnsentMessages = createAppSelector(
    [
        selectors.allChannelMaps,
        state => selectUnsentMessages(state),
    ],
    (cm, unsents) =>
        Array.from(hamtValues(cm))
            .filter(pcd => pcd.unsent.length > 0)
            .map(pcd => {
                for (const id of pcd.unsent) {
                    const msg = selectUnsentMessageById(unsents, id);
                    if (isErroredMsg(msg)) return;
                    if (isUnsentMsg(msg) && !msg.messageId) return msg;
                }
            })
            .filter(isUnsentMsg) // remove undefineds
            .sort(nextSendAttemptOrder),
    memoizeOptions.weakMapShallow,
);

export const selectNumberOfQueuedMessages = createAppSelector(
    [selectors.allChannelMaps],
    cm =>
        Array.from(hamtValues(cm))
            .reduce((acc, pcd) => acc + pcd.unsent.length, 0),
);

export const selectBondsForSquad = createAppSelector(
    [state => selectBonds(state), (_state, id: Optional<d.SquadId>) => id],
    (previews, id) => {
        return previews.filter(bp => (id && bp.squadIds?.includes(id)) ?? false);
    },
);

// Bodge to fix import cycle in tests
export const selectSquadLatestActivityDelayed = (state: RootState, squadId: Optional<d.SquadId>) =>
    selectSquadLatestActivity(state, squadId);

export const selectSquadIsUnread = createAppSelector(
    [
        selectors.squadReadTime,
        (_state: RootState, squadId: Optional<d.SquadId>) => squadId,
        selectSquadLatestActivityDelayed,
    ],
    (readTime, squadId, squadLatestActivity) => {
        if (!squadId || !squadLatestActivity) return false;

        return readTime === undefined || squadLatestActivity > readTime;
    },
);

export const selectSquadIsUnreadAsOf = createAppSelector(
    [
        selectors.squadReadTime,
        (_state: RootState, squadId: Optional<d.SquadId>) => squadId,
        selectSquadLatestActivityDelayed,
    ],
    (readTime, squadId, squadLatestActivity) => {
        if (!squadId || !squadLatestActivity) return false;

        if (readTime === undefined || squadLatestActivity > readTime) {
            return squadLatestActivity;
        }
        else {
            return false;
        }
    },
);

export const reducer = channelsSlice.reducer;

// Persistence.

export const richTextProviderStore = localStorageStore<string>(
    "channels/richTextProvider",
    32,
    undefined,
    { raw: true },
);

const stores = {
    _map1: idbStore<d.ChannelId, migrateV30.v29_PerChannelData>("channel-map-2", 14, 29),
    map: idbStore<d.ChannelId, cm.PerChannelData>("channel-map-3", 30),
    drafts: idbStore<d.ChannelId, DraftChatMessage>("channel-drafts-2", 14),
    pubSeqNos: idbStore<d.ChannelId, number>("published-sequence-numbers", 1),
    stagedSeqNos: idbStore<d.ChannelId, number>("staged-sequence-numbers", 1),
    maxSeqNos: idbStore<d.ChannelId, number>("maximum-sequence-numbers", 14),
    _squadUnreadStates: idbStore<d.SquadId, boolean>("unread-squad-states", 17, 18),
    squadReadTimes: idbStore<d.SquadId, number>("squad-read-times", 18),
    richTextProvider: richTextProviderStore,
};

const persist = (
    previousState: ChannelsState,
    currentState: ChannelsState,
): PersistenceUpdate[] => {
    if (previousState.updates == currentState.updates) {
        return [];
    }

    return currentState.updates;
};

const hydrate = async ({ idb, storage }: HydrateArgs) => {
    if (!idb || !storage) return getInitialState();

    const richTextProvider = providerFromString(storageRead(stores.richTextProvider));
    const [
        map,
        drafts,
        pubSeqNos,
        stagedSeqNos,
        maxSeqNos,
        squadReadTimes,
    ] = await Promise.all([
        fetchKeyedIdbStore(idb, stores.map),
        fetchKeyedIdbStore(idb, stores.drafts),
        fetchIdbStore(idb, stores.pubSeqNos),
        fetchIdbStore(idb, stores.stagedSeqNos),
        fetchIdbStore(idb, stores.maxSeqNos),
        fetchIdbStore(idb, stores.squadReadTimes),
    ]);

    return getInitialState({
        map,
        richTextProvider,
        drafts,
        pubSeqNos,
        stagedSeqNos,
        maxSeqNos,
        squadReadTimes,
    });
};

const migrate = async (tx: RWTransaction, oldVersion: number) => {
    if (oldVersion < 30) {
        await simpleIdbTranslate(
            tx,
            stores._map1,
            stores.map,
            migrateV30.translatePerChannelData,
        );
    }
};

export const persistor = {
    stores,
    hydrate,
    persist,
    migrate,
};
checkPersistor<ChannelsState>(persistor);
