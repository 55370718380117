import * as d from "@/domain/domain";
import { deepFreeze } from "@/misc/deepFreeze";
import { Optional } from "@/misc/types";
import { discriminatedUnionValueSchemaBuilder } from "@/misc/zod";
import { z } from "zod";
import { DeltaSummarySquadMetadata, SummaryRequestStatus } from "./intel";
import { PresenceModeSchema } from "./presence";

export const OrgOverviewSchema = z.object({
    id: d.orgIdSchema,
    name: z.string(),
    isPersonal: z.boolean(),
}).transform(({ isPersonal, ...overview }) => ({
    ...overview,
    personal: isPersonal,
}));
export type OrgOverview = z.infer<typeof OrgOverviewSchema>;

export const OrgOverviewOrDeletedSchema = z.discriminatedUnion("case", [
    discriminatedUnionValueSchemaBuilder("orgOverview", OrgOverviewSchema),
    discriminatedUnionValueSchemaBuilder("deletedId", d.orgIdSchema),
]);
export type OrgOverviewOrDeleted = z.infer<typeof OrgOverviewOrDeletedSchema>;

export const toOrgOverviewOrDeleted = (
    value: OrgOverview,
): OrgOverviewOrDeleted => ({
    case: "orgOverview",
    value: deepFreeze(value),
});
export const deletedOrgOverviewOrDeleted = (
    deletedId: d.OrgId,
): OrgOverviewOrDeleted => ({
    case: "deletedId",
    value: deletedId,
});

export const SquadOverviewSchema = z.object({
    id: d.squadIdSchema,
    name: z.string(),
    userIds: d.userSetSchema,
    invitedPersons: d.personSetSchema,
    invitedEmails: z.array(z.string()),
}).transform(({ invitedPersons, ...overview }) => ({
    ...overview,
    invitedPersonIds: invitedPersons,
}));
export type SquadOverview = z.infer<typeof SquadOverviewSchema>;

export const SquadOverViewOrDeletedSchema = z.discriminatedUnion("case", [
    discriminatedUnionValueSchemaBuilder("squadOverview", SquadOverviewSchema),
    discriminatedUnionValueSchemaBuilder("deletedId", d.squadIdSchema),
]);
export type SquadOverviewOrDeleted = z.infer<typeof SquadOverViewOrDeletedSchema>;

export const toSquadOverviewOrDeleted = (
    value: SquadOverview,
): SquadOverviewOrDeleted => ({
    case: "squadOverview",
    value: deepFreeze(value),
});
export const deletedSquadOverviewOrDeleted = (
    deletedId: d.SquadId,
): SquadOverviewOrDeleted => ({
    case: "deletedId",
    value: deletedId,
});

export const SquadActivitySchema = z.object({
    squadId: d.squadIdSchema,
    latestActivityAt: d.timestampSchema,
    dominantPresence: PresenceModeSchema.optional(),
});
export type SquadActivity = z.infer<typeof SquadActivitySchema>;

export const SquadActivityOrDeletedSchema = z.discriminatedUnion("case", [
    discriminatedUnionValueSchemaBuilder("activity", SquadActivitySchema),
    discriminatedUnionValueSchemaBuilder("deletedId", d.squadIdSchema),
]);
export type SquadActivityOrDeleted = z.infer<typeof SquadActivityOrDeletedSchema>;

export interface SquadSidebarSummaryInfo {
    status?: SummaryRequestStatus;
    summary?: string;
    metadata?: DeltaSummarySquadMetadata;
}

export function getOrgName(org: Optional<OrgOverview>): string {
    return org ? (org.personal ? "" : org.name) : "Unknown org";
}

export type SquadFilter = (u: SquadOverview) => boolean | undefined;

export type SquadSort = (a: SquadOverview, b: SquadOverview) => number;
export const squadSortByName: SquadSort = (a, b) => a.name.localeCompare(b.name);
