import { AudienceMember } from "@/domain/audience";
import { isSquadId } from "@/domain/domain";
import { DraftTarget, isBondCreationDraftTarget } from "@/domain/draftTarget";
import {
    AppView,
    AppViewStack,
    viewNames,
    viewStackSchema,
    viewStackUtils,
    viewUtils,
} from "@/domain/views";
import { Optional } from "@/misc/types";

/** Given an audience and a bond-creation bucket, find the most appropriate
 * view under which to view the new bond.
 *
 * `defaultViewForStack` will then provide the full viewstack to use.
 */
export const chooseMostRelevantViewForNewBond = (
    draftTarget: Optional<DraftTarget>,
    audience: AudienceMember[] = [],
) => {
    const squads = audience.filter(isSquadId);

    if (draftTarget && isBondCreationDraftTarget(draftTarget)) {
        const startingView = viewUtils.parseTopLevelName(draftTarget.viewName);

        if (!viewUtils.isSquad(startingView)) return viewUtils.inbox();

        if (squads.includes(startingView.id)) return startingView;
    }

    return squads.length > 0 ? viewUtils.squad(squads[0]) : viewUtils.inbox();
};

/** Guess what our current viewstack could be.
 *
 * On first load, we have no URL state, so we need to fill it in with something.
 *
 * @param path the current path
 * @param topLevelView the "current" top level view. n.b. this is *advisory*,
 * as it may be Just Wrong
 * @return a viewstack we can use for the current view
 */
export const guessViewStack = (
    currentView: Optional<AppView>,
    topLevelView: AppView,
): Optional<AppViewStack> => {
    if (!currentView) return;

    switch (currentView.view) {
        case viewNames.inbox:
        case viewNames.discover:
        case viewNames.mySquads:
        case viewNames.squad: {
            const stack = viewStackUtils.defaultStackForView(currentView);
            if (!stack) throw new Error("Incompatible definitions for top-level views");
            return stack;
        }
        case viewNames.singleBond:
        case viewNames.newBond:
        case viewNames.squadSettings:
        case viewNames.mediaSettings:
        case viewNames.profileSettings:
        case viewNames.reportIssue:
        case viewNames.inviteRedemption: {
            const startingStack = viewStackUtils.defaultStackForView(topLevelView);
            if (!startingStack) throw new Error(`No default stack for view ${topLevelView}`);
            return viewStackSchema.parse([...startingStack, currentView]);
        }
        case viewNames.squadSettingsCreate:
            return viewStackUtils.defaultStack;
        case viewNames.squadSettingsInvite:
        case viewNames.squadSettingsRename:
        case viewNames.squadSettingsRemove: {
            const squadSettingsStack = viewStackUtils.defaultStackForView(
                viewUtils.squadSettings(currentView.id),
            );
            if (!squadSettingsStack) throw new Error("No default stack for squad settings");
            return squadSettingsStack;
        }
    }
};
