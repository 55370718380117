import { BlockBlobClient, newPipeline } from "@azure/storage-blob";
import { nanoid } from "@reduxjs/toolkit";
import * as faceapi from "@vladmandic/face-api";
import modelsUrl from "@vladmandic/face-api/model/ssd_mobilenetv1_model-weights_manifest.json?url";
import classNames from "classnames";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";

import { AvatarCropRectangle } from "@/api/users";
import { AvatarImage } from "@/components/gui/Avatar.tsx";
import AvatarCapture from "@/components/gui/AvatarCapture.tsx";
import { BlockBlobClientFactory, FileStashContext } from "@/components/managers/AttachmentManager";
import * as d from "@/domain/domain.ts";
import { DeviceGroup, DeviceKind } from "@/domain/mediaDevices.ts";
import { getPersonFromUser, UploadableAvatarImage } from "@/domain/users";
import { selectCurrentOrgId, selectCurrentUserId } from "@/features/auth";
import {
    DevicePermissions,
    selectAllDeviceIds,
    selectCurrentDevice,
    selectDeviceByIdAndKind,
    selectDevicePermissions,
    setCurrentDevice,
} from "@/features/mediaDevices.ts";
import {
    clearUploadableAvatar,
    completeAvatarUploadThunk,
    deleteAvatar,
    getAvatarUploadCredentialsThunk,
    selectUploadableAvatar,
    selectUser,
} from "@/features/users";
import { useSelfInterest } from "@/hooks/interest/useInterest";
import useEnumeratedDevices from "@/hooks/media/useEnumeratedDevices.ts";
import { useConnectedEffect } from "@/hooks/useConnectedEffect";
import useSelectorArgs from "@/hooks/useSelectorArgs.ts";
import { fileNamePreparer } from "@/misc/attachments";
import log from "@/misc/log";
import { isMobileBrowser } from "@/misc/mobile.ts";
import { useAppDispatch, useAppSelector } from "@/store/redux";

const blockBlobClientGenerator = (url: string) => {
    return new BlockBlobClient(url, newPipeline());
};

async function detectFaceAndUpload(
    faceApiLoaded: React.MutableRefObject<boolean>,
    cropRectangleRef: React.MutableRefObject<AvatarCropRectangle>,
    fileStash: Map<string, File>,
    file: File,
    dispatch: ReturnType<typeof useAppDispatch>,
    orgId: d.OrgId,
    userId: d.UserId,
) {
    if (!faceApiLoaded.current) {
        await faceapi.loadSsdMobilenetv1Model(modelsUrl);
        faceApiLoaded.current = true;
    }

    const img = document.createElement("img");
    img.src = URL.createObjectURL(file);
    try {
        await new Promise(resolve => {
            img.onload = resolve;
        });

        const detection = await faceapi
            .detectSingleFace(img, new faceapi.SsdMobilenetv1Options());
        if (detection && detection.box) {
            cropRectangleRef.current = makeFaceRectangle(detection);
        }
        else {
            cropRectangleRef.current = { x: 0, y: 0, width: 1, height: 1 };
        }

        const localId = nanoid();
        fileStash.set(localId, file);

        try {
            const receivedCredentials = dispatch(getAvatarUploadCredentialsThunk({
                uploadableAvatarId: localId,
                properties: {
                    fileName: file.name,
                    fileSize: file.size,
                    mimeType: file.type,
                    dimensions: {
                        width: img.width,
                        height: img.height,
                    },
                },
                ownership: {
                    orgId: orgId,
                    uploaderId: userId,
                },
            }));
            await receivedCredentials;
        }
        catch (e) {
            log.error("Problem when getting attachment upload credentials", e);
        }
    }
    finally {
        URL.revokeObjectURL(img.src);
    }
}

function makeFaceRectangle(detection: faceapi.FaceDetection) {
    const faceRectangle = {
        x: detection.box.x,
        y: detection.box.y,
        width: detection.box.width,
        height: detection.box.height,
    };

    // margin values are based on face dimensions returned from face detection
    const xMargin = 0.6;
    const yMargin = 0.35;
    faceRectangle.x -= faceRectangle.width * xMargin;
    faceRectangle.y -= faceRectangle.height * yMargin;
    faceRectangle.width += 2 * xMargin * faceRectangle.width;
    faceRectangle.height += 2 * yMargin * faceRectangle.height;

    if (faceRectangle.x < 0) {
        faceRectangle.x = 0;
    }
    if (faceRectangle.y < 0) {
        faceRectangle.y = 0;
    }
    if (faceRectangle.x + faceRectangle.width > detection.imageWidth) {
        faceRectangle.width = detection.imageWidth - faceRectangle.x;
    }
    if (faceRectangle.y + faceRectangle.height > detection.imageHeight) {
        faceRectangle.height = detection.imageHeight - faceRectangle.y;
    }

    // make sure it's square
    faceRectangle.width = Math.min(faceRectangle.width, faceRectangle.height);
    faceRectangle.height = faceRectangle.width;

    return {
        x: faceRectangle.x / detection.imageWidth,
        y: faceRectangle.y / detection.imageHeight,
        width: faceRectangle.width / detection.imageWidth,
        height: faceRectangle.height / detection.imageHeight,
    };
}

enum ControlState {
    Idle,
    Uploading,
    TakingSelfie,
    ConfirmingSelfie,
    ConfirmingFile,
}

function WebcamInputSelector(): React.JSX.Element {
    const deviceIds = useSelectorArgs(selectAllDeviceIds, DeviceKind.VideoInput);
    const selectedDevice = useSelectorArgs(selectCurrentDevice, DeviceKind.VideoInput);
    const [isVisible, setIsVisible] = useState(false);

    const dropdownClassNames = classNames("c-avatar-dropdown__options", isVisible && "is-visible");

    const moreButtonToggle = useCallback(() => {
        setIsVisible(state => !state);
    }, []);

    return (
        <div className="c-avatar-dropdown">
            <button
                className="c-avatar-btn c-avatar-btn--more"
                title="Camera options"
                onClick={moreButtonToggle}
            >
                Camera options
            </button>
            <div className={dropdownClassNames}>
                {deviceIds.map(deviceId => (
                    <DeviceButton
                        deviceId={deviceId}
                        isSelected={deviceId === selectedDevice}
                        key={deviceId}
                    />
                ))}
            </div>
        </div>
    );
}

function DeviceButton(
    { deviceId, isSelected }: { deviceId: string; isSelected: boolean; },
): React.JSX.Element {
    const dispatch = useAppDispatch();

    const device = useSelectorArgs(selectDeviceByIdAndKind, deviceId, DeviceKind.VideoInput);
    const cNames = classNames("c-avatar-dropdown__option", isSelected && "is-selected");

    const selectDevice = () => {
        dispatch(setCurrentDevice(DeviceKind.VideoInput, deviceId));
    };

    return (
        <button className={cNames} onClick={selectDevice}>
            {device?.label || "Unknown device"}
        </button>
    );
}

interface SelfieCaptureControlProps {
    onPhotoTaken: (photo: HTMLVideoElement) => void;
    onCancel: () => void;
}

function SelfieCaptureControl(
    { onPhotoTaken, onCancel }: SelfieCaptureControlProps,
): React.JSX.Element {
    const permissions = useSelectorArgs(selectDevicePermissions, DeviceKind.VideoInput);

    useEnumeratedDevices(DeviceGroup.Video);

    function getPermissionString(permissions: DevicePermissions) {
        switch (permissions) {
            case DevicePermissions.Denied:
                return "Permissions denied";
        }
        return "";
    }

    const uploadClasses = classNames("c-profile-upload");

    return (
        <figure className={uploadClasses}>
            {permissions !== DevicePermissions.Granted ?
                <>{getPermissionString(permissions)}</> :
                (
                    <AvatarCapture
                        onPhotoTaken={onPhotoTaken}
                    />
                )}
            <button
                className="c-avatar-btn c-avatar-btn--close"
                title="Close and return"
                onClick={onCancel}
            >
                Close and return
            </button>
            <WebcamInputSelector />
        </figure>
    );
}

interface IdleStateProps {
    onFileSelected: (file: File) => void;
    onDeleteAvatar: () => void;
    onEnterSelfieMode: () => void;
}

const IdleState = ({ onFileSelected, onDeleteAvatar, onEnterSelfieMode }: IdleStateProps) => {
    const userId = useAppSelector(selectCurrentUserId);
    const user = useSelectorArgs(selectUser, userId);
    const person = getPersonFromUser(user);
    const userPicture = person?.picture;

    const fileElementRef = useRef<HTMLInputElement>(null);

    const uploadClasses = classNames("c-profile-upload");

    const onFileInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length != 1) {
            e.target.value = "";
            return;
        }

        const file = e.target.files.item(0);
        if (!file) {
            log.warn("File missing: skipping");
            return;
        }

        onFileSelected(file);
    }, [onFileSelected]);

    const profileOptionClasses = classNames("c-profile__options");

    if (userPicture) {
        return (
            <figure className={uploadClasses}>
                <AvatarImage
                    avatar={userPicture}
                    className="c-profile-upload__image"
                />
                <button
                    className="c-avatar-btn c-avatar-btn--delete"
                    title="Delete image"
                    onClick={onDeleteAvatar}
                >
                    Delete image
                </button>
            </figure>
        );
    }
    else {
        return (
            <div className={profileOptionClasses}>
                <button
                    className="c-btn-profile"
                    onClick={() => fileElementRef?.current?.click()}
                >
                    <input
                        type="file"
                        ref={fileElementRef}
                        id="files"
                        multiple={false}
                        onChange={onFileInput}
                        style={{ display: "none" }}
                        accept="image/jpeg, image/png"
                    />
                    <div className="c-btn-profile__icon c-btn-profile__icon--portrait">
                    </div>
                    Upload portrait
                </button>
                <button className="c-btn-profile" onClick={onEnterSelfieMode}>
                    <div className="c-btn-profile__icon c-btn-profile__icon--selfie">
                    </div>
                    Take selfie
                </button>
            </div>
        );
    }
};

interface ConfirmPhotoStateProps {
    capturedImage?: string;
    onUploadPhoto: () => void;
    onDiscardPhoto: () => void;
}

const ConfirmPhotoState = (
    { capturedImage, onUploadPhoto, onDiscardPhoto }: ConfirmPhotoStateProps,
) => {
    const uploadClasses = classNames("c-profile-upload");

    return (
        <figure className={uploadClasses}>
            {capturedImage && (
                <img
                    src={capturedImage}
                    className="c-profile-upload__image"
                />
            )}
            <button
                className="c-avatar-btn-action"
                title="Use photo"
                onClick={onUploadPhoto}
            >
                Use photo
            </button>
            <button
                className="c-avatar-btn c-avatar-btn--return"
                title="Take another photo"
                onClick={onDiscardPhoto}
            >
                Take another photo
            </button>
        </figure>
    );
};

interface UploadingPhotoStateProps {
    capturedImage?: string;
}

const UploadingPhotoState = ({ capturedImage }: UploadingPhotoStateProps) => {
    const uploadClasses = classNames("c-profile-upload");

    return (
        <figure className={uploadClasses}>
            {capturedImage && (
                <img
                    src={capturedImage}
                    className="c-profile-upload__image"
                />
            )}
            <button
                className="c-avatar-btn-action c-avatar-btn-action--processing"
                title="Processing photo"
            >
                <div className="cp-spinner-button">
                    <div className="cp-spinner-button__icon cp-spinner-button__icon--outer">
                    </div>
                    <div className="cp-spinner-button__icon cp-spinner-button__icon--inner">
                    </div>
                </div>
            </button>
        </figure>
    );
};

export default function AvatarUploadControls(
    { clientFactory }: { clientFactory?: BlockBlobClientFactory; },
): React.JSX.Element {
    const dispatch = useAppDispatch();
    const isMobile = isMobileBrowser();
    const blobClientFactory = clientFactory || blockBlobClientGenerator;
    const fileStash = useContext(FileStashContext);
    const userId = useAppSelector(selectCurrentUserId);
    const user = useSelectorArgs(selectUser, userId);
    const orgId = useAppSelector(selectCurrentOrgId);
    const cropRectangleRef = useRef({ x: 0, y: 0, width: 0, height: 0 });
    const faceApiLoaded = useRef(false);
    const [oldBlobId, setOldBlobId] = useState<string | undefined>(undefined);
    const [state, setState] = useState(ControlState.Idle);
    const [photoCanvas, setPhotoCanvas] = useState<HTMLCanvasElement | undefined>(undefined);
    const [fileToUpload, setFileToUpload] = useState<File | undefined>(undefined);
    const uploadableAvatar = useAppSelector(selectUploadableAvatar);
    const isAvatarBlobUpdating = user?.picture?.blobId && user?.picture?.blobId === oldBlobId;
    const [capturedImage, _setCapturedImage] = useState<string | undefined>(undefined);
    const [shouldRevokeCapturedImage, setShouldRevokeCapturedImage] = useState<boolean>(false);

    useSelfInterest();

    const setCapturedImage = useCallback((image: string | undefined, revokeOnChange: boolean) => {
        if (shouldRevokeCapturedImage && capturedImage) {
            URL.revokeObjectURL(capturedImage);
        }
        _setCapturedImage(image);
        setShouldRevokeCapturedImage(revokeOnChange);
    }, [capturedImage, shouldRevokeCapturedImage]);

    const onFileSelected = useCallback((file: File) => {
        if (!userId || !orgId) {
            log.error("Starting avatar upload: UserId or OrgId missing");
            return;
        }
        setCapturedImage(URL.createObjectURL(file), true);
        setFileToUpload(file);
        setState(ControlState.ConfirmingFile);
    }, [orgId, userId, setCapturedImage]);

    useEffect(() => {
        if (!isAvatarBlobUpdating) {
            setState(ControlState.Idle);
        }
    }, [isAvatarBlobUpdating]);

    const finishUploadableAvatar = useCallback(() => {
        // This may become more complex in the future, with different states to
        // represent the upload completing, or failing at different stages.
        dispatch(clearUploadableAvatar());
    }, [dispatch]);

    const completeAvatarImageUpload = useCallback((uai: UploadableAvatarImage) => () => {
        setOldBlobId(user?.picture?.blobId);
        const attachmentCompleted = dispatch(completeAvatarUploadThunk({
            uploadableAvatarId: uai.localId,
            blobId: uai.credentials!.blobId,
            completerId: uai.uploaderId,
            cropRectangle: cropRectangleRef.current,
        }));

        const successHandler = () => {
            log.info("Avatar image upload completed");
        };
        const failureHandler = (e: any) => {
            // No retry mechanism for now
            setOldBlobId(undefined);
            log.error("Completing avatar image upload failed", e);
            setState(ControlState.Idle);
        };

        attachmentCompleted
            .unwrap()
            .then(
                successHandler,
                failureHandler,
            )
            .catch(
                e => {
                    log.error("Error in completing avatar image upload:", e);
                    setState(ControlState.Idle);
                },
            )
            .finally(
                () => {
                    fileStash.delete(uai.localId);
                    finishUploadableAvatar();
                },
            );
    }, [dispatch, fileStash, finishUploadableAvatar, cropRectangleRef, user]);

    const uploadPhoto = useCallback(() => {
        if (!userId || !orgId) {
            log.error("Starting avatar upload: UserId or OrgId missing");
            setState(ControlState.Idle);
            return;
        }

        const uploadFunction = async (file: File) => {
            detectFaceAndUpload(
                faceApiLoaded,
                cropRectangleRef,
                fileStash,
                file,
                dispatch,
                orgId,
                userId,
            )
                .catch(
                    e => {
                        log.error("error in detectFaceAndUpload", e);
                        setState(ControlState.Idle);
                    },
                );
        };

        if (state === ControlState.ConfirmingSelfie) {
            if (!photoCanvas) {
                return;
            }

            setState(ControlState.Uploading);

            photoCanvas.toBlob(blob => {
                if (!blob) {
                    return;
                }
                const file = new File([blob], "selfie.jpeg", { type: "image/jpeg" });
                uploadFunction(file);
            });
        }
        else if (state === ControlState.ConfirmingFile && fileToUpload) {
            setState(ControlState.Uploading);
            uploadFunction(fileToUpload);
        }
        else {
            setState(ControlState.Idle);
        }
    }, [dispatch, userId, orgId, fileStash, photoCanvas, fileToUpload, state]);

    const discardPhoto = useCallback(() => {
        setPhotoCanvas(undefined);
        if (state === ControlState.ConfirmingSelfie) {
            setState(ControlState.TakingSelfie);
        }
        else {
            setState(ControlState.Idle);
        }
    }, [state]);

    const onSelfieTaken = useCallback((photo: HTMLVideoElement) => {
        try {
            const canvas = document.createElement("canvas");
            canvas.width = photo.videoWidth;
            canvas.height = photo.videoHeight;
            const ctx = canvas.getContext("2d");
            if (!ctx) {
                throw new Error("Unable to get 2d context.");
            }
            ctx.drawImage(photo, 0, 0, canvas.width, canvas.height);
            setPhotoCanvas(canvas);
            const dataUrl = canvas.toDataURL("image/jpeg");
            setCapturedImage(dataUrl, false);
            setState(ControlState.ConfirmingSelfie);
        }
        catch (e) {
            log.error("Error in taking selfie", e);
            setState(ControlState.Idle);
        }
    }, [setCapturedImage]);

    const cancelTakingSelfie = useCallback(() => {
        setState(ControlState.Idle);
    }, []);

    useConnectedEffect(() => {
        if (!uploadableAvatar) {
            return;
        }

        const file = fileStash.get(uploadableAvatar.localId);
        if (!file) {
            log.error("File missing from stash");
            finishUploadableAvatar();
            return;
        }

        // Copied straight from AttachmentManager.tsx, should consider DRY
        // TODO: maybe AttachmentManager is really BlobUploadManager?
        const blockBlobClient = blobClientFactory(
            uploadableAvatar.credentials.url,
        );
        const dataUpload = blockBlobClient.uploadData(file, {
            maxSingleShotSize: 4 * 1024 * 1024,
            blobHTTPHeaders: {
                blobContentType: file.type,
                blobCacheControl: "max-age=31536000, immutable",
                blobContentDisposition: `attachment;filename="${fileNamePreparer(file.name)}"`,
            },
        });

        const uploadAvatarFailureHandler = (e: any) => {
            log.error("Failed to upload avatar image file", e);
            finishUploadableAvatar();
            setState(ControlState.Idle);
        };

        dataUpload
            .then(
                completeAvatarImageUpload(uploadableAvatar),
                uploadAvatarFailureHandler,
            );
    }, [
        uploadableAvatar,
        blobClientFactory,
        completeAvatarImageUpload,
        fileStash,
        finishUploadableAvatar,
    ]);

    const enterSelfieMode = useCallback(() => {
        setState(ControlState.TakingSelfie);
    }, []);

    const deleteCurrentAvatar = useCallback(() => {
        if (userId) {
            dispatch(deleteAvatar({ userId }));
        }
    }, [dispatch, userId]);

    const profileClasses = classNames("c-profile", {
        "c-profile--desktop": !isMobile,
    });

    if (!userId) {
        return <></>;
    }

    return (
        <fieldset className={profileClasses}>
            {state === ControlState.Idle && (
                <IdleState
                    onEnterSelfieMode={enterSelfieMode}
                    onDeleteAvatar={deleteCurrentAvatar}
                    onFileSelected={onFileSelected}
                />
            )}
            {state === ControlState.TakingSelfie && (
                <SelfieCaptureControl
                    onPhotoTaken={onSelfieTaken}
                    onCancel={cancelTakingSelfie}
                />
            )}
            {(state === ControlState.ConfirmingSelfie || state === ControlState.ConfirmingFile) && (
                <ConfirmPhotoState
                    capturedImage={capturedImage}
                    onDiscardPhoto={discardPhoto}
                    onUploadPhoto={uploadPhoto}
                />
            )}
            {state === ControlState.Uploading && (
                <UploadingPhotoState capturedImage={capturedImage} />
            )}
        </fieldset>
    );
}
