import * as d from "@/domain/domain";
import { maybeValidateUUID } from "@/domain/uuid";
import { memoizeOptions } from "@/features/selectors";
import { andThen } from "@/misc/utils";
import { Params, useParams } from "react-router-dom";
import { createSelector } from "reselect";

export interface UrlParams {
    attachmentIdx?: number;
    attachmentVersion?: number;
    bondId?: d.BondId;
    callId?: d.CallId;
    messageId?: d.MessageId;
    squadId?: d.SquadId;
    userId?: d.UserId;
    opaqueInviteCode?: string;
    settingsSquadId?: d.SquadId;
}

// FIXME: doesn't need to use `createSelector`, could just use bare `weakMapMemoize`
const selectAddressParams = createSelector(
    [(params: Readonly<Params<string>>) => params],
    (params): UrlParams => ({
        attachmentIdx: andThen(params.attachmentIdx, parseInt),
        attachmentVersion: andThen(params.attachmentVersion, parseInt),
        bondId: andThen(andThen(params.bondId, maybeValidateUUID), d.fromRawBondId),
        callId: andThen(andThen(params.callId, maybeValidateUUID), d.fromRawCallId),
        messageId: andThen(andThen(params.messageId, maybeValidateUUID), d.fromRawMessageId),
        squadId: andThen(andThen(params.squadId, maybeValidateUUID), d.fromRawSquadId),
        userId: andThen(andThen(params.userId, maybeValidateUUID), d.fromRawUserId),
        opaqueInviteCode: params.inviteCode,
    }),
    {
        ...memoizeOptions.lruShallowArgs,
        ...memoizeOptions.weakMapShallow,
    },
);

export const useAddressParams = () => selectAddressParams(useParams());

export default useAddressParams;
