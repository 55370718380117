import classNames from "classnames";
import { Ref, useMemo } from "react";

import * as d from "@/domain/domain";
import {
    getCallId,
    getMessageCallStatus,
    getMsgTs,
    InviteRedeemedMessage,
    MessageCallContext,
} from "@/domain/messages";
import { selectUser } from "@/features/users";
import { useInViewInterest } from "@/hooks/interest/useInViewInterest";
import useMergedRefs from "@/hooks/useMergedRefs";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { Optional } from "@/misc/types";
import Avatar from "../gui/Avatar";
import TimeAgo from "../gui/TimeAgo";

export interface InviteRedeemedMessageViewProps {
    msg: InviteRedeemedMessage;
    messageContentRef?: Ref<HTMLDivElement>;
    currentCallId?: d.CallId;
}

export const InviteRedeemedMessageView = (
    props: InviteRedeemedMessageViewProps,
): React.JSX.Element => {
    const { msg, messageContentRef, currentCallId } = props;
    const { actorId, inviterId } = msg;

    const inViewRef = useInViewInterest({
        userIds: [actorId, inviterId],
        callIds: currentCallId,
    });
    const ref = useMergedRefs(messageContentRef, inViewRef);

    const ts = useMemo(() => getMsgTs(msg), [msg]);

    const callId = getCallId(msg);
    const callStatus = getMessageCallStatus(callId, currentCallId);

    const actor = useSelectorArgs(selectUser, actorId);
    const inviter = useSelectorArgs(selectUser, inviterId);
    const textContent = `joined by invitation from ${inviter?.name}`;

    if (!actor || !inviter) {
        return (
            <div className="c-message c-message--unknown" ref={ref}>
                {`Unknown user ${actorId} or ${inviterId}`}
            </div>
        );
    }

    return (
        <InviteRedeemedMessageViewInternal
            actorId={actorId}
            actorName={actor?.name}
            ts={ts}
            content={textContent}
            callStatus={callStatus}
            messageContentRef={ref}
        />
    );
};

interface InviteRedeemedMessageViewInternalProps {
    actorId: Optional<d.UserId>;
    actorName: Optional<string>;
    ts: Optional<Date>;
    content: string;
    callStatus: MessageCallContext;
    messageContentRef: Optional<Ref<HTMLDivElement>>;
}

const InviteRedeemedMessageViewInternal = (
    props: InviteRedeemedMessageViewInternalProps,
): React.JSX.Element => {
    const {
        actorId,
        actorName,
        ts,
        content,
        messageContentRef,
        callStatus,
    } = props;

    const isEnded = callStatus === MessageCallContext.EndedCall;
    const isLive = callStatus === MessageCallContext.LiveCall;
    const containerClassNames = classNames("c-message", {
        "c-message--live": isLive,
        "c-message--ended": isEnded,
    });
    const actionClassNames = classNames("c-message__action", {
        "c-message__action--live": isLive,
        "c-message__action--ended": isEnded,
    });

    return (
        <div className={containerClassNames}>
            {!!actorId && (
                <Avatar
                    id={actorId}
                    showPresence={false}
                    hidden={!actorName}
                    size="system-message"
                />
            )}
            <div className="c-message__content">
                {(actorName || ts) && (
                    <div className="c-message__meta c-message__meta--event">
                        {actorName &&
                            (
                                <span className="c-message__author">
                                    {actorName}
                                </span>
                            )}
                        {ts && (
                            <span className="c-message__timestamp">
                                <TimeAgo from={ts?.valueOf() || 0} live={true} precise={true} />
                            </span>
                        )}
                    </div>
                )}
                <div ref={messageContentRef} className={actionClassNames}>
                    {content}
                </div>
            </div>
        </div>
    );
};
