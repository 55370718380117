import { ConnectError, createClient } from "@connectrpc/connect";

import * as bonds_pb from "../../gen/proto/bonds/bonds_pb";
import { ChatService } from "../../gen/proto/chats/chats_connect";
import * as chats_pb from "../../gen/proto/chats/chats_pb";

import bondService from "@/api/bonds";
import { MessageType } from "@/api/chats";
import { streamHandler } from "@/api/stream";
import { transport } from "@/api/transport";
import {
    bigintToNumber,
    fromProtoBlobId,
    fromProtoCallId,
    fromProtoChannelId,
    fromProtoMessageId,
    fromProtoTimestamp,
    fromProtoUserId,
    pbAudienceOpSet,
    pbBlobId,
    pbBlobSet,
    pbBondId,
    pbChannelId,
    pbMessageId,
    pbOrgId,
    pbTimestamp,
    pbUserId,
} from "@/api/util";
import {
    CredentialedAttachment,
    OfficialAttachment,
    officialAttachmentSchema,
} from "@/domain/attachments";
import { defaultBackoffUpdater } from "@/domain/backoff";
import { BlobCredentials, ExpiringSasUrl, ExpiringSasUrlSchema } from "@/domain/blobs";
import { getContent_Mentions, SanitisedChatContent } from "@/domain/chatContent";
import * as d from "@/domain/domain";
import { audienceOpForMention } from "@/domain/mentions";
import {
    AnyOfficialMessage,
    AnyUnsentLocalMessage,
    BondCreatedMessage,
    BondCreatedMessageContentSchema,
    CallEndedMessage,
    CallEndMessageContentSchema,
    CallStartMessage,
    CallStartMessageContentSchema,
    IncompatibleMessage,
    InviteRedeemedMessage,
    InviteRedeemedMessageContentSchema,
    InviteSentMessage,
    InviteSentMessageContentSchema,
    OfficialChatMessage,
    OfficialMessage,
    OfficialMessageType,
    SquadAccessGrantedMessage,
    SquadAccessGrantedMessageContentSchema,
    SquadAccessRevokedMessage,
    SquadAccessRevokedMessageContentSchema,
    TitleChangeMessage,
    TitleChangeMessageContentSchema,
    toErroredMsg,
    UnsentChatMessage,
    UserAccessGrantedMessage,
    UserAccessGrantedMessageContentSchema,
    UserAccessRevokedMessage,
    UserAccessRevokedMessageContentSchema,
} from "@/domain/messages";
import { deepFreeze } from "@/misc/deepFreeze";
import log from "@/misc/log";
import { Mutable } from "@/misc/types";
import { z } from "zod";
import { parseBufMessage } from "./parser";
import {
    optionalifyArray,
    optionalifyBoolean,
    optionalifyString,
    updateValue,
    updateValues,
} from "./zod";

export const service = createClient(ChatService, transport);
export default service;

function getAttachmentId(attachment: chats_pb.Attachment): d.BlobId {
    return fromProtoBlobId(attachment.id);
}

function translateAttachment(attachment: chats_pb.Attachment): OfficialAttachment {
    return deepFreeze(officialAttachmentSchema.parse(parseBufMessage(attachment)));
}

// Re-export proto types for use in the rest of the frontend
export { MessageType } from "../../gen/proto/domain/domain_pb";

type MessageWithAttachments<T> = [T, OfficialAttachment[]];
type MessagesBundle<T> = { msgs: T[]; attachments?: OfficialAttachment[]; };

export const flattenMsgAndAttachments = <T, S extends string>(
    input: MessageWithAttachments<T>[],
    f: (t: T) => S,
): MessagesBundle<T> => ({
    msgs: input.map(([msg, _]) => msg)
        .filterLatest(f),
    attachments: input.flatMap(([_, attachments]) => attachments)
        .filterLatest(a => a.id),
});

export type OfficialMessageAndAttachments = MessageWithAttachments<AnyOfficialMessage>;
export type OfficialMessagesBundle = MessagesBundle<AnyOfficialMessage> & {
    channelId: d.ChannelId;
};

export const flattenOfficialMessageAndAttachments = (
    data: Readonly<OfficialMessageAndAttachments[]>,
    channelId: d.ChannelId,
): OfficialMessagesBundle => ({
    channelId,
    ...flattenMsgAndAttachments(data as Mutable<typeof data>, msg => msg.id),
});

function fillOutPartialMessage(
    partialMsg: Omit<OfficialMessage, "type">,
    message: chats_pb.ChatMessageCore,
): AnyOfficialMessage {
    switch (message.messageType) {
        case MessageType.CHAT:
            throw new Error(`Don't pass a CHAT message to this function`);
        case MessageType.CALL_START: {
            const csm: CallStartMessage = Object.assign(
                partialMsg,
                callStartMessageContentParser.parse(message.content),
                { type: OfficialMessageType.CallStart as const },
            );
            return csm;
        }
        case MessageType.CALL_END: {
            const cem: CallEndedMessage = Object.assign(
                partialMsg,
                callEndMessageContentParser.parse(message.content),
                { type: OfficialMessageType.CallEnd as const },
            );
            return cem;
        }
        case MessageType.SQUAD_ACCESS_GRANTED: {
            const sagm: SquadAccessGrantedMessage = Object.assign(
                partialMsg,
                squadAccessGrantedMessageContentParser.parse(message.content),
                { type: OfficialMessageType.SquadAccessGranted as const },
            );
            return sagm;
        }
        case MessageType.USER_ACCESS_GRANTED: {
            const uagm: UserAccessGrantedMessage = Object.assign(
                partialMsg,
                userAccessGrantedMessageContentParser.parse(message.content),
                { type: OfficialMessageType.UserAccessGranted as const },
            );
            return uagm;
        }
        case MessageType.TITLE_CHANGE: {
            const tcm: TitleChangeMessage = Object.assign(
                partialMsg,
                titleChangeMessageContentParser.parse(message.content),
                { type: OfficialMessageType.TitleChange as const },
            );
            return tcm;
        }
        case MessageType.USER_ACCESS_REVOKED: {
            const uarm: UserAccessRevokedMessage = Object.assign(
                partialMsg,
                userAccessRevokedMessageContentParser.parse(message.content),
                { type: OfficialMessageType.UserAccessRevoked as const },
            );
            return uarm;
        }
        case MessageType.SQUAD_ACCESS_REVOKED: {
            const sarm: SquadAccessRevokedMessage = Object.assign(
                partialMsg,
                squadAccessRevokedMessageContentParser.parse(message.content),
                { type: OfficialMessageType.SquadAccessRevoked as const },
            );
            return sarm;
        }
        case MessageType.BOND_CREATED: {
            const bcm: BondCreatedMessage = Object.assign(
                partialMsg,
                bondCreatedMessageContentParser.parse(message.content),
                { type: OfficialMessageType.BondCreated as const },
            );
            return bcm;
        }
        case MessageType.INVITE_SENT: {
            const ism: InviteSentMessage = Object.assign(
                partialMsg,
                inviteSentMessageContentParser.parse(message.content),
                { type: OfficialMessageType.InviteSent as const },
            );
            return ism;
        }
        case MessageType.INVITE_REDEEMED: {
            const irm: InviteRedeemedMessage = Object.assign(
                partialMsg,
                inviteRedeemedMessageContentParser.parse(message.content),
                { type: OfficialMessageType.InviteRedeemed as const },
            );
            return irm;
        }
        case MessageType.UNSPECIFIED:
            throw new Error("message type is unspecified");
        default: {
            const im: IncompatibleMessage = Object.assign(
                partialMsg,
                { type: OfficialMessageType.Incompatible as const },
            );
            return im;
        }
    }
}

function translateOfficialMessage(
    msg: chats_pb.ChatMessage,
): OfficialMessageAndAttachments {
    const message = msg.message;

    if (!message) {
        throw new Error(`message innards are undefined`);
    }

    const partialMsg: Omit<OfficialMessage, "type"> = {
        id: fromProtoMessageId(message.id),
        channelId: fromProtoChannelId(message.channelId),
        serverRxTs: fromProtoTimestamp(message.serverRxTs),
        sequenceNumber: bigintToNumber(message.sequenceNumber ?? BigInt(0)),
    };

    if (message.messageType === MessageType.CHAT) {
        const ocm: OfficialChatMessage = Object.assign(
            partialMsg,
            {
                type: OfficialMessageType.Chat as const,
                senderId: fromProtoUserId(message.senderId),
                clientTxTs: fromProtoTimestamp(message.clientTxTs),
                content: parseChatMessageContent(message.content),
                callId: message.callId && fromProtoCallId(message.callId),
                attachmentIds: msg.attachments.map(getAttachmentId),
            },
        );
        const attachments = msg.attachments.map(translateAttachment);
        return [deepFreeze(ocm), attachments];
    }
    else {
        const msg = fillOutPartialMessage(partialMsg, message);
        return [deepFreeze(msg), []];
    }
}

function parseChatMessageContent(json: string): SanitisedChatContent {
    const content = JSON.parse(json);
    // TODO: add validation by switching to Zod, like the parsers below.
    return content;
}

const callStartMessageContentParser = z.string()
    .transform(v => JSON.parse(v))
    .transform(updateValue("callId", d.fromRawCallId))
    .pipe(CallStartMessageContentSchema);

const callEndMessageContentParser = z.string()
    .transform(v => JSON.parse(v))
    .transform(updateValue("callId", d.fromRawCallId))
    .transform(optionalifyArray("participantIds"))
    .transform(updateValues("participantIds", d.fromRawUserId))
    .pipe(CallEndMessageContentSchema);

const squadAccessGrantedMessageContentParser = z.string()
    .transform(v => JSON.parse(v))
    .transform(updateValue("squadId", d.fromRawSquadId))
    .transform(updateValue("actorId", d.fromRawUserId))
    .pipe(SquadAccessGrantedMessageContentSchema);

const userAccessGrantedMessageContentParser = z.string()
    .transform(v => JSON.parse(v))
    .transform(updateValue("recipientId", d.fromRawUserId))
    .transform(updateValue("actorId", d.fromRawUserId))
    .pipe(UserAccessGrantedMessageContentSchema);

const titleChangeMessageContentParser = z.string()
    .transform(v => JSON.parse(v))
    .transform(optionalifyString("oldTitle"))
    .transform(optionalifyString("newTitle"))
    .transform(updateValue("editorId", d.fromRawUserId))
    .pipe(TitleChangeMessageContentSchema);

const userAccessRevokedMessageContentParser = z.string()
    .transform(v => JSON.parse(v))
    .transform(updateValue("recipientId", d.fromRawUserId))
    .transform(updateValue("actorId", d.fromRawUserId))
    .pipe(UserAccessRevokedMessageContentSchema);

const squadAccessRevokedMessageContentParser = z.string()
    .transform(v => JSON.parse(v))
    .transform(updateValue("squadId", d.fromRawSquadId))
    .transform(updateValue("actorId", d.fromRawUserId))
    .pipe(SquadAccessRevokedMessageContentSchema);

const bondCreatedMessageContentParser = z.string()
    .transform(v => JSON.parse(v))
    .transform(optionalifyArray("addedUserIds"))
    .transform(updateValues("addedUserIds", d.fromRawUserId))
    .transform(optionalifyArray("addedSquadIds"))
    .transform(updateValues("addedSquadIds", d.fromRawSquadId))
    .transform(optionalifyString("title"))
    .transform(updateValue("actorId", d.fromRawUserId))
    .transform(optionalifyArray("addedSquadUserIds"))
    .transform(updateValues("addedSquadUserIds", d.fromRawUserId))
    .pipe(BondCreatedMessageContentSchema);

const inviteSentMessageContentParser = z.string()
    .transform(v => JSON.parse(v))
    .transform(optionalifyString("recipientEmail"))
    .transform(updateValue("actorId", d.fromRawUserId))
    .pipe(InviteSentMessageContentSchema);

const inviteRedeemedMessageContentParser = z.string()
    .transform(v => JSON.parse(v))
    .transform(updateValue("inviterId", d.fromRawUserId))
    .transform(updateValue("actorId", d.fromRawUserId))
    .transform(optionalifyBoolean("commonInviteLink"))
    .pipe(InviteRedeemedMessageContentSchema);

export async function fetchMessages(
    channelId: d.ChannelId,
    from?: number,
): Promise<OfficialMessageAndAttachments[]> {
    const startSequenceNumber = from ? BigInt(from) : undefined;
    const req = new chats_pb.FetchMessagesRequest({
        channelId: pbChannelId(channelId),
        startSequenceNumber,
        limit: 64,
    });
    const resp = await service.fetchMessages(req);
    return resp.messages.map(translateOfficialMessage);
}

export async function getMessage(id: d.MessageId): Promise<OfficialMessageAndAttachments> {
    const req = new chats_pb.GetMessageRequest({
        messageId: pbMessageId(id),
    });

    const resp = await service.getMessage(req);
    if (!resp.message) {
        throw new Error("Received no message in response");
    }

    return translateOfficialMessage(resp.message);
}

interface SendMessageViaBondArgs {
    msg: UnsentChatMessage;
    bondId: d.BondId;
    channelId: d.ChannelId;
    senderId: d.UserId;
    officialAttachmentIds?: d.BlobId[];
}

export async function sendMessageViaBond(
    { msg, bondId, channelId, senderId, officialAttachmentIds }: SendMessageViaBondArgs,
): Promise<[d.ChannelId, AnyUnsentLocalMessage]> {
    const { content, clientTxTs } = msg;

    const mentions = getContent_Mentions(content) ?? [];
    const audienceOps = mentions.map(audienceOpForMention);

    const req = new bonds_pb.SendChatMessageViaBondRequest(
        {
            bondId: pbBondId(bondId),
            request: {
                request: {
                    channelId: pbChannelId(channelId),
                    userId: pbUserId(senderId),
                },
                details: new chats_pb.SendChatMessageDetails({
                    content: JSON.stringify(content),
                    clientTxAt: pbTimestamp(clientTxTs),
                    attachmentIds: officialAttachmentIds?.map(pbBlobId),
                }),
            },
            audienceOps: pbAudienceOpSet(audienceOps),
        },
    );

    try {
        const resp = await bondService.sendChatMessageViaBond(req);
        if (resp?.response?.response?.messageId) {
            return [
                channelId,
                deepFreeze({
                    ...msg,
                    messageId: fromProtoMessageId(resp.response.response.messageId),
                    backoffState: undefined,
                }),
            ];
        }
        else {
            // This is an error, really. All successful RPC responses should contain the message id.
            // Sending again should return the id.
            // TODO make sure we deal with all variations of timing when updating the store here.
            log.info(`Sent message ${msg.localId} but no id on response`);
        }
    }
    catch (e) {
        log.error(`Error sending message ${msg.localId}: ${e}, ${JSON.stringify(e)}`);
        // TODO Which errors are permanent and should not be retried?
        // For now, any RPC failure can be retried, anything else stops any messages being sent.
        if (!(e instanceof ConnectError)) {
            return [channelId, toErroredMsg(msg)];
        }
        // else fall through to normal backoff case.
    }

    return [
        channelId,
        deepFreeze({
            ...msg,
            backoffState: defaultBackoffUpdater(msg.backoffState?.attempts ?? 0),
        }),
    ];
}

function messageParser(res: chats_pb.SubMessageChannelResponse) {
    switch (res.messageOrDeleted?.case) {
        case "message":
            return translateOfficialMessage(res.messageOrDeleted.value);
        case "deletedId":
            throw new Error(`TODO handle message deletedId`);
        default:
            throw new Error(`undefined messageOrDeleted`);
    }
}

interface StreamMessagesArgs {
    userId: d.UserId;
    channelId: d.ChannelId;
    startSequenceNumber: number;
}
export async function* streamMessages(args: StreamMessagesArgs, signal: AbortSignal) {
    const startSequenceNumber = args.startSequenceNumber ? BigInt(args.startSequenceNumber)
        : undefined;

    const req = new chats_pb.SubMessageChannelRequest({
        userId: pbUserId(args.userId),
        channelId: pbChannelId(args.channelId),
        startSequenceNumber,
    });

    const logPrefix = `${streamMessages.name} ${args.channelId}`;

    const resp = service.subMessageChannel(req, { signal });

    yield* streamHandler(resp, messageParser, logPrefix);
}

export async function createAttachment(
    args: Pick<CredentialedAttachment, "metadata" | "ownership">,
): Promise<BlobCredentials> {
    const req = new chats_pb.CreateAttachmentRequest({
        properties: args.metadata,
        uploaderId: pbUserId(args.ownership.uploaderId),
        orgId: pbOrgId(args.ownership.orgId),
    });

    const resp = await service.createAttachment(req);

    if (!resp.response) {
        throw new Error("Received no response in response");
    }
    if (!resp.response.blobId) {
        throw new Error("Received no blobId in response");
    }
    if (!resp.response.uploadSasUrl) {
        throw new Error("Received no sasUrl in response");
    }
    if (resp.response.uploadSasUrl.url == "") {
        throw new Error("Received no url in response");
    }

    return deepFreeze({
        type: "upload",
        blobId: fromProtoBlobId(resp.response.blobId),
        url: resp.response.uploadSasUrl.url,
        urlExpiry: fromProtoTimestamp(resp.response.uploadSasUrl.urlExpiry),
    });
}

export interface CompleteAttachmentArgs {
    blobId: d.BlobId;
    completerId: d.UserId;
}
export async function completeAttachment(args: CompleteAttachmentArgs) {
    const req = new chats_pb.CompleteAttachmentRequest({
        request: {
            blobId: pbBlobId(args.blobId),
            completerId: pbUserId(args.completerId),
        },
    });

    await service.completeAttachment(req);
}

export interface GetAttachmentBlobUrlArgs {
    blobId: d.BlobId;
    messageId: d.MessageId;
}
export async function getAttachmentBlobUrl(
    args: GetAttachmentBlobUrlArgs,
): Promise<ExpiringSasUrl> {
    const req = new chats_pb.GetAttachmentBlobUrlRequest({
        attachmentId: pbBlobId(args.blobId),
        messageId: pbMessageId(args.messageId),
    });

    const resp = await service.getAttachmentBlobUrl(req);

    return deepFreeze(ExpiringSasUrlSchema.parse(parseBufMessage(resp).sasUrl));
}

export interface getAttachmentKnowledgesArgs {
    blobIds: d.BlobId[];
}
export async function getAttachmentKnowledges(
    args: getAttachmentKnowledgesArgs,
): Promise<chats_pb.AttachmentKnowledge[]> {
    const req = new chats_pb.GetAttachmentKnowledgesRequest({
        blobSet: pbBlobSet(args.blobIds),
    });

    const resp = await service.getAttachmentKnowledges(req);
    return resp.knowledges;
}
