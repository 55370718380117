import { useEffect } from "react";

import PresenceHeader from "@/components/PresenceHeader";
import { FilterOptions, selectFilter, setFilter } from "@/features/filterPanel";
import useAddressParams from "@/hooks/useAddressParams";

import useDebouncedOnlineStatus from "@/hooks/useDebouncedOnlineStatus";
import { useAppDispatch, useAppSelector } from "@/store/redux";
import { FilterPill } from "./FilterPill";
import ReconnectingPill from "./gui/ReconnectingPill";

export function FilterBondsAndPresenceHeader(): React.JSX.Element {
    const dispatch = useAppDispatch();
    const connected = useDebouncedOnlineStatus();

    const filter = useAppSelector(selectFilter);
    const { squadId } = useAddressParams();
    const isSquadView = squadId !== undefined;
    const options: FilterOptions[] = ["all", "unread"];
    if (!isSquadView) {
        options.push("dismissed");
    }

    useEffect(() => {
        if (isSquadView && filter === "dismissed") {
            // Dismissed is not a valid filter for squad view. So reset it.
            dispatch(setFilter("all"));
        }
    }, [isSquadView, filter, dispatch]);

    return (
        <div className="c-bonds-presence-wrapper">
            <FilterPill
                options={options}
                selectFilterAction={setFilter}
                selectFilterSelector={selectFilter}
            />
            {connected ? <PresenceHeader /> : <ReconnectingPill />}
        </div>
    );
}
