import {
    Change,
    ChangeBridge,
    ChangeResult,
    Doc,
    DocBridgeWithoutConvert,
    Format,
    Position,
    PositionRange,
    Provider,
} from "../types";
import {
    AnyNode,
    AnyTransform,
    compareNodes,
    firstInsertionPos,
    getMentions,
    getTransformResult,
    jsonNode,
    mentionDiff,
    nodeBuilder,
    nodeIsEmpty,
    parseNode,
    renderNodeToText,
    textInternalNode,
    transformBuilder,
} from "./node";
import { currentSchemaVersion } from "./schema";

export const pmEmptyDocBridge = () => pmDocBridge(nodeBuilder().build());

export const pmDocBridgeFromText = (text: string) => {
    return pmDocBridge(textInternalNode(currentSchemaVersion, text));
};

export const pmDocBridge = (root: AnyNode): DocBridgeWithoutConvert<Provider.Tiptap> => {
    const bridge: DocBridgeWithoutConvert<Provider.Tiptap> = {
        getIsEmpty: () => nodeIsEmpty(root),
        getEquals: other => compareNodes(root, other.rawDoc),
        getMentions: () => getMentions(root),
        getMentionCount: () => getMentions(root).length,
        getMentionDiff: other => mentionDiff(root, other.rawDoc),
        getText: expandMentions => renderNodeToText(root, expandMentions),

        get: <F extends Format>(format: F): Doc<Provider.Tiptap, F> => {
            if (format == Format.JSON) {
                const result: Doc<Provider.Tiptap, Format.JSON> = {
                    rawDoc: jsonNode(root),
                    p: Provider.Tiptap,
                    f: format,
                };
                return result as Doc<Provider.Tiptap, F>;
            }
            if (format == Format.Internal) {
                const result: Doc<Provider.Tiptap, Format.Internal> = {
                    rawDoc: parseNode(root),
                    p: Provider.Tiptap,
                    f: format,
                };
                return result as Doc<Provider.Tiptap, F>;
            }

            throw new Error(`Unsupported format ${format} for get`);
        },

        p: Provider.Tiptap,
    };
    return bridge;
};

export const pmChangeBridge = (
    from: AnyTransform | AnyNode,
): ChangeBridge<Provider.Tiptap> => {
    const builder = transformBuilder(from);
    const bridge: ChangeBridge<Provider.Tiptap> = {
        spliceText: (text, start, end) => {
            builder.spliceText(text, start.rawPosition, end.rawPosition);
            return bridge;
        },

        spliceMention: (mention, text, start, end) => {
            builder.spliceMention(mention, text, start.rawPosition, end.rawPosition);
            return bridge;
        },

        breakMentions: target => {
            builder.breakMentions(target).build();
            return bridge;
        },

        trimWhitespace: () => {
            builder.trim().build();
            return bridge;
        },

        clear: () => {
            builder.clear().build();
            return bridge;
        },

        replace: other => {
            builder.replace(parseNode(other.rawDoc)).build();
            return bridge;
        },

        get: <F extends Format>(
            format: F,
        ): ChangeResult<Provider.Tiptap, F> => {
            const change = {
                rawChange: (format == Format.Internal) ?
                    builder.build() :
                    builder.buildJSON(),
                p: Provider.Tiptap,
                f: format,
            } as Change<Provider.Tiptap, F>;
            const doc = pmDocBridge(getTransformResult(change.rawChange)).get(format);

            return { doc, change };
        },

        p: Provider.Tiptap,
    };
    return bridge;
};

export const pmInitialPosition = (
    doc: Doc<Provider.Tiptap, Format>,
): Position<Provider.Tiptap> => ({
    rawPosition: firstInsertionPos(doc.rawDoc),
    p: Provider.Tiptap,
});

export const pmInitialPositionRange = (
    doc: Doc<Provider.Tiptap, Format>,
): PositionRange<Provider.Tiptap> => ({
    start: {
        rawPosition: firstInsertionPos(doc.rawDoc),
        p: Provider.Tiptap,
    },
    end: {
        rawPosition: firstInsertionPos(doc.rawDoc),
        p: Provider.Tiptap,
    },
});
