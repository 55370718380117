import { SensitiveJSX } from "@/components/gui/SensitiveText";
import TimeAgoCatchup from "@/components/gui/TimeAgoCatchup";
import * as d from "@/domain/domain";
import { DeltaSummarySquadMetadata, SummaryRequestStatus } from "@/domain/intel";
import { ChannelReadState } from "@/domain/intel";
import { AppView, viewStackUtils } from "@/domain/views";
import { updateStagedSequenceNumberIfGreater } from "@/features/channels";
import { selectSquadHasUnreadBonds } from "@/features/filteredBonds";
import { selectCurrentViewStack } from "@/features/filterPanel";
import {
    selectInboxKnowledgeMetadata,
    selectInboxSummary,
    selectInboxSummaryRequestStatus,
} from "@/features/intel";
import {
    selectSquadKnowledgeMetadata,
    selectSquadSummary,
    selectSquadSummaryRequestStatus,
} from "@/features/squads";
import useViewStackNavigate from "@/hooks/navigation/useViewStackNavigate";
import useAddressParams from "@/hooks/useAddressParams";
import useDebouncedFollowedUnreadCount from "@/hooks/useDebouncedFollowedUnreadCount";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { isMobileBrowser } from "@/misc/mobile";
import { Optional } from "@/misc/types";
import { useAppSelector } from "@/store/redux";
import { useAppDispatch } from "@/store/redux";
import purify from "dompurify";
import { Marked } from "marked";
import { useCallback } from "react";
import { viewUtils } from "../domain/views";

function MarkAsReadButton(
    { squadId, channelReadStates }: {
        squadId: Optional<d.SquadId>;
        channelReadStates: ChannelReadState[];
    },
): React.JSX.Element {
    const dispatch = useAppDispatch();
    const viewStack = useAppSelector(selectCurrentViewStack);
    const { navigateReplace } = useViewStackNavigate();

    const markAsRead = useCallback(() => {
        channelReadStates.map(
            st => {
                dispatch(
                    updateStagedSequenceNumberIfGreater({
                        channelId: st.ChannelId,
                        sequenceNumber: st.MaxMessageSequenceNumber,
                    }),
                );
            },
        );

        const newStack = viewStackUtils.updateTopOfStack(
            viewStack,
            (frame: AppView) => viewUtils.toggleSquadOrInboxSummaryFrame(frame, squadId, false),
        );
        navigateReplace(newStack, { replace: true });
    }, [channelReadStates, dispatch, viewStack, navigateReplace, squadId]);

    return (
        <button
            className="c-btn-solid c-btn-solid--read"
            disabled={false}
            onClick={markAsRead}
        >
            <div className="c-btn-solid__icon c-btn-solid__icon--read"></div>
            Mark as read
        </button>
    );
}

export function SquadSidebarSummaryView(): React.JSX.Element {
    const { squadId } = useAddressParams();
    const summaryRequestStatus = useSelectorArgs(selectSquadSummaryRequestStatus, squadId);
    const summary = useSelectorArgs(selectSquadSummary, squadId);
    const hasUnreadBonds = useSelectorArgs(selectSquadHasUnreadBonds, squadId);
    const knowledgeMetadata = useSelectorArgs(selectSquadKnowledgeMetadata, squadId);
    return (
        <BondListSidebarSummaryViewInternal
            squadId={squadId}
            hasUnreadBonds={hasUnreadBonds}
            knowledgeMetadata={knowledgeMetadata}
            summary={summary}
            summaryRequestStatus={summaryRequestStatus}
        />
    );
}

export function InboxSidebarSummaryView(): React.JSX.Element {
    const summaryRequestStatus = useSelectorArgs(selectInboxSummaryRequestStatus);
    const summary = useSelectorArgs(selectInboxSummary);
    const debouncedUnreadCount = useDebouncedFollowedUnreadCount();
    const hasUnreadBonds = debouncedUnreadCount > 0;
    const knowledgeMetadata = useSelectorArgs(selectInboxKnowledgeMetadata);
    return (
        <BondListSidebarSummaryViewInternal
            squadId={undefined}
            hasUnreadBonds={hasUnreadBonds}
            knowledgeMetadata={knowledgeMetadata}
            summary={summary}
            summaryRequestStatus={summaryRequestStatus}
        />
    );
}

interface BondListSidebarSummaryViewInternalProps {
    squadId: Optional<d.SquadId>;
    hasUnreadBonds?: boolean;
    knowledgeMetadata?: DeltaSummarySquadMetadata;
    summary?: string;
    summaryRequestStatus?: SummaryRequestStatus;
}

function BondListSidebarSummaryViewInternal(
    props: BondListSidebarSummaryViewInternalProps,
): React.JSX.Element {
    const { hasUnreadBonds, knowledgeMetadata, summary, summaryRequestStatus, squadId } = props;
    const isMobile = isMobileBrowser();
    const hasUndefinedValues = knowledgeMetadata === undefined || summary == undefined;
    const isHideSidebarStatus = summaryRequestStatus === SummaryRequestStatus.Unspecified;

    if (summaryRequestStatus === SummaryRequestStatus.Error) {
        return <SidebarSummaryErrorMessage message={summary} />;
    }

    if (isMobile || hasUndefinedValues || isHideSidebarStatus) {
        return <></>;
    }
    return (
        <div
            className={"c-sidebar c-summary c-summary--catchup c-summary--open"}
        >
            <div className="c-sidebar__content c-sidebar__content--summary">
                <div className="c-summary__content">
                    <TimeAgoCatchup
                        describeLive={false}
                        from={knowledgeMetadata.TimeOfFirstMessage}
                    />
                    <MarkdownContent summary={summary} />
                </div>
                <div className="c-summary__footer">
                    {hasUnreadBonds && (
                        <MarkAsReadButton
                            squadId={squadId}
                            channelReadStates={knowledgeMetadata.ChannelReadStates}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

function MarkdownContent({ summary }: { summary: string; }) {
    const parser = new Marked();
    const parsedSummary = parser.parse(summary).toString();
    return (
        <SensitiveJSX>
            <div
                className="c-summary__generated"
                dangerouslySetInnerHTML={{
                    __html: purify.sanitize(parsedSummary),
                }}
            />
        </SensitiveJSX>
    );
}

export function SidebarSummaryErrorMessage(
    { message }: { message?: string; },
): React.JSX.Element {
    return (
        <div
            className={"c-sidebar c-summary c-summary--open"}
        >
            <div className="c-sidebar__content c-sidebar__content--summary">
                <div className="c-summary__content">
                    Oops, something went wrong. Please report an issue with `Ctrl + b`.
                    {message ? "Error: " + message : ""}
                </div>
            </div>
        </div>
    );
}
