import { FC, useCallback, useMemo } from "react";

import Avatar from "@/components/gui/Avatar";
import PillSelector from "@/components/gui/PillSelector";
import SensitiveText from "@/components/gui/SensitiveText";
import { AudienceMember, AudienceMemberOverview, isSquadOverview } from "@/domain/audience";
import { DraftTarget } from "@/domain/draftTarget";
import { squadNameForMention, userNameForMention } from "@/domain/mentions";
import { selectAudienceOverviews } from "@/features/audience";
import {
    addToBondCreationAudience,
    removeFromBondCreationAudienceThunk,
    selectBondCreationAudience,
    selectValidSquadIdsForBondCreationAudience,
    selectValidUserIdsForBondCreationAudience,
} from "@/features/bondCreation";
import useAudienceSuggestionDomain, {
    EmailSuggestionContent,
    SquadSubdomainOptions,
    SquadSuggestionContent,
    UserSubdomainOptions,
    UserSuggestionContent,
} from "@/hooks/useAudienceSuggestionDomain";
import useBooleanFeatureFlag from "@/hooks/useBooleanFeatureFlag";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { isMobileBrowser } from "@/misc/mobile";
import { isString } from "@/misc/types";
import { useAppDispatch } from "@/store/redux";

const AudienceMemberPillContent: FC<{ data: AudienceMemberOverview; }> = ({ data }) =>
    isSquadOverview(data) ?
        (
            <>
                <div className="c-squad-icon c-squad-icon--small"></div>
                {squadNameForMention(data)}
            </>
        ) :
        <>{userNameForMention(data)}</>;

export const SquadInviteSuggestionContent: SquadSuggestionContent = ({ entity }) => (
    <>
        <div className="c-squad-icon"></div>
        <div className="u-truncate-auto">
            <strong>
                <SensitiveText>{entity.name}</SensitiveText>
            </strong>
            &nbsp;&bull;&nbsp;
            <SensitiveText>{`${entity.userIds.length} people`}</SensitiveText>
        </div>
    </>
);

export const UserInviteSuggestionContent: UserSuggestionContent = ({ entity }) => (
    <>
        <Avatar id={entity.id} showPresence={false} size="suggestion" />
        <div className="u-truncate-auto">
            <strong>
                <SensitiveText>{userNameForMention(entity)}</SensitiveText>
            </strong>
            &nbsp;&bull;&nbsp;
            <SensitiveText>{entity.name}</SensitiveText>
        </div>
    </>
);

export const EmailInviteSuggestionContent: EmailSuggestionContent = ({ entity }) => (
    <div className="u-truncate-auto">
        <strong>
            <SensitiveText>{entity}</SensitiveText>
        </strong>
    </div>
);

const getAudienceMemberTitle = (data: AudienceMemberOverview) => data.name;

interface NewBondAudienceInputProps {
    draftTarget: DraftTarget;
    tabIndex?: number;
}

export const NewBondAudienceInput: FC<NewBondAudienceInputProps> = ({ draftTarget, tabIndex }) => {
    const dispatch = useAppDispatch();

    const validSquadIds = useSelectorArgs(selectValidSquadIdsForBondCreationAudience, draftTarget);
    const validUserIds = useSelectorArgs(selectValidUserIdsForBondCreationAudience, draftTarget);

    const selectedAudienceMembers = useSelectorArgs(selectBondCreationAudience, draftTarget);
    const selected = useSelectorArgs(selectAudienceOverviews, selectedAudienceMembers);

    const deselectAction = useCallback((id: AudienceMember) => {
        dispatch(removeFromBondCreationAudienceThunk({ member: id, draftTarget }));
    }, [dispatch, draftTarget]);

    const selectAction = useCallback((id: AudienceMember) => {
        dispatch(addToBondCreationAudience({ member: id, draftTarget }));
    }, [dispatch, draftTarget]);

    const selectOverviewAction = useCallback(
        (member: AudienceMemberOverview | string) =>
            isString(member) ? undefined : selectAction(member.id),
        [selectAction],
    );

    // Build the audience suggestion domain
    const squadSubdomainOptions = useMemo<SquadSubdomainOptions>(
        () => ({
            ids: validSquadIds,
            Content: SquadInviteSuggestionContent,
            action: selectOverviewAction,
        }),
        [validSquadIds, selectOverviewAction],
    );
    const userSubdomainOptions = useMemo<UserSubdomainOptions>(
        () => ({
            idsToFilter: validUserIds,
            Content: UserInviteSuggestionContent,
            action: selectOverviewAction,
        }),
        [validUserIds, selectOverviewAction],
    );
    const suggestionDomain = useAudienceSuggestionDomain(
        {
            squad: squadSubdomainOptions,
            user: userSubdomainOptions,
        },
    );
    const bondInvitesEnabled = useBooleanFeatureFlag("early-bond-invite-buttons");
    const placeholder = bondInvitesEnabled ? "Name or mail@example.com" : "Name";

    return (
        <PillSelector
            label="To:"
            placeholder={placeholder}
            PillContent={AudienceMemberPillContent}
            getPillTitle={getAudienceMemberTitle}
            selected={selected}
            suggestionDomain={suggestionDomain}
            selectAction={selectAction}
            deselectAction={deselectAction}
            tabIndex={tabIndex}
            autoFocus={isMobileBrowser()}
        />
    );
};

export default NewBondAudienceInput;
