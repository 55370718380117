import { AutoComplete } from "@/components/gui/AutoComplete";
import Avatar from "@/components/gui/Avatar";
import SensitiveText from "@/components/gui/SensitiveText";
import { SuggestionDomain } from "@/components/SuggestionsList";
import { DraftTarget } from "@/domain/draftTarget";
import {
    Mention,
    newSquadMention,
    newUserMention,
    squadNameForMention,
    userNameForMention,
} from "@/domain/mentions";
import { PositionRange, Provider } from "@/domain/richtext/types";
import { SquadOverview } from "@/domain/squads";
import { UserOverview } from "@/domain/users";
import { MetaInterestCounterKey } from "@/features/interest";
import { selectValidSquadIdsForMention, selectValidUserIdsForMention } from "@/features/mentions";
import { useMetaInterest } from "@/hooks/interest/useInterest";
import useAudienceSuggestionDomain, {
    SquadSubdomainOptions,
    SquadSuggestionContent,
    UserSubdomainOptions,
    UserSuggestionContent,
} from "@/hooks/useAudienceSuggestionDomain";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { FC, useCallback, useMemo, useState } from "react";
import { SuggestionQuery } from "./richtext/RichTextEditor";

const SquadMentionSuggestionContent: SquadSuggestionContent = ({ entity }) => (
    <>
        <div className="u-truncate-auto">
            <em>
                <SensitiveText>{entity.name}</SensitiveText>
            </em>
        </div>
        <div className="c-people-count">
            <div className="c-people-count__icon"></div>
            {entity.userIds.length}
        </div>
    </>
);

const UserMentionSuggestionContent: UserSuggestionContent = ({ entity }) => (
    <>
        <Avatar id={entity.id} showPresence={false} size="suggestion" />
        <div className="u-truncate-auto">
            <em>
                <SensitiveText>{userNameForMention(entity)}</SensitiveText>
            </em>
            &nbsp;&bull;&nbsp;
            <SensitiveText>{entity.name}</SensitiveText>
        </div>
    </>
);

export type MentionInsertFunc = (
    mention: Mention,
    text: string,
    range: PositionRange<Provider>,
) => void;

interface MentionAutoCompleteProps {
    insert: MentionInsertFunc;
    query: SuggestionQuery<Provider>;
    draftTarget: DraftTarget;
    similarityThreshold?: number;
}

export const MentionAutoComplete: FC<MentionAutoCompleteProps> = ({
    insert,
    query,
    draftTarget,
}) => {
    const validSquadIds = useSelectorArgs(selectValidSquadIdsForMention, draftTarget);
    const validUserIds = useSelectorArgs(selectValidUserIdsForMention, draftTarget);

    const insertSquadMention = useCallback((squad: SquadOverview) => {
        insert(
            newSquadMention(squad.id),
            query.trigger + squadNameForMention(squad),
            query.range,
        );
    }, [insert, query]);

    const insertUserMention = useCallback((user: UserOverview) => {
        insert(
            newUserMention(user.id),
            query.trigger + userNameForMention(user),
            query.range,
        );
    }, [insert, query]);

    // Build the audience suggestion domain
    const squadSubdomainOptions = useMemo<SquadSubdomainOptions>(
        () => ({
            ids: validSquadIds,
            Content: SquadMentionSuggestionContent,
            action: insertSquadMention,
        }),
        [validSquadIds, insertSquadMention],
    );
    const userSubdomainOptions = useMemo<UserSubdomainOptions>(
        () => ({
            idsToFilter: validUserIds,
            Content: UserMentionSuggestionContent,
            action: insertUserMention,
        }),
        [validUserIds, insertUserMention],
    );
    const suggestionDomain = useAudienceSuggestionDomain(
        {
            squad: squadSubdomainOptions,
            user: userSubdomainOptions,
        },
    );

    const [isEmpty, setIsEmpty] = useState(false);
    const onSuggestionsChange = useCallback((suggestionGroups: SuggestionDomain<any[]>) => {
        setIsEmpty(suggestionGroups.length == 0);
    }, []);

    useMetaInterest(!isEmpty, MetaInterestCounterKey.BlockMsgCompletion);

    return (
        <AutoComplete
            anchor={query.anchor}
            verticalAnchor="bottom"
            horizontalClampBehaviour="clamp"
            suggestionDomain={suggestionDomain}
            onSuggestionsChange={onSuggestionsChange}
            query={query.text}
        />
    );
};
