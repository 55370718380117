import classNames from "classnames";
import React, { useCallback, useState } from "react";

import OfflineIndicator from "@/components/OfflineIndicator";
import { SidebarLinks } from "@/components/SidebarLinks";
import { OrgOverview } from "@/domain/squads";
import { UserDefinition } from "@/domain/users";
import {
    selectAlternativeLogins,
    selectCurrentOrgId,
    selectCurrentUserId,
    setLocalPreferredIds,
} from "@/features/auth";
import { selectOrg } from "@/features/squads";
import { selectCurrentUser } from "@/features/users";
import { useInterestedOrgs } from "@/hooks/interest/useInterest";
import useBooleanFeatureFlag from "@/hooks/useBooleanFeatureFlag";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import log from "@/misc/log";
import { isMobileBrowser } from "@/misc/mobile";
import { Optional } from "@/misc/types";
import { useAppSelector } from "@/store/redux";

function orgName(org: Optional<OrgOverview>): string {
    return org?.personal ? "My Account" : org?.name || "Unknown Org";
}

function OrgSelectorItem(
    { user }: { user: UserDefinition; },
): React.JSX.Element {
    const currentUserId = useAppSelector(selectCurrentUserId);
    const selected = user.userId === currentUserId;
    const classes = classNames("c-org-selection__item", {
        "c-org-selection__item--selected": selected,
    });
    const org = useSelectorArgs(selectOrg, user.orgId);

    const chooseUser = useCallback(async (userDef: UserDefinition) => {
        if (userDef.userId === currentUserId) {
            return;
        }

        setLocalPreferredIds(userDef);

        // As we are changing the preferred user ID, refresh the page to reload
        // the IndexedDB and re-setup the store,
        log.info(`Switching to user ${userDef.userId} in org ${userDef.orgId}: reloading`);
        window.location.reload();
    }, [currentUserId]);

    const showPersonalOrg = useBooleanFeatureFlag("my-account-option");
    if (!showPersonalOrg && org?.personal) {
        return <></>;
    }

    return (
        <button className={classes} onClick={() => chooseUser(user)}>
            {orgName(org)}
        </button>
    );
}

export function UserInfoAndOrgSelector(): React.JSX.Element {
    const isMobile = isMobileBrowser();
    const user = useAppSelector(selectCurrentUser);
    const orgId = useAppSelector(selectCurrentOrgId);
    const org = useSelectorArgs(selectOrg, orgId);
    const alternativeLoginUsers = useAppSelector(selectAlternativeLogins);

    const [orgSelectorShowing, setOrgSelectorShowing] = useState(false);
    const orgSelectorClasses = classNames("c-sidebar__user-settings", {
        "is-visible": orgSelectorShowing,
    });

    const interestedOrgs = [
        orgId,
        ...(orgSelectorShowing ? alternativeLoginUsers.map(u => u.orgId) : []),
    ].filter(x => !!x);
    useInterestedOrgs(interestedOrgs);

    return (
        <div className="c-sidebar__human c-sidebar-human">
            {!isMobile ? (
                <>
                    <button
                        className="c-sidebar-human__name c-org-selector"
                        title={orgSelectorShowing ? "Hide user settings" : "Show user settings"}
                        onClick={() => setOrgSelectorShowing(x => !x)}
                    >
                        <OfflineIndicator />
                        {user?.name}
                        <div
                            className={`c-org-selector__icon ${
                                orgSelectorShowing ? "c-org-selector__icon--open" : ""
                            }`}
                        >
                        </div>
                    </button>
                    <div className="c-sidebar-human__meta">
                        {orgName(org)} <span className="c-middot">&#183;</span> {user?.nickname}
                    </div>
                    <div className={orgSelectorClasses}>
                        <div className="c-org-selection">
                            {alternativeLoginUsers.map(user => (
                                <OrgSelectorItem
                                    key={user.orgId}
                                    user={user}
                                />
                            ))}
                        </div>
                        <SidebarLinks />
                    </div>
                </>
            ) : (
                <>
                    <div className="c-sidebar-human__name">
                        <OfflineIndicator />
                        {user?.name}
                    </div>
                    <div className="c-sidebar-human__meta">
                        {orgName(org)} <span className="c-middot">&#183;</span> {user?.nickname}
                    </div>
                    <div className="c-org-selection">
                        {alternativeLoginUsers.map(user => (
                            <OrgSelectorItem
                                key={user.orgId}
                                user={user}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}
