import classNames from "classnames";
import { useCallback, useEffect, useMemo } from "react";

import { ButtonMain } from "@/components/buttons/ButtonMain";
import { CloseButton } from "@/components/buttons/Close";
import { getUserDisplayName, getUserNickname } from "@/domain/users";
import {
    removeSquadMemberThunk,
    selectSquadById,
    selectSquadIdFromViewStack,
} from "@/features/squads";
import { selectUser, selectUserIdFromViewStack } from "@/features/users";
import useViewStackNavigate from "@/hooks/navigation/useViewStackNavigate";
import useDialogOpenRef from "@/hooks/useDialogOpenRef";
import useDialogOutsideClick from "@/hooks/useDialogOutsideClick";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import log from "@/misc/log";
import { isMobileBrowser } from "@/misc/mobile";
import { selectCurrentUserId, useAppDispatch, useAppSelector } from "@/store/redux";

export default function RemoveUserFromSquadModal(): React.JSX.Element {
    const dispatch = useAppDispatch();
    const { navigatePop, navigateRemoveSettingsModals } = useViewStackNavigate();

    const closeModal = useCallback(() => {
        navigatePop();
    }, [navigatePop]);

    const closeAllSettings = useCallback(() => {
        navigateRemoveSettingsModals();
    }, [navigateRemoveSettingsModals]);

    const dialogRef = useDialogOpenRef();
    const handleBackdropClick = useDialogOutsideClick(dialogRef, closeModal);

    const currentUserId = useAppSelector(selectCurrentUserId);

    const squadId = useAppSelector(selectSquadIdFromViewStack);
    const userId = useAppSelector(selectUserIdFromViewStack);
    const squadOverview = useSelectorArgs(selectSquadById, squadId);
    const squadName = squadOverview?.name ?? "";

    const userOverview = useSelectorArgs(selectUser, userId);
    const userNickname = getUserNickname(userOverview);
    const userFullname = getUserDisplayName(userOverview);

    const removingSelf = currentUserId === userId;

    const closeAction = useMemo(
        () => removingSelf ? closeAllSettings : closeModal,
        [removingSelf, closeAllSettings, closeModal],
    );

    const confirmRemove = useCallback(() => {
        if (!squadId || !userId) return;

        dispatch(removeSquadMemberThunk({
            squadId: squadId,
            userId: userId,
        })).unwrap().then(closeAction).catch(
            e => {
                log.error("Error creating squad:", e);
                alert(`Failed to create squad.`);
            },
        );
    }, [dispatch, closeAction, squadId, userId]);

    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            if (e.key === "Enter") {
                confirmRemove();
            }
        };
        document.addEventListener("keypress", handleKeyPress);

        return () => {
            document.removeEventListener("keypress", handleKeyPress);
        };
    }, [confirmRemove]);

    const warningTitle = removingSelf ? "Leave squad?" : `Remove ${userNickname}`;
    const warningText = removingSelf ?
        `You will no longer have access to bonds in ${squadName}.` :
        `${userFullname} will no longer have access to bonds in ${squadName}.`;
    const buttonText = removingSelf ? "Leave" : "Remove";

    const isMobile = isMobileBrowser();
    const isDesktop = !isMobile;

    const dialogClass = classNames("cp-dialog", {
        "cp-dialog--desktop-notification": isDesktop,
    });

    return (
        <dialog
            className={dialogClass}
            onClose={closeModal}
            onMouseDown={handleBackdropClick}
            ref={dialogRef}
            role="dialog"
        >
            <header className="cp-dialog__header">
                <button className="cp-btn-back" onClick={closeModal}>Return</button>
                <h1 className="cp-dialog__title">{warningTitle}</h1>
                <CloseButton side="right" onClick={closeAllSettings} />
            </header>

            <article className="cp-dialog__content-wrapper">
                <div className="cp-dialog__content cp-dialog__content--centered">
                    {warningText}
                </div>

                <div className="cp-dialog__button-group">
                    <ButtonMain
                        label={buttonText}
                        onClick={confirmRemove}
                        isCentred
                        isWarning
                    />
                    <ButtonMain
                        label={"Cancel"}
                        onClick={closeModal}
                        isCentred
                    />
                </div>
            </article>
        </dialog>
    );
}
