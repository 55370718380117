import log from "@/misc/log";
import {
    quillChangeOnDocBridge,
    quillContinueChangeBridge,
    quillDocBridge,
    quillDocBridgeFromText,
    quillEmptyDocBridge,
    quillInitialPosition,
    quillInitialPositionRange,
} from "./quill/bridge";
import { tagChange } from "./taggers";
import {
    pmChangeBridge,
    pmDocBridge,
    pmDocBridgeFromText,
    pmEmptyDocBridge,
    pmInitialPosition,
    pmInitialPositionRange,
} from "./tiptap/bridge";
import {
    Change,
    ChangeBridge,
    ChangeTypes,
    Doc,
    DocBridge,
    DocBridgeWithoutConvert,
    Format,
    Position,
    PositionRange,
    Provider,
    providerToString,
} from "./types";

// region Utilities

/**
 * Utility for returning a different value based on provider variant
 *
 * NOTE: This function allows us to avoid a lot of nasty type assertions, by hiding
 *       them behind the `any` type. It is important to double check that the types
 *       returned by the map functions actually match what is intended, as this will
 *       not be checked at compile time
 */
const switchProvider = <T>(
    provider: Provider,
    maps: {
        quillMap: () => any;
        tiptapMap: () => any;
    },
    shim: (provider: Provider, bridge: any) => any = (_, x) => x,
): T => {
    switch (provider) {
        case Provider.Quill:
            return shim(provider, maps.quillMap());
        case Provider.Tiptap:
            return shim(provider, maps.tiptapMap());
    }
};

/**
 * Utility for returning a different value based on doc provider variant. A type-
 * asserted reference to the doc is passed as an argument to the map function
 *
 * NOTE: This function allows us to avoid a lot of nasty type assertions, by hiding
 *       them behind the `any` type. It is important to double check that the types
 *       returned by the map functions actually match what is intended, as this will
 *       not be checked at compile time
 */
export const switchDoc = <T, F extends Format>(
    doc: Doc<Provider, F>,
    maps: {
        quillMap: (doc: Doc<Provider.Quill, F>) => any;
        tiptapMap: (doc: Doc<Provider.Tiptap, F>) => any;
    },
    shim?: (provider: Provider, bridge: any) => any,
): T =>
    switchProvider(doc.p, {
        quillMap: () => maps.quillMap(doc as Doc<Provider.Quill, F>),
        tiptapMap: () => maps.tiptapMap(doc as Doc<Provider.Tiptap, F>),
    }, shim);

/**
 * Utility for returning a different value based on change provider variant. A type-
 * asserted reference to the change is passed as an argument to the map function
 *
 * NOTE: This function allows us to avoid a lot of nasty type assertions, by hiding
 *       them behind the `any` type. It is important to double check that the types
 *       returned by the map functions actually match what is intended, as this will
 *       not be checked at compile time
 */
const switchChange = <T, F extends Format>(
    change: Change<Provider, F>,
    maps: {
        quillMap: (doc: Change<Provider.Quill, F>) => any;
        tiptapMap: (doc: Change<Provider.Tiptap, F>) => any;
    },
    shim?: (provider: Provider, bridge: any) => any,
): T =>
    switchProvider(change.p, {
        quillMap: () => maps.quillMap(change as Change<Provider.Quill, F>),
        tiptapMap: () => maps.tiptapMap(change as Change<Provider.Tiptap, F>),
    }, shim);

// region Type safe shim

// The type parameter `P` on the tag is useful for guaranteeing that only implementation-specific values
// are used within the components for a specific provider. However, higher-level components that need to
// support all providers (such as the composer) will set this parameter to the broad type `Provider`.
// In this case, the type system gives no protection against subsequent inputs to a bridge having
// different providers, which is not allowed.

// Therefore, we need to check for this at runtime in any bridge methods that accept another provider as
// a direct or indirect argument. The type safe shims allow common provider-checking to be done here
// instead of repeating these checks in every bridge implementation

export const assertProvidersMatch = (
    siteName: string,
    expected: Provider,
    argName: string,
    actual: Provider,
) => {
    if (expected != actual) {
        const message = `Providers don't agree in ${siteName} : ` +
            `${providerToString(expected)} (expected) != ` +
            `${providerToString(actual)} (${argName})`;
        log.error(message);
        throw new Error(message);
    }
};

const typeSafeDocBridgeShim = <P extends Provider>(
    provider: P,
    delegate: DocBridgeWithoutConvert<P>,
): DocBridge<P> => ({
    getIsEmpty: delegate.getIsEmpty,
    getEquals: other => provider == other.p && delegate.getEquals(other),
    getMentions: delegate.getMentions,
    getMentionCount: delegate.getMentionCount,
    getMentionDiff: other => {
        assertProvidersMatch("getMentionDiff", provider, "other", other.p);
        return delegate.getMentionDiff(other);
    },
    getText: delegate.getText,
    get: delegate.get,
    p: delegate.p,
    convert: provider => docBridge.fromText(delegate.getText(true), provider),
});

const typeSafeChangeBridgeShim = <P extends Provider>(
    provider: P,
    delegate: ChangeBridge<P>,
): ChangeBridge<P> => ({
    spliceText: (text, start, end) => {
        assertProvidersMatch("spliceText", provider, "start", start.p);
        assertProvidersMatch("spliceText", provider, "end", end.p);
        return typeSafeChangeBridgeShim(
            provider,
            delegate.spliceText(text, start, end),
        );
    },
    spliceMention: (mention, text, start, end) => {
        assertProvidersMatch("spliceMention", provider, "start", start.p);
        assertProvidersMatch("spliceMention", provider, "end", end.p);
        return typeSafeChangeBridgeShim(
            provider,
            delegate.spliceMention(mention, text, start, end),
        );
    },
    breakMentions: target => typeSafeChangeBridgeShim(provider, delegate.breakMentions(target)),
    trimWhitespace: () => typeSafeChangeBridgeShim(provider, delegate.trimWhitespace()),
    replace: other => {
        assertProvidersMatch("replace", provider, "other", other.p);
        return typeSafeChangeBridgeShim(
            provider,
            delegate.replace(other),
        );
    },
    clear: () => typeSafeChangeBridgeShim(provider, delegate.clear()),
    get: delegate.get,
    p: delegate.p,
});

// region Bridge impl.

/**
 * Functions for getting a provider-specific implementation of the doc bridge
 */
export const docBridge = {
    convert: <P extends Provider, F extends Format>(
        doc: Doc<Provider, F>,
        newProvider: P,
    ): DocBridge<P> => docBridge.from(doc).convert(newProvider),

    from: <P extends Provider, F extends Format>(doc: Doc<P, F>): DocBridge<P> =>
        switchDoc(doc, {
            quillMap: doc => quillDocBridge(doc.rawDoc),
            tiptapMap: doc => pmDocBridge(doc.rawDoc),
        }, typeSafeDocBridgeShim),

    fromText: <P extends Provider>(
        text: string,
        provider: Provider,
    ): DocBridge<P> =>
        switchProvider(provider, {
            quillMap: () => quillDocBridgeFromText(text),
            tiptapMap: () => pmDocBridgeFromText(text),
        }, typeSafeDocBridgeShim),

    empty: <P extends Provider>(
        provider: Provider,
    ): DocBridge<P> =>
        switchProvider(provider, {
            quillMap: () => quillEmptyDocBridge(),
            tiptapMap: () => pmEmptyDocBridge(),
        }, typeSafeDocBridgeShim),
};

/**
 * Functions for getting a provider-specific implementation of the change bridge
 */
export const changeBridge = {
    from: <P extends Provider, F extends Format>(change: Change<P, F>): ChangeBridge<P> =>
        switchChange(change, {
            quillMap: change => quillContinueChangeBridge(change.rawChange),
            tiptapMap: change => pmChangeBridge(change.rawChange),
        }, typeSafeChangeBridgeShim),

    fromRaw: <P extends Provider, F extends Format>(
        rawChange: ChangeTypes[P][F],
        provider: P,
        format: F,
    ): ChangeBridge<P> => changeBridge.from(tagChange(rawChange, provider, format)),

    on: <P extends Provider>(doc: Doc<P, Format>): ChangeBridge<P> =>
        switchDoc(doc, {
            quillMap: doc => quillChangeOnDocBridge(doc.rawDoc),
            tiptapMap: doc => pmChangeBridge(doc.rawDoc),
        }, typeSafeChangeBridgeShim),
};

/**
 * Functions for getting a provider-specific position values
 */
export const positionBridge = {
    getInitial: <P extends Provider>(doc: Doc<P, Format>): Position<P> =>
        switchDoc(doc, {
            quillMap: () => quillInitialPosition(),
            tiptapMap: doc => pmInitialPosition(doc),
        }),

    getInitialRange: <P extends Provider>(doc: Doc<P, Format>): PositionRange<P> =>
        switchDoc(doc, {
            quillMap: () => quillInitialPositionRange(),
            tiptapMap: doc => pmInitialPositionRange(doc),
        }),
};
