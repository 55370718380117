// Phase 3 bond card
import classNames from "classnames";
import React, { memo, useCallback } from "react";

import BondCardContributorsAndSummary from "@/components/BondCardContributorsAndSummary";
import Avatar from "@/components/gui/Avatar";
import { BondDismissButton, BondFollowButton, BondReadButton } from "@/components/gui/BondActions";
import BondPrivacyDomain from "@/components/gui/BondPrivacyDomain";
import TimeAgo from "@/components/gui/TimeAgo";
import * as d from "@/domain/domain";
import { selectCurrentUserId } from "@/features/auth";
import {
    selectBondById,
    selectBondIsArchived,
    selectBondTitle,
    selectIsBondLive,
    selectSortedBondLiveParticipantIdsPair,
} from "@/features/bonds";
import { selectIsRead, selectUserIdSetWithMentions } from "@/features/channels";
import { selectKnownSquadNames } from "@/features/squads";
import {
    useInterestedBond,
    useInterestedCalls,
    useInterestedSquads,
    useInterestedUsers,
} from "@/hooks/interest/useInterest";
import useAddressParams from "@/hooks/useAddressParams";
import useBooleanFeatureFlag from "@/hooks/useBooleanFeatureFlag";
import useFreshBondObservers from "@/hooks/useFreshBondObservers";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { useShallowEqualsMemo } from "@/hooks/useShallowEquals";
import useSlider from "@/hooks/useSlider";
import useSortedUsers from "@/hooks/useSortedUsers";
import { isMobileBrowser } from "@/misc/mobile";
import { removeDuplicates } from "@/misc/primatives";
import { Optional } from "@/misc/types";
import { useAppSelector } from "@/store/redux";

export interface BondCardProps {
    id: d.BondId;
    score?: number;
    onClick?: (id: d.BondId, channelId: Optional<d.ChannelId>) => void;
    bondType?: string;
    isFirstCard: boolean;
    isLastCard: boolean;
}

function BondCardPresence({ bondId }: { bondId: d.BondId; }): React.JSX.Element {
    const { ids: observerIds } = useFreshBondObservers(bondId);
    const liveParticipantIds = useSortedUsers(selectSortedBondLiveParticipantIdsPair, bondId);
    const isLiveBond = useSelectorArgs(selectIsBondLive, bondId) || false;
    const isMobile = isMobileBrowser();

    const previewUsers = useShallowEqualsMemo(() =>
        removeDuplicates(
            liveParticipantIds,
            observerIds,
        ), [
        liveParticipantIds,
        observerIds,
    ]);
    useInterestedUsers(previewUsers);

    const classes = classNames("c-card-presence", {
        "c-card-presence--live": isLiveBond,
        "c-card-presence--mobile": isMobile,
    });

    const classesHuman = classNames("c-card-presence__humans", {
        "c-card-presence__humans--mobile": isMobile,
    });

    const avatarSize = isMobile ? "card" : "card-desktop";

    if (previewUsers.length === 0) {
        return <></>;
    }
    return (
        <div className={classes}>
            <div className={classesHuman}>
                {previewUsers.map(cid => (
                    <Avatar
                        key={cid}
                        id={cid}
                        showPresence={true}
                        context={{ isBondCard: true, isMobile, showBondPresence: true }}
                        size={avatarSize}
                        modifiers={{ isLiveBond }}
                    />
                ))}
            </div>
        </div>
    );
}

export const Swiper = (
    { id, children, className }: {
        id: string;
        children: React.ReactNode;
        className: string;
    },
): React.JSX.Element => {
    const isMobile = isMobileBrowser();
    const [swipeRef, domId] = useSlider(id, {
        swipeSensitivity: 1,
        revealWidthLeft: 80,
        revealWidthRight: 160,
        snapThreshold: 3,
    });
    return (
        <div id={domId} className={className} ref={isMobile && swipeRef || undefined}>
            {children}
        </div>
    );
};

export function BondCardPrivacyDomain(
    { id, includeInvited, leader }: { id: d.BondId; includeInvited: boolean; leader?: string; },
): React.JSX.Element {
    const bo = useSelectorArgs(selectBondById, id);
    const isRead = useSelectorArgs(selectIsRead, bo?.channelId);
    const groupClasses = classNames("c-card__squads", "u-clamp_1", {
        "c-card__squads--read": isRead,
    });

    return (
        <div className={groupClasses}>
            {leader && (
                <>
                    {leader}
                    &nbsp;&bull;&nbsp;
                </>
            )}
            <BondPrivacyDomain id={id} includeInvited={includeInvited} />
        </div>
    );
}

function BondCardInner(props: BondCardProps): React.JSX.Element {
    const { id, onClick, isFirstCard, isLastCard } = props;
    const bo = useSelectorArgs(selectBondById, id);

    const squadIds = bo?.squadIds || [];
    const channelId = bo?.channelId;
    const contributors = bo?.contributors;
    const liveCallId = bo?.liveCallIds[0];

    const currentUserId = useAppSelector(selectCurrentUserId);
    const squadNames = useSelectorArgs(selectKnownSquadNames, squadIds);
    const userSetWithMentions = useSelectorArgs(selectUserIdSetWithMentions, channelId);

    const isArchived = useSelectorArgs(selectBondIsArchived, id);
    const isLive = useSelectorArgs(selectIsBondLive, id) || false;
    const isMentioned = userSetWithMentions.size > 0;
    const isRead = useSelectorArgs(selectIsRead, channelId);
    const isPrivate = squadNames.length === 0;
    const isFollowed = currentUserId && bo && bo.followers.includes(currentUserId) || false;
    const bondTitle = useSelectorArgs(selectBondTitle, id);
    const showEmoji = useBooleanFeatureFlag("display-bond-emoji");

    const { squadId } = useAddressParams();
    const isSquadView = squadId !== undefined;

    // The squad and user names should be combined, such that we show any squad, and any
    // user who is not in that squad who has access to the bond (therefore private bonds
    // are just lists of users). This isn't quite right yet as we don't handle bonds with
    // squad and usernames needing showing.
    // We also need a sensible ellipsis strategy - we probably want to truncate to e.g.
    // "+2 more" rather than just "...".
    // const squadOrUserNames = squadNames.length === 0 ? userNames : squadNames;

    const timeFrom = bo?.lastActivityAt || 0;

    const title = `${showEmoji && bondTitle.emoji ? bondTitle.emoji + " " : ""}${bondTitle.title}`;
    const titleOnly = bondTitle.title;

    const onCardClick = useCallback(() => {
        onClick?.(id, channelId);
    }, [id, channelId, onClick]);

    // XXX: we need the full bond overview to get the list of contributors, as for the time
    // being that is what we're using to show the list of people on this card. The stream of
    // bond *previews* does not contain this information today.
    // We probably want to revisit this area.
    useInterestedBond(id);
    useInterestedSquads(squadIds);
    useInterestedUsers(contributors);
    useInterestedCalls(liveCallId);

    const isMobile = isMobileBrowser();
    const isDesktop = !isMobile;

    const topClasses = classNames("c-card", {
        "c-card--live": isLive,
        "c-card--first": isFirstCard && !isLastCard,
        "c-card--single": !isFirstCard && !isLastCard,
        "c-card--last": !isFirstCard && isLastCard,
        "c-card--only": isFirstCard && isLastCard,
        "c-card--desktop": isDesktop,
    });
    const contentClasses = classNames("c-card__content", {
        "c-card__content--live": isLive,
        "c-card__content--desktop": !isMobile,
    });
    const titleClasses = classNames("c-card__title", "u-clamp_1", {
        "c-card__title--read": isRead,
    });
    const summaryClasses = classNames("c-card__summary", "u-clamp_2", {
        "c-card__summary--read": isRead,
    });
    const notificationClasses = classNames("c-card-notification", {
        "c-card-notification--live": isLive,
        "c-card-notification--mentioned": isMentioned,
        "c-card-notification--desktop": !isMobile,
    });
    const timeClasses = classNames("c-card__time", {
        "c-card__time--read": isRead,
    });
    const wrapperClasses = classNames("c-card-wrapper", {
        "c-card-wrapper--desktop": !isMobile,
    });
    const detailWrapperClasses = classNames("c-card__details-wrapper", {
        "c-card__details-wrapper--desktop": !isMobile,
    });

    return (
        <div
            className={wrapperClasses}
            onClick={onCardClick}
            aria-label={`Open bond '${titleOnly}'`}
            role="button"
            tabIndex={0}
        >
            <div
                className={topClasses}
            >
                {isMobile && (
                    <div className="c-card__controls c-card-controls">
                        <div className="c-card-controls__left">
                            <BondReadButton
                                isRead={isRead}
                                channelId={channelId}
                                isMobile={true}
                            />
                        </div>
                        <div className="c-card-controls__right">
                            {!isSquadView && (
                                <BondDismissButton
                                    id={id}
                                    channelId={channelId}
                                    isArchived={isArchived}
                                    isMobile={true}
                                />
                            )}
                            {!isPrivate && (
                                <BondFollowButton id={id} isFollowed={isFollowed} isMobile={true} />
                            )}
                        </div>
                    </div>
                )}
                <Swiper id={id} className={contentClasses}>
                    <div className="c-card__header">
                        <h3
                            className={titleClasses}
                        >
                            {title}
                        </h3>
                        <time
                            className={timeClasses}
                            dateTime={new Date(timeFrom).toISOString()}
                        >
                            <TimeAgo from={timeFrom} live={true} />
                        </time>
                        {isDesktop && (
                            <div className="c-card__controls c-card__controls--desktop">
                                {!isPrivate && (
                                    <BondFollowButton
                                        id={id}
                                        isFollowed={isFollowed}
                                        isMobile={false}
                                    />
                                )}
                                {!isSquadView && (
                                    <BondDismissButton
                                        id={id}
                                        channelId={channelId}
                                        isArchived={isArchived}
                                        isMobile={false}
                                    />
                                )}
                                <BondReadButton
                                    channelId={channelId}
                                    isRead={isRead}
                                    isMobile={false}
                                />
                            </div>
                        )}
                    </div>
                    <div className={detailWrapperClasses}>
                        <div className="c-card__details">
                            {!isRead && !isLive && (
                                <span
                                    className={notificationClasses}
                                >
                                </span>
                            )}
                            <p
                                className={summaryClasses}
                            >
                                <BondCardContributorsAndSummary bondId={id} />
                            </p>
                            <BondCardPrivacyDomain id={id} includeInvited={true} />
                        </div>
                        <BondCardPresence bondId={id} />
                    </div>
                </Swiper>
            </div>
        </div>
    );
}

export const BondCard = memo(BondCardInner);
