import { WebsocketRpcsInterface } from "@/api/transport";
import * as auth from "@/features/auth";
import { selectCurrentUserId } from "@/features/auth";
import { startManagingAuth } from "@/features/authManagement";
import * as bondCreation from "@/features/bondCreation";
import * as bonds from "@/features/bonds";
import * as calls from "@/features/calls";
import * as channels from "@/features/channels";
import * as messages from "@/features/chats";
import * as connection from "@/features/connection";
import {
    startDetectingOnlineStatus,
    startManagingConnection,
} from "@/features/connectionManagement";
import * as filterPanel from "@/features/filterPanel";
import * as intel from "@/features/intel";
import * as interest from "@/features/interest";
import * as mediaDevices from "@/features/mediaDevices";
import * as meta from "@/features/meta";
import { startDebugListening } from "@/features/middleware";
import * as notifications from "@/features/notifications";
import { proxiedThunkRegistry } from "@/features/proxiedThunk";
import * as search from "@/features/search";
import * as squads from "@/features/squads";
import { startConnectedListeners } from "@/features/streams";
import * as users from "@/features/users";
import log from "@/misc/log";
import { getOidcConfig } from "@/misc/oidcConfig";
import { createPerformanceEnhancer, timeFn } from "@/misc/perf";
import { startSharedWorkerWatchdog } from "@/misc/sharedWorkerWatchdog";
import { setPersistorMap } from "@/persist/map";
import { startPersisting } from "@/persist/persist";
import { indexedDbAvailable } from "@/persist/shared";
import { tagProxyAction } from "@/store/locations";
import {
    type AppAppendListener,
    type AppListenerMiddleware,
    appListenerMiddleware,
    startAppListening,
} from "@/store/middleware";
import {
    isProxiedRequest,
    isProxiedResponse,
    isThunkRequest,
    listenForProxiedDispatches,
    ProxiedMessageDirection,
    proxyMiddleware,
    startBroadcastingDispatches,
} from "@/store/proxy";
import { AppStore, PersistorMap, RootReducerMap, RootState, StoreConfig } from "@/store/types";
import { contextSchema } from "@/store/types";
import { isWorkerContext } from "@/workers/util";
import {
    combineReducers,
    configureStore,
    createDynamicMiddleware,
    PayloadAction,
} from "@reduxjs/toolkit";
import { z } from "zod";

const rootReducerMap: RootReducerMap = {
    auth: auth.reducer,
    bondCreation: bondCreation.reducer,
    bonds: bonds.reducer,
    calls: calls.reducer,
    channels: channels.reducer,
    connection: connection.reducer,
    filterPanel: filterPanel.reducer,
    intel: intel.reducer,
    interest: interest.reducer,
    mediaDevices: mediaDevices.reducer,
    messages: messages.reducer,
    meta: meta.reducer,
    notifications: notifications.reducer,
    search: search.reducer,
    squads: squads.reducer,
    users: users.reducer,
};

const combinedReducer = combineReducers({ ...rootReducerMap });

const rootPersistorMap: PersistorMap<RootState> = {
    auth: auth.persistor,
    bondCreation: bondCreation.persistor,
    bonds: bonds.persistor,
    calls: calls.persistor,
    channels: channels.persistor,
    connection: connection.persistor,
    filterPanel: filterPanel.persistor,
    intel: intel.persistor,
    interest: interest.persistor,
    mediaDevices: mediaDevices.persistor,
    messages: messages.persistor,
    meta: meta.persistor,
    notifications: notifications.persistor,
    search: search.persistor,
    squads: squads.persistor,
    users: users.persistor,
};

setPersistorMap(rootPersistorMap);

export type SetupStoreArgs = Partial<
    StoreConfig & {
        listenerMiddleware: AppListenerMiddleware;
        dispatchThunkResponse: (store: AppStore, action: PayloadAction<unknown>) => void;
        websocketRpcs: () => WebsocketRpcsInterface;
        context: z.infer<typeof contextSchema>;
        useSharedWorkerWatchdog: boolean;
    }
>;

export function storeContextToDefaultConfig(
    context: z.infer<typeof contextSchema>,
): Partial<StoreConfig> {
    switch (context) {
        case contextSchema.Enum.overseer:
            return {
                persist: { idb: true, storage: false },
                broadcast: true,
                proxy: false,
                manageAuth: true,
                manageConnection: true,
                startListeners: true,
            };
        case contextSchema.Enum.proxyTab:
            return {
                persist: { idb: false, storage: true },
                broadcast: false,
                proxy: true,
                manageAuth: false,
                manageConnection: false,
                startListeners: false,
            };
        case contextSchema.Enum.soloTab:
            return {
                persist: { idb: true, storage: true },
                broadcast: false,
                proxy: false,
                manageAuth: true,
                manageConnection: true,
                startListeners: true,
            };
    }
}

export function setupStoreInternal(preloadedState?: Partial<RootState>, args?: SetupStoreArgs) {
    preloadedState ??= {};

    const persistArg = args?.persist;
    const persist = typeof persistArg === "object" ? {
        idb: persistArg.idb ?? false,
        storage: persistArg.storage ?? false,
    } : {
        idb: persistArg ?? false,
        storage: persistArg ?? false,
    };
    const broadcast = args?.broadcast ?? false;
    const proxy = args?.proxy ?? false;
    const manageConnection = (args?.manageConnection ?? true) && !proxy;
    const startListeners = (args?.startListeners ?? false) && manageConnection;
    const debug = args?.debug ?? false;
    const manageAuth = args?.manageAuth ?? false;
    const measurePerf = args?.perf ?? true;

    if (proxy && args?.manageConnection) {
        throw new Error(`cannot proxy and manage connection`);
    }

    if (!manageConnection && args?.startListeners) {
        throw new Error(`cannot run listeners without managing connection`);
    }

    if (broadcast && proxy) {
        throw new Error(`cannot both redirect and broadcast dispatched actions`);
    }

    if (persist.idb && !indexedDbAvailable()) {
        log.warn(`IDB persistence requested but IndexedDB not available`);
        persist.idb = false;
    }

    if (persist.storage && isWorkerContext()) {
        log.warn(`Storage persistence requested but inside a worker`);
        persist.storage = false;
    }

    const dynamicMiddleware = createDynamicMiddleware();

    preloadedState.meta = meta.getInitialMetaState({
        ...preloadedState.meta,
        config: {
            persist,
            broadcast,
            proxy,
            manageConnection,
            startListeners,
            debug,
        },
    });

    const listenerMW = args?.listenerMiddleware ?? appListenerMiddleware;
    const appendListener: AppAppendListener = startAppListening(listenerMW);

    const store = configureStore({
        reducer: combinedReducer,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({
                // You may want to disable these locally to improve performance.
                // Set `immutableCheck` to false to disable completely.
                // immutableCheck: {
                //    warnAfter: 64,
                // },
                immutableCheck: false,
                // Set `serializableCheck` to false to disable completely.
                // We could set something on the HAMT to flag "this is checked
                // for serializable values already", but this doesn't seem to be
                // a particular bottleneck.
                // serializableCheck: {
                //   ignoredPaths: [
                //       "bonds.observers",
                //       "bonds.channelIndex",
                //       "bonds.catchupKnowledge",
                //   ],
                //   // warnAfter: 64,
                // },
                serializableCheck: false,
            })
                .prepend(listenerMW.middleware)
                .concat(dynamicMiddleware.middleware),
        enhancers: getDefaultEnhancers => {
            const enhancers = getDefaultEnhancers();
            if (measurePerf) return enhancers.concat(createPerformanceEnhancer());
            return enhancers;
        },
        preloadedState,
    });

    const cleanupFns: Array<() => void> = [];
    Object.assign(store, {
        cleanup: () => {
            cleanupFns.forEach(f => f());
        },
    });

    if (debug) {
        const c = startDebugListening(appendListener, debug);
        cleanupFns.push(c);
    }

    if (manageConnection) {
        const c = startDetectingOnlineStatus(store);
        cleanupFns.push(c);

        const cs = startManagingConnection(appendListener, { rpcs: args?.websocketRpcs });
        cleanupFns.push(...cs);
    }

    if (manageAuth) {
        const c = startManagingAuth(appendListener, { oidcConfig: () => getOidcConfig(undefined) });
        cleanupFns.push(c);
    }

    const userId = selectCurrentUserId(store.getState());

    if (startListeners) {
        const cs = startConnectedListeners(appendListener, userId);
        cleanupFns.push(...cs);
    }

    if (args?.useSharedWorkerWatchdog) {
        cleanupFns.push(startSharedWorkerWatchdog(store));
    }

    if (!userId) {
        return store;
    }

    if (proxy) {
        const mw = proxyMiddleware(userId);
        dynamicMiddleware.addMiddleware(mw);

        const c = listenForProxiedDispatches(
            userId,
            ProxiedMessageDirection.Broadcast,
            msg => {
                if (!isProxiedResponse(msg)) return;

                const { action } = msg;
                if (args?.dispatchThunkResponse) {
                    args.dispatchThunkResponse(store, action);
                }
                else {
                    store.dispatch(action);
                }
            },
        );
        cleanupFns.push(c);
    }

    if (broadcast) {
        const c1 = listenForProxiedDispatches(
            userId,
            ProxiedMessageDirection.Request,
            msg => {
                if (!isProxiedRequest(msg)) return;

                if (isThunkRequest(msg)) {
                    const { typePrefix, arg } = msg.thunk;
                    const thunk = proxiedThunkRegistry.get(typePrefix);

                    if (!thunk) {
                        log.error(`Proxied thunk ${typePrefix} not present in registry`);
                    }
                    else {
                        store.dispatch(tagProxyAction(thunk(arg)));
                    }
                }
                else {
                    store.dispatch(tagProxyAction(msg.action));
                }
            },
        );

        const c2 = startBroadcastingDispatches(userId, appendListener);

        cleanupFns.push(c1, c2);
    }

    if (persist.idb || persist.storage) {
        const dbUpgradeBlockingAction = () => {
            store.dispatch(meta.idbUpgradeRequired());
        };

        const c = startPersisting(persist, userId, dbUpgradeBlockingAction, appendListener);
        cleanupFns.push(c);
    }

    return store;
}

export const setupStore: typeof setupStoreInternal = (...args) =>
    timeFn(
        () => setupStoreInternal(...args),
        n => log.info(`Store setup took ${n}ms`),
    );
