import classNames from "classnames";
import { ReactEventHandler, useCallback, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { LightboxTitle } from "@/components/gui/LightboxTitle";
import { OfficialAttachment } from "@/domain/attachments";
import { credentialsValidWithin } from "@/domain/blobs";
import * as d from "@/domain/domain";
import { getAttachmentMsgId, getMsgAttachmentIds } from "@/domain/messages";
import {
    getAttachmentBlobUrlThunk,
    selectMessage,
    selectOfficialAttachments,
} from "@/features/chats";
import { MetaInterestCounterKey } from "@/features/interest";
import { useMetaInterest } from "@/hooks/interest/useInterest";
import useAddressParams from "@/hooks/useAddressParams";
import useDialogOpenRef from "@/hooks/useDialogOpenRef";
import useDialogOutsideClick from "@/hooks/useDialogOutsideClick";
import useDownloadAttachment from "@/hooks/useDownloadAttachment";
import { useNavigateBack } from "@/hooks/useNavigateBack";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { isInlinableImage } from "@/misc/attachments";
import log from "@/misc/log";
import { isMobileBrowser } from "@/misc/mobile";
import { Optional } from "@/misc/types";
import { useAppDispatch } from "@/store/redux";

export const attachmentLightboxVersion = 1;

type AttachmentLightBoxInnerProps = {
    attachment: OfficialAttachment;
    index: number;
    nextIndex: number;
    previousIndex: number;
    inlinableIndex: number;
    inlinableAttachmentsLength: number;
    anchorElementCreator?: () => HTMLAnchorElement;
    backendMsgId?: d.MessageId;
} & d.AttachmentMessageId;

function AttachmentLightboxInner(props: AttachmentLightBoxInnerProps): React.JSX.Element {
    const dispatch = useAppDispatch();
    const { navigate, navigateBack } = useNavigateBack({ defaultPath: "..", replaceDefault: true });

    const lightboxRef = useDialogOpenRef();
    const headerRef = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);
    const previousButtonRef = useRef<HTMLButtonElement>(null);
    const nextButtonRef = useRef<HTMLButtonElement>(null);

    const {
        attachment,
        index,
        nextIndex,
        previousIndex,
        inlinableIndex,
        inlinableAttachmentsLength,
        backendMsgId,
    } = props;
    const { id: attachmentId, credentials, metadata } = attachment;

    const closeLightbox = useCallback(() => {
        navigateBack();
    }, [navigateBack]);

    const singletonLightbox = useMemo(() => index === nextIndex, [index, nextIndex]);
    const navigateToNext = useCallback(() => {
        if (!singletonLightbox) {
            navigate(`../attachment/${attachmentLightboxVersion}/${nextIndex}`, { replace: true });
        }
    }, [navigate, nextIndex, singletonLightbox]);

    const navigateToPrevious = useCallback(() => {
        if (!singletonLightbox) {
            navigate(`../attachment/${attachmentLightboxVersion}/${previousIndex}`, {
                replace: true,
            });
        }
    }, [navigate, singletonLightbox, previousIndex]);

    // Ideally this should do nothing. The dialog should take up the whole screen
    // and the user will therefore not be able to click outside of it.
    // However, we will keep it to be safe.
    const handleBackdropClick = useDialogOutsideClick(lightboxRef, closeLightbox);

    const errorHandler: ReactEventHandler<HTMLImageElement> = useCallback(e => {
        e.preventDefault();
        e.stopPropagation();

        if (!backendMsgId || !credentials) {
            return;
        }

        if (!credentialsValidWithin(credentials, 0)) {
            return;
        }

        dispatch(getAttachmentBlobUrlThunk({
            blobId: attachmentId,
            messageId: backendMsgId,
        }));
    }, [backendMsgId, credentials, dispatch, attachmentId]);

    const downloadLinkContents = useDownloadAttachment(props);

    useMetaInterest(
        true,
        MetaInterestCounterKey.BlockHotkey,
    );
    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                closeLightbox();
                e.preventDefault();
            }
            if (e.key === "ArrowRight" && !singletonLightbox) {
                navigateToNext();
                e.preventDefault();
            }
            if (e.key === "ArrowLeft" && !singletonLightbox) {
                navigateToPrevious();
                e.preventDefault();
            }
            if (e.key === "Enter") {
                e.preventDefault();
            }
        };
        document.addEventListener("keydown", handleKeyPress);

        const handleOutsideClick = (e: MouseEvent) => {
            if (
                lightboxRef.current &&
                [
                    imageRef.current,
                    previousButtonRef.current,
                    nextButtonRef.current,
                    headerRef.current,
                ].every(x => !x?.contains(e.target as Node))
            ) {
                closeLightbox();
            }
        };

        const lbr = lightboxRef.current;
        lbr?.addEventListener("click", handleOutsideClick);

        return () => {
            lbr?.removeEventListener("click", handleOutsideClick);
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [closeLightbox, lightboxRef, navigateToNext, navigateToPrevious, singletonLightbox]);

    const headerClasses = classNames("c-lightbox__header", {
        "c-lightbox__header--desktop": !isMobileBrowser(),
    });

    const titleClasses = classNames("c-lightbox__title", {
        "c-lightbox__title--desktop": !isMobileBrowser(),
    });

    const controlClasses = classNames("c-lightbox__controls", {
        "c-lightbox__controls--desktop": !isMobileBrowser(),
    });

    return (
        <dialog
            className="c-lightbox"
            ref={lightboxRef}
            role="dialog"
            onMouseDown={handleBackdropClick}
        >
            <div className={headerClasses} ref={headerRef}>
                <h1
                    className={titleClasses}
                    title={metadata.fileName}
                >
                    <LightboxTitle
                        filename={metadata.fileName}
                        inlinableIndex={inlinableIndex}
                        inlinableAttachmentsLength={inlinableAttachmentsLength}
                    />
                </h1>
                <div className={controlClasses}>
                    <button
                        className="c-btn-download-file"
                        title={`Download '${metadata.fileName}'`}
                        onClick={downloadLinkContents}
                    >
                        Download file
                    </button>

                    <button
                        className="c-btn-close c-btn-close--lightbox"
                        title={`Close '${metadata.fileName}'`}
                        onClick={closeLightbox}
                    >
                        Close
                    </button>
                </div>
            </div>
            <div className="c-lightbox__content">
                <img
                    className="c-lightbox__image"
                    ref={imageRef}
                    src={credentials.url}
                    title={metadata.fileName}
                    onError={errorHandler}
                    width={metadata.dimensions?.width}
                    height={metadata.dimensions?.height}
                />

                {!singletonLightbox && (
                    <>
                        <button
                            className="c-btn-carousel c-btn-carousel--previous"
                            ref={previousButtonRef}
                            title={`Previous attachment`}
                            onClick={navigateToPrevious}
                        >
                            <span className="c-btn-carousel__icon c-btn-carousel__icon--previous">
                                Previous
                            </span>
                        </button>

                        <button
                            className="c-btn-carousel c-btn-carousel--next"
                            ref={nextButtonRef}
                            title={`Next attachment`}
                            onClick={navigateToNext}
                        >
                            <span className="c-btn-carousel__icon c-btn-carousel__icon--next">
                                Next
                            </span>
                        </button>
                    </>
                )}
            </div>
        </dialog>
    );
}

export default function AttachmentLightbox(): React.JSX.Element {
    const { attachmentIdx, messageId } = useAddressParams();

    const navigate = useNavigate();
    const closeLightbox = useCallback(() => {
        navigate("..", { replace: true });
    }, [navigate]);

    const message = useSelectorArgs(selectMessage, messageId);

    const attachmentIds: d.AnyAttachmentId[] = getMsgAttachmentIds(message);

    const attachments: Optional<OfficialAttachment>[] = useSelectorArgs(
        selectOfficialAttachments,
        attachmentIds,
    );

    const attachment = attachmentIdx != undefined ? attachments[attachmentIdx] : undefined;
    const attachmentMessageId = message && getAttachmentMsgId(message);

    // Carousel logic

    const inlineableAttachments = useMemo(
        () => attachments.filter(a => a && isInlinableImage(a.metadata.mimeType)),
        [attachments],
    );
    const currentInlnableAttachmentIdx = useMemo(() => {
        if (!attachment) {
            return 0;
        }

        return inlineableAttachments.indexOf(attachment);
    }, [attachment, inlineableAttachments]);

    const nextInlineableAttachmentTrueIdx = useMemo(() =>
        attachments.indexOf(
            inlineableAttachments[
                (currentInlnableAttachmentIdx + 1) %
                inlineableAttachments.length
            ],
        ), [attachments, currentInlnableAttachmentIdx, inlineableAttachments]);

    const previousInlineableAttachmentTrueIdx = useMemo(() =>
        attachments.indexOf(
            inlineableAttachments[
                (currentInlnableAttachmentIdx - 1 < 0) ?
                    (currentInlnableAttachmentIdx - 1 + inlineableAttachments.length) :
                    currentInlnableAttachmentIdx - 1
            ],
        ), [attachments, currentInlnableAttachmentIdx, inlineableAttachments]);

    // ¬ Carousel logic

    useEffect(() => {
        if (
            message == undefined || attachmentIdx == undefined ||
            (attachmentIdx > (attachments.length - 1) || 0 > attachmentIdx) ||
            attachment == undefined || attachmentMessageId == undefined ||
            !isInlinableImage(attachment.metadata.mimeType)
        ) {
            log.error("Closing broken lightbox: ", {
                message,
                attachmentIdx,
                attachment,
                attachmentMessageId,
            });
            closeLightbox();
        }
    }, [
        attachment,
        attachmentIdx,
        attachmentMessageId,
        attachments.length,
        closeLightbox,
        message,
    ]);

    if (
        messageId == undefined || message == undefined || attachmentIdx == undefined ||
        (attachmentIdx > (attachments.length - 1) || 0 > attachmentIdx) ||
        attachment == undefined || attachmentMessageId == undefined ||
        !isInlinableImage(attachment.metadata.mimeType)
    ) {
        return <></>;
    }

    return (
        <AttachmentLightboxInner
            attachment={attachment}
            backendMsgId={messageId}
            index={attachmentIdx}
            nextIndex={nextInlineableAttachmentTrueIdx}
            previousIndex={previousInlineableAttachmentTrueIdx}
            inlinableIndex={currentInlnableAttachmentIdx}
            inlinableAttachmentsLength={inlineableAttachments.length}
            {...attachmentMessageId}
        />
    );
}

export const messageAttachmentLightboxRoute = (
    bondId: d.BondId,
    msgId: d.MessageId,
    index: number,
): string =>
    `/bond/${d.extractUUID(bondId)}/message/${
        d.extractUUID(msgId)
    }/attachment/${attachmentLightboxVersion}/${index}`;
