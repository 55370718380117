import { Optional } from "@/misc/types";
import { Opts as LinkifyOpts } from "linkifyjs";
import { createContext, FC, PropsWithChildren, useContext } from "react";

const LinkifyOptsContext = createContext<Optional<LinkifyOpts>>(undefined);

interface LinkifyOptsProviderProps {
    opts: LinkifyOpts;
}

export const LinkifyOptsProvider: FC<PropsWithChildren<LinkifyOptsProviderProps>> = (
    { opts, children },
) => (
    <LinkifyOptsContext.Provider value={opts}>
        {children}
    </LinkifyOptsContext.Provider>
);

export const useLinkifyOpts = () => useContext(LinkifyOptsContext);
