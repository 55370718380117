import { assertProvidersMatch } from "@/domain/richtext/bridge";
import {
    Change,
    ChangeBridge,
    Doc,
    DocBridge,
    Format,
    Position,
    PositionRange,
    Provider,
    providerToString,
} from "@/domain/richtext/types";
import { selectRichTextProvider } from "@/features/channels";
import log from "@/misc/log";
import { Focusable, Optional, Point } from "@/misc/types";
import { useAppSelector } from "@/store/redux";
import { ForwardedRef, forwardRef, Ref, useImperativeHandle, useRef } from "react";
import QuillRichTextEditor from "./quill/QuillRichTextEditor";
import TiptapRichTextEditor from "./tiptap/TiptapRichTextEditor";

export interface SuggestionQuery<P extends Provider> {
    anchor: Point;
    trigger: string;
    text: string;
    range: PositionRange<P>;
}

export type RichTextSuggestionQueryHandler<P extends Provider> = (
    query: Optional<SuggestionQuery<P>>,
) => void;

export type RichTextEditorChangeHandler<P extends Provider> = (
    change: Change<P, Format.Internal>,
    isApiChange: boolean,
) => void;

export type RichTextEditorSelectHandler<P extends Provider> = (
    range: PositionRange<P>,
    isApiChange: boolean,
) => void;

export interface RichTextEditorProps<P extends Provider> {
    doc?: Doc<P, Format>;
    placeholder?: string;
    tabIndex?: number;
    onChange?: RichTextEditorChangeHandler<P>;
    onSelect?: RichTextEditorSelectHandler<P>;
    onSubmit?: () => void;
    onEscape?: () => void;
    onShiftEscape?: () => void;
    onFocus?: () => void;
    onBlur?: () => void;
    onSuggestionQuery?: RichTextSuggestionQueryHandler<P>;
}

export type RichTextEditorOps<P extends Provider> = Focusable & {
    setContent: (bridge: DocBridge<P>) => void;
    updateContent: (bridge: ChangeBridge<P>) => void;
    getOffsetScreenPos: (index: Optional<Position<P>>) => Optional<Point>;
};

export const RichTextEditor = forwardRef((
    // RichTextEditor could be backed by any provider at
    // runtime, so we can't use type parameters here
    props: RichTextEditorProps<Provider>,
    ref: ForwardedRef<RichTextEditorOps<Provider>>,
) => {
    const provider = useAppSelector(selectRichTextProvider);

    // Type-safe shim for ops ref (prevents wrong provider being passed in any op args)
    const unsafeRef = useRef<RichTextEditorOps<Provider>>();
    useImperativeHandle(ref, () => ({
        focus: options => unsafeRef.current?.focus(options),
        blur: () => unsafeRef.current?.blur(),
        hasFocus: () => unsafeRef.current?.hasFocus() ?? false,
        setContent: bridge => {
            try {
                assertProvidersMatch("setContent", provider, "bridge", bridge.p);
            }
            catch {
                bridge = bridge.convert(provider);
            }
            unsafeRef.current?.setContent(bridge);
        },
        updateContent: bridge => {
            assertProvidersMatch("updateContent", provider, "bridge", bridge.p);
            unsafeRef.current?.updateContent(bridge);
        },
        getOffsetScreenPos: index => {
            if (index !== undefined) {
                assertProvidersMatch("getOffsetScreenPos", provider, "index", index?.p);
            }
            return unsafeRef.current?.getOffsetScreenPos(index);
        },
    }), [unsafeRef, provider]);

    // Double-check the provider for the doc before passing to the underlying component
    if ((props.doc?.p ?? provider) != provider) {
        log.warn(
            "Providers don't agree in RichTextEditor render: " +
                `${providerToString(props.doc!.p)} (doc) != ` +
                `${providerToString(provider)} (provider)`,
        );
        return <></>;
    }

    // IMPORTANT: Any new props which directly or indirectly store a rich-text provider
    //            value must be checked here, similarly to the check on `doc.p`. This
    //            ensures the type assertions below are safe

    // Select underlying component based on rich-text provider value
    switch (provider) {
        case Provider.Quill:
            return (
                <QuillRichTextEditor
                    ref={unsafeRef as Ref<RichTextEditorOps<Provider.Quill>>}
                    {...props as RichTextEditorProps<Provider.Quill>}
                />
            );
        case Provider.Tiptap:
            return (
                <TiptapRichTextEditor
                    ref={unsafeRef as Ref<RichTextEditorOps<Provider.Tiptap>>}
                    {...props as RichTextEditorProps<Provider.Tiptap>}
                />
            );
    }
});
