import {
    AuthMachine,
    createAuthStateMachine,
    purgeAuthCache,
    runAuthStateMachine,
    State,
    TokenChangeDetails,
} from "@/auth/stateMachine";
import { OidcConfig } from "@/auth/types";
import * as d from "@/domain/domain";
import { Optional } from "@/misc/types";
import { purgeCache } from "@/persist/persist";
import { Action } from "@reduxjs/toolkit";
import log from "../misc/log";
import {
    type AppAppendListener,
    appAppendListener,
    statesDifferBySelectors,
} from "../store/middleware";
import { ListenerAPI, startLongLivedListener } from "../store/middleware";
import type { RootState } from "../store/types";
import {
    AuthStatus,
    clearOidc,
    resetStore,
    selectAuthStatus,
    selectCurrentPersonId,
    startAuthForUser,
    updateAuthStatus,
    updateOidc,
} from "./auth";
import { ConnectionStatus, selectConnectionStatus, updateConnectionStatus } from "./connection";

/**
 * Manages the authentication state machine,
 * using the AuthStatus in Redux to drive the state machine.
 * The auth state machine is not started until an auth action is dispatched
 * with the required bootrapping data.
 */

const authStartActions = [
    startAuthForUser,
];

const authStatusComparer = statesDifferBySelectors(
    selectAuthStatus,
    selectConnectionStatus,
    selectCurrentPersonId,
);

type ManageAuthParameters = {
    oidcConfig: () => Promise<OidcConfig>;
};

const toAuthStatus = (state: State) => {
    switch (state) {
        case "Initialising":
            return AuthStatus.Initialising;
        case "Refreshing":
            return AuthStatus.Refreshing;
        case "Authenticated":
            return AuthStatus.Authenticated;
        case "Error":
            return AuthStatus.ClearingAuthentication;
    }
};

const verySafeWrapper = async (
    t: Promise<unknown> | (() => Promise<unknown>) | undefined,
): Promise<void> => {
    if (t === undefined) return;

    try {
        const p = typeof t === "function" ? t() : t;
        await p;
    }
    catch (e) {
        log.warn(`Promise failed`, e);
    }
};

const cleanupOnLogout = async (dispatch: ListenerAPI["dispatch"], pid: Optional<d.PersonId>) => {
    log.info(`Resetting auth and purging cache`);

    try {
        await Promise.all([
            purgeCache,
            pid && purgeAuthCache(pid),
        ].map(verySafeWrapper));
    }
    catch (err) {
        log.warn(`Auth cleanup failed`, err);
    }

    await dispatch(resetStore());

    log.info(`Auth and cache purged, notifying tabs`);
    await dispatch(updateAuthStatus(AuthStatus.LoggedOutReloadRequired));
};

const getAuthStateMachine = (params: ManageAuthParameters) => {
    const { oidcConfig } = params;

    const transition = (api: ListenerAPI, status: AuthStatus) => {
        api.dispatch(updateAuthStatus(status));
    };

    let machine: Optional<AuthMachine> = undefined;
    let lastPersonId: Optional<d.PersonId> = undefined;

    const onAbort = () => {
        if (machine) {
            machine.stop(false).catch(_e => {});
        }
    };

    return async (api: ListenerAPI, state: RootState) => {
        const initialState = selectAuthStatus(state);
        const initialConnectionStatus = selectConnectionStatus(state);

        if (machine && initialConnectionStatus === ConnectionStatus.AuthFailure) {
            // Pre-emptively move to the refreshing state; the machine will do the same eventually in
            // authError handling. The important piece here is that the connection state machine knows
            // not to reconnect until we move back to Authenticated..
            transition(api, AuthStatus.Refreshing);
            machine.authError();

            // Now that we've both in the right state, and have told the machine to handle the error,
            // we can move the connection state machine back into a state where it is waiting on us.
            api.dispatch(updateConnectionStatus(ConnectionStatus.AwaitAuth));
        }

        // If we ever properly lose auth, we'll eventually end up here; so clean up our state
        if (
            machine &&
            (initialState === AuthStatus.ClearingAuthentication ||
                initialState === AuthStatus.Initialising)
        ) {
            await machine.stop(true);
            machine = undefined;
            api.signal.removeEventListener("abort", onAbort);

            await cleanupOnLogout(api.dispatch, lastPersonId);
            return;
        }

        // Return to caller to wait until we have a person ID to manage
        if (selectCurrentPersonId(api.getState()) === undefined) {
            return;
        }

        const personId = selectCurrentPersonId(api.getState());

        // Mostly to keep TS happy. The api.condition above should ensure this is never undefined.
        if (!personId) return;

        lastPersonId = personId;

        const onTokenChange = (details: TokenChangeDetails) => {
            let { token } = details;
            const { subject, validUntil } = details;

            if (subject === null) {
                api.dispatch(clearOidc());
                return;
            }

            if (validUntil !== null && Date.now() + 60 * 1000 >= validUntil) {
                token = null;
            }

            let personId: d.PersonId;
            try {
                personId = d.fromRawPersonId(subject);
            }
            catch (_e) {
                log.error("failed to parse OIDC person ID");
                return;
            }

            // XXX: consider calling setBearerToken explicitly here, and not storing
            // it in Redux at all. We could then remove the management from
            // connectionManagement.ts

            api.dispatch(updateOidc({
                token,
                personId,
            }));
        };
        const onStateChange = (state: State) => {
            log.info(`Auth state change: ${state}`);

            if (state !== "Authenticated") {
                api.dispatch(clearOidc());
            }

            transition(api, toAuthStatus(state));
        };

        if (!machine) {
            log.info(`Starting auth state machine for person ${personId}`);
            machine = createAuthStateMachine({
                oidc: await oidcConfig(),
                onTokenChange,
                onStateChange,
                personId,
            });

            // Run the state machine in the background.
            runAuthStateMachine(machine).catch(_e => {});

            api.signal.addEventListener("abort", onAbort, { once: true });
        }
    };
};

export const startManagingAuth = (
    appendListener: AppAppendListener = appAppendListener,
    params: ManageAuthParameters,
) => {
    const authStateMachine = getAuthStateMachine(params);

    const actionStartPredicate = (action: Action) =>
        authStartActions.some(a => action.type === a.type);

    const cleanup = startLongLivedListener(
        authStatusComparer,
        authStateMachine,
        "auth-manager",
        appendListener,
        actionStartPredicate,
    );

    return cleanup;
};
