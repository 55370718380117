import { FC, useCallback, useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { CloseButton } from "@/components/buttons/Close";
import PillSelector from "@/components/gui/PillSelector";
import {
    EmailInviteSuggestionContent,
    SquadInviteSuggestionContent,
    UserInviteSuggestionContent,
} from "@/components/NewBondAudienceInput";
import { AudienceMember, AudienceMemberOverview, isSquadOverview } from "@/domain/audience";
import * as d from "@/domain/domain";
import { invalidDraftTarget, newChannelDraftTarget } from "@/domain/draftTarget";
import {
    asPendingEmailMember,
    asPendingEmailOverview,
    extractPendingEmail,
    isPendingEmail,
    isPendingEmailOverview,
    PendingEmailMember,
    PendingEmailOverview,
} from "@/domain/invites";
import { squadNameForMention, userNameForMention } from "@/domain/mentions";
import { selectAudienceOverviews } from "@/features/audience";
import {
    inviteUserToBondViaEmailThunk,
    modifyBondMembershipThunk,
    selectBondById,
    selectValidSquadIdsForExistingBondAudience,
} from "@/features/bonds";
import { selectSquads } from "@/features/squads";
import { selectAllUserIds, selectUsers } from "@/features/users";
import { useInterestedContacts } from "@/hooks/interest/useInterestedContacts";
import useAudienceSuggestionDomain, {
    EmailSubdomainOptions,
    SquadSubdomainOptions,
    UserSubdomainOptions,
} from "@/hooks/useAudienceSuggestionDomain";
import useBooleanFeatureFlag from "@/hooks/useBooleanFeatureFlag";
import useDialogOpenRef from "@/hooks/useDialogOpenRef";
import useDialogOutsideClick from "@/hooks/useDialogOutsideClick";
import { useNavigateBack } from "@/hooks/useNavigateBack";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import useSet from "@/hooks/useSet";
import { BondChildRouteContext } from "@/misc/bondChildRouteContext";
import log from "@/misc/log";
import { useAppDispatch } from "@/store/redux";

type AudienceMemberOrEmail = AudienceMember | PendingEmailMember;
type AudienceMemberOrEmailOverview = AudienceMemberOverview | PendingEmailOverview;

const AudienceMemberPillContent: FC<{ data: AudienceMemberOrEmailOverview; }> = ({ data }) => {
    if (isPendingEmailOverview(data)) {
        return (
            <>
                <div className="u-truncate-auto">
                    Invite <strong>{extractPendingEmail(data.id)}</strong> by email
                </div>
            </>
        );
    }

    if (isSquadOverview(data)) {
        return (
            <>
                <div className="c-squad-icon c-squad-icon--small"></div>
                {squadNameForMention(data)}
            </>
        );
    }

    return <>{userNameForMention(data)}</>;
};

const getAudienceMemberTitle = (data: AudienceMemberOrEmailOverview) => {
    if (isPendingEmailOverview(data)) {
        return extractPendingEmail(data.id);
    }
    return data.name;
};

const getAddButtonText = (nUsers: number, nSquads: number): string => {
    const users = nUsers == 1 ? "1 user" : `${nUsers} users`;
    const squads = nSquads == 1 ? "1 squad" : `${nSquads} squads`;

    const inner = [nUsers > 0 && users, nSquads > 0 && squads].filter(s => s).join(" and ");

    return `Add ${inner} to bond`;
};

export default function InviteUsersToBondModal(): React.JSX.Element {
    const dispatch = useAppDispatch();
    const { navigateBack } = useNavigateBack({ defaultPath: "..", replaceDefault: true });
    const { bondId } = useOutletContext() as BondChildRouteContext;

    const bondOverview = useSelectorArgs(selectBondById, bondId);

    // The sketches have squads appear in the 'Add people' modal, but setting a
    // channel draft target hides squads from the suggestions at the moment.
    const draftTarget = useMemo(
        () => bondOverview ? newChannelDraftTarget(bondOverview.channelId) : invalidDraftTarget(),
        [bondOverview],
    );

    const closeModal = useCallback(() => {
        log.debug("Closing InviteUsersModal");
        navigateBack();
    }, [navigateBack]);

    const dialogRef = useDialogOpenRef();
    const handleBackdropClick = useDialogOutsideClick(dialogRef, closeModal);

    const [userLookupText, setUserLookupText] = useState("");

    const {
        add: addChosenEmail,
        remove: removeChosenEmail,
        has: emailIsChosen,
        sorted: sortedChosenEmails,
    } = useSet<PendingEmailMember>();

    const selectEmail = useCallback((email: string) => {
        if (!email) return;

        const pendingEmail = asPendingEmailMember(email);

        if (emailIsChosen(pendingEmail)) {
            removeChosenEmail(pendingEmail);
        }
        else {
            addChosenEmail(pendingEmail);
        }
    }, [emailIsChosen, removeChosenEmail, addChosenEmail]);

    const {
        add: addChosenUserId,
        remove: removeChosenUserId,
        has: userIdIsChosen,
        sorted: sortedChosenUserIds,
    } = useSet<d.UserId>();

    const chosenUsers = useSelectorArgs(selectUsers, sortedChosenUserIds);

    const selectUser = useCallback((userId: d.UserId) => {
        if (!userId) return;
        if (userIdIsChosen(userId)) {
            removeChosenUserId(userId);
        }
        else {
            addChosenUserId(userId);
        }
    }, [addChosenUserId, removeChosenUserId, userIdIsChosen]);

    const {
        add: addChosenSquadId,
        remove: removeChosenSquadId,
        has: squadIdIsChosen,
        sorted: sortedChosenSquadIds,
    } = useSet<d.SquadId>();

    const chosenSquads = useSelectorArgs(selectSquads, sortedChosenSquadIds);

    const selectSquad = useCallback((squadId: d.SquadId) => {
        if (!squadId) return;
        if (squadIdIsChosen(squadId)) {
            removeChosenSquadId(squadId);
        }
        else {
            addChosenSquadId(squadId);
        }
    }, [addChosenSquadId, removeChosenSquadId, squadIdIsChosen]);

    // Maintain interest in all squads and users that the backend can make known to
    // the user while this modal is open
    useInterestedContacts();

    const selectedUsersAndSquads = useSelectorArgs(selectAudienceOverviews, [
        ...sortedChosenSquadIds,
        ...sortedChosenUserIds,
    ]);

    const selected: AudienceMemberOrEmailOverview[] = useMemo(() => [
        ...selectedUsersAndSquads,
        ...sortedChosenEmails.map(asPendingEmailOverview),
    ], [
        selectedUsersAndSquads,
        sortedChosenEmails,
    ]);

    const selectAction = useCallback((id: AudienceMemberOrEmail) => {
        if (isPendingEmail(id)) {
            selectEmail(extractPendingEmail(id));
        }
        else if (d.isUserId(id)) {
            selectUser(id);
        }
        else if (d.isSquadId(id)) {
            selectSquad(id);
        }
    }, [selectEmail, selectUser, selectSquad]);

    const deselectAction = useCallback((id: AudienceMemberOrEmail) => {
        if (isPendingEmail(id)) {
            removeChosenEmail(id);
        }
        else if (d.isUserId(id)) {
            removeChosenUserId(id);
        }
        else if (d.isSquadId(id)) {
            removeChosenSquadId(id);
        }
    }, [removeChosenEmail, removeChosenUserId, removeChosenSquadId]);

    // Build the audience suggestion domain. The set of people valid to add to the bond
    // is any known user not already directly in the bond (indirectly via squad is still
    // of interest, because we want to be able to make them follow the bond).
    const allUserIds = useSelectorArgs(selectAllUserIds);
    const validUserIds = useMemo(() => {
        const idSet = new Set(allUserIds);
        if (bondOverview) {
            bondOverview.followers.forEach(id => idSet.delete(id));
            bondOverview.contributors.forEach(id => idSet.delete(id));
        }
        return [...idSet.values()];
    }, [allUserIds, bondOverview]);

    const validSquadIds = useSelectorArgs(
        selectValidSquadIdsForExistingBondAudience,
        draftTarget,
        bondId,
    );

    const emailSubdomainOptions = useMemo<EmailSubdomainOptions>(
        () => ({
            query: userLookupText,
            Content: EmailInviteSuggestionContent,
            action: u => selectEmail(u),
            // overrideHighlight: u => emailIsChosen(u),
        }),
        [userLookupText, selectEmail],
    );
    const userSubdomainOptions = useMemo<UserSubdomainOptions>(
        () => ({
            idsToFilter: validUserIds.filter(uid => !userIdIsChosen(uid)),
            Content: UserInviteSuggestionContent,
            action: u => selectUser(u.id),
            overrideHighlight: u => userIdIsChosen(u.id),
        }),
        [validUserIds, selectUser, userIdIsChosen],
    );
    const squadSubdomainOptions = useMemo<SquadSubdomainOptions>(
        () => ({
            ids: validSquadIds.filter(sid => !squadIdIsChosen(sid)),
            Content: SquadInviteSuggestionContent,
            action: u => selectSquad(u.id),
            overrideHighlight: u => squadIdIsChosen(u.id),
        }),
        [validSquadIds, selectSquad, squadIdIsChosen],
    );

    // Disable adding-by-email if the invites feature flag is off
    const bondInvitesEnabled = useBooleanFeatureFlag("early-bond-invite-buttons");

    const flaggedEmailSubdomainOptions = bondInvitesEnabled ? emailSubdomainOptions : undefined;

    const suggestionDomain = useAudienceSuggestionDomain({
        email: flaggedEmailSubdomainOptions,
        user: userSubdomainOptions,
        squad: squadSubdomainOptions,
    });

    const inviteAllChosen = useCallback(() => {
        log.info(
            "Adding users and squads to bond",
            sortedChosenUserIds,
            sortedChosenSquadIds,
            sortedChosenEmails,
            bondId,
        );

        if (sortedChosenUserIds.length !== 0 || sortedChosenSquadIds.length !== 0) {
            dispatch(
                modifyBondMembershipThunk({
                    bondId,
                    userIdsToAdd: sortedChosenUserIds,
                    squadIdsToAdd: sortedChosenSquadIds,
                }),
            );
        }

        // TODO: bulk action, not lots of individual ones
        sortedChosenEmails.forEach(invitedEmailAddress =>
            dispatch(inviteUserToBondViaEmailThunk({
                bondId,
                invitedEmailAddress: extractPendingEmail(invitedEmailAddress),
            }))
        );

        closeModal();
    }, [
        closeModal,
        dispatch,
        sortedChosenUserIds,
        sortedChosenSquadIds,
        sortedChosenEmails,
        bondId,
    ]);

    const buttonText = getAddButtonText(
        sortedChosenEmails.length + chosenUsers.length,
        chosenSquads.length,
    );

    const placeholder = bondInvitesEnabled ? "Name or mail@example.com" : "Name";

    if (!bondOverview) return <></>;

    return (
        <dialog
            className="c-dialog c-dialog--add-people"
            onClose={closeModal}
            onMouseDown={handleBackdropClick}
            ref={dialogRef}
            role="dialog"
        >
            <header className="c-dialog__header c-dialog__header--centered">
                <h1 className="c-dialog__title">Add people</h1>
                <CloseButton side="right" onClick={closeModal} />
            </header>

            <article className="c-dialog__content-wrapper">
                <div className="c-dialog__content c-dialog__content--has-scroll">
                    <PillSelector
                        label=""
                        placeholder={placeholder}
                        PillContent={AudienceMemberPillContent}
                        getPillTitle={getAudienceMemberTitle}
                        selected={selected}
                        selectAction={selectAction}
                        deselectAction={deselectAction}
                        suggestionDomain={suggestionDomain}
                        dialogMode={true}
                        queryChanged={setUserLookupText}
                    />
                </div>
            </article>

            <div className="c-dialog__footer c-dialog__footer--new">
                <button
                    className="c-btn-solid c-btn-solid--add"
                    onClick={inviteAllChosen}
                    disabled={chosenUsers.length == 0 && chosenSquads.length == 0 &&
                        sortedChosenEmails.length == 0}
                >
                    {buttonText}
                </button>
            </div>
        </dialog>
    );
}
