import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";

import { ButtonMain } from "@/components/buttons/ButtonMain";
import { CloseButton } from "@/components/buttons/Close";
import { TextInput } from "@/components/gui/TextInput";
import { renameSquadThunk, selectSquadById, selectSquadIdFromViewStack } from "@/features/squads";
import useViewStackNavigate from "@/hooks/navigation/useViewStackNavigate";
import useDialogOpenRef from "@/hooks/useDialogOpenRef";
import useDialogOutsideClick from "@/hooks/useDialogOutsideClick";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import log from "@/misc/log";
import { isMobileBrowser } from "@/misc/mobile";
import { setOnChange } from "@/misc/utils";
import { useAppDispatch, useAppSelector } from "@/store/redux";

export default function RenameSquadModal(): React.JSX.Element {
    const dispatch = useAppDispatch();
    const { navigatePop, navigateRemoveSettingsModals } = useViewStackNavigate();

    const closeModal = useCallback(() => {
        navigatePop();
    }, [navigatePop]);

    const squadId = useAppSelector(selectSquadIdFromViewStack);
    const squadOverview = useSelectorArgs(selectSquadById, squadId);
    const squadName = squadOverview?.name ?? "";

    const closeAllSettings = useCallback(() => {
        navigateRemoveSettingsModals();
    }, [navigateRemoveSettingsModals]);

    const dialogRef = useDialogOpenRef();
    const handleBackdropClick = useDialogOutsideClick(dialogRef, closeModal);

    const [newSquadName, setNewSquadName] = useState(squadName);
    const nameTooShort = newSquadName.length <= 0;
    const nameTooLong = newSquadName.length > 64;
    const validName = !nameTooShort && !nameTooLong;

    const confirmRename = useCallback(() => {
        if (!squadId || squadOverview?.name === newSquadName) {
            return;
        }

        dispatch(
            renameSquadThunk({
                squadId: squadId,
                newName: newSquadName,
            }),
        ).unwrap().then(closeModal).catch(
            e => {
                log.error("Error renaming squad:", e);
                alert(`Failed to rename squad.`);
            },
        );
    }, [dispatch, closeModal, squadId, squadOverview?.name, newSquadName]);

    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            if (e.key === "Enter") {
                confirmRename();
            }
        };
        document.addEventListener("keypress", handleKeyPress);

        return () => {
            document.removeEventListener("keypress", handleKeyPress);
        };
    }, [confirmRename]);

    const buttonText = "Save";
    const placeholder = "Squad name";

    const isMobile = isMobileBrowser();
    const isDesktop = !isMobile;

    const dialogClass = classNames("cp-dialog", {
        "cp-dialog--desktop-notification": isDesktop,
    });

    return (
        <dialog
            className={dialogClass}
            onClose={closeModal}
            onMouseDown={handleBackdropClick}
            ref={dialogRef}
            role="dialog"
        >
            <header className="cp-dialog__header">
                <button className="cp-btn-back" onClick={closeModal}>Return</button>
                <h1 className="cp-dialog__title">Change name</h1>
                <CloseButton side="right" onClick={closeAllSettings} />
            </header>

            <article className="cp-dialog__content-wrapper">
                <div className="cp-dialog__content">
                    <TextInput
                        type="text"
                        id="squadName"
                        label={placeholder}
                        value={newSquadName}
                        hasError={nameTooLong}
                        onChange={setOnChange(setNewSquadName)}
                        hasFocus
                    />
                    <ButtonMain
                        label={buttonText}
                        onClick={confirmRename}
                        isDisabled={!validName}
                    />
                </div>
            </article>
        </dialog>
    );
}
