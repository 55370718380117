import {
    ConnectionStatus,
    selectConnectionStatus,
    selectOnlineStatus,
} from "@/features/connection";
import { backendRTTDebounceDuration } from "@/misc/backend";
import { useAppSelector } from "@/store/redux";
import useDebounce from "./useDebounce";

function isOnline(status: ConnectionStatus, online: boolean): boolean {
    if (!online) {
        return false;
    }

    switch (status) {
        // For ShouldConnect, `!online` above takes precendence
        // - we remain in `ShouldConnect` until we are `online`.
        case ConnectionStatus.AwaitReconnect:
        case ConnectionStatus.AwaitAuth:
        case ConnectionStatus.AuthFailure:
            return false;
        case ConnectionStatus.ShouldConnect:
        case ConnectionStatus.Connected:
        default:
            return true;
    }
}

/**
 * @function useDebouncedOnlineStatus
 *
 * @returns Whether the user is "online" (i.e. browser thinks there is an
 * internet connection and the WebSocket is connected),
 * debounced by `backendRTTDebounceDuration`.
 */
export default function useDebouncedOnlineStatus(): boolean {
    const connectionStatus = useAppSelector(selectConnectionStatus);
    const hasInternetConnection = useAppSelector(selectOnlineStatus);

    return useDebounce(
        isOnline(connectionStatus, hasInternetConnection),
        backendRTTDebounceDuration,
    );
}
