import * as clients_pb from "../../gen/proto/clients/clients_pb";
import * as devices_pb from "../../gen/proto/devices/devices_pb";
import * as domain_pb from "../../gen/proto/domain/domain_pb";
import { IdentityService } from "../../gen/proto/identity/identity_connect";
import * as identity_pb from "../../gen/proto/identity/identity_pb";
import { UserService } from "../../gen/proto/users/users_connect";
import * as users_pb from "../../gen/proto/users/users_pb";

import clientService from "@/api/client";
import { streamHandler } from "@/api/stream";
import { transport } from "@/api/transport";
import {
    fromProtoBlobId,
    fromProtoOrgId,
    fromProtoPersonId,
    fromProtoTimestamp,
    fromProtoUserId,
    fromProtoUserSet,
    pbBlobId,
    pbUserId,
    toProtoPersonSet,
    toProtoUserSet,
} from "@/api/util";
import {
    BlobCredentials,
    BlobMetadata,
    blobMetadataSchema,
    BlobOwnershipDetails,
} from "@/domain/blobs";
import * as d from "@/domain/domain";
import { fromProtoPresenceMode, PresenceMode } from "@/domain/presence";
import {
    AvatarBlob,
    PersonalActivity,
    PersonalAvatar,
    PersonOverview,
    UserDefinition,
    UserOverview,
} from "@/domain/users";
import { deepFreeze } from "@/misc/deepFreeze";
import { translateAsyncIterable } from "@/misc/iterable";
import { Diff, Optional } from "@/misc/types";
import { createClient } from "@connectrpc/connect";
import { parseBufMessage } from "./parser";

export const identityService = createClient(IdentityService, transport);
export const userService = createClient(UserService, transport);
export default userService;

export function translateUserDefinition(definition: domain_pb.UserDefinition): UserDefinition {
    const ud: UserDefinition = {
        userId: fromProtoUserId(definition.userId),
        personId: fromProtoPersonId(definition.personId),
        orgId: fromProtoOrgId(definition.orgId),
    };
    return deepFreeze(ud);
}

function translateUserOverview(overview: users_pb.UserOverview): UserOverview {
    const uo: UserOverview = {
        id: fromProtoUserId(overview.id),
        orgId: fromProtoOrgId(overview.orgId),
        personId: fromProtoPersonId(overview.personId),
        email: overview.personalInfo?.email || "",
        name: overview.personalInfo?.displayName || "",
        nickname: overview.personalInfo?.nickname || "",
        activity: translatePersonalActivity(overview.activity),
        picture: translatePersonalAvatar(overview.avatar),
    };
    return deepFreeze(uo);
}

function translatePersonOverview(overview: users_pb.PersonOverview): PersonOverview {
    const po: PersonOverview = {
        personId: fromProtoPersonId(overview.id),
        email: overview.personalInfo?.email || "",
        name: overview.personalInfo?.displayName || "",
        nickname: overview.personalInfo?.nickname || "",
        picture: translatePersonalAvatar(overview.avatar),
    };
    return deepFreeze(po);
}

function translatePersonalActivity(
    activity?: devices_pb.PersonalActivity,
): Optional<PersonalActivity> {
    if (!activity) {
        return undefined;
    }
    return {
        ...(activity.currentlyConnected ? {
            connected: true,
            connectedAt: fromProtoTimestamp(activity.connectionTs.value),
        } : {
            connected: false,
            disconnectedAt: fromProtoTimestamp(activity.connectionTs.value),
        }),
        ...(activity.currentlyActive ? {
            active: true,
            activeSince: fromProtoTimestamp(activity.activityTs.value),
        } : {
            active: false,
            inactiveSince: fromProtoTimestamp(activity.activityTs.value),
        }),
        presenceModes: activity.currentPresence.map(fromProtoPresenceMode)
            .filter((m): m is PresenceMode => m !== undefined),
    };
}

function translatePersonalAvatar(avatar?: users_pb.PersonalAvatar): Optional<PersonalAvatar> {
    if (!avatar) {
        return undefined;
    }
    return {
        blobId: fromProtoBlobId(avatar.blobId),
    };
}

export type UserOverviewDU = {
    case: "userOverview";
    value: UserOverview;
};

export type UserDeletedDU = {
    case: "deletedId";
    value: d.UserId;
};

export type UserOverviewOrDeleted = UserOverviewDU | UserDeletedDU;

function usersParser(res: users_pb.SubUsersResponse): UserOverviewOrDeleted {
    switch (res.overviewOrDeleted?.case) {
        case "userOverview":
            return {
                case: "userOverview",
                value: translateUserOverview(res.overviewOrDeleted.value),
            };
        case "deletedId":
            return { case: "deletedId", value: fromProtoUserId(res.overviewOrDeleted.value) };
        default:
            throw new Error(`unexpected overviewOrDeleted case: ${res.overviewOrDeleted?.case}`);
    }
}

async function* translateUserDiff(v: Diff<d.UserId>) {
    if (v.added?.length) {
        yield new users_pb.SubUsersRequest({
            userIds: toProtoUserSet(v.added),
            addToSub: true,
        });
    }
    if (v.removed?.length) {
        yield new users_pb.SubUsersRequest({
            userIds: toProtoUserSet(v.removed),
            addToSub: false,
        });
    }
}

export async function* subUsers(
    reqStream: AsyncIterableIterator<Diff<d.UserId>>,
    signal: AbortSignal,
) {
    const translation = translateAsyncIterable(reqStream, translateUserDiff);

    const stream = userService.subUsers(translation, { signal });

    yield* streamHandler(stream, usersParser, subUsers.name);
}

export type PersonOverviewDU = {
    case: "person";
    value: PersonOverview;
};

export type PersonDeletedDU = {
    case: "deletedId";
    value: d.PersonId;
};

export type PersonOverviewOrDeleted = PersonOverviewDU | PersonDeletedDU;

function personsParser(res: users_pb.SubPersonsResponse): PersonOverviewOrDeleted {
    switch (res.personOrDeleted?.case) {
        case "person":
            return {
                case: "person",
                value: translatePersonOverview(res.personOrDeleted.value),
            };
        case "deletedId":
            return { case: "deletedId", value: fromProtoPersonId(res.personOrDeleted.value) };
        default:
            throw new Error(`unexpected personOrDeleted case: ${res.personOrDeleted?.case}`);
    }
}

async function* translatePersonDiff(v: Diff<d.PersonId>) {
    if (v.added?.length) {
        yield new users_pb.SubPersonsRequest({
            personIds: toProtoPersonSet(v.added),
            addToSub: true,
        });
    }
    if (v.removed?.length) {
        yield new users_pb.SubPersonsRequest({
            personIds: toProtoPersonSet(v.removed),
            addToSub: false,
        });
    }
}

export async function* subPersons(
    reqStream: AsyncIterableIterator<Diff<d.PersonId>>,
    signal: AbortSignal,
) {
    const translation = translateAsyncIterable(reqStream, translatePersonDiff);

    const stream = userService.subPersons(translation, { signal });

    yield* streamHandler(stream, personsParser, subPersons.name);
}

const validAvatarImageTypes = new Set([
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/webp",
]);

export function isValidAvatarMimeType(mimeType: string): boolean {
    return validAvatarImageTypes.has(mimeType);
}

export interface CreateAvatarImageArgs {
    properties: BlobMetadata;
    ownership: BlobOwnershipDetails;
}
export async function createAvatarImage(args: CreateAvatarImageArgs): Promise<BlobCredentials> {
    if (!isValidAvatarMimeType(args.properties.mimeType)) {
        throw new Error("Invalid mime type for an avatar");
    }

    const req = new users_pb.CreateUserAvatarImageRequest({
        properties: args.properties,
        userId: pbUserId(args.ownership.uploaderId),
    });

    const { response } = await userService.createUserAvatarImage(req);

    if (!response) {
        throw new Error("Received no response in response");
    }
    if (!response.blobId) {
        throw new Error("Received no blobId in response");
    }
    if (!response.uploadSasUrl) {
        throw new Error("Received no sasUrl in response");
    }
    if (response.uploadSasUrl.url == "") {
        throw new Error("Received no url in response");
    }

    return {
        type: "upload",
        blobId: fromProtoBlobId(response.blobId),
        url: response.uploadSasUrl.url,
        urlExpiry: fromProtoTimestamp(response.uploadSasUrl.urlExpiry),
    };
}

export interface DeleteAvatarImageArgs {
    userId: d.UserId;
}
export async function deleteAvatarImage(args: DeleteAvatarImageArgs): Promise<void> {
    const req = new users_pb.DeleteUserAvatarImageRequest({
        userId: pbUserId(args.userId),
    });

    await userService.deleteUserAvatarImage(req);
}

export interface UpdateUserDetailsArgs {
    userId: d.UserId;
    displayName: string;
    nickname: string;
}
export async function updateUserDetails(args: UpdateUserDetailsArgs): Promise<void> {
    const req = new users_pb.UpdateUserDetailsRequest({
        userId: pbUserId(args.userId),
        displayName: args.displayName,
        nickname: args.nickname,
    });

    await userService.updateUserDetails(req);
}

export interface AvatarCropRectangle {
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface CompleteAvatarImageArgs {
    blobId: d.BlobId;
    completerId: d.UserId;
    cropRectangle: AvatarCropRectangle;
}
export async function completeAvatarImage(args: CompleteAvatarImageArgs) {
    const req = new users_pb.CompleteUserAvatarImageRequest({
        request: {
            blobId: pbBlobId(args.blobId),
            completerId: pbUserId(args.completerId),
        },
        cropRectangle: {
            x: args.cropRectangle.x,
            y: args.cropRectangle.y,
            width: args.cropRectangle.width,
            height: args.cropRectangle.height,
        },
    });

    await userService.completeUserAvatarImage(req);
}

export interface FetchAvatarBlobUrlArgs {
    blobId: d.BlobId;
}
export async function fetchAvatarBlobDownloadUrl(
    { blobId }: FetchAvatarBlobUrlArgs,
): Promise<Optional<AvatarBlob>> {
    const req = new users_pb.GetAvatarBlobUrlRequest({
        blobId: pbBlobId(blobId),
    });

    const resp = await userService.getAvatarBlobUrl(req);

    if (!resp.publicUrl || !resp.properties) {
        return undefined;
    }

    return {
        blobId,
        publicUrl: resp.publicUrl,
        metadata: blobMetadataSchema.parse(parseBufMessage(resp.properties)),
    };
}

export interface FetchPersonByEmailArgs {
    email: string;
}
export async function fetchPersonByEmail(
    args: FetchPersonByEmailArgs,
): Promise<Optional<d.PersonId>> {
    const req = new identity_pb.GetPersonByEmailRequest({
        email: args.email,
    });

    const resp = await identityService.getPersonByEmail(req);

    return resp.personId && fromProtoPersonId(resp.personId);
}

export interface GetContactsBookArgs {
    userId: d.UserId;
}

export async function fetchContactsBook(
    args: GetContactsBookArgs,
): Promise<Array<d.UserId>> {
    const req = new clients_pb.GetContactsBookRequest({
        userId: pbUserId(args.userId),
    });

    const resp = await clientService.getContactsBook(req);

    return fromProtoUserSet(resp.userIds);
}

export type BackendInfo = {
    version: string;
    instance: string;
};
