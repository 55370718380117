export const optionalifyBoolean = (key: string) => (obj: any) => {
    obj[key] = obj[key] ?? false;
    return obj;
};

export const optionalifyString = (key: string) => (obj: any) => {
    obj[key] = obj[key] ?? "";
    return obj;
};

export const optionalifyArray = (key: string) => (obj: any) => {
    obj[key] = obj[key] ?? [];
    return obj;
};

export const updateValue = (key: string, updater: (v: any) => any) => (obj: any) => {
    obj[key] = updater(obj[key]);
    return obj;
};

export const updateValues = (key: string, updater: (v: any) => any) => (obj: any) => {
    obj[key] = obj[key].map(updater);
    return obj;
};
