import classNames from "classnames";
import React, { useCallback, useMemo } from "react";

import { BondCardPrivacyDomain, Swiper } from "@/components/BondCard";
import TimeAgo from "@/components/gui/TimeAgo";
import { BondInvite } from "@/domain/invites";
import { getUserFullName, getUserNickname } from "@/domain/users";
import { viewUtils } from "@/domain/views";
import { selectCurrentUserId } from "@/features/auth";
import { selectBondById } from "@/features/bonds";
import { selectUser } from "@/features/users";
import { useInterestedBond, useInterestedUsers } from "@/hooks/interest/useInterest";
import useViewStackNavigate from "@/hooks/navigation/useViewStackNavigate";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { isMobileBrowser } from "@/misc/mobile";
import { useAppSelector } from "@/store/redux";

export function BondInviteCard(props: { invite: BondInvite; }): React.JSX.Element {
    const { bondId, body: { opaqueCode, expiry, createdAt, originatorId } } = props.invite;
    const { navigatePush } = useViewStackNavigate();

    const currentUserId = useAppSelector(selectCurrentUserId);

    useInterestedUsers(originatorId);
    const originator = useSelectorArgs(selectUser, originatorId);
    const originatorNickName = getUserNickname(originator);
    const originatorFullName = getUserFullName(originator);
    useInterestedBond(bondId);
    const bond = useSelectorArgs(selectBondById, bondId);

    const alreadyInBond = useMemo(() => {
        return bondId && bond && currentUserId &&
            bond.followers.includes(currentUserId);
    }, [currentUserId, bondId, bond]);

    const inviteLabel = "Bond invitation";
    const title = bond?.knowledge.aiGeneratedTitle || bond?.knowledge.userSpecifiedTitle;
    const inviteTitle = `${originatorNickName} invited you to ` +
        (title ? `"${title}"` : "a new bond");

    const expired = expiry.expired;
    const timeFrom = createdAt;

    const onCardClick = useCallback(() => {
        navigatePush(viewUtils.inviteRedemption(opaqueCode));
    }, [navigatePush, opaqueCode]);

    const isMobile = isMobileBrowser();
    const isDesktop = !isMobile;

    const topClasses = classNames("c-card", {
        "c-card--desktop": isDesktop,
    });
    const contentClasses = classNames("c-card__content", {
        "c-card__content--desktop": isDesktop,
    });
    const notificationClasses = classNames("c-card-notification", {
        "c-card-notification--desktop": isDesktop,
    });
    const wrapperClasses = classNames("c-card-wrapper", {
        "c-card-wrapper--desktop": isDesktop,
    });
    const detailWrapperClasses = classNames("c-card__details-wrapper", {
        "c-card__details-wrapper--desktop": isDesktop,
    });
    const titleClasses = classNames("c-card__title", "u-clamp_1");
    const summaryClasses = classNames("c-card__summary", "u-clamp_2");
    const timeClasses = classNames("c-card__time");

    if (alreadyInBond || expired) {
        return <></>;
    }
    const ariaLabel = `Open bond ` + (title ? `'${title}'` : "");

    return (
        <div
            className={wrapperClasses}
            onClick={onCardClick}
            aria-label={ariaLabel}
            role="button"
            tabIndex={0}
        >
            <div className={topClasses}>
                <Swiper id={opaqueCode} className={contentClasses}>
                    <div className="c-card__header">
                        <h3 className={titleClasses}>
                            {inviteLabel}
                        </h3>
                        <time
                            className={timeClasses}
                            dateTime={new Date(timeFrom).toISOString()}
                        >
                            <TimeAgo from={timeFrom} live={true} />
                        </time>
                    </div>
                    <div className={detailWrapperClasses}>
                        <div className="c-card__details">
                            <span className={notificationClasses} />
                            <p className={summaryClasses}>
                                <span className="c-card__ai-summary" />
                                {inviteTitle}
                            </p>
                            <BondCardPrivacyDomain
                                id={bondId}
                                includeInvited={false}
                                leader={originatorFullName}
                            />
                        </div>
                    </div>
                </Swiper>
            </div>
        </div>
    );
}
