import {
    createBondFromCall,
    CreateBondFromCallParams,
    createBondFromMsg,
    CreateBondFromMsgParams,
} from "@/api/bondCreation";
import { AnyLocalAttachment, isUploadedAttachment, ProposedAttachment } from "@/domain/attachments";
import { AudienceMember } from "@/domain/audience";
import {
    convertDraftContentToV3,
    convertDraftProvider,
    DraftChatContent,
    DraftChatContent_V1,
    getDraftMessageDoc,
    withChange,
    withDoc,
} from "@/domain/draftChatContent";
import {
    DraftTarget,
    draftTargetToSquadId,
    isBondCreationDraftTarget,
    WithDraftTarget,
} from "@/domain/draftTarget";
import {
    convertDraftToUnsentMessage,
    DraftChatMessage,
    emptyDraftChatMessage,
} from "@/domain/messages";
import { changeBridge, docBridge } from "@/domain/richtext/bridge";
import {
    defaultProvider,
    Format,
    Provider,
    providerFromString,
    providerToString,
} from "@/domain/richtext/types";
import { BondCreationBucketViewName, bondCreationBucketViewNameSchema } from "@/domain/views";
import { resetStore, selectCurrentUserId } from "@/features/auth";
import { emojiRegex } from "@/features/bonds";
import {
    addAttachmentsToDraft,
    clearAttachmentsFromDraft,
    richTextProviderStore,
    selectLocalAttachmentsDraftForMessage,
    setRichTextProvider,
    updateDraftDoc,
} from "@/features/channels";
import { deleteAttachmentFromDraftThunk, selectLocalAttachment } from "@/features/chats";
import { fetchBondTitleSuggestionThunk } from "@/features/intel";
import { createLocalAsyncThunk } from "@/features/localThunk";
import { selectValidSquadIdsForMention, selectValidUserIdsForMention } from "@/features/mentions";
import { createProxiedAsyncThunk } from "@/features/proxiedThunk";
import { memoizeOptions } from "@/features/selectors";
import { unaryThunkHandler } from "@/features/thunk";
import log from "@/misc/log";
import { ExpandType, Optional } from "@/misc/types";
import { checkPersistor, sessionStorageStore } from "@/persist/shared";
import { storageRead, storageRemove } from "@/persist/storage";
import { HydrateArgs, PersistenceUpdate } from "@/persist/types";
import { createLocalSlice } from "@/store/localSlice";
import { createAppSelector } from "@/store/redux";
import { RootState } from "@/store/types";
import { original, PayloadAction } from "@reduxjs/toolkit";
import { z } from "zod";

type CreateBondFromMessageArgs = ExpandType<
    & Omit<CreateBondFromMsgParams, "senderId" | "msg" | "officialAttachmentIds">
    & { msg: DraftChatMessage; }
    // This is a bit naff - probably shouldn't be necessary based on the data
    // we have available.
    & WithDraftTarget
>;
export const createBondFromMessageThunk = createProxiedAsyncThunk(
    "bonds/createFromMessage",
    async (args: CreateBondFromMessageArgs, thunkAPI) => {
        const { draftTarget } = args;
        if (!isBondCreationDraftTarget(draftTarget)) {
            // Throwing here avoids an awkward `!== undefined` check against
            // the result of the `dispatch`.
            throw new Error(
                `Must pass a bond-creation draft target to create bond: ${draftTarget}`,
            );
        }

        const state = thunkAPI.getState();
        const senderId = selectCurrentUserId(state)!;

        const msg = convertDraftToUnsentMessage(args.msg, Date.now());
        if (!msg) {
            log.warn("Cannot create bond: failed to convert draft to unsent message");
            return thunkAPI.rejectWithValue({ error: "Failed to convert draft to unsent message" });
        }

        const officialAttachmentIds = selectOfficialAttachmentIdsForBondCreationMessage(
            state,
            draftTarget,
        );

        return await unaryThunkHandler(
            thunkAPI,
            createBondFromMsg({
                ...args,
                senderId,
                msg,
                officialAttachmentIds,
            }),
            "createBondFromMessage",
        );
    },
);

type CreateBondFromCallArgs = ExpandType<
    & Omit<CreateBondFromCallParams, "senderId" | "clientTxTs">
    & WithDraftTarget
>;
export const createBondFromCallThunk = createProxiedAsyncThunk(
    "bonds/createFromCall",
    async (args: CreateBondFromCallArgs, thunkAPI) => {
        const state = thunkAPI.getState();
        const senderId = selectCurrentUserId(state)!;

        return await unaryThunkHandler(
            thunkAPI,
            createBondFromCall({
                ...args,
                senderId,
                clientTxTs: Date.now(),
            }),
            "createBondFromCall",
        );
    },
);

export const clearDraftThunk = createLocalAsyncThunk(
    "bonds/clearDraft",
    (
        draftTarget: DraftTarget,
        thunkAPI,
    ): { attachments?: AnyLocalAttachment[]; } & WithDraftTarget => {
        const state = thunkAPI.getState();
        const attachments = selectLocalAttachmentsDraftForMessage(state, draftTarget);

        return { draftTarget, attachments };
    },
);

export const removeFromBondCreationAudienceThunk = createLocalAsyncThunk(
    "bondCreation/removeFromAudience",
    (
        args: { member: AudienceMember; } & WithDraftTarget,
        thunkAPI,
    ) => {
        const { member, draftTarget } = args;
        if (!isBondCreationDraftTarget(draftTarget)) return;

        const state = thunkAPI.getState();
        const draft = selectBondCreationDraft(state, draftTarget.viewName);
        if (!draft) {
            initAndGetDraft(state.bondCreation, draftTarget);
            return;
        }

        // Remove the audience member from the audience list
        thunkAPI.dispatch(removeFromBondCreationAudience(args));

        // "Break" any mentions for that member (replace with text)
        const draftDoc = getDraftMessageDoc(draft.message.content);
        if (draftDoc === undefined) return;

        thunkAPI.dispatch(updateDraftDoc({
            draftTarget,
            update: changeBridge
                .on(draftDoc)
                .breakMentions(member)
                .get(Format.JSON),
            isApiChange: true,
        }));
    },
);

const bondDraftSchema = z.object({
    message: z.custom<DraftChatMessage>(v => !!v), // TODO: make type kosher
    titles: z.object({
        userSpecifiedTitle: z.string().optional(),
        bondTitleSuggestion: z.string().optional(),
    }),
    /**
     * Audience that will be used when creating the new bond. This includes all members (squads
     * and users) which will have access to view and engage with the newly created bond. Adding
     * a mention to the draft will automatically include a member in this list.
     */
    audience: z.array(z.custom<AudienceMember>(v => !!v)), // TODO: make type kosher
});
type BondCreationDraft = z.infer<typeof bondDraftSchema>;

const emptyBondCreationDraft = (
    draftTarget: DraftTarget,
    provider: Provider,
    lastContent?: DraftChatContent,
): BondCreationDraft => {
    const squadId = draftTargetToSquadId(draftTarget);
    return {
        message: emptyDraftChatMessage(draftTarget, provider, true, lastContent),
        audience: squadId ? [squadId] : [],
        titles: {},
    };
};

const initAndGetDraft = (
    state: BondCreationState,
    draftTarget: DraftTarget,
): Optional<BondCreationDraft> => {
    if (!isBondCreationDraftTarget(draftTarget)) return;

    const v = draftTarget.viewName;

    if (!state.drafts[v]) {
        state.drafts[v] = emptyBondCreationDraft(draftTarget, state.richTextProvider);
    }

    return state.drafts[v];
};

const bondCreationStateSchema = z.object({
    richTextProvider: z.optional(z.nativeEnum(Provider)).default(defaultProvider),
    drafts: z.record(bondCreationBucketViewNameSchema, bondDraftSchema),
    updates: z.array(z.custom<PersistenceUpdate>(v => !!v)), // TODO: make type kosher
}).default({ drafts: {}, updates: [] });

export type BondCreationState = z.infer<typeof bondCreationStateSchema>;

const getInitialState = (props?: Partial<BondCreationState>): BondCreationState => {
    return bondCreationStateSchema.parse({
        provider: props?.richTextProvider ?? defaultProvider,
        drafts: props?.drafts ?? {},
        updates: [],
    });
};

type BondCreationTarget = BondCreationBucketViewName | DraftTarget;
const getDraft = (
    state: BondCreationState,
    view: BondCreationTarget,
): Optional<BondCreationDraft> => {
    if (typeof view === "string") return state.drafts[view];
    if (!isBondCreationDraftTarget(view)) return;
    return state.drafts[view.viewName];
};

export const bondCreationSlice = createLocalSlice({
    name: "bondCreation",
    initialState: getInitialState(),
    selectors: {
        draft: getDraft,
        userSpecifiedTitle: (state, view: BondCreationTarget) =>
            getDraft(state, view)?.titles?.userSpecifiedTitle,
        bondTitleSuggestion: (state, view: BondCreationTarget) =>
            getDraft(state, view)?.titles?.bondTitleSuggestion,
        prefixLength: (state, view: BondCreationTarget) => {
            const draft = getDraft(state, view)?.message.content as DraftChatContent_V1;
            return draft?.prefix?.length ?? 0;
        },
    },
    reducers: {
        updateUserSpecifiedTitle: (
            state,
            { payload: { draftTarget, title } }: PayloadAction<
                { title: Optional<string>; } & WithDraftTarget
            >,
        ) => {
            const draft = initAndGetDraft(state, draftTarget);
            if (!draft) return;

            draft.titles.userSpecifiedTitle = title;

            state.updates = [[stores.drafts, state.drafts]];
        },

        clearBondTitleSuggestion: (state, { payload: draftTarget }: PayloadAction<DraftTarget>) => {
            const draft = initAndGetDraft(state, draftTarget);
            if (!draft) return;

            draft.titles.bondTitleSuggestion = undefined;

            state.updates = [[stores.drafts, state.drafts]];
        },

        addToAudience: (
            state,
            { payload: { member, draftTarget } }: PayloadAction<
                { member: AudienceMember; } & WithDraftTarget
            >,
        ) => {
            const draft = initAndGetDraft(state, draftTarget);
            if (!draft) return;

            draft.audience = [...draft.audience, member].removeDuplicates();

            state.updates = [[stores.drafts, state.drafts]];
        },

        removeFromAudience: (
            state,
            { payload: { member, draftTarget } }: PayloadAction<
                { member: AudienceMember; } & WithDraftTarget
            >,
        ) => {
            const draft = initAndGetDraft(state, draftTarget);
            if (!draft) return;

            draft.audience = draft.audience.filter(m => m !== member);

            state.updates = [[stores.drafts, state.drafts]];
        },
    },
    extraReducers: builder => {
        builder.addCase(
            clearDraftThunk.fulfilled,
            (state, action) => {
                const { payload: { draftTarget } } = action;

                if (!isBondCreationDraftTarget(draftTarget)) return;

                state.drafts[draftTarget.viewName] = emptyBondCreationDraft(
                    draftTarget,
                    state.richTextProvider,
                    state.drafts[draftTarget.viewName]?.message.content,
                );

                state.updates = [[stores.drafts, state.drafts]];
            },
        );

        builder.addCase(
            fetchBondTitleSuggestionThunk.fulfilled,
            (state, { payload: { title, draftTarget } }) => {
                const draft = initAndGetDraft(state, draftTarget);
                if (!draft) return;

                draft.titles.bondTitleSuggestion = title;

                state.updates = [[stores.drafts, state.drafts]];
            },
        );

        builder.addCase(
            createBondFromMessageThunk.fulfilled,
            (state, { meta: { arg } }) => {
                const { msg: { localId, draftTarget } } = arg;

                const draft = initAndGetDraft(state, draftTarget);
                if (!draft || !isBondCreationDraftTarget(draftTarget)) return;

                if (localId !== draft?.message.localId) return;

                state.drafts[draftTarget.viewName] = emptyBondCreationDraft(
                    draftTarget,
                    state.richTextProvider,
                    state.drafts[draftTarget.viewName]?.message.content,
                );

                state.updates = [[stores.drafts, state.drafts]];
            },
        );

        builder.addCase(
            createBondFromCallThunk.fulfilled,
            (state, { meta: { arg } }) => {
                const { draftTarget } = arg;

                if (!isBondCreationDraftTarget(draftTarget)) return;

                state.drafts[draftTarget.viewName] = emptyBondCreationDraft(
                    draftTarget,
                    state.richTextProvider,
                    state.drafts[draftTarget.viewName]?.message.content,
                );

                state.updates = [[stores.drafts, state.drafts]];
            },
        );

        builder.addCase(setRichTextProvider, (state, { payload }) => {
            if (state.richTextProvider == payload) return;

            const drafts = original(state.drafts);
            Object.entries(drafts ?? {}).forEach(([viewName, draft]) => {
                const convertedContent = convertDraftProvider(payload, draft.message.content);
                state.drafts[viewName].message.content = convertedContent;
                log.info(`Converted new bond draft to ${providerToString(payload)}: ${viewName}`);
            });

            state.richTextProvider = payload;
        });

        builder.addCase(updateDraftDoc, (state, { payload }) => {
            const {
                update: { doc, change },
                isApiChange,
                draftTarget,
            } = payload;

            // TODO: check draft target for transfers
            const draft = initAndGetDraft(state, draftTarget);
            if (!draft) return;

            const contentAsV3 = convertDraftContentToV3(
                state.richTextProvider,
                draft.message.content,
            );

            const newContent = change ?
                withChange(contentAsV3, change, isApiChange) :
                withDoc(contentAsV3, doc!, isApiChange);

            draft.message.content = newContent;

            const {
                addedMentions,
                lastRemovedMentions,
            } = docBridge.from(contentAsV3.draftDoc).getMentionDiff(newContent.draftDoc);

            // Update the audience according to the change in mentions
            draft.audience = draft.audience
                .filter(m => !lastRemovedMentions.some(r => m == r.target))
                .concat(addedMentions.map(m => m.target))
                .removeDuplicates();

            state.updates = [[stores.drafts, state.drafts]];
        });

        builder.addCase(
            addAttachmentsToDraft,
            (state, { payload: attachments }: PayloadAction<ProposedAttachment[]>) => {
                attachments.forEach(({ localId, draftTarget }) => {
                    const draft = initAndGetDraft(state, draftTarget);
                    if (!draft) return;

                    const index = draft.message.attachmentIds.indexOf(localId);
                    if (index === -1) {
                        draft.message.attachmentIds.push(localId);
                    }
                });

                // TODO: could make more efficient, who cares
                state.updates = [[stores.drafts, state.drafts]];
            },
        );
        builder.addCase(
            deleteAttachmentFromDraftThunk.fulfilled,
            (state, { payload: { localId, draftTarget } }) => {
                const draft = initAndGetDraft(state, draftTarget);
                if (!draft) return;

                const index = draft.message.attachmentIds.indexOf(localId);
                if (index !== -1) {
                    draft.message.attachmentIds.splice(index, 1);
                }

                // TODO: could make more efficient, who cares
                state.updates = [[stores.drafts, state.drafts]];
            },
        );

        builder.addCase(
            clearAttachmentsFromDraft,
            (state, { payload: draftTarget }: PayloadAction<DraftTarget>) => {
                const draft = initAndGetDraft(state, draftTarget);
                if (!draft) return;

                draft.message.attachmentIds = [];

                state.updates = [[stores.drafts, state.drafts]];
            },
        );
        builder.addCase(resetStore, _state => {
            return getInitialState();
        });
    },
});

export const {
    updateUserSpecifiedTitle,
    clearBondTitleSuggestion,
    addToAudience: addToBondCreationAudience,
    removeFromAudience: removeFromBondCreationAudience,
} = bondCreationSlice.actions;

const selectors = bondCreationSlice.getSelectors((state: RootState) => state.bondCreation);
export const {
    userSpecifiedTitle: selectBondUserSpecifiedTitle,
    bondTitleSuggestion: selectBondTitleSuggestion,
    prefixLength: selectBondCreationPrefixLength,
} = selectors;

/** Select a bond-creation draft from the store.
 *
 * This could be `undefined`. All reducers handle this case and generate
 * an empty draft when one does not already exist for the view.
 *
 * @param view either the top-level view name, or a draft target for
 * bond creation
 * @return a `DraftChatMessage` or `undefined`
 */
export const selectBondCreationDraft = selectors.draft;

export const selectBondCreationAudience = createAppSelector(
    [selectBondCreationDraft],
    draft => draft?.audience ?? [],
    memoizeOptions.weakMapShallow,
);

export const selectBondTitleSuggestionWithEmojiSplit = createAppSelector(
    [selectors.bondTitleSuggestion],
    bondTitleSuggestion => {
        if (!bondTitleSuggestion) return { title: "", emoji: "" };
        let emoji = "";
        if (bondTitleSuggestion) {
            emoji = bondTitleSuggestion.match(emojiRegex)?.[0] || "";
        }
        return {
            title: bondTitleSuggestion.replace(emoji, "").trim(),
            emoji: emoji,
        };
    },
);

export const selectOfficialAttachmentIdsForBondCreationMessage = createAppSelector(
    [state => state, selectBondCreationDraft],
    (state, draft) =>
        draft?.message.attachmentIds
            .map(a => selectLocalAttachment(state, a))
            .filter(isUploadedAttachment)
            .map(a => a.blobId) ?? [],
    memoizeOptions.weakMapShallow,
);

export const selectValidSquadIdsForBondCreationAudience = createAppSelector(
    [selectValidSquadIdsForMention, selectBondCreationAudience],
    (ids, audience) => ids.filter(id => !audience?.includes(id)),
    memoizeOptions.weakMapShallow,
);

export const selectValidUserIdsForBondCreationAudience = createAppSelector(
    [selectValidUserIdsForMention, selectBondCreationAudience],
    (ids, audience) => ids.filter(id => !audience?.includes(id)),
    memoizeOptions.weakMapShallow,
);

export const reducer = bondCreationSlice.reducer;

// region Persistence

const stores = {
    drafts: sessionStorageStore<BondCreationState["drafts"]>("bond-creation/drafts", 26),
};

export const persistor = {
    stores,
    persist: (previousState: BondCreationState, nextState: BondCreationState) => {
        return nextState.updates !== previousState.updates ? nextState.updates : [];
    },
    hydrate: async ({ storage }: HydrateArgs) => {
        if (!storage) return getInitialState();

        const richTextProvider = providerFromString(storageRead(richTextProviderStore));
        const drafts = storageRead(stores.drafts);

        return getInitialState({ richTextProvider, drafts });
    },
    purge: () => {
        storageRemove(stores.drafts);
    },
};
checkPersistor<BondCreationState>(persistor);
