import * as d from "@/domain/domain";
import { ContentMention, Mention } from "@/domain/mentions";
import { getMentions, isMentionOp, parseMarkupDelta } from "@/domain/richtext/quill/delta";
import { Optional } from "@/misc/types";
import { andThen } from "@/misc/utils";
import { nanoid } from "@reduxjs/toolkit";
import { changeBridge, docBridge } from "./richtext/bridge";
import {
    LegacyChangeDelta,
    LegacyMarkupDelta,
    legacyToCompactChangeDelta,
    legacyToCompactMarkupDelta,
} from "./richtext/quill/legacyDelta";
import { tagDoc } from "./richtext/taggers";
import { Change, defaultProvider, Doc, Format, Provider } from "./richtext/types";

/**
 * @deprecated
 */
export interface DraftChatContent_V1 {
    /**
     * @deprecated
     */
    ver: 1 | undefined;

    /**
     * @deprecated
     */
    draftId: d.UnsentMessageId;

    /**
     * The current draft message text.
     * @deprecated
     */
    draftMessage: string;

    /**
     * Always set to be draftMessage.trim(). This is used to contain the
     * version of the message that is used to decide if the message
     * is sendable and is the version sent.
     * @deprecated
     */
    trimmedMessage?: string;

    /**
     * List of non-overlapping character ranges representing mentions
     * in the text, ordered by start offset
     * @deprecated
     */
    mentions?: ContentMention[];

    /**
     * Hint for the message composer to update the caret position (e.g. after
     * a text modification), with a unique id to enable idempotence
     * @deprecated
     */
    caretHint: { location?: number; id: string; };

    /**
     * Track the length of the message prefix and any (usually whitespace)
     * padding between the prefix and the body. The prefix is brittle: it will
     * immediately become part of the message body if it is modified by the
     * user. The padding will shrink as it is modified but will not convert the
     * rest of the prefix.
     * @deprecated
     */
    prefix?: {
        length: number;
        padding: number;
    };
}

/**
 * @deprecated
 */
export interface DraftChatContent_V2 {
    /**
     * @deprecated
     */
    ver: 2;

    /**
     * @deprecated
     */
    draftId: d.UnsentMessageId;

    /**
     * Quill Delta markup describing the message with formatting and embeds.
     * @deprecated
     */
    draftMarkup: LegacyMarkupDelta;

    /**
     * Plaintext version of the message, parsed from the message markup.
     * This can be used for prompting AI models, but should not be
     * displayed to the user unless the message is plaintext-only.
     * @deprecated
     */
    draftMessage: string;

    /**
     * Track the last change to the draft markup that was made directly though
     * Redux, ignoring changes that came from the message composer. This is used
     * to relay the change back to the message composer efficiently---without it
     * we would have to replace the entire contents of the composer with the new
     * version whenever a change were detected.
     * @deprecated
     */
    lastApiChange: Optional<LegacyChangeDelta>;
}

export interface DraftChatContent_V3 {
    /**
     * Version 3 supports rich text documents stored in any format, while
     * flattened plaintext is stored in `draftMessage` for AI use.
     */
    ver: 3;

    /**
     * Opaque, client-only identifier for each draft.
     */
    draftId: d.UnsentMessageId;

    /**
     * Tagged rich text document describing the draft with formatting and embeds
     */
    draftDoc: Doc<Provider, Format.JSON>;

    /**
     * Plaintext version of the message, parsed from the message markup.
     * This can be used for prompting AI models, but should not be
     * displayed to the user unless the message is plaintext-only.
     */
    draftMessage: string;

    /**
     * Track the last change to the draft document that was made directly though
     * Redux, ignoring changes that came from the editor. This is used to relay
     * the change back to the editor efficiently---without it we would have to
     * replace the entire contents whenever a change were detected.
     */
    lastApiChange: Optional<Change<Provider, Format.JSON>>;
}

/**
 * An EnrichedChatMessageContent extended with information derived from it.
 *
 * It is used to prepare messages for sending and deciding their sendability.
 */
export type DraftChatContent =
    | DraftChatContent_V1
    | DraftChatContent_V2
    | DraftChatContent_V3;

/**
 * Generate a unique hint id
 * @deprecated
 */
export const genCaretHintId = () => nanoid(16);

/**
 * Generate a unique local message id
 */
export const genLocalMessageId = (): d.UnsentMessageId => nanoid();

/**
 * Create a new blank version 3 draft
 */
export const createBlankDraftContent = (
    provider: Provider,
    makeClearChange: boolean = false,
    lastContent?: DraftChatContent,
): DraftChatContent_V3 => {
    const lastDoc = (lastContent?.ver == 3) ? lastContent.draftDoc : undefined;
    const lastApiChange = (makeClearChange && lastDoc !== undefined) ?
        changeBridge
            .on(lastDoc)
            .clear()
            .get(Format.JSON).change :
        undefined;
    return {
        ver: 3,
        draftId: genLocalMessageId(),
        draftDoc: docBridge.empty(provider).get(Format.JSON),
        draftMessage: "",
        lastApiChange,
    };
};

/**
 * Convert a draft content instance to the version 3 format. If it is already
 * in that format return it unmodified
 */
export const convertDraftContentToV3 = (
    providerIfEmpty: Provider,
    content: DraftChatContent,
): DraftChatContent_V3 => {
    if (content.ver == 3) {
        return content;
    }

    return {
        ver: 3,
        draftId: content.draftId,
        draftDoc: getDraftMessageDoc(content) ??
            docBridge.empty(providerIfEmpty).get(Format.JSON),
        draftMessage: getDraftMessageText(content) ?? "",
        lastApiChange: undefined,
    };
};

/**
 * Convert a draft to a new rich text provider. If the draft is not already a V3 draft, convert it
 * to that format first. If it is already a V3 draft with the correct provider, return it unmodified
 */
export const convertDraftProvider = (
    provider: Provider,
    content: DraftChatContent,
): DraftChatContent_V3 => {
    if (content.ver == 3 && content.draftDoc.p == provider) {
        return content;
    }

    const contentV3 = convertDraftContentToV3(provider, content);
    return {
        ...contentV3,
        draftDoc: docBridge
            .convert(contentV3.draftDoc, provider)
            .get(Format.JSON),
        lastApiChange: undefined,
    };
};

/**
 * Return the string message text from a draft message content instance
 */
export const getDraftMessageText = (content: Optional<DraftChatContent>) => content?.draftMessage;

/**
 * Return the `MarkupDelta` message markup from a draft message content instance. If the message
 * version does not store markup, generate markup from the text and mentions list
 */
export const getDraftMessageDoc = (
    content: Optional<DraftChatContent>,
): Optional<Doc<Provider, Format.JSON>> =>
    switchDraftContentVersion(
        content,
        c1 => docBridge.fromText(c1.draftMessage, defaultProvider).get(Format.JSON),
        c2 =>
            tagDoc(
                legacyToCompactMarkupDelta(c2.draftMarkup),
                Provider.Quill,
                Format.JSON,
            ),
        c3 => c3.draftDoc,
        () => undefined,
    );

/**
 * Return a list of mentions from a draft content instance
 */
export const getDraftMentions = (content: Optional<DraftChatContent>): Mention[] =>
    switchDraftContentVersion(
        content,
        c1 => c1.mentions,
        c2 => getMentions(parseMarkupDelta(c2.draftMarkup)),
        c3 => docBridge.from(c3.draftDoc).getMentions(),
        () => undefined,
    ) ?? [];

/**
 * Get the count of mentions present in the draft content
 */
export const getDraftMentionsCount = (content: Optional<DraftChatContent>): number =>
    switchDraftContentVersion(
        content,
        c1 => c1.mentions?.length ?? 0,
        c2 => c2.draftMarkup.filter(isMentionOp).length,
        c3 => docBridge.from(c3.draftDoc).getMentionCount(),
        () => 0,
    );

/**
 * Get the length in characters of the whitespace-trimmed message from the draft
 */
export const getDraftTrimmedLength = (content: Optional<DraftChatContent>): number =>
    switchDraftContentVersion(
        content,
        c1 => c1.trimmedMessage?.length ?? 0,
        c2 => c2.draftMessage?.trim().length ?? 0,
        c3 => c3.draftMessage?.trim().length ?? 0,
        () => 0,
    );

/**
 * Return the last api change that was applied to a draft, or undefined if the last change
 * was not an api change, or if the draft is not version 2. An api change is a change made
 * directly to the store, rather than via a Quill editor
 */
export const getDraftLastApiChange = (
    content: Optional<DraftChatContent>,
): Optional<Change<Provider, Format.JSON>> =>
    switchDraftContentVersion(
        content,
        _ => undefined,
        c2 =>
            andThen(
                c2.lastApiChange,
                c => changeBridge.fromRaw(
                    legacyToCompactChangeDelta(c),
                    Provider.Quill,
                    Format.JSON,
                ),
            )?.get(Format.JSON).change,
        c3 => c3.lastApiChange,
        () => undefined,
    );

/**
 * Return a new draft equivalent to an existing draft but with the provided doc
 */
export const withDoc = (
    content: DraftChatContent_V3,
    doc: Doc<Provider, Format.JSON>,
    isApiChange: boolean,
): DraftChatContent_V3 => ({
    ...content,
    draftDoc: doc,
    draftMessage: docBridge.from(doc).getText(true),
    lastApiChange: isApiChange ?
        changeBridge
            .on(content.draftDoc)
            .replace(doc)
            .get(Format.JSON).change :
        undefined,
});

/**
 * Return a new draft with markup produced by applying a change delta to an existing draft
 */
export const withChange = (
    content: DraftChatContent_V3,
    change: Change<Provider, Format.JSON>,
    isApiChange: boolean,
): DraftChatContent_V3 => {
    const { doc: newDoc } = changeBridge.from(change).get(Format.JSON);
    const newDocBridge = docBridge.from(newDoc);
    return {
        ...content,
        draftDoc: newDocBridge.get(Format.JSON),
        draftMessage: newDocBridge.getText(true),
        lastApiChange: isApiChange ? change : undefined,
    };
};

/**
 * Produce a different result depending on the draft content type. If the draft
 * type is not recognised, use a default result
 */
export const switchDraftContentVersion = <T>(
    content: Optional<DraftChatContent>,
    ver1Map: (c1: DraftChatContent_V1) => T,
    ver2Map: (c2: DraftChatContent_V2) => T,
    ver3Map: (c3: DraftChatContent_V3) => T,
    defaultThunk: () => T,
): T => {
    if (content) {
        switch (content.ver) {
            case undefined:
            case 1:
                return ver1Map(content as DraftChatContent_V1);
            case 2:
                return ver2Map(content as DraftChatContent_V2);
            case 3:
                return ver3Map(content as DraftChatContent_V3);
        }
    }
    return defaultThunk();
};
