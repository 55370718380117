import React, { PropsWithChildren } from "react";
import BondsListView from "./BondsListView";

// The "Inbox" view on the Phase3 UI
export function MyBondsView({ children }: PropsWithChildren): React.JSX.Element {
    return (
        <section className="c-content c-content--squad">
            <BondsListView />
            {children}
        </section>
    );
}
