import classNames from "classnames";

interface SidebarItemProps {
    hasFocus?: boolean;
    hasIcon?: boolean;
    isDesktop?: boolean;
    isDisabled?: boolean;
    icon?: string;
    label?: string;
    onClick?: () => void;
}

export const SidebarItem = ({
    hasFocus,
    hasIcon,
    isDesktop,
    isDisabled,
    icon = "profile",
    label = "Profile",
    onClick,
}: SidebarItemProps) => {
    const classes = classNames("cp-sidebar-link", {
        "cp-sidebar-link--active": hasFocus,
        "cp-sidebar-link--icon": hasIcon,
        "cp-sidebar-link--disabled": isDisabled,
        "cp-sidebar-link--desktop": isDesktop,
    });

    return (
        <a
            type="submit"
            aria-label={label}
            className={classes}
            onClick={onClick}
        >
            {hasIcon && (
                <span className={`cp-sidebar-link__icon cp-sidebar-link__icon--${icon}`}>
                </span>
            )}
            {label}
            {!isDesktop && <span className="cp-sidebar-link__icon cp-sidebar-link__icon--arrow" />}
        </a>
    );
};
