import { MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ButtonMain } from "@/components/buttons/ButtonMain";
import { CloseButton } from "@/components/buttons/Close";
import * as d from "@/domain/domain";
import { viewStackUtils } from "@/domain/views";
import { selectCurrentOrgId } from "@/features/auth";
import { selectCurrentViewStack } from "@/features/filterPanel";
import { createNewSquad } from "@/features/squads";
import useViewStackNavigate from "@/hooks/navigation/useViewStackNavigate";
import useDialogOpenRef from "@/hooks/useDialogOpenRef";
import useDialogOutsideClick from "@/hooks/useDialogOutsideClick";
import log from "@/misc/log";
import { isMobileBrowser } from "@/misc/mobile";
import { setOnChange } from "@/misc/utils";
import { useAppDispatch, useAppSelector } from "@/store/redux";

import { nanoid } from "@reduxjs/toolkit";
import classNames from "classnames";

export default function CreateSquadModal(): React.JSX.Element {
    const dispatch = useAppDispatch();
    const { navigatePop, navigateReplace, navigateRemoveSettingsModals } = useViewStackNavigate();
    const currentViewStack = useAppSelector(selectCurrentViewStack);

    const closeModal = useCallback(() => {
        navigatePop();
    }, [navigatePop]);

    const closeAllSettings = useCallback(() => {
        navigateRemoveSettingsModals();
    }, [navigateRemoveSettingsModals]);

    const dialogRef = useDialogOpenRef();
    const handleBackdropClick = useDialogOutsideClick(dialogRef, closeModal);

    // Copied from PillSelector.tsx
    const inputRef = useRef<HTMLInputElement>(null);
    const inputId = useMemo(() => `c-pill-select__input__${nanoid(16)}`, []);

    const onClickInputArea: MouseEventHandler = useCallback(e => {
        if (e.currentTarget === e.target) {
            inputRef.current?.focus();
        }
    }, []);

    // The following can be used once we can navigate directly to multiply-stacked views:
    /*
    const showAddPeopleDialog = useCallback((squadId: d.SquadId) => {
        // Replace the old squad ID in the view stack
        const withNewSquad = viewStackUtils.replaceSquadInStack(currentViewStack, squadId);
        // Replace this modal with the add people dialog
        const newStack = viewStackUtils.updateTopOfStack(withNewSquad, _ => {
            return viewUtils.squadSettingsInvite(squadId);
        });
        navigateReplace(newStack);
    }, [currentViewStack, navigateReplace]);
    */

    const returnToCorrectSquad = useCallback((squadId: d.SquadId) => {
        // Replace the old squad ID in the view stack
        const withNewSquad = viewStackUtils.replaceSquadInStack(currentViewStack, squadId);
        // Remove this modal
        const withoutHead = viewStackUtils.removeTopOfStack(withNewSquad);
        navigateReplace(withoutHead);
    }, [currentViewStack, navigateReplace]);

    const orgId = useAppSelector(selectCurrentOrgId);
    const [newSquadName, setNewSquadName] = useState("");
    const nameTooShort = newSquadName.length <= 0;
    const nameTooLong = newSquadName.length > 64;
    const validName = !nameTooShort && !nameTooLong;

    const confirmCreate = useCallback(() => {
        if (!orgId || newSquadName.length == 0) return;

        dispatch(
            createNewSquad({ name: newSquadName, orgId: orgId }),
        ).unwrap().then(
            newSquadId => {
                // showAddPeopleDialog(newSquadId);
                returnToCorrectSquad(newSquadId);
            },
        ).catch(
            e => {
                log.error("Error creating squad:", e);
                alert(`Failed to create squad.`);
            },
        );
    }, [dispatch, newSquadName, orgId, returnToCorrectSquad]);

    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            if (e.key === "Enter") {
                confirmCreate();
            }
        };
        document.addEventListener("keypress", handleKeyPress);

        return () => {
            document.removeEventListener("keypress", handleKeyPress);
        };
    }, [confirmCreate]);

    const buttonText = "Continue";
    const placeholder = "Squad name";

    const isMobile = isMobileBrowser();
    const isDesktop = !isMobile;

    const dialogClass = classNames("cp-dialog", {
        "cp-dialog--desktop-notification": isDesktop,
    });
    const inputClass = classNames("cp-input", {
        "cp-input--error": nameTooLong,
    });

    return (
        <dialog
            className={dialogClass}
            onClose={closeModal}
            onMouseDown={handleBackdropClick}
            ref={dialogRef}
            role="dialog"
        >
            <header className="cp-dialog__header">
                <button className="cp-btn-back" onClick={closeModal}>Return</button>
                <h1 className="cp-dialog__title">Add squad</h1>
                <CloseButton side="right" onClick={closeAllSettings} />
            </header>

            <article className="cp-dialog__content-wrapper">
                <div className="cp-dialog__content">
                    <div className="cp-form-element" onClick={onClickInputArea}>
                        <input
                            ref={inputRef}
                            id={inputId}
                            type="text"
                            placeholder={""}
                            onChange={setOnChange(setNewSquadName)}
                            autoFocus={true}
                            value={newSquadName}
                            className={inputClass}
                        />
                        <label className="cp-label">
                            {placeholder}
                        </label>
                    </div>

                    <ButtonMain
                        label={buttonText}
                        onClick={confirmCreate}
                        isDisabled={!validName}
                    />
                </div>
            </article>
        </dialog>
    );
}
