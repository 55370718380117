import classNames from "classnames";
import { useMemo } from "react";
import { z } from "zod";

import LinkToView from "@/components/navigation/LinkToView";
import { SquadAndMyBondsSelector } from "@/components/SquadAndMyBondsSelector";
import { AppViewStack, viewSchema, viewStackSchema, viewUtils } from "@/domain/views";
import { selectCurrentViewStack } from "@/features/filterPanel";
import {
    selectAllSquadsDominantPresenceIcon,
    selectInboxDominantPresenceIconPair,
    selectInboxUnreadBondsExist,
    selectMobileSquadsTabHighlighted,
} from "@/features/presence";
import useBooleanFeatureFlag from "@/hooks/useBooleanFeatureFlag";
import usePresenceIcon from "@/hooks/usePresenceIcon";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { Optional } from "@/misc/types";
import { useAppSelector } from "@/store/redux";

const constantTabInfoSchema = z.object({
    title: z.string(),
    id: z.enum(["bonds", "squads", "discover"]),
    view: viewSchema,
    highlightsOn: z.function()
        .args(viewStackSchema.optional())
        .returns(z.boolean()),
});
const _tabInfoSchema = constantTabInfoSchema.and(z.object(
    {
        highlight: z.boolean(),
        presenceIconString: z.string().optional(),
        newActivity: z.boolean().optional(),
    },
));
type TabInfo = z.infer<typeof _tabInfoSchema>;

function Tab(
    {
        title,
        highlight,
        id,
        presenceIconString,
        newActivity = false,
        view,
    }: TabInfo & {
        highlight: boolean;
    },
): React.JSX.Element {
    const classes = classNames(`c-footer-btn c-footer-btn--${id}`, {
        [`is-selected`]: highlight,
    });

    const showPresenceIndicator = presenceIconString &&
        (viewUtils.isInbox(view) || viewUtils.isMySquads(view));
    const showNewActivityDot = !showPresenceIndicator && newActivity;

    return (
        <div className="c-footer__item">
            <LinkToView view={view} className={classes}>
                {title}
            </LinkToView>

            {showPresenceIndicator && (
                <div className="c-footer__activity">
                    <span
                        className="c-activity-icon"
                        style={{
                            backgroundImage: `url("${presenceIconString}")`,
                        }}
                    />
                </div>
            )}

            {showNewActivityDot && (
                <div className="c-footer__activity">
                    <div className="c-activity-update" />
                </div>
            )}
        </div>
    );
}

const tabs: z.infer<typeof constantTabInfoSchema>[] = [
    {
        title: "Inbox",
        id: "bonds",
        view: viewUtils.inbox(),
        highlightsOn: viewUtils.hasInbox,
    },
    {
        title: "Squads",
        id: "squads",
        view: viewUtils.mySquads(),
        highlightsOn: viewUtils.highlightForSquadTab,
    },
    {
        title: "Discover",
        id: "discover",
        view: viewUtils.discover(),
        highlightsOn: viewUtils.hasDiscover,
    },
];

const embellishTabInfo = (
    ti: z.infer<typeof constantTabInfoSchema>,
    currentViewStack: Optional<AppViewStack>,
    presenceIconString?: string,
    newActivity?: boolean,
): TabInfo => ({
    ...ti,
    presenceIconString,
    newActivity,
    highlight: ti.highlightsOn(currentViewStack),
});

export function MobileTabsView(): React.JSX.Element {
    const inboxPresenceIconString = usePresenceIcon(selectInboxDominantPresenceIconPair);
    const allSquadsPresenceIconString = useSelectorArgs(selectAllSquadsDominantPresenceIcon);
    const inboxUnreadBondsExist = useSelectorArgs(selectInboxUnreadBondsExist);
    const mobileSquadsTabUnread = useSelectorArgs(selectMobileSquadsTabHighlighted);
    const discoverFeature = useBooleanFeatureFlag("discover-feature");

    const viewStack = useAppSelector(selectCurrentViewStack);

    const detailedTabs: TabInfo[] = useMemo(() => [
        embellishTabInfo(tabs[0], viewStack, inboxPresenceIconString, inboxUnreadBondsExist),
        embellishTabInfo(tabs[1], viewStack, allSquadsPresenceIconString, mobileSquadsTabUnread),
        embellishTabInfo(tabs[2], viewStack),
    ], [
        allSquadsPresenceIconString,
        inboxPresenceIconString,
        inboxUnreadBondsExist,
        viewStack,
        mobileSquadsTabUnread,
    ]);

    // Only render if we're on one of the tabs and not viewing a bond
    if (!viewUtils.isMobileTabsViewShown(viewStack)) {
        return <></>;
    }

    const className = classNames("c-footer", {
        "c-footer--squads": viewUtils.isMySquads(viewStack.at(-1)),
    });

    return (
        <footer className={className}>
            {detailedTabs
                .filter(x => x.id !== "discover" || discoverFeature)
                .map(x => <Tab key={x.id} {...x} />)}
        </footer>
    );
}

export function MobileSquadSelectorView(): React.JSX.Element {
    return <SquadAndMyBondsSelector showMyBonds={false} />;
}
