import { Optional } from "@/misc/types";
import { isAnyCompactDelta, isAnyDelta } from "./quill/delta";
import { isAnyNode, isAnyTransform, isInternalNode, isInternalTransform } from "./tiptap/node";
import {
    AnyBackingType,
    Change,
    ChangeTypes,
    Doc,
    DocTypes,
    Format,
    Position,
    PositionTypes,
    Provider,
    Tag,
} from "./types";

/**
 * Return the tag for a given raw value (document, change, or position). In practise, type
 * parameterization necessitates that we provide the information needed to form the tag as
 * arguments, but we do some runtime checks in this function to make sure we are tagging
 * the correct thing
 */
const getTag = <P extends Provider, F extends Format>(
    raw: AnyBackingType,
    provider: P,
    format: F,
): Tag<P, F> => {
    // These checks are unfortunately needed for runtime type safety
    let inferredProvider: Optional<Provider> = undefined;
    let inferredFormat: Optional<Format> = undefined;

    if (isAnyDelta(raw)) {
        inferredProvider = Provider.Quill;
        inferredFormat = isAnyCompactDelta(raw) ? Format.JSON : Format.Internal;
    }
    if (isAnyNode(raw)) {
        inferredProvider = Provider.Tiptap;
        inferredFormat = isInternalNode(raw) ? Format.Internal : Format.JSON;
    }
    if (isAnyTransform(raw)) {
        inferredProvider = Provider.Tiptap;
        inferredFormat = isInternalTransform(raw) ? Format.Internal : Format.JSON;
    }

    if (provider != inferredProvider) {
        throw new Error("Requested provider does not match provider inferred from type");
    }
    if (format != inferredFormat) {
        throw new Error("Requested format does not match format inferred from type");
    }

    return {
        p: provider,
        f: format,
    };
};

export const tagDoc = <P extends Provider, F extends Format>(
    rawDoc: DocTypes[P][F],
    provider: P,
    format: F,
): Doc<P, F> => ({ rawDoc, ...getTag(rawDoc, provider, format) });

export const tagChange = <P extends Provider, F extends Format>(
    rawChange: ChangeTypes[P][F],
    provider: P,
    format: F,
): Change<P, F> => ({ rawChange, ...getTag(rawChange, provider, format) });

export const tagPosition = <P extends Provider>(
    rawPosition: PositionTypes[P],
    provider: P,
): Position<P> => ({ rawPosition, p: provider });
