import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import BondChatParticipants from "@/components/BondChatParticipants";
import { FeatureFlagged } from "@/components/FeatureFlags";
import { FilterBondsAndPresenceHeader } from "@/components/FilterButtonAndPresenceHeader";
import { BondTopbarActions } from "@/components/gui/BondActions";
import BondPrivacyDomain from "@/components/gui/BondPrivacyDomain";
import SensitiveText from "@/components/gui/SensitiveText";
import LinkToViewButton from "@/components/navigation/LinkToViewButton";
import { NewBondTitleBar } from "@/components/NewBondTitleBar";
import PresenceHeader from "@/components/PresenceHeader";
import { QueryAnswer } from "@/components/QueryAnswer";
import { useSidebar } from "@/context/SidebarContext";
import * as d from "@/domain/domain";
import { newQueryId } from "@/domain/intel";
import { canOpenLiveView, orderParticipants } from "@/domain/rtc";
import { SquadOverview } from "@/domain/squads";
import { AppView, viewNames, viewUtils } from "@/domain/views";
import { selectCurrentUserId } from "@/features/auth";
import { clearDraftThunk } from "@/features/bondCreation";
import { selectLiveCallIdByBondId } from "@/features/bonds";
import { selectBondTitle } from "@/features/bonds";
import {
    selectCurrentView,
    selectDraftTargetForBondCreationFromCurrentView,
} from "@/features/filterPanel";
import {
    purgeQuery,
    queryThunk,
    selectCurrentQueryId,
    updateQueryId,
    updateQueryQuestion,
} from "@/features/intel";

import {
    SummaryButtonBond,
    SummaryButtonInbox,
    SummaryButtonSquad,
} from "@/components/buttons/SummaryButton.tsx";
import {
    SearchFilterOptions,
    searchThunk,
    selectQuery,
    setFilter,
    setQuery,
} from "@/features/search";

import ReconnectingPill from "@/components/gui/ReconnectingPill";
import SearchFilterAndPresenceHeader from "@/components/SearchFilterAndPresenceHeader";
import { selectSquadById } from "@/features/squads";
import useViewStackNavigate from "@/hooks/navigation/useViewStackNavigate";
import useRtcSessionContext from "@/hooks/rtc/useRtcSessionContext";
import useAddressParams from "@/hooks/useAddressParams";
import useBooleanFeatureFlag from "@/hooks/useBooleanFeatureFlag";
import useDebounce from "@/hooks/useDebounce";
import useDebouncedOnlineStatus from "@/hooks/useDebouncedOnlineStatus";
import useLocalDispatch from "@/hooks/useLocalDispatch";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { useShallowEqualsMemo } from "@/hooks/useShallowEquals";
import { isMobileBrowser } from "@/misc/mobile";
import { Optional } from "@/misc/types";
import { useAppDispatch, useAppSelector } from "@/store/redux";

const newBondView = viewUtils.newBond();

export function DesktopTopbarViewInternal(): React.JSX.Element {
    const inputRef = useRef<HTMLInputElement>(null);

    const dispatch = useAppDispatch();
    const localDispatch = useLocalDispatch();

    const view = useAppSelector(selectCurrentView);
    const isDiscover = viewUtils.isDiscover(view);
    const isNewBond = viewUtils.isNewBond(view);

    const query = useAppSelector(selectQuery);
    const currentQueryId = useAppSelector(selectCurrentQueryId);

    const squadId = viewUtils.isSquad(view) ? view.id : undefined;

    const searchEnabled = useBooleanFeatureFlag("search-feature-frontend");

    const { navigatePush, navigatePop } = useViewStackNavigate();

    const forceQueryChange = (oldValue: string, newValue: string) => {
        const triggerValue = 3;
        if (newValue.length < triggerValue) {
            return false;
        }
        return Math.abs(oldValue.length - newValue.length) >= triggerValue;
    };

    const debouncedQuery = useDebounce(query, 300, undefined, forceQueryChange);

    useEffect(() => {
        if (searchEnabled) dispatch(searchThunk({ query: debouncedQuery }));
    }, [debouncedQuery, searchEnabled, dispatch]);

    const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const newQuery = e.target.value;
        if (newQuery.length === 0 && query.length !== 0) navigatePop();
        if (newQuery.length !== 0 && !viewUtils.isSearch(view)) {
            navigatePush(viewUtils.search());
        }

        localDispatch(setQuery(newQuery));
    }, [localDispatch, navigatePop, navigatePush, query.length, view]);

    const handleQuestionAsk = useCallback((q: string) => {
        if (currentQueryId) {
            localDispatch(purgeQuery(currentQueryId));
        }

        const id = newQueryId();
        localDispatch(updateQueryId(id));
        localDispatch(updateQueryQuestion({ question: q, queryId: id }));

        // I need a way to close the stream / abort the query
        // This dispatch gives an abort function, but I can't serialize it
        // (meaning I can't store it in the state). To get around this, I'm
        // storing it in a local state, which is a bit of a hack.

        dispatch(queryThunk({ question: q, queryId: id }));
    }, [localDispatch, dispatch, currentQueryId]);

    const handleClearClick = () => {
        dispatch(setFilter(SearchFilterOptions.Everything));
        // clearQuery() clears query and pops viewstack but does not navigate
        // so use setQuery("") instead (+navigatePop to pop and navigate to viewStack)
        dispatch(setQuery(""));
        navigatePop();
        if (inputRef.current) {
            inputRef.current.blur();
        }
    };

    return (
        <>
            <header className="c-header c-header--desktop">
                {!isDiscover && (
                    <FeatureFlagged
                        flag={"search-feature-frontend"}
                        match={true}
                        className="c-search c-search--desktop"
                    >
                        <input
                            type="text"
                            placeholder="Search"
                            className="c-search__input ph-no-capture"
                            required
                            ref={inputRef}
                            value={query}
                            onChange={handleInputChange}
                            onKeyDown={e => {
                                if (e.key === "Enter") {
                                    e.preventDefault();
                                    handleQuestionAsk(query);
                                }
                                if (e.key === "Escape") {
                                    e.preventDefault();
                                    // n.b setQuery("") doesn't trigger the change event
                                    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/change_event
                                    handleClearClick();
                                }
                            }}
                            id="searchBar"
                            aria-label="Search Bond"
                            title=""
                        />
                        <label
                            className="c-search__icon"
                            htmlFor="searchBar"
                        >
                            Search
                        </label>
                        <button
                            className="c-search__clear"
                            disabled={query.length === 0}
                            onClick={handleClearClick}
                        >
                            Clear
                        </button>
                    </FeatureFlagged>
                )}
                <FeatureFlagged flag={"show-squad-summaries"} match={true} wrapWithDiv={false}>
                    {squadId ? <SummaryButtonSquad squadId={squadId} /> : <SummaryButtonInbox />}
                </FeatureFlagged>
                <LinkToViewButton
                    view={newBondView}
                    className="c-btn-create-bond c-btn-create-bond--desktop"
                    title="Create bond"
                    // TODO: remove this BODGE once proper in-bond/new-bond topbars are implemented
                    // Use "visibility: hidden" rather than removing the component so the flex layout doesn't change
                    style={{ visibility: isNewBond ? "hidden" : "unset" }}
                >
                    Create bond
                </LinkToViewButton>
            </header>
            {!isDiscover && (
                <FeatureFlagged flag="ai-querying" match={true}>
                    <QueryAnswer queryId={currentQueryId!} />
                </FeatureFlagged>
            )}
            {!isDiscover && query.length == 0 && <FilterBondsAndPresenceHeader />}
            {!isDiscover && query.length != 0 && <SearchFilterAndPresenceHeader />}
        </>
    );
}

function NewBondTopbar(): React.JSX.Element {
    const { navigatePop } = useViewStackNavigate();
    const isMobile = isMobileBrowser();

    const className = classNames("c-header", {
        "c-header--desktop": !isMobile,
    });

    const dispatch = useAppDispatch();
    const draftTarget = useAppSelector(selectDraftTargetForBondCreationFromCurrentView);

    const backAction = useCallback(() => {
        // TODO: "Are you sure?" check
        if (draftTarget) {
            dispatch(clearDraftThunk(draftTarget));
        }
        navigatePop();
    }, [dispatch, draftTarget, navigatePop]);

    return (
        <header className={className}>
            <NewBondTitleBar backAction={backAction} tabIndex={1} />
        </header>
    );
}

function DesktopTopbarView(): React.JSX.Element {
    const view = useAppSelector(selectCurrentView);

    if (viewUtils.isNewBond(view)) {
        return <NewBondTopbar />;
    }

    if (viewUtils.isSingleBond(view)) {
        return <BondViewTopbar />;
    }

    if (viewUtils.isInviteRedemption(view)) {
        return <InviteRedemptionTopbar />;
    }

    return <DesktopTopbarViewInternal />;
}

// TODO: does this really belong with the view declarations?
const mobileTitles = {
    [viewNames.inbox]: "Inbox",
    [viewNames.mySquads]: "Squads",
    [viewNames.singleBond]: "",
    [viewNames.discover]: "Discover",
    [viewNames.newBond]: "",
    [viewNames.inviteRedemption]: "",
    [viewNames.login]: "",
    [viewNames.search]: "Search",
    [viewNames.mediaSettings]: "Audio & Video",
    [viewNames.profileSettings]: "Profile Photo",
    [viewNames.reportIssue]: "Report Issue",
    [viewNames.squadSettings]: "Manage Squads",
    [viewNames.squadSettingsCreate]: "Add Squad",
    [viewNames.squadSettingsInvite]: "Add People",
    [viewNames.squadSettingsRename]: "Rename Squad",
    [viewNames.squadSettingsRemove]: "Remove User",
};
const getMobileTitle = (view: Optional<AppView>, squad?: SquadOverview): string => {
    if (!view) return "";
    if (viewUtils.isSquad(view)) return squad?.name || "";
    return mobileTitles[view.view];
};

function MobileTopbarViewInternal({ view }: { view: Optional<AppView>; }): React.JSX.Element {
    const { navigatePop } = useViewStackNavigate();
    const { squadId } = useAddressParams();
    const squad = useSelectorArgs(selectSquadById, squadId);

    const [isOpen, setIsOpen] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const asideRef = useRef<HTMLDivElement>(null);

    const closeAside = useCallback((event: TouchEvent | MouseEvent) => {
        if (
            buttonRef.current &&
            !buttonRef.current.contains(event.target as Node) &&
            asideRef.current &&
            !asideRef.current.contains(event.target as Node)
        ) {
            setIsOpen(false);
        }
    }, []);

    useEffect(() => {
        if (isOpen) {
            document.addEventListener("touchstart", closeAside);
            document.addEventListener("mousedown", closeAside);
            return () => {
                document.removeEventListener("touchstart", closeAside);
                document.removeEventListener("mousedown", closeAside);
            };
        }
    }, [isOpen, closeAside]);

    const title = getMobileTitle(view, squad);

    const showBackButton = viewUtils.isSquad(view);
    const showPlusButton = viewUtils.isInbox(view) || viewUtils.isSquad(view);

    const className = classNames("c-header", {
        "c-header--squads": viewUtils.isMySquads(view),
    });

    return (
        <header className={className}>
            {showBackButton &&
                (
                    <button
                        className="c-btn-return c-btn-return--squads"
                        title="Return"
                        onClick={() => navigatePop()}
                    >
                        Return
                    </button>
                )}

            <h1 className="c-header__title">{title}</h1>

            {showPlusButton &&
                (
                    <LinkToViewButton
                        view={newBondView}
                        className="c-btn-create-bond"
                        title="Create bond"
                    >
                        Create bond
                    </LinkToViewButton>
                )}
        </header>
    );
}

function BondViewTopbar(): React.JSX.Element {
    const navigate = useNavigate();
    const { navigatePop } = useViewStackNavigate();
    const { bondId } = useAddressParams();
    const isMobile = isMobileBrowser();
    const bondTitle = useSelectorArgs(selectBondTitle, bondId);
    const liveCallId = useSelectorArgs(selectLiveCallIdByBondId, bondId);
    const bondIsLive = !!liveCallId;

    const connected = useDebouncedOnlineStatus();

    const showEmoji = useBooleanFeatureFlag("display-bond-emoji");

    const title = `${showEmoji && bondTitle.emoji ? bondTitle.emoji + " " : ""}${bondTitle.title}`;

    const currentUserId = useAppSelector(selectCurrentUserId);

    const { callParticipants } = useRtcSessionContext();
    const { orderedParticipants } = useShallowEqualsMemo(
        () => orderParticipants(callParticipants),
        [callParticipants],
    );

    const canOpenLiveViewMemo = useMemo(
        () => canOpenLiveView(orderedParticipants, currentUserId),
        [orderedParticipants, currentUserId],
    );

    // Memoised for (indirect) context dependency
    const participantsComponent = useMemo(() =>
        bondId && (
            <BondChatParticipants
                bondId={bondId}
                canOpenLiveView={canOpenLiveViewMemo}
            />
        ), [bondId, canOpenLiveViewMemo]);

    const showRenameBondModalAction = useCallback(() => {
        if (bondId) {
            navigate(`/bond/${d.extractUUID(bondId)}/modify`);
        }
    }, [navigate, bondId]);

    const { isOpen } = useSidebar();

    const buttonClasses = classNames(
        "c-btn-return c-btn-return--desktop",
        !isOpen && "sidebar-closed",
    );
    const headerClasses = classNames("c-header", {
        "c-header--desktop": !isMobile,
        "c-header--live": bondIsLive,
    });

    const nonMobileHeader = (
        <header className={headerClasses}>
            <button
                className={buttonClasses}
                onClick={() => navigatePop()}
                title="Return"
            >
                Return
            </button>
            <div
                className="c-bond-title c-bond-title--desktop"
                onClick={showRenameBondModalAction}
            >
                <div className="c-bond-title__participants c-bond-title__participants--desktop">
                    <BondPrivacyDomain id={bondId} includeInvited={true} />
                </div>
                <div className="c-middot c-bond-title__separator">&#183;</div>
                <h1 className="c-bond-title__title c-bond-title__title--desktop u-truncate-auto">
                    <SensitiveText>{title}</SensitiveText>
                </h1>
            </div>
            {bondId && <SummaryButtonBond bondId={bondId} />}
            {bondId && <BondTopbarActions bondId={bondId} />}
        </header>
    );

    const mobileHeader = (
        <header className={headerClasses}>
            <button
                className="c-btn-return"
                onClick={() => navigatePop()}
                title="Return"
            >
                Return
            </button>
            <div
                className="c-bond-title"
                onClick={showRenameBondModalAction}
            >
                <h1 className="c-bond-title__title u-truncate-title">
                    <SensitiveText>{title}</SensitiveText>
                </h1>
                <div className="c-bond-title__participants">
                    <BondPrivacyDomain id={bondId} includeInvited={true} />
                </div>
            </div>
            {bondId && <BondTopbarActions bondId={bondId} />}
        </header>
    );

    const headerWrapperClasses = classNames("c-header-wrapper", {
        "c-header-wrapper--live": bondIsLive,
    });

    return (
        <div className={headerWrapperClasses}>
            {!isMobile ? nonMobileHeader : mobileHeader}
            {(connected || callParticipants.length > 1) ? participantsComponent
                : <ReconnectingPill />}
        </div>
    );
}

function InviteRedemptionTopbar(): React.JSX.Element {
    // Currently set by InviteRedemptionView itself
    return <></>;
}

function MobileTopbarView(): React.JSX.Element {
    const view = useAppSelector(selectCurrentView);
    const query = useAppSelector(selectQuery);

    const connected = useDebouncedOnlineStatus();

    if (viewUtils.isNewBond(view)) {
        return <NewBondTopbar />;
    }

    if (viewUtils.isSingleBond(view)) {
        return <BondViewTopbar />;
    }

    if (viewUtils.isInviteRedemption(view)) {
        return <InviteRedemptionTopbar />;
    }

    const presenceOrReconnectingHeader = connected ? <PresenceHeader /> : <ReconnectingPill />;

    return (
        <>
            <MobileTopbarViewInternal view={view} />
            {query.length === 0 && presenceOrReconnectingHeader}
        </>
    );
}

export function TopbarView(): React.JSX.Element {
    return isMobileBrowser() ? <MobileTopbarView /> : <DesktopTopbarView />;
}
