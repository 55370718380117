import React, { FC } from "react";

import QuillMarkupView from "@/components/richtext/quill/QuillMarkupView";
import TiptapDocView from "@/components/richtext/tiptap/TiptapDocView";
import { switchDoc } from "@/domain/richtext/bridge";
import { Doc, Format, Provider } from "@/domain/richtext/types";
import { Optional } from "@/misc/types";
import { andThen } from "@/misc/utils";
import { ErrorBoundary } from "@sentry/react";

export interface DocViewProps {
    doc: Optional<Doc<Provider, Format.JSON>>;
    fallback: Optional<string>;
    inline: boolean;
}

export const DocView: FC<DocViewProps> = ({ doc, fallback, inline }) => {
    const docElem: Optional<React.JSX.Element> = andThen(doc, doc =>
        switchDoc(doc, {
            quillMap: doc => <QuillMarkupView markup={doc.rawDoc} inline={inline} />,
            tiptapMap: doc => <TiptapDocView root={doc.rawDoc} inline={inline} />,
        }));
    const fallbackElem = <p>{fallback}</p>;

    if (docElem === undefined) return fallbackElem;
    return <ErrorBoundary fallback={fallbackElem}>{docElem}</ErrorBoundary>;
};

export default DocView;
