import { useEffect, useState } from "react";

import { selectBackendInfo } from "@/features/meta";
import { isNativeIosPlatform, isNativePlatform } from "@/misc/capacitor";
import { useAppSelector } from "@/store/redux";
import { App } from "@capacitor/app";

export function VersionInfo(): React.JSX.Element {
    const backendInfo = useAppSelector(selectBackendInfo);

    const nativePlatform = isNativePlatform();

    const [nativeVersion, setNativeVersion] = useState<string>("");
    useEffect(() => {
        if (nativePlatform) {
            const prefix = isNativeIosPlatform() ? "v" : "";

            App.getInfo().then(info => setNativeVersion(prefix + info.version));
        }
    }, [nativePlatform]);

    const innerClassNames = "c-version";

    return (
        <div className="c-app-info">
            <div className={innerClassNames}>Backend: {backendInfo?.version}</div>
            <div className={innerClassNames}>Instance: {backendInfo?.instance}</div>

            {(!nativePlatform || nativeVersion !== __BEYOND_FRONTEND_VERSION__) && (
                <div className={innerClassNames}>
                    Frontend: {__BEYOND_FRONTEND_VERSION__}
                </div>
            )}

            {nativePlatform && (
                <div className={innerClassNames}>
                    App version: {nativeVersion}
                </div>
            )}
        </div>
    );
}
