import { transport } from "@/api/transport";
import {
    bigintToNumber,
    fromProtoChannelId,
    fromProtoTimestamp,
    pbBondId,
    pbSquadId,
    pbUserId,
} from "@/api/util";
import * as d from "@/domain/domain";
import { DeltaKnowledgeBond, DeltaSummarySquadMetadata } from "@/domain/intel";
import { deepFreeze } from "@/misc/deepFreeze";
import log from "@/misc/log";
import { Optional } from "@/misc/types";
import { createClient } from "@connectrpc/connect";
import { IntelSummaryStreamingStatus } from "../../gen/proto/domain/domain_pb";
import { QueryAnswerStatus } from "../../gen/proto/domain/domain_pb";
import { IntelService } from "../../gen/proto/intel/intel_connect";
import * as intel_pb from "../../gen/proto/intel/intel_pb";
import { streamHandler } from "./stream";

export const service = createClient(IntelService, transport);
export default service;

export { IntelSummaryStreamingStatus, QueryAnswerStatus } from "../../gen/proto/domain/domain_pb";

export interface getBondTitleSuggestionArgs {
    userId: d.UserId;
    bondContentDraft: string;
}

export async function getBondTitleSuggestion(
    args: getBondTitleSuggestionArgs,
): Promise<string> {
    const req = new intel_pb.SuggestBondTitleRequest({
        userId: pbUserId(args.userId),
        bondContentDraft: args.bondContentDraft,
    });

    const resp = await service.suggestBondTitle(req).catch(e => {
        log.error(e);
        return Promise.reject(e);
    });

    return resp.bondTitleSuggestion ?? "";
}

export interface getDeltaSummaryBondArgs {
    userId: d.UserId;
    bondId: d.BondId;
    lastReadSequenceNumber: number;
    ianaTimezoneName: string;
}

export async function getDeltaSummaryBond(
    args: getDeltaSummaryBondArgs,
): Promise<DeltaKnowledgeBond> {
    const req = new intel_pb.GetDeltaSummaryBondRequest({
        userId: pbUserId(args.userId),
        bondId: pbBondId(args.bondId),
        lastReadSequenceNumber: BigInt(args.lastReadSequenceNumber),
        ianaTimezoneName: args.ianaTimezoneName,
    });

    const resp = await service.getDeltaSummaryBond(req).catch(e => {
        log.error(e);
        return Promise.reject(e);
    });

    return deepFreeze({
        bondId: args.bondId,
        deltaSummaryBond: resp.deltaSummaryBond,
        deltaSummaryBondLive: resp.deltaSummaryBondLive,
        lastReadSeqNumber: args.lastReadSequenceNumber,
        lastSummarisedSeq: bigintToNumber(resp.maxMessageSequenceNumber),
        firstNewMessageTs: fromProtoTimestamp(resp.firstNewMessageTs),
        liveSessionStartedAt: resp.liveSessionStartedAtTs
            ? fromProtoTimestamp(resp.liveSessionStartedAtTs) : undefined,
    });
}

export type DeltaSummarySquadOrOther =
    | { case: "summaryPiece"; summary: string; }
    | { case: "status"; status: IntelSummaryStreamingStatus; }
    | { case: "metadata"; metadata: DeltaSummarySquadMetadata; };

function getDeltaSummarySquadOrInboxResponseParser(
    resp: intel_pb.GetDeltaSummarySquadResponse,
): DeltaSummarySquadOrOther {
    const r = resp.response;
    switch (r.case) {
        case "summaryPiece": {
            return { case: "summaryPiece", summary: r.value };
        }
        case "progressStatus": {
            return { case: "status", status: r.value };
        }
        case "metadata": {
            return {
                case: "metadata",
                metadata: translateDeltaSummarySquadOrInboxMetadata(r.value),
            };
        }
        default:
            throw new Error(`unexpected delta summary squad response case: ${r.case}`);
    }
}

function translateDeltaSummarySquadOrInboxMetadata(
    md?: intel_pb.DeltaSummarySquadMetadata,
): DeltaSummarySquadMetadata {
    if (!md) {
        return {} as DeltaSummarySquadMetadata;
    }
    return {
        TimeOfFirstMessage: fromProtoTimestamp(md.firstNewMessageTs),
        ChannelReadStates: md.channelReadStates.map(st => {
            return {
                ChannelId: fromProtoChannelId(st.channelId),
                LastReadSequenceNumber: bigintToNumber(st.lastReadSequenceNumber),
                MaxMessageSequenceNumber: bigintToNumber(st.maxMessageSequenceNumber),
            };
        }),
        LastActivityAt: fromProtoTimestamp(md.lastActivityAt),
    };
}

export interface getDeltaSummarySquadOrInboxArgs {
    userId: d.UserId;
    squadId: Optional<d.SquadId>;
    ianaTimezoneName: string;
}
export async function* getDeltaSummarySquadOrInbox(
    args: getDeltaSummarySquadOrInboxArgs,
): AsyncGenerator<DeltaSummarySquadOrOther> {
    const req = new intel_pb.GetDeltaSummarySquadRequest({
        userId: pbUserId(args.userId),
        squadId: args.squadId ? pbSquadId(args.squadId) : undefined,
        ianaTimezoneName: args.ianaTimezoneName,
    });

    const stream = service.getDeltaSummarySquad(req);
    yield* streamHandler(
        stream,
        getDeltaSummarySquadOrInboxResponseParser,
        "deltaSummarySquadOrInbox",
    );
}

export type AnswerDeltaOrOther =
    | { case: "answer"; answer: string; }
    | { case: "progress"; status: QueryAnswerStatus; }
    | { case: "search"; results: string; };

function queryResponseParser(
    res: intel_pb.QueryResponse,
): AnswerDeltaOrOther {
    const r = res.result;
    switch (r.case) {
        case "answerDelta": {
            return { case: "answer", answer: r.value };
        }
        case "progressStatus": {
            return { case: "progress", status: r.value };
        }
        case "searchResults": {
            return { case: "search", results: r.value };
        }

        default:
            throw new Error(`unexpected bondOrDeleted case: ${r.case}`);
    }
}

export interface queryArgs {
    userId: d.UserId;
    question: string;
}

export async function* askQuery(
    args: queryArgs,
): AsyncGenerator<AnswerDeltaOrOther> {
    const req = new intel_pb.QueryRequest({
        userId: pbUserId(args.userId),
        query: args.question,
    });

    const stream = service.query(req);
    yield* streamHandler(stream, queryResponseParser, "query");
}
