import { FC, useCallback, useEffect, useMemo, useState } from "react";
// import { useOutletContext } from "react-router-dom";

import { CloseButton } from "@/components/buttons/Close";
import * as d from "@/domain/domain";
import { userNameForMention } from "@/domain/mentions";
import { UserOverview } from "@/domain/users";

import { ButtonMain } from "@/components/buttons/ButtonMain";
import PillSelector from "@/components/gui/PillSelector";
import {
    EmailInviteSuggestionContent,
    UserInviteSuggestionContent,
} from "@/components/NewBondAudienceInput";
import {
    asPendingEmailMember,
    asPendingEmailOverview,
    extractPendingEmail,
    isPendingEmail,
    isPendingEmailOverview,
    PendingEmailMember,
    PendingEmailOverview,
} from "@/domain/invites";
import {
    addUserToSquad,
    inviteUserToSquadViaEmailThunk,
    selectSquadById,
    selectSquadIdFromViewStack,
} from "@/features/squads";
import { selectAllUserIds, selectUsers } from "@/features/users";
import { useInterestedContacts } from "@/hooks/interest/useInterestedContacts";
import useViewStackNavigate from "@/hooks/navigation/useViewStackNavigate";
import useAudienceSuggestionDomain, {
    EmailSubdomainOptions,
    UserSubdomainOptions,
} from "@/hooks/useAudienceSuggestionDomain";
import useDialogOpenRef from "@/hooks/useDialogOpenRef";
import useDialogOutsideClick from "@/hooks/useDialogOutsideClick";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import useSet from "@/hooks/useSet";
import log from "@/misc/log";
import { useAppDispatch, useAppSelector } from "@/store/redux";

type UserIdOrEmail = d.UserId | PendingEmailMember;
type UserOverviewOrEmailOverview = UserOverview | PendingEmailOverview;

const AudienceMemberPillContent: FC<{ data: UserOverviewOrEmailOverview; }> = ({ data }) => {
    if (isPendingEmailOverview(data)) {
        return (
            <div className="u-truncate-auto">
                Invite <strong>{extractPendingEmail(data.id)}</strong> by email
            </div>
        );
    }

    return <>{userNameForMention(data)}</>;
};

const getAudienceMemberTitle = (data: UserOverviewOrEmailOverview) => {
    if (isPendingEmailOverview(data)) {
        return extractPendingEmail(data.id);
    }
    return data.name;
};

const getAddButtonText = (nUsers: number, squadName?: string): string => {
    const users = nUsers == 1 ? "1 user" : `${nUsers} users`;
    const name = squadName ? `${squadName}` : "squad";
    return `Add ${users} to ${name}`;
};

export default function InviteUsersToSquadModal(): React.JSX.Element {
    const dispatch = useAppDispatch();
    const { navigatePop, navigateRemoveSettingsModals } = useViewStackNavigate();

    const squadId = useAppSelector(selectSquadIdFromViewStack);
    const squadOverview = useSelectorArgs(selectSquadById, squadId);
    const squadName = squadOverview?.name ?? "";

    const closeModal = useCallback(() => {
        navigatePop();
    }, [navigatePop]);

    const closeAllSettings = useCallback(() => {
        navigateRemoveSettingsModals();
    }, [navigateRemoveSettingsModals]);

    const dialogRef = useDialogOpenRef();
    const handleBackdropClick = useDialogOutsideClick(dialogRef, closeModal);

    const [userLookupText, setUserLookupText] = useState("");

    const {
        add: addChosenEmail,
        remove: removeChosenEmail,
        has: emailIsChosen,
        sorted: sortedChosenEmails,
    } = useSet<PendingEmailMember>();

    const selectEmail = useCallback((email: string) => {
        if (!email) return;

        const pendingEmail = asPendingEmailMember(email);

        if (emailIsChosen(pendingEmail)) {
            removeChosenEmail(pendingEmail);
        }
        else {
            addChosenEmail(pendingEmail);
        }
    }, [emailIsChosen, removeChosenEmail, addChosenEmail]);

    const {
        add: addChosenUserId,
        remove: removeChosenUserId,
        has: userIdIsChosen,
        sorted: sortedChosenUserIds,
    } = useSet<d.UserId>();

    const chosenUsers = useSelectorArgs(selectUsers, sortedChosenUserIds);

    const selectUser = useCallback((userId: d.UserId) => {
        if (!userId) return;
        if (userIdIsChosen(userId)) {
            removeChosenUserId(userId);
        }
        else {
            addChosenUserId(userId);
        }
    }, [addChosenUserId, removeChosenUserId, userIdIsChosen]);

    // Maintain interest in all squads and users that the backend can make known to
    // the user while this modal is open
    useInterestedContacts();

    const selectedUsers = useSelectorArgs(selectUsers, sortedChosenUserIds);

    const selected: UserOverviewOrEmailOverview[] = useMemo(() => [
        ...selectedUsers,
        ...sortedChosenEmails.map(asPendingEmailOverview),
    ], [
        selectedUsers,
        sortedChosenEmails,
    ]);

    const selectAction = useCallback((id: UserIdOrEmail) => {
        if (isPendingEmail(id)) {
            selectEmail(extractPendingEmail(id));
        }
        else if (d.isUserId(id)) {
            selectUser(id);
        }
    }, [selectEmail, selectUser]);

    const deselectAction = useCallback((id: UserIdOrEmail) => {
        if (isPendingEmail(id)) {
            removeChosenEmail(id);
        }
        else if (d.isUserId(id)) {
            removeChosenUserId(id);
        }
    }, [removeChosenEmail, removeChosenUserId]);

    // Build the audience suggestion domain. The set of people valid to add to
    // the squad is any known user not already in the squad.
    const allUserIds = useSelectorArgs(selectAllUserIds);
    const validUserIds = useMemo(() => {
        const idSet = new Set(allUserIds);
        if (squadOverview) {
            squadOverview.userIds.forEach(id => idSet.delete(id));
        }
        return [...idSet.values()];
    }, [allUserIds, squadOverview]);

    const emailSubdomainOptions = useMemo<EmailSubdomainOptions>(
        () => ({
            query: userLookupText,
            Content: EmailInviteSuggestionContent,
            action: selectEmail,
            // overrideHighlight: u => emailIsChosen(u),
        }),
        [userLookupText, selectEmail],
    );
    const userSubdomainOptions = useMemo<UserSubdomainOptions>(
        () => ({
            idsToFilter: validUserIds.filter(uid => !userIdIsChosen(uid)),
            Content: UserInviteSuggestionContent,
            action: u => selectUser(u.id),
            overrideHighlight: u => userIdIsChosen(u.id),
        }),
        [validUserIds, selectUser, userIdIsChosen],
    );

    // Disable adding-by-email if the relevant feature flag is off
    // const emailInvitesEnabled = useBooleanFeatureFlag("email-invites-for-squads");
    const emailInvitesEnabled = true;

    const flaggedEmailSubdomainOptions = emailInvitesEnabled ? emailSubdomainOptions : undefined;

    const suggestionDomain = useAudienceSuggestionDomain({
        email: flaggedEmailSubdomainOptions,
        user: userSubdomainOptions,
    });

    const inviteAllChosen = useCallback(() => {
        log.info(
            `Adding users to squad (${sortedChosenUserIds.length} ids, ${sortedChosenEmails.length} emails)`,
            sortedChosenUserIds,
            squadId,
        );
        if (!squadId) {
            log.error("No squadId to add users to");
            return;
        }

        // TODO: bulk action, not lots of individual ones
        sortedChosenUserIds.forEach(userId => {
            dispatch(addUserToSquad({ squadId, userId }));
        });

        sortedChosenEmails.forEach(invitedEmailAddress =>
            dispatch(inviteUserToSquadViaEmailThunk({
                squadId,
                invitedEmailAddress: extractPendingEmail(invitedEmailAddress),
            }))
        );

        closeModal();
    }, [dispatch, closeModal, squadId, sortedChosenUserIds, sortedChosenEmails]);

    const totalChosen = sortedChosenEmails.length + sortedChosenUserIds.length;

    const buttonText = getAddButtonText(
        sortedChosenEmails.length + chosenUsers.length,
        squadOverview?.name,
    );

    const placeholder = emailInvitesEnabled ? "Name or mail@example.com" : "Name";

    // Redirect after 2 seconds if the squad overview is not available
    const shouldRedirect = !squadOverview;
    useEffect(() => {
        if (shouldRedirect) {
            const t = setTimeout(() => {
                closeModal();
            }, 2 * 1000);

            return () => {
                clearTimeout(t);
            };
        }
    }, [closeModal, shouldRedirect]);

    if (!squadOverview) return <></>;

    return (
        <dialog
            className="cp-dialog cp-dialog--desktop-notification"
            onClose={closeModal}
            onMouseDown={handleBackdropClick}
            ref={dialogRef}
            role="dialog"
        >
            <header className="cp-dialog__header">
                <button className="cp-btn-back" onClick={closeModal}>Return</button>
                <h1 className="cp-dialog__title">Add people to {squadName}</h1>
                <CloseButton side="right" onClick={closeAllSettings} />
            </header>

            <article className="cp-dialog__content-wrapper">
                <div className="cp-dialog__content cp-dialog__content--add-people">
                    {/* TODO: Remove article tag and update PillSelector to new UX */}
                    <article className="c-dialog__content-wrapper">
                        <PillSelector
                            label=""
                            placeholder={placeholder}
                            PillContent={AudienceMemberPillContent}
                            getPillTitle={getAudienceMemberTitle}
                            selected={selected}
                            selectAction={selectAction}
                            deselectAction={deselectAction}
                            suggestionDomain={suggestionDomain}
                            dialogMode={true}
                            queryChanged={setUserLookupText}
                        />
                    </article>

                    <ButtonMain
                        icon="add"
                        label={buttonText}
                        onClick={inviteAllChosen}
                        isDisabled={totalChosen === 0}
                    />
                </div>
            </article>
        </dialog>
    );
}
