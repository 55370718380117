import { Optional } from "@/misc/types";
import { usePostHog } from "posthog-js/react";
import { useEffect, useState } from "react";
import { FeatureFlagId } from "../../gen/features/features";

const featureFlagToString = (val: boolean | string | undefined): Optional<string> =>
    (typeof val === "string") ? val : undefined;

/**
 * Similar to the <FeatureFlagged /> component in that it checks a feature flag and
 * ensures it is ready on first render. But just a hook returning a variant string
 * rather than a component wrapper.
 */
export const useStringFeatureFlag = (flag: FeatureFlagId): Optional<string> => {
    const client = usePostHog();
    const [value, setValue] = useState<Optional<string>>(
        featureFlagToString(client.getFeatureFlag(flag)),
    );

    useEffect(
        () =>
            client.onFeatureFlags(
                () => setValue(featureFlagToString(client.getFeatureFlag(flag))),
            ),
        [client, flag],
    );

    return value;
};

export default useStringFeatureFlag;
