export default function ReconnectingPill(): React.JSX.Element {
    return (
        <div className="cp-offline-indicator">
            <div className="cp-spinner-offline">
                <div className="cp-spinner-offline__icon cp-spinner-offline__icon--outer" />
                <div className="cp-spinner-offline__icon cp-spinner-offline__icon--inner" />
            </div>
            <div className="cp-offline-indicator__label">Reconnecting</div>
        </div>
    );
}
