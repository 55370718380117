import { bigintToNumber } from "@/api/util";
import * as d from "@/domain/domain";
import { hamtGet, ImmutableHAMTSet } from "@/ds/hamt";
import { ExpandType, Optional } from "@/misc/types";
import { discriminatedUnionValueSchemaBuilder } from "@/misc/zod";
import omit from "lodash.omit";
import { z } from "zod";

const BondKnowledgePreviewSchema = z.object({
    aiGeneratedTitle: z.string().optional(),
    userSpecifiedTitle: z.string(),
    summary: z.string(),
    detailedSummary: z.string(),
    imageUrl: z.string(),
});
export type BondKnowledgePreview = z.infer<typeof BondKnowledgePreviewSchema>;

const PrivacyLevelSchema = z.enum([
    "unspecified",
    "open",
    "private",
]);
export type PrivacyLevel = z.infer<typeof PrivacyLevelSchema>;

// A BondOverview is the full set of Bond information needed to render the
// BondView for that bond. It is the response type for SubBond and GetBonds.
const BondOverviewSchema = z.object({
    id: d.bondIdSchema,
    orgId: d.orgIdSchema,
    channelId: d.channelIdSchema,
    squadIds: d.squadIdSchema.array(),
    privacy: PrivacyLevelSchema,
    knowledgePreview: BondKnowledgePreviewSchema,
    contributors: d.userSetSchema,
    followers: d.userSetSchema,
    lastActivityAt: d.timestampSchema,
    maxSequenceNumber: z.bigint().transform(x => bigintToNumber(x)),
    liveCalls: d.callSetSchema,
    invitedPersons: d.personSetSchema,
    invitedEmails: z.string().array(),
    creatorId: d.userIdSchema.optional(),
    externalUsers: d.userSetSchema.optional(), // Deprecated
}).transform(({ knowledgePreview, liveCalls, invitedPersons, ...overview }) => ({
    ...omit(overview, "externalUsers"),
    knowledge: knowledgePreview,
    liveCallIds: liveCalls,
    invitedPersonIds: invitedPersons,
}));
export type BondOverview = ExpandType<z.infer<typeof BondOverviewSchema>>;

export const BondOverviewOrDeletedSchema = z.discriminatedUnion("case", [
    discriminatedUnionValueSchemaBuilder("bond", BondOverviewSchema),
    discriminatedUnionValueSchemaBuilder("deletedId", d.bondIdSchema),
]);
export type BondOverviewOrDeleted = z.infer<typeof BondOverviewOrDeletedSchema>;

// A BondHerald holds the subset of information about a bond that is needed to
// include or exclude that bond from a list of bonds. It is the response type
// for ListBonds.
const BondHeraldSchema = z.object({
    bondId: d.bondIdSchema,
    squadIds: d.squadIdSchema.array(),
    lastActivityAt: d.timestampSchema,
    requesterIsFollower: z.boolean(),
}).transform(({ requesterIsFollower, ...overview }) => ({
    ...overview,
    currentUserIsFollower: requesterIsFollower,
}));
export type BondHerald = z.infer<typeof BondHeraldSchema>;

export const BondHeraldOrDeletedSchema = z.discriminatedUnion("case", [
    discriminatedUnionValueSchemaBuilder("herald", BondHeraldSchema),
    discriminatedUnionValueSchemaBuilder("deletedId", d.bondIdSchema),
]);
export type BondHeraldOrDeleted = z.infer<typeof BondHeraldOrDeletedSchema>;

export interface CatchupKnowledge {
    bondId: d.BondId;
    summary: string;
    lastSummarisedSeq: number;
}

export type BondTitles = {
    userSpecifiedTitle?: string;
    aiGeneratedTitle?: string;
};

export type BondOverviewPredicate = (bo: BondOverview) => boolean;
export const bondHasSquadId = (squadId: d.SquadId): BondOverviewPredicate => bo =>
    bo.squadIds.includes(squadId);
export const bondIsFollowed = (currentUserId: Optional<d.UserId>): BondOverviewPredicate => bo =>
    (currentUserId && bo.followers?.includes(currentUserId)) ?? false;
export const bondIsNotFollowed = (currentUserId: Optional<d.UserId>): BondOverviewPredicate => bo =>
    !((currentUserId && bo.followers?.includes(currentUserId)) ?? false);
export const bondIsArchived =
    (archivedSet: ImmutableHAMTSet<d.BondId>): BondOverviewPredicate => bo =>
        !!hamtGet(archivedSet, bo.id);
export const bondIsNotArchived =
    (archivedSet: ImmutableHAMTSet<d.BondId>): BondOverviewPredicate => bo =>
        !hamtGet(archivedSet, bo.id);
export const hasImage = (bo: BondOverview) => !!bo.knowledge.imageUrl;

export type BondHeraldPredicate = (bh: BondHerald) => boolean;
export const heraldHasSquadId = (squadId: d.SquadId): BondHeraldPredicate => bh =>
    bh.squadIds.includes(squadId);
export const heraldIsFollowed: BondHeraldPredicate = bh => bh.currentUserIsFollower;
export const heraldIsNotFollowed: BondHeraldPredicate = bh => !bh.currentUserIsFollower;
