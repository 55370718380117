import { CompactChangeDelta, CompactMarkupDelta } from "./delta";

// region Types

/**
 * Object with string keys containing attributes associated with an Op
 */
export type LegacyOpAttributes = Record<string, unknown>;

/**
 * An operation which inserts either text or an embed, optionally providing associated attributes
 */
export interface LegacyInsertOp {
    insert: string | Record<string, unknown>;
    attributes?: LegacyOpAttributes;
}

/**
 * An operation which retains some number of characters, optionally updating the attributes
 */
export interface LegacyRetainOp {
    retain?: number | Record<string, unknown>;
    attributes?: LegacyOpAttributes;
}

/**
 * An operation which deletes some number of characters
 */
export interface LegacyDeleteOp {
    delete: number;
}

/**
 * An array of insert operations which can be converted to a `MarkupDelta` instance
 *
 * This type is used for deltas that are stored in the Redux store and sent over the wire
 */
export type LegacyMarkupDelta = LegacyInsertOp[];

/**
 * Equivalent to `ChangeDelta`, but with an array of operations in place of the `Delta`
 * instance. This allows it to be serialised in Redux actions and state
 *
 * This type is used for deltas that are stored in Redux actions
 */
export interface LegacyChangeDelta {
    case: "change";
    ops: (LegacyInsertOp | LegacyRetainOp | LegacyDeleteOp)[];
}

// region Utilities

/**
 * Convert a legacy change delta to a compact change delta
 */
export const legacyToCompactChangeDelta = (
    delta: LegacyChangeDelta,
): CompactChangeDelta => ({
    result: [],
    change: delta.ops,
});

/**
 * Convert a legacy markup delta to a compact markup delta
 */
export const legacyToCompactMarkupDelta = (
    delta: LegacyMarkupDelta,
): CompactMarkupDelta => delta;

/**
 * Create a new blank legacy markup delta
 */
export const emptyLegacyMarkupDelta = (): LegacyMarkupDelta => [{ insert: "\n" }];
