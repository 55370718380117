import classNames from "classnames";
import { ReactEventHandler, useCallback, useMemo } from "react";

import { isValidAvatarMimeType } from "@/api/users";
import ParticipantTile, { ParticipantTileProps } from "@/components/gui/ParticipantTile";
import * as d from "@/domain/domain";
import {
    getDominantPresenceMode,
    getPresenceBubbleIcon,
    hasLivePresence,
    isLivePresence,
    isVisibleUserPresenceMode,
    livePresenceModesFromTracks,
    PresenceMode,
    PresenceModes,
} from "@/domain/presence";
import { isTrackActive } from "@/domain/rtc";
import { getOrgName, OrgOverview } from "@/domain/squads";
import {
    asActivityRecencyText,
    asScreenShareText,
    getPersonDisplayName,
    getPersonFromUser,
    getPersonNickname,
    PersonalAvatar,
    PersonOverview,
    UserOverview,
} from "@/domain/users";
import { selectOrg } from "@/features/squads";
import {
    retrieveAvatarDownloadBlob,
    selectAvatarBlob,
    selectPerson,
    selectUser,
} from "@/features/users";
import { useInterestedOrgs } from "@/hooks/interest/useInterest";
import { useConnectedEffect } from "@/hooks/useConnectedEffect";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { Optional } from "@/misc/types";
import { useAppDispatch } from "@/store/redux";

type AvatarSize =
    | "card"
    | "card-desktop"
    | "default"
    | "message"
    | "presence"
    | "squad"
    | "suggestion"
    | "system-message"
    | "upload";

interface AvatarContext {
    showBondPresence?: boolean;
    isScreenShare?: boolean;
    isMobile?: boolean;
    isBondCard?: boolean;
    isSquadListing?: boolean;
}

interface AvatarModifiers {
    observingBond?: boolean;
    contributed?: boolean;
    mentionedCurrentUser?: boolean;
    callParticipation?: ParticipantTileProps;
    isLiveBond?: boolean;
    invited?: boolean;
}

type PersonIdOrUserId = d.PersonId | d.UserId;

function usePersonIdOrUserId(id: PersonIdOrUserId): {
    person: Optional<PersonOverview>;
    user: Optional<UserOverview>;
} {
    const personFromId = useSelectorArgs(selectPerson, d.isPersonId(id) ? id : undefined);
    const userFromId = useSelectorArgs(selectUser, d.isUserId(id) ? id : undefined);
    const personFromUser = getPersonFromUser(userFromId);
    const person = personFromId || personFromUser;
    return { person, user: userFromId };
}

export interface IAvatarProps {
    id: PersonIdOrUserId; // Rename to just `id`?
    testId?: string;
    showPresence: boolean;
    size?: AvatarSize;
    hidden?: boolean;
    context?: AvatarContext;
    modifiers?: AvatarModifiers;
}

const getInitials = (name: string) =>
    name.trim()
        .split(" ")
        .map(word => String.fromCodePoint(word.codePointAt(0) || 0))
        .join("")
        .slice(0, 3);

const asInvitedText = (s: string) => `${s} has been invited to the bond.`;

const asAvatarText = (s: string) => `${s}'s avatar`;

const asOrgLabelledText = (s: string, org: Optional<OrgOverview>) =>
    s + (org ? ` (${getOrgName(org)})` : "");

export function AvatarImage(
    { avatar: { blobId }, altText, className = "c-human__image" }: {
        avatar: PersonalAvatar;
        altText?: string;
        className?: string;
    },
): React.JSX.Element {
    const dispatch = useAppDispatch();

    const avatarBlob = useSelectorArgs(selectAvatarBlob, blobId);
    const avatarUrl = avatarBlob?.publicUrl;

    useConnectedEffect(() => {
        if (blobId && !avatarBlob) {
            dispatch(retrieveAvatarDownloadBlob({ blobId }));
        }
    }, [dispatch, blobId, avatarBlob]);

    const errorHandler: ReactEventHandler<HTMLImageElement> = useCallback(e => {
        e.preventDefault();
        e.stopPropagation();

        if (!blobId || !avatarUrl) {
            return;
        }

        dispatch(retrieveAvatarDownloadBlob({ blobId }));
    }, [dispatch, blobId, avatarUrl]);

    if (!avatarBlob || !avatarUrl || !isValidAvatarMimeType(avatarBlob.metadata.mimeType)) {
        return <></>;
    }

    return (
        <img
            className={className}
            alt={altText}
            src={avatarUrl}
            onError={errorHandler}
            draggable={false}
        />
    );
}

export function AvatarFallback(
    { name, className, testId }: {
        name: string;
        className?: string;
        testId?: string;
    },
): React.JSX.Element {
    return (
        <div
            className={className}
            data-testid={testId}
        >
            {getInitials(name)}
        </div>
    );
}

export default function Avatar(
    props: IAvatarProps,
): React.JSX.Element {
    // Obtain the person and optional user from the PersonOrUserId provided
    const { person, user } = usePersonIdOrUserId(props.id);

    const org = useSelectorArgs(selectOrg, user?.orgId);
    useInterestedOrgs(user?.orgId);

    const hidden = !!props.hidden;
    const size = props.size || "default";

    const showPresence = props.showPresence;
    const showBondPresence = showPresence && (!!props.context?.showBondPresence);
    const isScreenShare = !!props.context?.isScreenShare;
    const isMobile = !!props.context?.isMobile;
    const inBondCard = !!props.context?.isBondCard;
    const isSquadListing = !!props.context?.isSquadListing;

    const innerTestId = user ? (isScreenShare
        ? ("screenshare-" + user.id)
        : ("avatar-" + user.id)) :
        ("person-avatar-" + person?.personId || "");
    const testId = props.testId || innerTestId || "";

    const callParticipation = props.modifiers?.callParticipation;
    const isVideoOn = isTrackActive(callParticipation?.videoTrack);
    const isBondLive = !!props.modifiers?.isLiveBond;

    const invited = !!props.modifiers?.invited;

    const presenceModes = useMemo(() => {
        if (!showPresence) {
            return [];
        }

        let modes = person?.activity?.presenceModes ?? [];
        if (callParticipation) {
            // For a user in a call, only use RTC stack as source of live presence info
            modes = modes.filter(mode => !isLivePresence(mode));

            modes.push(...livePresenceModesFromTracks(
                callParticipation.audioTrack,
                callParticipation.videoTrack,
                isScreenShare,
            ));
        }
        return modes;
    }, [person?.activity?.presenceModes, callParticipation, isScreenShare, showPresence]);

    // Show "sharing" icon iff isScreenShare.
    // Otherwise, show the most dominant visibile presence mode that the user has.
    const presenceForIcon = useMemo((): Optional<PresenceMode> => (
        isScreenShare
            ? PresenceModes.screenSharing
            : getDominantPresenceMode(presenceModes.filter(isVisibleUserPresenceMode))
    ), [isScreenShare, presenceModes]);
    const presenceImageString = presenceForIcon ? getPresenceBubbleIcon(presenceForIcon) : "";

    if (hidden || !person) {
        // This empty div is used to align message content
        return <div className={`c-human-spacer`} />;
    }

    const hasLiveParticipation = !!callParticipation || hasLivePresence(presenceModes);
    const active = !!person.activity?.active || hasLiveParticipation;
    const isLiveElsewhere = active && !isBondLive && hasLiveParticipation;

    const nickname = getPersonNickname(person);
    const displayName = getPersonDisplayName(person);

    const avatarAltText = asAvatarText(nickname);
    const presenceText = displayName + asActivityRecencyText(active && person.activity);
    const userHoverText = asOrgLabelledText(showPresence ? presenceText : displayName, org);

    const hoverText = isScreenShare ? asScreenShareText(nickname)
        : invited ? asInvitedText(userHoverText)
        : userHoverText;

    const pictureValid = !!person?.picture;
    const userPicture = pictureValid ? person?.picture : undefined;

    const newUX = isSquadListing;

    // Formatting CSS classes:
    const figureClasses = classNames(`c-human`, `c-human--${size}`, {
        "c-human--offline": showPresence && !active,
        "c-human--multitasking": showBondPresence && isLiveElsewhere,
        "c-human--card": inBondCard && isMobile,
        "c-human--card-desktop": inBondCard && !isMobile,
        "c-human--squad-human": isSquadListing,
    });

    // TODO: We should put the participant tile alongside the backup
    // avatarHTML once we figure out how to not do this dangerously.
    const fallbackVisual = (
        <AvatarFallback
            className={"c-human__fallback"}
            name={person.name}
            testId={"fallback-" + testId}
        />
    );

    return (
        <figure
            className={figureClasses}
            title={hoverText}
            data-testid={testId}
        >
            {!isVideoOn && (
                userPicture ? (
                    <AvatarImage
                        avatar={userPicture}
                        altText={avatarAltText}
                        className={newUX ? "c-human__image" : "c-human__avatar"}
                    />
                ) : fallbackVisual
            )}
            {callParticipation && (
                <ParticipantTile
                    {...callParticipation}
                    videoTitle={false}
                />
            )}
            {presenceImageString && (
                <div
                    className="c-human__activity"
                    style={{
                        backgroundImage: `url("${presenceImageString}")`,
                    }}
                />
            )}
        </figure>
    );
}

export interface IEmailAvatarProps {
    email: string;
    testId?: string;
    size?: AvatarSize;
    hidden?: boolean;
    invited?: boolean;
    context?: AvatarContext;
}

export function EmailAvatar(
    props: IEmailAvatarProps,
): React.JSX.Element {
    const email = props.email;

    const hidden = !!props.hidden;
    const size = props.size || "default";
    const testId = props.testId || "";
    const invited = !!props.invited;

    const inBondCard = !!props.context?.isBondCard;
    const isMobile = !!props.context?.isMobile;

    const hoverText = invited ? asInvitedText(email) : email;

    const figureClasses = classNames(`c-human`, `c-human--${size}`, {
        "c-human--card": inBondCard && isMobile,
        "c-human--card-desktop": inBondCard && !isMobile,
    });

    if (hidden || email == "") {
        return <div className={`c-human-spacer`} />;
    }
    return (
        <figure
            className={figureClasses}
            title={hoverText}
            data-testid={testId}
        >
            <AvatarFallback
                className={"c-human__fallback"}
                name={email}
                testId={"inner-" + testId}
            />
        </figure>
    );
}
