import * as d from "@/domain/domain";
import { SquadOverview } from "@/domain/squads";
import { UserOverview } from "@/domain/users";

export type AudienceMember =
    | d.SquadId
    | d.UserId;

export type AudienceMemberOverview =
    | SquadOverview
    | UserOverview;

export enum AudienceAction {
    Unspecified = "unspecified",
    Add = "add",
    AddNotify = "add_notify",
    Remove = "remove",
}

export type AudienceOp = {
    target: AudienceMember;
    action: AudienceAction;
};

export const isSquadOverview = (overview: AudienceMemberOverview): overview is SquadOverview =>
    d.isSquadId(overview.id);

export const isUserOverview = (overview: AudienceMemberOverview): overview is UserOverview =>
    d.isUserId(overview.id);
