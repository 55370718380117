import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import MediaDeviceSelector from "@/components/gui/MediaDeviceSelector";
import VolumeMeter from "@/components/gui/VolumeMeter";
import { DeviceKind, MediaInputKind } from "@/domain/mediaDevices";
import useAudioSinkRef from "@/hooks/media/useAudioSinkRef";
import { useMediaTrack } from "@/hooks/media/useMediaTrack";
import useRefCallback from "@/hooks/useRefCallback";
import { isNativePlatform } from "@/misc/capacitor";
import { Optional } from "@/misc/types";
import { ButtonMain } from "../buttons/ButtonMain";

const EXAMPLE_AUDIO_URL =
    "https://interactive-examples.mdn.mozilla.net/media/cc0-audio/t-rex-roar.mp3";

export default function AudioPreview(): React.JSX.Element {
    // Capture MediaStream for volume meter
    const [micStream, setMicStream] = useState<Optional<MediaStream>>(undefined);
    const onGetMedia = useCallback(async (stream: MediaStream) => {
        setMicStream(stream);
    }, []);
    const onEndMedia = useCallback(() => {
        setMicStream(undefined);
    }, []);
    const streamCapturer = useMemo(() => ({
        publish: onGetMedia,
        unpublish: onEndMedia,
    }), [onGetMedia, onEndMedia]);

    // Play a fake sound if no device selected
    const [sampleAudioEnabled, setSampleAudioEnabled] = useState(false);
    const isSampleAudioPlaying = useRef(false);
    const enableSampleAudio = useCallback(() => {
        setSampleAudioEnabled(true);
    }, []);

    const { track: micTrack, toggleTrack } = useMediaTrack({
        endOnToggle: true,
        mediaKind: MediaInputKind.AudioInput,
        onNoDevice: enableSampleAudio,
        publisher: streamCapturer,
    });

    const micEnabled = micTrack.enabled;

    useEffect(() => {
        if (!micEnabled) {
            setSampleAudioEnabled(false);
        }
    }, [micEnabled]);

    const playSampleAudio = useCallback((node: HTMLAudioElement) => {
        if (sampleAudioEnabled) {
            node.src = EXAMPLE_AUDIO_URL;
            node.play();
            isSampleAudioPlaying.current = true;
        }
        else if (isSampleAudioPlaying.current) {
            node.pause();
            isSampleAudioPlaying.current = false;
        }
    }, [sampleAudioEnabled]);

    const audioSinkRefCb = useAudioSinkRef({ track: micTrack });
    const audioRef = useRefCallback(playSampleAudio, { parentRefCb: audioSinkRefCb });

    const isNative = isNativePlatform();

    return (
        <>
            <div className="cp-form-element-audio">
                <VolumeMeter enabled={micEnabled} stream={micStream} />
                <MediaDeviceSelector kind={DeviceKind.AudioInput} id="defaultSelect" />
                <audio ref={audioRef} autoPlay loop data-testid={"preview-audio"} />
            </div>

            {!isNative && (
                <div className="cp-form-elements">
                    <MediaDeviceSelector kind={DeviceKind.AudioOutput} id="defaultSelect" />
                    <ButtonMain
                        data-testid="play-btn"
                        isCentred
                        label={micEnabled ? "Stop" : "Play"}
                        onClick={toggleTrack}
                    />
                </div>
            )}
        </>
    );
}
