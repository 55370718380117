import AvatarUploadControls from "@/components/AvatarUploadControls";
import { selectUser, setUserDetails } from "@/features/users.ts";
import useSelectorArgs from "@/hooks/useSelectorArgs.ts";
import { selectCurrentUserId, useAppDispatch, useAppSelector } from "@/store/redux.ts";
import { useCallback, useRef, useState } from "react";

interface ProfileTextInputProps {
    name: string;
    text: string;
    value: string;
    onValueChanged: (value: string) => void;
    onConfirmChange: () => void;
}

function ProfileTextInput(
    { name, text, value, onValueChanged, onConfirmChange }: ProfileTextInputProps,
): React.JSX.Element {
    const inputRef = useRef<HTMLInputElement>(null);

    const onInputKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            inputRef.current?.blur();
        }
    }, []);

    return (
        <div className="cp-form-element">
            <input
                type="text"
                ref={inputRef}
                id={name}
                data-testid={name}
                autoComplete="off"
                maxLength={100}
                className="cp-input"
                placeholder=" "
                value={value}
                onChange={e => onValueChanged(e.target.value)}
                onBlur={onConfirmChange}
                onKeyDown={onInputKeyDown}
            />
            <label htmlFor={name} className="cp-label">
                {text}
            </label>
        </div>
    );
}

export function ProfileSettingsView(): React.JSX.Element {
    const dispatch = useAppDispatch();

    const userId = useAppSelector(selectCurrentUserId);
    const user = useSelectorArgs(selectUser, userId);
    const [name, setName] = useState(user?.name ?? "");
    const [nickname, setNickname] = useState(user?.nickname ?? "");

    const changeConfirmed = useCallback(() => {
        if (userId && (name !== user?.name || nickname !== user?.nickname)) {
            dispatch(setUserDetails({
                userId: userId,
                displayName: name,
                nickname: nickname,
            }));
        }
    }, [dispatch, userId, user, name, nickname]);

    return (
        <>
            <ProfileTextInput
                name="name"
                text="Full name"
                value={name}
                onValueChanged={setName}
                onConfirmChange={changeConfirmed}
            />
            <ProfileTextInput
                name="nickname"
                text="Display name"
                value={nickname}
                onValueChanged={setNickname}
                onConfirmChange={changeConfirmed}
            />
            <AvatarUploadControls />
        </>
    );
}
