import type { ExpandType, Optional } from "@/misc/types";
import type { AudienceMember } from "../audience";
import type { Mention } from "../mentions";
import type {
    ChangeDelta,
    CompactChangeDelta,
    CompactMarkupDelta,
    MarkupDelta,
} from "./quill/delta";
import type { InternalNode, InternalTransform, JSONNode, JSONTransform } from "./tiptap/node";

// region Enums

/**
 * Enumeration of rich text providers; i.e. underlying library used for the editor
 */
export enum Provider {
    Quill = 0,
    Tiptap = 1,
}

/**
 * Provider that is used before the feature flag is loaded the first time
 */
export const defaultProvider = Provider.Quill;

/**
 * Return a canonical string for a provider
 */
export const providerToString = (provider: Provider): string => {
    switch (provider) {
        case Provider.Quill:
            return "quill";
        case Provider.Tiptap:
            return "tiptap";
    }
};

/**
 * Return a provider given a canonical string. If the string isn't recognised, return
 * the default provider
 */
export const providerFromString = (input: Optional<string>): Provider => {
    switch (input) {
        case "quill":
            return Provider.Quill;
        case "tiptap":
            return Provider.Tiptap;
        default:
            return defaultProvider;
    }
};

/**
 * Enumeration of formats for rich text data stored by each provider. This is mostly
 * relevant for serialization, since the rich text bridges will internally manage the
 * format used for operations
 */
export enum Format {
    /**
     * Internal format data may not be serializable, but may contain additional state
     * or capabilities and may be the only format directly accepted by the provider API
     */
    Internal = 0,

    /**
     * POJO format capable of being serialized using JSON.stringify. May not be directly
     * accepted by the provider API
     */
    JSON = 1,
}

/**
 * Return a canonical string for a Format
 */
export const formatToString = (format: Format): string => {
    switch (format) {
        case Format.Internal:
            return "internal";
        case Format.JSON:
            return "json";
    }
};

// region Type config.

/**
 * Mapping of provider/format to concrete types for documents
 */
export type DocTypes = {
    [Provider.Quill]: {
        [Format.Internal]: MarkupDelta;
        [Format.JSON]: CompactMarkupDelta;
    };
    [Provider.Tiptap]: {
        [Format.Internal]: InternalNode;
        [Format.JSON]: JSONNode;
    };
};

/**
 * Mapping of provider/format to concrete types for changes
 */
export type ChangeTypes = {
    [Provider.Quill]: {
        [Format.Internal]: ChangeDelta;
        [Format.JSON]: CompactChangeDelta;
    };
    [Provider.Tiptap]: {
        [Format.Internal]: InternalTransform;
        [Format.JSON]: JSONTransform;
    };
};

/**
 * Mapping of provider to concrete types for positions. All positions are assumed to
 * be JSON-serializable
 */
export type PositionTypes = {
    [Provider.Quill]: number;
    [Provider.Tiptap]: number;
};

// region Types

export type AnyDocType = DocTypes[Provider][Format];
export type AnyChangeType = ChangeTypes[Provider][Format];
export type AnyPositionType = PositionTypes[Provider];
export type AnyBackingType = AnyDocType | AnyChangeType | AnyPositionType;

/**
 * Type used to form a discriminated union over providers and formats for each of
 * documents and changes. Type parameterization allows client code to require a particular
 * provider/format at compile time, which is useful when writing code specific to one provider,
 * or requiring a serializable value for storage
 */
export type Tag<P extends Provider, F extends Format> = {
    /**
     * The id of the rich text provider for the tagged data
     */
    p: P;
    /**
     * The id of the rich text format for the tagged data
     */
    f: F;
};

/**
 * Discriminated union over providers and formats of document types
 */
export type Doc<P extends Provider, F extends Format> = ExpandType<
    { rawDoc: DocTypes[P][F]; } & Tag<P, F>
>;

/**
 * Discriminated union over providers and formats of change types
 */
export type Change<P extends Provider, F extends Format> = ExpandType<
    { rawChange: ChangeTypes[P][F]; } & Tag<P, F>
>;

/**
 * Utility containing a change and the result of applying that change
 */
export type ChangeResult<P extends Provider, F extends Format> = {
    doc: Doc<P, F>;
    change: Change<P, F>;
};

/**
 * Discriminated union over providers of position types
 */
export type Position<P extends Provider> = {
    rawPosition: PositionTypes[P];
    /**
     * The id of the rich text provider for the position
     */
    p: P;
};

/**
 * Utility containing a start and end position having the same provider
 */
export type PositionRange<P extends Provider> = {
    start: Position<P>;
    end: Position<P>;
};

/**
 * List of mentions which were added and were the last of their type
 * removed between two documents
 */
export type MentionDiff = {
    addedMentions: Mention[];
    lastRemovedMentions: Mention[];
};

/**
 * The doc bridge interface contains functions for getting information from a document,
 * converting it to other formats and providers, and applying changes. The interface
 * should be implemented once for each underlying provider
 */
export interface DocBridgeWithoutConvert<P extends Provider> {
    /**
     * Return whether the document is empty. This may ignore whitespace that is required
     * in certain formats, such as the terminal newline in Quill deltas
     */
    getIsEmpty(): boolean;

    /**
     * Return whether the document has the same contents as another
     */
    getEquals(other: Doc<P, Format>): boolean;

    /**
     * Return a list of mentions in the document, in the order that they appear
     */
    getMentions(): Mention[];

    /**
     * Return the count of mentions in the document
     */
    getMentionCount(): number;

    /**
     * Compare to another document and return the list of mentions that were added in that
     * document, and a list of mentions which were removed (for which no duplicates remain)
     * in that document, relative to the current document
     */
    getMentionDiff(other: Doc<P, Format>): MentionDiff;

    /**
     * Get the contents of the document rendered as text
     *
     * @param expandMentions if true, mentions are rendered as text; otherwise each as a single space
     */
    getText(expandMentions: boolean): string;

    /**
     * Return the document, converted to the specified format
     */
    get<F extends Format>(format: F): Doc<P, F>;

    /**
     * Return the bridge provider
     */
    get p(): Provider;
}

/**
 * Augment a doc bridge with a method for converting the provider
 */
export interface DocBridge<P extends Provider> extends DocBridgeWithoutConvert<P> {
    convert<P2 extends Provider>(provider: P2): DocBridge<P2>;
}

/**
 * The change on doc bridge interface contains functions for building changes. Due to the limitations
 * of some providers, it is only possible to build changes in relation to the doc on which they will
 * ultimately be applied
 */
export interface ChangeBridge<P extends Provider> {
    /**
     * Insert the given text into the document at the given range, deleting the previous contents
     * of the range
     */
    spliceText(
        text: string,
        start: Position<P>,
        end: Position<P>,
    ): ChangeBridge<P>;

    /**
     * Insert the given mention into the document at the given range, deleting the previous
     * contents of the range
     */
    spliceMention(
        mention: Mention,
        text: string,
        start: Position<P>,
        end: Position<P>,
    ): ChangeBridge<P>;

    /**
     * Find all mentions that target a specified user and convert them to text, removing the
     * trigger character (i.e. '@')
     */
    breakMentions(target: AudienceMember): ChangeBridge<P>;

    /**
     * Remove any leading and trailing whitespace from the document
     */
    trimWhitespace(): ChangeBridge<P>;

    /**
     * Replace the document with the contents of another
     */
    replace(other: Doc<P, Format>): ChangeBridge<P>;

    /**
     * Clear the document (replace with the empty document)
     */
    clear(): ChangeBridge<P>;

    /**
     * Return the change and the updated doc, converted to the specified format
     */
    get<F extends Format>(format: F): ChangeResult<P, F>;

    /**
     * Return the bridge provider
     */
    get p(): Provider;
}
