import * as d from "@/domain/domain";
import { z } from "zod";

export const ExpiringSasUrlSchema = z.object({
    url: z.string(),
    urlExpiry: d.timestampSchema,
});
export type ExpiringSasUrl = z.infer<typeof ExpiringSasUrlSchema>;

export const BlobOwnershipDetailsSchema = z.object({
    uploaderId: d.userIdSchema,
    orgId: d.orgIdSchema,
});
export type BlobOwnershipDetails = z.infer<typeof BlobOwnershipDetailsSchema>;

const BlobDimensionsSchema = z.object({
    width: z.number(),
    height: z.number(),
});
export type BlobDimensions = z.infer<typeof BlobDimensionsSchema>;

export const blobMetadataSchema = z.object({
    fileName: z.string(),
    mimeType: z.string(),
    fileSize: z.number(),
    dimensions: BlobDimensionsSchema.optional(),
});
export type BlobMetadata = z.infer<typeof blobMetadataSchema>;

const mimeTypePreparer = (mimeType: string | undefined): string =>
    mimeType || "application/octet-stream";

export const fileToBlobMetadata = (file: File): BlobMetadata => ({
    fileName: file.name,
    fileSize: file.size,
    mimeType: mimeTypePreparer(file.type),
});

export const BlobCredentialsSchema = ExpiringSasUrlSchema.extend({
    type: z.enum(["upload", "download"]).default("download"),
    blobId: d.blobIdSchema,
});
export type BlobCredentials = z.infer<typeof BlobCredentialsSchema>;

export function credentialsValidWithin(
    creds: BlobCredentials,
    seconds: number,
    now?: number,
): boolean {
    return !!creds.url && (now ?? Date.now()) + seconds * 1000 < creds.urlExpiry;
}
