import { useEffect } from "react";

import { selectSharedWorkerWatchDogLock } from "@/features/meta";
import log from "@/misc/log";
import { useAppSelector } from "@/store/redux";

export default function SharedWorkerWatchdog(): React.JSX.Element {
    const sharedWorkerWatchdogLock = useAppSelector(selectSharedWorkerWatchDogLock);

    const handleWorkerLockAcquired = () => {
        log.warn("Shared worker watchdog lock acquired, reloading page");
        location.reload();
    };

    useEffect(() => {
        const controller = new AbortController();

        if (sharedWorkerWatchdogLock) {
            navigator.locks.request(
                sharedWorkerWatchdogLock,
                { signal: controller.signal },
                handleWorkerLockAcquired,
            );
        }

        return () => controller.abort();
    }, [sharedWorkerWatchdogLock]);

    return <></>;
}
