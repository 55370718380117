import speakingBubbleIcon from "../../assets/icons/speaking-animated-icon.svg";
import speakingIcon from "../../assets/icons/speaking-animated-icon.svg";
import typingBubbleIcon from "../../assets/icons/typing-animated-icon.svg";
import typingIcon from "../../assets/icons/typing-animated-icon.svg";
import * as domain_pb from "../../gen/proto/domain/domain_pb";

import * as d from "@/domain/domain";
import { isTrackActive, Track } from "@/domain/rtc";
import { Optional } from "@/misc/types";
import { z } from "zod";

export interface UserObservation {
    userId: d.UserId;
    current: boolean;
    startedAt?: d.Timestamp;
    endedAt?: d.Timestamp;
}

export const PresenceModeSchema = z.enum([
    "unknown",
    "typing",
    "speaking",
    "videoCalling",
    "screenSharing",
    "liveObserving",
]);
export const PresenceModes = PresenceModeSchema.Enum;
export type PresenceMode = z.infer<typeof PresenceModeSchema>;

export const getPresenceIcon = (mode?: PresenceMode): string => {
    switch (mode) {
        case PresenceModes.typing:
            return typingIcon;
        case PresenceModes.speaking:
            return speakingIcon;
        case PresenceModes.videoCalling:
            return speakingIcon; // Temporary
        case PresenceModes.screenSharing:
            return speakingIcon; // Temporary
        default:
            return "";
    }
};

export const getPresenceBubbleIcon = (mode?: PresenceMode): string => {
    switch (mode) {
        case PresenceModes.typing:
            return typingBubbleIcon;
        case PresenceModes.speaking:
            return speakingBubbleIcon;
        case PresenceModes.videoCalling:
            return "";
        case PresenceModes.screenSharing:
            return "";
        case PresenceModes.liveObserving:
            return "";
        default:
            return "";
    }
};

// The presence modes that are visible in a user's Avatar name pill.
// NB: Does NOT include ScreenSharing, because that is visible in the name pill for the *display tile*.
export const visibleUserPresenceModes: PresenceMode[] = ["speaking", "typing"];

export const isVisibleUserPresenceMode = (mode: PresenceMode) =>
    visibleUserPresenceModes.includes(mode);

export const livePresenceModes: PresenceMode[] = [
    PresenceModes.liveObserving,
    PresenceModes.screenSharing,
    PresenceModes.speaking,
    PresenceModes.videoCalling,
];
export const isLivePresence = (mode: PresenceMode) => livePresenceModes.includes(mode);
export const hasLivePresence = (modes?: PresenceMode[]) => modes?.some(isLivePresence) ?? false;

export const getDominantPresenceMode = (modes: PresenceMode[]): Optional<PresenceMode> => {
    if (modes.includes(PresenceModes.screenSharing)) {
        return PresenceModes.screenSharing;
    }
    if (modes.includes(PresenceModes.videoCalling)) {
        return PresenceModes.videoCalling;
    }
    if (modes.includes(PresenceModes.speaking)) {
        return PresenceModes.speaking;
    }
    if (modes.includes(PresenceModes.typing)) {
        return PresenceModes.typing;
    }
    if (modes.includes(PresenceModes.liveObserving)) {
        return PresenceModes.liveObserving;
    }
};

export function fromProtoPresenceMode(mode: domain_pb.PresenceMode): PresenceMode | undefined {
    switch (mode) {
        case domain_pb.PresenceMode.SPEAKING:
            return PresenceModes.speaking;
        case domain_pb.PresenceMode.TYPING:
            return PresenceModes.typing;
        case domain_pb.PresenceMode.VIDEOING:
            return PresenceModes.videoCalling;
        case domain_pb.PresenceMode.SCREEN_SHARING:
            return PresenceModes.screenSharing;
        case domain_pb.PresenceMode.LIVE_OBSERVING:
            return PresenceModes.liveObserving;
        case domain_pb.PresenceMode.UNSPECIFIED:
            return undefined;
        default:
            return undefined;
    }
}

export function livePresenceModesFromTracks(
    audioTrack?: Track,
    videoTrack?: Track,
    isDisplay?: boolean,
) {
    // Assumes that the person is live
    const modes: PresenceMode[] = [PresenceModes.liveObserving];

    // TODO: voice detection (see beyond#365)
    if (isTrackActive(audioTrack)) {
        modes.push(PresenceModes.speaking);
    }
    if (isTrackActive(videoTrack)) {
        modes.push(
            isDisplay ? PresenceModes.screenSharing : PresenceModes.videoCalling,
        );
    }

    return modes;
}
