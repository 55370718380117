import { useEffect } from "react";

import type { InterestUpdate } from "@/features/interest";
import { selectTabId } from "@/features/meta";
import { useShallowEquals } from "@/hooks/useShallowEquals";
import { useAppDispatch, useAppSelector } from "@/store/redux";
import type { ActionCreatorWithPayload } from "@reduxjs/toolkit";

interface UseRegisterInterestProps<T> {
    ids: T[];
    update: ActionCreatorWithPayload<InterestUpdate<T>>;
}

/** @function Dispatch Redux reducers to register/unregister interest in a resource,
 * based on the ids passed into the props.
 * Note we first shallow equal check the ids before registering interest in them.
 */
export default function useRegisterInterest<T>(props: UseRegisterInterestProps<T>) {
    const { ids, update: updateInterest } = props;

    const dispatch = useAppDispatch();

    const memoedIds = useShallowEquals(ids);

    const tabId = useAppSelector(selectTabId);

    useEffect(() => {
        if (memoedIds.length === 0) {
            return;
        }

        dispatch(updateInterest({ action: "add", ids: memoedIds, tabId }));

        return () => {
            dispatch(updateInterest({ action: "remove", ids: memoedIds, tabId }));
        };
    }, [dispatch, memoedIds, updateInterest, tabId]);
}

export type OneNoneOrMany<T> = T[] | T | undefined;

export function oneNoneOrManyToArray<T>(items: OneNoneOrMany<T>): T[] {
    if (items === undefined) {
        return [];
    }
    if (Array.isArray(items)) {
        return items;
    }
    return [items];
}
