import { useEffect, useRef } from "react";

import { useVolume } from "@/hooks/media/useVolume";

const VOLUME_SHIFT = 50;

interface IVolumeMeterProps {
    enabled?: boolean;
    stream?: MediaStream;
}

export default function VolumeMeter(props?: IVolumeMeterProps): React.JSX.Element {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const volume = useVolume({
        enabled: props?.enabled ?? false,
        stream: props?.stream,
    });

    useEffect(() => {
        if (!canvasRef.current) {
            return;
        }

        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        if (!context) {
            return;
        }
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.fillStyle = "rgba(256,256,256,0.12)";
        context.fillRect(0, 0, canvas.width, canvas.height);

        if (volume) {
            // Squish into a [0, 1] range and render bar of that size
            const volumeStrength = (volume + VOLUME_SHIFT) / VOLUME_SHIFT;
            context.fillStyle = "#FFFFFF";
            context.fillRect(0, 0, canvas.width * Math.min(volumeStrength, 1.0), canvas.height);
        }
    }, [volume]);

    return (
        <div className="c-settings-meter">
            <canvas ref={canvasRef} className="c-settings-meter__bar" />
            <svg
                className="c-settings-meter__mask"
                preserveAspectRatio="none"
            >
                <defs>
                    <clipPath id="meterClip">
                        <rect width="calc(12.5% - 1.75px)" height="6" x="0" rx="3" ry="3" />
                        <rect
                            width="calc(12.5% - 1.75px)"
                            height="6"
                            x="calc(12.5% + 0.25px)"
                            rx="3"
                            ry="3"
                        />
                        <rect
                            width="calc(12.5% - 1.75px)"
                            height="6"
                            x="calc(25% + 0.5px)"
                            rx="3"
                            ry="3"
                        />
                        <rect
                            width="calc(12.5% - 1.75px)"
                            height="6"
                            x="calc(37.5% + 0.75px)"
                            rx="3"
                            ry="3"
                        />
                        <rect
                            width="calc(12.5% - 1.75px)"
                            height="6"
                            x="calc(50% + 1px)"
                            rx="3"
                            ry="3"
                        />
                        <rect
                            width="calc(12.5% - 1.75px)"
                            height="6"
                            x="calc(62.5% + 1.25px)"
                            rx="3"
                            ry="3"
                        />
                        <rect
                            width="calc(12.5% - 1.75px)"
                            height="6"
                            x="calc(75% + 1.5px)"
                            rx="3"
                            ry="3"
                        />
                        <rect
                            width="calc(12.5% - 1.75px)"
                            height="6"
                            x="calc(87.5% + 1.75px)"
                            rx="3"
                            ry="3"
                        />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
}
