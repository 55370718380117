import classNames from "classnames";
import { Ref, useMemo } from "react";

import * as d from "@/domain/domain";
import {
    BondCreatedMessage,
    getCallId,
    getMessageCallStatus,
    getMsgTs,
    MessageCallContext,
    OfficialMessageType,
} from "@/domain/messages";
import { squadSortByName } from "@/domain/squads";
import { userSortByName } from "@/domain/users";
import { selectCurrentUserId } from "@/features/auth";
import { selectSquads } from "@/features/squads";
import { selectUser, selectUsers } from "@/features/users";
import { useInViewInterest } from "@/hooks/interest/useInViewInterest";
import useMergedRefs from "@/hooks/useMergedRefs";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { Optional } from "@/misc/types";
import Avatar from "../gui/Avatar";
import TimeAgo from "../gui/TimeAgo";
import { TitleChangeMessageView } from "./TitleChangeMessageView";

const addedUsersAdjs = { sort: userSortByName };
const addedSquadsAdjs = { sort: squadSortByName };

export interface BondCreatedMessageViewProps {
    msg: BondCreatedMessage;
    messageContentRef?: Ref<HTMLDivElement>;
    currentCallId?: d.CallId;
}

export const BondCreatedMessageView = (
    props: BondCreatedMessageViewProps,
): React.JSX.Element => {
    const { msg, messageContentRef, currentCallId } = props;
    const { addedUserIds, addedSquadIds, addedSquadUserIds, title, actorId } = msg;

    const ts = useMemo(() => getMsgTs(msg), [msg]);

    const callId = getCallId(msg);

    // The errorRef is needed, rather than the messageContentRef, because the messageContentRef
    // alone on the error return will lead to us not having interest registered for the actor.
    // This potentially leads to never recovering by by loading the actor's user overview from
    // the backend.
    const errorRef = useMergedRefs(messageContentRef, useInViewInterest({ userIds: [actorId] }));
    const actor = useSelectorArgs(selectUser, actorId);

    // We need to render up to three "messages" as far as the user is concerned:

    // 1. A title change message if the title was set.
    const titleChanged = !!title;

    // 2. A message for the users added.
    const usersAdded = addedUserIds.length > 0;

    // 3. A message for the squads added.
    const squadAddedUsersOnly = (addedSquadUserIds ?? []).filter(
        uid => !addedUserIds.includes(uid),
    );
    const squadUsersAdded = squadAddedUsersOnly.length > 0;

    if (!titleChanged && !usersAdded && !squadUsersAdded) {
        return <div ref={messageContentRef} />;
    }

    if (!actor) {
        return (
            <div className="c-message c-message--unknown" ref={errorRef}>
                {`Unknown user ${actorId}`}
            </div>
        );
    }

    return (
        <>
            {titleChanged && (
                <TitleChangeMessageView
                    msg={{
                        ...msg,
                        type: OfficialMessageType.TitleChange,
                        oldTitle: "",
                        newTitle: title,
                        editorId: actorId,
                    }}
                    currentCallId={currentCallId}
                    messageContentRef={squadUsersAdded || usersAdded ? undefined
                        : messageContentRef}
                />
            )}
            {usersAdded && (
                <MultiUserAddedView
                    addedUserIds={addedUserIds}
                    actorId={actorId}
                    ts={ts}
                    callId={callId}
                    currentCallId={currentCallId}
                    messageContentRef={squadUsersAdded ? undefined : messageContentRef}
                />
            )}
            {squadUsersAdded && (
                <MultiSquadsAddedView
                    addedSquads={addedSquadIds}
                    squadAddedUsers={squadAddedUsersOnly}
                    actorId={actorId}
                    ts={ts}
                    callId={callId}
                    currentCallId={currentCallId}
                    messageContentRef={messageContentRef}
                />
            )}
        </>
    );
};

interface MultiUserAddedViewProps {
    addedUserIds: d.UserId[];
    actorId: d.UserId;
    ts: Optional<Date>;
    callId: Optional<d.CallId>;
    currentCallId: Optional<d.CallId>;
    messageContentRef: Optional<Ref<HTMLDivElement>>;
}

const MultiUserAddedView = (
    props: MultiUserAddedViewProps,
): React.JSX.Element => {
    const { addedUserIds, actorId, callId, ts, messageContentRef, currentCallId } = props;

    const inViewRef = useInViewInterest({
        userIds: addedUserIds.concat(actorId),
        callIds: currentCallId,
    });

    const callStatus = getMessageCallStatus(callId, currentCallId);

    const currentUserId = useSelectorArgs(selectCurrentUserId);
    const actor = useSelectorArgs(selectUser, actorId);

    const userOverviews = useSelectorArgs(selectUsers, addedUserIds, addedUsersAdjs);

    const usersAddedTextContent = `${wasOrWere(addedUserIds.length)} added by ${
        actorId === currentUserId ? `you` : actor?.name
    }`;

    const numberOfUsernamesToDisplay = 3;
    const displayedAuthor = joinAndTruncateNames(
        userOverviews.map(u => u.name),
        numberOfUsernamesToDisplay,
    );

    const isEnded = callStatus === MessageCallContext.EndedCall;
    const isLive = callStatus === MessageCallContext.LiveCall;
    const containerClassNames = classNames("c-message", {
        "c-message--live": isLive,
        "c-message--ended": isEnded,
    });

    return (
        <div ref={inViewRef} className={containerClassNames}>
            <div className="c-human-spacer"></div>
            <div className="c-message__content">
                {(displayedAuthor || ts) && (
                    <div className="c-message__meta c-message__meta--event">
                        {displayedAuthor &&
                            (
                                <span className="c-message__author">
                                    {displayedAuthor}
                                </span>
                            )}
                        {ts && (
                            <span className="c-message__timestamp">
                                <TimeAgo from={ts?.valueOf() || 0} live={true} precise={true} />
                            </span>
                        )}
                    </div>
                )}
                {userOverviews && (
                    <div className="c-message__squad">
                        {userOverviews.map(uo => (
                            <Avatar
                                key={uo.id}
                                id={uo.id}
                                showPresence={false}
                                hidden={!uo.name}
                                size="squad"
                            />
                        ))}
                    </div>
                )}
                <div ref={messageContentRef} className="c-message__action">
                    {usersAddedTextContent}
                </div>
            </div>
        </div>
    );
};

interface MultiSquadsAddedViewProps {
    addedSquads: d.SquadId[];
    squadAddedUsers: d.UserId[];
    actorId: d.UserId;
    ts: Optional<Date>;
    callId: Optional<d.CallId>;
    currentCallId: Optional<d.CallId>;
    messageContentRef: Optional<Ref<HTMLDivElement>>;
}

const MultiSquadsAddedView = (
    props: MultiSquadsAddedViewProps,
): React.JSX.Element => {
    const { addedSquads, squadAddedUsers, actorId, callId, ts, messageContentRef, currentCallId } =
        props;

    const inViewRef = useInViewInterest({
        squadIds: addedSquads,
        userIds: squadAddedUsers.concat(actorId),
        callIds: currentCallId,
    });

    const callStatus = getMessageCallStatus(callId, currentCallId);

    const currentUserId = useSelectorArgs(selectCurrentUserId);
    const actor = useSelectorArgs(selectUser, actorId);
    const userOverviews = useSelectorArgs(selectUsers, squadAddedUsers, addedUsersAdjs);
    const squadOverviews = useSelectorArgs(selectSquads, addedSquads, addedSquadsAdjs);
    const squadsAddedTextContent = `${wasOrWere(squadAddedUsers.length)} invited by ${
        actorId === currentUserId ? `you` : actor?.name
    }`;

    const numberOfSquadNamesToDisplay = 3;
    const displayedAuthor = joinAndTruncateNames(
        squadOverviews.map(s => s.name),
        numberOfSquadNamesToDisplay,
    );

    const isEnded = callStatus === MessageCallContext.EndedCall;
    const isLive = callStatus === MessageCallContext.LiveCall;
    const containerClassNames = classNames("c-message", {
        "c-message--live": isLive,
        "c-message--ended": isEnded,
    });

    return (
        <div ref={inViewRef} className={containerClassNames}>
            <div className="c-human-spacer"></div>
            <div className="c-message__content">
                {(displayedAuthor || ts) && (
                    <div className="c-message__meta c-message__meta--event">
                        {displayedAuthor &&
                            (
                                <span className="c-message__author">
                                    {displayedAuthor}
                                </span>
                            )}
                        {ts && (
                            <span className="c-message__timestamp">
                                <TimeAgo from={ts?.valueOf() || 0} live={true} precise={true} />
                            </span>
                        )}
                    </div>
                )}
                {userOverviews && (
                    <div className="c-message__squad">
                        {userOverviews.map(uo => (
                            <Avatar
                                key={uo.id}
                                id={uo.id}
                                showPresence={false}
                                hidden={!uo.name}
                                size="squad"
                            />
                        ))}
                    </div>
                )}
                <div ref={messageContentRef} className="c-message__action">
                    {squadsAddedTextContent}
                </div>
            </div>
        </div>
    );
};

const wasOrWere = (n: number): string => (n > 1 ? "were" : "was");

const joinAndTruncateNames = (names: string[], truncationThreshold: number): string => {
    const n = names.length;

    if (n <= truncationThreshold) {
        const formatter = new Intl.ListFormat("en", {
            style: "long",
            type: "conjunction",
        });
        return formatter.format(names);
    }

    return `${names.slice(0, truncationThreshold).join(", ")} + ${n - truncationThreshold}`;
};
