import { AsyncThunk, createSlice, current, PayloadAction, TaskAbortError } from "@reduxjs/toolkit";
import { castDraft, original } from "immer";

import * as d from "@/domain/domain";
import asyncIterableQueue from "@/ds/asyncIterableQueue";
import {
    Counter,
    CounterState,
    createCounter,
    createHAMTCounter,
    HAMTCounter,
    HAMTCounterState,
} from "@/ds/counter";
import { hamtGet, hamtKeys } from "@/ds/hamt";
import { selectConnectedWithUserId } from "@/features/connection";
import type { AnySelector } from "@/features/selectors";
import log from "@/misc/log";
import { diff, mapRecord } from "@/misc/primatives";
import type { Diff } from "@/misc/types";
import { checkPersistor } from "@/persist/shared";
import {
    type AppAppendListener,
    appAppendListener,
    firstOrStatesDifferBySelectors,
    statesDifferBySelectors,
} from "@/store/middleware";
import { cleanupTab } from "@/store/redux";
import type { AppAsyncThunkConfig, RootState } from "@/store/types";

type StreamDiffThunk<T> = AsyncThunk<
    void,
    AsyncIterableIterator<Diff<T>>,
    AppAsyncThunkConfig
>;

// TODO: this is very similar to `startConnectedStatefulListener`, except
// it needs `api` to create the thunk to be dispatched as part of the stateful
// setup. Think about how these two uses could be combined into a single
// utility function.
export const startManagingInterest = <T extends string>(
    idSelector:
        | AnySelector<[], HAMTCounterState<T>>
        | AnySelector<[], Readonly<T[]>>,
    streamThunk: StreamDiffThunk<T>,
    appendListener: AppAppendListener = appAppendListener,
) => {
    const innerPredicate = statesDifferBySelectors(idSelector);

    return appendListener({
        predicate: firstOrStatesDifferBySelectors(selectConnectedWithUserId),

        effect: async (_action, api) => {
            const firstState = api.getState();

            if (!selectConnectedWithUserId(firstState)) {
                api.cancelActiveListeners();
                return;
            }

            const queue = asyncIterableQueue<Diff<T>>();
            const thunk = api.dispatch(streamThunk(queue));

            let lastState: RootState | undefined;

            const initialCounterState = idSelector(firstState);

            let interest: T[] = [];
            if (Array.isArray(initialCounterState)) {
                const initial: T[] = initialCounterState;
                queue.push({ added: initial });
                interest = initial;
            }
            else {
                const initial = initialCounterState as HAMTCounterState<T>;
                queue.push({ added: Array.from(hamtKeys<T>(initial.hamt)) });
            }

            try {
                while (!api.signal.aborted) {
                    const state = api.getState();
                    if (state === lastState) {
                        await api.take(innerPredicate);
                        continue;
                    }

                    // The rest of this loop **must** be synchronous, else we
                    // can lose updates.

                    lastState = state;

                    const counterState = idSelector(state);

                    const isArray = Array.isArray(counterState);
                    const interestDiff = isArray ? diff(interest, counterState)
                        : (counterState as HAMTCounterState<T>).latest;

                    if (isArray) interest = counterState;

                    if (
                        (interestDiff.added?.length ?? 0) > 0 ||
                        (interestDiff.removed?.length ?? 0) > 0
                    ) {
                        queue.push(interestDiff);
                    }
                }
            }
            catch (e) {
                if (!(e instanceof TaskAbortError)) {
                    log.warn(`Unexpected middleware exception ${streamThunk.typePrefix}`, e);
                }
            }

            thunk.abort("disconnected");
            // This gives an easy way to test that the listener has completed.
            queue.clear();
        },
    });
};

export enum MetaInterestCounterKey {
    ActiveForNotifications = "activeForNotifications",
    AnyTabActive = "anyTabActive",
    BlockHotkey = "blockHotkey",
    RaiseBondView = "raiseBondView",
    BlockMsgCompletion = "blockMsgCompletion",
    Typing = "typingActivity",
    FollowedUnreadCount = "followedUnreadCount",
}

const counters = {
    bonds: createCounter<d.BondId>(),
    calls: createCounter<d.CallId>(),
    meta: createHAMTCounter<MetaInterestCounterKey>(),
    orgs: createCounter<d.OrgId>(),
    squads: createCounter<d.SquadId>(),
    users: createHAMTCounter<d.UserId>(),
    persons: createHAMTCounter<d.PersonId>(),
    visibleBonds: createHAMTCounter<d.BondId>(),
    invitedBonds: createHAMTCounter<d.BondId>(),
    visibleChannels: createHAMTCounter<d.ChannelId>(),
};
type Counters = typeof counters;

type CounterToStateType<T> = T extends HAMTCounter<infer S> ? HAMTCounterState<S>
    : T extends Counter<infer S> ? CounterState<S>
    : never;
export type InterestState = { [K in keyof Counters]: CounterToStateType<Counters[K]>; };

const getInitialState = (): InterestState =>
    mapRecord(counters, (_, v) => v.getInitialState()) as InterestState;

export type InterestUpdate<T> = { action: "add" | "remove"; ids: T[]; tabId: string; };
type UpdateArgs<T> = PayloadAction<InterestUpdate<T>>;

export const interestSlice = createSlice({
    name: "interest",
    initialState: getInitialState(),
    selectors: {
        bonds: state => state.bonds,
        calls: state => state.calls,
        meta: state => state.meta,
        metaKey: (state, interestKey: MetaInterestCounterKey) =>
            !!hamtGet(state.meta.hamt, interestKey),
        orgs: state => state.orgs,
        squads: state => state.squads,
        users: state => state.users,
        persons: state => state.persons,
        visibleBonds: state => state.visibleBonds,
        invitedBonds: state => state.invitedBonds,
        visibleChannels: state => state.visibleChannels,
    },

    reducers: {
        updateBondInterest: (
            state,
            { payload: { action, ids, tabId } }: UpdateArgs<d.BondId>,
        ) => {
            const f = action === "add" ? counters.bonds.add
                : counters.bonds.remove;
            state.bonds = castDraft(f(state.bonds, tabId, ids));
        },
        updateCallInterest: (
            state,
            { payload: { action, ids, tabId } }: UpdateArgs<d.CallId>,
        ) => {
            const f = action === "add" ? counters.calls.add : counters.calls.remove;
            state.calls = castDraft(f(state.calls, tabId, ids));
        },
        updateMetaInterest: (
            state,
            { payload: { action, ids, tabId } }: UpdateArgs<MetaInterestCounterKey>,
        ) => {
            const f = action === "add" ? counters.meta.add : counters.meta.remove;
            state.meta = f(current(state).meta, tabId, ids);
        },
        updateOrgInterest: (
            state,
            { payload: { action, ids, tabId } }: UpdateArgs<d.OrgId>,
        ) => {
            const f = action === "add" ? counters.orgs.add : counters.orgs.remove;
            state.orgs = castDraft(f(state.orgs, tabId, ids));
        },
        updateSquadInterest: (
            state,
            { payload: { action, ids, tabId } }: UpdateArgs<d.SquadId>,
        ) => {
            const f = action === "add" ? counters.squads.add : counters.squads.remove;
            state.squads = castDraft(f(state.squads, tabId, ids));
        },
        updateUserInterest: (
            state,
            { payload: { action, ids, tabId } }: UpdateArgs<d.UserId>,
        ) => {
            const f = action === "add" ? counters.users.add : counters.users.remove;
            state.users = f(current(state).users, tabId, ids);
        },
        updatePersonInterest: (
            state,
            { payload: { action, ids, tabId } }: UpdateArgs<d.PersonId>,
        ) => {
            const f = action === "add" ? counters.persons.add : counters.persons.remove;
            state.persons = f(current(state).persons, tabId, ids);
        },
        updateVisibleBondInterest: (
            state,
            { payload: { action, ids, tabId } }: UpdateArgs<d.BondId>,
        ) => {
            const f = action === "add" ? counters.visibleBonds.add
                : counters.visibleBonds.remove;
            state.visibleBonds = f(current(state).visibleBonds, tabId, ids);
        },
        updateInvitedBondInterest: (
            state,
            { payload: { action, ids, tabId } }: UpdateArgs<d.BondId>,
        ) => {
            const f = action === "add" ? counters.invitedBonds.add
                : counters.invitedBonds.remove;
            state.invitedBonds = f(current(state).invitedBonds, tabId, ids);
        },
        updateVisibleChannelInterest: (
            state,
            { payload: { action, ids, tabId } }: UpdateArgs<d.ChannelId>,
        ) => {
            const f = action === "add" ? counters.visibleChannels.add
                : counters.visibleChannels.remove;
            state.visibleChannels = f(current(state).visibleChannels, tabId, ids);
        },
    },

    extraReducers: builder => {
        builder.addCase(cleanupTab, (state, { payload: tabId }) => {
            const os = original(state)!;

            state.bonds = castDraft(counters.bonds.removeTabId(os.bonds, tabId));
            state.calls = castDraft(counters.calls.removeTabId(os.calls, tabId));
            state.meta = castDraft(counters.meta.removeTabId(os.meta, tabId));
            state.orgs = castDraft(counters.orgs.removeTabId(os.orgs, tabId));
            state.squads = castDraft(counters.squads.removeTabId(os.squads, tabId));
            state.users = castDraft(counters.users.removeTabId(os.users, tabId));
            state.persons = castDraft(counters.persons.removeTabId(os.persons, tabId));
            state.visibleBonds = castDraft(
                counters.visibleBonds.removeTabId(os.visibleBonds, tabId),
            );
            state.invitedBonds = castDraft(
                counters.invitedBonds.removeTabId(os.invitedBonds, tabId),
            );
            state.visibleChannels = castDraft(
                counters.visibleChannels.removeTabId(os.visibleChannels, tabId),
            );
        });
    },
});

export const {
    bonds: selectBondInterest,
    calls: selectCallsInterest,
    meta: selectMetaInterest,
    metaKey: selectInterestInKey,
    orgs: selectOrgsInterest,
    squads: selectSquadsInterest,
    users: selectUsersInterest,
    persons: selectPersonsInterest,
    visibleBonds: selectVisibleBondInterest,
    invitedBonds: selectInvitedBondInterest,
    visibleChannels: selectVisibleChannelInterest,
} = interestSlice.getSelectors<RootState>(state => state.interest);

export const {
    updateBondInterest,
    updateCallInterest,
    updateMetaInterest,
    updateOrgInterest,
    updateSquadInterest,
    updateUserInterest,
    updatePersonInterest,
    updateVisibleBondInterest,
    updateInvitedBondInterest,
    updateVisibleChannelInterest,
} = interestSlice.actions;

export const selectTypingInterest = (state: RootState) =>
    selectInterestInKey(state, MetaInterestCounterKey.Typing);

export const selectActiveForNotifications = (state: RootState) =>
    selectInterestInKey(state, MetaInterestCounterKey.ActiveForNotifications);

export const selectAnyTabActive = (state: RootState) =>
    selectInterestInKey(state, MetaInterestCounterKey.AnyTabActive);

export const selectDisplayingFollowedUnreadCount = (state: RootState) =>
    selectInterestInKey(state, MetaInterestCounterKey.FollowedUnreadCount);

export const reducer = interestSlice.reducer;

export const persistor = {
    stores: {},
};
checkPersistor<InterestState>(persistor);
