import * as d from "@/domain/domain";
import {
    AnyOfficialMessage,
    AnyUnsentLocalMessage,
    isCallEndedMessage,
    isOfficialChatMessage,
    UnsentChatMessage,
} from "@/domain/messages";
import log from "@/misc/log";
import { Optional } from "@/misc/types";

export type PerChannelData = Readonly<{
    channelId: d.ChannelId;
    /** List of messages ids (official or unsent), ordered by "correct latest
     * message in correct order for displaying".
     *
     * Always use UnsentMessageIds until we get the full message from the server.
     * "Correct latest message" means we will keep the most-recent-message for any
     * given message, i.e. this list takes into account edits and deletions.
     */
    curatedList: Readonly<d.AnyMessageId[]>;
    /** For efficient checking of "has this user sent a message into this bond/
     * mentioned me", we need to create an index here. It is cleared as the
     * published sequence number increases.
     */
    unreadMessagesIndex: Readonly<Record<number, AnyOfficialMessage>>;
    /** The sequence number of the most recent message we have received into
     * the store.
     */
    localSequenceNumber?: number;
    /** Index of messages ids into `curatedList` for efficient updates.
     */
    index: Readonly<Record<d.AnyMessageId, number>>;
    /**  List of messages waiting to be sent.
     *
     * The logic that sends the messages should only over look at unsent[0]
     * until we are sure that the server has received that message.
     * Should only ever be small, so ignore the O(n) search complexity.
     * (We could always add an index for lookups if we needed to.)
     */
    unsent: Readonly<d.UnsentMessageId[]>;
    /** Map of messages ids confirmed sent, but that we haven't received via
     * a subscription or a fetch.
     *
     * This is an efficient way to correctly switch over the indexing key from
     * our `localId` to the official UUID.
     */
    pending: Readonly<Record<d.MessageId, d.UnsentMessageId>>;
}>;

export function getDefaultData(channelId: d.ChannelId): PerChannelData {
    return Object.freeze({
        channelId,
        curatedList: Object.freeze([]),
        unreadMessagesIndex: Object.freeze({}),
        index: Object.freeze({}),
        unsent: Object.freeze([]),
        pending: Object.freeze({}),
    });
}

/** Record an intent to send a message.
 *
 * TODO: apply edit if we lose the race and the message is already sent?
 * This case is actually quite complex to handle, I think.
 */
export function addUnsentToChannel(
    pcd: Optional<PerChannelData>,
    channelId: d.ChannelId,
    msg: AnyUnsentLocalMessage,
): PerChannelData {
    pcd ??= getDefaultData(channelId);

    const { curatedList, index, unsent } = pcd;

    if (unsent.includes(msg.localId)) {
        log.info(`Tried to send message ${msg.localId} twice`);
        return pcd;
    }

    // This is the "send a new message" case.
    // TODO: avoid the three spreads here, do some structural sharing
    return Object.freeze({
        ...pcd,
        index: Object.freeze({ ...index, [msg.localId]: curatedList.length }),
        unsent: Object.freeze([...unsent, msg.localId]),
        curatedList: Object.freeze([...curatedList, msg.localId]),
    });
}

/** Remove an intent to send a message.
 *
 * Only has an effect if we haven't managed to act on the intent and push the
 * message to the server yet.
 */
export function removeUnsentFromChannel(
    pcd: Optional<PerChannelData>,
    channelId: d.ChannelId,
    msg: UnsentChatMessage,
): PerChannelData {
    if (!pcd) return getDefaultData(channelId);

    const i = pcd.unsent.indexOf(msg.localId);
    if (i === -1) return pcd;

    const unsent = Array.from(pcd.unsent);
    const index = { ...pcd.index };
    const curatedList = Array.from(pcd.curatedList);

    for (let j = i + 1; j < unsent.length; j++) {
        const msg = unsent[j];
        index[msg]--;
    }

    unsent.splice(i, 1);

    // Only delete from `curated` if we haven't managed to send to the server.
    // TODO check case of disconnect before receiving confirmation.
    const t = index[msg.localId];
    curatedList.splice(t, 1);
    delete index[msg.localId];

    // TODO: check pending if we've managed to send and want to delete server-side

    return Object.freeze({
        ...pcd,
        index: Object.freeze(index),
        unsent: Object.freeze(unsent),
        curatedList: Object.freeze(curatedList),
    });
}

/** Marks a message as confirmed sent to the server.
 *
 * This moves it out of `unsent` and into `pending`.
 * Requires msg.messageId to be set.
 */
export function messageSent(
    pcd: Optional<PerChannelData>,
    channelId: d.ChannelId,
    msg: UnsentChatMessage,
): PerChannelData {
    if (!msg.messageId) {
        log.error(`Message ${msg.localId} "sent" but with no confirmed id`);
        return pcd ?? getDefaultData(channelId);
    }

    if (!pcd) {
        log.error(`No per-channel data for message send`, { channelId });
        return {
            ...getDefaultData(channelId),
            pending: Object.freeze({ [msg.messageId]: msg.localId }),
        };
    }

    const { unsent, pending } = pcd;

    const newPcd = {
        ...pcd,
        pending: Object.freeze({ ...pending, [msg.messageId]: msg.localId }),
    };

    if (unsent.length > 0 && unsent[0] === msg.localId) {
        newPcd.unsent = Object.freeze(unsent.slice(1, unsent.length));
    }
    else {
        // TODO: assert
        // We should always be respecting the order of the unsent list here.
        let i = 1;
        for (; i < unsent.length; i++) {
            if (unsent[i] === msg.localId) {
                break;
            }
        }
        if (i === unsent.length) {
            log.error(`Sent ${msg.localId}, but not not found in unsent list ${unsent}`);
        }
        else {
            log.error(
                `Sent ${msg.localId}, but not first in unsent list (position ${i}) ${unsent}`,
            );
            const newUnsent = Array.from(unsent);
            newUnsent.splice(i, 1);
            newPcd.unsent = Object.freeze(newUnsent);
        }
    }

    return Object.freeze(newPcd);
}

// TODO update edited/deleted versions
// TODO non-contiguous message ranges
const cmpSeqNo = (a: AnyOfficialMessage, b: AnyOfficialMessage) =>
    a.sequenceNumber - b.sequenceNumber;

/** Add a message to the data for a message received from the backend.
 */
export function addToChannel(
    pcd: Optional<PerChannelData>,
    channelId: d.ChannelId,
    msgs: AnyOfficialMessage[],
): PerChannelData {
    pcd ??= getDefaultData(channelId);
    const { localSequenceNumber } = pcd;

    const unreadMessagesIndex = { ...pcd.unreadMessagesIndex };
    const index = { ...pcd.index };
    const curatedList = Array.from(pcd.curatedList);
    const unsent = Array.from(pcd.unsent);
    const pending = { ...pcd.pending };

    const sorted = msgs.sort(cmpSeqNo);

    for (const msg of sorted) {
        // Can't check index[msg.id] as it might be zero, which is false-y
        if (Object.prototype.hasOwnProperty.call(index, msg.id)) {
            continue;
        }

        const isChatMsg = isOfficialChatMessage(msg);
        const isCallEndMsg = isCallEndedMessage(msg);
        if (isChatMsg || isCallEndMsg) {
            // Don't worry about if the seqNo is smaller than our staged seqNo.
            // The selector will handle that, and it will be removed on the next
            // update to published seqNos.
            unreadMessagesIndex[msg.sequenceNumber] = msg;
        }

        const pendingId = pending[msg.id];
        let curatedIndex: number;

        // Messages should not ever be in both `unsent` and `pending`.
        // Hence the `else if` is correct.
        if (isChatMsg && unsent.length > 0 && msg.content.id === unsent[0]) {
            // Message sent, but did not receive confirmation before disconnecting.
            curatedIndex = index[msg.content.id];
            delete index[msg.content.id];

            unsent.splice(0, 1);
        }
        else if (pendingId) {
            // Normal flow: got confirmation of receipt, message then appears
            // through a subscription.
            curatedIndex = index[pendingId];
            delete index[pendingId];
            delete pending[msg.id];
        }
        else {
            curatedIndex = curatedList.length;
        }

        curatedList[curatedIndex] = msg.id;
        index[msg.id] = curatedIndex;
    }

    let newLocalSequenceNumber = localSequenceNumber;
    if (sorted.length > 0) {
        const seq = sorted[sorted.length - 1].sequenceNumber;
        newLocalSequenceNumber = Math.max(seq, localSequenceNumber ?? -1);
    }

    return Object.freeze({
        channelId,
        localSequenceNumber: newLocalSequenceNumber,
        unreadMessagesIndex: Object.freeze(unreadMessagesIndex),
        index: Object.freeze(index),
        pending: Object.freeze(pending),
        curatedList: Object.freeze(curatedList),
        unsent: Object.freeze(unsent),
    });
}

export function publishedSeqNoUpdated(
    pcd: Optional<PerChannelData>,
    channelId: d.ChannelId,
    newSeqNo: number,
): PerChannelData {
    if (!pcd) return getDefaultData(channelId);

    return Object.freeze({
        ...pcd,
        unreadMessagesIndex: Object.freeze(Object.fromEntries(
            Object.entries(pcd.unreadMessagesIndex)
                .map(([seqNo, msg]) => [parseInt(seqNo, 10), msg] as [number, AnyOfficialMessage])
                .filter(([seqNo, _]) => newSeqNo < seqNo),
        )),
    });
}
