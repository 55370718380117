import classNames from "classnames";

interface ButtonMainProps {
    hasFocus?: boolean;
    isCentred?: boolean;
    isDisabled?: boolean;
    isSecondary?: boolean;
    isWarning?: boolean;
    isWarningText?: boolean;
    icon?: string;
    label?: string;
    onClick?: () => void;
}

export const ButtonMain = ({
    isCentred,
    isDisabled,
    isSecondary,
    isWarning,
    isWarningText,
    hasFocus,
    icon = "arrow-right",
    label = "Continue",
    onClick,
}: ButtonMainProps) => {
    const classes = classNames("c-btn-main", {
        "c-btn-main--centred": isCentred,
        "c-btn-main--secondary": isSecondary,
        "c-btn-main--active": hasFocus,
        "c-btn-main--warning": isWarning,
        "c-btn-main--warning-text": isWarningText,
    });

    return (
        <button
            type="submit"
            aria-label={label}
            disabled={isDisabled}
            className={classes}
            onClick={onClick}
        >
            {label}
            {!isCentred && <span className={`c-btn-main__icon c-btn-main__icon--${icon}`}></span>}
        </button>
    );
};
