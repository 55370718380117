import React, { FC, Fragment, memo } from "react";

import ChatMessageTextView from "@/components/messages/ChatMessageTextView";
import MentionView from "@/components/messages/MentionView";
import {
    CompactMarkupDelta,
    isMentionOp,
    isTextInsertOp,
    OpAttributes,
    splitLines,
} from "@/domain/richtext/quill/delta";

const getLineTagName = (lineAttributes: OpAttributes, inline: boolean): string => {
    if (inline) return "span";
    const header = lineAttributes?.header;
    if (
        typeof header == "number" &&
        1 <= header && header <= 3
    ) {
        return `h${header}`;
    }

    if (lineAttributes?.blockquote) {
        return "blockquote";
    }

    return "p";
};

const getFieldTagNames = (attributes: OpAttributes): string[] => {
    const tagNames = [];

    if (attributes?.bold) tagNames.push("strong");
    if (attributes?.italic) tagNames.push("em");

    return tagNames;
};

type QuillMarkupView = {
    markup: CompactMarkupDelta;
    inline: boolean;
};

const QuillMarkupViewInternal: FC<QuillMarkupView> = ({ markup, inline }) =>
    splitLines(markup)
        .map(line =>
            React.createElement(
                getLineTagName(line.lineAttributes ?? {}, inline),
                {},
                line.ops.map(op => {
                    if (isTextInsertOp(op)) {
                        return getFieldTagNames(op?.attributes ?? {}).reduce(
                            (node, tagName) => React.createElement(tagName, {}, node),
                            <ChatMessageTextView text={op.insert} />,
                        );
                    }
                    else if (isMentionOp(op)) {
                        return (
                            <MentionView
                                mention={op.insert.mention}
                                text={op.insert.mention.text}
                            />
                        );
                    }
                }).map((node, i) => <Fragment key={i}>{node}</Fragment>),
            )
        ).map((node, i) => <Fragment key={i}>{node}</Fragment>);

export const QuillMarkupView = memo(QuillMarkupViewInternal);

export default QuillMarkupView;
