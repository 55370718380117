/**
 * Recursively freezes an object and all of its nested properties, making them immutable.
 * It handles circular references by using a WeakSet to track previously visited objects.
 *
 * @template T - The type of the object to be frozen.
 * @param obj - The object to freeze. Must be a non-null object to be frozen.
 * @param visited - A WeakSet used to track objects that have already been processed.
 *  Defaults to a new, empty WeakSet to signify that nothing has been visited yet.
 * @returns The frozen object, where all properties and sub-properties are immutable.
 *
 * @example
 * const obj = { a: 1, b: { c: 2 } };
 * const frozenObj = deepFreeze(obj);
 * frozenObj.b.c = 3; // Throws an error in strict mode
 *
 * @example
 * const circularObj: any = {};
 * circularObj.self = circularObj;
 * const frozenCircularObj = deepFreeze(circularObj);
 * console.log(frozenCircularObj.self === frozenCircularObj); // true
 */
export function deepFreeze<T>(obj: T, visited?: WeakSet<object>): T {
    if (typeof obj !== "object" || obj === null) {
        return obj;
    }

    let willDeepFreeze = false;
    const propNames = Object.getOwnPropertyNames(obj);
    for (const name of propNames) {
        const value = (obj as Record<string, unknown>)[name];
        if (typeof value === "object" && value !== null) {
            willDeepFreeze = true;
            break;
        }
    }
    if (!willDeepFreeze) {
        return Object.freeze(obj);
    }

    if (visited === undefined) {
        visited = new WeakSet<object>();
    }
    if (visited.has(obj) || isGlobalObject(obj)) {
        return obj;
    }
    visited.add(obj);

    for (const name of propNames) {
        const value = (obj as Record<string, unknown>)[name];
        if (typeof value === "object" && value !== null) {
            deepFreeze(value, visited);
        }
    }

    return Object.freeze(obj);
}

/**
 * Determines if an object is a global object that should not be frozen.
 * Examples include `window`, `document`, or `global`.
 *
 * @param obj - The object to check.
 * @returns `true` if the object is a global object, `false` otherwise.
 */
function isGlobalObject(obj: unknown): boolean {
    return (
        obj === globalThis || // Covers `window`, `global`, and `self` in various environments
        (typeof document !== "undefined" && obj === document) || // Browser `document`
        (typeof navigator !== "undefined" && obj === navigator) // Browser `navigator`
    );
}
