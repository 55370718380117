import { Optional } from "@/misc/types";
import { useCallback, useRef } from "react";

/**
 * Return a callback which indirectly calls the given function using a ref. The result
 * is stable and can be passed to a dependency list to use the given function in an
 * effect without re-triggering when the function changes.
 *
 * IMPORTANT: this breaks the reactive model and is mostly useful for integrating with
 *            3rd party libraries (e.g. Tiptap)
 */
export const useCallbackRef = <A extends any[], R>(
    f: Optional<(...args: A) => R>,
): (...args: A) => Optional<R> => {
    const ref = useRef<Optional<(...args: A) => R>>(undefined);
    ref.current = f;
    return useCallback<(...args: A) => Optional<R>>(
        (...args: A) => ref.current?.(...args),
        [],
    );
};

export default useCallbackRef;
