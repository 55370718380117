import { Attribute } from "@tiptap/react";
import { andThen } from "../utils";

export const simpleAttribute = (
    name: string,
    defaultValue?: any,
): Attribute => ({
    default: defaultValue,
    parseHTML: element => element.getAttribute(`data-${name}`),
    renderHTML: attributes => andThen(attributes[name], v => ({ [`data-${name}`]: v })) ?? null,
});
