import { getEnvironmentConfig } from "@/misc/environment";
import { z } from "zod";

// NB: these are all functions so they can be updated with the
// Tailscale host from the input in the login page.
const apiBaseUrl = () => `${getEnvironmentConfig().oidcAuthority}/api`;
const pathFn = (apiPath: string) => (suffix: string) => `${apiBaseUrl()}/${apiPath}/${suffix}`;
const authPath = pathFn("auth");
const invitePath = pathFn("invites");
const passcodesPath = pathFn("auth/passcodes");
const userPath = pathFn("users");

const uris = {
    associateUserWithAuthRequest: (authReqId: string) => authPath(`requests/${authReqId}`),
    completeAuthRequest: (authReqId: string) => authPath(`requests/${authReqId}/done`),
    existsPasscode: () => passcodesPath("exists"),
    generatePasscode: () => passcodesPath("generate"),
    getInviteByCode: () => invitePath("code"),
    resendPasscode: () => passcodesPath("resend"),
    updateUserDetails: (userId: string) => userPath(userId),
    verifyPasscode: () => passcodesPath("verify"),
};

type ApiCredentials = {
    authRequestId: string;
    codeVerifier: string;
};

const getCreds = (authRequestId: string, codeVerifier: string): ApiCredentials => ({
    authRequestId,
    codeVerifier,
});

const basicAuthHeaders = (creds: ApiCredentials) => ({
    "Content-Type": "application/json",
    "Authorization": `Basic ${btoa(`${creds.authRequestId}:${creds.codeVerifier}`)}`,
});

type SuccessResponse<T = undefined> = {
    isSuccess: true;
    response: T;
};
type FailureResponse = {
    isSuccess: false;
    reason: string;
};
type ApiResponse<T = undefined> = SuccessResponse<T> | FailureResponse;

const succeeded = (): SuccessResponse => {
    return { isSuccess: true, response: undefined };
};
const succeededWithData = <T>(response: T): SuccessResponse<T> => {
    return { isSuccess: true, response };
};

const failed = (reason?: string): FailureResponse => ({
    isSuccess: false,
    reason: reason ?? "unknown",
});

async function doRequest(
    url: string,
    creds: ApiCredentials,
    method: string,
    body?: object,
): Promise<Response> {
    return fetch(encodeURI(url), {
        method,
        headers: basicAuthHeaders(creds),
        body: body ? JSON.stringify(body) : undefined,
    });
}

async function doPOST(url: string, creds: ApiCredentials, body?: object): Promise<Response> {
    return doRequest(url, creds, "POST", body);
}

async function doPATCH(url: string, creds: ApiCredentials, body?: object): Promise<Response> {
    return doRequest(url, creds, "PATCH", body);
}

type ExistsPasscodeResp = ApiResponse<{
    exists: boolean;
}>;

export async function existsPasscode(
    userId: string,
    creds: ApiCredentials,
): Promise<ExistsPasscodeResp> {
    try {
        const response = await doPOST(uris.existsPasscode(), creds, { user_id: userId });

        if (!response.ok && response.status !== 404) {
            return failed(response.statusText);
        }

        return succeededWithData({ exists: response.status === 200 });
    }
    catch (err: any) {
        return failed(err?.message);
    }
}

export async function generatePasscode(
    userId: string,
    creds: ApiCredentials,
): Promise<ApiResponse> {
    try {
        const response = await doPOST(uris.generatePasscode(), creds, { user_id: userId });

        if (!response.ok) {
            return failed(response.statusText);
        }

        return succeeded();
    }
    catch (err: any) {
        return failed(err?.message);
    }
}

export async function resendPasscode(
    userId: string,
    creds: ApiCredentials,
): Promise<ApiResponse> {
    try {
        const response = await doPOST(uris.resendPasscode(), creds, { user_id: userId });

        if (!response.ok) {
            return failed(response.statusText);
        }

        return succeeded();
    }
    catch (err: any) {
        return failed(err?.message);
    }
}

export async function verifyPasscode(
    userId: string,
    passcode: string,
    creds: ApiCredentials,
): Promise<ApiResponse> {
    try {
        const response = await doPOST(uris.verifyPasscode(), creds, {
            passcode,
            user_id: userId,
        });

        if (!response.ok) {
            return failed(response.statusText);
        }

        return succeeded();
    }
    catch (err: any) {
        return failed(err?.message);
    }
}

type AssociateUserWithAuthReqResp = ApiResponse<{
    isNewUser: boolean;
    userId: string;
}>;

const AssociateUserWithAuthRequestApiResponse = z.object({
    user_id: z.string(),
});

export async function associateUserWithAuthRequest(
    authReqId: string,
    email: string,
    codeVerifier: string,
): Promise<AssociateUserWithAuthReqResp> {
    try {
        const response = await doPOST(
            uris.associateUserWithAuthRequest(authReqId),
            getCreds(authReqId, codeVerifier),
            { email },
        );

        const isNewUser = response.status === 201;
        if (!response.ok && !isNewUser) {
            return failed(`${response.status} ${response.statusText}`);
        }

        const { user_id: userId } = AssociateUserWithAuthRequestApiResponse.parse(
            await response.json(),
        );

        return succeededWithData({ isNewUser, userId });
    }
    catch (err: any) {
        return failed(err?.message);
    }
}

type CompleteAuthReqResp = ApiResponse<{
    callbackUri: string;
}>;

const CompleteAuthRequestApiResponse = z.object({
    callback_uri: z.string(),
});

export async function completeAuthRequest(
    authReqId: string,
    codeVerifier: string,
): Promise<CompleteAuthReqResp> {
    try {
        const response = await doPOST(
            uris.completeAuthRequest(authReqId),
            getCreds(authReqId, codeVerifier),
        );

        if (!response.ok) {
            return failed(response.statusText);
        }

        const { callback_uri: callbackUri } = CompleteAuthRequestApiResponse.parse(
            await response.json(),
        );

        return succeededWithData({ callbackUri });
    }
    catch (err: any) {
        return failed(err?.message);
    }
}

type GetInviteByCodeResp = ApiResponse<{
    email: string;
}>;

const GetInviteByCodeApiResponse = z.object({
    email: z.string(),
});

export async function getInviteByCode(
    code: string,
    creds: ApiCredentials,
): Promise<GetInviteByCodeResp> {
    try {
        const response = await doPOST(
            uris.getInviteByCode(),
            creds,
            { code },
        );

        if (!response.ok) {
            return failed(response.statusText);
        }

        const { email } = GetInviteByCodeApiResponse.parse(await response.json());

        return succeededWithData({ email });
    }
    catch (err: any) {
        return failed(err?.message);
    }
}

export async function updateUserDetails(
    userId: string,
    displayName: string,
    nickname: string,
    creds: ApiCredentials,
): Promise<ApiResponse> {
    try {
        const response = await doPATCH(uris.updateUserDetails(userId), creds, {
            nickname,
            display_name: displayName,
        });

        if (!response.ok) {
            return failed(response.statusText);
        }

        return succeeded();
    }
    catch (err: any) {
        return failed(err?.message);
    }
}
