import posthog, { type PostHog } from "posthog-js";

import type { FeatureFlagId } from "../../gen/features/features";

import { setTracingEnabled } from "@/api/trace";
import { getLocalPreferredIds } from "@/features/auth";
import { getEnvironmentConfig } from "@/misc/environment";
import log from "@/misc/log";
import { reinitSentry } from "@/misc/sentry";

export function getPosthogClient(): PostHog {
    const targetConfig = getEnvironmentConfig();
    const ids = getLocalPreferredIds();

    // The project hard-coded here is our "bo-nd.dev" project, which also seems to be a reasonable
    // thing to use for local development. The expectation is that once we have a production
    // environment we'll selectively pick that project for production only, so it won't contain
    // dev/test data like this will.

    // `posthog.init` is annotated to return "PostHog | void" but never returns void in reality.
    const posthogClient = posthog.init(
        "phc_Weej2JxH1WnJOw2haIvtTx5SclGgijPcPsPjLQxsoVC",
        {
            api_host: targetConfig.posthogHost,
            autocapture: false,
            enable_heatmaps: false,
            bootstrap: {
                distinctID: ids?.userId,
                isIdentifiedID: ids?.userId !== null || undefined,
            },
        },
    )!;

    posthog.register({
        frontend_version: __BEYOND_FRONTEND_VERSION__,
    });

    posthogClient.onFeatureFlags(flags => {
        log.info("Feature flags received", flags);

        // We type these strings to enforce that we're referring to a real feature flag.
        const posthogAutocapture: FeatureFlagId = "posthog-autocapture";
        if (flags.includes(posthogAutocapture)) {
            posthogClient.set_config({
                autocapture: true,
                enable_heatmaps: true,
            });
        }

        const frontendTracing: FeatureFlagId = "frontend-tracing";
        setTracingEnabled(flags.includes(frontendTracing));

        const sentryReplay: FeatureFlagId = "sentry-replay";
        if (flags.includes(sentryReplay)) {
            let sessionSampleRate = 0.01;
            let errorSampleRate = 0.1;

            const payload = posthogClient.getFeatureFlagPayload(sentryReplay);
            if (payload && typeof payload === "object") {
                const tpayload = payload as any;
                sessionSampleRate =
                    typeof tpayload.sessionSampleRate === "number" && tpayload.sessionSampleRate ||
                    0;
                errorSampleRate =
                    typeof tpayload.errorSampleRate === "number" && tpayload.errorSampleRate || 0;
            }

            reinitSentry({ replay: { sessionSampleRate, errorSampleRate } });
        }
        else {
            reinitSentry();
        }
    });

    return posthogClient;
}
