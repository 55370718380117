import classNames from "classnames";
import Graphemer from "graphemer";
import { Opts as LinkifyOpts } from "linkifyjs";
import { Ref, useMemo } from "react";

import { FeatureFlagged } from "@/components/FeatureFlags";
import Avatar from "@/components/gui/Avatar";
import { SensitiveJSX } from "@/components/gui/SensitiveText";
import TimeAgo from "@/components/gui/TimeAgo";
import AttachmentView from "@/components/messages/AttachmentView";
import DocView from "@/components/richtext/DocView";
import { LinkifyOptsProvider } from "@/context/LinkifyOptsContext";
import { getContentMessageDoc, SanitisedChatContent } from "@/domain/chatContent";
import * as d from "@/domain/domain";
import { AnyMessageId, UserId } from "@/domain/domain";
import {
    AnyMessage,
    getAttachmentMsgId,
    getCallId,
    getMessageCallStatus,
    getMsgTs,
    getSenderId,
    haveSameMessageType,
    haveSameSender,
    isOfficialChatMessage,
    isUnsentLocalMessage,
    MessageCallContext,
} from "@/domain/messages";
import { docBridge } from "@/domain/richtext/bridge";
import { defaultProvider, Format } from "@/domain/richtext/types";
import { selectCurrentUserId } from "@/features/auth";
import { selectMessage } from "@/features/chats";
import { selectUserName } from "@/features/users";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { Optional } from "@/misc/types";
import { useAppSelector } from "@/store/redux";

const linkifyOpts: LinkifyOpts = {
    target: "_blank",
};

export const maxJumbomojiLength = 40;

const whitespaceRegex = /\s/g;
const emojiRegex = new RegExp(
    "^(?:" +
        "(?:\\p{Extended_Pictographic}|\\p{Emoji_Component})+" +
        "(?:\\p{Emoji_Modifier}|\\p{Emoji_Modifier_Base}|\\p{Emoji_Presentation}|\\u200d)*" +
        ")+$",
    "u",
);

const alphaNumericRegex = /[\p{L}\p{N}]/u;

function isEmojiOnly(str: string): boolean {
    const noWhiteSpace = str.replace(whitespaceRegex, "");
    const splitter = new Graphemer();

    if (splitter.countGraphemes(noWhiteSpace) > maxJumbomojiLength) return false;

    // Iterate over graphemes to not overwhelm the regex engine.
    const graphemes = splitter.splitGraphemes(noWhiteSpace);
    for (const grapheme of graphemes) {
        if (!emojiRegex.test(grapheme)) {
            return false;
        }
    }

    return !alphaNumericRegex.test(noWhiteSpace);
}

const shouldShowMessageHeader = (
    previousMsg: Optional<AnyMessage>,
    msg: AnyMessage,
    currentUserId?: d.UserId,
) => {
    // `!haveSameSender` includes the case of 2 call messages.
    return !previousMsg || !haveSameMessageType(previousMsg, msg) ||
        !haveSameSender(previousMsg, msg, currentUserId);
};

const bigEnoughTimeDifference = (a: Date, b: Date) => a.getTime() - b.getTime() >= 5 * 60 * 1000;
const shouldShowTimestamp = (a: Optional<Date>, b: Optional<Date>) =>
    !a || !b || bigEnoughTimeDifference(a, b);

export interface ChatMessageViewProps {
    msg: AnyMessage;
    previousMsgId?: AnyMessageId;
    currentCallId?: d.CallId;
    messageContentRef?: Ref<HTMLDivElement>;
}

export const ChatMessageView = (
    { currentCallId, previousMsgId, msg, messageContentRef }: ChatMessageViewProps,
): React.JSX.Element => {
    const invalidMessage = !msg || (!isOfficialChatMessage(msg) && !isUnsentLocalMessage(msg));

    const currentUserId = useAppSelector(selectCurrentUserId);
    const senderId = getSenderId(msg, currentUserId);

    const senderName = useSelectorArgs(selectUserName, senderId);
    const previousMsg = useSelectorArgs(selectMessage, previousMsgId);

    const ts = useMemo(() => getMsgTs(msg), [msg]);
    const previousTs = useMemo(() => getMsgTs(previousMsg), [previousMsg]);

    const callId = getCallId(msg);
    const callStatus = getMessageCallStatus(callId, currentCallId);

    const messageId = useMemo(() => getAttachmentMsgId(msg), [msg]);

    if (invalidMessage) {
        return <div className="c-message c-message--unknown" ref={messageContentRef}></div>;
    }

    const props: ChatMessageViewInternalProps = {
        userId: senderId,
        content: msg.content,
        callStatus,
        attachmentIds: msg.attachmentIds,
        messageId,
        messageContentRef,
    };

    if (
        shouldShowMessageHeader(previousMsg, msg, currentUserId)
        || shouldShowTimestamp(ts, previousTs)
    ) {
        props.name = senderName;
        props.ts = ts;
    }

    return <ChatMessageViewInternal {...props} />;
};

interface ChatMessageViewInternalProps {
    userId?: UserId;
    name?: string;
    ts?: Date;
    content: SanitisedChatContent;
    callStatus: MessageCallContext;
    attachmentIds: d.BlobId[] | d.LocalAttachmentId[];
    messageId: d.AttachmentMessageId;
    messageContentRef?: Ref<HTMLDivElement>;
}

const ChatMessageViewInternal = (props: ChatMessageViewInternalProps): React.JSX.Element => {
    const {
        userId,
        name,
        messageId,
        ts,
        content,
        attachmentIds,
        messageContentRef,
        callStatus,
    } = props;

    const emojiOnly = useMemo(
        () => isEmojiOnly(content.message ?? ""),
        [content],
    );

    const doc = useMemo(
        () =>
            getContentMessageDoc(content)
                ?? docBridge
                    .fromText("Invalid message content", defaultProvider)
                    .get(Format.JSON),
        [content],
    );

    const isEnded = callStatus === MessageCallContext.EndedCall;
    const isLive = callStatus === MessageCallContext.LiveCall;
    const containerClassNames = classNames("c-message", {
        "c-message--live": isLive,
        "c-message--ended": isEnded,
    });
    const postClassNames = classNames("c-message__post", {
        "c-message__post--live": isLive,
        "c-message__post--ended": isEnded,
        "c-message__post--emoji": emojiOnly,
    });

    return (
        <div className={containerClassNames}>
            {userId && (
                <Avatar
                    id={userId}
                    showPresence={false}
                    hidden={!name}
                    size="message"
                />
            )}
            <div className="c-message__content">
                {(name || ts) && (
                    <div className="c-message__meta">
                        {name &&
                            (
                                <span className="c-message__author">
                                    {name}
                                </span>
                            )}
                        {ts && (
                            <span className="c-message__timestamp">
                                <TimeAgo from={ts?.valueOf() || 0} live={true} precise={true} />
                            </span>
                        )}
                    </div>
                )}
                <div ref={messageContentRef} className={postClassNames}>
                    <LinkifyOptsProvider opts={linkifyOpts}>
                        <SensitiveJSX>
                            <DocView
                                doc={doc}
                                fallback={content.message}
                                inline={false}
                            />
                        </SensitiveJSX>
                    </LinkifyOptsProvider>
                </div>
                <FeatureFlagged flag="message-attachments-view" match={true}>
                    {attachmentIds.map((id, i) => (
                        <AttachmentView
                            attachmentId={id}
                            key={id}
                            msgId={messageId}
                            index={i}
                        />
                    ))}
                </FeatureFlagged>
            </div>
        </div>
    );
};
