import QuillWrapper, { QuillBinding } from "@/components/richtext/quill/QuillWrapper";
import {
    RichTextEditorOps,
    RichTextEditorProps,
    RichTextEditorSelectHandler,
} from "@/components/richtext/RichTextEditor";
import { docBridge } from "@/domain/richtext/bridge";
import { Format, PositionRange, Provider } from "@/domain/richtext/types";
import useMergedRefs from "@/hooks/useMergedRefs";
import useQuillAutoCompleteQuery from "@/hooks/useQuillAutoCompleteQuery";
import { isMobileBrowser } from "@/misc/mobile";
import BeyondTheme from "@/misc/quill/themes/BeyondTheme";
import { andThen } from "@/misc/utils";
import {
    ForwardedRef,
    forwardRef,
    useCallback,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";

export const QuillRichTextEditor = forwardRef((
    props: RichTextEditorProps<Provider.Quill>,
    ref: ForwardedRef<RichTextEditorOps<Provider.Quill>>,
): React.JSX.Element => {
    const {
        doc,
        placeholder,
        tabIndex,
        onChange,
        onSelect,
        onSubmit,
        onEscape,
        onShiftEscape,
        onFocus,
        onBlur,
        onSuggestionQuery,
    } = props;

    const editorRef = useRef<RichTextEditorOps<Provider.Quill>>(null);
    const mergedRef = useMergedRefs(ref, editorRef);

    const docInternal = useMemo(
        () => andThen(doc, d => docBridge.from(d).get(Format.Internal)),
        [doc],
    );

    const [selection, setSelection] = useState<PositionRange<Provider.Quill> | null>(null);
    const onSelectInternal: RichTextEditorSelectHandler<Provider.Quill> = useCallback(
        (range, isApiChange) => {
            setSelection(range);
            onSelect?.(range, isApiChange);
        },
        [onSelect],
    );

    const suggestionQuery = useQuillAutoCompleteQuery(
        editorRef,
        ["@"],
        docInternal,
        selection?.end,
    );
    useLayoutEffect(
        () => onSuggestionQuery?.(suggestionQuery),
        [onSuggestionQuery, suggestionQuery],
    );

    const bindings = useMemo(() => {
        const b: Record<string, QuillBinding> = {
            shiftEscape: {
                key: "Escape",
                shiftKey: true,
                handler: onShiftEscape,
            },
            escape: {
                key: "Escape",
                handler: onEscape,
            },
        };

        // On mobile, we don't want "enter" to submit the message. The user should click on the
        // send icon instead.
        if (!isMobileBrowser()) {
            b["submit"] = {
                key: "Enter",
                handler: onSubmit,
            };
        }

        return b;
    }, [onSubmit, onShiftEscape, onEscape]);

    return (
        <QuillWrapper
            ref={mergedRef}
            bindings={bindings}
            placeholder={placeholder}
            theme={BeyondTheme.NAME}
            maxLength={8000}
            docForInit={docInternal}
            tabIndex={tabIndex}
            onChange={onChange}
            onSelect={onSelectInternal}
            onFocus={onFocus}
            onBlur={onBlur}
        />
    );
});

export default QuillRichTextEditor;
