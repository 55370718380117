import { Extension } from "@tiptap/core";

interface KeymapExtensionOptions {
    invertSubmit?: boolean;
    onSubmit?: () => void;
    onEscape?: () => void;
    onShiftEscape?: () => void;
}

export const KeymapExtension = Extension.create<KeymapExtensionOptions>({
    name: "keymapExtension",

    addKeyboardShortcuts() {
        const submitKey = this.options.invertSubmit ? "Shift-Enter" : "Enter";
        const newlineKey = this.options.invertSubmit ? "Enter" : "Shift-Enter";
        return {
            [submitKey]: () => this.options.onSubmit?.() ?? true,
            [newlineKey]: ({ editor }) =>
                editor.commands.first(({ commands }) => [
                    () => commands.splitListItem("listItem"),
                    () => commands.newlineInCode(),
                    () => commands.createParagraphNear(),
                    () => commands.liftEmptyBlock(),
                    () => commands.splitBlock(),
                ]),
            "Escape": () => this.options.onEscape?.() ?? true,
            "Shift-Escape": () => this.options.onShiftEscape?.() ?? true,
        };
    },
});
