import * as d from "@/domain/domain";
import type { RootState } from "@/store/types";

export type WorkerInitRequest = {
    type: "workerInitRequest";
    payload: {
        userId: d.UserId;
        deviceTag: string;
        port?: MessagePort;
    };
};

export type WorkerInitPayload = WorkerInitRequest["payload"];

export const workerInitRequest = (
    { userId, deviceTag, port }: WorkerInitPayload,
): WorkerInitRequest => ({
    type: "workerInitRequest",
    payload: { userId, deviceTag, port },
});

export type WorkerInitResponse = {
    type: "workerInitResponse";
    payload: {
        state: RootState;
        readFromSeqNo: number;
    };
    tabLock: {
        id: string;
        port: MessagePort;
    };
};

export const workerInitResponse = (
    state: RootState,
    readFromSeqNo: number,
    tabId: string,
    tabLockPort: MessagePort,
): WorkerInitResponse => ({
    type: "workerInitResponse",
    payload: { state, readFromSeqNo },
    tabLock: { id: tabId, port: tabLockPort },
});
