import { DateTime, Interval } from "luxon";

import { FeatureFlagged } from "@/components/FeatureFlags";
import { BondCreatedMessageView } from "@/components/messages/BondCreatedMessageView";
import { BondNewDivider, BondTimeDivider } from "@/components/messages/BondDivider";
import { CallMessageView } from "@/components/messages/CallMessageView";
import { ChatMessageView } from "@/components/messages/ChatMessageView";
import DebugMessageView from "@/components/messages/DebugMessageView";
import { IncompatibleMessageView } from "@/components/messages/IncompatibleMessageView";
import { InviteRedeemedMessageView } from "@/components/messages/InviteRedeemedMessageView";
import { InviteSentMessageView } from "@/components/messages/InviteSentMessageView";
import { SquadAccessGrantedMessageView } from "@/components/messages/SquadAccessGrantedMessageView";
import { SquadAccessRevokedMessageView } from "@/components/messages/SquadAccessRevokedMessage";
import { TitleChangeMessageView } from "@/components/messages/TitleChangeMessageView";
import { UserAccessGrantedMessageView } from "@/components/messages/UserAccessGrantedMessageView";
import { UserAccessRevokedMessageView } from "@/components/messages/UserAccessRevokedMessageView";
import * as d from "@/domain/domain";
import { AnyMessageId } from "@/domain/domain";
import {
    AnyOfficialMessage,
    AnyUnsentLocalMessage,
    getMsgChannelId,
    getMsgSequenceNumber,
    getMsgTs,
    isCallEndedMessage,
    isOfficialChatMessage,
    isOfficialMessage,
    OfficialMessageType,
} from "@/domain/messages";
import { selectCurrentUserId } from "@/features/auth";
import { selectBondIdByChannelId } from "@/features/bonds";
import { updateBondSidebarSummarySavedPublishedSeqNum } from "@/features/bonds";
import { selectMessage } from "@/features/chats";
import useBooleanFeatureFlag from "@/hooks/useBooleanFeatureFlag";
import useLocalDispatch from "@/hooks/useLocalDispatch";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { useAppSelector } from "@/store/redux";
import classNames from "classnames";
import { Ref, useEffect } from "react";

export interface MessageViewProps {
    id: AnyMessageId;
    currentCallId?: d.CallId;
    previousId?: AnyMessageId;
    isLastMessage?: boolean;
    savedPublishedSequenceNumber?: number;
    extraStyle?: React.CSSProperties;
    highlightMessage?: boolean;
    messageContentRef?: Ref<HTMLDivElement>;
}

// straddleDay decides whether the there is more than one calendar day in the
// interval [previousMsgTs, msgTs).
//
// For example, if msgTs and previousMsgTs reflected 23:59 and the following
// 00:01 respectively, then this would return true.
//
// For more information, see the function documentation for the count function
// on Luxon's Interval class.
function straddleDay(msgTs: Date, previousMsgTs: Date): boolean {
    const dT = DateTime.fromMillis(msgTs.getTime());
    const previousDT = DateTime.fromMillis(previousMsgTs.getTime());

    const interval = Interval.fromDateTimes(previousDT, dT);
    const dayCount = interval.count("days");

    return dayCount >= 2;
}

// TODO render emoji reactions
export default function MessageView(props: MessageViewProps): React.JSX.Element {
    const {
        savedPublishedSequenceNumber,
        currentCallId,
        id,
        previousId,
        isLastMessage,
        messageContentRef,
        highlightMessage,
    } = props;

    const currentUserId = useAppSelector(selectCurrentUserId);

    const msg = useSelectorArgs(selectMessage, id);
    const previousMsg = useSelectorArgs(selectMessage, previousId);
    const msgSequenceNumber = getMsgSequenceNumber(msg);
    const channelId = getMsgChannelId(msg);
    const bondId = useSelectorArgs(selectBondIdByChannelId, channelId);

    const isFirstUnreadMessage = ((savedPublishedSequenceNumber ?? 0) + 1) === msgSequenceNumber;
    const wasSentByUs = isOfficialChatMessage(msg) && msg.senderId === currentUserId;
    const lastMessageWasCallEnded = isLastMessage && isCallEndedMessage(msg);
    const showNewDivider = isFirstUnreadMessage && !wasSentByUs && !lastMessageWasCallEnded;

    const msgTs = getMsgTs(msg);
    const previousMsgTs = getMsgTs(previousMsg);
    const dateDivider = !!msgTs && !!previousMsgTs && !isFirstUnreadMessage &&
        straddleDay(msgTs, previousMsgTs);

    const localDispatch = useLocalDispatch();

    useEffect(() => {
        if (!bondId) return;
        // If the first unread message is also the last message, and this
        // message is a call ended message. Then there is nothing to summarise.
        // In this case we bump the saved published sequence number by 1, to
        // ensure that the bond does not have any "new content".
        if (isFirstUnreadMessage && lastMessageWasCallEnded) {
            localDispatch(
                updateBondSidebarSummarySavedPublishedSeqNum({
                    bondId,
                    savedPublishedSeqNum: (savedPublishedSequenceNumber ?? 0) + 1,
                }),
            );
        }
    }, [
        savedPublishedSequenceNumber,
        isFirstUnreadMessage,
        lastMessageWasCallEnded,
        localDispatch,
        bondId,
    ]);

    // Note we force a minimum height of 1px because virtual rendering requires all elements
    // to have a height. The one awkward edge case there is the call end message.

    const wrapperClasses = classNames(
        "c-message-wrapper",
        { "c-message-wrapper--highlight": highlightMessage },
    );

    return (
        <div className={wrapperClasses} id={id} style={props.extraStyle}>
            {dateDivider && <BondTimeDivider />}
            {showNewDivider && <BondNewDivider />}
            <MessageViewInternal
                currentCallId={currentCallId}
                msg={msg}
                previousId={previousId}
                messageContentRef={messageContentRef}
            />
        </div>
    );
}

interface MessageViewInternalProps {
    currentCallId?: d.CallId;
    msg?: AnyOfficialMessage | AnyUnsentLocalMessage;
    previousId?: AnyMessageId;
    messageContentRef?: Ref<HTMLDivElement>;
}

function MessageViewInternal(props: MessageViewInternalProps): React.JSX.Element {
    const { msg, previousId, currentCallId, messageContentRef } = props;

    const showDebug = useBooleanFeatureFlag("debug-message-view");
    if (showDebug) {
        return <DebugMessageView msg={msg} />;
    }

    if (msg && !isOfficialMessage(msg)) {
        return (
            <ChatMessageView
                currentCallId={currentCallId}
                msg={msg}
                previousMsgId={previousId}
                messageContentRef={messageContentRef}
            />
        );
    }
    const messageReaderComponent = <div ref={messageContentRef}></div>;
    switch (msg?.type) {
        case OfficialMessageType.Chat: {
            return (
                <ChatMessageView
                    currentCallId={currentCallId}
                    msg={msg}
                    previousMsgId={previousId}
                    messageContentRef={messageContentRef}
                />
            );
        }
        case OfficialMessageType.CallStart: {
            return (
                <CallMessageView
                    callId={msg.callId}
                    messageContentRef={messageContentRef}
                />
            );
        }
        case OfficialMessageType.CallEnd: {
            return <div ref={messageContentRef}></div>;
        }
        case OfficialMessageType.Incompatible: {
            return (
                <IncompatibleMessageView
                    msg={msg}
                    messageContentRef={messageContentRef}
                />
            );
        }
        case OfficialMessageType.SquadAccessGranted: {
            return (
                <FeatureFlagged
                    flag="chat-control-messages"
                    match={true}
                    fallback={messageReaderComponent}
                >
                    <SquadAccessGrantedMessageView
                        currentCallId={currentCallId}
                        msg={msg}
                        messageContentRef={messageContentRef}
                    />
                </FeatureFlagged>
            );
        }
        case OfficialMessageType.UserAccessGranted: {
            return (
                <FeatureFlagged
                    flag="chat-control-messages"
                    match={true}
                    fallback={messageReaderComponent}
                >
                    <UserAccessGrantedMessageView
                        currentCallId={currentCallId}
                        msg={msg}
                        messageContentRef={messageContentRef}
                    />
                </FeatureFlagged>
            );
        }
        case OfficialMessageType.TitleChange:
            return (
                <FeatureFlagged
                    flag="chat-control-messages"
                    match={true}
                    fallback={messageReaderComponent}
                >
                    <TitleChangeMessageView
                        currentCallId={currentCallId}
                        msg={msg}
                        messageContentRef={messageContentRef}
                    />
                </FeatureFlagged>
            );
        case OfficialMessageType.UserAccessRevoked: {
            return (
                <FeatureFlagged
                    flag="chat-control-messages"
                    match={true}
                    fallback={messageReaderComponent}
                >
                    <UserAccessRevokedMessageView
                        currentCallId={currentCallId}
                        msg={msg}
                        messageContentRef={messageContentRef}
                    />
                </FeatureFlagged>
            );
        }
        case OfficialMessageType.SquadAccessRevoked: {
            return (
                <FeatureFlagged
                    flag="chat-control-messages"
                    match={true}
                    fallback={messageReaderComponent}
                >
                    <SquadAccessRevokedMessageView
                        currentCallId={currentCallId}
                        msg={msg}
                        messageContentRef={messageContentRef}
                    />
                </FeatureFlagged>
            );
        }
        case OfficialMessageType.BondCreated: {
            return (
                <FeatureFlagged
                    flag="chat-control-messages"
                    match={true}
                    fallback={messageReaderComponent}
                >
                    <BondCreatedMessageView
                        currentCallId={currentCallId}
                        msg={msg}
                        messageContentRef={messageContentRef}
                    />
                </FeatureFlagged>
            );
        }
        case OfficialMessageType.InviteSent: {
            return (
                <FeatureFlagged
                    flag="chat-control-messages"
                    match={true}
                    fallback={messageReaderComponent}
                >
                    <InviteSentMessageView
                        currentCallId={currentCallId}
                        msg={msg}
                        messageContentRef={messageContentRef}
                    />
                </FeatureFlagged>
            );
        }
        case OfficialMessageType.InviteRedeemed: {
            return (
                <FeatureFlagged
                    flag="chat-control-messages"
                    match={true}
                    fallback={messageReaderComponent}
                >
                    <InviteRedeemedMessageView
                        currentCallId={currentCallId}
                        msg={msg}
                        messageContentRef={messageContentRef}
                    />
                </FeatureFlagged>
            );
        }
        default: {
            return <div className="c-message c-message--unknown" ref={messageContentRef} />;
        }
    }
}
