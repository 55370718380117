import * as d from "@/domain/domain";
import { backoffManagerCreator } from "@/ds/backoff";
import { hamtGet } from "@/ds/hamt";
import {
    observeSquadBondsListThunk,
    selectBonds,
    selectInterestedAndFollowedHeraldedIds,
} from "@/features/bonds";
import {
    catchupMessages,
    selectAllChannelMaps,
    selectChannelInfosForSeqNumUpdate,
    selectFirstUnsentMessages,
    subChannelMsgSeqNumsThunk,
    updateUserReadSequenceNumber,
} from "@/features/channels";
import { sendMessageThunk } from "@/features/chats";
import { selectVisibleChannelInterest, startManagingInterest } from "@/features/interest";
import { multiRpcStreamManager, StartConnectedStatefulListenerArgs } from "@/features/middleware";
import { selectCurrentSquadIds } from "@/features/squads";
import { filterRecordByEntry } from "@/misc/primatives";
import { TypedEntries } from "@/misc/types";
import {
    type AppAppendListener,
    appAppendListener,
    ListenerAPI,
    statesDifferBySelectors,
} from "@/store/middleware";
import { createAppSelector } from "@/store/redux";
import { RootState } from "@/store/types";

const selectChannelsWithUnfetchedMessages = createAppSelector(
    [
        selectBonds,
        selectAllChannelMaps,
        selectVisibleChannelInterest,
    ],
    (orderedBonds, cm, visibleChannelIds) =>
        orderedBonds
            .filter(
                ({ channelId, maxSequenceNumber }) => {
                    const local = hamtGet(cm, channelId)?.localSequenceNumber;
                    return !hamtGet(visibleChannelIds.hamt, channelId) && maxSequenceNumber !== 0 &&
                        (local === undefined || local < maxSequenceNumber);
                },
            ).map(bo => bo.channelId),
);

export const startChannelMsgSeqNoStream = (
    appendListener: AppAppendListener = appAppendListener,
) => startManagingInterest(
    selectInterestedAndFollowedHeraldedIds,
    subChannelMsgSeqNumsThunk,
    appendListener,
);

export const squadBondListObservationManager = multiRpcStreamManager(
    selectCurrentSquadIds,
    observeSquadBondsListThunk,
    "observeSquadBondsListManager",
);

export const messageFetcher: StartConnectedStatefulListenerArgs = [
    () => {
        const backoff = backoffManagerCreator();

        return async (api: ListenerAPI, _firstState: RootState) => {
            while (!api.signal.aborted) {
                const state = api.getState();
                const potentials = selectChannelsWithUnfetchedMessages(state);

                if (potentials.length === 0) break;

                const cb = backoff.begin();

                const channelId = potentials[0];

                try {
                    await api.dispatch(catchupMessages({ channelId })).unwrap();
                    cb(true);
                }
                catch (_e) {
                    cb(false);

                    await api.delay(backoff.getDelay());
                }
            }
        };
    },
    selectChannelsWithUnfetchedMessages,
    "messageFetcher",
];

export const messageReadUpdater: StartConnectedStatefulListenerArgs = [
    () => {
        const backoff = backoffManagerCreator();
        const outstanding = new Map<d.ChannelId, number>();

        return async (api: ListenerAPI, _firstState: RootState) => {
            let i = 0;
            while (!api.signal.aborted && i++ < 10) {
                const state = api.getState();
                const discrepant = selectChannelInfosForSeqNumUpdate(state);

                const updated = [...outstanding.entries()].filter(([cid, sn]) => {
                    const d = discrepant[cid];
                    return d === undefined || sn < d;
                });
                updated.forEach(([cid, _]) => outstanding.delete(cid));

                const potential = filterRecordByEntry(discrepant, (cid, sn) => {
                    const o = outstanding.get(cid);
                    return o === undefined || sn! < o;
                });

                if (Object.keys(potential).length === 0) break;

                const cb = backoff.begin();

                const [channelId, seqNum] = TypedEntries(potential)[0];

                try {
                    const sequenceNumber = seqNum!;
                    await api.dispatch(updateUserReadSequenceNumber({ channelId, sequenceNumber }))
                        .unwrap();
                    outstanding.set(channelId, sequenceNumber);
                    cb(true);
                }
                catch (_e) {
                    cb(false);

                    await api.delay(backoff.getDelay());
                }
            }
        };
    },
    selectChannelInfosForSeqNumUpdate,
    "messageReadUpdater",
];

export const messageSender: StartConnectedStatefulListenerArgs = [
    () => {
        const backoff = backoffManagerCreator();
        const stateChanged = statesDifferBySelectors(selectFirstUnsentMessages);

        return async (api: ListenerAPI, _firstState: RootState) => {
            while (!api.signal.aborted) {
                const state = api.getState();
                const msgs = selectFirstUnsentMessages(state);

                if (msgs.length === 0) break;

                const cb = backoff.begin();

                const msg = msgs[0];
                const now = Date.now();
                const nextAttempt = msg.backoffState?.nextAttempt ?? now;

                if (nextAttempt <= now) {
                    try {
                        await api.dispatch(sendMessageThunk(msg)).unwrap();
                        cb(true);
                    }
                    catch (_e) {
                        cb(false);

                        await api.delay(backoff.getDelay());
                    }
                }
                else {
                    await api.take(stateChanged, nextAttempt - now);
                }
            }
        };
    },
    selectFirstUnsentMessages,
    "messageSender",
];
