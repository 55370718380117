import Linkify from "linkify-react";
import { FC, memo } from "react";

import { useLinkifyOpts } from "@/context/LinkifyOptsContext";

interface ChatMessageTextViewProps {
    text: string;
}

export const ChatMessageTextViewInternal: FC<ChatMessageTextViewProps> = ({ text }) => {
    const linkifyOpts = useLinkifyOpts();
    return (linkifyOpts === undefined) ?
        text :
        <Linkify options={linkifyOpts}>{text}</Linkify>;
};

export const ChatMessageTextView = memo(ChatMessageTextViewInternal);

export default ChatMessageTextView;
