import { createClient } from "@connectrpc/connect";

import { DeviceService } from "../../gen/proto/devices/devices_connect";
import * as devices_pb from "../../gen/proto/devices/devices_pb";

import { transport } from "@/api/transport";
import { fromProtoTimestamp, fromProtoUserId } from "@/api/util";
import { UserObservation } from "@/domain/presence";
import { Optional } from "@/misc/types";

export const deviceService = createClient(DeviceService, transport);
export default deviceService;

export function parseObservations(
    obs: Optional<devices_pb.UserObservation[]>,
): UserObservation[] {
    return obs?.map((observation): UserObservation => {
        return {
            userId: fromProtoUserId(observation.userId),
            current: observation.current,
            startedAt: observation.startedAt && fromProtoTimestamp(observation.startedAt),
            endedAt: observation.endedAt && fromProtoTimestamp(observation.endedAt),
        };
    }) || [];
}
