import classNames from "classnames";
import { Ref, useMemo } from "react";

import * as d from "@/domain/domain";
import {
    getCallId,
    getMessageCallStatus,
    getMsgTs,
    MessageCallContext,
    UserAccessRevokedMessage,
} from "@/domain/messages";
import { selectCurrentUserId } from "@/features/auth";
import { selectUser } from "@/features/users";
import { useInViewInterest } from "@/hooks/interest/useInViewInterest";
import useMergedRefs from "@/hooks/useMergedRefs";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { Optional } from "@/misc/types";
import Avatar from "../gui/Avatar";
import TimeAgo from "../gui/TimeAgo";

export interface UserAccessRevokedMessageViewProps {
    msg: UserAccessRevokedMessage;
    messageContentRef?: Ref<HTMLDivElement>;
    currentCallId?: d.CallId;
}

export const UserAccessRevokedMessageView = (
    props: UserAccessRevokedMessageViewProps,
): React.JSX.Element => {
    const { msg, messageContentRef, currentCallId } = props;
    const { actorId, recipientId } = msg;

    const inViewRef = useInViewInterest({
        userIds: [actorId, recipientId],
        callIds: currentCallId,
    });
    const ref = useMergedRefs(messageContentRef, inViewRef);

    const ts = useMemo(() => getMsgTs(msg), [msg]);

    const callId = getCallId(msg);
    const callStatus = getMessageCallStatus(callId, currentCallId);

    const currentUserId = useSelectorArgs(selectCurrentUserId);
    const recipient = useSelectorArgs(selectUser, recipientId);
    const actor = useSelectorArgs(selectUser, actorId);
    const textContent = (() => {
        if (actorId === recipientId) {
            return "left the bond";
        }

        return `was removed by ${actorId === currentUserId ? `you` : actor?.name}`;
    })();

    if (!actor || !recipient) {
        return (
            <div className="c-message c-message--unknown" ref={ref}>
                {`Unknown user ${actorId} or ${recipientId}`}
            </div>
        );
    }

    return (
        <UserAccessRevokedMessageViewInternal
            recipientId={recipientId}
            recipientName={recipient?.name}
            ts={ts}
            content={textContent}
            callStatus={callStatus}
            messageContentRef={ref}
        />
    );
};

interface UserAccessRevokedMessageViewInternalProps {
    recipientId: Optional<d.UserId>;
    recipientName: Optional<string>;
    ts: Optional<Date>;
    content: string;
    callStatus: MessageCallContext;
    messageContentRef: Optional<Ref<HTMLDivElement>>;
}

const UserAccessRevokedMessageViewInternal = (
    props: UserAccessRevokedMessageViewInternalProps,
): React.JSX.Element => {
    const { recipientId, recipientName, ts, content, messageContentRef, callStatus } = props;

    const isEnded = callStatus === MessageCallContext.EndedCall;
    const isLive = callStatus === MessageCallContext.LiveCall;
    const containerClassNames = classNames("c-message", {
        "c-message--live": isLive,
        "c-message--ended": isEnded,
    });
    const actionClassNames = classNames("c-message__action", {
        "c-message__action--live": isLive,
        "c-message__action--ended": isEnded,
    });

    return (
        <div className={containerClassNames}>
            {!!recipientId && (
                <Avatar
                    id={recipientId}
                    showPresence={false}
                    hidden={!recipientName}
                    size="system-message"
                />
            )}
            <div className="c-message__content">
                {(recipientName || ts) && (
                    <div className="c-message__meta c-message__meta--event">
                        {recipientName &&
                            (
                                <span className="c-message__author">
                                    {recipientName}
                                </span>
                            )}
                        {ts && (
                            <span className="c-message__timestamp">
                                <TimeAgo from={ts?.valueOf() || 0} live={true} precise={true} />
                            </span>
                        )}
                    </div>
                )}
                <div ref={messageContentRef} className={actionClassNames}>
                    {content}
                </div>
            </div>
        </div>
    );
};
