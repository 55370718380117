import { getNonNativeDeviceTag } from "@/features/meta";
import { getNativeDeviceTag, isNativePlatform } from "./capacitor";

/** Get the device tag for the client.
 *
 * If running in a native environment, this returns the native device tag.
 *
 * If running in a non-native environment, this returns the device tag stored in local storage.
 */
export const getDeviceTag = async (): Promise<string> => {
    let deviceTag: string;
    if (isNativePlatform()) {
        deviceTag = await getNativeDeviceTag();
    }
    else {
        deviceTag = getNonNativeDeviceTag();
    }
    if (!deviceTag) {
        throw new Error("Device tag is not present to start store");
    }

    return deviceTag;
};
