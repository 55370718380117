import { RichTextSuggestionQueryHandler } from "@/components/richtext/RichTextEditor";
import { mapRecord } from "@/misc/primatives";
import { KeymapExtension } from "@/misc/tiptap/KeymapExtension";
import { MentionNode } from "@/misc/tiptap/MentionNode";
import { TabIndexExtension } from "@/misc/tiptap/TabIndexExtension";
import { TransformExtension } from "@/misc/tiptap/TransformExtension";
import { Blockquote } from "@tiptap/extension-blockquote";
import { Bold } from "@tiptap/extension-bold";
import { BulletList } from "@tiptap/extension-bullet-list";
import { Code } from "@tiptap/extension-code";
import { CodeBlock } from "@tiptap/extension-code-block";
import { Document } from "@tiptap/extension-document";
import { Dropcursor } from "@tiptap/extension-dropcursor";
import { Gapcursor } from "@tiptap/extension-gapcursor";
import { Heading } from "@tiptap/extension-heading";
import { History } from "@tiptap/extension-history";
import { HorizontalRule } from "@tiptap/extension-horizontal-rule";
import { Italic } from "@tiptap/extension-italic";
import { ListItem } from "@tiptap/extension-list-item";
import { OrderedList } from "@tiptap/extension-ordered-list";
import { Paragraph } from "@tiptap/extension-paragraph";
import { Placeholder } from "@tiptap/extension-placeholder";
import { Strike } from "@tiptap/extension-strike";
import { Text } from "@tiptap/extension-text";
import { Extensions, getSchema } from "@tiptap/react";
import { Provider } from "../types";

export type SchemaVersion = 1;

interface ExtensionsOptions {
    placeholder?: string;
    onSuggestionQuery?: RichTextSuggestionQueryHandler<Provider.Tiptap>;
    invertSubmit?: boolean;
    onSubmit?: () => void;
    onEscape?: () => void;
    onShiftEscape?: () => void;
    tabIndex?: number;
}

// TODO: use CodeBlockLowlight for syntax highlighting
const extensions: Record<SchemaVersion, (o: ExtensionsOptions) => Extensions> = {
    1: ({
        placeholder,
        onSuggestionQuery,
        invertSubmit,
        onSubmit,
        onEscape,
        onShiftEscape,
        tabIndex,
    }) => [
        Bold,
        Blockquote,
        BulletList,
        Code,
        CodeBlock.configure({ exitOnTripleEnter: false }),
        Document,
        Dropcursor,
        Gapcursor,
        Heading.configure({ levels: [1, 2, 3] }),
        History.configure({ newGroupDelay: 250 }),
        HorizontalRule,
        Italic,
        ListItem,
        OrderedList,
        Paragraph,
        Strike,
        Text,
        Placeholder.configure({ placeholder }),
        MentionNode.configure({ onSuggestionQuery }),
        TransformExtension,
        KeymapExtension.configure({ invertSubmit, onSubmit, onEscape, onShiftEscape }),
        TabIndexExtension.configure({ tabIndex }),
    ],
};

export const currentSchemaVersion: SchemaVersion = 1;

export const configureExtensions = (
    ver: SchemaVersion,
    options: ExtensionsOptions,
): Extensions => extensions[ver](options);

export const schemas = mapRecord(extensions, (_, e) => getSchema(e({})));
