import { JSONTransform } from "@/domain/richtext/tiptap/node";
import { currentSchemaVersion } from "@/domain/richtext/tiptap/schema";
import { Extension } from "@tiptap/core";
import { Step } from "@tiptap/pm/transform";

const transformExtensionName = "transformExtension";

export const isApiChangeMetaKey = `${transformExtensionName}/isApiChange`;

declare module "@tiptap/core" {
    interface Commands<ReturnType> {
        [transformExtensionName]: {
            applyFromJSON: (transform: JSONTransform) => ReturnType;
        };
    }
}

export const TransformExtension = Extension.create({
    name: transformExtensionName,

    addCommands: () => ({
        applyFromJSON: transform => ({ tr, dispatch, state }) => {
            if (transform.ver != currentSchemaVersion) return false;

            tr.setMeta(isApiChangeMetaKey, true);

            try {
                transform.steps.forEach(s => {
                    tr.step(Step.fromJSON(state.schema, s));
                });
            }
            catch {
                return false;
            }

            if (dispatch !== undefined) dispatch(tr);
            return true;
        },
    }),
});
