import { SearchFilterOptions, selectFilter, setFilter } from "@/features/search";
import { useAppDispatch, useAppSelector } from "@/store/redux";
import classNames from "classnames";
import { MouseEventHandler, useState } from "react";
import PresenceHeader from "./PresenceHeader";

const FilterButton = (
    { name, current, onClick }: {
        name: SearchFilterOptions;
        current: SearchFilterOptions;
        onClick: (nf: SearchFilterOptions) => void;
    },
) => {
    const classes = classNames("c-filter-dropdown__option", {
        "is-selected": current === name,
    });
    return (
        <button
            className={classes}
            onClick={() => onClick(name)}
        >
            {name}
        </button>
    );
};

export default function SearchFilterAndPresenceHeader(): React.JSX.Element {
    const dispatch = useAppDispatch();

    const filter = useAppSelector(selectFilter);
    const isFilterEnabled = filter !== SearchFilterOptions.Everything;
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const toggleFilter: MouseEventHandler = () => setIsFilterVisible(z => !z);
    const handleFilterChange = (newFilter: SearchFilterOptions) => {
        dispatch(setFilter(newFilter));
        setIsFilterVisible(false);
    };

    const buttonClasses = classNames("c-btn-filter", {
        "is-selected": isFilterEnabled,
    });
    const divClasses = classNames("c-filter-dropdown__options", {
        "is-visible": isFilterVisible,
    });

    const options: SearchFilterOptions[] = [
        SearchFilterOptions.Everything,
        SearchFilterOptions.Messages,
        SearchFilterOptions.Bonds,
        SearchFilterOptions.Calls,
        SearchFilterOptions.Attachments,
    ];

    return (
        <div className="c-bonds-presence-wrapper">
            <div className="c-filter-dropdown">
                <button
                    onClick={toggleFilter}
                    className={buttonClasses}
                    title={`Showing ${filter} bonds`}
                >
                    {filter}
                </button>

                <div className={divClasses}>
                    {options.map(name => (
                        <FilterButton
                            name={name}
                            current={filter}
                            onClick={handleFilterChange}
                            key={name}
                        />
                    ))}
                </div>
            </div>
            <PresenceHeader />
        </div>
    );
}
