import { selectFollowedUnreadBondsCount } from "@/features/bonds";
import { selectConnectedWithUserId } from "@/features/connection";
import {
    selectNumberOfFollowedUnreadMentions,
    selectOfflineNumberOfFollowedUnreadBonds,
} from "@/features/filteredBonds";
import { MetaInterestCounterKey } from "@/features/interest";
import { backendRTTDebounceDuration } from "@/misc/backend";
import { useMetaInterest } from "./interest/useInterest";
import useDebounce from "./useDebounce";
import useSelectorArgs from "./useSelectorArgs";

// This is similar to useDebouncedFollowedUnreadCount but doesn't include bonds with mentions
export default function useDebouncedRelevantUnreadCount(): number {
    useMetaInterest(true, MetaInterestCounterKey.FollowedUnreadCount);

    const followedUnreadBondsCount = useSelectorArgs(selectFollowedUnreadBondsCount);

    const offlineFollowedUnreadCountEstimate = useSelectorArgs(
        selectOfflineNumberOfFollowedUnreadBonds,
    );

    const connected = useSelectorArgs(selectConnectedWithUserId);
    const unread = connected ? followedUnreadBondsCount : offlineFollowedUnreadCountEstimate;
    const mentionsCount = useSelectorArgs(selectNumberOfFollowedUnreadMentions);
    const relevantUnread = Math.max(0, unread - mentionsCount);

    return useDebounce(
        relevantUnread,
        backendRTTDebounceDuration,
        undefined,
        (oldValue, newValue) => oldValue > newValue,
    );
}
