import * as d from "@/domain/domain";
import { convertDraftContentToV3, DraftChatContent } from "@/domain/draftChatContent";
import { ContentMention, Mention } from "@/domain/mentions";
import { getMentions, parseMarkupDelta } from "@/domain/richtext/quill/delta";
import { Optional } from "@/misc/types";
import { andThen } from "@/misc/utils";
import { changeBridge, docBridge } from "./richtext/bridge";
import { emptyLegacyMarkupDelta, LegacyMarkupDelta } from "./richtext/quill/legacyDelta";
import { tagDoc } from "./richtext/taggers";
import { defaultProvider, Doc, Format, Provider } from "./richtext/types";

export const currentChatContentVer = 2;

/**
 * @deprecated
 */
export type SanitisedChatContent_V1 = {
    /**
     * @deprecated
     */
    ver: 1 | undefined;

    /**
     * Opaque, client-only identifier for each message.
     * Allows the client to tie together messages received from the
     * cloud and messages that it sent.
     *
     * The backend provides the contract that messages with different
     * ids will be treated as distinct messages for the idempotent
     * semantics of sending a message.
     * @deprecated
     */
    id?: d.UnsentMessageId;

    /**
     * Plaintext version of the message. Not provided if, e.g., the
     * message contains only an uploaded file but no text
     * @deprecated
     */
    message?: string;

    /**
     * List of non-overlapping character ranges representing mentions
     * in the text, ordered by start offset
     * @deprecated
     */
    mentions?: ContentMention[];
};

/**
 * @deprecated
 */
export type SanitisedChatContent_V2 = {
    /**
     * Version 2 supports rich text markup stored in `messageMarkup`, while
     * flattened plaintext is stored in `message` for AI use
     * @deprecated
     */
    ver: 2;

    /**
     * Opaque, client-only identifier for each message.
     * Allows the client to tie together messages received from the
     * cloud and messages that it sent.
     *
     * The backend provides the contract that messages with different
     * ids will be treated as distinct messages for the idempotent
     * semantics of sending a message.
     * @deprecated
     */
    id?: d.UnsentMessageId;

    /**
     * Quill Delta markup describing the message with formatting and embeds
     * @deprecated
     */
    messageMarkup?: LegacyMarkupDelta;

    /**
     * Plaintext version of the message, parsed from the message markup.
     * This can be used for prompting AI models, but should not be
     * displayed to the user unless the message is plaintext-only.
     * @deprecated
     */
    message?: string;
};

export type SanitisedChatContent_V3 = {
    /**
     * Version 3 supports rich text documents stored in any format, while
     * flattened plaintext is stored in `message` for AI use
     */
    ver: 3;

    /**
     * Opaque, client-only identifier for each message.
     * Allows the client to tie together messages received from the
     * cloud and messages that it sent.
     *
     * The backend provides the contract that messages with different
     * ids will be treated as distinct messages for the idempotent
     * semantics of sending a message.
     */
    id?: d.UnsentMessageId;

    /**
     * Tagged rich text document describing the message with formatting and embeds
     */
    messageDoc?: Doc<Provider, Format.JSON>;

    /**
     * Plaintext version of the message, parsed from the message markup.
     * This can be used for prompting AI models, but should not be
     * displayed to the user unless the message is plaintext-only.
     */
    message?: string;
};

/**
 * This is stored as a string-y blob in the database. But it must
 * be parsed by something on the backend in order for the message
 * to be provided to an AI for the purpose of creating summaries.
 */
export type SanitisedChatContent =
    | SanitisedChatContent_V1
    | SanitisedChatContent_V2
    | SanitisedChatContent_V3;

/**
 * Produce a different result depending on the chat content type. If the
 * type is not recognised, use a default result
 */
export const switchChatContentVersion = <T>(
    content: Optional<SanitisedChatContent>,
    ver1Map: (c1: SanitisedChatContent_V1) => T,
    ver2Map: (c2: SanitisedChatContent_V2) => T,
    ver3Map: (c3: SanitisedChatContent_V3) => T,
    defaultThunk: () => T,
): T => {
    if (content) {
        switch (content.ver) {
            case undefined:
            case 1:
                return ver1Map(content as SanitisedChatContent_V1);
            case 2:
                return ver2Map(content as SanitisedChatContent_V2);
            case 3:
                return ver3Map(content as SanitisedChatContent_V3);
        }
    }
    return defaultThunk();
};

/**
 * Return the message document from a message content instance. If the message version
 * does not store a document, generate one from the available data
 */
export const getContentMessageDoc = (
    content: Optional<SanitisedChatContent>,
): Optional<Doc<Provider, Format.JSON>> =>
    switchChatContentVersion(
        content,
        c1 => docBridge.fromText(c1.message ?? "", defaultProvider).get(Format.JSON),
        c2 =>
            tagDoc(
                c2.messageMarkup ?? emptyLegacyMarkupDelta(),
                Provider.Quill,
                Format.JSON,
            ),
        c3 => c3.messageDoc,
        () =>
            // Future-proof against future chat content versions by using `message` if present
            andThen(content?.message, m =>
                docBridge.fromText(m ?? "", defaultProvider).get(Format.JSON)),
    );

/**
 * Return a list of mentions from a message content instance
 */
export const getContent_Mentions = (content: Optional<SanitisedChatContent>): Optional<Mention[]> =>
    switchChatContentVersion(
        content,
        c1 => c1.mentions,
        c2 => getMentions(parseMarkupDelta(c2.messageMarkup)),
        c3 => andThen(c3.messageDoc, docBridge.from)?.getMentions(),
        () => undefined,
    );

/**
 * Takes a DraftChatContent and uses it to fill out a SanitisedChatContent that can
 * be used to send a chat message to the backend
 */
export const sanitiseDraftContent = (content: DraftChatContent): SanitisedChatContent_V3 => {
    const convertedContent = convertDraftContentToV3(defaultProvider, content);
    const { doc: newDoc } = changeBridge
        .on(convertedContent.draftDoc)
        .trimWhitespace()
        .get(Format.JSON);
    const newDocBridge = docBridge.from(newDoc);
    return {
        ver: 3,
        id: convertedContent.draftId,
        messageDoc: newDocBridge.get(Format.JSON),
        message: newDocBridge.getText(true),
    };
};
