import { createClient } from "@connectrpc/connect";

import { BondService } from "../../gen/proto/bonds/bonds_connect";
import * as bonds_pb from "../../gen/proto/bonds/bonds_pb";
import * as clients_pb from "../../gen/proto/clients/clients_pb";

import clientService from "@/api/client";
import { parseObservations } from "@/api/devices";
import { parseMessageWithSchema } from "@/api/parser";
import { streamHandler } from "@/api/stream";
import { transport } from "@/api/transport";
import * as util from "@/api/util";
import {
    fromProtoBondId,
    fromProtoBondSet,
    pbPersonId,
    pbUserId,
    toProtoBondSet,
} from "@/api/util";
import {
    BondHeraldOrDeleted,
    BondHeraldOrDeletedSchema,
    BondOverviewOrDeleted,
    BondOverviewOrDeletedSchema,
    type CatchupKnowledge,
} from "@/domain/bonds";
import * as d from "@/domain/domain";
import { type BondInvite, translateBondInvite } from "@/domain/invites";
import { UserObservation } from "@/domain/presence";
import { deepFreeze } from "@/misc/deepFreeze";
import { translateAsyncIterable } from "@/misc/iterable";
import { Diff, Optional } from "@/misc/types";
import { separateDiscriminatedUnion } from "@/misc/utils";

// Re-export proto types for use in the rest of the frontend
export { BondSet, PrivacyLevel } from "../../gen/proto/domain/domain_pb";

const service = createClient(BondService, transport);
export default service;

interface FollowBondArgs {
    bondId: d.BondId;
    follow: boolean;
}

export async function setFollowBond(
    args: FollowBondArgs,
): Promise<void> {
    const req = new clients_pb.FollowBondRequest({
        bondId: util.pbBondId(args.bondId),
        follow: args.follow,
    });
    await clientService.followBond(req);
}

interface ArchiveBondArgs {
    bondId: d.BondId;
    archive: boolean;
}

export async function setArchiveBond(
    args: ArchiveBondArgs,
): Promise<void> {
    const req = new clients_pb.ArchiveBondRequest({
        bondId: util.pbBondId(args.bondId),
        archive: args.archive,
    });
    await clientService.archiveBond(req);
}

interface UpdateBondTitleArgs {
    bondId: d.BondId;
    title: string;
    actingUserId: d.UserId;
}

export async function updateBondTitle(
    args: UpdateBondTitleArgs,
): Promise<void> {
    const req = new bonds_pb.UpdateBondUserSpecifiedTitleRequest({
        bondId: util.pbBondId(args.bondId),
        content: args.title,
        actingUserId: util.pbUserId(args.actingUserId),
    });

    await service.updateBondUserSpecifiedTitle(req);
}

interface ModifyBondMembershipArgs {
    bondId: d.BondId;
    userIdsToAdd: d.UserId[];
    squadIdsToAdd: d.SquadId[];
}

export async function modifyBondMembership(
    args: ModifyBondMembershipArgs,
): Promise<void> {
    // Currently this RPC only supports adding to membership, not removing.
    const req = new clients_pb.ModifyBondMembershipRequest({
        bondId: util.pbBondId(args.bondId),
        addToMembership: true,
        users: util.pbUserSet(args.userIdsToAdd),
        squads: util.pbSquadSet(args.squadIdsToAdd),
    });

    await clientService.modifyBondMembership(req);
}

interface InviteUserToBondViaEmailArgs {
    bondId: d.BondId;
    invitedEmailAddress: string;
}

export async function inviteUserToBondViaEmail(
    args: InviteUserToBondViaEmailArgs,
): Promise<void> {
    const req = new clients_pb.InviteUserToBondViaEmailRequest({
        bondId: util.pbBondId(args.bondId),
        invitedEmailAddress: args.invitedEmailAddress,
    });
    await clientService.inviteUserToBondViaEmail(req);
}

interface GetShareableBondInviteLinkArgs {
    bondId: d.BondId;
}

export async function getShareableBondInviteLink(
    args: GetShareableBondInviteLinkArgs,
): Promise<string> {
    const req = new clients_pb.GetShareableBondInviteLinkRequest({
        bondId: util.pbBondId(args.bondId),
    });
    const resp = await clientService.getShareableBondInviteLink(req);
    return resp.inviteLink;
}

interface FindBondInviteArgs {
    opaqueCode: string;
}
interface FindBondInviteResp {
    invite: BondInvite;
}

export async function retrieveBondInvite(
    args: FindBondInviteArgs,
): Promise<Optional<FindBondInviteResp>> {
    const req = new bonds_pb.FindBondInviteRequest({
        opaqueCode: args.opaqueCode,
    });
    const resp = await service.findBondInvite(req);
    return resp.invite && deepFreeze({
        invite: translateBondInvite(resp.invite),
    });
}

interface RedeemBondInviteArgs {
    actingUserId: d.UserId;
    opaqueCode: string;
}

export async function redeemBondInvite(
    args: RedeemBondInviteArgs,
): Promise<Optional<d.BondId>> {
    const req = new bonds_pb.RedeemBondInviteCodeRequest({
        actingUserId: pbUserId(args.actingUserId),
        opaqueCode: args.opaqueCode,
    });
    const resp = await service.redeemBondInviteCode(req);
    return fromProtoBondId(resp.bondId);
}

interface RejectBondInviteArgs {
    bondId: d.BondId;
}

export async function rejectBondInvite(
    args: RejectBondInviteArgs,
): Promise<void> {
    const req = new clients_pb.RejectBondInviteRequest({
        bondId: util.pbBondId(args.bondId),
    });
    await clientService.rejectBondInvite(req);
}

export async function* observeBond(
    bondId: d.BondId,
    signal: AbortSignal,
): AsyncGenerator<void, void, unknown> {
    const req = new clients_pb.ObserveBondRequest({
        bondId: util.pbBondId(bondId),
    });

    const resp = clientService.observeBond(req, { signal });

    yield* streamHandler(resp, () => {}, observeBond.name);
}

export async function* subBond(
    bondId: d.BondId,
    signal: AbortSignal,
): AsyncGenerator<BondOverviewOrDeleted, void, unknown> {
    const req = new bonds_pb.SubBondRequest({
        bondId: util.pbBondId(bondId),
    });

    const resp = service.subBond(req, { signal });

    yield* streamHandler(
        resp,
        parseMessageWithSchema(BondOverviewOrDeletedSchema, "bondOrDeleted"),
        subBond.name,
    );
}

export async function* subBondsList(
    userId: d.UserId,
    signal: AbortSignal,
): AsyncGenerator<BondHeraldOrDeleted, void, unknown> {
    const req = new bonds_pb.SubBondsListRequest({
        userId: pbUserId(userId),
    });

    const resp = service.subBondsList(req, { signal });

    yield* streamHandler(
        resp,
        parseMessageWithSchema(BondHeraldOrDeletedSchema, "heraldOrDeleted"),
        subBondsList.name,
    );
}

async function* translateBondDiff(v: Diff<d.BondId>) {
    if (v.added?.length) {
        yield new bonds_pb.SubBondsV2Request({
            bondIds: toProtoBondSet(v.added),
            addToSub: true,
        });
    }
    if (v.removed?.length) {
        yield new bonds_pb.SubBondsV2Request({
            bondIds: toProtoBondSet(v.removed),
            addToSub: false,
        });
    }
}

export async function* subBondsV2(
    reqStream: AsyncIterableIterator<Diff<d.BondId>>,
    signal: AbortSignal,
) {
    const translation = translateAsyncIterable(reqStream, translateBondDiff);

    const stream = service.subBondsV2(translation, { signal });

    yield* streamHandler(
        stream,
        parseMessageWithSchema(BondOverviewOrDeletedSchema, "bondOrDeleted"),
        subBondsV2.name,
    );
}

export async function* observeSquadBondsList(
    squadId: d.SquadId,
    userId: d.UserId,
    signal: AbortSignal,
): AsyncGenerator<void, void, unknown> {
    const req = new clients_pb.ObserveBondsPreviewsRequest({
        squadId: util.pbSquadId(squadId),
        userId: util.pbUserId(userId),
    });

    const resp = clientService.observeBondsPreviews(req, { signal });

    yield* streamHandler(resp, () => {}, observeSquadBondsList.name);
}

export async function* subArchivedBonds(
    userId: d.UserId,
    signal: AbortSignal,
) {
    const req = new bonds_pb.SubArchivedBondsListDiffRequest({
        userId: pbUserId(userId),
    });

    const resp = service.subArchivedBondsListDiff(req, { signal });

    let first = true;
    const parse = (pb: bonds_pb.SubArchivedBondsListDiffResponse) => {
        const overwrite = first;
        first = false;
        return ({
            overwrite: overwrite,
            added: fromProtoBondSet(pb.bondIdsAdded),
            removed: fromProtoBondSet(pb.bondIdsRemoved),
        });
    };

    yield* streamHandler(resp, parse, subArchivedBonds.name);
}

export async function* subPersonalBondInvites(
    personId: d.PersonId,
    signal: AbortSignal,
) {
    const req = new bonds_pb.SubPersonBondInvitesDiffRequest({
        personId: pbPersonId(personId),
    });

    const resp = service.subPersonBondInvitesDiff(req, { signal });

    let first = true;
    const parse = (pb: bonds_pb.SubPersonBondInvitesDiffResponse) => {
        const overwrite = first;
        first = false;
        return ({
            overwrite: overwrite,
            added: pb.bondInvitesAdded.map(translateBondInvite),
            removed: pb.bondInvitesRemoved.map(translateBondInvite),
        });
    };

    yield* streamHandler(resp, parse, service.subPersonBondInvitesDiff.name);
}

export type BondObservations = {
    viewId: d.BondId;
    observations: Array<UserObservation>;
};

async function* translateBondObserversDiff(v: Diff<d.BondId>) {
    if (v.added?.length) {
        yield new bonds_pb.SubBondObserversRequest({
            bondIds: toProtoBondSet(v.added),
            addToSub: true,
        });
    }
    if (v.removed?.length) {
        yield new bonds_pb.SubBondObserversRequest({
            bondIds: toProtoBondSet(v.removed),
            addToSub: false,
        });
    }
}

function parseBondObservers(pb: Optional<bonds_pb.SubBondObserversResponse>): BondObservations {
    return {
        viewId: fromProtoBondId(pb?.bondId),
        observations: parseObservations(pb?.observers),
    };
}

export async function* subBondObservers(
    reqStream: AsyncIterableIterator<Diff<d.BondId>>,
    signal: AbortSignal,
): AsyncGenerator<BondObservations, void, unknown> {
    const translation = translateAsyncIterable(reqStream, translateBondObserversDiff);

    const resp = service.subBondObservers(translation, { signal });

    yield* streamHandler(resp, parseBondObservers, subBondObservers.name);
}

export interface BondContemporaries {
    bondId: d.BondId;
    userIds: d.UserId[];
}

function bondContemporariesParser(res: bonds_pb.SubBondContemporariesResponse): BondContemporaries {
    return {
        bondId: fromProtoBondId(res.bondId),
        userIds: util.fromProtoUserSet(res.bondContemporaries),
    };
}

async function* translateBondContemporariesDiff(v: Diff<d.BondId>) {
    if (v.added?.length) {
        yield new bonds_pb.SubBondContemporariesRequest({
            bondIds: toProtoBondSet(v.added),
            addToSub: true,
        });
    }
    if (v.removed?.length) {
        yield new bonds_pb.SubBondContemporariesRequest({
            bondIds: toProtoBondSet(v.removed),
            addToSub: false,
        });
    }
}

export async function* subBondContemporaries(
    reqStream: AsyncIterableIterator<Diff<d.BondId>>,
    signal: AbortSignal,
) {
    const translation = translateAsyncIterable(reqStream, translateBondContemporariesDiff);

    const stream = service.subBondContemporaries(translation, { signal });

    yield* streamHandler(stream, bondContemporariesParser, subBondContemporaries.name);
}

type CatchupKnowledgeOrDeletedA = {
    case: "knowledge";
    value: CatchupKnowledge;
};
type CatchupKnowledgeOrDeletedB = {
    case: "deleted";
    deletedId: d.BondId;
};
export type CatchupKnowledgeOrDeleted =
    | CatchupKnowledgeOrDeletedA
    | CatchupKnowledgeOrDeletedB;
export const separateCatchupKnowledgeOrDeleteds = (xs: CatchupKnowledgeOrDeleted[]) =>
    separateDiscriminatedUnion<
        CatchupKnowledgeOrDeletedA,
        CatchupKnowledgeOrDeletedB
    >(x => x.case == "knowledge", xs);
export const toCatchupKnowledgeOrDeleted = (
    value: CatchupKnowledge,
): CatchupKnowledgeOrDeletedA => ({
    case: "knowledge",
    value,
});
export const deletedCatchupKnowledgeOrDeleted = (
    deletedId: d.BondId,
): CatchupKnowledgeOrDeletedB => ({
    case: "deleted",
    deletedId,
});

function catchupSummariesParser(
    res: bonds_pb.SubBondCatchupSummariesResponse,
): CatchupKnowledgeOrDeleted {
    switch (res.summaryOrDeleted.case) {
        case "summary":
            return toCatchupKnowledgeOrDeleted({
                bondId: fromProtoBondId(res.summaryOrDeleted.value.bondId),
                summary: res.summaryOrDeleted.value.summary,
                lastSummarisedSeq: Number(res.summaryOrDeleted.value.lastSummarisedSeq),
            });
        case "deletedId":
            return deletedCatchupKnowledgeOrDeleted(fromProtoBondId(res.summaryOrDeleted.value));
        default:
            throw new Error(
                `unexpected catchupKnowledgeOrDeleted case: ${res.summaryOrDeleted?.case}`,
            );
    }
}

const translateCatchupSummariesDiff = (userId: d.UserId) => {
    return async function* (v: Diff<d.BondId>) {
        if (v.added?.length) {
            yield new bonds_pb.SubBondCatchupSummariesRequest({
                userId: pbUserId(userId),
                addToSub: true,
                bondIds: toProtoBondSet(v.added),
            });
        }
        if (v.removed?.length) {
            yield new bonds_pb.SubBondCatchupSummariesRequest({
                userId: pbUserId(userId),
                addToSub: false,
                bondIds: toProtoBondSet(v.removed),
            });
        }
    };
};

interface SubBondCatchupSummariesArgs {
    userId: d.UserId;
}

export async function* subBondCatchupSummaries(
    reqStream: AsyncIterableIterator<Diff<d.BondId>>,
    { userId }: SubBondCatchupSummariesArgs,
    signal: AbortSignal,
) {
    const translation = translateAsyncIterable(
        reqStream,
        translateCatchupSummariesDiff(userId),
    );

    const stream = service.subBondCatchupSummaries(translation, { signal });

    yield* streamHandler(stream, catchupSummariesParser, subBondCatchupSummaries.name);
}
