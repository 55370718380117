import AudioPreview from "@/components/gui/AudioPreview";
import MediaDeviceSelector from "@/components/gui/MediaDeviceSelector";
import VideoPreview from "@/components/gui/VideoPreview";
import { DeviceKind } from "@/domain/mediaDevices";
import { DevicePermissions, selectDevicePermissions } from "@/features/mediaDevices";
import useEnumeratedDevices from "@/hooks/media/useEnumeratedDevices";
import useSelectorArgs from "@/hooks/useSelectorArgs";

export function MediaSettingsView(): React.JSX.Element {
    // Assume video permissions === all permissions (which it is with
    // enumeratedDevices this high in the component tree)
    const permissions = useSelectorArgs(selectDevicePermissions, DeviceKind.VideoInput);

    // Request and listen to list of media devices
    useEnumeratedDevices();

    function getPermissionString(permissions: DevicePermissions) {
        switch (permissions) {
            case DevicePermissions.None:
            case DevicePermissions.Pending:
                return "Waiting for permissions...";
            case DevicePermissions.Denied:
                return "Permissions denied";
        }
    }

    return (
        <>
            <div className="cp-preferences-video">
                {getPermissionString(permissions) ?
                    <>{getPermissionString(permissions)}</> :
                    <VideoPreview className="c-settings-video" />}
            </div>
            <MediaDeviceSelector kind={DeviceKind.VideoInput} id="defaultSelect" />
            <AudioPreview />
        </>
    );
}
