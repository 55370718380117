import { AnyLocalAttachment, isUploadedAttachment } from "@/domain/attachments";
import { AudienceMember } from "@/domain/audience";
import { BackoffState } from "@/domain/backoff";
import {
    getContent_Mentions,
    SanitisedChatContent,
    sanitiseDraftContent,
} from "@/domain/chatContent";
import * as d from "@/domain/domain";
import {
    createBlankDraftContent,
    DraftChatContent,
    getDraftMentionsCount,
    getDraftTrimmedLength,
} from "@/domain/draftChatContent";
import { DraftTarget, invalidDraftTarget, type WithDraftTarget } from "@/domain/draftTarget";
import { isSquadMention } from "@/domain/mentions";
import { deepFreeze } from "@/misc/deepFreeze";
import { ExpandType, Optional } from "@/misc/types";
import { z } from "zod";
import { Provider } from "./richtext/types";

// region Official types
// These correspond to messages we have been pushed by a backend.

export enum OfficialMessageType {
    Chat,
    CallStart,
    CallEnd,
    Incompatible,
    SquadAccessGranted,
    UserAccessGranted,
    TitleChange,
    UserAccessRevoked,
    SquadAccessRevoked,
    BondCreated,
    InviteSent,
    InviteRedeemed,
}

export interface OfficialMessage {
    id: d.MessageId;
    channelId: d.ChannelId;
    serverRxTs: d.Timestamp;
    sequenceNumber: number;
    type: OfficialMessageType;
}

export const CallStartMessageContentSchema = z.object({
    callId: d.callIdSchema,
});

export const CallEndMessageContentSchema = z.object({
    callId: d.callIdSchema,
    participantIds: d.userIdSchema.array(),
});

export const SquadAccessGrantedMessageContentSchema = z.object({
    squadId: d.squadIdSchema,
    actorId: d.userIdSchema,
});

export const UserAccessGrantedMessageContentSchema = z.object({
    recipientId: d.userIdSchema,
    actorId: d.userIdSchema,
});

export const TitleChangeMessageContentSchema = z.object({
    oldTitle: z.string(),
    newTitle: z.string(),
    editorId: d.userIdSchema,
});

export const UserAccessRevokedMessageContentSchema = z.object({
    recipientId: d.userIdSchema,
    actorId: d.userIdSchema,
});

export const SquadAccessRevokedMessageContentSchema = z.object({
    squadId: d.squadIdSchema,
    actorId: d.userIdSchema,
});

export const BondCreatedMessageContentSchema = z.object({
    addedUserIds: d.userIdSchema.array(),
    addedSquadIds: d.squadIdSchema.array(),
    title: z.string(),
    actorId: d.userIdSchema,
    addedSquadUserIds: d.userIdSchema.array().optional(),
});

export const InviteSentMessageContentSchema = z.object({
    recipientEmail: z.string(),
    actorId: d.userIdSchema,
});

export const InviteRedeemedMessageContentSchema = z.object({
    inviterId: d.userIdSchema,
    commonInviteLink: z.boolean(),
    actorId: d.userIdSchema,
});

export type InviteRedeemedMessage = ExpandType<
    & OfficialMessage
    & z.infer<typeof InviteRedeemedMessageContentSchema>
    & { type: OfficialMessageType.InviteRedeemed; }
>;

export type InviteSentMessage = ExpandType<
    & OfficialMessage
    & z.infer<typeof InviteSentMessageContentSchema>
    & { type: OfficialMessageType.InviteSent; }
>;

export type BondCreatedMessage = ExpandType<
    & OfficialMessage
    & z.infer<typeof BondCreatedMessageContentSchema>
    & { type: OfficialMessageType.BondCreated; }
>;

export type SquadAccessRevokedMessage = ExpandType<
    & OfficialMessage
    & z.infer<typeof SquadAccessRevokedMessageContentSchema>
    & { type: OfficialMessageType.SquadAccessRevoked; }
>;

export type UserAccessRevokedMessage = ExpandType<
    & OfficialMessage
    & z.infer<typeof UserAccessRevokedMessageContentSchema>
    & { type: OfficialMessageType.UserAccessRevoked; }
>;

export type TitleChangeMessage = ExpandType<
    & OfficialMessage
    & z.infer<typeof TitleChangeMessageContentSchema>
    & { type: OfficialMessageType.TitleChange; }
>;

export type UserAccessGrantedMessage = ExpandType<
    & OfficialMessage
    & z.infer<typeof UserAccessGrantedMessageContentSchema>
    & { type: OfficialMessageType.UserAccessGranted; }
>;

export type SquadAccessGrantedMessage = ExpandType<
    & OfficialMessage
    & z.infer<typeof SquadAccessGrantedMessageContentSchema>
    & { type: OfficialMessageType.SquadAccessGranted; }
>;

export type CallStartMessage = ExpandType<
    & OfficialMessage
    & z.infer<typeof CallStartMessageContentSchema>
    & { type: OfficialMessageType.CallStart; }
>;

export type CallEndedMessage = ExpandType<
    & OfficialMessage
    & z.infer<typeof CallEndMessageContentSchema>
    & { type: OfficialMessageType.CallEnd; }
>;

export type OfficialChatMessage = ExpandType<
    & OfficialMessage
    & {
        type: OfficialMessageType.Chat;
        senderId: d.UserId;
        clientTxTs: d.Timestamp;
        content: SanitisedChatContent;
        callId?: d.CallId;
        // Unused - add back later.
        // editOf?: number;
        attachmentIds: d.BlobId[];
    }
>;

export type IncompatibleMessage = ExpandType<
    & OfficialMessage
    & { type: OfficialMessageType.Incompatible; }
>;

export type AnyOfficialMessage =
    | IncompatibleMessage
    | OfficialChatMessage
    | CallStartMessage
    | CallEndedMessage
    | SquadAccessGrantedMessage
    | UserAccessGrantedMessage
    | TitleChangeMessage
    | UserAccessRevokedMessage
    | SquadAccessRevokedMessage
    | BondCreatedMessage
    | InviteSentMessage
    | InviteRedeemedMessage;

// region Local types
// The backend has no idea about these messages until we try to send them.

export enum LocalMessageType {
    Draft = "draft",
    Unsent = "unsent",
    Errored = "errored",
}

type LocalChatMessage = {
    type: LocalMessageType;
    localId: d.UnsentMessageId;
    // Always store all local attachments here.
    // Selectors will get you those in the different states.
    attachmentIds: d.LocalAttachmentId[];
    // In order to avoid a flashing UI, we want to track which call we think a
    // message has been sent during. This is ignored totally when the message
    // is actually sent.
    liveCallId?: d.CallId;
} & WithDraftTarget;

export type DraftChatMessage = ExpandType<
    & LocalChatMessage
    & {
        type: LocalMessageType.Draft;
        content: DraftChatContent;
    }
>;
export type UnsentChatMessage = ExpandType<
    & LocalChatMessage
    & {
        type: LocalMessageType.Unsent;
        // A place to put the official message id given to us by the backend.
        // We want to keep using our local id until we receive the message from
        // an RPC stream.
        messageId?: d.MessageId;
        content: SanitisedChatContent;
        clientTxTs: d.Timestamp;
        backoffState?: BackoffState;
        // editOf?: d.MessageId; // Unused
        // localEditOf?: d.UnsentMessageId; // Unused
    }
>;

export type ErroredChatMessage = ExpandType<
    & LocalChatMessage
    & {
        type: LocalMessageType.Errored;
        content: SanitisedChatContent;
        clientTxTs: d.Timestamp;
        // No backoffState - being errored means we have given up.
        // editOf?: d.MessageId; // Unused
    }
>;

export type AnyUnsentLocalMessage = UnsentChatMessage | ErroredChatMessage;

export type AnyLocalMessage = DraftChatMessage | UnsentChatMessage | ErroredChatMessage;

export type AnyMessage = AnyOfficialMessage | AnyLocalMessage;

// region Helper functions

export const isUnsentMsg = (msg: Optional<AnyLocalMessage>): msg is UnsentChatMessage =>
    msg?.type === LocalMessageType.Unsent;

export const isErroredMsg = (msg: Optional<AnyLocalMessage>): msg is ErroredChatMessage =>
    msg?.type === LocalMessageType.Errored;

export const toErroredMsg = (
    { backoffState: _, messageId: __, ...msg }: UnsentChatMessage,
): ErroredChatMessage =>
    deepFreeze({
        ...msg,
        type: LocalMessageType.Errored,
    });

export const determineOfficialMessageContributors = (
    msg: Optional<AnyOfficialMessage>,
): d.UserId[] => {
    switch (msg?.type) {
        case OfficialMessageType.Chat:
            return [msg.senderId];
        case OfficialMessageType.CallEnd:
            return msg.participantIds ?? [];
        default:
            return [];
    }
};

export function emptyDraftChatMessage(
    draftTarget: DraftTarget,
    provider: Provider,
    makeClearChange?: boolean,
    lastContent?: DraftChatContent,
): DraftChatMessage {
    const content = createBlankDraftContent(provider, makeClearChange, lastContent);
    return {
        type: LocalMessageType.Draft,
        localId: content.draftId,
        draftTarget: draftTarget ?? invalidDraftTarget(),
        attachmentIds: [],
        content,
    };
}

export enum MessageSendableStatus {
    /**
     * The message in question may not be sent.
     */
    Denied = -1,

    /**
     * The message in question is ready to send.
     */
    Ready = 0,

    /**
     * The message in question has attachments that are uploading and must
     * finish doing so before the message can be sent.
     */
    Waiting = 1,

    /**
     * The string portion of the message in question is too long to be sent.
     */
    TooLong = 2,

    /**
     * The message has no recipients. (Look at bondCreation messages.)
     */
    NoRecipients = 3,
}

const statusText = {
    [MessageSendableStatus.Denied]: "Denied",
    [MessageSendableStatus.Ready]: "Send",
    [MessageSendableStatus.NoRecipients]: "Your message has no recipients",
    [MessageSendableStatus.TooLong]: "Your message is longer than the allowed maximum size",
    [MessageSendableStatus.Waiting]: "Waiting for attachments to finish uploading",
};
export const messageSendableStatusText = (status: MessageSendableStatus): string =>
    statusText[status];

/**
 * Decide if a message is sendable based on its trimmedContent and attachments
 */
export function messageIsSendable(
    draft: Optional<DraftChatMessage>,
    requireRecipients: boolean,
    attachments?: AnyLocalAttachment[],
    newBondAudience?: AudienceMember[],
): MessageSendableStatus {
    if (!draft) {
        return MessageSendableStatus.Denied;
    }

    const { attachmentIds } = draft;

    const mentionsCount = getDraftMentionsCount(draft?.content);
    const newBondAudienceCount = newBondAudience?.length ?? 0;
    const trimmedLength = getDraftTrimmedLength(draft?.content);

    if (requireRecipients && mentionsCount + newBondAudienceCount === 0) {
        return MessageSendableStatus.NoRecipients;
    }
    if (trimmedLength > 4000) {
        return MessageSendableStatus.TooLong;
    }
    if (attachments?.some(a => !isUploadedAttachment(a))) {
        return MessageSendableStatus.Waiting;
    }
    if (trimmedLength == 0 && attachmentIds.length == 0) {
        return MessageSendableStatus.Denied;
    }
    return MessageSendableStatus.Ready;
}

export function isOfficialMessage<T extends AnyMessage>(
    msg: Optional<T>,
): msg is AnyOfficialMessage & T {
    return !!msg && "id" in msg;
}

export function isOfficialChatMessage<T extends AnyMessage>(
    msg: Optional<T>,
): msg is OfficialChatMessage & T {
    return isOfficialMessage(msg) && msg.type === OfficialMessageType.Chat;
}

export function isCallEndedMessage<T extends AnyMessage>(msg?: T): msg is CallEndedMessage & T {
    return isOfficialMessage(msg) && msg.type === OfficialMessageType.CallEnd;
}

export function isCallStartMessage<T extends AnyMessage>(msg?: T): msg is CallStartMessage & T {
    return isOfficialMessage(msg) && msg.type === OfficialMessageType.CallStart;
}

export function isLocalMessage<T extends AnyMessage>(msg?: T): msg is LocalChatMessage & T {
    return !!msg && "localId" in msg;
}

export function isUnsentLocalMessage<T extends AnyMessage>(
    msg?: T,
): msg is AnyUnsentLocalMessage & T {
    switch (msg?.type) {
        case LocalMessageType.Unsent:
        case LocalMessageType.Errored:
            return true;
        default:
            return false;
    }
}

export function haveSameMessageType(a: AnyMessage, b: AnyMessage): boolean {
    if (isOfficialMessage(a) && isOfficialMessage(b)) return a.type === b.type;
    const aLocal = isLocalMessage(a);
    const bLocal = isLocalMessage(b);
    if (aLocal && bLocal) return true;
    return (isOfficialChatMessage(a) && bLocal) || (aLocal && isOfficialChatMessage(b));
}

export function haveSameSender(a: AnyMessage, b: AnyMessage, ourUserId?: d.UserId): boolean {
    if (isLocalMessage(a)) {
        return isLocalMessage(b) || (isOfficialChatMessage(b) && b.senderId === ourUserId);
    }
    else if (isLocalMessage(b)) {
        return isOfficialChatMessage(a) && a.senderId === ourUserId;
    }
    else {
        return isOfficialChatMessage(a) && isOfficialChatMessage(b) && a.senderId === b.senderId;
    }
}

export function getSenderId(msg: Optional<AnyMessage>, ourUserId?: d.UserId): Optional<d.UserId> {
    if (isOfficialChatMessage(msg)) return msg.senderId;
    if (isUnsentLocalMessage(msg)) return ourUserId;
}

export function getCallId(msg: Optional<AnyMessage>): Optional<d.CallId> {
    if (!msg) return;
    if (isOfficialChatMessage(msg)) return msg.callId;
    if (isLocalMessage(msg)) return msg.liveCallId;
    if (isCallStartMessage(msg)) return msg.callId;
    if (isCallEndedMessage(msg)) return msg.callId;
}

export const convertDraftToUnsentMessage = (
    draft: DraftChatMessage,
    now: d.Timestamp,
): UnsentChatMessage => {
    const sanitisedContent = sanitiseDraftContent(draft.content);
    return {
        ...draft,
        localId: sanitisedContent.id ?? "unknown",
        type: LocalMessageType.Unsent,
        content: sanitisedContent,
        clientTxTs: now,
    };
};

export function messageMentionsAny(
    msg: AnyOfficialMessage,
    userIds?: d.UserId[],
    squadIds?: d.SquadId[],
): boolean {
    if (!isOfficialChatMessage(msg)) return false;
    if ((userIds ?? []).length + (squadIds ?? []).length == 0) return false;

    const mentions = getContent_Mentions(msg.content);
    if (!mentions || mentions.length === 0) return false;

    return mentions.some(m =>
        isSquadMention(m) ?
            squadIds?.includes(m.target) :
            userIds?.includes(m.target)
    );
}

export const getAttachmentMsgId = (msg: AnyMessage): d.AttachmentMessageId =>
    isLocalMessage(msg) ? { localId: msg.localId } : { id: msg.id };

export const getMsgSequenceNumber = (msg?: AnyMessage): Optional<number> => {
    if (isOfficialMessage(msg)) return msg.sequenceNumber;
};

export const getMsgChannelId = (msg?: AnyMessage): Optional<d.ChannelId> => {
    if (isOfficialMessage(msg)) return msg.channelId;
};

export const getMsgAttachmentIds = (msg?: AnyMessage): d.BlobId[] | d.LocalAttachmentId[] => {
    const noAttachments = !isLocalMessage(msg) && !isOfficialChatMessage(msg);
    return noAttachments ? [] : msg.attachmentIds;
};

export const getMsgTs = (msg?: AnyMessage): Optional<Date> => {
    if (!msg) return;
    if (isOfficialMessage(msg)) return new Date(msg.serverRxTs);
    if (msg.type !== LocalMessageType.Draft) return new Date(msg.clientTxTs);
};

// The context in which a message was sent
// e.g. this message was sent during a live call
export enum MessageCallContext {
    // Not sent during a call
    None,
    // Sent during a call which has since ended
    EndedCall,
    // Sent during a call which is live
    LiveCall,
}

/** Get the status of the call the message was sent in, returning None if it was
 * not sent in a call.
 */
export function getMessageCallStatus(
    msgCallId: Optional<d.CallId>,
    currentCallId: Optional<d.CallId>,
): MessageCallContext {
    if (!msgCallId) {
        return MessageCallContext.None;
    }
    if (currentCallId === msgCallId) {
        return MessageCallContext.LiveCall;
    }

    return MessageCallContext.EndedCall;
}
