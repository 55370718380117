import { App, URLOpenListenerEvent } from "@capacitor/app";
import { Clipboard } from "@capacitor/clipboard";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { To } from "react-router-dom";

import { FrontendPlatformType } from "../../gen/proto/domain/domain_pb";

import log from "@/misc/log";
import { getRouter } from "@/misc/router";
import { nativeTargets } from "./environment";

export const isNativePlatform = Capacitor.isNativePlatform;
export const isNativeAndroidPlatform = () => Capacitor.getPlatform() === "android";
export const isNativeIosPlatform = () => Capacitor.getPlatform() === "ios";
// isNativeAndroidPlatform || isNativeIosPlatform <=> isNativePlatform

export const getFrontendPlatform = () => {
    let frontendPlatform = FrontendPlatformType.UNSPECIFIED;
    if (!isNativePlatform()) {
        frontendPlatform = FrontendPlatformType.BROWSER;
    }
    else {
        if (isNativeAndroidPlatform()) {
            frontendPlatform = FrontendPlatformType.ANDROID;
        }
        if (isNativeIosPlatform()) {
            frontendPlatform = FrontendPlatformType.IOS;
        }
    }

    return frontendPlatform;
};

export const nativePlatformTargetKey = "nativePlatformTarget";
export const getNativeTarget = () => {
    if (!isNativePlatform()) {
        return undefined;
    }

    return localStorage.getItem(nativePlatformTargetKey);
};

const nativeTailscaleHostKey = "nativeTailscaleHost";
export const getNativeTailscaleHost = () => {
    if (!isNativePlatform()) {
        return undefined;
    }

    return localStorage.getItem(nativeTailscaleHostKey);
};
export const setNativeTailscaleHost = (host: string) => {
    if (!isNativePlatform()) {
        return;
    }

    localStorage.setItem(nativeTailscaleHostKey, host);
};

const getNativeUrlPrefix = () => {
    switch (getNativeTarget()) {
        case nativeTargets.bondDev:
            return "https://bo-nd.dev";
        case nativeTargets.bondtestUk:
            return "https://bondtest.uk";
        case nativeTargets.tailscale:
            // We can't register the Tailscale domain in the app,
            // so to let us open Tailscale links in the app, treat any bo-nd.dev
            // link as if they were Tailscale.
            // e.g. https://bo-nd.dev/invite/tailscalecode -> https://tailscale.blah/invite/tailscalecode
            return "https://bo-nd.dev";
        default:
            return "https://bo-nd.dev";
    }
};

export const capacitorSetup = () => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
        log.info("Native client redirecting to: ", event);

        const urlPrefix = getNativeUrlPrefix();
        const inboxUrlPrefix = urlPrefix + "/bond";
        const bondUrlPrefix = urlPrefix + "/bond/";
        const inviteUrlPrefix = urlPrefix + "/invite";

        const router = getRouter();
        const routeTo = (route: To) => {
            log.info(`route to: ${route}`);
            router.navigate(route, { replace: true });
        };

        if (event.url.startsWith(bondUrlPrefix)) {
            const slug = event.url.replace(bondUrlPrefix, "/bond/");
            if (slug) {
                routeTo(slug);
            }
        }
        else if (event.url.startsWith(inboxUrlPrefix)) {
            const slug = event.url.replace(inboxUrlPrefix, "/mobile/tab/mybonds");
            if (slug) {
                routeTo(slug);
            }
        }
        else if (event.url.startsWith(inviteUrlPrefix)) {
            const slug = event.url.replace(inviteUrlPrefix, "/invite");
            if (slug) {
                routeTo(slug);
            }
        }
        else {
            // Default to the MyBonds tab view
            log.error("Unknown native client redirect:", event);
            routeTo("/mobile/tab/mybonds");
        }
    });
};

export const copyToClipboard = async (string: string) => {
    if (isNativePlatform()) {
        await Clipboard.write({ string }).catch(() => {
            throw new Error("Failed to copy string to native clipboard.");
        });
    }
    else {
        await navigator.clipboard.writeText(string).catch(() => {
            throw new Error("Failed to copy string to clipboard.");
        });
    }
};

const androidDeviceTagPrefix = "android:";
const iosDeviceTagPrefix = "ios:";
export const getNativeDeviceTag = async (): Promise<string> => {
    const deviceId = await Device.getId();
    const deviceTag = deviceId.identifier;

    let prefix: string;
    if (isNativeAndroidPlatform()) {
        prefix = androidDeviceTagPrefix;
    }
    else if (isNativeIosPlatform()) {
        prefix = iosDeviceTagPrefix;
    }
    else {
        throw new Error("Unexpected native platform.");
    }

    return prefix + deviceTag;
};
