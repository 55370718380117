import { useCallback } from "react";
import { NavigateOptions, useNavigate } from "react-router-dom";

import { AudienceMember } from "@/domain/audience";
import * as d from "@/domain/domain";
import { DraftTarget } from "@/domain/draftTarget";
import {
    AppView,
    AppViewStack,
    viewStackSchema,
    viewStackUtils,
    viewToPath,
    viewUtils,
} from "@/domain/views";
import {
    popViewStack,
    pushViewStack,
    replaceAndSwitchViewStack,
    selectCurrentViewStack,
} from "@/features/filterPanel";
import { chooseMostRelevantViewForNewBond } from "@/features/views";
import log from "@/misc/log";
import { Optional } from "@/misc/types";
import { useAppDispatch, useAppSelector } from "@/store/redux";

type NavigateToBondArgs = {
    id: d.BondId;
    draftTarget?: DraftTarget;
    audience?: AudienceMember[];
};

export type ViewStackNavigateFns = {
    /** Navigate to a given view. Push the view onto the current viewstack. */
    navigatePush: (view: AppView, options?: NavigateOptions) => void;
    /** Navigate up one level in the hierarchy. Pop the current view off the
     * viewstack. */
    navigatePop: (options?: NavigateOptions) => void;
    /** Navigate to the view at the head of the viewstack. Replace the viewstack. */
    navigateReplace: (stack: AppViewStack, options?: NavigateOptions) => void;
    /** Navigate to the given bond. Choose the most appropriate viewstack to
     * switch to. */
    navigateToBond: (args: NavigateToBondArgs, options?: NavigateOptions) => void;
    /** Navigate down the hierarchy to below any settings views. Remove all
     * settings menus/modals from the viewstack. */
    navigateRemoveSettingsModals: (options?: NavigateOptions) => void;
};

export default function useViewStackNavigate(): ViewStackNavigateFns {
    const navigateRR = useNavigate();
    const dispatch = useAppDispatch();

    const currentViewStack = useAppSelector(selectCurrentViewStack);

    const viewStackNavigate = useCallback(
        (path: string, stack: AppViewStack, options: Optional<NavigateOptions>) => {
            navigateRR(path, { ...options, state: { ...options?.state, viewStack: stack } });
        },
        [navigateRR],
    );

    const navigatePush = useCallback((view: AppView, options?: NavigateOptions) => {
        const newStack = [...currentViewStack, view];
        const p = viewStackSchema.safeParse(newStack);
        if (!p.success) {
            log.warn(`Invalid stack for push`, newStack);
            return;
        }

        const path = viewToPath(view);
        if (!path) {
            log.warn(`Cannot navigate to empty path`, newStack);
            return;
        }

        dispatch(pushViewStack(view));
        viewStackNavigate(path, p.data, options);
    }, [viewStackNavigate, currentViewStack, dispatch]);

    const navigatePop = useCallback((options?: NavigateOptions) => {
        if (currentViewStack.length <= 1) {
            log.warn(
                `Ignoring request to navigate "back" to the same view`,
                currentViewStack,
            );
            return;
        }

        const targetView = currentViewStack.at(-2);
        const path = viewToPath(targetView);
        if (!path) {
            log.warn(`Cannot navigate back to empty path`, targetView);
            return;
        }

        dispatch(popViewStack());
        const newStack = viewStackSchema.parse(currentViewStack.slice(0, -1));
        viewStackNavigate(path, newStack, options);
    }, [viewStackNavigate, currentViewStack, dispatch]);

    const navigateReplace = useCallback((stack: AppViewStack, options?: NavigateOptions) => {
        const p = viewStackSchema.safeParse(stack);
        if (!p.success) {
            log.warn(`Cannot navigate to invalid stack`, stack);
            return;
        }

        const targetView = p.data.at(-1);
        const path = viewToPath(targetView);
        if (!path) {
            log.warn(`Cannot navigate back to empty path`, targetView);
            return;
        }

        dispatch(replaceAndSwitchViewStack(p.data));
        viewStackNavigate(path, p.data, options);
    }, [viewStackNavigate, dispatch]);

    const navigateToBond = useCallback(
        ({ draftTarget, audience, id }: NavigateToBondArgs, options?: NavigateOptions) => {
            const targetView = chooseMostRelevantViewForNewBond(draftTarget, audience);
            const intendedStack = viewStackUtils.defaultStackForView(targetView);
            const fallbackStack = viewStackUtils.fallbackViewForBond(id);

            if (!intendedStack) {
                log.warn("No sensible viewstack for new bond", { target: targetView });
            }

            const stack =
                viewStackSchema.safeParse([...intendedStack ?? [], viewUtils.singleBond(id)]).data
                    ?? fallbackStack;

            const bondView = stack.at(-1);
            const path = viewToPath(bondView);
            if (!path) {
                log.warn(`Cannot navigate to bond via an empty path`, bondView);
                return;
            }

            dispatch(replaceAndSwitchViewStack(stack));
            viewStackNavigate(path, stack, options);
        },
        [viewStackNavigate, dispatch],
    );

    const navigateRemoveSettingsModals = useCallback((options?: NavigateOptions) => {
        if (currentViewStack.length <= 1 || !viewUtils.hasSettingsView(currentViewStack)) {
            log.warn(`Ignoring request to remove settings modals`, currentViewStack);
            return;
        }

        // Pop from the stuck until it contains no settings views
        let newStack = currentViewStack;
        while (viewUtils.hasSettingsView(newStack)) {
            newStack = viewStackSchema.parse(newStack.slice(0, -1));
        }

        const targetView = newStack.at(-1);
        const path = viewToPath(targetView);
        if (!path) {
            log.warn(`Cannot navigate back to empty path`, targetView);
            return;
        }

        dispatch(replaceAndSwitchViewStack(newStack));
        viewStackNavigate(path, newStack, options);
    }, [dispatch, viewStackNavigate, currentViewStack]);

    return {
        navigatePush,
        navigatePop,
        navigateReplace,
        navigateToBond,
        navigateRemoveSettingsModals,
    };
}
