import { UnknownAction } from "@reduxjs/toolkit";

import * as d from "@/domain/domain";
import { getLocalPreferredIds } from "@/features/auth";
import { dispatchThunkResponse } from "@/features/proxiedThunk";
import { isNativePlatform } from "@/misc/capacitor";
import { getDeviceTag } from "@/misc/device";
import log from "@/misc/log";
import { delay, promiseWithResolvers } from "@/misc/promises";
import { getHydrationData } from "@/persist/persist";
import {
    isProxiedResponse,
    listenForProxiedDispatches,
    type ProxiedMessage,
    ProxiedMessageDirection,
} from "@/store/proxy";
import { setupStore, storeContextToDefaultConfig } from "@/store/setup";
import { type AppStore, localSliceSchema } from "@/store/types";
import Overseer from "@/workers/overseer.ts?sharedworker";
import { workerInitRequest, type WorkerInitResponse } from "@/workers/types";
import { isSharedWorker, sharedWorkerAvailable, tabIdToLockName } from "@/workers/util";

export const usingOverseer = () => sharedWorkerAvailable();

const takeTabIdLock = async ({ id, port }: WorkerInitResponse["tabLock"]) => {
    const pwr = promiseWithResolvers();

    const err = new Error("timeout");
    const ac = new AbortController();
    delay(100).then(_ => ac.abort(err));

    try {
        await navigator.locks.request(
            tabIdToLockName(id),
            { signal: ac.signal },
            async function tabLockCb(lock) {
                if (!lock) {
                    log.warn(`Failed to take tab lock ${id}, reloading page`);
                    await delay(1000);
                    window.location.reload();
                }
                else {
                    log.info(`Took tab lock ${id}`);
                    port.postMessage(id);
                    port.close();
                    // Keep the lock forever.
                    return pwr.promise;
                }
            },
        );
    }
    catch (e) {
        if (e === err) {
            log.warn(`Timed out taking tab lock ${id}, assuming page duplicated, reloading page`);
            window.location.reload();
        }
        else {
            log.info(`Failed to take tab lock ${id}:`, e);
        }
    }
};

const spawnWorker = async (userId: d.UserId, deviceTag: string): Promise<AppStore> => {
    if (isSharedWorker()) {
        throw new Error(`Cannot spawn workers from within a SharedWorker`);
    }

    const worker = new Overseer({ name: `overseer-${userId}` });

    const statePromise = promiseWithResolvers<WorkerInitResponse["payload"]>();

    worker.port.addEventListener(
        "message",
        ({ data: { payload, tabLock } }: MessageEvent<WorkerInitResponse>) => {
            if (tabLock) {
                takeTabIdLock(tabLock);
                payload.state.meta.tabId = tabLock.id;
            }
            statePromise.resolve(payload);
        },
        { once: true },
    );

    worker.port.start();

    // Capture all messages broadcast by the overseer.
    // When we receive the full `state`, we will apply any
    // actions that have been broadcast since the serial number
    // provided to us at the same time as the `state`.
    const q: [number, UnknownAction][] = [];

    const onMessage = (msg: ProxiedMessage) => {
        if (!isProxiedResponse(msg)) return;

        const { seqNo, action } = msg;

        q.push([seqNo, action]);
    };

    const stopListening = listenForProxiedDispatches(
        userId,
        ProxiedMessageDirection.Broadcast,
        onMessage,
    );

    // Open our connection to the shared worker.
    worker.port.postMessage(workerInitRequest({ userId, deviceTag }));

    const [{ state, readFromSeqNo }, fullLocalStore] = await Promise.all(
        [
            statePromise.promise,
            getHydrationData(undefined, true),
        ],
    );

    // Replace all data in overseer state that lives in a "local" slice
    const mergedState = Object.entries(fullLocalStore).reduce((s, [k, v]) => {
        const p = localSliceSchema.safeParse(k);
        if (p.success) {
            // Don't think this is possible without casting
            (s as any)[p.data] = v;
        }
        return s;
    }, { ...state });

    const store = setupStore(mergedState, {
        ...storeContextToDefaultConfig("proxyTab"),
        dispatchThunkResponse,
        useSharedWorkerWatchdog: true,
    });

    const i = q.findIndex(([seqNo, _]) => seqNo === readFromSeqNo);
    q.slice(i).map(([_, action]) => action).forEach(store.dispatch);
    // This is a Javascript-wut API, but we want to clear out references to
    // actions that we no longer need references to, without redefining `q`.
    q.length = 0;

    stopListening();

    return store;
};

/** Create a store that will *not* proxy to an overseer.
 *
 * This is currently only for the "shared worker unavailable" case.
 */
const fetchLocalStore = async (userId: d.UserId): Promise<AppStore> => {
    const reason = isNativePlatform() ? "native platform" : "no shared worker";
    log.info(`Running store locally as ${reason}`);
    const data = await getHydrationData(userId, true);
    // TODO: implement tab leadership contest, winning tab becomes
    // the "overseer" in spirit, and persists, manages connection, etc.
    return setupStore(data, storeContextToDefaultConfig("soloTab"));
};

/** Get a handle to a working store.
 *
 * If shared workers are available, this means a store that's up-to-date with
 * the overseer, and automatically applying updates broadcast from the overseer.
 *
 * If shared workers aren't available, that means a store hydrated with data
 * from IndexedDB, and that is managing its own connection.
 */
export const startStore = async (): Promise<AppStore> => {
    const ids = getLocalPreferredIds();

    const deviceTag = await getDeviceTag();

    if (!ids?.userId) {
        log.info(`Running store locally as no stored userId`);
        // Don't start listeners - there's nothing for them to do.
        return setupStore({}, {
            manageConnection: true,
            manageAuth: true,
            useSharedWorkerWatchdog: true,
        });
    }

    if (!usingOverseer()) return await fetchLocalStore(ids.userId);

    return await spawnWorker(ids.userId, deviceTag);
};
