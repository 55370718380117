import { providerFromString } from "@/domain/richtext/types";
import { setRichTextProvider } from "@/features/channels";
import useStringFeatureFlag from "@/hooks/useStringFeatureFlag";
import log from "@/misc/log";
import { useAppDispatch } from "@/store/redux";
import { useEffect } from "react";

/**
 * Listen to feature flag changes and dispatch setRichTextProvider when the
 * rich text provider feature flag variant is updated
 */
export const RichTextProviderManager = (): React.JSX.Element => {
    const dispatch = useAppDispatch();

    const richTextProvider = useStringFeatureFlag("rich-text-composer-provider");
    useEffect(() => {
        log.info(`Rich text provider set to ${richTextProvider}`);
        dispatch(setRichTextProvider(providerFromString(richTextProvider)));
    }, [dispatch, richTextProvider]);

    return <></>;
};

export default RichTextProviderManager;
