import { configureStore, nanoid } from "@reduxjs/toolkit";

import { setSharedWorkerWatchdogLock } from "@/features/meta";
import log from "./log";
import { promiseWithResolvers } from "./promises";

export function startSharedWorkerWatchdog(store: ReturnType<typeof configureStore>) {
    const lockName = `shared-worker-watchdog:${nanoid()}`;
    const pwr = promiseWithResolvers<void>();

    navigator.locks.request(
        lockName,
        async () => {
            store.dispatch(setSharedWorkerWatchdogLock(lockName));

            // Keep the lock forever to prevent the watchdog from being triggered unless
            // the shared worker is killed.
            await pwr.promise;
        },
    ).catch(log.error);

    return pwr.resolve;
}
