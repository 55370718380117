// @generated by protoc-gen-es v1.4.2 with parameter "target=js+ts"
// @generated from file squads/squads.proto (package squads, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { CrewId, CrewSet, InviteBody, Invitee, OrgId, OrgSet, PersonId, PersonSet, PresenceMode, SquadId, SquadSet, UserDefinition, UserId, UserSet } from "../domain/domain_pb.js";
import { RegisterActivityCore, UserObservation } from "../devices/devices_pb.js";

/**
 * @generated from message squads.OrgOverview
 */
export const OrgOverview = proto3.makeMessageType(
  "squads.OrgOverview",
  () => [
    { no: 1, name: "id", kind: "message", T: OrgId },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "is_personal", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message squads.SquadOverview
 */
export const SquadOverview = proto3.makeMessageType(
  "squads.SquadOverview",
  () => [
    { no: 1, name: "id", kind: "message", T: SquadId },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "user_ids", kind: "message", T: UserSet },
    { no: 4, name: "org_id", kind: "message", T: OrgId },
    { no: 5, name: "invited_persons", kind: "message", T: PersonSet },
    { no: 6, name: "invited_emails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message squads.SquadActivity
 */
export const SquadActivity = proto3.makeMessageType(
  "squads.SquadActivity",
  () => [
    { no: 1, name: "squad_id", kind: "message", T: SquadId },
    { no: 2, name: "latest_activity_at", kind: "message", T: Timestamp },
    { no: 3, name: "dominant_presence", kind: "enum", T: proto3.getEnumType(PresenceMode) },
  ],
);

/**
 * @generated from message squads.CrewOverview
 */
export const CrewOverview = proto3.makeMessageType(
  "squads.CrewOverview",
  () => [
    { no: 1, name: "id", kind: "message", T: CrewId },
    { no: 2, name: "squad_ids", kind: "message", T: SquadSet },
    { no: 3, name: "user_ids", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message squads.SquadInvite
 */
export const SquadInvite = proto3.makeMessageType(
  "squads.SquadInvite",
  () => [
    { no: 1, name: "squad_id", kind: "message", T: SquadId },
    { no: 2, name: "body", kind: "message", T: InviteBody },
  ],
);

/**
 * @generated from message squads.CreateOrgRequest
 */
export const CreateOrgRequest = proto3.makeMessageType(
  "squads.CreateOrgRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "initial_person_id", kind: "message", T: PersonId },
  ],
);

/**
 * @generated from message squads.CreateOrgResponse
 */
export const CreateOrgResponse = proto3.makeMessageType(
  "squads.CreateOrgResponse",
  () => [
    { no: 1, name: "new_org_id", kind: "message", T: OrgId },
    { no: 2, name: "initial_user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message squads.CreateSquadRequest
 */
export const CreateSquadRequest = proto3.makeMessageType(
  "squads.CreateSquadRequest",
  () => [
    { no: 1, name: "org_id", kind: "message", T: OrgId },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "initial_user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message squads.CreateSquadResponse
 */
export const CreateSquadResponse = proto3.makeMessageType(
  "squads.CreateSquadResponse",
  () => [
    { no: 1, name: "new_squad_id", kind: "message", T: SquadId },
  ],
);

/**
 * @generated from message squads.AddSquadMemberRequest
 */
export const AddSquadMemberRequest = proto3.makeMessageType(
  "squads.AddSquadMemberRequest",
  () => [
    { no: 1, name: "squad_id", kind: "message", T: SquadId },
    { no: 2, name: "user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message squads.AddSquadMemberResponse
 */
export const AddSquadMemberResponse = proto3.makeMessageType(
  "squads.AddSquadMemberResponse",
  [],
);

/**
 * @generated from message squads.RemoveSquadMemberRequest
 */
export const RemoveSquadMemberRequest = proto3.makeMessageType(
  "squads.RemoveSquadMemberRequest",
  () => [
    { no: 1, name: "squad_id", kind: "message", T: SquadId },
    { no: 2, name: "user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message squads.RemoveSquadMemberResponse
 */
export const RemoveSquadMemberResponse = proto3.makeMessageType(
  "squads.RemoveSquadMemberResponse",
  [],
);

/**
 * @generated from message squads.SetSquadLastReadRequest
 */
export const SetSquadLastReadRequest = proto3.makeMessageType(
  "squads.SetSquadLastReadRequest",
  () => [
    { no: 1, name: "squad_ids", kind: "message", T: SquadSet },
    { no: 2, name: "user_id", kind: "message", T: UserId },
    { no: 3, name: "last_read", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message squads.SetSquadLastReadResponse
 */
export const SetSquadLastReadResponse = proto3.makeMessageType(
  "squads.SetSquadLastReadResponse",
  [],
);

/**
 * @generated from message squads.RenameSquadRequest
 */
export const RenameSquadRequest = proto3.makeMessageType(
  "squads.RenameSquadRequest",
  () => [
    { no: 1, name: "squad_id", kind: "message", T: SquadId },
    { no: 2, name: "new_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message squads.RenameSquadResponse
 */
export const RenameSquadResponse = proto3.makeMessageType(
  "squads.RenameSquadResponse",
  [],
);

/**
 * @generated from message squads.CreateUserRequest
 */
export const CreateUserRequest = proto3.makeMessageType(
  "squads.CreateUserRequest",
  () => [
    { no: 1, name: "org_id", kind: "message", T: OrgId },
    { no: 2, name: "person_id", kind: "message", T: PersonId },
  ],
);

/**
 * @generated from message squads.CreateUserResponse
 */
export const CreateUserResponse = proto3.makeMessageType(
  "squads.CreateUserResponse",
  () => [
    { no: 1, name: "new_user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message squads.ListOrgsRequest
 */
export const ListOrgsRequest = proto3.makeMessageType(
  "squads.ListOrgsRequest",
  [],
);

/**
 * @generated from message squads.ListOrgsResponse
 */
export const ListOrgsResponse = proto3.makeMessageType(
  "squads.ListOrgsResponse",
  () => [
    { no: 1, name: "org_ids", kind: "message", T: OrgId, repeated: true },
  ],
);

/**
 * @generated from message squads.GetOrgsRequest
 */
export const GetOrgsRequest = proto3.makeMessageType(
  "squads.GetOrgsRequest",
  () => [
    { no: 1, name: "org_ids", kind: "message", T: OrgId, repeated: true },
  ],
);

/**
 * @generated from message squads.GetOrgsResponse
 */
export const GetOrgsResponse = proto3.makeMessageType(
  "squads.GetOrgsResponse",
  () => [
    { no: 1, name: "orgs", kind: "message", T: OrgOverview, repeated: true },
  ],
);

/**
 * @generated from message squads.ListSquadsRequest
 */
export const ListSquadsRequest = proto3.makeMessageType(
  "squads.ListSquadsRequest",
  () => [
    { no: 1, name: "org_id", kind: "message", T: OrgId },
  ],
);

/**
 * @generated from message squads.ListSquadsResponse
 */
export const ListSquadsResponse = proto3.makeMessageType(
  "squads.ListSquadsResponse",
  () => [
    { no: 1, name: "squad_ids", kind: "message", T: SquadId, repeated: true },
  ],
);

/**
 * @generated from message squads.GetSquadsRequest
 */
export const GetSquadsRequest = proto3.makeMessageType(
  "squads.GetSquadsRequest",
  () => [
    { no: 1, name: "squad_ids", kind: "message", T: SquadId, repeated: true },
    { no: 2, name: "squads", kind: "message", T: SquadSet },
  ],
);

/**
 * @generated from message squads.GetSquadsResponse
 */
export const GetSquadsResponse = proto3.makeMessageType(
  "squads.GetSquadsResponse",
  () => [
    { no: 1, name: "squads", kind: "message", T: SquadOverview, repeated: true },
  ],
);

/**
 * @generated from message squads.GetUserDefinitionRequest
 */
export const GetUserDefinitionRequest = proto3.makeMessageType(
  "squads.GetUserDefinitionRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message squads.GetUserDefinitionResponse
 */
export const GetUserDefinitionResponse = proto3.makeMessageType(
  "squads.GetUserDefinitionResponse",
  () => [
    { no: 1, name: "ids", kind: "message", T: UserDefinition },
  ],
);

/**
 * @generated from message squads.ListOrgMembersRequest
 */
export const ListOrgMembersRequest = proto3.makeMessageType(
  "squads.ListOrgMembersRequest",
  () => [
    { no: 1, name: "org_id", kind: "message", T: OrgId },
  ],
);

/**
 * @generated from message squads.ListOrgMembersResponse
 */
export const ListOrgMembersResponse = proto3.makeMessageType(
  "squads.ListOrgMembersResponse",
  () => [
    { no: 1, name: "user_ids", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message squads.ListSquadMembersRequest
 */
export const ListSquadMembersRequest = proto3.makeMessageType(
  "squads.ListSquadMembersRequest",
  () => [
    { no: 2, name: "squad_ids", kind: "message", T: SquadSet },
  ],
);

/**
 * @generated from message squads.ListSquadMembersResponse
 */
export const ListSquadMembersResponse = proto3.makeMessageType(
  "squads.ListSquadMembersResponse",
  () => [
    { no: 1, name: "user_ids", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message squads.ListUsersByPersonRequest
 */
export const ListUsersByPersonRequest = proto3.makeMessageType(
  "squads.ListUsersByPersonRequest",
  () => [
    { no: 1, name: "person_id", kind: "message", T: PersonId },
  ],
);

/**
 * @generated from message squads.ListUsersByPersonResponse
 */
export const ListUsersByPersonResponse = proto3.makeMessageType(
  "squads.ListUsersByPersonResponse",
  () => [
    { no: 1, name: "person_id", kind: "message", T: PersonId },
    { no: 2, name: "user_ids", kind: "message", T: UserId, repeated: true },
    { no: 3, name: "user_definitions", kind: "message", T: UserDefinition, repeated: true },
  ],
);

/**
 * @generated from message squads.ListUserSquadsRequest
 */
export const ListUserSquadsRequest = proto3.makeMessageType(
  "squads.ListUserSquadsRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message squads.ListUserSquadsResponse
 */
export const ListUserSquadsResponse = proto3.makeMessageType(
  "squads.ListUserSquadsResponse",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 3, name: "squad_ids", kind: "message", T: SquadId, repeated: true },
  ],
);

/**
 * @generated from message squads.ListPersonOrgsRequest
 */
export const ListPersonOrgsRequest = proto3.makeMessageType(
  "squads.ListPersonOrgsRequest",
  () => [
    { no: 1, name: "person_id", kind: "message", T: PersonId },
  ],
);

/**
 * @generated from message squads.ListPersonOrgsResponse
 */
export const ListPersonOrgsResponse = proto3.makeMessageType(
  "squads.ListPersonOrgsResponse",
  () => [
    { no: 1, name: "person_id", kind: "message", T: PersonId },
    { no: 2, name: "org_ids", kind: "message", T: OrgId, repeated: true },
  ],
);

/**
 * @generated from message squads.ListSquadmatesRequest
 */
export const ListSquadmatesRequest = proto3.makeMessageType(
  "squads.ListSquadmatesRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message squads.ListSquadmatesResponse
 */
export const ListSquadmatesResponse = proto3.makeMessageType(
  "squads.ListSquadmatesResponse",
  () => [
    { no: 1, name: "user_ids", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message squads.CreateCrewRequest
 */
export const CreateCrewRequest = proto3.makeMessageType(
  "squads.CreateCrewRequest",
  () => [
    { no: 1, name: "org_id", kind: "message", T: OrgId },
    { no: 2, name: "squad_ids", kind: "message", T: SquadId, repeated: true },
    { no: 4, name: "squads", kind: "message", T: SquadSet },
    { no: 3, name: "users", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message squads.CreateCrewResponse
 */
export const CreateCrewResponse = proto3.makeMessageType(
  "squads.CreateCrewResponse",
  () => [
    { no: 1, name: "new_crew_id", kind: "message", T: CrewId },
  ],
);

/**
 * @generated from message squads.ListCrewMembersRequest
 */
export const ListCrewMembersRequest = proto3.makeMessageType(
  "squads.ListCrewMembersRequest",
  () => [
    { no: 1, name: "crew_id", kind: "message", T: CrewId },
  ],
);

/**
 * @generated from message squads.ListCrewMembersResponse
 */
export const ListCrewMembersResponse = proto3.makeMessageType(
  "squads.ListCrewMembersResponse",
  () => [
    { no: 1, name: "squads", kind: "message", T: SquadSet },
    { no: 2, name: "users", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message squads.AddSquadsToCrewRequest
 */
export const AddSquadsToCrewRequest = proto3.makeMessageType(
  "squads.AddSquadsToCrewRequest",
  () => [
    { no: 1, name: "crew_id", kind: "message", T: CrewId },
    { no: 2, name: "squads", kind: "message", T: SquadSet },
  ],
);

/**
 * @generated from message squads.AddSquadsToCrewResponse
 */
export const AddSquadsToCrewResponse = proto3.makeMessageType(
  "squads.AddSquadsToCrewResponse",
  [],
);

/**
 * @generated from message squads.RemoveSquadsFromCrewRequest
 */
export const RemoveSquadsFromCrewRequest = proto3.makeMessageType(
  "squads.RemoveSquadsFromCrewRequest",
  () => [
    { no: 1, name: "crew_id", kind: "message", T: CrewId },
    { no: 2, name: "squads", kind: "message", T: SquadSet },
  ],
);

/**
 * @generated from message squads.RemoveSquadsFromCrewResponse
 */
export const RemoveSquadsFromCrewResponse = proto3.makeMessageType(
  "squads.RemoveSquadsFromCrewResponse",
  [],
);

/**
 * @generated from message squads.AddUsersToCrewRequest
 */
export const AddUsersToCrewRequest = proto3.makeMessageType(
  "squads.AddUsersToCrewRequest",
  () => [
    { no: 1, name: "crew_id", kind: "message", T: CrewId },
    { no: 2, name: "users", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message squads.AddUsersToCrewResponse
 */
export const AddUsersToCrewResponse = proto3.makeMessageType(
  "squads.AddUsersToCrewResponse",
  [],
);

/**
 * @generated from message squads.RemoveUsersFromCrewRequest
 */
export const RemoveUsersFromCrewRequest = proto3.makeMessageType(
  "squads.RemoveUsersFromCrewRequest",
  () => [
    { no: 1, name: "crew_id", kind: "message", T: CrewId },
    { no: 2, name: "users", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message squads.RemoveUsersFromCrewResponse
 */
export const RemoveUsersFromCrewResponse = proto3.makeMessageType(
  "squads.RemoveUsersFromCrewResponse",
  [],
);

/**
 * @generated from message squads.ObserveSquadRequest
 */
export const ObserveSquadRequest = proto3.makeMessageType(
  "squads.ObserveSquadRequest",
  () => [
    { no: 1, name: "squad_id", kind: "message", T: SquadId },
    { no: 2, name: "user_id", kind: "message", T: UserId },
    { no: 3, name: "core", kind: "message", T: RegisterActivityCore },
    { no: 4, name: "stream_correlation_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "observe", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message squads.ObserveSquadResponse
 */
export const ObserveSquadResponse = proto3.makeMessageType(
  "squads.ObserveSquadResponse",
  [],
);

/**
 * @generated from message squads.CreateSquadInviteRequest
 */
export const CreateSquadInviteRequest = proto3.makeMessageType(
  "squads.CreateSquadInviteRequest",
  () => [
    { no: 1, name: "squad_id", kind: "message", T: SquadId },
    { no: 2, name: "originator_id", kind: "message", T: UserId },
    { no: 3, name: "expires_at", kind: "message", T: Timestamp },
    { no: 4, name: "invitee", kind: "message", T: Invitee, oneof: "target" },
    { no: 5, name: "is_common", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "target" },
  ],
);

/**
 * @generated from message squads.CreateSquadInviteResponse
 */
export const CreateSquadInviteResponse = proto3.makeMessageType(
  "squads.CreateSquadInviteResponse",
  () => [
    { no: 1, name: "opaque_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message squads.FindSquadInviteRequest
 */
export const FindSquadInviteRequest = proto3.makeMessageType(
  "squads.FindSquadInviteRequest",
  () => [
    { no: 1, name: "opaque_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message squads.FindSquadInviteResponse
 */
export const FindSquadInviteResponse = proto3.makeMessageType(
  "squads.FindSquadInviteResponse",
  () => [
    { no: 1, name: "invite", kind: "message", T: SquadInvite },
  ],
);

/**
 * @generated from message squads.GetUserInSharedOrgForEmailRequest
 */
export const GetUserInSharedOrgForEmailRequest = proto3.makeMessageType(
  "squads.GetUserInSharedOrgForEmailRequest",
  () => [
    { no: 1, name: "squad_id", kind: "message", T: SquadId },
    { no: 2, name: "sender_id", kind: "message", T: UserId },
    { no: 3, name: "target_email_addr", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message squads.GetUserInSharedOrgForEmailResponse
 */
export const GetUserInSharedOrgForEmailResponse = proto3.makeMessageType(
  "squads.GetUserInSharedOrgForEmailResponse",
  () => [
    { no: 1, name: "target_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message squads.SubUserSquadListsRequest
 */
export const SubUserSquadListsRequest = proto3.makeMessageType(
  "squads.SubUserSquadListsRequest",
  () => [
    { no: 1, name: "add_to_sub", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "user_ids", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message squads.SubUserSquadListsResponse
 */
export const SubUserSquadListsResponse = proto3.makeMessageType(
  "squads.SubUserSquadListsResponse",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 3, name: "squad_ids", kind: "message", T: SquadId, repeated: true },
  ],
);

/**
 * @generated from message squads.SubUserSquadLastReadsRequest
 */
export const SubUserSquadLastReadsRequest = proto3.makeMessageType(
  "squads.SubUserSquadLastReadsRequest",
  () => [
    { no: 1, name: "add_to_sub", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "user_id", kind: "message", T: UserId },
    { no: 3, name: "squad_ids", kind: "message", T: SquadSet },
  ],
);

/**
 * @generated from message squads.SubUserSquadLastReadsResponse
 */
export const SubUserSquadLastReadsResponse = proto3.makeMessageType(
  "squads.SubUserSquadLastReadsResponse",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "squad_id", kind: "message", T: SquadId },
    { no: 3, name: "last_read", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message squads.SubOrgOverviewsRequest
 */
export const SubOrgOverviewsRequest = proto3.makeMessageType(
  "squads.SubOrgOverviewsRequest",
  () => [
    { no: 1, name: "add_to_sub", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "org_ids", kind: "message", T: OrgSet },
  ],
);

/**
 * @generated from message squads.SubOrgOverviewsResponse
 */
export const SubOrgOverviewsResponse = proto3.makeMessageType(
  "squads.SubOrgOverviewsResponse",
  () => [
    { no: 1, name: "org_overview", kind: "message", T: OrgOverview, oneof: "org_overview_or_deleted" },
    { no: 2, name: "deleted_id", kind: "message", T: OrgId, oneof: "org_overview_or_deleted" },
  ],
);

/**
 * @generated from message squads.SubSquadOverviewsRequest
 */
export const SubSquadOverviewsRequest = proto3.makeMessageType(
  "squads.SubSquadOverviewsRequest",
  () => [
    { no: 1, name: "add_to_sub", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "squad_ids", kind: "message", T: SquadSet },
  ],
);

/**
 * @generated from message squads.SubSquadOverviewsResponse
 */
export const SubSquadOverviewsResponse = proto3.makeMessageType(
  "squads.SubSquadOverviewsResponse",
  () => [
    { no: 1, name: "squad_overview", kind: "message", T: SquadOverview, oneof: "squad_overview_or_deleted" },
    { no: 2, name: "deleted_id", kind: "message", T: SquadId, oneof: "squad_overview_or_deleted" },
  ],
);

/**
 * @generated from message squads.SubSquadLatestActivityRequest
 */
export const SubSquadLatestActivityRequest = proto3.makeMessageType(
  "squads.SubSquadLatestActivityRequest",
  () => [
    { no: 1, name: "add_to_sub", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "squad_ids", kind: "message", T: SquadSet },
  ],
);

/**
 * @generated from message squads.SubSquadLatestActivityResponse
 */
export const SubSquadLatestActivityResponse = proto3.makeMessageType(
  "squads.SubSquadLatestActivityResponse",
  () => [
    { no: 1, name: "squad_id", kind: "message", T: SquadId },
    { no: 2, name: "latest_activity", kind: "message", T: Timestamp },
    { no: 3, name: "activity", kind: "message", T: SquadActivity, oneof: "activity_or_deleted" },
    { no: 4, name: "deleted_id", kind: "message", T: SquadId, oneof: "activity_or_deleted" },
  ],
);

/**
 * @generated from message squads.GetCrewsRequest
 */
export const GetCrewsRequest = proto3.makeMessageType(
  "squads.GetCrewsRequest",
  () => [
    { no: 1, name: "crews", kind: "message", T: CrewSet },
  ],
);

/**
 * @generated from message squads.GetCrewsResponse
 */
export const GetCrewsResponse = proto3.makeMessageType(
  "squads.GetCrewsResponse",
  () => [
    { no: 1, name: "crews", kind: "message", T: CrewOverview, repeated: true },
  ],
);

/**
 * @generated from message squads.SubFollowedUnreadCountRequest
 */
export const SubFollowedUnreadCountRequest = proto3.makeMessageType(
  "squads.SubFollowedUnreadCountRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message squads.SubFollowedUnreadCountResponse
 */
export const SubFollowedUnreadCountResponse = proto3.makeMessageType(
  "squads.SubFollowedUnreadCountResponse",
  () => [
    { no: 1, name: "followed_unread_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message squads.SubSquadObserversRequest
 */
export const SubSquadObserversRequest = proto3.makeMessageType(
  "squads.SubSquadObserversRequest",
  () => [
    { no: 1, name: "squad_ids", kind: "message", T: SquadSet },
    { no: 2, name: "add_to_sub", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message squads.SubSquadObserversResponse
 */
export const SubSquadObserversResponse = proto3.makeMessageType(
  "squads.SubSquadObserversResponse",
  () => [
    { no: 1, name: "squad_id", kind: "message", T: SquadId },
    { no: 2, name: "observers", kind: "message", T: UserObservation, repeated: true },
  ],
);

