import { fromProtoPresenceMode } from "@/domain/presence";
import { deepFreeze } from "@/misc/deepFreeze";
import { FieldInfo, Message, OneofInfo } from "@bufbuild/protobuf";
import { z } from "zod";
import {
    fromProtoBlobId,
    fromProtoBondId,
    fromProtoCallId,
    fromProtoChannelId,
    fromProtoDeviceId,
    fromProtoMessageId,
    fromProtoNotificationPriority,
    fromProtoNotifId,
    fromProtoOrgId,
    fromProtoPersonId,
    fromProtoPrivacyLevel,
    fromProtoRtcParticipantId,
    fromProtoRtcSessionId,
    fromProtoSquadId,
    fromProtoSquadSet,
    fromProtoTimestamp,
    fromProtoUserId,
} from "./util";

const bufMessageParsers: Record<string, any> = {
    // ID types
    "domain.BlobId": fromProtoBlobId,
    "domain.BondId": fromProtoBondId,
    "domain.CallId": fromProtoCallId,
    "domain.ChannelId": fromProtoChannelId,
    "domain.DeviceId": fromProtoDeviceId,
    "domain.MessageId": fromProtoMessageId,
    "domain.NotifId": fromProtoNotifId,
    "domain.SquadId": fromProtoSquadId,
    "domain.UserId": fromProtoUserId,
    "domain.PersonId": fromProtoPersonId,
    "domain.OrgId": fromProtoOrgId,
    "domain.RtcParticipantId": fromProtoRtcParticipantId,
    "domain.RtcSessionId": fromProtoRtcSessionId,

    // Set types
    "domain.SquadSet": fromProtoSquadSet,

    // enum types
    "domain.NotifPriority": fromProtoNotificationPriority,
    "domain.PrivacyLevel": fromProtoPrivacyLevel,
    "domain.PresenceMode": fromProtoPresenceMode,

    // Misc types
    "google.protobuf.Timestamp": fromProtoTimestamp,

    // Ignored types
    "domain.CrewId": () => undefined,
};

const parsePB = <T extends Message<T>>(o: any, msg: T, field: FieldInfo | OneofInfo) => {
    if (msg === undefined) return o;

    const localName = field.localName as keyof T;

    if (msg === undefined) {
        o[localName] = undefined;
        return o;
    }

    switch (field.kind) {
        case "message": {
            const customParser = bufMessageParsers[field.T.typeName];
            if (customParser) {
                if (field.repeated) {
                    const repeated = msg as unknown as Array<typeof field.T>;
                    o[localName] = repeated.map(customParser);
                }
                else {
                    o[localName] = customParser(msg);
                }
            }
            else {
                o[localName] = {};

                const fields = msg.getType().fields;

                fields.byMember().forEach(subfield => {
                    // TODO typing
                    const child = (msg as any)[subfield.localName];
                    parsePB(o[localName], child, subfield);
                });
            }

            break;
        }
        case "enum": {
            const customParser = bufMessageParsers[field.T.typeName];
            if (!customParser) {
                throw new Error("unexpected enum type encountered: " + field.T.typeName);
            }

            if (field.repeated) {
                const repeated = msg as unknown as Array<typeof field.T>;
                o[localName] = repeated.map(customParser);
            }
            else {
                o[localName] = customParser(msg);
            }

            break;
        }
        case "scalar": {
            if (field.repeated) {
                const repeated = msg as unknown as Array<typeof field.T>;
                o[localName] = repeated;
            }
            else {
                o[localName] = msg;
            }

            break;
        }
        case "map": {
            throw new Error("Not implemented");
        }
        case "oneof": {
            if (field.repeated) throw new Error("repeated oneofs are not (yet) supported");
            // TODO: fixup type-y stuff
            const c = (msg as any)["case"];
            const newField = field.findField(c);
            if (!newField) throw new Error(`unknown case ${c} in oneof ${field.name}`);

            const subObject = { case: c, value: {} };
            parsePB(subObject.value, (msg as any)["value"], newField);
            o[localName] = subObject;

            break;
        }
    }
    return o;
};

export const parseBufMessage = <T extends Message<T>>(msg: T) => {
    return msg.getType().fields.byMember().reduce(
        // TODO: typing
        (o, field) => parsePB(o, (msg as any)[field.localName], field),
        {} as any,
    );
};

/**
 * Parse a message with a given Zod schema.
 * @param schema - The Zod schema to parse the message with.
 * @param value - The field name to parse with schema from the message.
 * @returns A function that takes a proto message and returns the deep-frozen parsed value.
 * @throws An error if the value on the proto message to parse with Zod is not found.
 * @throws The usual Zod parsing errors if the message does not match the schema.
 */
export const parseMessageWithSchema =
    <M extends Message<M>, T extends z.ZodTypeAny>(schema: T, value?: keyof M) => (msg: M) => {
        const parsed = parseBufMessage(msg);

        const toZodParse = value ? parsed[value] : parsed;

        if (!toZodParse) {
            throw new Error(`Nothing to parse${value ? ` for ${String(value)}` : ""}`);
        }

        return deepFreeze(schema.parse(toZodParse) as z.infer<T>);
    };
