import { RichTextSuggestionQueryHandler } from "@/components/richtext/RichTextEditor";
import { Mention } from "@/domain/mentions";
import { tagPosition } from "@/domain/richtext/taggers";
import { Provider } from "@/domain/richtext/types";
import { Attribute, mergeAttributes, Node } from "@tiptap/core";
import { PluginKey } from "@tiptap/pm/state";
import Suggestion, { SuggestionProps } from "@tiptap/suggestion";
import { Optional } from "../types";
import { simpleAttribute } from "./utils";

// NOTE: originally adapted from @tiptap/extension-mention

const mentionNodeName = "mention";

const mentionNodeTrigger = "@";

type MentionNodeAttrs = Mention & { text: string; };

interface MentionNodeOptions {
    onSuggestionQuery?: RichTextSuggestionQueryHandler<Provider.Tiptap>;
}

const handleSuggestion = (
    onSuggestionQuery: Optional<RichTextSuggestionQueryHandler<Provider.Tiptap>>,
    { clientRect, query, range }: SuggestionProps<any, any>,
) => {
    onSuggestionQuery?.({
        anchor: clientRect?.() ?? { x: 0, y: 0 },
        trigger: mentionNodeTrigger,
        text: query,
        range: {
            start: tagPosition(range.from, Provider.Tiptap),
            end: tagPosition(range.to, Provider.Tiptap),
        },
    });
};

export const MentionNode = Node.create<MentionNodeOptions>({
    name: mentionNodeName,
    priority: 101,
    group: "inline",
    inline: true,
    selectable: true,
    atom: true,

    addAttributes: (): { [K in keyof MentionNodeAttrs]: Attribute; } => ({
        target: simpleAttribute("target"),
        text: simpleAttribute("text", ""),
    }),

    parseHTML: () => [{ tag: `span[data-type="${mentionNodeName}"]` }],

    renderHTML: ({ node, HTMLAttributes }) => [
        "span",
        mergeAttributes(
            { "data-type": mentionNodeName, "class": "cp-composer-mention" },
            HTMLAttributes,
        ),
        node.attrs.text ?? "",
    ],

    addKeyboardShortcuts() {
        return {
            Backspace: () =>
                this.editor.commands.command(
                    ({ tr, state: { doc, selection: { empty, anchor } } }) => {
                        // Do nothing if the selection is collapsed
                        if (!empty) return false;

                        // If any mention node is found immediately before the selection,
                        // remove it and consume the keyboard shortcut. If none is found,
                        // do nothing
                        let isMention = false;
                        doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
                            if (node.type.name === mentionNodeName) {
                                isMention = true;
                                tr.insertText("", pos, pos + node.nodeSize);
                                return false;
                            }
                        });
                        return isMention;
                    },
                ),
        };
    },

    addProseMirrorPlugins() {
        return [
            Suggestion({
                editor: this.editor,
                char: mentionNodeTrigger,
                pluginKey: new PluginKey(`${mentionNodeName}/suggestion`),
                allow: ({ state, range }) => {
                    const $from = state.doc.resolve(range.from);
                    const type = state.schema.nodes[mentionNodeName];
                    return !!$from.parent.type.contentMatch.matchType(type);
                },
                render: () => ({
                    onStart: props => handleSuggestion(this.options.onSuggestionQuery, props),
                    onUpdate: props => handleSuggestion(this.options.onSuggestionQuery, props),
                    onExit: () => this.options.onSuggestionQuery?.(undefined),
                }),
            }),
        ];
    },
});
