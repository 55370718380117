import { useCallback, useRef, useState } from "react";

import { DeviceKind, MediaPublishFunc } from "@/domain/mediaDevices";
import { selectCurrentDevice } from "@/features/mediaDevices";
import { useMediaDevice } from "@/hooks/media/useMediaDevice";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import log from "@/misc/log";

interface IAvatarCaptureProps {
    onPhotoTaken?: (photo: HTMLVideoElement) => void;
}

export default function AvatarCapture({ onPhotoTaken }: IAvatarCaptureProps): React.JSX.Element {
    const deviceId = useSelectorArgs(selectCurrentDevice, DeviceKind.VideoInput);
    const videoRef = useRef<HTMLVideoElement>(null);
    const [isCaptureEnabled, setIsCaptureEnabled] = useState(false);

    const onGetVideo: MediaPublishFunc = useCallback(stream => {
        if (!videoRef.current) {
            setIsCaptureEnabled(false);
            throw new Error("Unable to find video element.");
        }
        videoRef.current.srcObject = stream;
        setIsCaptureEnabled(true);
    }, []);

    useMediaDevice({ enabled: true, kind: DeviceKind.VideoInput, onGetMedia: onGetVideo });

    const takePhoto = useCallback(() => {
        if (!videoRef.current) {
            log.error("Unable to find video element");
            throw new Error("Unable to find video element.");
        }
        try {
            onPhotoTaken?.(videoRef.current);
        }
        catch (e) {
            log.error("Error taking photo", e);
        }
    }, [videoRef, onPhotoTaken]);

    return (
        <>
            {deviceId ?
                (
                    <video
                        ref={videoRef}
                        data-testid="preview-video"
                        className="c-profile-upload__image"
                        playsInline
                        autoPlay
                        key={deviceId}
                    />
                ) :
                <>No device selected</>}
            {isCaptureEnabled &&
                (
                    <button
                        className="c-avatar-btn-action c-avatar-btn-action--take-photo"
                        title="Take photo"
                        onClick={takePhoto}
                    >
                        Take photo
                    </button>
                )}
        </>
    );
}
