import classNames from "classnames";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";

import { BondCard } from "@/components/BondCard";
import { BondInviteCard } from "@/components/InviteCard";
import * as d from "@/domain/domain";
import { viewUtils } from "@/domain/views";
import {
    selectBondById,
    selectCardsViewScrollTop,
    selectPersonalBondInvites,
    setCardsViewScrollTop,
} from "@/features/bonds";
import { selectSquadIsUnread } from "@/features/channels";
import {
    selectBondIdsForBondList,
    selectInterestingBondIdsForBondList,
} from "@/features/filteredBonds";
import { FilterOptions, selectFilter, setFilter } from "@/features/filterPanel";
import { setSquadLastReadThunk } from "@/features/squads";
import { useInterestedBonds } from "@/hooks/interest/useInterest";
import useViewStackNavigate from "@/hooks/navigation/useViewStackNavigate";
import useAddressParams from "@/hooks/useAddressParams";
import useLoadMoreOnScroll from "@/hooks/useLoadMoreOnScroll";
import useOutsideClick from "@/hooks/useOutsideClick";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { useShallowEqualsMemo } from "@/hooks/useShallowEquals";
import useSwipeDown from "@/hooks/useSwipeDown";
import { isMobileBrowser } from "@/misc/mobile";
import { useAppDispatch, useAppSelector } from "@/store/redux";

const initialNumberOfBondsShown = 36;
const incrementNumberOfBondsShown = 18;

function limitBondIds(bondOverviews: d.BondId[], cardLimit: number) {
    return {
        bondIds: bondOverviews.slice(0, cardLimit),
        isLimited: bondOverviews.length > cardLimit,
    };
}

export default function BondsListView(): React.JSX.Element {
    const dispatch = useAppDispatch();
    const { navigatePush } = useViewStackNavigate();
    const [cardLimit, setCardLimit] = useState(initialNumberOfBondsShown);
    const endDivRef = useRef<HTMLDivElement>(null);
    const { squadId } = useAddressParams();

    const interestingBondIds = useSelectorArgs(selectInterestingBondIdsForBondList, squadId);
    useInterestedBonds(interestingBondIds);

    const allBondIds = useSelectorArgs(selectBondIdsForBondList, squadId);
    const { bondIds, isLimited } = limitBondIds(allBondIds, cardLimit);

    const loadMore = useCallback(() => {
        if (isLimited) {
            setCardLimit(x => x + incrementNumberOfBondsShown);
        }
    }, [isLimited, setCardLimit]);
    const loadMoreOnScroll = useLoadMoreOnScroll(endDivRef, loadMore);
    const squadIsUnread = useSelectorArgs(selectSquadIsUnread, squadId);
    const topBond = useSelectorArgs(selectBondById, bondIds[0]);

    // Keep the squad marked as read while we have this view open
    useEffect(() => {
        if (!squadId) return;

        if (squadIsUnread && topBond?.lastActivityAt) {
            // This could mark all squads in the bond as read, but the semantics
            // would be a bit odd.
            dispatch(setSquadLastReadThunk({
                squadIds: [squadId],
                lastRead: topBond.lastActivityAt,
            }));
        }
    }, [dispatch, squadId, squadIsUnread, topBond?.lastActivityAt]);

    // XXX: "selectCardsViewScrollTop" -- naming off, it's a list view in V3
    const savedScrollPosition = useAppSelector(selectCardsViewScrollTop);
    const scrollableAreaRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        const current = scrollableAreaRef.current;
        return () => {
            dispatch(setCardsViewScrollTop(current?.scrollTop ?? 0));
        };
    }, [dispatch]);
    useLayoutEffect(() => {
        if (scrollableAreaRef.current) {
            scrollableAreaRef.current?.scrollTo({
                top: savedScrollPosition,
                behavior: "instant",
            });
        }
    }, [savedScrollPosition]);

    const clickCallback = useCallback((id: d.BondId) => {
        navigatePush(viewUtils.singleBond(id));
    }, [navigatePush]);

    const isInbox = !squadId;
    const invites = useAppSelector(selectPersonalBondInvites);

    // Sort invites by creation date, then de-duplicate by bondId
    // FIXME: represent this as a selector/within the slice itself.
    const sortedInvites = useShallowEqualsMemo(() => {
        return invites
            .sort((a, b) => b.body.createdAt - a.body.createdAt)
            .filter((invite, index, self) => (
                self.findIndex(i => i.bondId === invite.bondId) === index
            ));
    }, [invites]);

    return (
        <div
            onScroll={loadMoreOnScroll}
            ref={scrollableAreaRef}
            className={!isInbox ? "c-content__squad-inbox" : "c-content"}
        >
            {isInbox &&
                sortedInvites.map(invite => (
                    <BondInviteCard key={invite.body.opaqueCode} invite={invite} />
                ))}
            <CardContainer endDivRef={endDivRef}>
                {bondIds.map((id, idx) => (
                    <BondCard
                        key={id}
                        id={id}
                        onClick={clickCallback}
                        isFirstCard={idx === 0}
                        isLastCard={idx === bondIds.length - 1}
                    />
                ))}
            </CardContainer>
        </div>
    );
}

interface MobileFiltersProps {
    isVisible: boolean;
}

const MobileFilters = (props: MobileFiltersProps): React.JSX.Element => {
    const dispatch = useAppDispatch();
    const { squadId } = useAddressParams();
    const isSquadView = squadId !== undefined;

    const filter = useAppSelector(selectFilter);
    const allButtonClassName = classNames("c-bond-filters__filter", {
        "c-bond-filters__filter--selected": filter == "all",
    });
    const unreadButtonClassName = classNames("c-bond-filters__filter", {
        "c-bond-filters__filter--selected": filter == "unread",
    });
    const dismissedButtonClassName = classNames("c-bond-filters__filter", {
        "c-bond-filters__filter--selected": filter == "dismissed",
    });

    const handleFilterChange = (newFilter: FilterOptions) => {
        dispatch(setFilter(newFilter));
    };

    if (!props.isVisible) return <></>;

    return (
        <div className="c-bond-actions">
            <div className="c-bond-filters">
                <button
                    className={allButtonClassName}
                    onClick={() => handleFilterChange("all")}
                >
                    All
                </button>
                <button
                    className={unreadButtonClassName}
                    onClick={() => handleFilterChange("unread")}
                >
                    Unread
                </button>
                {!isSquadView && (
                    <button
                        className={dismissedButtonClassName}
                        onClick={() => handleFilterChange("dismissed")}
                    >
                        Dismissed
                    </button>
                )}
            </div>
        </div>
    );
};

interface CardContainerProps {
    children: React.ReactNode;
    endDivRef?: React.RefObject<HTMLDivElement>;
}

export const CardContainer = (props: CardContainerProps): React.JSX.Element => {
    const { children, endDivRef } = props;

    const [areFiltersVisible, setAreFiltersVisible] = useState(false);

    const [containerRef] = useOutsideClick<HTMLDivElement>(
        () => setAreFiltersVisible(false),
        { active: areFiltersVisible },
    );
    useSwipeDown(containerRef, () => setAreFiltersVisible(true));

    const isMobile = isMobileBrowser();

    const wrapperClasses = classNames({
        "c-mobile": isMobile,
        "c-desktop": !isMobile,
    });

    const listingClasses = classNames("cp-bond-listing", {
        "cp-bond-listing--desktop": !isMobile,
    });

    return (
        <div className={wrapperClasses} ref={containerRef}>
            {isMobile && <MobileFilters isVisible={areFiltersVisible} />}
            <div className={listingClasses}>
                {children}
            </div>
            <div ref={endDivRef} />
        </div>
    );
};
