import { merge } from "lodash";
import { PropsWithChildren, useCallback, useMemo } from "react";
import { Link, LinkProps } from "react-router-dom";

import { AppView, viewUtils } from "@/domain/views";
import {
    pushViewStack,
    replaceAndSwitchViewStack,
    selectViewLinkPathAndViewStack,
    switchViewStack,
} from "@/features/filterPanel";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { useAppDispatch } from "@/store/redux";

type LinkToViewProps = Omit<LinkProps, "to"> & {
    view: AppView;
};

export default function LinkToView(props: PropsWithChildren<LinkToViewProps>) {
    const { view, state, children, onClick: propOnClick, ...restOfProps } = props;

    const dispatch = useAppDispatch();

    const [targetPath, navState] = useSelectorArgs(selectViewLinkPathAndViewStack, view);

    const fullState = useMemo(() => merge(navState, state), [navState, state]);

    const onClick: React.MouseEventHandler<HTMLAnchorElement> = useCallback(e => {
        propOnClick?.(e);

        const stack = navState?.viewStack;
        if (!stack) throw new Error(`Invalid view ${view.view} for Link`);

        const sameStack = stack.some(f => viewUtils.viewsEqual(f, view));
        const topLevelView = viewUtils.toTopLevelName(view);

        if (sameStack) {
            dispatch(replaceAndSwitchViewStack(stack));
        }
        else if (topLevelView) { // desktop squad case
            dispatch(switchViewStack(stack[0]));
        }
        else { // mobile squad case
            dispatch(pushViewStack(view));
        }
    }, [propOnClick, navState, view, dispatch]);

    return (
        <Link
            to={targetPath}
            state={fullState}
            onClick={onClick}
            {...restOfProps}
        >
            {children}
        </Link>
    );
}
