import shallowEqual from "@/misc/shallowEqual";
import { Optional, PartialRecord } from "@/misc/types";
import { range } from "@/misc/utils";

// region Set helpers

interface diffResponse<T> {
    added: Array<T>;
    removed: Array<T>;
}

export function diff<T>(before: Array<T> | undefined, after: Array<T>): diffResponse<T> {
    return {
        added: after.filter(x => !before?.includes(x)),
        removed: before?.filter(x => !after.includes(x)) ?? [],
    };
}

type ArrayOrUndefined<T> = Readonly<T[]> | T[] | undefined;
export function union<T>(...arrs: ArrayOrUndefined<T>[]): T[] {
    const s = new Set(arrs.filter(a => a).flat().filter(e => e !== undefined));
    return Array.from(s).sort() as T[];
}

const inPlaceUnion = <T>(a: Set<T>, b?: Set<T>): Set<T> => {
    b?.forEach(i => a.add(i));
    return a;
};

export function setUnion<T>(...sets: Set<T>[]): Set<T> {
    return sets.reduce(inPlaceUnion, new Set()).filter(e => e !== undefined);
}

// region Set extensions

function filter<T>(this: Set<T>, predicate: (value: T) => boolean): Set<T> {
    const result = new Set<T>();
    for (const value of this) {
        if (predicate(value)) {
            result.add(value);
        }
    }
    return result;
}

function map<T, U>(this: Set<T>, fn: (value: T) => U): Set<U> {
    const result = new Set<U>();
    for (const value of this) {
        result.add(fn(value));
    }
    return result;
}

function reduce<T, U>(this: Set<T>, fn: (acc: U, value: T) => U, initial: U): U {
    let acc = initial;
    for (const value of this) {
        acc = fn(acc, value);
    }
    return acc;
}

function sorted<T>(this: Set<T>, compareFn?: (a: T, b: T) => number): T[] {
    return Array.from(this).sort(compareFn);
}

function addMany<T>(this: Set<T>, vals: T[]): Set<T> {
    vals.forEach(v => this.add(v));
    return this;
}

function unionExt<T>(this: Set<T>, ...others: Set<T>[]): Set<T> {
    return others.reduce(inPlaceUnion, this);
}

function shallowEqualsSet<T>(this: Set<T>, other: Set<T>): boolean {
    if (this.size !== other.size) return false;

    for (const v of this.keys()) {
        if (!other.has(v)) return false;
    }

    return true;
}

// region Array helpers

// Arrays never value-compare equal. Use this function instead.
// If we naively check `array1 === array2`, we can get an infinite loop where
// our selectors cause a re-render of a component, which causes an action to be
// dispatched which overwrites the value in the store, which updates the
// selector, which causes a re-render...
export const arraysAreEqual = <T>(a: T[], b: T[]) => {
    if (a == b) return true;
    if (a.length !== b.length) return false;
    return a.every((v, i) => v === b[i]);
};

// Compare two arrays using a set equality comparison; disregard any duplicate values
// and return whether all the elements from each set are present in the other
export const arraysAreSetEqual = <T>(xa: T[], ya: T[]) => {
    if (xa === ya) {
        return true;
    }
    const xs = new Set(xa);
    const ys = new Set(ya);
    return xs.size === ys.size && [...xs].every(x => ys.has(x));
};

// Returns true if `a` is equal to the subset of `b` that it contains keys for.
// If the value for a key is an object, only referentially equal objects will match.
// (This matches the use-case of immutable updates to the redux store.)
export const mapIsSubset = <T extends Record<any, any>>(a: T, b: T) =>
    Object.keys(a).every(k => a[k] === b[k]);

// Map a record by a predicate on its values.
export const mapRecord = <K extends PropertyKey, V, T>(
    record: Record<K, V>,
    transform: (key: K, value: V) => T,
): Record<K, T> => {
    const mappedEntries = Object.entries<V>(record).map(([k, v]) => [k, transform(k as K, v)]);
    return Object.fromEntries(mappedEntries);
};

// Filter a record by a predicate on its values.
export const filterRecordByValue = <K extends PropertyKey, V>(
    record: Record<K, V>,
    predicate: (value: V) => boolean,
): PartialRecord<K, V> => {
    const filteredEntries = Object.entries<V>(record).filter(([_, v]) => predicate(v));
    return Object.fromEntries(filteredEntries) as PartialRecord<K, V>;
};

export const filterRecordByEntry = <K extends PropertyKey, V>(
    record: Record<K, V>,
    predicate: (key: K, value: V) => boolean,
): PartialRecord<K, V> => {
    const filteredEntries = Object.entries<V>(record).filter(([k, v]) => predicate(k as K, v));
    return Object.fromEntries(filteredEntries) as PartialRecord<K, V>;
};

export const removeDuplicates = <T>(...arrs: T[][]): T[] => {
    const [r, _] = arrs.flat().reduce<[T[], Set<T>]>((acc, v: T) => {
        if (!acc[1].has(v)) {
            acc[1].add(v);
            acc[0].push(v);
        }
        return acc;
    }, [[], new Set()]);

    return r;
};

// region Array extensions

function filterLatest<T, R extends string>(this: Array<T>, fn: (t: T) => R) {
    const o = Object.fromEntries(this.map(t => [fn(t), t]));
    return Object.values(o);
}

// shuffle returns a new shuffled array from the items in `this`, using the
// Fisher-Yates Shuffle Algorithm to shuffle the elements.
function shuffle<T>(this: Array<T>): Array<T> {
    // Copy the array
    const ret = [...this];

    // Shuffle the elements
    const n = ret.length;
    for (let i = n - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [ret[i], ret[j]] = [ret[j], ret[i]];
    }

    return ret;
}

function windows<T>(this: Array<T>, size: number): Array<Array<T>> {
    if (size < 1) {
        throw new Error(`invalid size in windows call: ${size} (must be greater than zero)`);
    }
    if (size % 1 != 0) {
        throw new Error(`invalid size in windows call: ${size} (must be integer)`);
    }
    if (this.length == 0) {
        return [];
    }
    return range(this.length - size + 1).map(i => this.slice(i, i + size));
}

function toSet<T>(this: T[]): Set<T> {
    return new Set(this);
}

function removeDuplicatesExt<T>(this: T[], ...rest: T[][]) {
    return removeDuplicates(this, ...rest);
}

function joinArray<T, S>(this: Array<T>, delim: S): Array<T | S> {
    return this.flatMap((t, i) => (i < this.length - 1) ? [t, delim] : [t]);
}

/** Replace any elements in this array with elements from a different array
 * that shallow-equal those in this array.
 *
 * @param from the array from which to draw replacements. Can be undefined/null.
 * @returns an array with as many elements of `from` that shallow-equal our own
 */
function replaceFrom<T>(this: Array<T>, from: Optional<Array<T>>): Array<T> {
    if (!from) return this;
    return this.map(m => from.find(v => shallowEqual(v, m)) ?? m);
}

// region Object helpers
export const assignDefined = <T extends object>(target: T, source: Partial<T>): T =>
    Object.assign(
        target,
        ...Object.entries(source)
            .map(e => (e[1] !== undefined) ? { [e[0]]: e[1] } : {}),
    );

// region Map helpers

// Return true iff, for every key in `this`, the corresponding value is
// strictly equal to the value for that key in `other`.
function shallowEqualsMap<K, V>(this: Map<K, V>, other: Map<K, V>): boolean {
    if (this.size !== other.size) return false;

    for (const k of this.keys()) {
        if (this.get(k) !== other.get(k)) return false;
    }

    return true;
}

// region Interfaces

declare global {
    interface Set<T> {
        filter(predicate: (value: T) => boolean): Set<T>;
        map<U>(fn: (value: T) => U): Set<U>;
        reduce<U>(fn: (acc: U, value: T) => U, initial: U): U;
        sorted(compareFn?: (a: T, b: T) => number): T[];
        addMany(vals: T[]): Set<T>;
        union(...others: Set<T>[]): Set<T>;
        shallowEquals(other: Set<T>): boolean;
    }

    interface Array<T> {
        filterLatest<R extends string>(predicate: (value: T) => R): Array<T>;
        shuffle(): Array<T>;
        windows(size: number): Array<Array<T>>;
        toSet(): Set<T>;
        removeDuplicates(...others: T[][]): Array<T>;
        joinArray<S>(delim: S): Array<T | S>;
        replaceFrom(from: Optional<Array<T>>): Array<T>;
    }

    interface Map<K, V> {
        shallowEquals(other: Map<K, V>): boolean;
    }
}

/**
 * Register extension methods with the relevant prototypes. This must be called at
 * every entrypoint to the application (including tests)
 */
export function setupTypeExtensions() {
    Set.prototype.filter = filter;
    Set.prototype.map = map;
    Set.prototype.reduce = reduce;
    Set.prototype.sorted = sorted;
    Set.prototype.addMany = addMany;
    Set.prototype.union = unionExt;
    Set.prototype.shallowEquals = shallowEqualsSet;

    Array.prototype.filterLatest = filterLatest;
    Array.prototype.shuffle = shuffle;
    Array.prototype.windows = windows;
    Array.prototype.toSet = toSet;
    Array.prototype.removeDuplicates = removeDuplicatesExt;
    Array.prototype.joinArray = joinArray;
    Array.prototype.replaceFrom = replaceFrom;

    Map.prototype.shallowEquals = shallowEqualsMap;
}
