import {
    createBrowserRouter,
    createRoutesFromElements,
    Outlet,
    redirect,
    Route,
} from "react-router-dom";

import AuthenticationStartup from "@/components/AuthenticationStartup";
import { ErrorElement } from "@/components/gui/ErrorElement";
import { LocationLogger } from "@/components/LocationLogger";
import ViewStackManager from "@/components/managers/ViewStackManager";
import AttachmentLightbox from "@/components/messages/AttachmentLightbox";
import FlaggedCallLocationModal from "@/components/modals/CallLocationModal";
import CreateSquadModal from "@/components/modals/CreateSquadModal";
import InviteUsersToBondModal from "@/components/modals/InviteUsersModal";
import InviteUsersToSquadModal from "@/components/modals/InviteUsersToSquadModal";
import ModifyBondModal from "@/components/modals/ModifyBondModal";
import RenameSquadModal from "@/components/modals/RenameSquadModal";
import { SentryReportForm } from "@/components/SentryReportButton"; // Move to views?
import LoginView from "@/login/LoginView";
import LogoutView from "@/login/LogoutView";
import PasscodeView from "@/login/PasscodeView";
import log from "@/misc/log";
import { isMobileBrowser } from "@/misc/mobile";
import { setRouter } from "@/misc/router";
import { BondSidebarSummaryView } from "@/views/BondSidebarSummaryView";
import BondView from "@/views/BondView";
import DiscoverView from "@/views/DiscoverView";
import InviteRedemptionView from "@/views/InviteRedemptionView";
import { MediaSettingsView } from "@/views/MediaSettingsView";
import { MobileSquadSelectorView } from "@/views/MobileViews";
import { MyBondsView } from "@/views/MyBondsView";
import { NewBondView } from "@/views/NewBondView";
import { ProfileSettingsView } from "@/views/ProfileSettingsView";
import { AuthenticatedRootView } from "@/views/RootView";
import { SearchView } from "@/views/SearchView";
import SettingsView from "@/views/SettingsView";
import SquadSettingsView from "@/views/SquadSettingsView";
import { InboxSidebarSummaryView, SquadSidebarSummaryView } from "@/views/SquadSidebarSummaryView";
import { SquadView } from "@/views/SquadView";
import RemoveUserFromSquadModal from "./modals/RemoveUserFromSquadModal";

const indexRedirect = async () => {
    log.info(`Redirecting index -> /bond`);

    // Keep query params so we have the OIDC state for login
    return redirect(`/bond${window.location.search}`);
};

const mobileDiscoverRedirect = async () => {
    if (isMobileBrowser()) {
        return redirect("/mobile/tab/discover");
    }
    return null;
};

const mobileBondsRedirect = async () => {
    if (isMobileBrowser()) {
        const from = window.location.href;
        const to = `/mobile/tab/mybonds${window.location.search}`;

        log.info(`Redirecting ${from} -> ${to}`);
        return redirect(to);
    }
    return null;
};

const nonMobileBondsRedirect = async () => {
    if (!isMobileBrowser()) {
        log.info(`Redirecting /mobile/tab -> /bond`);
        return redirect("/bond");
    }
    return null;
};

const errorHandler = {
    errorElement: <ErrorElement />,
};

const routeTree = (
    <Route
        path="/"
        element={
            <>
                <LocationLogger />
                <ViewStackManager />
                <AuthenticationStartup />
                <Outlet />
            </>
        }
        {...errorHandler}
    >
        {/* /login,/logout routes */}
        <Route
            path="login"
            {...errorHandler}
        >
            <Route index={true} element={<LoginView />} {...errorHandler} />
            <Route
                path="passcode"
                element={<PasscodeView />}
                {...errorHandler}
            />
        </Route>
        <Route
            path="logout"
            element={<LogoutView />}
            {...errorHandler}
        />

        {
            /* Index. We always redirect to /bond since that is our authenticated area
            today. */
        }
        <Route index={true} loader={indexRedirect} {...errorHandler} />

        {/* /bond routes */}
        <Route
            path="bond"
            element={<AuthenticatedRootView />}
            {...errorHandler}
        >
            <Route path=":bondId" element={<BondView />} {...errorHandler}>
                <Route path="message/:messageId" element={<Outlet />} {...errorHandler}>
                    <Route
                        path="attachment/:attachmentVersion/:attachmentIdx"
                        element={<AttachmentLightbox />}
                        {...errorHandler}
                    />
                </Route>
                <Route path="summary" element={<BondSidebarSummaryView />} {...errorHandler} />
                <Route path="invite" element={<InviteUsersToBondModal />} {...errorHandler} />
                <Route path="modify" element={<ModifyBondModal />} {...errorHandler} />
                <Route path="location" element={<FlaggedCallLocationModal />} {...errorHandler} />
            </Route>
            <Route path="new" element={<NewBondView />} {...errorHandler} />
            <Route
                path="summary"
                element={
                    <>
                        <MyBondsView>
                            <InboxSidebarSummaryView />
                        </MyBondsView>
                    </>
                }
                {...errorHandler}
            />

            <Route
                index={true}
                loader={mobileBondsRedirect}
                element={<MyBondsView />}
                {...errorHandler}
            />
        </Route>

        {/* Settings  */}

        <Route
            path="settings"
            element={
                <>
                    <SettingsView />
                    <MyBondsView />
                </>
            }
            {...errorHandler}
        >
            <Route path="squads" {...errorHandler}>
                <Route path=":squadId" element={<SquadSettingsView />} {...errorHandler}>
                    <Route path="invite" element={<InviteUsersToSquadModal />} {...errorHandler} />
                    <Route path="rename" element={<RenameSquadModal />} {...errorHandler} />
                    <Route
                        path="remove/:userId"
                        element={<RemoveUserFromSquadModal />}
                        {...errorHandler}
                    />
                </Route>
                <Route path="new" element={<CreateSquadModal />} {...errorHandler} />
            </Route>

            <Route path="media" element={<MediaSettingsView />} {...errorHandler} />
            <Route path="profile" element={<ProfileSettingsView />} {...errorHandler} />
            <Route path="report" element={<SentryReportForm />} {...errorHandler} />
        </Route>

        {/* Invite Redemption */}

        <Route
            path="invite"
            element={<AuthenticatedRootView />}
            {...errorHandler}
        >
            <Route path=":inviteCode" element={<InviteRedemptionView />} {...errorHandler} />
        </Route>

        {/* Search */}
        <Route
            path="search"
            element={<AuthenticatedRootView />}
            {...errorHandler}
        >
            <Route
                index={true}
                // TODO: Mobile search view : #4311
                element={<SearchView />}
                {...errorHandler}
            />
        </Route>

        {/* Desktop "Discover" */}
        <Route
            path="discover"
            loader={mobileDiscoverRedirect}
            element={<AuthenticatedRootView />}
            {...errorHandler}
        >
            <Route index={true} element={<DiscoverView />} {...errorHandler} />
        </Route>

        {/* Filtering bonds by a squad */}
        <Route
            path="squad"
            element={<AuthenticatedRootView />}
            {...errorHandler}
        >
            <Route path=":squadId" element={<SquadView />} {...errorHandler}>
                <Route path="summary" element={<SquadSidebarSummaryView />} {...errorHandler} />
            </Route>
        </Route>

        {/* Mobile only tabbed view */}
        <Route
            path="mobile"
            loader={nonMobileBondsRedirect}
            element={<AuthenticatedRootView />}
            {...errorHandler}
        >
            <Route path="tab">
                <Route
                    path="mybonds"
                    element={<MyBondsView />}
                    {...errorHandler}
                />
                <Route
                    path="mysquads"
                    element={<MobileSquadSelectorView />}
                    {...errorHandler}
                />
                <Route
                    path="discover"
                    element={<DiscoverView />}
                    {...errorHandler}
                />
            </Route>
        </Route>
    </Route>
);

export function setupRoutes() {
    setRouter(createBrowserRouter(createRoutesFromElements(routeTree)));
}
