import { useCallback } from "react";
import { Outlet } from "react-router-dom";

import { CloseButton } from "@/components/buttons/Close";
import { SidebarItem } from "@/components/buttons/SidebarItem";
import { VersionInfo } from "@/components/VersionInfo";
import { AppView, viewStackUtils, viewUtils } from "@/domain/views";
import { selectCurrentView, selectCurrentViewStack } from "@/features/filterPanel";
import useViewStackNavigate from "@/hooks/navigation/useViewStackNavigate";
import useDialogOpenRef from "@/hooks/useDialogOpenRef";
import useDialogOutsideClick from "@/hooks/useDialogOutsideClick";
import { isMobileBrowser } from "@/misc/mobile";
import { useAppSelector } from "@/store/redux";
import classNames from "classnames";

type SidebarEntry = {
    key: string;
    label: string;
    icon: string;
    view: AppView;
};

const sidebarEntries: SidebarEntry[] = [{
    key: "profile",
    label: "Profile",
    icon: "profile",
    view: viewUtils.profileSettings(),
}, {
    key: "media",
    label: "Audio & video",
    icon: "audio-video",
    view: viewUtils.mediaSettings(),
}, {
    key: "report",
    label: "Report issue",
    icon: "report-issue",
    view: viewUtils.reportIssue(),
}];

function SettingsSidebarItem(
    props: {
        entry: SidebarEntry;
        navigateToSettingsView: (v: AppView) => void;
        isDesktop: boolean;
    },
): React.JSX.Element {
    const { entry, navigateToSettingsView, isDesktop } = props;

    const currentView = useAppSelector(selectCurrentView);

    const navigateToThisEntry = useCallback(() => {
        navigateToSettingsView(entry.view);
    }, [navigateToSettingsView, entry.view]);

    return (
        <SidebarItem
            {...entry}
            key={entry.key}
            hasIcon
            hasFocus={entry.view.view === currentView.view}
            onClick={navigateToThisEntry}
            isDesktop={isDesktop}
        />
    );
}

export default function SettingsView(): React.JSX.Element {
    const { navigatePop, navigateReplace } = useViewStackNavigate();
    const currentViewStack = useAppSelector(selectCurrentViewStack);

    const isMobile = isMobileBrowser();
    const isDesktop = !isMobile;

    const dialogRef = useDialogOpenRef();
    const view = useAppSelector(selectCurrentView);

    const closeModal = useCallback(() => {
        if (viewUtils.isSettingsView(view)) {
            navigatePop();
        }
    }, [navigatePop, view]);

    const handleBackdropClick = useDialogOutsideClick(dialogRef, closeModal);

    const title = viewUtils.isMediaSettings(view) ? "Audio & video"
        : viewUtils.isProfileSettings(view) ? "Profile"
        : viewUtils.isReportIssue(view) ? "Report issue"
        : viewUtils.isSquadSettings(view) ? "Manage Squads" // Shouldn't ever appear
        : "Settings"; // Shouldn't ever appear

    const navigateToSettingsView = useCallback((view: AppView) => {
        const newStack = viewStackUtils.updateTopOfStack(currentViewStack, _ => view);
        navigateReplace(newStack);
    }, [currentViewStack, navigateReplace]);

    // The squad settings will handle themselves, but the others will share a sidebar
    if (viewUtils.isSquadSettingsView(view)) {
        return <Outlet />;
    }

    const settingsSidebar = (
        <div className="cp-dialog__sidebar">
            <header className="cp-dialog__header">
                <h2 className="cp-dialog__title cp-dialog__title--desktop">Preferences</h2>
            </header>

            <div className="c-dialog-content">
                <div className="c-dialog-content__sidebar">
                    {sidebarEntries.map(entry => (
                        <SettingsSidebarItem
                            key={entry.key}
                            entry={entry}
                            navigateToSettingsView={navigateToSettingsView}
                            isDesktop={isDesktop}
                        />
                    ))}
                </div>

                <VersionInfo />
            </div>
        </div>
    );

    const dialogClass = classNames("cp-dialog", {
        "cp-dialog--desktop": isDesktop,
    });
    const articleClass = classNames("cp-dialog__content-wrapper", {
        "cp-dialog__content-wrapper--desktop": isDesktop,
    });

    return (
        <dialog
            className={dialogClass}
            onClose={closeModal}
            onMouseDown={handleBackdropClick}
            ref={dialogRef}
            role="dialog"
        >
            <article className={articleClass}>
                {settingsSidebar}

                <div className="cp-dialog__content cp-dialog__content--desktop">
                    <header className="cp-dialog__header">
                        <h1 className="cp-dialog__title cp-dialog__title--desktop">
                            {title}
                        </h1>
                        <CloseButton side="right" onClick={closeModal} />
                    </header>

                    <div className="c-dialog-content">
                        <div className="c-dialog-content__content">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </article>
        </dialog>
    );
}
