import * as d from "@/domain/domain";
import { discriminatedUnionValueSchemaBuilder } from "@/misc/zod";
import { z } from "zod";

const NotificationPrioritySchema = z.enum([
    "unspecified",
    "low",
    "medium",
    "high",
    "timesensitive",
    "urgent",
    "critical",
]);
export type NotificationPriority = z.infer<typeof NotificationPrioritySchema>;

export const TextWebNotificationDetailsSchema = z.object({
    text: z.string(),
});

export const ChatMessageWebNotificationDetailsSchema = z.object({
    bondName: z.string(),
    previewText: z.string(),
    bondId: d.bondIdSchema,
    chatMessageId: d.messageIdSchema,
    senderId: d.userIdSchema,
});
export type ChatMessageWebNotificationDetails = z.infer<
    typeof ChatMessageWebNotificationDetailsSchema
>;

export const CallStartWebNotificationDetailsSchema = z.object({
    bondName: z.string(),
    bondId: d.bondIdSchema,
    callId: d.callIdSchema,
    initiatorId: d.userIdSchema,
    initiatorName: z.string(),
});
export type CallStartWebNotificationDetails = z.infer<
    typeof CallStartWebNotificationDetailsSchema
>;

const WebNotificationDetailsSchema = z.discriminatedUnion("case", [
    discriminatedUnionValueSchemaBuilder("textDetails", TextWebNotificationDetailsSchema),
    discriminatedUnionValueSchemaBuilder("chatDetails", ChatMessageWebNotificationDetailsSchema),
    discriminatedUnionValueSchemaBuilder("callStartDetails", CallStartWebNotificationDetailsSchema),
]);

export const WebNotificationSchema = z.object({
    id: d.notificationIdSchema,
    userId: d.userIdSchema,
    eventTs: d.timestampSchema,
    createdTs: d.timestampSchema,
    priority: NotificationPrioritySchema,
    details: WebNotificationDetailsSchema,
});
export type WebNotification = z.infer<typeof WebNotificationSchema>;

export const NotificationOrDismissedSchema = z.discriminatedUnion("case", [
    discriminatedUnionValueSchemaBuilder("notification", WebNotificationSchema),
    discriminatedUnionValueSchemaBuilder("dismissedId", d.notificationIdSchema),
]);
export type NotificationOrDismissed = z.infer<typeof NotificationOrDismissedSchema>;
