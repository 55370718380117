import { RichTextEditorOps, RichTextEditorProps } from "@/components/richtext/RichTextEditor";
import { docBridge } from "@/domain/richtext/bridge";
import { tagChange, tagPosition } from "@/domain/richtext/taggers";
import { asInternalTransform, Transform } from "@/domain/richtext/tiptap/node";
import { configureExtensions, currentSchemaVersion } from "@/domain/richtext/tiptap/schema";
import { Format, PositionRange, Provider } from "@/domain/richtext/types";
import useCallbackRef from "@/hooks/useCallbackRef";
import { isMobileBrowser } from "@/misc/mobile";
import { isApiChangeMetaKey } from "@/misc/tiptap/TransformExtension";
import { andThen } from "@/misc/utils";
import { Selection } from "@tiptap/pm/state";
import { EditorContent, EditorEvents, useEditor } from "@tiptap/react";
import { ForwardedRef, forwardRef, useCallback, useImperativeHandle, useMemo } from "react";

const tagTiptapChange = (transform: Transform) =>
    tagChange(
        asInternalTransform(currentSchemaVersion, transform),
        Provider.Tiptap,
        Format.Internal,
    );

const tagSelectionAsRange = (s: Selection): PositionRange<Provider.Tiptap> => ({
    start: tagPosition(s.from, Provider.Tiptap),
    end: tagPosition(s.to, Provider.Tiptap),
});

export const TiptapRichTextEditor = forwardRef((
    props: RichTextEditorProps<Provider.Tiptap>,
    ref: ForwardedRef<RichTextEditorOps<Provider.Tiptap>>,
): React.JSX.Element => {
    const {
        doc,
        placeholder,
        tabIndex,
        onChange: onChangeProp,
        onSelect: onSelectProp,
    } = props;

    const docJSON = useMemo(
        () => andThen(doc, d => docBridge.from(d).get(Format.JSON)),
        [doc],
    );

    const onUpdateInternal = useCallback(
        ({ transaction }: EditorEvents["update"]) => {
            onChangeProp?.(
                tagTiptapChange(transaction),
                !!transaction.getMeta(isApiChangeMetaKey),
            );
        },
        [onChangeProp],
    );

    const onSelectionUpdateInternal = useCallback(
        ({ editor, transaction }: EditorEvents["selectionUpdate"]) => {
            const newSelection = editor.state.selection;
            onSelectProp?.(
                tagSelectionAsRange(newSelection),
                !!transaction.getMeta(isApiChangeMetaKey),
            );
        },
        [onSelectProp],
    );

    const onUpdate = useCallbackRef(onUpdateInternal);
    const onSelectionUpdate = useCallbackRef(onSelectionUpdateInternal);
    const onSubmit = useCallbackRef(props.onSubmit);
    const onEscape = useCallbackRef(props.onEscape);
    const onShiftEscape = useCallbackRef(props.onShiftEscape);
    const onFocus = useCallbackRef(props.onFocus);
    const onBlur = useCallbackRef(props.onBlur);
    const onSuggestionQuery = useCallbackRef(props.onSuggestionQuery);

    const isMobile = isMobileBrowser();

    const editor = useEditor({
        content: docJSON?.rawDoc.json,
        enableCoreExtensions: {
            tabindex: false, // We use our own extension for configurable tab indices
        },
        extensions: configureExtensions(currentSchemaVersion, {
            placeholder,
            invertSubmit: isMobile,
            onSuggestionQuery,
            onSubmit: isMobile ? undefined : onSubmit,
            onEscape,
            onShiftEscape,
            tabIndex,
        }),
        onUpdate,
        onSelectionUpdate,
        onFocus,
        onBlur,
    }, [
        isMobile,
        placeholder,
        tabIndex,
        onUpdate,
        onSelectionUpdate,
        onSubmit,
        onEscape,
        onShiftEscape,
        onFocus,
        onBlur,
        onSuggestionQuery,
    ]);

    useImperativeHandle(ref, (): RichTextEditorOps<Provider.Tiptap> => ({
        focus: options => editor?.commands.focus(options?.goToEnd ? "end" : null),
        blur: () => editor?.commands.blur(),
        hasFocus: () => editor?.isFocused ?? false,

        setContent: bridge =>
            editor?.commands.setContent(
                bridge
                    .get(Format.JSON)
                    .rawDoc
                    .json,
            ),

        updateContent: bridge =>
            editor?.commands.applyFromJSON(
                bridge
                    .get(Format.JSON)
                    .change
                    .rawChange,
            ),

        getOffsetScreenPos: position => {
            const coords = editor?.view.coordsAtPos(position?.rawPosition ?? 0);
            return {
                x: coords?.left ?? 0,
                y: coords?.top ?? 0,
            };
        },
    }), [editor]);

    return <EditorContent editor={editor} />;
});

export default TiptapRichTextEditor;
