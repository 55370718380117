import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import classNames from "classnames";
import React, {
    ClipboardEventHandler,
    ForwardedRef,
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import { createPortal } from "react-dom";

import AttachmentUploadControls from "@/components/AttachmentUploadControls";
import AudienceHint from "@/components/AudienceHint";
import { CloseButton } from "@/components/buttons/Close";
import CallControls, { CallControlsLocation } from "@/components/gui/CallControls";
import ComposerButton from "@/components/gui/ComposerButton";
import { FileStashContext } from "@/components/managers/AttachmentManager.tsx";
import { MentionAutoComplete } from "@/components/MentionAutoComplete";
import {
    RichTextEditor,
    type RichTextEditorOps,
    SuggestionQuery,
} from "@/components/richtext/RichTextEditor";
import { createProposedAttachment } from "@/domain/attachments.ts";
import { fileToBlobMetadata } from "@/domain/blobs.ts";
import * as d from "@/domain/domain.ts";
import { genLocalAttachmentId } from "@/domain/domain.ts";
import { DraftTarget } from "@/domain/draftTarget";
import { messageHysteresisBondTitleSuggestion, minimumTriggerMessageLength } from "@/domain/intel";
import { Mention } from "@/domain/mentions";
import {
    DraftChatMessage,
    MessageSendableStatus,
    messageSendableStatusText,
} from "@/domain/messages";
import { changeBridge, docBridge, positionBridge } from "@/domain/richtext/bridge";
import { Format, PositionRange, Provider } from "@/domain/richtext/types";
import { bondCreationBucketViewNameSchema } from "@/domain/views";
import { selectCurrentOrgId } from "@/features/auth.ts";
import { clearBondTitleSuggestion } from "@/features/bondCreation";
import {
    addAttachmentsToDraft,
    selectDraft,
    selectDraftDoc,
    selectDraftMessageSendableStatus,
    selectDraftText,
    updateDraftDoc,
} from "@/features/channels";
import { fetchBondTitleSuggestionThunk } from "@/features/intel";
import { MetaInterestCounterKey, selectInterestInKey } from "@/features/interest";
import { useMetaInterest } from "@/hooks/interest/useInterest";
import { useConnectedEffect } from "@/hooks/useConnectedEffect";
import useCurrentBondIsLive from "@/hooks/useCurrentBondIsLive";
import useDebounceWithoutTimeout from "@/hooks/useDebounceWithoutTimeout";
import useDocSync from "@/hooks/useDocSync";
import useOutsideClick from "@/hooks/useOutsideClick";
import usePrevious from "@/hooks/usePrevious";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { getAttachmentDimensionsNoThrow } from "@/misc/attachments.ts";
import log from "@/misc/log.ts";
import { isMobileBrowser } from "@/misc/mobile";
import { Focusable, Optional } from "@/misc/types";
import { selectCurrentUserId, useAppDispatch, useAppSelector } from "@/store/redux";
import type { AppDispatch } from "@/store/types.ts";

export const typingTimeoutDelay = 5 * 1000;

export interface MessageComposerProps {
    id: string;
    draftTarget: DraftTarget;

    msgCompletionAction: (draft: DraftChatMessage | undefined) => void;
    mediaEnableAction?: (draft: DraftChatMessage | undefined) => void;

    numberOfParticipants?: number;
    bondComposer?: boolean;
    placeholder?: string;

    showCallLocationAction?: () => void;

    onModalChange?: (current: boolean) => void;
    onEditorFocus?: () => void;
    onEditorBlur?: () => void;

    escapeAction?: () => void;
    discardAction?: () => void;

    tabIndex?: number;
}

function attachFile(
    file: File,
    fileStash: Map<string, File>,
    draftTarget: DraftTarget,
    dispatch: AppDispatch,
    orgId: d.OrgId,
    userId: d.UserId,
) {
    getAttachmentDimensionsNoThrow(file).then(dimensions => {
        const localId = genLocalAttachmentId();
        fileStash.set(localId, file);
        const initiatedAt = Date.now();
        const attachment = createProposedAttachment({
            localId,
            draftTarget,
            initiatedAt,
            metadata: { ...fileToBlobMetadata(file), dimensions },
            ownership: { uploaderId: userId, orgId: orgId },
        });

        dispatch(addAttachmentsToDraft([attachment]));
    }).catch(e => {
        log.error("Failed to get attachment dimensions", e);
    });
}

export const MessageComposer = forwardRef((
    props: MessageComposerProps,
    ref: ForwardedRef<Focusable>,
): React.JSX.Element => {
    const {
        draftTarget,
        msgCompletionAction: onMessageCompletion,
        mediaEnableAction: onMediaEnable,
        discardAction,
        escapeAction,
        bondComposer,
        placeholder,
        onModalChange,
        onEditorFocus,
        onEditorBlur,
        tabIndex,
        showCallLocationAction,
    } = props;
    const numberOfParticipants = props.numberOfParticipants ?? 0;

    const dispatch = useAppDispatch();

    const bondIsLive = useCurrentBondIsLive();

    const typingTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

    const [currentlyTyping, setCurrentlyTyping] = useState<boolean>(false);

    const draft = useSelectorArgs(selectDraft, draftTarget);
    const draftDoc = useSelectorArgs(selectDraftDoc, draftTarget);
    const draftText = useSelectorArgs(selectDraftText, draftTarget);

    const [editorSelection, setEditorSelection] = useState<
        Optional<PositionRange<Provider>>
    >(undefined);
    const getSelection = useCallback(
        () => editorSelection ?? positionBridge.getInitialRange(draftDoc),
        [editorSelection, draftDoc],
    );

    const fileStash = useContext(FileStashContext);

    const userId = useAppSelector(selectCurrentUserId);
    const orgId = useAppSelector(selectCurrentOrgId);

    const editorRef = useRef<RichTextEditorOps<Provider>>(null);

    const composerRef = useRef<HTMLDivElement>(null);

    const [suggestionQuery, setSuggestionQuery] = useState<
        Optional<SuggestionQuery<Provider>>
    >(undefined);
    const prevMentionOffset = usePrevious(suggestionQuery?.range.start);

    // Mark the mention autocomplete active if a mention query is present and the autocomplete
    // has not already been shown for this mention query offset
    const [mentionAutoCompleteActive, setMentionAutoCompleteActive] = useState(false);
    useEffect(() => {
        if (prevMentionOffset != suggestionQuery?.range.start) {
            setMentionAutoCompleteActive(suggestionQuery !== undefined);
        }
    }, [prevMentionOffset, suggestionQuery]);

    useEffect(() => {
        // Both the window and the editor must be focused to be considered typing
        if (
            !docBridge.from(draftDoc).getIsEmpty() &&
            document.hasFocus() &&
            editorRef.current?.hasFocus()
        ) {
            setCurrentlyTyping(true);

            // Clear the existing timeout and start a new one
            if (typingTimeout.current !== null) {
                clearTimeout(typingTimeout.current);
            }
            typingTimeout.current = setTimeout(() => {
                setCurrentlyTyping(false);
            }, typingTimeoutDelay);
        }
        else {
            setCurrentlyTyping(false);
        }
    }, [draftDoc]);

    useMetaInterest(currentlyTyping, MetaInterestCounterKey.Typing);

    const msgCompletionBlocked = useSelectorArgs(
        selectInterestInKey,
        MetaInterestCounterKey.BlockMsgCompletion,
    );
    const msgSendableStatus = useSelectorArgs(
        selectDraftMessageSendableStatus,
        draftTarget,
        false,
    );
    const canSend = msgSendableStatus == MessageSendableStatus.Ready;
    const complete = useCallback(() => {
        // Suppress completion when e.g. the mention autocomplete is shown with suggestions
        if (msgCompletionBlocked) {
            return;
        }

        if (msgSendableStatus == MessageSendableStatus.Ready) {
            onMessageCompletion(draft);
        }
    }, [draft, msgCompletionBlocked, msgSendableStatus, onMessageCompletion]);

    const onToggleMedia = useCallback((isEnabling: boolean) => {
        if (isEnabling) onMediaEnable?.(draft);
    }, [onMediaEnable, draft]);

    const sendButtonText = messageSendableStatusText(msgSendableStatus);

    const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
    const showEmojiPickerRef = useRef<HTMLButtonElement>(null);
    const outsideClickCallback = useCallback((target: EventTarget | null) => {
        // Filter out clicks on the button which shows the emoji picker itself.
        if (showEmojiPickerRef && showEmojiPickerRef.current?.contains(target as Node)) {
            return;
        }
        setShowEmojiPicker(false);
    }, [showEmojiPickerRef, setShowEmojiPicker]);
    const [emojiPickerDivRef] = useOutsideClick<HTMLDivElement>(outsideClickCallback);

    const insertEmoji = useCallback((emoji: { native: string; }) => {
        const selection = getSelection();

        dispatch(
            updateDraftDoc({
                draftTarget,
                update: changeBridge
                    .on(draftDoc)
                    .spliceText(emoji.native, selection.start, selection.end)
                    .get(Format.JSON),
                isApiChange: true,
            }),
        );

        setShowEmojiPicker(false);

        editorRef.current?.focus();
    }, [dispatch, draftTarget, draftDoc, getSelection]);

    const insertMention = useCallback(
        (mention: Mention, text: string, range: PositionRange<Provider>) => {
            dispatch(
                updateDraftDoc({
                    draftTarget,
                    update: changeBridge
                        .on(draftDoc)
                        .spliceMention(mention, text, range.start, range.end)
                        .get(Format.JSON),
                    isApiChange: true,
                }),
            );
        },
        [dispatch, draftTarget, draftDoc],
    );

    // Notify the parent when a modal opens or closes
    const modalState = mentionAutoCompleteActive || showEmojiPicker;
    const prevModalState = usePrevious(modalState);
    useLayoutEffect(() => {
        if (modalState != prevModalState) {
            onModalChange?.(modalState);
        }
    }, [onModalChange, modalState, prevModalState]);

    // Sync the markup delta between the redux store and the rich text editor
    const onEditorUserChange = useCallback(() => setShowEmojiPicker(false), []);
    const onEditorChange = useDocSync(draftTarget, editorRef, onEditorUserChange);

    // Document-level escape key handler (for when composer is not focussed)
    useEffect(() => {
        if (!showEmojiPicker) return;

        const check = (e: KeyboardEvent) => {
            // Ignore events for all keys bar 'Escape'
            if (e.key != "Escape") return;

            // Always close the emoji viewer if open
            if (!showEmojiPicker) return;

            e.preventDefault();

            setShowEmojiPicker(false);

            editorRef.current?.focus();
        };

        document.addEventListener("keydown", check);
        return () => document.removeEventListener("keydown", check);
    }, [showEmojiPicker]);

    const handleEdgeLinkPreviewHack = useCallback((clipboardData: DataTransfer) => {
        // Edge on Windows does something horrible here. When pasting a GitHub URL, for example,
        // we get 3 string entries as follows:
        // e.clipboardData?.types:
        //  - ["text/html", "text/plain", "text/link-preview"]
        //
        // e.clipboardData?.items:
        //  - kind and type are both empty strings
        //  - the first string is an HTML fragment "<html><body><!--StartFragment--><a href=..."
        //  - the second string is the plain text URL
        //  - the third string is JSON describing some stuff about the GH issue `{"description":"Not fini...`

        // As we don't support markup yet, the best we can do is just to take the plain text URL

        if (
            clipboardData.types.includes("text/link-preview") &&
            clipboardData.types.includes("text/plain")
        ) {
            const content = clipboardData.getData("text/plain");
            const selection = getSelection();

            dispatch(
                updateDraftDoc({
                    draftTarget,
                    update: changeBridge
                        .on(draftDoc)
                        .spliceText(content, selection.start, selection.end)
                        .get(Format.JSON),
                    isApiChange: true,
                }),
            );

            return true;
        }

        return false;
    }, [dispatch, draftTarget, draftDoc, getSelection]);

    const onComposerPaste: ClipboardEventHandler = useCallback(e => {
        if (e.clipboardData && handleEdgeLinkPreviewHack(e.clipboardData)) {
            // Don't give the original event to rich text editor
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        const items = e.clipboardData?.items;
        if (!items) return;

        const files = [...items].filter(item => item.kind === "file");

        if (files.length > 0) {
            if (!userId || !orgId) {
                log.error(`Failed to create attachment: userId=${userId} orgId=${orgId}`);
                return;
            }

            files.forEach(item => {
                const file = item.getAsFile();
                if (file) {
                    attachFile(file, fileStash, draftTarget, dispatch, orgId, userId);
                }
            });

            e.preventDefault();
            e.stopPropagation();
        }
    }, [dispatch, draftTarget, fileStash, userId, orgId, handleEdgeLinkPreviewHack]);

    useEffect(() => {
        const onDrop = (e: DragEvent) => {
            e.preventDefault();

            if (!userId || !orgId) {
                log.error(`Failed to create attachment: userId=${userId} orgId=${orgId}`);
                return;
            }

            if (e.dataTransfer && e.dataTransfer.items) {
                [...e.dataTransfer.items].forEach((item, _) => {
                    if (item.kind === "file") {
                        const file = item.getAsFile();
                        if (file) {
                            attachFile(file, fileStash, draftTarget, dispatch, orgId, userId);
                        }
                    }
                });
            }
        };

        const onDragover = (e: Event) => {
            e.preventDefault(); // apparently we have to do this to enable drop
        };

        document.addEventListener("dragover", onDragover);
        document.addEventListener("drop", onDrop, false);

        return () => {
            document.removeEventListener("drop", onDrop, false);
            document.removeEventListener("dragover", onDragover);
        };
    }, [dispatch, draftTarget, fileStash, userId, orgId]);

    const escapeVariant = useCallback((action: Optional<() => void>) => {
        // Always close the mention autocomplete if open
        if (mentionAutoCompleteActive) {
            setMentionAutoCompleteActive(false);
        }
        // Blur the editor if focussed
        else if (editorRef.current) {
            editorRef.current.blur();
            action?.();
        }
    }, [mentionAutoCompleteActive]);

    const onEditorEscape = useCallback(
        () => escapeVariant(escapeAction),
        [escapeAction, escapeVariant],
    );

    const onEditorShiftEscape = useCallback(
        () => escapeVariant(discardAction),
        [discardAction, escapeVariant],
    );

    const onEditorFocusWrapper = useCallback(() => {
        onEditorFocus?.();
    }, [onEditorFocus]);

    const onEditorBlurWrapper = useCallback(() => {
        onEditorBlur?.();
        setCurrentlyTyping(false);
    }, [onEditorBlur]);

    useImperativeHandle(ref, () => ({
        focus: options => editorRef.current?.focus(options),
        blur: () => editorRef.current?.blur(),
        hasFocus: () => editorRef.current?.hasFocus() ?? false,
    }), []);

    // Block hotkeys when the emoji picker is open
    useMetaInterest(showEmojiPicker, MetaInterestCounterKey.BlockHotkey);

    // Focus the editor when the background of the control area is clicked (visually this
    // looks to the user like part of the editor so it makes sense to focus)
    const onClickControlArea = useCallback((e: React.MouseEvent<HTMLElement>) => {
        if (e.currentTarget === e.target) {
            editorRef.current?.focus({ goToEnd: true });
        }
    }, []);

    const debouncedSuggestionQueryString = useDebounceWithoutTimeout(
        draftText,
        undefined,
        messageHysteresisBondTitleSuggestion,
    );

    useConnectedEffect(() => {
        // We don't generate title suggestions for bonds that already created
        if (numberOfParticipants !== 0) return;
        // Whitespace at the start of the message is removed by
        // the selector. We need whitespace at the end as part of our
        // determination of when to fetch new suggestions. But we don't
        // want to include that whitespace in the soliciting text.
        const solicitingText = debouncedSuggestionQueryString.trimEnd();

        if (!solicitingText) {
            dispatch(clearBondTitleSuggestion(draftTarget));
            return;
        }

        if (solicitingText.length < minimumTriggerMessageLength) return;

        dispatch(
            fetchBondTitleSuggestionThunk({ bondContentDraft: solicitingText, draftTarget }),
        );
    }, [
        dispatch,
        debouncedSuggestionQueryString,
        numberOfParticipants,
        draftTarget,
    ]);

    const showMentionAutoComplete = mentionAutoCompleteActive &&
        !showEmojiPicker &&
        !!suggestionQuery;

    const classes = classNames(
        "c-composer",
        {
            "c-composer--bond": bondComposer,
            "c-composer--live": bondIsLive,
        },
    );

    const showHeader = bondComposer && discardAction;
    const headerClasses = classNames(
        "c-composer__header",
        {
            "c-composer__header--bond": bondComposer,
        },
    );

    // Provide a unique-per-bond-creation-bucket key to force un/remounting
    // of the RichTextEditor. This is required to force it to show the correct
    // content when changing top-level views.
    // Use safeParse and fallback defensively - it should never fail.
    const richTextEditorKey = bondCreationBucketViewNameSchema.safeParse(draftTarget).data ??
        JSON.stringify(draftTarget);

    return (
        <>
            {showMentionAutoComplete && createPortal(
                <MentionAutoComplete
                    query={suggestionQuery}
                    insert={insertMention}
                    draftTarget={draftTarget}
                />,
                document.body,
            )}
            <div className={classes} data-testid={props.id}>
                {showEmojiPicker && (
                    <div ref={emojiPickerDivRef} className="c-emoji-wrapper">
                        <Picker
                            data={data}
                            onEmojiSelect={insertEmoji}
                            emojiSize={19}
                            previewPosition={"none"}
                            theme="dark"
                            autoFocus={true}
                        />
                    </div>
                )}
                {showHeader && (
                    <div className={headerClasses}>
                        <div className="c-composer-header-count">
                            {bondComposer && discardAction && (
                                <CloseButton
                                    title="Discard draft"
                                    onClick={discardAction}
                                    side={"composer"}
                                />
                            )}
                        </div>
                    </div>
                )}
                <div
                    ref={composerRef}
                    className="c-composer__actions"
                    onPasteCapture={onComposerPaste}
                >
                    <AudienceHint draftTarget={draftTarget} />
                    <RichTextEditor
                        key={richTextEditorKey}
                        ref={editorRef}
                        tabIndex={tabIndex}
                        doc={draftDoc}
                        placeholder={placeholder}
                        onChange={onEditorChange}
                        onSelect={setEditorSelection}
                        onSubmit={complete}
                        onEscape={onEditorEscape}
                        onShiftEscape={onEditorShiftEscape}
                        onFocus={onEditorFocusWrapper}
                        onBlur={onEditorBlurWrapper}
                        onSuggestionQuery={setSuggestionQuery}
                    />
                    <div
                        className="c-composer__controls"
                        onClick={onClickControlArea}
                    >
                        <div className="c-composer__btn-group">
                            {!isMobileBrowser() && (
                                <ComposerButton
                                    content="Emojis"
                                    onClick={() => setShowEmojiPicker(old => !old)}
                                    buttonRef={showEmojiPickerRef}
                                    extraStyle={bondIsLive ? "c-btn-composer--emojis-live"
                                        : "c-btn-composer--emojis"}
                                    title="Emoji"
                                />
                            )}
                            <AttachmentUploadControls
                                draftTarget={draftTarget}
                                bondIsLive={bondIsLive}
                            />
                            {showCallLocationAction && (
                                <ComposerButton
                                    content="Set location"
                                    onClick={showCallLocationAction}
                                    extraStyle={bondIsLive ? "c-btn-composer--location-live"
                                        : "c-btn-composer--location"}
                                    title="Set call location"
                                />
                            )}
                        </div>
                        <div className="c-composer__btn-group">
                            <CallControls
                                location={CallControlsLocation.LiveBond}
                                onToggle={onToggleMedia}
                                showScreenshare={!isMobileBrowser()}
                            />
                        </div>
                        <div className="c-composer__btn-group">
                            <ComposerButton
                                content={"Send"}
                                onClick={complete}
                                extraStyle="c-btn-composer--send"
                                disabled={!canSend}
                                title={sendButtonText}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

export default MessageComposer;
