import { RichTextEditorOps, SuggestionQuery } from "@/components/richtext/RichTextEditor";
import { renderDeltaToText } from "@/domain/richtext/quill/delta";
import { tagPosition } from "@/domain/richtext/taggers";
import { Doc, Format, Position, Provider } from "@/domain/richtext/types";
import { selectShowSidebar } from "@/features/meta";
import { useShallowEquals } from "@/hooks/useShallowEquals";
import { Optional } from "@/misc/types";
import { escapeForRegex } from "@/misc/utils";
import { useAppSelector } from "@/store/redux";
import { RefObject, useMemo } from "react";
import useClientBounds from "./useClientBounds";

interface useQuillAutoCompleteQueryOptions {
    maxLength?: number;
}

export const useQuillAutoCompleteQuery = (
    editorRef: RefObject<RichTextEditorOps<Provider.Quill>>,
    triggers: string[],
    doc: Optional<Doc<Provider.Quill, Format.Internal>>,
    position: Optional<Position<Provider.Quill>>,
    options: useQuillAutoCompleteQueryOptions = {},
): Optional<SuggestionQuery<Provider.Quill>> => {
    const maxLength = options.maxLength ?? 1000;

    // Shallow memoise the triggers so an array can be passed directly as an arg
    const memoisedTriggers = useShallowEquals(triggers);

    // Cache the regex instance to avoid unnecessary recompilation
    const re = useMemo(() => {
        const mergedTriggers = memoisedTriggers
            .map(escapeForRegex)
            .map(t => `(?:${t})`)
            .join("|");

        // (?<=^|\\s|\\p{Ps}|\\p{Pe}) : Positive lookbehind for start of string, whitespace, or opening/closing punctuation
        // (${mergedTriggers})        : Any of the given trigger strings
        // ([^\\s\\p{P}]*)$           : Any number of non-whitespace non-punctuation characters until the end of the string
        return new RegExp(`(?<=^|\\s|\\p{Ps}|\\p{Pe})(${mergedTriggers})([^\\s\\p{P}]*)$`, "u");
    }, [memoisedTriggers]);

    // Calculate the query without the anchor position
    const rawDoc = doc?.rawDoc;
    const rawPosition = position?.rawPosition;
    const partialQuery = useMemo(() => {
        if (rawDoc === undefined) return undefined;
        if (rawPosition === undefined) return undefined;

        const windowStart = Math.max(0, rawPosition - maxLength);
        const window = rawDoc.slice(rawPosition - maxLength, rawPosition);
        const windowText = renderDeltaToText(window, false);

        const match = re.exec(windowText) ?? undefined;
        if (match === undefined) return undefined;

        const start = windowStart + match.index;
        const end = start + match[0].length;

        return {
            trigger: match[1],
            text: match[2],
            range: {
                start: tagPosition(start, Provider.Quill),
                end: tagPosition(end, Provider.Quill),
            },
        };
    }, [re, rawDoc, rawPosition, maxLength]);

    // Calculate the anchor position relative to the visual viewport
    const baseAnchor = useMemo(
        () => editorRef.current?.getOffsetScreenPos(partialQuery?.range.end),
        [editorRef, partialQuery],
    );

    // Transform the anchor to be relative to the layout viewport
    const bounds = useClientBounds();
    const showSidebar = useAppSelector(selectShowSidebar);
    const anchor = useMemo(
        () =>
            baseAnchor && {
                x: baseAnchor.x + (bounds.visualViewportOffset?.x ?? 0),
                y: baseAnchor.y + (bounds.visualViewportOffset?.y ?? 0),
            },
        // If the sidebar changes the implementation of `getOffsetScreenPos`
        // may give a different result, so we need to rerun this memo
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [baseAnchor, bounds, showSidebar],
    );

    return useMemo(
        () => anchor && partialQuery && { anchor, ...partialQuery },
        [anchor, partialQuery],
    );
};

export default useQuillAutoCompleteQuery;
