import * as d from "@/domain/domain";
import { AnyOfficialMessage } from "@/domain/messages";
import { PerChannelData } from "@/ds/ChannelMap";
import { Optional } from "@/misc/types";

export interface v29_PerChannelData {
    curatedList: d.AnyMessageId[];
    unreadMessagesIndex: Record<number, AnyOfficialMessage>;
    localSequenceNumber?: number;
    index: Record<d.AnyMessageId, number>;
    unsent: d.UnsentMessageId[];
    pending: Record<d.MessageId, d.UnsentMessageId>;
}

type TranslatePerChannelDataArg = [
    channelId: d.ChannelId,
    oldPerChannelData: Optional<v29_PerChannelData>,
];

export const translatePerChannelData = (
    [channelId, oldPerChannelData]: TranslatePerChannelDataArg,
): Optional<[d.ChannelId, PerChannelData]> => {
    if (!oldPerChannelData) return;

    return [channelId, Object.assign(oldPerChannelData, { channelId })];
};
