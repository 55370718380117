import {
    getNativeTailscaleHost,
    getNativeTarget,
    isNativeAndroidPlatform,
    isNativePlatform,
} from "@/misc/capacitor";

// In this mode we point our local frontend at a remote backend; e.g. bo-nd.dev.
let frontendOnly: string | null = null;

// https://vitejs.dev/guide/env-and-mode
if (
    import.meta.env.VITE_FRONTEND_ONLY === "bondtest.uk" ||
    import.meta.env.VITE_FRONTEND_ONLY === "bo-nd.dev"
) {
    frontendOnly = import.meta.env.VITE_FRONTEND_ONLY;
}

export const isTailscale = location.hostname.endsWith("taildee6.ts.net");
const isLocalhost = location.hostname === "localhost" || location.hostname === "127.0.0.1";
export const isBondTestUk = () =>
    location.hostname === "bondtest.uk" || frontendOnly === "bondtest.uk" ||
    getNativeTarget() === "bondtest.uk";
export const isDevEnv = isLocalhost && !isNativePlatform() && !frontendOnly;

export type EnvironmentConfig = {
    apiBaseUrl: string;
    httpApiBaseUrl: string;
    oidcAuthority: string;
    posthogHost: string;
    baseUrl: string;
    sentryHost: string;
    sigUrl: string;
    tracingUrl: string;
};

export const nativeTargets = {
    bondtestUk: "bondtest.uk",
    bondDev: "bo-nd.dev",
    tailscale: "tailscale",
};

const baseNativeAndroidEnvironment = {};
const baseNativeIosEnvironment = {};

const baseBondTestEnvironment = {
    apiBaseUrl: "wss://api.bondtest.uk",
    httpApiBaseUrl: "https://api.bondtest.uk",
    oidcAuthority: "https://auth.bondtest.uk",
    posthogHost: "https://p.bondtest.uk",
    sentryHost: "s.bondtest.uk",
    sigUrl: "wss://rtc.bondtest.uk/sig",
    tracingUrl: "https://t.bondtest.uk/v1/traces",
    baseUrl: "https://bondtest.uk",
};
const baseBondDevEnvironment = {
    apiBaseUrl: "wss://api.bo-nd.dev",
    httpApiBaseUrl: "https://api.bo-nd.dev",
    oidcAuthority: "https://auth.bo-nd.dev",
    posthogHost: "https://p.bo-nd.dev",
    sentryHost: "s.bo-nd.dev",
    sigUrl: "wss://rtc.bo-nd.dev/sig",
    tracingUrl: "https://t.bo-nd.dev/v1/traces",
    baseUrl: "https://bo-nd.dev",
};

const baseNativeBondTestEnvironment = {
    ...baseBondTestEnvironment,
};
const baseNativeBondDevEnvironment = {
    ...baseBondDevEnvironment,
};

const environmentConfigs = {
    native: {
        android: {
            bondTest: {
                ...baseNativeBondTestEnvironment,
                ...baseNativeAndroidEnvironment,
            },
            bondDev: {
                ...baseNativeBondDevEnvironment,
                ...baseNativeAndroidEnvironment,
            },
        },
        ios: {
            bondTest: {
                ...baseNativeBondTestEnvironment,
                ...baseNativeIosEnvironment,
            },
            bondDev: {
                ...baseNativeBondDevEnvironment,
                ...baseNativeIosEnvironment,
            },
        },
    },
    localhost: {
        apiBaseUrl: "ws://api.localhost:8080",
        httpApiBaseUrl: "http://api.localhost:8080",
        oidcAuthority: "http://auth.localhost:8080",
        posthogHost: "https://us.i.posthog.com",
        baseUrl: "http://localhost:8080",
        sentryHost: "o4507746773172224.ingest.de.sentry.io",
        sigUrl: "ws://rtc.localhost:8080/sig",
        tracingUrl: "http://t.localhost:8080/v1/traces",
    },
    bondTest: {
        ...baseBondTestEnvironment,
    },
    bondTestLocal: {
        ...baseBondTestEnvironment,
    },
    bondDev: {
        ...baseBondDevEnvironment,
    },
    bondDevLocal: {
        ...baseBondDevEnvironment,
    },
};

const getTailscaleConfig = (host?: string) => {
    const tailscaleHost = host || location.hostname;

    return {
        apiBaseUrl: `wss://${tailscaleHost}:8082`,
        httpApiBaseUrl: `https://${tailscaleHost}:8082`,
        oidcAuthority: `https://${tailscaleHost}:8082/auth`,
        posthogHost: "https://us.i.posthog.com",
        // Only used to validate initial auth request,
        // still actually redirects to Tailscale after sign-in
        baseUrl: `http://localhost:8080`,
        sentryHost: "o4507746773172224.ingest.de.sentry.io",
        sigUrl: `wss://${tailscaleHost}:8082/sig`,
        tracingUrl: `https://${tailscaleHost}:8082/t/v1/traces`,
    };
};

export const getEnvironmentConfig = (): EnvironmentConfig => {
    if (frontendOnly) {
        if (frontendOnly === "bondtest.uk") {
            return environmentConfigs.bondTestLocal;
        }
        return environmentConfigs.bondDevLocal;
    }

    const nativeTarget = getNativeTarget();
    if (nativeTarget) {
        const nativeEnvironmentConfig = isNativeAndroidPlatform() ?
            environmentConfigs.native.android : environmentConfigs.native.ios;

        switch (nativeTarget) {
            case nativeTargets.bondtestUk:
                return nativeEnvironmentConfig.bondTest;
            case nativeTargets.bondDev:
                return nativeEnvironmentConfig.bondDev;
            case nativeTargets.tailscale: {
                const host = getNativeTailscaleHost() ?? "";
                return getTailscaleConfig(host);
            }
        }
        return nativeEnvironmentConfig.bondDev;
    }

    if (isDevEnv) {
        return environmentConfigs.localhost;
    }

    if (isTailscale) {
        return getTailscaleConfig();
    }

    if (isBondTestUk()) {
        return environmentConfigs.bondTest;
    }

    return environmentConfigs.bondDev;
};
