import { GetSearchArgs, parseSearchResults, SearchResultResponse } from "@/api/search";
import { SearchResult } from "@/domain/search";
import { selectCurrentUserId } from "@/features/auth";
import { createProxiedAsyncThunk } from "@/features/proxiedThunk";
import { unaryThunkHandler } from "@/features/thunk";
import { checkPersistor } from "@/persist/shared";
import { createLocalSlice } from "@/store/localSlice";
import { RootState } from "@/store/types";
import { PayloadAction } from "@reduxjs/toolkit";

export enum SearchFilterOptions {
    Everything = "Everything",
    Messages = "Messages",
    Bonds = "Bonds",
    Calls = "Calls",
    Attachments = "Attachments",
}

type FetchSearchArgs = Omit<GetSearchArgs, "userId">;
export const searchThunk = createProxiedAsyncThunk(
    "search/search",
    async (args: FetchSearchArgs, thunkAPI): Promise<SearchResultResponse> => {
        if (!args.query) {
            return {
                results: [],
                tags: ["", ""],
            };
        }

        const state = thunkAPI.getState();
        const userId = selectCurrentUserId(state)!;

        return await unaryThunkHandler(
            thunkAPI,
            parseSearchResults({
                userId,
                ...args,
            }),
            "search",
        );
    },
);

export type SearchState = {
    query: string;
    results: Array<SearchResult>;
    highlightMarks: [string, string];
    filter: SearchFilterOptions;
};

const getInitialState = (
    props?: Partial<SearchState>,
): SearchState => ({
    query: props?.query ?? "",
    results: props?.results ?? [],
    highlightMarks: props?.highlightMarks ?? ["", ""],
    filter: props?.filter ?? SearchFilterOptions.Everything,
});

export const searchSlice = createLocalSlice({
    name: "search",
    initialState: getInitialState(),
    selectors: {
        query: state => state.query,
        results: state => state.results,
        highlightMarks: state => state.highlightMarks,
        filter: state => state.filter,
    },
    reducers: {
        setQuery: (state, action: PayloadAction<string>) => {
            state.query = action.payload;
        },
        // Used for resetting the search query for navigation purposes
        // We expect clearQuery to be used outside of the search bar
        clearQuery: state => {
            state.query = "";
        },
        setFilter: (state, action: PayloadAction<SearchState["filter"]>) => {
            state.filter = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(
            searchThunk.fulfilled,
            (state, { payload: f }: PayloadAction<SearchResultResponse>) => {
                state.results = f.results;
                state.highlightMarks = f.tags;
            },
        );
    },
});

export const {
    setQuery,
    clearQuery,
    setFilter,
} = searchSlice.actions;

const selectors = searchSlice.getSelectors((state: RootState) => state.search);
export const {
    query: selectQuery,
    results: selectResults,
    highlightMarks: selectHighlightMarks,
    filter: selectFilter,
} = selectors;

export const reducer = searchSlice.reducer;

// Persistence.
export const persistor = {
    stores: {},
};
checkPersistor<SearchState>(persistor);
