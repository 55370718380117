import useDebouncedOnlineStatus from "@/hooks/useDebouncedOnlineStatus";
import styles from "@/sass/export.module.scss";

function Dot(props: { colour: string; title: string; }): React.JSX.Element {
    const classes = "c-offline-indicator";

    return (
        <svg className={classes}>
            <title>{props.title}</title>
            <circle r={4} fill={props.colour} cx={4} cy={4} />
        </svg>
    );
}

export default function OfflineIndicator(): React.JSX.Element {
    const isOnline = useDebouncedOnlineStatus();

    if (!isOnline) return <Dot colour={styles.red_dark} title="You're offline" />;

    return <></>;
}
