import Avatar from "@/components/gui/Avatar";
import { selectCurrentSquadIds } from "@/features/squads";
import { useInterestedSquads } from "@/hooks/interest/useInterest";
import useAddressParams from "@/hooks/useAddressParams";
import useFreshSquadObservers from "@/hooks/useFreshSquadObservers";
import useHorizontalScroll from "@/hooks/useHorizontalScroll";
import { isMobileBrowser } from "@/misc/mobile";
import { useAppSelector } from "@/store/redux";
import classNames from "classnames";
import React, { useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";

function PresenceHeader(): React.JSX.Element {
    const pathSegments = useLocation().pathname.split("/");
    const viewLocation = pathSegments.length > 1 ? pathSegments[1] : "";
    const { squadId } = useAddressParams();

    const currentUserSquads = useAppSelector(selectCurrentSquadIds);

    const squadsToSubObservers = useMemo(() => {
        if (squadId === undefined) {
            return currentUserSquads;
        }
        return [squadId];
    }, [squadId, currentUserSquads]);

    useInterestedSquads(squadsToSubObservers);
    const sortedSquadObservers = useFreshSquadObservers(squadsToSubObservers);

    const avatarsRef = useRef<HTMLDivElement>(null);
    const onWheelAvatar = useHorizontalScroll(avatarsRef);

    // This logic is getting really nasty. Does it ever evaluate to false?
    const showPresenceHeader = viewLocation == "bond" ||
        viewLocation == "search" ||
        viewLocation == "squad" ||
        (pathSegments[1] == "mobile" && pathSegments[2] == "tab" && pathSegments[3] == "mybonds");

    if (!showPresenceHeader) {
        return <></>;
    }

    const isMobile = isMobileBrowser();

    const presenceClasses = classNames("c-presence", {
        "c-presence--inbox-desktop": !isMobile,
        "c-presence--inbox": isMobile,
    });

    const humansClasses = classNames("c-presence__humans", {
        "c-presence__humans--inbox-desktop": !isMobile,
        "c-presence__humans--inbox": isMobile,
    });

    return (
        <div
            ref={avatarsRef}
            className={presenceClasses}
            onWheel={onWheelAvatar}
        >
            <div className={humansClasses}>
                {sortedSquadObservers.map(userId => (
                    <Avatar
                        id={userId}
                        key={userId}
                        showPresence={true}
                        size="presence"
                    />
                ))}
            </div>
        </div>
    );
}

export default PresenceHeader;
