import React, { useCallback } from "react";
import { Outlet } from "react-router-dom";

import { ButtonMain } from "@/components/buttons/ButtonMain";
import { CloseButton } from "@/components/buttons/Close";
import { SidebarItem } from "@/components/buttons/SidebarItem";
import { FeatureFlagged } from "@/components/FeatureFlags";
import Avatar from "@/components/gui/Avatar";
import SensitiveText from "@/components/gui/SensitiveText";
import * as d from "@/domain/domain";
import { userNameForMention } from "@/domain/mentions";
import { SquadOverview } from "@/domain/squads";
import { PersonOverview, UserOverview, userSortByName } from "@/domain/users";
import { viewStackUtils, viewUtils } from "@/domain/views";
import { selectCurrentView, selectCurrentViewStack } from "@/features/filterPanel";
import {
    selectCurrentSquadIdsOrderedBySquadName,
    selectCurrentUserInSquad,
    selectSquadById,
    selectSquadIdFromViewStack,
    selectSquads,
} from "@/features/squads";
import { selectUsers } from "@/features/users";
import useViewStackNavigate from "@/hooks/navigation/useViewStackNavigate";
import useDialogOpenRef from "@/hooks/useDialogOpenRef";
import useDialogOutsideClick from "@/hooks/useDialogOutsideClick";
import { useSquadInvitees } from "@/hooks/useInvitees";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { isMobileBrowser } from "@/misc/mobile";
import { Optional } from "@/misc/types";
import { selectCurrentUserId, useAppSelector } from "@/store/redux";
import classNames from "classnames";

const UserEntryContent = (
    props: { user: UserOverview; squad: Optional<SquadOverview>; closeModal: () => void; },
) => {
    const { navigatePush } = useViewStackNavigate();
    const { user, squad } = props;

    const currentUserId = useSelectorArgs(selectCurrentUserId);
    const isMobile = isMobileBrowser();
    const isDesktop = !isMobile;

    const removingSelf = currentUserId === user.id;
    const removeLabel = removingSelf ? "Leave squad" : "Remove from squad";

    const showRemoveUserAction = useCallback(() => {
        if (!squad) return;
        navigatePush(viewUtils.squadSettingsRemove(squad.id, user.id));
    }, [navigatePush, squad, user.id]);

    // This will be false for invited user entries
    const hasOptions = true;

    return (
        <div className="c-squad-human">
            <Avatar
                id={props.user.id}
                showPresence={false}
                size="squad"
                context={{ isSquadListing: true }}
            />

            <div
                className={`c-squad-human__name ${
                    isDesktop ? "c-squad-human__name--desktop" : ""
                } u-truncate-auto`}
            >
                <SensitiveText>{userNameForMention(props.user)}</SensitiveText>&nbsp;
                <span>
                    &bull;&nbsp;<SensitiveText>{props.user.name}</SensitiveText>
                </span>
            </div>

            {hasOptions && (
                <div className="cp-popover-wrapper">
                    <button
                        className="cp-btn-options"
                        aria-label="Options"
                        title={"Options"}
                    />
                    <div className={`cp-popover`}>
                        <button
                            className="cp-popover__item cp-popover__item--warning"
                            onClick={showRemoveUserAction}
                        >
                            {removeLabel}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

const InvitedPersonEntryContent = (props: { person: PersonOverview; isDesktop: boolean; }) => (
    <div className="c-squad-human">
        <Avatar
            id={props.person.personId}
            modifiers={{ invited: true }}
            showPresence={false}
            size="squad"
            context={{ isSquadListing: true }}
        />
        <div
            className={`c-squad-human__name ${
                props.isDesktop ? "c-squad-human__name--desktop" : ""
            } u-truncate-auto`}
        >
            <SensitiveText>{props.person.name}</SensitiveText>&nbsp;&bull;&nbsp;<SensitiveText>
                {props.person.email}
            </SensitiveText>
            <div
                className={`c-squad-human__status ${
                    props.isDesktop ? "c-squad-human__status--desktop" : ""
                } u-truncate-auto`}
            >
                Invited
            </div>
        </div>
    </div>
);

const InvitedEmailEntryContent = (props: { email: string; isDesktop: boolean; }) => (
    <div className="c-squad-human">
        <div className="c-squad-human__invite-icon"></div>
        <div
            className={`c-squad-human__name ${
                props.isDesktop ? "c-squad-human__name--desktop" : ""
            } u-truncate-auto`}
        >
            <SensitiveText>{props.email}</SensitiveText>
            <div
                className={`c-squad-human__status ${
                    props.isDesktop ? "c-squad-human__status--desktop" : ""
                } u-truncate-auto`}
            >
                Invited
            </div>
        </div>
    </div>
);

function SquadListing({ squadId, closeModal }: { squadId: d.SquadId; closeModal: () => void; }) {
    const { navigatePush } = useViewStackNavigate();

    const isMobile = isMobileBrowser();
    const isDesktop = !isMobile;

    const squad = useSelectorArgs(selectSquadById, squadId);
    const sortedSquadUsers = useSelectorArgs(selectUsers, squad?.userIds, { sort: userSortByName });

    const {
        emails: filteredInvitedEmails,
        persons: filteredInvitedPersons,
    } = useSquadInvitees(squadId);

    const showUserInvitationAction = useCallback(() => {
        navigatePush(viewUtils.squadSettingsInvite(squadId));
    }, [navigatePush, squadId]);

    const squadName = squad?.name || "Unknown Squad";

    const showRenameSquadAction = useCallback(() => {
        navigatePush(viewUtils.squadSettingsRename(squadId));
    }, [navigatePush, squadId]);

    const headerH1Class = classNames("cp-dialog__title", {
        "cp-dialog__title--desktop": isDesktop,
    });
    const squadHeader = (
        <header className="cp-dialog__header cp-dialog__header--action">
            <h1 className={headerH1Class}>
                {squadName}
            </h1>
            <CloseButton side="right" onClick={closeModal} />
            <button
                className="c-btn-change-name"
                onClick={showRenameSquadAction}
            >
                Change name
            </button>
        </header>
    );

    const membersCount = (
        <div className="c-squad-count">
            Members&nbsp;<span>&middot;&nbsp;{sortedSquadUsers.length}</span>
        </div>
    );

    const membersBoxClass = classNames("cp-squad-listing", {
        "cp-squad-listing--desktop": isDesktop,
    });
    const membersBox = (
        <div className={membersBoxClass}>
            <button
                className="cp-btn-add-people"
                onClick={showUserInvitationAction}
            >
                <div className="cp-btn-add-people__icon" />
                Add people
            </button>

            <div className="cp-squad-listing__list">
                {filteredInvitedEmails.map(email => (
                    <InvitedEmailEntryContent
                        key={email}
                        email={email}
                        isDesktop={isDesktop}
                    />
                ))}
                {filteredInvitedPersons.map(invitee => (
                    <InvitedPersonEntryContent
                        key={invitee.personId}
                        person={invitee}
                        isDesktop={isDesktop}
                    />
                ))}
                {sortedSquadUsers.map(user => (
                    <UserEntryContent
                        key={user.id}
                        user={user}
                        squad={squad}
                        closeModal={closeModal}
                    />
                ))}
            </div>
            <Outlet />
        </div>
    );

    const squadListingClass = classNames("cp-dialog__content", {
        "cp-dialog__content--desktop": isDesktop,
    });

    return (
        <div className={squadListingClass}>
            {squadHeader}

            <div className="c-dialog-content">
                <div className="c-dialog-content__content">
                    {membersCount}
                    {membersBox}
                </div>
            </div>
        </div>
    );
}

function SquadSidebarItem(
    props: {
        squad: SquadOverview;
        selectedSquadId: Optional<d.SquadId>;
        setSelectedSquadId: (s: d.SquadId) => void;
        isDesktop: boolean;
    },
): React.JSX.Element {
    const { squad, selectedSquadId, setSelectedSquadId, isDesktop } = props;

    const selectThisSquad = useCallback(() => {
        setSelectedSquadId(squad.id);
    }, [setSelectedSquadId, squad.id]);

    return (
        <SidebarItem
            key={squad.id}
            label={squad.name}
            hasFocus={selectedSquadId === squad.id}
            onClick={selectThisSquad}
            isDesktop={isDesktop}
        />
    );
}

export default function SquadSettingsView(): React.JSX.Element {
    const squadIds = useAppSelector(selectCurrentSquadIdsOrderedBySquadName);
    const squads = useSelectorArgs(selectSquads, squadIds);
    const squadId = useAppSelector(selectSquadIdFromViewStack);
    const { navigatePop } = useViewStackNavigate();

    const dialogRef = useDialogOpenRef();
    const currentView = useAppSelector(selectCurrentView);
    const closeModal = useCallback(() => {
        if (viewUtils.isSquadSettings(currentView)) {
            navigatePop();
        }
    }, [navigatePop, currentView]);
    const handleBackdropClick = useDialogOutsideClick(dialogRef, closeModal);

    const currentViewStack = useAppSelector(selectCurrentViewStack);
    const { navigateReplace, navigatePush } = useViewStackNavigate();

    const selectedSquadId = squadId;
    const setSelectedSquadId = useCallback((id: d.SquadId) => {
        const newStack = viewStackUtils.updateTopOfStack(currentViewStack, _ => {
            return viewUtils.squadSettings(id);
        });
        navigateReplace(newStack);
    }, [navigateReplace, currentViewStack]);

    const currentUserInSquad = useSelectorArgs(selectCurrentUserInSquad, selectedSquadId);

    const goToCreateSquad = useCallback(() => {
        navigatePush(viewUtils.squadSettingsCreate());
    }, [navigatePush]);

    const isMobile = isMobileBrowser();
    const isDesktop = !isMobile;

    const squadListingHeaderClass = classNames("cp-dialog__title", {
        "cp-dialog__title": isDesktop,
    });

    const squadSettingsSidebar = (
        <div className="cp-dialog__sidebar">
            <header className="cp-dialog__header">
                <h2 className={squadListingHeaderClass}>
                    Squads
                </h2>
            </header>

            <div className="c-dialog-content">
                <div className="c-dialog-content__sidebar">
                    <ButtonMain
                        icon="add"
                        label="Add squad"
                        onClick={goToCreateSquad}
                        isSecondary
                    />
                    <div className="cp-sidebar-link-group">
                        {squads.map(squad => (
                            <SquadSidebarItem
                                key={squad.id}
                                squad={squad}
                                selectedSquadId={selectedSquadId}
                                setSelectedSquadId={setSelectedSquadId}
                                isDesktop={isDesktop}
                            />
                        ))}
                    </div>
                </div>

                <div className="c-squad-info">
                    <div className="c-squad-info__icon" />
                    Squads help people communicate openly and stay in the loop.
                </div>
            </div>
        </div>
    );

    const dialogClass = classNames("cp-dialog", {
        "cp-dialog--desktop": isDesktop,
    });
    const articleClass = classNames("cp-dialog__content-wrapper", {
        "cp-dialog__content-wrapper--desktop": isDesktop,
    });

    const squadSettingsDialog = (
        <dialog
            className={dialogClass}
            role="dialog"
            onClose={closeModal}
            onMouseDown={handleBackdropClick}
            ref={dialogRef}
        >
            <article className={articleClass}>
                {squadSettingsSidebar}
                {selectedSquadId && currentUserInSquad && (
                    <SquadListing squadId={selectedSquadId} closeModal={closeModal} />
                )}
            </article>
        </dialog>
    );

    return (
        <FeatureFlagged flag={"show-squads-management-frontend"} match={true}>
            {squadSettingsDialog}
        </FeatureFlagged>
    );
}
