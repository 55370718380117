import { z, ZodSchema } from "zod";

import * as d from "@/domain/domain";
import { isMobileBrowser } from "@/misc/mobile";
import shallowEqual from "@/misc/shallowEqual";
import { Optional } from "@/misc/types";
import { zodAddIssue } from "@/misc/zod";

const uuidSchema = z.string().uuid();

// region View names

/** Names for all known views.
 *
 * Roughly equivalent to all elements that can be rendered by the route table.
 */
export const viewNameSchema = z.enum([
    "inbox",
    "discover",
    "mySquads",
    "newBond",
    "squad",
    "singleBond",
    "inviteRedemption", // sic
    "login",
    "search",
    "mediaSettings",
    "profileSettings",
    "reportIssue",
    "squadSettings",
    "squadSettingsCreate",
    "squadSettingsInvite",
    "squadSettingsRename",
    "squadSettingsRemove",
]);
export const viewNames = viewNameSchema.Enum;
export type AppViewName = z.infer<typeof viewNameSchema>;

const squadTopLevelViewNamePrefix = "squad-";
/** A string-y name to use for the "squad" view.
 *
 * This is required in places where we need to use the view as an index,
 * but since the view itself is an object we need some coercion to a string.
 */
const squadTopLevelViewNameSchema = z.string()
    .startsWith(squadTopLevelViewNamePrefix)
    .refine(v => uuidSchema.safeParse(v.slice(squadTopLevelViewNamePrefix.length)).success);

/** All top-level view names.
 *
 * This includes top-level view names for mobile and desktop combined. Runtime
 * checks are applied to disallow usage of, e.g. "my squads" as a top-level view
 * on desktop.
 */
export const topLevelViewNameSchema = z.union([
    z.literal(viewNames.inbox),
    z.literal(viewNames.discover),
    z.literal(viewNames.mySquads),
    z.literal(viewNames.inviteRedemption),
    squadTopLevelViewNameSchema,
]);
export type TopLevelViewName = z.infer<typeof topLevelViewNameSchema>;

/** The allowable view names which can be used for `DraftTarget`s for
 * bond-creation.
 */
export const bondCreationBucketViewNameSchema = z.union([
    z.literal(viewNames.inbox),
    z.literal(viewNames.discover),
    squadTopLevelViewNameSchema,
]);
export type BondCreationBucketViewName = z.infer<typeof bondCreationBucketViewNameSchema>;

// region Views

const inboxView = z.object({
    view: z.literal(viewNames.inbox),
    summary: z.boolean().optional(),
});
const discoverView = z.object({ view: z.literal(viewNames.discover) });
const mySquadsView = z.object({ view: z.literal(viewNames.mySquads) });
const newBondView = z.object({ view: z.literal(viewNames.newBond) });
const loginView = z.object({ view: z.literal(viewNames.login) });
const searchView = z.object({ view: z.literal(viewNames.search) });
const mediaSettingsView = z.object({ view: z.literal(viewNames.mediaSettings) });
const profileSettingsView = z.object({ view: z.literal(viewNames.profileSettings) });
const reportIssueView = z.object({ view: z.literal(viewNames.reportIssue) });

const singleBondView = z.object({
    view: z.literal(viewNames.singleBond),
    id: d.bondIdSchema,
    messageId: d.messageIdSchema.optional(),
    summary: z.boolean().optional(),
});

const squadView = z.object({
    view: z.literal(viewNames.squad),
    id: d.squadIdSchema,
    summary: z.boolean().optional(),
});

const squadSettingsView = z.object({
    view: z.literal(viewNames.squadSettings),
    id: d.squadIdSchema.optional(),
});
const squadSettingsCreateView = z.object({
    view: z.literal(viewNames.squadSettingsCreate),
});
const squadSettingsInviteView = z.object({
    view: z.literal(viewNames.squadSettingsInvite),
    id: d.squadIdSchema,
    // wasRedirectedFromCreate: z.boolean().optional(), // TODO
});
const squadSettingsRenameView = z.object({
    view: z.literal(viewNames.squadSettingsRename),
    id: d.squadIdSchema,
});
const squadSettingsRemoveView = z.object({
    view: z.literal(viewNames.squadSettingsRemove),
    id: d.squadIdSchema,
    userId: d.userIdSchema,
});

/** Converts a string-y squad name to a `squadView`. */
const squadTopLevelViewSchema = squadTopLevelViewNameSchema.transform(v => {
    const uuid = v.slice(squadTopLevelViewNamePrefix.length);
    return viewUtils.squad(d.fromRawSquadId(uuid));
}).pipe(squadView);

const inviteRedemptionView = z.object({
    view: z.literal(viewNames.inviteRedemption),
    inviteCode: z.string().min(1),
});

/** All known views within the app that take part in the viewstacks. */
export const viewSchema = z.discriminatedUnion("view", [
    inboxView,
    discoverView,
    squadView,
    singleBondView,
    mySquadsView,
    newBondView,
    inviteRedemptionView,
    loginView,
    searchView,
    mediaSettingsView,
    profileSettingsView,
    reportIssueView,
    squadSettingsView,
    squadSettingsCreateView,
    squadSettingsInviteView,
    squadSettingsRenameView,
    squadSettingsRemoveView,
]);
export type AppView = z.output<typeof viewSchema>;

/** Helper object for referencing the schemas for the views. */
export const viewSchemaMap = {
    inbox: inboxView,
    discover: discoverView,
    mySquads: mySquadsView,
    newBond: newBondView,
    squad: squadView,
    singleBond: singleBondView,
    inviteRedemption: inviteRedemptionView,
    login: loginView,
    search: searchView,
    mediaSettings: mediaSettingsView,
    profileSettings: profileSettingsView,
    reportIssue: reportIssueView,
    squadSettings: squadSettingsView,
    squadSettingsCreate: squadSettingsCreateView,
    squadSettingsInvite: squadSettingsInviteView,
    squadSettingsRename: squadSettingsRenameView,
    squadSettingsRemove: squadSettingsRemoveView,
};

const newOrSingleBondOrSearch = [singleBondView, newBondView, searchView];
const settingsViews = [mediaSettingsView, squadSettingsView, profileSettingsView, reportIssueView];
const squadSettingsViews = [
    squadSettingsCreateView,
    squadSettingsInviteView,
    squadSettingsRenameView,
    squadSettingsRemoveView,
];

/** Define which views can live as children of other views within a stack.
 */
export const viewTreeSchema: Partial<Record<AppViewName, ZodSchema[]>> = {
    [viewNames.inbox]: [...newOrSingleBondOrSearch, inviteRedemptionView, ...settingsViews],
    [viewNames.discover]: [...newOrSingleBondOrSearch, ...settingsViews],
    [viewNames.squad]: [...newOrSingleBondOrSearch, ...settingsViews],
    [viewNames.mySquads]: [squadView],
    [viewNames.search]: [singleBondView],
    [viewNames.singleBond]: [...settingsViews],
    [viewNames.squadSettings]: [...squadSettingsViews],
};

// TODO: Consider how settingsViews work on mobile.

// region Viewstack

/** A schema for permissible viewstacks.
 *
 * These must be a non-empty array of views, each of which is permissible by
 * the relations defined by `viewTreeSchema`.
 */
export const viewStackSchema = viewSchema.array().nonempty().superRefine((views, ctx) => {
    for (let i = 0; i < views.length - 1; i++) {
        const currentView = views[i];

        const allowedSchemas = viewTreeSchema[currentView.view];

        if (!allowedSchemas) {
            zodAddIssue(ctx, `${currentView.view} has no allowed next views`, [i]);
            continue;
        }

        const nextView = views[i + 1];
        const isValidTransition = allowedSchemas.some(
            schema => schema.safeParse(nextView).success,
        );

        if (!isValidTransition) {
            zodAddIssue(
                ctx,
                `Invalid view transition ${currentView.view} -> ${nextView.view}`,
                [i + 1],
            );
            continue;
        }
    }
});
export type AppViewStack = z.infer<typeof viewStackSchema>;

// region Utilities

/** Helper object for utilities for views. */
export const viewUtils = {
    /** Are two views equal? */
    viewsEqual: (a: Optional<AppView>, b: Optional<AppView>) => shallowEqual(a, b),

    /** Create an "inbox" view. */
    inbox: (summary?: boolean) =>
        viewSchemaMap.inbox.parse({ view: viewNames.inbox, summary: summary }),
    /** Check if a view is "inbox". */
    isInbox: (v: Optional<AppView>): v is z.infer<typeof viewSchemaMap.inbox> =>
        v?.view === viewNames.inbox,
    /** Check if a stack has one of its frames as "inbox". */
    hasInbox: (stack: Optional<AppViewStack>) => !!stack?.some(viewUtils.isInbox),

    /** Create a "discover" view. */
    discover: () => viewSchemaMap.discover.parse({ view: viewNames.discover }),
    /** Check if a view is "discover". */
    isDiscover: (v: Optional<AppView>): v is z.infer<typeof viewSchemaMap.discover> =>
        v?.view === viewNames.discover,
    /** Check if a stack has one of its frames as "discover". */
    hasDiscover: (stack: Optional<AppViewStack>) => !!stack?.some(viewUtils.isDiscover),

    /** Create a "my squads" view. */
    mySquads: () => viewSchemaMap.mySquads.parse({ view: viewNames.mySquads }),
    /** Check if a view is "my squads". */
    isMySquads: (v: Optional<AppView>): v is z.infer<typeof viewSchemaMap.mySquads> =>
        v?.view === viewNames.mySquads,
    /** Check if a stack has one of its frames as "my squads". */
    hasMySquads: (stack: Optional<AppViewStack>) => !!stack?.some(viewUtils.isMySquads),

    /** Create a "squad" view. */
    squad: (id: d.SquadId, summary?: boolean) =>
        viewSchemaMap.squad.parse({ view: viewNames.squad, id, summary }),
    /** Check if a view is "squad". */
    isSquad: (v: Optional<AppView>): v is z.infer<typeof viewSchemaMap.squad> =>
        v?.view === viewNames.squad,
    /** Check if a stack has one of its frames as "squad" with the given id. */
    hasSquad: (stack: Optional<AppViewStack>, id: d.SquadId) =>
        !!stack?.some(frame => viewUtils.isSquad(frame) && frame.id === id),

    /** Check if a stack has one of its frames as "squad". */
    hasAnySquad: (stack: Optional<AppViewStack>) =>
        !!stack?.some(frame => viewUtils.isSquad(frame)),

    /** Check if the "squad" tab should be highlighted. */
    highlightForSquadTab: (stack: Optional<AppViewStack>) =>
        !!stack?.some(frame => viewUtils.isMySquads(frame) || viewUtils.isSquad(frame)),

    /** Create a "new bond" view. */
    newBond: () => viewSchemaMap.newBond.parse({ view: viewNames.newBond }),
    /** Check if a view is "new bond". */
    isNewBond: (v: Optional<AppView>): v is z.infer<typeof viewSchemaMap.newBond> =>
        v?.view === viewNames.newBond,

    /** Create a "single bond" view. */
    singleBond: (
        id: d.BondId,
        messageId?: d.MessageId,
        summary?: boolean,
    ) => viewSchemaMap.singleBond.parse({
        view: viewNames.singleBond,
        id,
        messageId,
        summary,
    }),
    /** Check if a view is "single bond". */
    isSingleBond: (v: Optional<AppView>): v is z.infer<typeof viewSchemaMap.singleBond> =>
        v?.view === viewNames.singleBond,
    /** Check if a stack has one of its frames as "single bond" with the given id. */
    hasSingleBond: (stack: Optional<AppViewStack>, id: d.BondId) =>
        !!stack?.some(frame => viewUtils.isSingleBond(frame) && frame.id === id),

    /** Create a "invite redemption" view. */
    inviteRedemption: (
        inviteCode: string,
    ) => viewSchemaMap.inviteRedemption.parse({
        view: viewNames.inviteRedemption,
        inviteCode,
    }),
    /** Check if a view is "invite redemption". */
    isInviteRedemption: (
        v: Optional<AppView>,
    ): v is z.infer<typeof viewSchemaMap.inviteRedemption> =>
        v?.view === viewNames.inviteRedemption,

    /** Create a "login" view. */
    login: () => viewSchemaMap.login.parse({ view: viewNames.login }),
    /** Check if a view is "login". */
    isLogin: (v: Optional<AppView>): v is z.infer<typeof viewSchemaMap.login> =>
        v?.view === viewNames.login,

    /** Create a "search" view. */
    search: () => viewSchemaMap.search.parse({ view: viewNames.search }),
    /** Check if a view is "search". */
    isSearch: (v: Optional<AppView>): v is z.infer<typeof viewSchemaMap.search> =>
        v?.view === viewNames.search,

    /** Check if a stack has one of its frames as "search". */
    hasSearch: (stack: Optional<AppViewStack>) => !!stack?.some(frame => viewUtils.isSearch(frame)),

    /** Convert a "squad" view to its string name.  */
    topLevelSquadName: (view: z.infer<typeof viewSchemaMap.squad>): TopLevelViewName =>
        squadTopLevelViewNamePrefix + d.extractUUID(view.id),

    /** Check if a view stack should display the mobile tab bar. */
    isMobileTabsViewShown: (stack: Optional<AppViewStack>) => {
        const leafView = stack?.at(-1);
        return viewUtils.isInbox(leafView) ||
            viewUtils.isMySquads(leafView) ||
            viewUtils.isSquad(leafView) ||
            viewUtils.isDiscover(leafView);
    },

    /** Create a "media settings" view */
    mediaSettings: () => viewSchemaMap.mediaSettings.parse({ view: viewNames.mediaSettings }),
    /** Check if a view is "media settings" */
    isMediaSettings: (v: Optional<AppView>): v is z.infer<typeof viewSchemaMap.mediaSettings> =>
        v?.view === viewNames.mediaSettings,

    /** Create an "profile settings" view */
    profileSettings: () => viewSchemaMap.profileSettings.parse({ view: viewNames.profileSettings }),
    /** Check if a view is "profile settings" */
    isProfileSettings: (v: Optional<AppView>): v is z.infer<typeof viewSchemaMap.profileSettings> =>
        v?.view === viewNames.profileSettings,

    /** Create a "report issue" view */
    reportIssue: () => viewSchemaMap.reportIssue.parse({ view: viewNames.reportIssue }),
    /** Check if a view is "report issue" */
    isReportIssue: (v: Optional<AppView>): v is z.infer<typeof viewSchemaMap.reportIssue> =>
        v?.view === viewNames.reportIssue,

    /** Create a "squad settings" view */
    squadSettings: (id?: d.SquadId) =>
        viewSchemaMap.squadSettings.parse({ view: viewNames.squadSettings, id }),
    /** Check if a view is "squad settings" */
    isSquadSettings: (v: Optional<AppView>): v is z.infer<typeof viewSchemaMap.squadSettings> =>
        v?.view === viewNames.squadSettings,
    /** Check if a stack has one of its frames as "squad settings" with the given id. */
    hasSquadSettings: (stack: Optional<AppViewStack>, id: d.SquadId) =>
        !!stack?.some(frame => viewUtils.isSquadSettings(frame) && frame.id === id),

    /** Create a "squad settings" view for creating a new squad. */
    squadSettingsCreate: () =>
        viewSchemaMap.squadSettingsCreate.parse({
            view: viewNames.squadSettingsCreate,
        }),
    /** Check if a view is "squad settings" for creating a new squad. */
    isSquadSettingsCreate: (
        v: Optional<AppView>,
    ): v is z.infer<typeof viewSchemaMap.squadSettingsCreate> =>
        v?.view === viewNames.squadSettingsCreate,

    /** Create a "squad settings" view for inviting users to a squad. */
    squadSettingsInvite: (id: d.SquadId) =>
        viewSchemaMap.squadSettingsInvite.parse({
            view: viewNames.squadSettingsInvite,
            id,
        }),
    /** Check if a view is "squad settings" for inviting users to a squad. */
    isSquadSettingsInvite: (
        v: Optional<AppView>,
    ): v is z.infer<typeof viewSchemaMap.squadSettingsInvite> =>
        v?.view === viewNames.squadSettingsInvite,

    /** Create a "squad settings" view for renaming a squad. */
    squadSettingsRename: (id: d.SquadId) =>
        viewSchemaMap.squadSettingsRename.parse({
            view: viewNames.squadSettingsRename,
            id,
        }),
    /** Check if a view is "squad settings" for renaming a squad. */
    isSquadSettingsRename: (
        v: Optional<AppView>,
    ): v is z.infer<typeof viewSchemaMap.squadSettingsRename> =>
        v?.view === viewNames.squadSettingsRename,

    /** Create a "squad settings" view for removing a user from a squad. */
    squadSettingsRemove: (id: d.SquadId, userId: d.UserId) =>
        viewSchemaMap.squadSettingsRemove.parse({
            view: viewNames.squadSettingsRemove,
            id,
            userId,
        }),
    /** Check if a view is "squad settings" for removing a user from a squad. */
    isSquadSettingsRemove: (
        v: Optional<AppView>,
    ): v is z.infer<typeof viewSchemaMap.squadSettingsRemove> =>
        v?.view === viewNames.squadSettingsRemove,

    /** Check if a view is one of the settings views */
    isSettingsView: (v: Optional<AppView>) =>
        viewUtils.isSquadSettings(v) ||
        viewUtils.isProfileSettings(v) ||
        viewUtils.isMediaSettings(v) ||
        viewUtils.isReportIssue(v),
    /** Check if a stack includes a settings view as any of its frames. */
    hasSettingsView: (stack: Optional<AppViewStack>) =>
        !!stack?.some(frame => viewUtils.isSettingsView(frame)),

    /** Check if a view is one of the squad settings views */
    isSquadSettingsView: (v: Optional<AppView>) =>
        viewUtils.isSquadSettings(v) ||
        viewUtils.isSquadSettingsCreate(v) ||
        viewUtils.isSquadSettingsInvite(v) ||
        viewUtils.isSquadSettingsRename(v) ||
        viewUtils.isSquadSettingsRemove(v),

    /** Check if a view is top-level or not.
     *
     * Mobile/desktop dependent!
     */
    isTopLevel: (v: AppView): boolean => {
        const name = viewUtils.toTopLevelName(v);
        const p = topLevelViewNameSchema.safeParse(name);
        if (!p.success) return false;

        // Type versus runtime-value mismatch.
        if (isMobileBrowser() && viewUtils.isSquad(v)) return false;
        if (!isMobileBrowser() && viewUtils.isMySquads(v)) return false;

        return true;
    },
    /** Convert a view to its top-level name, if it is a top-level view.
     *
     * Mobile/desktop dependent!
     */
    toTopLevelName: (v: Optional<AppView>): z.infer<typeof topLevelViewNameSchema> | undefined => {
        switch (v?.view) {
            case viewNames.inbox:
            case viewNames.discover:
                return v.view;
            case viewNames.mySquads:
                return isMobileBrowser() ? v.view : undefined;
            case viewNames.squad:
                return isMobileBrowser() ? undefined : viewUtils.topLevelSquadName(v);
        }
    },
    /** Parse a top-level name to its associated view.
     *
     * Mobile/desktop dependent!
     */
    parseTopLevelName: (viewName: TopLevelViewName): Optional<AppView> => {
        switch (viewName) {
            case viewNames.inbox:
                return viewUtils.inbox();
            case viewNames.discover:
                return viewUtils.discover();
            case viewNames.mySquads:
                return isMobileBrowser() ? viewUtils.mySquads() : undefined;
        }
        const p = squadTopLevelViewSchema.safeParse(viewName);
        return p.success ? p.data : undefined;
    },

    /** Toggles or sets the summary state of an Inbox or Squad view frame.
     *
     * @param frame - The current view frame to potentially modify
     * @param squadId - Squad ID required for modifying Squad views
     * @param visible - Optional boolean to explicitly set the summary state. If not provided,
     *                 toggles the current summary state
     * @returns A new view frame with updated summary state if the frame is Inbox or Squad,
     *          otherwise returns the original frame unchanged
     */
    toggleSquadOrInboxSummaryFrame: (
        frame: AppView,
        squadId: Optional<d.SquadId>,
        visible?: boolean,
    ) => {
        if (viewUtils.isInbox(frame)) {
            return viewUtils.inbox(visible ?? !frame.summary);
        }
        else if (viewUtils.isSquad(frame) && squadId) {
            return viewUtils.squad(squadId, visible ?? !frame.summary);
        }
        else {
            return frame;
        }
    },
};

// region Viewstacks

/** Check if the parts of a URL match ["", prefix, {uuid}], and if so,
 * return the UUID. */
const parsePathThenUUID = (
    parts: string[],
    prefix: string,
    ctx: z.RefinementCtx,
): Optional<string> => {
    if (parts.length < 3) {
        zodAddIssue(ctx, "path does not have enough separators");
        return;
    }

    const issues: string[] = [];
    if (parts[0] !== "") {
        issues.push("path does not start with /");
    }

    if (parts[1] !== prefix) {
        issues.push(`first path segment is not '${prefix}'`);
    }

    const p = uuidSchema.safeParse(parts[2]);
    if (!p.success) {
        issues.push("second path segment is not a UUID");
    }

    if (issues.length > 0) {
        issues.forEach(msg => zodAddIssue(ctx, msg));
        return;
    }

    return p.data!; // tsc unable to infer this is non-undefined?
};

/** Check if the parts of a URL match our path for individual messages. */
const parsePossibleMessageId = (parts: string[]): Optional<d.MessageId> => {
    if (parts.length < 5 || parts[3] !== "message") return;

    const p = uuidSchema.safeParse(parts[4]);

    return p.success ? d.fromRawMessageId(p.data) : undefined;
};

/** Attempt to coerce a path "/squad/{uuid}" to a "squad" view. */
const squadPathToView = z.string()
    .transform((v, ctx) => {
        const parts = v.split("/");

        const squadId = parsePathThenUUID(parts, "squad", ctx);

        if (!squadId) return z.NEVER;
        const summary = parts[3] === "summary" ? true : undefined;
        return viewUtils.squad(d.fromRawSquadId(squadId), summary);
    })
    .pipe(viewSchemaMap.squad);

/** Attempt to coerce a path "/bond/{uuid}" to a "single bond" view. */
const singleBondPathToView = z.string()
    .transform((v, ctx) => {
        const parts = v.split("/");

        const bondId = parsePathThenUUID(parts, "bond", ctx);

        if (!bondId) return z.NEVER;

        const messageId = parsePossibleMessageId(parts);
        const summary = parts[3] === "summary" ? true : undefined;

        return viewUtils.singleBond(d.fromRawBondId(bondId), messageId, summary);
    })
    .pipe(viewSchemaMap.singleBond);

const ssptvBase = z.string()
    .transform((v, ctx) => {
        const parts = v.split("/");

        // ! Recombine two parts instead?
        // const squadId = parsePathThenUUID(parts, "settings/squads", ctx);

        const issues: string[] = [];
        if (parts.length < 4) {
            issues.push("path does not have enough separators");
            return;
        }
        if (parts[0] !== "") {
            issues.push("path does not start with /");
        }

        if (parts[1] !== "settings") {
            issues.push(`first path segment is not 'settings'`);
        }
        if (parts[2] !== "squads") {
            issues.push(`second path segment is not 'squads'`);
        }
        const p = uuidSchema.safeParse(parts[3]);
        if (!p.success) {
            issues.push("third path segment is not a UUID");
        }
        if (issues.length > 0) {
            issues.forEach(msg => zodAddIssue(ctx, msg));
            return z.NEVER;
        }

        const rawSquadId = p.data!;
        const squadId = d.fromRawSquadId(rawSquadId);

        if (parts[4] === "invite") {
            return viewUtils.squadSettingsInvite(squadId);
        }
        else if (parts[4] === "rename") {
            return viewUtils.squadSettingsRename(squadId);
        }
        else if (parts[4] === "remove") {
            // Parse fifth segment as a user ID
            if (parts.length < 6) {
                issues.push("path does not have enough separators");
            }

            const u = uuidSchema.safeParse(parts[5]);
            if (!u.success) {
                issues.push("fifth path segment is not a UUID");
            }
            if (issues.length > 0) {
                issues.forEach(msg => zodAddIssue(ctx, msg));
                return z.NEVER;
            }

            const rawUserId = u.data!;
            const userId = d.fromRawUserId(rawUserId);
            return viewUtils.squadSettingsRemove(squadId, userId);
        }

        return viewUtils.squadSettings(squadId);
    });

const squadSettingsPathToView = ssptvBase.pipe(viewSchemaMap.squadSettings);
const squadSettingsInvitePathToView = ssptvBase.pipe(viewSchemaMap.squadSettingsInvite);
const squadSettingsRenamePathToView = ssptvBase.pipe(viewSchemaMap.squadSettingsRename);
const squadSettingsRemovePathToView = ssptvBase.pipe(viewSchemaMap.squadSettingsRemove);

/** Attempt to coerce a path "/invite/{inviteCode}" to an "invite redemption"
 * view. */
const inviteRedemptionPathToView = z.string()
    .transform((v, ctx) => {
        const parts = v.split("/");

        if (parts[0] !== "") {
            zodAddIssue(ctx, "path does not start with /");
            return z.NEVER;
        }

        if (parts[1] !== "invite") {
            zodAddIssue(ctx, "first path segment is not 'invite'");
            return z.NEVER;
        }

        if (!parts[2]) {
            zodAddIssue(ctx, "second path segment is empty");
            return z.NEVER;
        }

        return viewUtils.inviteRedemption(parts[2]);
    }).pipe(viewSchemaMap.inviteRedemption);

const loginPathToView = z.string().startsWith("/login")
    .transform(viewUtils.login)
    .pipe(viewSchemaMap.login);

/** Convert a path to a view. */
export const pathToView = (path: Optional<string>): Optional<AppView> => {
    if (!path) return;

    switch (path) {
        case "/bond":
        case "/mobile/tab/mybonds":
            return viewUtils.inbox();
        case "/bond/summary":
            return viewUtils.inbox(true);
        case "/discover":
        case "/mobile/tab/discover":
            return viewUtils.discover();
        case "/mobile/tab/mysquads":
            return viewUtils.mySquads();
        case "/bond/new":
            return viewUtils.newBond();
        case "/search":
            return viewUtils.search();
        case "/settings/squads/new":
            return viewUtils.squadSettingsCreate();
        case "/settings/media":
            return viewUtils.mediaSettings();
        case "/settings/profile":
            return viewUtils.profileSettings();
        case "/settings/report":
            return viewUtils.reportIssue();
    }

    const firstMatch = [
        squadPathToView,
        singleBondPathToView,
        squadSettingsPathToView,
        squadSettingsInvitePathToView,
        squadSettingsRenamePathToView,
        squadSettingsRemovePathToView,
        inviteRedemptionPathToView,
        loginPathToView,
    ].find(
        pathToView => pathToView.safeParse(path).success,
    );
    if (firstMatch) return firstMatch.safeParse(path).data;

    return undefined;
};

/** Convert a view to a path. */
export const viewToPath = (view: Optional<AppView>): Optional<string> => {
    if (!view) return;

    switch (view.view) {
        case viewNames.inbox:
            if (isMobileBrowser()) {
                return "/mobile/tab/mybonds";
            }
            return "/bond" + (view.summary ? "/summary" : "");
        case viewNames.discover:
            return isMobileBrowser() ? "/mobile/tab/discover" : "/discover";
        case viewNames.mySquads:
            return "/mobile/tab/mysquads";
        case viewNames.squad:
            return `/squad/${d.extractUUID(view.id)}` + (view.summary ? "/summary" : "");
        case viewNames.newBond:
            return "/bond/new";
        case viewNames.singleBond: {
            const prefix = `/bond/${d.extractUUID(view.id)}`;

            if (view.messageId) {
                return prefix + `/message/${d.extractUUID(view.messageId)}`;
            }

            return prefix + (view.summary ? "/summary" : "");
        }
        case viewNames.inviteRedemption:
            return `/invite/${view.inviteCode}`;
        case viewNames.login:
            return "/login";
        case viewNames.search:
            return "/search";
        case viewNames.squadSettings: {
            const prefix = "/settings/squads";
            const idSeg = view.id ? `/${d.extractUUID(view.id)}` : "";
            return prefix + idSeg;
        }
        case viewNames.squadSettingsCreate:
            return "/settings/squads/new";
        case viewNames.squadSettingsInvite:
            return `/settings/squads/${d.extractUUID(view.id)}/invite`;
        case viewNames.squadSettingsRename:
            return `/settings/squads/${d.extractUUID(view.id)}/rename`;
        case viewNames.squadSettingsRemove:
            return `/settings/squads/${d.extractUUID(view.id)}` +
                `/remove/${d.extractUUID(view.userId)}`;
        case viewNames.mediaSettings:
            return "/settings/media";
        case viewNames.profileSettings:
            return "/settings/profile";
        case viewNames.reportIssue:
            return "/settings/report";
    }
};

/** Schema describing the part of URL state we require to track viewstacks
 * over browser navigation. */
export const navStateSchema = z.object({
    viewStack: viewStackSchema,
});
export type NavState = z.infer<typeof navStateSchema>;

/** Helper object for utilities for viewstacks. */
export const viewStackUtils = {
    /** The default viewstack. */
    defaultStack: viewStackSchema.parse([viewUtils.inbox()]),

    /** Find the default viewstack for viewing a squad.
     *
     * Mobile/desktop dependent!
     */
    defaultSquadViewStack: (squadId: d.SquadId): AppViewStack => {
        const v = viewUtils.squad(squadId);
        return viewStackSchema.parse(
            isMobileBrowser() ? [viewUtils.mySquads(), v] : [v],
        );
    },

    /** Find the default viewstack for a given view. */
    defaultStackForView: (v: AppView): AppViewStack => {
        switch (v.view) {
            case viewNames.inbox:
            case viewNames.discover:
            case viewNames.mySquads:
                return viewStackSchema.parse([v]);
            case viewNames.squad:
                return viewStackUtils.defaultSquadViewStack(v.id);
            case viewNames.newBond:
            case viewNames.singleBond:
            case viewNames.inviteRedemption:
            case viewNames.search:
            case viewNames.mediaSettings:
            case viewNames.profileSettings:
            case viewNames.reportIssue:
            case viewNames.squadSettings:
                return viewStackSchema.parse([viewUtils.inbox(), v]);
            case viewNames.squadSettingsCreate:
            case viewNames.squadSettingsInvite:
            case viewNames.squadSettingsRename:
            case viewNames.squadSettingsRemove:
                throw new Error(`No default stack for settings modal: ${v}`);
            default:
                throw new Error(`No default stack for view ${v}`);
        }
    },

    /** Find a guaranteed-to-be-sane viewstack for viewing a bond. */
    fallbackViewForBond: (id: d.BondId, messageId?: d.MessageId): AppViewStack =>
        viewStackSchema.parse([viewUtils.inbox(), viewUtils.singleBond(id, messageId)]),

    /** Are two viewstacks essentially equal? */
    viewStacksEqual: (a: AppViewStack, b: AppViewStack): boolean => {
        if (a.length !== b.length) return false;

        return !a.some((frame, i) => !viewUtils.viewsEqual(frame, b[i]));
    },

    /** Updates the topmost view in a viewstack using the provided transformation function.
     * @returns A new viewstack with the transformed top view
     */
    updateTopOfStack: (
        viewStack: AppViewStack,
        f: (frame: AppView) => AppView,
    ) => {
        const frame = viewStack.at(-1);
        if (frame === undefined) {
            return viewStack;
        }
        const newStack: typeof viewStack = [...viewStack];
        newStack[newStack.length - 1] = f(frame);
        return newStack;
    },

    /** Returns the view at the base of the stack. */
    rootViewOfStack: (stack: AppViewStack): AppView => {
        if (viewUtils.isMySquads(stack[0])) return stack[1];
        else return stack[0];
    },

    /** Iterates through all views in a stack, replacing the squad ID in any squadView
     * or squadSettingsView with the provided new squad ID.
     * @returns A new viewstack with all squad ID values replaced
     */
    replaceSquadInStack: (stack: AppViewStack, squadId: d.SquadId): AppViewStack => {
        const newStack: AppViewStack = [...stack];
        stack.forEach((frame, i) => {
            if (viewUtils.isSquad(frame)) {
                newStack[i] = viewUtils.squad(squadId);
            }
            else if (viewUtils.isSquadSettings(frame)) {
                newStack[i] = viewUtils.squadSettings(squadId);
            }
        });
        return newStack;
    },

    /** Removes the topmost view from a viewstack. If the stack has only one view,
     * the stack will be returned unchanged.
     * @returns A new viewstack with the topmost view removed
     */
    removeTopOfStack: (viewStack: AppViewStack): AppViewStack => {
        if (viewStack.length === 1) return viewStack;
        const newStack: typeof viewStack = [...viewStack];
        newStack.pop();
        return newStack;
    },

    /** Find the squad view or squad settings view in a stack, if any, and
     * return the squad ID. */
    extractSquadId: (stack: AppViewStack): Optional<d.SquadId> => {
        const squadFrame = stack.find(viewUtils.isSquad);
        if (squadFrame) return squadFrame.id;

        const squadSettingsFrame = stack.find(viewUtils.isSquadSettings);
        if (squadSettingsFrame) return squadSettingsFrame.id;

        const squadSettingsInviteFrame = stack.find(viewUtils.isSquadSettingsInvite);
        if (squadSettingsInviteFrame) return squadSettingsInviteFrame.id;
        const squadSettingsRenameFrame = stack.find(viewUtils.isSquadSettingsRename);
        if (squadSettingsRenameFrame) return squadSettingsRenameFrame.id;
        const squadSettingsRemoveFrame = stack.find(viewUtils.isSquadSettingsRemove);
        if (squadSettingsRemoveFrame) return squadSettingsRemoveFrame.id;

        return;
    },

    /** Find the remove user from squad view in a stack, if any, and return the
     * user ID. */
    extractUserId: (stack: AppViewStack): Optional<d.UserId> => {
        const squadSettingsRemoveFrame = stack.find(viewUtils.isSquadSettingsRemove);
        if (squadSettingsRemoveFrame) return squadSettingsRemoveFrame.userId;

        return;
    },
};
