import useLocalDispatch from "@/hooks/useLocalDispatch";
import useResizeObserver from "@react-hook/resize-observer";
import classNames from "classnames";
import React, {
    createContext,
    CSSProperties,
    ForwardedRef,
    forwardRef,
    memo,
    Ref,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { IndexLocationWithAlign, Virtuoso, VirtuosoHandle, VirtuosoProps } from "react-virtuoso";
import { useImmer } from "use-immer";

import {
    ChannelMessageComposer,
    ChannelMessageComposerProps,
} from "@/components/ChannelMessageComposer";
import { FeatureFlagged } from "@/components/FeatureFlags";
import * as d from "@/domain/domain";
import { orderParticipants } from "@/domain/rtc";
import { selectChannelIdByBondId, selectLiveCallIdByBondId } from "@/features/bonds";
import { updateBondSidebarSummarySavedPublishedSeqNum } from "@/features/bonds";
import { JoinedCallView, selectCallById, selectJoinedCallView } from "@/features/calls";
import { selectMessageIdsByChannelId, selectPublishedSequenceNumber } from "@/features/channels";
import { selectBondViewScrollPosition, setBondViewScrollPosition } from "@/features/meta";
import { useInterestedChannel } from "@/hooks/interest/useInterest";
import useRtcSessionContext from "@/hooks/rtc/useRtcSessionContext";
import useAddressParams from "@/hooks/useAddressParams";
import useKeypressFocus from "@/hooks/useKeypressFocus";
import { useLastMessageReader } from "@/hooks/useLastMessageReader";
import useMergedRefs from "@/hooks/useMergedRefs";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { useShallowEqualsMemo } from "@/hooks/useShallowEquals";
import log from "@/misc/log";
import { Focusable, Optional } from "@/misc/types";
import { useAppSelector } from "@/store/redux";
import BondLiveGridView from "@/views/BondLiveGridView";
import MessageView from "@/views/MessageView";

const DEFAULT_MSG_HEIGHT = 60;

// Enable this to get verbose logging around scroll restoration and scrolling in general
// in the chat view.
const SCROLL_DEBUG_ENABLED = false;

function scrolldbg(event: string, msg: string, ...args: any[]) {
    if (!SCROLL_DEBUG_ENABLED) return;

    let logFunc = log.info;
    if (event === "scroll") {
        // The "scroll" event can be quite spammy, so hide it by default (in dev tools,
        // needs changing the filter to "Verbose" to see it).
        logFunc = log.debug;
    }
    logFunc(`scrolldbg(${event}): ${msg}`, ...args);
}

export interface MessagesViewContextValue {
    // Client size of the messages view
    clientWidth: number;
    clientHeight: number;
}

export const MessagesViewContext = createContext<MessagesViewContextValue>({
    clientWidth: 0,
    clientHeight: 0,
});

interface ChatMessagesViewInternalProps {
    bondId: d.BondId;
    scrollToMessageId?: d.AnyMessageId;
    savedPublishedSequenceNumberForChannel?: number;
    preChatContent?: React.JSX.Element;
}

export function ChatMessagesViewInternal(props: ChatMessagesViewInternalProps): React.JSX.Element {
    const {
        bondId,
        scrollToMessageId,
        savedPublishedSequenceNumberForChannel,
    } = props;

    const channelId = useAppSelector(selectChannelIdByBondId(bondId));
    const messageIds = useSelectorArgs(selectMessageIdsByChannelId, channelId);
    const liveCallId = useSelectorArgs(selectLiveCallIdByBondId, bondId);
    const listRef = useRef<HTMLDivElement>(null);
    const divRef = useRef<HTMLDivElement>(null);
    const previousScrollToMessageId = useRef<Optional<d.AnyMessageId>>(undefined);
    const endDivRef = useRef<HTMLDivElement>(null);
    const [clientDims, setClientDims] = useImmer<MessagesViewContextValue>({
        clientWidth: document.documentElement.clientWidth,
        clientHeight: document.documentElement.clientHeight,
    });

    const { callParticipants } = useRtcSessionContext();
    const { displayMediaParticipants } = useShallowEqualsMemo(
        () => orderParticipants(callParticipants),
        [callParticipants],
    );

    // To save where we've scrolled to in each bond, we save the position in the redux store
    // using localDispatch() so the saving is specific to an individual tab.
    const savedScrollPosition = useSelectorArgs(selectBondViewScrollPosition, bondId);
    // We need to read the current scroll position in a use(Layout)Effect below, but don't want
    // the effect recalculated whenever this changes. So we keep it in a ref.
    // Note that in React 19 we're told explicitly not to do this, but we're not React 19 yet
    // and don't have their new APIs like useEffectEvent().
    const savedScrollPositionRef = useRef<number | undefined>();
    savedScrollPositionRef.current = savedScrollPosition;
    // We keep tabs on the current scroll position to avoid having to rely on the DOM to get it.
    const currentScrollPosition = useRef<number | undefined>(undefined); // Undefined = at bottom

    // Keeping scrolled to the bottom is complex because of the asynchronous nature of the various
    // event updates and asking the DOM for the current state. To try and enforce some
    // predictability, we cache the scrollHeight of the scrollable div, and only update it when we
    // get our resize observer callback.
    // This can then be used in the scroll callback. If we don't do this, but instead ask the DOM
    // for the current state in the scroll callback, then we might be getting a new height before
    // the resize observer callback has run. This leads to produblems with not staying scrolled to
    // the bottom.
    const lastSizeUpdateRef = useRef<number | undefined>(undefined);

    const scrollToBottom = useCallback(() => {
        endDivRef.current?.scrollIntoView({ behavior: "instant" });
    }, []);

    const localDispatch = useLocalDispatch();

    const getScrollHeight = () => listRef.current?.scrollHeight;
    const getScrollTop = () => listRef.current?.scrollTop;
    const getClientHeight = () => listRef.current?.clientHeight;

    const isNearBottom = useCallback(() => {
        if (!listRef.current) return true;
        if (!lastSizeUpdateRef.current) return true;

        const scrollHeight = lastSizeUpdateRef.current;

        // Half a small message height is about right as a heuristic
        // for "can we see the message content at all".
        const bottomThresh = DEFAULT_MSG_HEIGHT / 2;
        return scrollHeight - getScrollTop()! - getClientHeight()! < bottomThresh;
    }, []);

    useLayoutEffect(() => {
        scrolldbg(
            "mount",
            `bondId=${bondId}, scrollToMessageId=${scrollToMessageId} ` +
                `savedScrollPosition=${savedScrollPositionRef.current} ` +
                `height=${getScrollHeight()}`,
        );

        lastSizeUpdateRef.current = getScrollHeight();

        if (!scrollToMessageId) {
            if (savedScrollPositionRef.current !== undefined) {
                listRef.current?.scrollTo(0, savedScrollPositionRef.current);

                // We shouldn't need this, but I find it hard to say whether we can
                // definitely get away without it. With react strict mode, we'll unmount
                // quickly, and we save currentScrollPosition on unmount, so let's just
                // initialise/overwrite it to what it will become pre-emptively.
                currentScrollPosition.current = savedScrollPositionRef.current;
            }
            else {
                scrollToBottom();
            }
        }
        else if (scrollToMessageId && scrollToMessageId != previousScrollToMessageId.current) {
            document.getElementById(scrollToMessageId)?.scrollIntoView({
                behavior: "instant",
                block: "center",
            });
            currentScrollPosition.current = getScrollTop();

            previousScrollToMessageId.current = scrollToMessageId;
        }

        return () => {
            localDispatch(
                setBondViewScrollPosition({
                    bondId,
                    position: currentScrollPosition.current,
                }),
            );
            savedScrollPositionRef.current = isNearBottom() ? undefined
                : currentScrollPosition.current;

            scrolldbg(
                "unmount",
                `bondId=${bondId} saving scroll position ${currentScrollPosition.current}`,
            );
        };
    }, [localDispatch, scrollToBottom, scrollToMessageId, bondId, isNearBottom]);

    // Keep scroll up to date. We monitor `divRef` here because this is the element that actually
    // grows in size. `listRef` is layed out according to the viewport and fixed until the window
    // is resized; so we can't use a resize observer on it. But all scroll calculations happen on
    // `listRef`, so we use that for `scrollTop` and `scrollHeight`.
    const resizeEventCallback = useCallback(() => {
        if (!divRef.current) return;
        if (!listRef.current) return;

        const newScrollHeight = getScrollHeight()!;
        if (newScrollHeight === lastSizeUpdateRef.current) return;

        scrolldbg(
            "resize",
            `bondId=${bondId} height=${newScrollHeight} previous=${lastSizeUpdateRef.current} ` +
                `scrollTop=${getScrollTop()} ` +
                `bottom=${currentScrollPosition.current === undefined}/${isNearBottom()}`,
        );

        const wasScrolledToBottom = (currentScrollPosition.current === undefined) || isNearBottom();
        lastSizeUpdateRef.current = newScrollHeight;

        // Keep scrolled to the bottom (see also scrollHandler)
        if (wasScrolledToBottom) {
            scrollToBottom();
        }
        // This implicitly assumes that call summaries do not change for long-since-ended calls.
    }, [scrollToBottom, bondId, isNearBottom]);

    useResizeObserver(divRef, resizeEventCallback);

    // Let elements that need to know the viewport width know it. This is used for
    // sizing inline attachments at least. Unfortunately we can't just use the span
    // sizing since that will be empty before any messages are rendered, we need
    // the div above it; i.e. listRef.
    useResizeObserver(listRef, () => {
        const lr = listRef.current;
        if (!lr) return;

        setClientDims(draft => {
            draft.clientWidth = lr.clientWidth;
            draft.clientHeight = lr.clientHeight;
        });
    });

    // We don't want to wait for the first render to calculate the client dimensions. So
    // we do it as early as possible, i.e. in a useLayoutEffect. This stops an awkward
    // resize after the first render; which is when the useResizeObserver will fire.
    useLayoutEffect(() => {
        const lr = listRef.current;
        if (!lr) return;

        setClientDims(draft => {
            draft.clientWidth = lr.clientWidth;
            draft.clientHeight = lr.clientHeight;
        });
    }, [setClientDims]);

    const handleScroll: React.UIEventHandler<HTMLDivElement> = useCallback(
        (_ev: React.UIEvent<HTMLDivElement, UIEvent>) => {
            if (!listRef.current) return;

            const scrollHeight = getScrollHeight()!;
            const scrollTop = getScrollTop()!;

            if (scrollHeight != lastSizeUpdateRef.current) {
                // We've had a size change before the resize observer has run. This
                // ordering seems common, on Chromium at least.
                resizeEventCallback();
                return;
            }

            const atBottom = isNearBottom();

            scrolldbg(
                "scroll",
                `bottom=${atBottom} height=${scrollHeight} top=${scrollTop} ` +
                    `lastSize=${lastSizeUpdateRef.current}`,
            );

            currentScrollPosition.current = atBottom ? undefined : scrollTop;
        },
        [isNearBottom, resizeEventCallback],
    );

    const [lastMessageContent, setLastMessageContent] = useState<HTMLDivElement | null>(null);
    useLastMessageReader(lastMessageContent ?? undefined, channelId);

    const { messageId: highlightId } = useAddressParams();

    if (!channelId) {
        return <></>;
    }

    const bondClasses = classNames("c-content__bond", {
        "c-content__bond--short": displayMediaParticipants.length > 0,
    });

    return (
        <div
            onScroll={handleScroll}
            ref={listRef}
            className={bondClasses}
            data-testid="chat-messages-view"
        >
            <div ref={divRef}>
                <MessagesViewContext.Provider value={clientDims}>
                    {props.preChatContent}
                    <MessageListMemo
                        messageIds={messageIds}
                        liveCallId={liveCallId}
                        savedPublishedSequenceNumberForChannel={savedPublishedSequenceNumberForChannel}
                        lastMessageContentRef={setLastMessageContent}
                        highlightMessageId={highlightId}
                    />
                    <div
                        className="c-bond__spacer"
                        ref={endDivRef}
                        data-testid="chat-message-end-spacer"
                    />
                </MessagesViewContext.Provider>
            </div>
        </div>
    );
}

function ChatMessagesViewVirtualised(props: ChatMessagesViewInternalProps): React.JSX.Element {
    const {
        bondId,
        scrollToMessageId,
        savedPublishedSequenceNumberForChannel,
        preChatContent,
    } = props;

    const channelId = useAppSelector(selectChannelIdByBondId(bondId));
    const messageIds = useSelectorArgs(selectMessageIdsByChannelId, channelId);
    const liveCallId = useSelectorArgs(selectLiveCallIdByBondId, bondId);

    const divRef = useRef<HTMLDivElement>(null);
    const [clientDims, setClientDims] = useImmer<MessagesViewContextValue>({
        clientWidth: document.documentElement.clientWidth,
        clientHeight: document.documentElement.clientHeight,
    });

    const [lastMessageContent, setLastMessageContent] = useState<HTMLDivElement | null>(null);
    useLastMessageReader(lastMessageContent ?? undefined, channelId);

    const itemRenderer = useCallback((index: number, id: d.AnyMessageId | "preChatContent") => {
        /*
        Getting padding right is awkward. I haven't found a way to get padding around the general
        item content area, so I need to instead apply it directly to the item content. The logic
        here mimics the layout normally applied by c-content__bond.
        */
        const isLastMessage = index == (messageIds.length - 1);

        const style: CSSProperties = {
            minHeight: "1px",
            paddingLeft: "16px",
            paddingRight: "16px",
        };

        if (index == 0) {
            style.paddingTop = "116px";
        }
        if (isLastMessage) {
            style.paddingBottom = "12px";
        }

        if (id === "preChatContent") {
            return <div style={style}>{preChatContent}</div>;
        }

        const prevIndex = preChatContent ? index : index - 1;

        return (
            <MessageView
                key={id}
                id={id}
                currentCallId={liveCallId}
                previousId={messageIds[prevIndex]}
                isLastMessage={isLastMessage}
                savedPublishedSequenceNumber={savedPublishedSequenceNumberForChannel}
                extraStyle={style}
                messageContentRef={isLastMessage ? setLastMessageContent : undefined}
            />
        );
    }, [
        liveCallId,
        messageIds,
        savedPublishedSequenceNumberForChannel,
        preChatContent,
    ]);

    useResizeObserver(divRef, () => {
        const lr = divRef.current;
        if (!lr) return;

        setClientDims(draft => {
            draft.clientWidth = lr.clientWidth;
            draft.clientHeight = lr.clientHeight;
        });
    });

    useLayoutEffect(() => {
        const lr = divRef.current;
        if (!lr) return;

        setClientDims(draft => {
            draft.clientWidth = lr.clientWidth;
            draft.clientHeight = lr.clientHeight;
        });
    }, [setClientDims]);

    const virtualsoRef = useRef<VirtuosoHandle>(null);
    const data = preChatContent ? ["preChatContent", ...messageIds] : messageIds;
    let initialTopMostItemIndex: IndexLocationWithAlign | undefined = undefined;

    if (scrollToMessageId !== undefined) {
        const index = data.indexOf(scrollToMessageId);
        if (index !== -1) {
            initialTopMostItemIndex = {
                index: index,
                align: "center",
                behavior: "auto",
            };
        }
    }
    else {
        initialTopMostItemIndex = {
            index: data.length - 1,
            align: "end",
            behavior: "auto",
        };
    }

    const virtuosoProps: VirtuosoProps<string, null> = {
        // We need to fill up the vertical space given to us by the parent
        style: { height: "100%" },
        data,
        itemContent: itemRenderer,
        // This keeps us scrolled to the bottom if we're currently already at the bottom
        followOutput: true,

        endReached: _index => {
        },
        isScrolling: _isScrolling => {
        },
        atBottomStateChange: _atBottom => {
        },
    };

    if (initialTopMostItemIndex) {
        virtuosoProps.initialTopMostItemIndex = initialTopMostItemIndex;
    }

    return (
        <div style={{ height: "100%" }} ref={divRef}>
            <MessagesViewContext.Provider value={clientDims}>
                <Virtuoso {...virtuosoProps} ref={virtualsoRef} />
            </MessagesViewContext.Provider>
        </div>
    );
}

interface MessageListProps {
    messageIds: Readonly<string[]>;
    liveCallId: Optional<d.CallId>;
    savedPublishedSequenceNumberForChannel: Optional<number>;
    lastMessageContentRef?: Ref<HTMLDivElement>;
    highlightMessageId?: d.AnyMessageId;
}

const MessageListMemo: React.FC<MessageListProps> = memo(props =>
    props.messageIds.map((id, index) => {
        const isLastMessage = index == (props.messageIds.length - 1);
        const shouldHighlight = props.highlightMessageId === id;
        return (
            <MessageView
                key={id}
                id={id}
                currentCallId={props.liveCallId}
                previousId={props.messageIds[index - 1]}
                isLastMessage={isLastMessage}
                savedPublishedSequenceNumber={props.savedPublishedSequenceNumberForChannel}
                messageContentRef={isLastMessage ? props.lastMessageContentRef : undefined}
                highlightMessage={shouldHighlight}
            />
        );
    })
);

interface ChatMessagesViewProps {
    bondId: d.BondId;
    scrollToMessageId?: d.AnyMessageId;
    publishedSequenceNumberForChannel?: number;
    preChatContent?: React.JSX.Element;
}

export function ChatMessagesView(props: ChatMessagesViewProps): React.JSX.Element {
    const { bondId } = props;

    const channelId = useAppSelector(selectChannelIdByBondId(bondId));
    const messageIds = useSelectorArgs(selectMessageIdsByChannelId, channelId);
    const publishedSequenceNumber = useSelectorArgs(selectPublishedSequenceNumber, channelId);

    // We save the published sequence number on mount so we can keep the unread messages
    // marker at the same place in the view.
    const savedPublishedSeqNo = useRef<{ bondId: d.BondId; seqNo: number; } | undefined>(
        publishedSequenceNumber !== undefined ? {
            bondId,
            seqNo: publishedSequenceNumber,
        } : undefined,
    );

    useEffect(() => {
        // If we re-render with a different bond, forget any state
        if (savedPublishedSeqNo.current) {
            if (savedPublishedSeqNo.current.bondId !== bondId) {
                savedPublishedSeqNo.current = undefined;
            }
        }

        // Mostly this will fire to save the sequence number when we initially mount this component
        if (!savedPublishedSeqNo.current) {
            savedPublishedSeqNo.current = { bondId, seqNo: publishedSequenceNumber ?? 0 };
        }
    }, [bondId, publishedSequenceNumber]);

    const localDispatch = useLocalDispatch();

    useEffect(() => {
        if (!bondId) return;
        localDispatch(
            updateBondSidebarSummarySavedPublishedSeqNum({
                bondId,
                savedPublishedSeqNum: savedPublishedSeqNo.current?.seqNo ?? 0,
            }),
        );
    }, [
        savedPublishedSeqNo,
        localDispatch,
        bondId,
    ]);

    // This is responsible for finding the ID of the message with the new messages marker on it.
    // If there is not one, the it returns undefined.
    //
    // If there is a specific message ID to scroll to supplied in the props to this component,
    // that ID is prioritised.
    const scrollToMessageId = useMemo(() => {
        scrolldbg(
            "scrollToMessageId",
            `scrollToMessageId=${props.scrollToMessageId} ` +
                `publishedSequenceNumber=${publishedSequenceNumber} ` +
                `result=${messageIds[publishedSequenceNumber ?? 0]}`,
        );

        if (props.scrollToMessageId !== undefined) {
            return props.scrollToMessageId;
        }

        // This is the index of the message that would have the new messages marker on it,
        // which is the message with sequence number 1 greater than the published sequence number.
        // Since the sequence numbers for messages start at 1, we have to subtract 1
        // from the published sequence number to use as an index into the array of messageIds.
        const desiredIndex = publishedSequenceNumber ?? 0;

        // The following index works since we have already fetched all messages
        // in the channel when we come to here.
        return messageIds[desiredIndex];
    }, [messageIds, props.scrollToMessageId, publishedSequenceNumber]);

    return (
        <FeatureFlagged
            flag="virtualised-chat-view"
            style={{ height: "100%" }}
            fallback={
                <ChatMessagesViewInternal
                    {...props}
                    scrollToMessageId={scrollToMessageId}
                    savedPublishedSequenceNumberForChannel={savedPublishedSeqNo.current?.seqNo}
                    key={bondId}
                />
            }
            match={true}
        >
            <ChatMessagesViewVirtualised
                {...props}
                scrollToMessageId={scrollToMessageId}
                savedPublishedSequenceNumberForChannel={savedPublishedSeqNo.current?.seqNo}
                key={bondId}
            />
        </FeatureFlagged>
    );
}

export interface ChatViewProps {
    bondId: d.BondId;
    scrollToMessageId?: d.MessageId;
    preChatContent?: React.JSX.Element;
}

export const ChatView = forwardRef((
    props: ChatViewProps,
    ref: ForwardedRef<Focusable>,
): React.JSX.Element => {
    const {
        bondId,
        scrollToMessageId,
    } = props;

    const channelId = useAppSelector(selectChannelIdByBondId(bondId));
    const liveCallId = useSelectorArgs(selectLiveCallIdByBondId, bondId);

    const liveCall = useSelectorArgs(selectCallById, liveCallId);
    const joinedCallView = useAppSelector(selectJoinedCallView);
    const isLiveView = joinedCallView === JoinedCallView.Live;

    const composerRef = useRef<Focusable>(null);
    const setComposerTextFocused = useKeypressFocus(composerRef);
    const mergedRefs = useMergedRefs(composerRef, ref);

    const onEditorFocus = useCallback(() => setComposerTextFocused(true), [setComposerTextFocused]);
    const onEditorBlur = useCallback(() => setComposerTextFocused(false), [setComposerTextFocused]);

    // TODO: do we need this?
    useInterestedChannel(channelId);

    if (!channelId) {
        return <></>;
    }

    const liveGridView = <BondLiveGridView />;

    const chatOrLiveGrid = (isLiveView && liveCall) ? liveGridView :
        (
            <ChatMessagesView
                bondId={bondId}
                scrollToMessageId={scrollToMessageId}
                preChatContent={props.preChatContent}
            />
        );

    const composerProps: ChannelMessageComposerProps = {
        channelId,
        onEditorFocus,
        onEditorBlur,
    };

    return (
        <>
            {chatOrLiveGrid}
            <ChannelMessageComposer ref={mergedRefs} {...composerProps} />
        </>
    );
});

export default ChatView;
