import { StoreEnhancer } from "redux";
import log from "./log";

const runPerf = true;

const roundFn = (n: number) => Math.round(n * 100) / 100;

const isPromise = <T>(value: any): value is Promise<T> => {
    return value !== null && typeof value === "object" && typeof value.then === "function";
};

export function timeFn<T>(
    f: () => T,
    cb?: (n: number) => void,
    opts?: { round: boolean; },
): T;

export function timeFn<T>(
    f: () => T | Promise<T>,
    cb?: (n: number) => void,
    { round }: { round: boolean; } = { round: true },
): ReturnType<typeof f> {
    if (!runPerf || !cb) return f();

    const start = performance.now();

    const handleCompletion = () => {
        const end = performance.now();
        const duration = round ? roundFn(end - start) : end - start;
        cb(duration);
    };

    try {
        const r = f();
        if (isPromise(r)) {
            return r.finally(handleCompletion);
        }
        else {
            handleCompletion();
            return r;
        }
    }
    catch (e) {
        handleCompletion();
        throw e;
    }
}

interface PerformanceMetrics {
    actionTypeDurations: Record<string, number[]>;
}

// Define enhancer options interface
interface EnhancerOptions {
    slowActionThresholdMs?: number; // in milliseconds
    reportIntervalSeconds?: number;
}

const summarisePerformanceJSON = async (metrics: PerformanceMetrics) => {
    const summaries = Object.entries(metrics.actionTypeDurations).map(([k, vs]) => {
        const n = vs.length;
        if (n === 1) return { k, mean: roundFn(vs[0]), n: 1 };

        // We own this array, so sorting in place is fine.
        vs.sort((a, b) => a - b);
        const min = roundFn(vs[0]);
        const max = roundFn(vs.at(-1) as number);
        const sum = vs.reduce((s, v) => s + v, 0);
        const mean = sum / n;
        const variance = vs.map(v => v - mean).reduce((s, v) => s + v * v, 0) / n;
        const stddev = Math.sqrt(variance);
        return { k, mean: roundFn(mean), n, min, max, stddev: roundFn(stddev) };
    });
    summaries.sort((o1, o2) => o2.mean - o1.mean);
    const summaryStrings = summaries.map(({ k, ...data }) => `"${k}": ${JSON.stringify(data)}`);
    log.info(`Action dispatch performance: {${summaryStrings.join(", ")}}`);
};

/**
 * Creates a Redux enhancer that adds performance monitoring
 * @param options Configuration options for the enhancer
 */
export const createPerformanceEnhancer = (
    options: EnhancerOptions = {},
): StoreEnhancer => {
    const {
        slowActionThresholdMs = 20,
        reportIntervalSeconds = 60,
    } = options;

    let metrics: PerformanceMetrics;

    const replaceMetrics = (): PerformanceMetrics => {
        const original = metrics;
        metrics = {
            actionTypeDurations: {},
        };
        return original;
    };
    replaceMetrics();
    let reportTimeoutHandle: ReturnType<typeof setTimeout> | null = null;

    return createStore => (reducer, preloadedState?) => {
        const store = createStore(reducer, preloadedState);

        return {
            ...store,
            dispatch: action =>
                timeFn(() => store.dispatch(action), n => {
                    if (!metrics.actionTypeDurations[action.type]) {
                        metrics.actionTypeDurations[action.type] = [];
                    }
                    metrics.actionTypeDurations[action.type].push(n);

                    if (n > slowActionThresholdMs) {
                        log.warn(
                            `Slow action detected: ${action.type} took ${n.toFixed(2)}ms`,
                        );
                    }

                    if (!reportTimeoutHandle) {
                        reportTimeoutHandle = setTimeout(() => {
                            const data = replaceMetrics();

                            reportTimeoutHandle = null;

                            summarisePerformanceJSON(data);
                        }, reportIntervalSeconds * 1000);
                        //
                    }
                }, { round: false }),

            // Add method to get performance metrics
            getMetrics: () => ({
                ...metrics,
            }),
        };
    };
};
