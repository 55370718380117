// @generated by protoc-gen-es v1.4.2 with parameter "target=js+ts"
// @generated from file devices/devices.proto (package devices, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { ConnectionId, DeviceId, DeviceSet, FrontendPlatformType, OrgId, PersonId, PersonSet, PresenceMode, UserId, UserSet } from "../domain/domain_pb.js";

/**
 * @generated from message devices.PersonalActivity
 */
export const PersonalActivity = proto3.makeMessageType(
  "devices.PersonalActivity",
  () => [
    { no: 1, name: "person_id", kind: "message", T: PersonId },
    { no: 9, name: "logged_in_as", kind: "message", T: UserId },
    { no: 2, name: "currently_connected", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "connected_since", kind: "message", T: Timestamp, oneof: "connection_ts" },
    { no: 4, name: "disconnected_since", kind: "message", T: Timestamp, oneof: "connection_ts" },
    { no: 5, name: "currently_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "active_since", kind: "message", T: Timestamp, oneof: "activity_ts" },
    { no: 7, name: "inactive_since", kind: "message", T: Timestamp, oneof: "activity_ts" },
    { no: 8, name: "current_presence", kind: "enum", T: proto3.getEnumType(PresenceMode), repeated: true },
  ],
);

/**
 * @generated from message devices.UserObservation
 */
export const UserObservation = proto3.makeMessageType(
  "devices.UserObservation",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 3, name: "current", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "started_at", kind: "message", T: Timestamp },
    { no: 4, name: "ended_at", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message devices.KioskOverview
 */
export const KioskOverview = proto3.makeMessageType(
  "devices.KioskOverview",
  () => [
    { no: 1, name: "device_id", kind: "message", T: DeviceId },
    { no: 2, name: "org_id", kind: "message", T: OrgId },
    { no: 3, name: "kiosk_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "nearby_users", kind: "message", T: UserSet },
    { no: 5, name: "nearby_devices", kind: "message", T: DeviceSet },
  ],
);

/**
 * @generated from message devices.FirebaseToken
 */
export const FirebaseToken = proto3.makeMessageType(
  "devices.FirebaseToken",
  () => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message devices.APNSToken
 */
export const APNSToken = proto3.makeMessageType(
  "devices.APNSToken",
  () => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message devices.DeviceOverview
 */
export const DeviceOverview = proto3.makeMessageType(
  "devices.DeviceOverview",
  () => [
    { no: 1, name: "device_id", kind: "message", T: DeviceId },
    { no: 2, name: "person_id", kind: "message", T: PersonId },
    { no: 3, name: "device_tag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "firebase_token", kind: "message", T: FirebaseToken },
    { no: 5, name: "apns_token", kind: "message", T: APNSToken },
    { no: 6, name: "frontend_platform", kind: "enum", T: proto3.getEnumType(FrontendPlatformType) },
    { no: 7, name: "frontend_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message devices.GetOrAddDeviceRequest
 */
export const GetOrAddDeviceRequest = proto3.makeMessageType(
  "devices.GetOrAddDeviceRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "device_tag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "frontend_platform", kind: "enum", T: proto3.getEnumType(FrontendPlatformType) },
    { no: 4, name: "frontend_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message devices.GetOrAddDeviceResponse
 */
export const GetOrAddDeviceResponse = proto3.makeMessageType(
  "devices.GetOrAddDeviceResponse",
  () => [
    { no: 1, name: "new_device_id", kind: "message", T: DeviceId },
  ],
);

/**
 * @generated from message devices.GetAllKiosksRequest
 */
export const GetAllKiosksRequest = proto3.makeMessageType(
  "devices.GetAllKiosksRequest",
  () => [
    { no: 1, name: "org_id", kind: "message", T: OrgId },
  ],
);

/**
 * @generated from message devices.GetAllKiosksResponse
 */
export const GetAllKiosksResponse = proto3.makeMessageType(
  "devices.GetAllKiosksResponse",
  () => [
    { no: 1, name: "kiosks", kind: "message", T: KioskOverview, repeated: true },
  ],
);

/**
 * @generated from message devices.RegisterActivityCore
 */
export const RegisterActivityCore = proto3.makeMessageType(
  "devices.RegisterActivityCore",
  () => [
    { no: 1, name: "device_id", kind: "message", T: DeviceId },
    { no: 2, name: "connection_id", kind: "message", T: ConnectionId },
    { no: 3, name: "recorded_at", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message devices.RegisterConnectionRequest
 */
export const RegisterConnectionRequest = proto3.makeMessageType(
  "devices.RegisterConnectionRequest",
  () => [
    { no: 1, name: "device_id", kind: "message", T: DeviceId },
    { no: 4, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "connection_tag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "disconnection", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message devices.RegisterConnectionResponse
 */
export const RegisterConnectionResponse = proto3.makeMessageType(
  "devices.RegisterConnectionResponse",
  () => [
    { no: 1, name: "new_connection_id", kind: "message", T: ConnectionId },
  ],
);

/**
 * @generated from message devices.RegisterObservationRequest
 */
export const RegisterObservationRequest = proto3.makeMessageType(
  "devices.RegisterObservationRequest",
  () => [
    { no: 6, name: "core", kind: "message", T: RegisterActivityCore },
    { no: 5, name: "user_id", kind: "message", T: UserId },
    { no: 3, name: "view_urn", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "deregister", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "retain_after_deregister", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "stream_correlation_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message devices.RegisterObservationResponse
 */
export const RegisterObservationResponse = proto3.makeMessageType(
  "devices.RegisterObservationResponse",
  [],
);

/**
 * @generated from message devices.RegisterBrowserFocusRequest
 */
export const RegisterBrowserFocusRequest = proto3.makeMessageType(
  "devices.RegisterBrowserFocusRequest",
  () => [
    { no: 1, name: "core", kind: "message", T: RegisterActivityCore },
    { no: 2, name: "deregister_focus", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message devices.RegisterBrowserFocusResponse
 */
export const RegisterBrowserFocusResponse = proto3.makeMessageType(
  "devices.RegisterBrowserFocusResponse",
  [],
);

/**
 * @generated from message devices.RegisterPresenceModeRequest
 */
export const RegisterPresenceModeRequest = proto3.makeMessageType(
  "devices.RegisterPresenceModeRequest",
  () => [
    { no: 1, name: "core", kind: "message", T: RegisterActivityCore },
    { no: 2, name: "presence_mode", kind: "enum", T: proto3.getEnumType(PresenceMode) },
    { no: 3, name: "deregister", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message devices.RegisterPresenceModeResponse
 */
export const RegisterPresenceModeResponse = proto3.makeMessageType(
  "devices.RegisterPresenceModeResponse",
  [],
);

/**
 * @generated from message devices.ReportFirebaseTokenRequest
 */
export const ReportFirebaseTokenRequest = proto3.makeMessageType(
  "devices.ReportFirebaseTokenRequest",
  () => [
    { no: 1, name: "device_id", kind: "message", T: DeviceId },
    { no: 3, name: "token", kind: "message", T: FirebaseToken },
  ],
);

/**
 * @generated from message devices.ReportFirebaseTokenResponse
 */
export const ReportFirebaseTokenResponse = proto3.makeMessageType(
  "devices.ReportFirebaseTokenResponse",
  [],
);

/**
 * @generated from message devices.ReportAPNSTokenRequest
 */
export const ReportAPNSTokenRequest = proto3.makeMessageType(
  "devices.ReportAPNSTokenRequest",
  () => [
    { no: 1, name: "device_id", kind: "message", T: DeviceId },
    { no: 3, name: "token", kind: "message", T: APNSToken },
  ],
);

/**
 * @generated from message devices.ReportAPNSTokenResponse
 */
export const ReportAPNSTokenResponse = proto3.makeMessageType(
  "devices.ReportAPNSTokenResponse",
  [],
);

/**
 * @generated from message devices.DeleteNotificationTokensRequest
 */
export const DeleteNotificationTokensRequest = proto3.makeMessageType(
  "devices.DeleteNotificationTokensRequest",
  () => [
    { no: 1, name: "firebase_tokens", kind: "message", T: FirebaseToken, repeated: true },
    { no: 2, name: "apns_tokens", kind: "message", T: APNSToken, repeated: true },
  ],
);

/**
 * @generated from message devices.DeleteNotificationTokensResponse
 */
export const DeleteNotificationTokensResponse = proto3.makeMessageType(
  "devices.DeleteNotificationTokensResponse",
  [],
);

/**
 * @generated from message devices.GetPersonDevicesRequest
 */
export const GetPersonDevicesRequest = proto3.makeMessageType(
  "devices.GetPersonDevicesRequest",
  () => [
    { no: 1, name: "person_id", kind: "message", T: PersonId },
  ],
);

/**
 * @generated from message devices.GetPersonDevicesResponse
 */
export const GetPersonDevicesResponse = proto3.makeMessageType(
  "devices.GetPersonDevicesResponse",
  () => [
    { no: 1, name: "overviews", kind: "message", T: DeviceOverview, repeated: true },
  ],
);

/**
 * @generated from message devices.FilterConnectedUsersRequest
 */
export const FilterConnectedUsersRequest = proto3.makeMessageType(
  "devices.FilterConnectedUsersRequest",
  () => [
    { no: 1, name: "user_ids", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message devices.FilterConnectedUsersResponse
 */
export const FilterConnectedUsersResponse = proto3.makeMessageType(
  "devices.FilterConnectedUsersResponse",
  () => [
    { no: 1, name: "user_ids", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message devices.SubPersonalActivityRequest
 */
export const SubPersonalActivityRequest = proto3.makeMessageType(
  "devices.SubPersonalActivityRequest",
  () => [
    { no: 1, name: "add_to_sub", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "person_ids", kind: "message", T: PersonSet },
  ],
);

/**
 * @generated from message devices.SubPersonalActivityResponse
 */
export const SubPersonalActivityResponse = proto3.makeMessageType(
  "devices.SubPersonalActivityResponse",
  () => [
    { no: 1, name: "person_id", kind: "message", T: PersonId },
    { no: 2, name: "activity", kind: "message", T: PersonalActivity, oneof: "activity_or_deleted" },
    { no: 3, name: "deleted_id", kind: "message", T: PersonId, oneof: "activity_or_deleted" },
  ],
);

/**
 * @generated from message devices.SubObserversRequest
 */
export const SubObserversRequest = proto3.makeMessageType(
  "devices.SubObserversRequest",
  () => [
    { no: 1, name: "view_urn", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message devices.SubObserversResponse
 */
export const SubObserversResponse = proto3.makeMessageType(
  "devices.SubObserversResponse",
  () => [
    { no: 1, name: "view_urn", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "observers", kind: "message", T: UserObservation, repeated: true },
  ],
);

/**
 * @generated from message devices.SubObserversV2Request
 */
export const SubObserversV2Request = proto3.makeMessageType(
  "devices.SubObserversV2Request",
  () => [
    { no: 1, name: "view_urns", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "add_to_sub", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message devices.SubObserversV2Response
 */
export const SubObserversV2Response = proto3.makeMessageType(
  "devices.SubObserversV2Response",
  () => [
    { no: 1, name: "view_urn", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "observers", kind: "message", T: UserObservation, repeated: true },
  ],
);

/**
 * @generated from message devices.GetActiveObserversRequest
 */
export const GetActiveObserversRequest = proto3.makeMessageType(
  "devices.GetActiveObserversRequest",
  () => [
    { no: 1, name: "view_urn", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message devices.GetActiveObserversResponse
 */
export const GetActiveObserversResponse = proto3.makeMessageType(
  "devices.GetActiveObserversResponse",
  () => [
    { no: 1, name: "observers", kind: "message", T: UserSet },
  ],
);

