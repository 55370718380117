import * as d from "@/domain/domain";
import { DeltaSummarySquadMetadata, SummaryRequestStatus } from "@/domain/intel";
import { AppView, viewStackUtils, viewUtils } from "@/domain/views";
import {
    clearDeltaKnowledgeBond,
    selectBondSidebarSummarySavedPublishedSeqNum,
    selectBondSummaryRequestStatus,
    selectChannelIdByBondId,
    selectDeltaKnowledgeBond,
    selectLiveCallIdByBondId,
} from "@/features/bonds";
import { selectCallById } from "@/features/calls";
import { selectSquadLatestActivityDelayed } from "@/features/channels.ts";
import { selectMaxSequenceNumber } from "@/features/channels.ts";
import useDebouncedFollowedUnreadCount from "@/hooks/useDebouncedFollowedUnreadCount";

import { selectSquadHasUnreadBonds } from "@/features/filteredBonds";
import { selectCurrentViewStack } from "@/features/filterPanel";
import {
    clearInboxKnowledge,
    fetchDeltaKnowledgeBondThunk,
    fetchDeltaKnowledgeInboxThunk,
    fetchDeltaKnowledgeSquadThunk,
    selectInboxKnowledgeMetadata,
    selectInboxSummaryRequestStatus,
} from "@/features/intel.ts";
import { selectInboxLastActivityAt } from "@/features/presence";
import {
    clearDeltaKnowledgeSquad,
    selectSquadKnowledgeMetadata,
    selectSquadSummaryRequestStatus,
} from "@/features/squads";
import useViewStackNavigate from "@/hooks/navigation/useViewStackNavigate";
import usePrevious from "@/hooks/usePrevious";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { isMobileBrowser } from "@/misc/mobile";
import { Optional } from "@/misc/types";
import { useAppDispatch } from "@/store/redux";
import { useAppSelector } from "@/store/redux";
import { useCallback, useEffect } from "react";

function SummaryButtonSpinner({ onClick }: { onClick: () => void; }): React.JSX.Element {
    return (
        <button
            className="c-btn-generate-summary"
            title="Generating summary"
            onClick={onClick}
        >
        </button>
    );
}

function SummaryButtonInternal(
    { isDisabled, clickable, onClick }: {
        isDisabled: boolean;
        clickable: boolean;
        onClick: () => void;
    },
): React.JSX.Element {
    const buttonClass = isDisabled && clickable ? `c-btn-summary-disabled-but-clickable`
        : `c-btn-summary`;
    return (
        <button
            className={buttonClass}
            title="View summary"
            onClick={onClick}
            disabled={!clickable}
        >
        </button>
    );
}

function isKnowledgeMetadataUpToDate(
    knowledgeMetadata: Optional<DeltaSummarySquadMetadata>,
    bondListLastActivityAt: Optional<number>,
): boolean {
    const valuesAreDefined = knowledgeMetadata !== undefined &&
        bondListLastActivityAt !== undefined;
    return valuesAreDefined && knowledgeMetadata.LastActivityAt >= bondListLastActivityAt;
}

export function SummaryButtonInbox(): React.JSX.Element {
    const summaryRequestStatus = useSelectorArgs(selectInboxSummaryRequestStatus);
    const knowledgeMetadata = useSelectorArgs(selectInboxKnowledgeMetadata);
    const bondListLastActivityAt = useSelectorArgs(selectInboxLastActivityAt);
    const debouncedUnreadCount = useDebouncedFollowedUnreadCount();
    const hasUnreadBonds = debouncedUnreadCount > 0;
    const knowledgeIsUpToDate = isKnowledgeMetadataUpToDate(
        knowledgeMetadata,
        bondListLastActivityAt,
    );

    const dispatch = useAppDispatch();

    const viewStack = useAppSelector(selectCurrentViewStack);
    const { navigateReplace } = useViewStackNavigate();
    const prevSummaryRequestStatus = usePrevious(summaryRequestStatus);

    const toggleDisplaySummary = useCallback((visible?: boolean) => {
        const newStack = viewStackUtils.updateTopOfStack(
            viewStack,
            (frame: AppView) => viewUtils.toggleSquadOrInboxSummaryFrame(frame, undefined, visible),
        );
        navigateReplace(newStack, { replace: true });
    }, [
        viewStack,
        navigateReplace,
    ]);

    const fetchAndOrToggleSidebarSummary = useCallback(() => {
        const isProcessingRequest = summaryRequestStatus === SummaryRequestStatus.ProcessingRequest;
        const fetchNewKnowledge = hasUnreadBonds && !knowledgeIsUpToDate;
        if (!isProcessingRequest && fetchNewKnowledge) {
            dispatch(
                fetchDeltaKnowledgeInboxThunk(),
            );
        }
        if (knowledgeIsUpToDate) {
            toggleDisplaySummary();
        }
    }, [
        dispatch,
        summaryRequestStatus,
        hasUnreadBonds,
        knowledgeIsUpToDate,
        toggleDisplaySummary,
    ]);

    useEffect(() => {
        if (
            prevSummaryRequestStatus === SummaryRequestStatus.ProcessingRequest &&
            summaryRequestStatus === SummaryRequestStatus.Done
        ) {
            toggleDisplaySummary(true);
        }
    }, [prevSummaryRequestStatus, summaryRequestStatus, toggleDisplaySummary]);

    const handleError = useCallback(() => {
        dispatch(
            clearInboxKnowledge(),
        );
    }, [dispatch]);
    return (
        <SummaryButtonBondListInternal
            hasUnreadBonds={hasUnreadBonds}
            knowledgeIsUpToDate={knowledgeIsUpToDate}
            summaryRequestStatus={summaryRequestStatus}
            fetchAndOrToggleSidebarSummary={fetchAndOrToggleSidebarSummary}
            handleError={handleError}
        />
    );
}

export function SummaryButtonSquad(
    { squadId }: { squadId: d.SquadId; },
): React.JSX.Element {
    const summaryRequestStatus = useSelectorArgs(selectSquadSummaryRequestStatus, squadId);
    const knowledgeMetadata = useSelectorArgs(selectSquadKnowledgeMetadata, squadId);

    const squadLastActivityAt = useSelectorArgs(selectSquadLatestActivityDelayed, squadId);
    const hasUnreadBonds = useSelectorArgs(selectSquadHasUnreadBonds, squadId);
    const knowledgeIsUpToDate = isKnowledgeMetadataUpToDate(knowledgeMetadata, squadLastActivityAt);

    const dispatch = useAppDispatch();

    const viewStack = useAppSelector(selectCurrentViewStack);
    const { navigateReplace } = useViewStackNavigate();
    const prevSummaryRequestStatus = usePrevious(summaryRequestStatus);

    const toggleDisplaySummary = useCallback((visible?: boolean) => {
        const newStack = viewStackUtils.updateTopOfStack(
            viewStack,
            (frame: AppView) => viewUtils.toggleSquadOrInboxSummaryFrame(frame, squadId, visible),
        );
        navigateReplace(newStack, { replace: true });
    }, [
        viewStack,
        navigateReplace,
        squadId,
    ]);

    const fetchAndOrToggleSidebarSummary = useCallback(() => {
        const isProcessingRequest = summaryRequestStatus === SummaryRequestStatus.ProcessingRequest;
        const fetchNewKnowledge = hasUnreadBonds && !knowledgeIsUpToDate;
        if (!isProcessingRequest && fetchNewKnowledge) {
            dispatch(
                fetchDeltaKnowledgeSquadThunk({
                    squadId,
                }),
            );
        }
        if (knowledgeIsUpToDate) {
            toggleDisplaySummary();
        }
    }, [
        dispatch,
        squadId,
        summaryRequestStatus,
        hasUnreadBonds,
        knowledgeIsUpToDate,
        toggleDisplaySummary,
    ]);

    useEffect(() => {
        if (
            prevSummaryRequestStatus === SummaryRequestStatus.ProcessingRequest &&
            summaryRequestStatus === SummaryRequestStatus.Done
        ) {
            toggleDisplaySummary(true);
        }
    }, [prevSummaryRequestStatus, summaryRequestStatus, toggleDisplaySummary]);

    const handleError = useCallback(() => {
        dispatch(
            clearDeltaKnowledgeSquad(squadId),
        );
    }, [squadId, dispatch]);

    return (
        <SummaryButtonBondListInternal
            hasUnreadBonds={hasUnreadBonds}
            knowledgeIsUpToDate={knowledgeIsUpToDate}
            summaryRequestStatus={summaryRequestStatus}
            fetchAndOrToggleSidebarSummary={fetchAndOrToggleSidebarSummary}
            handleError={handleError}
        />
    );
}

interface SummaryButtonBondListInternalProps {
    hasUnreadBonds?: boolean;
    summaryRequestStatus?: SummaryRequestStatus;
    knowledgeIsUpToDate: boolean;
    fetchAndOrToggleSidebarSummary: () => void;
    handleError: () => void;
}

function SummaryButtonBondListInternal(
    props: SummaryButtonBondListInternalProps,
): React.JSX.Element {
    const {
        hasUnreadBonds,
        summaryRequestStatus,
        knowledgeIsUpToDate,
        handleError,
        fetchAndOrToggleSidebarSummary,
    } = props;

    const isMobile = isMobileBrowser();
    if (isMobile) return <></>;
    switch (summaryRequestStatus) {
        case SummaryRequestStatus.ProcessingRequest:
            return (
                <SummaryButtonSpinner
                    onClick={fetchAndOrToggleSidebarSummary}
                />
            );
        case SummaryRequestStatus.Error:
            return (
                <SummaryButtonInternal
                    isDisabled={knowledgeIsUpToDate}
                    clickable={true}
                    onClick={handleError}
                />
            );
        case SummaryRequestStatus.Done:
            return (
                <SummaryButtonInternal
                    isDisabled={knowledgeIsUpToDate}
                    clickable={true}
                    onClick={fetchAndOrToggleSidebarSummary}
                />
            );
        default: // Status: hide sidebar
            return (
                <SummaryButtonInternal
                    isDisabled={knowledgeIsUpToDate}
                    clickable={hasUnreadBonds || knowledgeIsUpToDate}
                    onClick={fetchAndOrToggleSidebarSummary}
                />
            );
    }
}

export function SummaryButtonBond({ bondId }: { bondId: d.BondId; }): React.JSX.Element {
    const channelId = useAppSelector(selectChannelIdByBondId(bondId));
    const summaryRequestStatus = useSelectorArgs(selectBondSummaryRequestStatus, bondId);
    const savedPublishedSeqNum = useSelectorArgs(
        selectBondSidebarSummarySavedPublishedSeqNum,
        bondId,
    );
    const maxSequenceNumber = useSelectorArgs(selectMaxSequenceNumber, channelId) ?? 0;
    const deltaKnowledge = useSelectorArgs(selectDeltaKnowledgeBond, bondId);
    const isMobile = isMobileBrowser();

    const bondHasKnowledge = deltaKnowledge !== undefined;
    const knowledgeIsUpToDate = bondHasKnowledge &&
        deltaKnowledge !== undefined &&
        savedPublishedSeqNum !== undefined &&
        deltaKnowledge.lastReadSeqNumber >= savedPublishedSeqNum
        && deltaKnowledge.lastSummarisedSeq === maxSequenceNumber;
    const bondHasNewContent = savedPublishedSeqNum !== undefined &&
        maxSequenceNumber > savedPublishedSeqNum;

    const liveCallId = useSelectorArgs(selectLiveCallIdByBondId, bondId);
    const liveCall = useSelectorArgs(selectCallById, liveCallId);
    const isLiveCall = liveCall !== undefined && !liveCall.endedAt;
    // participantsByContributions is a list of call participants with
    // transcript events in the call. If this list is empty, then the call does
    // not have any transcript events
    const callHasTranscriptEvents = liveCall !== undefined &&
        liveCall.participantsByContribution.length > 0;

    const dispatch = useAppDispatch();

    const viewStack = useAppSelector(selectCurrentViewStack);
    const { navigateReplace } = useViewStackNavigate();
    const prevSummaryRequestStatus = usePrevious(summaryRequestStatus);

    const toggleDisplaySummary = useCallback((visible?: boolean) => {
        const newStack = viewStackUtils.updateTopOfStack(viewStack, frame => {
            const summary = viewUtils.isSingleBond(frame) ? !frame.summary : undefined;
            return viewUtils.singleBond(bondId, undefined, visible ?? summary);
        });
        navigateReplace(newStack, { replace: true });
    }, [
        viewStack,
        navigateReplace,
        bondId,
    ]);

    const fetchAndOrToggleSidebarSummary = useCallback(() => {
        if (savedPublishedSeqNum === undefined) return;
        const isProcessingRequest = summaryRequestStatus === SummaryRequestStatus.ProcessingRequest;
        const fetchNewKnowledge = isLiveCall ? !knowledgeIsUpToDate
            : (!knowledgeIsUpToDate && bondHasNewContent);
        if (!isProcessingRequest && fetchNewKnowledge) {
            dispatch(
                fetchDeltaKnowledgeBondThunk({
                    bondId,
                    lastReadSequenceNumber: savedPublishedSeqNum,
                }),
            );
        }
        if (knowledgeIsUpToDate || (bondHasKnowledge && !bondHasNewContent)) {
            toggleDisplaySummary();
        }
    }, [
        isLiveCall,
        dispatch,
        bondId,
        savedPublishedSeqNum,
        summaryRequestStatus,
        knowledgeIsUpToDate,
        toggleDisplaySummary,
        bondHasKnowledge,
        bondHasNewContent,
    ]);

    useEffect(() => {
        if (
            prevSummaryRequestStatus === SummaryRequestStatus.ProcessingRequest &&
            summaryRequestStatus === SummaryRequestStatus.Done
        ) {
            toggleDisplaySummary(true);
        }
    }, [prevSummaryRequestStatus, summaryRequestStatus, toggleDisplaySummary]);

    const handleError = useCallback(() => {
        dispatch(
            clearDeltaKnowledgeBond({
                bondId,
            }),
        );
    }, [bondId, dispatch]);

    const currentView = viewStack[viewStack.length - 1];
    const displayingSidebar = viewUtils.isSingleBond(currentView) && currentView.summary;
    const buttonIsClickable = isLiveCall ? callHasTranscriptEvents
        : bondHasKnowledge || bondHasNewContent;
    if (isMobile || !channelId || !bondId) return <></>;

    const displayButtonAsDisabled = isLiveCall ? knowledgeIsUpToDate
        : bondHasKnowledge || !bondHasNewContent;
    switch (summaryRequestStatus) {
        case SummaryRequestStatus.ProcessingRequest:
            return (
                <SummaryButtonSpinner
                    onClick={fetchAndOrToggleSidebarSummary}
                />
            );
        case SummaryRequestStatus.Error:
            return (
                <SummaryButtonInternal
                    isDisabled={displayButtonAsDisabled}
                    clickable={true}
                    onClick={handleError}
                />
            );
        default:
            return (
                <SummaryButtonInternal
                    isDisabled={displayButtonAsDisabled}
                    clickable={displayingSidebar || buttonIsClickable}
                    onClick={fetchAndOrToggleSidebarSummary}
                />
            );
    }
}
