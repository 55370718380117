import React, { ForwardedRef, forwardRef, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import MessageComposer from "@/components/MessageComposer";
import * as d from "@/domain/domain";
import { newChannelDraftTarget } from "@/domain/draftTarget";
import { DraftChatMessage } from "@/domain/messages";
import {
    selectAllBondInvolvees,
    selectBondIdByChannelId,
    selectLiveCallIdByBondId,
} from "@/features/bonds";
import { stageMessageForChannel } from "@/features/channels";
import useViewStackNavigate from "@/hooks/navigation/useViewStackNavigate";
import useBooleanFeatureFlag from "@/hooks/useBooleanFeatureFlag";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { Focusable, Optional } from "@/misc/types";
import { useAppDispatch } from "@/store/redux";

const placeholder = "Message...";

export interface ChannelMessageComposerProps {
    channelId: d.ChannelId;
    onModalChange?: (current: boolean) => void;
    onEditorFocus?: () => void;
    onEditorBlur?: () => void;
}

export const ChannelMessageComposer = forwardRef((
    props: ChannelMessageComposerProps,
    ref: ForwardedRef<Focusable>,
): React.JSX.Element => {
    const {
        channelId,
        onModalChange,
        onEditorFocus,
        onEditorBlur,
    } = props;

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { navigatePop } = useViewStackNavigate();

    const escapeAction = useCallback(() => navigatePop(), [navigatePop]);

    const bondId = useSelectorArgs(selectBondIdByChannelId, channelId);
    const liveCallId = useSelectorArgs(selectLiveCallIdByBondId, bondId);

    const isCallLocationFlagged = useBooleanFeatureFlag("call-location-controls");
    const callLocationEnabled = !!isCallLocationFlagged && !!liveCallId;

    const allBondInvolvees = useSelectorArgs(selectAllBondInvolvees, bondId);

    const draftTarget = useMemo(() => newChannelDraftTarget(channelId), [channelId]);

    const sendMessage = useCallback((draft: Optional<DraftChatMessage>) => {
        if (!draft) return;

        dispatch(stageMessageForChannel({ ...draft, liveCallId }));
    }, [dispatch, liveCallId]);

    const openCallLocationModal = useCallback(() => {
        if (!callLocationEnabled) return;
        navigate("location");
    }, [callLocationEnabled, navigate]);
    const showCallLocationAction = callLocationEnabled ? openCallLocationModal : undefined;

    return (
        <MessageComposer
            id={`comms-input-${channelId}`}
            ref={ref}
            key={`comms-input-bond-channel-${channelId}`}
            draftTarget={draftTarget}
            msgCompletionAction={sendMessage}
            escapeAction={escapeAction}
            numberOfParticipants={allBondInvolvees.length}
            placeholder={placeholder}
            onModalChange={onModalChange}
            onEditorFocus={onEditorFocus}
            onEditorBlur={onEditorBlur}
            showCallLocationAction={showCallLocationAction}
        />
    );
});
