import * as d from "@/domain/domain";
import { isDevEnv } from "@/misc/environment";
import log from "@/misc/log";
import { promiseWithResolvers } from "@/misc/promises";
import { nanoid } from "@reduxjs/toolkit";
import { z } from "zod";
import { AuthMachineStorage, AuthRequestStateSchema } from "./types";
import {
    ascii_to_uint8array,
    base64url_decode,
    base64url_encode,
    cbckey_from_string,
    decrypt_cbc,
    encrypt_cbc,
    uint8array_to_ascii,
} from "./util";

// These need to be 16 bytes. The idea is just to provide some obfuscation, not security.
const defaultEncryptionKeyString = "76031c6762678790";
const defaultEncryptionIv = ascii_to_uint8array("fb7ff654b65c40c3");

export class EncryptedStorageWrapper implements AuthMachineStorage {
    constructor(private readonly _next: AuthMachineStorage) {}

    async dispose() {
        if ("dispose" in this._next) return (this._next as any).dispose();
    }

    async load() {
        const data = await this._next.load();
        if (data === null) return null;

        try {
            const ciphertext = base64url_decode(data);
            const plaintext = await decrypt_cbc(
                await cbckey_from_string(defaultEncryptionKeyString),
                defaultEncryptionIv,
                ciphertext,
            );
            const ascii = uint8array_to_ascii(new Uint8Array(plaintext));
            return JSON.parse(ascii);
        }
        catch (_e) {
            return null;
        }
    }

    async save(state: any | null) {
        if (state === null) return this._next.save(state);

        const stateString = JSON.stringify(state);

        const ciphertext = await encrypt_cbc(
            await cbckey_from_string(defaultEncryptionKeyString),
            defaultEncryptionIv,
            ascii_to_uint8array(stateString),
        );
        const base64 = base64url_encode(ciphertext);
        await this._next.save(base64);
    }
}

export class BroadcastChannelStorage implements AuthMachineStorage {
    constructor(private readonly personId: d.PersonId) {}

    private getBC() {
        // TODO: think about https://developer.mozilla.org/en-US/docs/Web/API/BroadcastChannel/close
        return new BroadcastChannel("auth-storage-prsn");
    }

    async load() {
        const bc = this.getBC();
        let cookie = nanoid();
        const { resolve, promise } = promiseWithResolvers<any>();

        const handleMessage = (ev: MessageEvent<any>) => {
            const parseResult = BroadcastChannelStorage.MessageSchema.safeParse(ev.data);

            if (!parseResult.success) return;

            const { type } = parseResult.data;

            if (
                type === "storage-load-response" &&
                parseResult.data.cookie === cookie &&
                parseResult.data.pid === this.personId
            ) {
                resolve(parseResult.data.storage);
            }
            else if (type === "storage-gratuitous-load-complete") {
                cookie = nanoid();
                const msg: BroadcastChannelStorageLoadRequestType = {
                    type: "storage-load-request",
                    cookie,
                    pid: this.personId,
                };
                bc.postMessage(msg);
            }
        };

        bc.addEventListener("message", handleMessage);

        const msg: BroadcastChannelStorageLoadRequestType = {
            type: "storage-load-request",
            cookie,
            pid: this.personId,
        };
        bc.postMessage(msg);

        const ret = await promise;
        bc.removeEventListener("message", handleMessage);

        return ret;
    }

    async save(state: any | null): Promise<void> {
        const bc = this.getBC();
        const cookie = nanoid();
        const { resolve, promise } = promiseWithResolvers<void>();

        const handleMessage = (ev: MessageEvent<any>) => {
            const parseResult = BroadcastChannelStorage.MessageSchema.safeParse(ev.data);
            if (!parseResult.success) return;

            if (
                parseResult.data.type === "storage-save-response" &&
                parseResult.data.cookie === cookie
            ) {
                resolve();
            }
        };
        bc.addEventListener("message", handleMessage);

        const msg: BroadcastChannelStorageSaveRequestType = {
            type: "storage-save-request",
            pid: this.personId,
            cookie,
            storage: state,
        };
        bc.postMessage(msg);

        await promise;

        bc.removeEventListener("message", handleMessage);
    }

    async dispose(del: boolean = false, _wait: boolean = false) {
        if (del) {
            window.localStorage.removeItem(`auth:${this.personId}`);
        }
    }

    // A note on the cookies used in these schemas. We need to handle ordering and
    // races between the tab running the localStorage code, and the shared worker.
    // The cookie allows precise matching of request and response, meaning we don't
    // use an old reply to a new request. The cookie is just a random ID.
    static readonly LoadRequestSchema = z.object({
        type: z.literal("storage-load-request"),
        cookie: z.string(),
        pid: d.PersonIdSchema,
    });

    static readonly LoadResponseSchema = z.object({
        type: z.literal("storage-load-response"),
        pid: d.PersonIdSchema,
        cookie: z.string(),
        storage: z.any(),
    });

    static readonly GratuitousLoadSchema = z.object({
        type: z.literal("storage-gratuitous-load-complete"),
    });

    static readonly SaveRequestSchema = z.object({
        type: z.literal("storage-save-request"),
        pid: d.PersonIdSchema,
        cookie: z.string(),
        storage: z.any(),
    });

    static readonly SaveResponseSchema = z.object({
        type: z.literal("storage-save-response"),
        cookie: z.string(),
    });

    static readonly MessageSchema = z.discriminatedUnion("type", [
        this.LoadRequestSchema,
        this.LoadResponseSchema,
        this.GratuitousLoadSchema,
        this.SaveRequestSchema,
        this.SaveResponseSchema,
    ]);
}

type BroadcastChannelStorageLoadRequestType = z.infer<
    typeof BroadcastChannelStorage.LoadRequestSchema
>;
type BroadcastChannelGratuitousLoadCompleteType = z.infer<
    typeof BroadcastChannelStorage.GratuitousLoadSchema
>;
type BroadcastChannelStorageSaveRequestType = z.infer<
    typeof BroadcastChannelStorage.SaveRequestSchema
>;
type BroadcastChannelStorageSaveResponseSchema = z.infer<
    typeof BroadcastChannelStorage.SaveResponseSchema
>;
type BroadcastChannelStorageLoadResponseType = z.infer<
    typeof BroadcastChannelStorage.LoadResponseSchema
>;

// TODO: remove this class once the migration is complete #4334
class LocalStorageUser implements AuthMachineStorage {
    constructor(private readonly userId: d.UserId) {}

    async load() {
        const data = window.localStorage.getItem(`auth:${this.userId}`);
        if (data === null) {
            return null;
        }
        return JSON.parse(data);
    }

    async save(state: any | null) {
        if (state === null) {
            window.localStorage.removeItem(`auth:${this.userId}`);
            return;
        }
        window.localStorage.setItem(`auth:${this.userId}`, state);
    }
}
class LocalStoragePerson implements AuthMachineStorage {
    constructor(private readonly personId: d.PersonId) {}

    async load() {
        const data = window.localStorage.getItem(`auth:${this.personId}`);
        if (data === null) {
            return null;
        }
        return JSON.parse(data);
    }

    async save(state: any | null) {
        if (state === null) {
            window.localStorage.removeItem(`auth:${this.personId}`);
            return;
        }
        window.localStorage.setItem(`auth:${this.personId}`, state);
    }
}

export function tokenStorageUser(userId: d.UserId): AuthMachineStorage {
    const baseStorage = new LocalStorageUser(userId);

    if (isDevEnv) {
        return baseStorage;
    }

    return new EncryptedStorageWrapper(baseStorage);
}

async function userToPersonBCStorageMigrate(
    userId: d.UserId,
    personId: d.PersonId,
) {
    log.info("Auth storage: user -> person storage migration started");

    // Migrate data held for user ID to person ID
    const userStorage = new LocalStorageUser(userId);
    const personStorage = new LocalStoragePerson(personId);

    const data = await userStorage.load();
    if (data !== null) {
        await personStorage.save(JSON.stringify(data));
        await userStorage.save(null);
    }

    log.info("Auth storage: user -> person storage migration complete");
}

export function broadcastChannelStorageToLocalStorage(userId?: d.UserId, personId?: d.PersonId) {
    const bc = new BroadcastChannel("auth-storage-prsn");

    const storageLoad = (pid: d.PersonId, cookie: string) => {
        let storage: any = null;

        try {
            storage = window.localStorage.getItem(`auth:${pid}`);
            if (storage !== null) {
                storage = JSON.parse(storage);
            }
        }
        catch (err) {
            log.warn(`Error loading auth from localStorage`, err);
        }

        const msg: BroadcastChannelStorageLoadResponseType = {
            type: "storage-load-response",
            cookie,
            pid: pid,
            storage,
        };
        bc.postMessage(msg);
    };

    const handleMessage = (ev: MessageEvent<any>) => {
        const parseResult = BroadcastChannelStorage.MessageSchema.safeParse(ev.data);

        if (!parseResult.success) return;

        switch (parseResult.data.type) {
            case "storage-load-request":
                storageLoad(parseResult.data.pid, parseResult.data.cookie);
                break;

            case "storage-load-response":
            case "storage-save-response":
            case "storage-gratuitous-load-complete":
                break;

            case "storage-save-request": {
                if (parseResult.data.storage === null) {
                    window.localStorage.removeItem(`auth:${parseResult.data.pid}`);
                }
                else {
                    window.localStorage.setItem(
                        `auth:${parseResult.data.pid}`,
                        JSON.stringify(parseResult.data.storage),
                    );
                }
                const msg: BroadcastChannelStorageSaveResponseSchema = {
                    type: "storage-save-response",
                    cookie: parseResult.data.cookie,
                };
                bc.postMessage(msg);
                break;
            }
        }
    };

    const gratuitousLoad = () => {
        const msg: BroadcastChannelGratuitousLoadCompleteType = {
            type: "storage-gratuitous-load-complete",
        };
        bc.postMessage(msg);

        log.info("Auth storage: gratuitous load complete");
    };

    const controller = new AbortController();

    // Deliberately don't `await` this. It gets cancelled in the cleanup function due to
    // the controller.abort().
    navigator.locks.request("auth-storage-prsn", {
        mode: "exclusive",
        signal: controller.signal,
    }, async _lock => {
        const pwr = promiseWithResolvers<Event>();
        controller.signal.addEventListener("abort", pwr.resolve);

        try {
            if (userId && personId) {
                await userToPersonBCStorageMigrate(userId, personId);
            }

            bc.addEventListener("message", handleMessage);
            gratuitousLoad();

            await pwr.promise;
        }
        finally {
            controller.signal.removeEventListener("abort", pwr.resolve);
            bc.removeEventListener("message", handleMessage);
        }
    }).catch(_e => {});

    return () => {
        controller.abort();
    };
}

export class SessionStorage implements AuthMachineStorage {
    private static readonly storeName = "auth/authRequest";

    constructor() {
    }

    async load() {
        return window.sessionStorage.getItem(SessionStorage.storeName) ?? null;
    }

    async save(state: any | null) {
        if (state === null) {
            window.sessionStorage.removeItem(SessionStorage.storeName);
            return;
        }
        window.sessionStorage.setItem(SessionStorage.storeName, state);
    }
}

export function tokenStorage(personId: d.PersonId): AuthMachineStorage {
    const baseStorage = new BroadcastChannelStorage(personId);

    if (isDevEnv) {
        return baseStorage;
    }

    return new EncryptedStorageWrapper(baseStorage);
}

export function authRequestStorage() {
    const baseStorage = new SessionStorage();

    if (isDevEnv) {
        return baseStorage;
    }

    return new EncryptedStorageWrapper(baseStorage);
}

export async function loadCodeVerifier(): Promise<string | null> {
    const data = await authRequestStorage().load();
    if (!data) {
        return null;
    }

    return AuthRequestStateSchema.parse(JSON.parse(data)).code_verifier;
}
