import {
    RichTextEditorChangeHandler,
    RichTextEditorOps,
    RichTextEditorSelectHandler,
} from "@/components/richtext/RichTextEditor";
import {
    asChangeDelta,
    compareDeltas,
    type EmitterSource,
    EmitterSources,
} from "@/domain/richtext/quill/delta";
import { tagChange, tagPosition } from "@/domain/richtext/taggers";
import { Doc, Format, Provider } from "@/domain/richtext/types";
import { useShallowEquals } from "@/hooks/useShallowEquals";
import { mapRecord } from "@/misc/primatives";
import MentionBlot from "@/misc/quill/blots/MentionBlot";
import { MaxLength } from "@/misc/quill/modules/MaxLength";
import BeyondTheme from "@/misc/quill/themes/BeyondTheme";
import { Optional } from "@/misc/types";
import Block from "quill/blots/block";
import Break from "quill/blots/break";
import Container from "quill/blots/container";
import Cursor from "quill/blots/cursor";
import Inline from "quill/blots/inline";
import Scroll from "quill/blots/scroll";
import Text from "quill/blots/text";
import type { Delta, QuillOptions, Range } from "quill/core";
import Quill, { Parchment } from "quill/core";
import Keyboard from "quill/modules/keyboard";
import {
    ForwardedRef,
    forwardRef,
    memo,
    MutableRefObject,
    RefObject,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useMemo,
    useRef,
} from "react";

Quill.register("modules/maxLength", MaxLength);
Quill.register(`themes/${BeyondTheme.NAME}`, BeyondTheme);

const REGISTRY = new Parchment.Registry();
REGISTRY.register(
    Scroll,
    Block,
    Break,
    Container,
    Cursor,
    Inline,
    Text,
    MentionBlot,
);

// Utilities for tagging quill documents and changes
const tagQuillChange = (result: Delta, change: Delta) =>
    tagChange(asChangeDelta(result, change), Provider.Quill, Format.Internal);

export type QuillBinding = (typeof Keyboard)["DEFAULTS"]["bindings"][string];
type QuillBindingObject = Extract<QuillBinding, object>;
type QuillBindingHandler = QuillBindingObject["handler"];

const attachListeners = (
    quill: Quill | null,
    lastSelectionRef: MutableRefObject<Optional<Range>>,
    listenersRef: RefObject<QuillEventListeners>,
) => {
    if (!quill) return;

    // If the quill instance already has focus, call onFocus immediately
    if (quill.hasFocus()) {
        listenersRef.current?.onFocus?.();
    }

    const onTextChange = (delta: Delta, source: EmitterSource) => {
        const change = tagQuillChange(quill.getContents(), delta);
        listenersRef.current?.onChange?.(change, source == EmitterSources.API);
    };

    const onSelectionChange = (
        range: Range | null,
        oldRange: Range | null,
        source: EmitterSource,
    ) => {
        if (range === null) {
            if (oldRange !== null) listenersRef.current?.onBlur?.();
            return;
        }

        lastSelectionRef.current = range;

        if (oldRange === null) listenersRef.current?.onFocus?.();

        listenersRef.current?.onSelect?.(
            {
                start: tagPosition(range.index, Provider.Quill),
                end: tagPosition(range.index + range.length, Provider.Quill),
            },
            source == EmitterSources.API,
        );
    };

    const onEditorChange = (
        // Quill event handlers are not very well typed, so we have to use any[] here
        ...args: any[]
    ) => {
        switch (args[0]) {
            case Quill.events.TEXT_CHANGE:
                onTextChange(args[1], args[3]);
                break;
            case Quill.events.SELECTION_CHANGE:
                onSelectionChange(args[1], args[2], args[3]);
                break;
        }
    };

    quill.on(Quill.events.EDITOR_CHANGE, onEditorChange);
};

type QuillEventListeners = {
    /**
     * Handler to run when the contents of the editor change. This will always run, even
     * if a known change was programmatically made to the contents
     */
    onChange?: RichTextEditorChangeHandler<Provider.Quill>;
    /**
     * Handler to run when the selection of the editor changes. This will only run when
     * there is a valid selection
     */
    onSelect?: RichTextEditorSelectHandler<Provider.Quill>;
    /**
     * Handler to run when the editor gains focus
     */
    onFocus?: () => void;
    /**
     * Handler to run when the editor loses focus
     */
    onBlur?: () => void;
};

type QuillWrapperProps = Pick<React.HTMLProps<HTMLElement>, "tabIndex"> & QuillEventListeners & {
    /**
     * Collection of key bindings for the Quill keyboard module. Changes to any binding
     * names will cause the quill instance to be recreated. Changes to binding attributes
     * cannot be made without changing the binding name, with the exception of the
     * callbacks themselves, which are stored in refs and called indirectly
     */
    bindings?: Partial<Record<string, QuillBinding>>;
    /**
     * Placeholder text to show when the editor is empty
     */
    placeholder?: QuillOptions["placeholder"];
    /**
     * Editor theme name. A theme implementation must be registered in Quill against the given name
     */
    theme?: QuillOptions["theme"];
    /**
     * Optional maximum length for the editor contents in characters
     */
    maxLength?: number;
    /**
     * Contents that will be loaded when the editor is recreated, including the initial
     * creation. Changes to this markup will not trigger the recreation, but it will be
     * stored in a ref so it can be used when the recreation occurs for some other reason
     */
    docForInit?: Doc<Provider.Quill, Format.Internal>;
};

const QuillWrapperInternal = forwardRef((
    props: QuillWrapperProps,
    ref: ForwardedRef<RichTextEditorOps<Provider.Quill>>,
) => {
    const {
        bindings,
        placeholder,
        maxLength,
        docForInit,
        tabIndex,
        onChange,
        onSelect,
        onFocus,
        onBlur,
    } = props;
    const theme = props.theme ?? "bubble";

    const containerRef = useRef<HTMLDivElement>(null);

    // Refs for the initial markup and selection which are used when the Quill container
    // is recreated, but whose changes must not trigger the container recreation
    const docForInitRef = useRef<Optional<Doc<Provider.Quill, Format.Internal>>>();
    docForInitRef.current = docForInit;
    const lastSelectionRef = useRef<Optional<Range>>();

    const quillRef = useRef<Quill | null>(null);

    // Store the key binding handlers in a ref so they can be indirectly called from a
    // memoised bindings map, which can be passed to the quill instance once at creation
    const bindingHandlersRef = useRef<Map<string, Optional<QuillBindingHandler>>>(new Map());
    useEffect(() => {
        // Remove handlers from the map if the relevant binding key no longer exists
        bindingHandlersRef.current.forEach((_, key) => {
            if (bindings?.[key] === undefined) {
                bindingHandlersRef.current.delete(key);
            }
        });
        // Add/update handlers in the map for existing binding keys
        Object.entries(bindings ?? {}).forEach(e => {
            const [key, binding] = e;
            if (
                binding === undefined ||
                typeof binding == "string" ||
                typeof binding == "number"
            ) return;
            bindingHandlersRef.current.set(key, binding.handler);
        });
    }, [bindings]);

    const bindingsKeys = useShallowEquals(Object.keys(bindings ?? {}));
    const bindingsMemo = useMemo(
        () =>
            mapRecord(
                bindings ?? {},
                (k, v): QuillBindingObject => ({
                    ...(v as QuillBindingObject),
                    // Replace the handler with an indirect call through the ref map
                    handler: function (this: { quill: Quill; }, ...args) {
                        return bindingHandlersRef.current.get(k)?.apply(this, args);
                    },
                }),
            ),
        // We're intentionally breaking the reactive model here so we don't have to recreate the
        // Quill instance with new bindings whenever the handlers change. We will require that
        // bindings with the same key must be identical, so we can memoise on the binding keys
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [bindingsKeys],
    );

    // Store the event listeners in a ref so they can be indirectly called
    const listenersRef = useRef<QuillEventListeners>({ onChange, onSelect, onFocus, onBlur });

    // (Re)create the Quill editor based on the configuration props
    useLayoutEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        const editorContainer = container.appendChild(
            container.ownerDocument.createElement("div"),
        );
        const newQuill = new Quill(editorContainer, {
            registry: REGISTRY,
            modules: {
                keyboard: {
                    bindings: bindingsMemo,
                },
                maxLength: maxLength && { limit: maxLength },
            },
            placeholder,
            theme,
            // To avoid autofocus, we need to start the Quill off in read-only mode. Else
            // the first call to .setContents() below will auto-focus, which pops the keyboard
            // up on mobile
            readOnly: true,
        });
        editorContainer.firstElementChild?.setAttribute("tabIndex", tabIndex?.toString() ?? "");
        editorContainer.firstElementChild?.setAttribute(
            "data-testId",
            "quill-content-editable-div",
        );

        // Populate the initial contents and restore the selection if necessary
        if (docForInitRef.current !== undefined) {
            newQuill.setContents(docForInitRef.current.rawDoc);
        }
        newQuill.enable();

        // Clear the history, otherwise undo will clear the initial contents
        newQuill.history.clear();
        // TODO: carry over the history from the last instance (if any)

        // Attach indirect listeners from the listeners ref
        attachListeners(newQuill, lastSelectionRef, listenersRef);

        quillRef.current = newQuill;

        return () => {
            quillRef.current = null;
            container.innerHTML = "";
        };
    }, [
        bindingsMemo,
        placeholder,
        theme,
        maxLength,
        tabIndex,
    ]);

    useImperativeHandle(ref, (): RichTextEditorOps<Provider.Quill> => ({
        focus: options => {
            if (!quillRef.current) return;
            const quill = quillRef.current;

            quill.focus();

            if (options?.goToEnd) {
                quill.setSelection(quill.getLength());
            }
        },
        blur: () => quillRef.current?.blur(),
        hasFocus: () => quillRef.current?.hasFocus() ?? false,

        setContent: bridge => {
            const doc = bridge.get(Format.Internal);
            quillRef.current?.setContents(doc.rawDoc);
        },

        updateContent: bridge => {
            if (!quillRef.current) return;
            const quill = quillRef.current;

            const { doc, change } = bridge.get(Format.Internal);

            if (change) {
                const quillMarkup = quill.getContents();
                const newQuillMarkup = quillMarkup.compose(change.rawChange.change);

                if (compareDeltas(newQuillMarkup, doc.rawDoc)) {
                    // If the change produces the expected markup from the existing
                    // contents, we can apply the change to the editor directly
                    quill.updateContents(change.rawChange.change);

                    const hadFocus = quill.hasFocus();
                    const oldActiveElement = document.activeElement;

                    // Update the selection as well
                    const index = quill.getSelection()?.index ?? 0;
                    const newIndex = change.rawChange.change.transformPosition(index);
                    quill.setSelection(newIndex);

                    // Quill doesn't have a method to set the selection without also focussing
                    // the editor (i.e. the setting selection that will be used when the editor
                    // is refocused by external means). Setting the selection and immediately
                    // restoring the original focus will have to do
                    if (!hadFocus) {
                        quill.blur();
                        if (oldActiveElement instanceof HTMLElement) {
                            oldActiveElement.focus();
                        }
                    }

                    return;
                }
            }

            // If the change did not produce the expected markup, we have
            // to replace the entire contents of the editor in one go
            quill.setContents(doc.rawDoc);
        },

        getOffsetScreenPos: position => {
            if (position === undefined) return undefined;

            const bounds = quillRef.current?.selection.getBounds(position.rawPosition);
            return {
                x: bounds?.left ?? 0,
                y: bounds?.top ?? 0,
            };
        },
    }), []);

    return <div ref={containerRef} className="c-quill-wrapper"></div>;
});

export const QuillWrapper = memo(QuillWrapperInternal);

export default QuillWrapper;
