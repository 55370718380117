import {
    createEntityAdapter,
    createSlice,
    current,
    EntityState,
    PayloadAction,
} from "@reduxjs/toolkit";

import {
    BondObservations,
    getShareableBondInviteLink,
    inviteUserToBondViaEmail,
    modifyBondMembership,
    observeBond,
    observeSquadBondsList,
    redeemBondInvite,
    rejectBondInvite,
    retrieveBondInvite,
    separateCatchupKnowledgeOrDeleteds,
    setArchiveBond,
    setFollowBond,
    subArchivedBonds,
    subBond,
    subBondCatchupSummaries,
    subBondContemporaries,
    subBondObservers,
    subBondsList,
    subBondsV2,
    subPersonalBondInvites,
    updateBondTitle,
} from "@/api/bonds";
import { subFollowedUnreadBondsCount } from "@/api/squads";
import {
    BondHerald,
    BondHeraldOrDeleted,
    BondOverview,
    BondOverviewOrDeleted,
    CatchupKnowledge,
} from "@/domain/bonds";
import { isNullRole } from "@/domain/calls";
import * as d from "@/domain/domain";
import { DraftTarget, DraftType } from "@/domain/draftTarget";
import { DeltaKnowledgeBond, SummaryRequestStatus } from "@/domain/intel";
import { BondInvite } from "@/domain/invites";
import { UserObservation } from "@/domain/presence";
import { userSortByBondLiveActivity, userSortByPresence } from "@/domain/users";
import {
    createHAMT,
    createHAMTIndex,
    createHAMTRecord,
    createHAMTSet,
    hamtGet,
    hamtValues,
    ImmutableHAMT,
    ImmutableHAMTSet,
} from "@/ds/hamt";
import { resetStore, selectCurrentPersonId, selectCurrentUserId } from "@/features/auth";
import { createBondFromMessageThunk } from "@/features/bondCreation";
import { selectCallById, selectCurrentCallId, startCall } from "@/features/calls";
import { selectUserIdSetWithMentions, selectUserIdSetWithUnread } from "@/features/channels";
import { ConnectionStatus, updateConnectionStatus } from "@/features/connection";
import { fetchDeltaKnowledgeBondThunk } from "@/features/intel";
import {
    selectBondInterest,
    selectInvitedBondInterest,
    selectVisibleBondInterest,
    startManagingInterest,
} from "@/features/interest";
import { selectValidSquadIdsForMention } from "@/features/mentions";
import { multiRpcStreamManager } from "@/features/middleware";
import { createProxiedAsyncThunk } from "@/features/proxiedThunk";
import {
    createSelectorPair,
    memoizeOptions,
    shallowArrayEqualSelectorCreator,
} from "@/features/selectors";
import { selectUserIdsBySquadIds } from "@/features/squads";
import { createStreamingAsyncThunk } from "@/features/streamingThunk";
import { TrimmedThunkAPI, unaryThunkHandler } from "@/features/thunk";
import { selectUsers } from "@/features/users";
import { deepFreeze } from "@/misc/deepFreeze";
import log from "@/misc/log";
import { union } from "@/misc/primatives";
import { Diff, Optional, optionalFriendly, partialRecord } from "@/misc/types";
import { makeKeyedObjectFromArray } from "@/misc/utils";
import {
    checkPersistor,
    clearKey,
    fetchIdbStore,
    fetchKeyedIdbStore,
    idbStore,
    simpleIdbTranslate,
} from "@/persist/shared";
import type {
    HydrateArgs,
    IdbStoreUpdate,
    IdbStoreUpdateWithClear,
    PersistenceUpdate,
    RWTransaction,
} from "@/persist/types";
import { valueUpdateToStoreUpdate } from "@/persist/updates";
import { type AppAppendListener, appAppendListener } from "@/store/middleware";
import { createAppSelector } from "@/store/redux";
import type { RootState } from "@/store/types";
import type { BondContemporaries, CatchupKnowledgeOrDeleted } from "../api/bonds";

const followedUnreadBondsCountKey = "followedUnreadBondsCountKey";

const bondsAdapter = createEntityAdapter({
    selectId: (bond: BondOverview) => bond.id,
    sortComparer: (a: BondOverview, b: BondOverview) => {
        if (a.liveCallIds.length > 0 && b.liveCallIds.length == 0) return -1;
        if (a.liveCallIds.length == 0 && b.liveCallIds.length > 0) return 1;
        return b.lastActivityAt - a.lastActivityAt;
    },
});

const heraldsAdapter = createEntityAdapter({
    selectId: (herald: BondHerald) => herald.bondId,
    sortComparer: (a: BondHerald, b: BondHerald) => b.lastActivityAt - a.lastActivityAt,
});

const bondStreamParser = (
    { thunkAPI, content: batchedMsgs }: {
        thunkAPI: TrimmedThunkAPI;
        content: BondOverviewOrDeleted[];
    },
) => {
    const views = batchedMsgs.filter(x => x.case === "bond");
    if (views.length) {
        const items = views.map(v => v.value).filterLatest(v => v.id);
        thunkAPI.dispatch(streamedBonds(items));
    }

    const deleted = batchedMsgs.filter(x => x.case === "deletedId");
    if (deleted.length) {
        const ids = deleted.map(v => v.value);
        thunkAPI.dispatch(removeBonds(ids));
    }
};

export const subBondsV2Thunk = createStreamingAsyncThunk<
    AsyncIterableIterator<Diff<d.BondId>>,
    BondOverviewOrDeleted
>(
    "users/subBondsV2",
    {
        rpc: ({ arg, signal }) => subBondsV2(arg, signal),
        parser: args => bondStreamParser(args),
    },
);

// To preserve existing functionality, which uses loaded bonds in redux to
// determine the badge counts and squad unread status, we need to load all
// bonds in the user's inbox (e.g. heralds with the followed flag) as well as
// just those that we are tracking interest in.

// When the inbox state is fully backend driven, we can swap this selector for
// selectInterestedBonds.

export const startBondInterestStream = (
    appendListener: AppAppendListener = appAppendListener,
) => startManagingInterest(
    selectInterestedAndFollowedHeraldedIds,
    subBondsV2Thunk,
    appendListener,
);

const bondHeraldStreamParser = (
    { thunkAPI, content: batchedMsgs }: {
        thunkAPI: TrimmedThunkAPI;
        content: BondHeraldOrDeleted[];
    },
) => {
    const views = batchedMsgs.filter(x => x.case === "herald");
    if (views.length) {
        const items = views.map(v => v.value).filterLatest(v => v.bondId);
        thunkAPI.dispatch(streamedBondHeralds(items));
    }

    const deleted = batchedMsgs.filter(x => x.case === "deletedId");
    if (deleted.length) {
        const ids = deleted.map(v => v.value);
        thunkAPI.dispatch(removeBondHeralds(ids));
    }
};

export const subBondsListThunk = createStreamingAsyncThunk(
    "bonds/subBondsList",
    {
        rpc: ({ state, signal }) => {
            const userId = selectCurrentUserId(state)!;
            return subBondsList(userId, signal);
        },
        parser: args => bondHeraldStreamParser(args),
    },
);

export const streamArchivedBondsList = createStreamingAsyncThunk(
    "bonds/streamArchivedList",
    {
        rpc: ({ state, signal }) => {
            const userId = selectCurrentUserId(state)!;
            return subArchivedBonds(userId, signal);
        },
        parser: ({ thunkAPI, content: responses }) => {
            if (responses.length === 0) return;
            thunkAPI.dispatch(streamedArchivedBonds(responses));
        },
    },
);

export const subPersonalBondInvitesThunk = createStreamingAsyncThunk(
    "bonds/subPersonalBondInvites",
    {
        rpc: ({ state, signal }) => {
            const personId = selectCurrentPersonId(state)!;
            return subPersonalBondInvites(personId, signal);
        },
        parser: ({ thunkAPI, content: responses }) => {
            if (responses.length === 0) return;
            thunkAPI.dispatch(streamedPersonalBondInvites(responses));
        },
    },
);

export const observeSquadBondsListThunk = createStreamingAsyncThunk<
    d.SquadId
>(
    "bonds/observeSquadBondsList",
    {
        rpc: ({ arg: squadId, state, signal }) => {
            const userId = selectCurrentUserId(state)!;
            return observeSquadBondsList(squadId, userId, signal);
        },
        parser: () => {},
        logId: ({ arg: squadId }) => `observe for squad:${squadId}`,
    },
);

interface SubBondThunkArgs {
    bondId: d.BondId;
}

export const subBondThunk = createStreamingAsyncThunk<
    SubBondThunkArgs,
    BondOverviewOrDeleted
>(
    "bonds/subBond",
    {
        rpc: ({ arg: { bondId }, signal }) => {
            if (!bondId) return;
            return subBond(bondId, signal);
        },
        // Action to dispatch is defined after this function call, hence the lambda.
        parser: args => bondStreamParser(args),
        logId: ({ arg: { bondId } }) => `bond overview id:${bondId}`,
    },
);

interface ObserveBondThunkArgs {
    bondId: Optional<d.BondId>;
}
const observeBondThunk = createStreamingAsyncThunk<ObserveBondThunkArgs, void>(
    "bonds/observeBond",
    {
        rpc: ({ arg: { bondId }, signal }) => {
            if (!bondId) return;
            return observeBond(bondId, signal);
        },
        parser: () => {},
        logId: ({ arg: { bondId } }) => `observe bond:${bondId}`,
    },
);

// Note: Remember that this manager only reports observations for the visible
// bonds, it doesn't retrieve bond overviews for them.
export const visibleBondObservationManager = multiRpcStreamManager(
    selectVisibleBondInterest,
    (bondId: d.BondId) => observeBondThunk({ bondId }),
    "streamAndObserveBond",
);

export const invitedBondStreamingManager = multiRpcStreamManager(
    selectInvitedBondInterest,
    (bondId: d.BondId) => subBondThunk({ bondId }),
    "streamInvitedBond",
);

const subBondObserversThunk = createStreamingAsyncThunk<
    AsyncIterableIterator<Diff<d.BondId>>,
    BondObservations
>(
    "bonds/subBondObservers",
    {
        rpc: ({ arg, signal }) => subBondObservers(arg, signal),
        // Action to dispatch is defined after this function call, hence the lambda.
        parser: ({ thunkAPI, content: msgs }) => {
            const action = bondsSlice.actions.streamedManyBondObservers(
                msgs.filterLatest(msg => msg.viewId),
            );
            thunkAPI.dispatch(action);
        },
    },
);
export const startSubBondObserversStream = (
    appendListener: AppAppendListener = appAppendListener,
) => startManagingInterest(
    selectInterestedAndFollowedHeraldedIds,
    subBondObserversThunk,
    appendListener,
);

interface FollowBondArgs {
    bondId: d.BondId;
    follow: boolean;
}

export const followBond = createProxiedAsyncThunk(
    "bonds/follow",
    async (args: FollowBondArgs, thunkAPI) => {
        if (!args || !args.bondId) {
            throw thunkAPI.rejectWithValue({ error: `Tried to (un)follow bond without a bond id` });
        }

        log.info(`${args.follow ? "F" : "Unf"}ollowing ${args.bondId}`);

        return await unaryThunkHandler(
            thunkAPI,
            setFollowBond(args),
            `setFollowBond ${args.follow ? "true" : "false"} for ${args.bondId}`,
        );
    },
);

interface ArchiveBondArgs {
    bondId: d.BondId;
    archive: boolean;
    channelId?: d.ChannelId;
}

export const archiveBond = createProxiedAsyncThunk(
    "bonds/archive",
    async (args: ArchiveBondArgs, thunkAPI) => {
        if (!args || !args.bondId) {
            throw thunkAPI.rejectWithValue({
                error: `Tried to (un)archive bond without a bond id`,
            });
        }

        log.info(`${args.archive ? "Archiving" : "Unarchiving"} ${args.bondId}`);

        await unaryThunkHandler(
            thunkAPI,
            setArchiveBond(args),
            `setArchiveBond ${args.archive ? "true" : "false"} for ${args.bondId}`,
        );
    },
);

interface RenameBondTitleArgs {
    bondId: d.BondId;
    title: string;
    actingUserId: d.UserId;
}

export const renameBondTitle = createProxiedAsyncThunk(
    "bonds/titleupdate",
    async (args: RenameBondTitleArgs, thunkAPI) => {
        if (!args || !args.bondId) {
            throw thunkAPI.rejectWithValue({ error: `Tried to rename bond without a bond id` });
        }

        log.info(`Renaming ${args.bondId}`);

        return await unaryThunkHandler(
            thunkAPI,
            updateBondTitle(args),
            `updateBondTitle ${args.bondId}`,
        );
    },
);

interface ModifyBondMembershipArgs {
    bondId: d.BondId;
    userIdsToAdd: d.UserId[];
    squadIdsToAdd: d.SquadId[];
}

export const modifyBondMembershipThunk = createProxiedAsyncThunk(
    "bonds/modifyMembership",
    async (args: ModifyBondMembershipArgs, thunkAPI) => {
        if (!args || !args.bondId) {
            throw thunkAPI.rejectWithValue({
                error: `Tried to modify bond membership without a bond id`,
            });
        }

        log.info(`Modifying ${args.bondId} membership`);

        return await unaryThunkHandler(
            thunkAPI,
            modifyBondMembership(args),
            `modifyBondMembership ${args.bondId}`,
        );
    },
);

interface InviteUserToBondViaEmailArgs {
    bondId: d.BondId;
    invitedEmailAddress: string;
}

export const inviteUserToBondViaEmailThunk = createProxiedAsyncThunk(
    "bonds/inviteUserToBondViaEmail",
    async (args: InviteUserToBondViaEmailArgs, thunkAPI) => {
        if (!args || !args.bondId || !args.invitedEmailAddress) {
            throw thunkAPI.rejectWithValue({
                error: `Tried to invite user to bond without a bond id`,
            });
        }

        return await unaryThunkHandler(
            thunkAPI,
            inviteUserToBondViaEmail(args),
            `inviteUserToBond ${args.bondId}`,
        );
    },
);

interface GetShareableBondInviteLinkArgs {
    bondId: d.BondId;
}

export const getShareableBondInviteLinkThunk = createProxiedAsyncThunk(
    "bonds/getShareableBondInviteLink",
    async (args: GetShareableBondInviteLinkArgs, thunkAPI) => {
        if (!args || !args.bondId) {
            throw thunkAPI.rejectWithValue({
                error: `Tried to invite user to bond without a bond id`,
            });
        }

        return await unaryThunkHandler(
            thunkAPI,
            getShareableBondInviteLink(args),
            `getShareableBondInviteLink ${args.bondId}`,
        );
    },
);

interface RetrieveBondInviteArgs {
    opaqueCode: string;
}

export const retrieveBondInviteThunk = createProxiedAsyncThunk(
    "bonds/retrieveBondInvite",
    async (args: RetrieveBondInviteArgs, thunkAPI) => {
        if (!args) {
            throw thunkAPI.rejectWithValue({
                error: `Tried to retrieve invite without code`,
            });
        }

        const resp = await unaryThunkHandler(
            thunkAPI,
            retrieveBondInvite(args),
            `retrieveBondInvite code ${args.opaqueCode}`,
        );
        return resp?.invite;
    },
);

interface RedeemBondInviteArgs {
    opaqueCode: string;
}

export const redeemBondInviteThunk = createProxiedAsyncThunk(
    "bonds/redeemBondInvite",
    async (args: RedeemBondInviteArgs, thunkAPI) => {
        if (!args) {
            throw thunkAPI.rejectWithValue({
                error: `Tried to redeem invite without code`,
            });
        }

        return await unaryThunkHandler(
            thunkAPI,
            redeemBondInvite({
                opaqueCode: args.opaqueCode,
                actingUserId: selectCurrentUserId(thunkAPI.getState())!,
            }),
            `redeemBondInvite code ${args.opaqueCode}`,
        );
    },
);

interface RejectBondInviteArgs {
    bondId: d.BondId;
}

export const rejectBondInviteThunk = createProxiedAsyncThunk(
    "bonds/rejectBondInvite",
    async (args: RejectBondInviteArgs, thunkAPI) => {
        if (!args || !args.bondId) {
            throw thunkAPI.rejectWithValue({
                error: `Tried to reject invite without bond id`,
            });
        }

        return await unaryThunkHandler(
            thunkAPI,
            rejectBondInvite(args),
            `rejectBondInvite ${args.bondId}`,
        );
    },
);

// Currently unused
export const subBondContemporariesThunk = createStreamingAsyncThunk<
    AsyncIterableIterator<Diff<d.BondId>>,
    BondContemporaries
>(
    "users/subBondContemporaries",
    {
        rpc: ({ arg, signal }) => subBondContemporaries(arg, signal),
        // Action to dispatch is defined after this function call, hence the lambda.
        parser: ({ thunkAPI, content: msgs }) => {
            const action = bondsSlice.actions.streamedManyBondContemporaries(msgs);
            thunkAPI.dispatch(action);
        },
    },
);

export const subBondCatchupSummariesThunk = createStreamingAsyncThunk<
    AsyncIterableIterator<Diff<d.BondId>>,
    CatchupKnowledgeOrDeleted
>(
    "users/subBondCatchupSummaries",
    {
        rpc: ({ arg, state, signal }) => {
            const userId = selectCurrentUserId(state)!;
            return subBondCatchupSummaries(arg, { userId }, signal);
        },
        // Action to dispatch is defined after this function call, hence the lambda.
        parser: ({ thunkAPI, content: msgs }) => {
            const [toUpsert, toDelete] = separateCatchupKnowledgeOrDeleteds(msgs);
            const upsertAction = bondsSlice.actions.upsertCatchupSummaries(
                toUpsert.map(msg => msg.value),
            );
            const deleteAction = bondsSlice.actions.deleteCatchupSummaries(
                toDelete.map(msg => msg.deletedId),
            );
            thunkAPI.dispatch(upsertAction);
            thunkAPI.dispatch(deleteAction);
        },
    },
);

export const subFollowedUnreadBondsCountThunk = createStreamingAsyncThunk(
    "subFollowedUnreadBondsCount",
    {
        rpc: ({ state, signal }) => {
            const userId = selectCurrentUserId(state)!;
            return subFollowedUnreadBondsCount(userId, signal);
        },
        parser: ({ thunkAPI, content: counts }) =>
            thunkAPI.dispatch(
                bondsSlice.actions.streamedFollowedUnreadBondsCount(counts.at(-1) ?? 0),
            ),
    },
);

const hamts = {
    archivedBonds: createHAMTSet<d.BondId>(),
    catchupKnowledge: createHAMT((ck: CatchupKnowledge) => ck.bondId),
    channelIndex: createHAMTIndex(
        ({ channelId }: BondOverview) => channelId,
        ({ id }) => id,
    ),
    observers: createHAMT((v: BondObservations) => v.viewId),
    sidebarSummaryInfo: createHAMTRecord<d.BondId, BondSidebarSummaryInfo>(),
    personalBondInvites: createHAMT((bi: BondInvite) => bi.body.opaqueCode),
};

interface BondSidebarSummaryInfo {
    status?: SummaryRequestStatus;
    savedPublishedSeqNum?: number;
    deltaKnowledge?: DeltaKnowledgeBond;
}

export interface BondState {
    bonds: EntityState<BondOverview, d.BondId>;
    heralds: EntityState<BondHerald, d.BondId>;
    archivedBonds: ImmutableHAMTSet<d.BondId>;
    channelIndex: ImmutableHAMT<d.ChannelId, d.BondId>;
    // In future, could use change-tracking from BondOverview updates to manage
    // this.
    liveCallIndex: Record<d.CallId, d.BondId>;
    observers: ImmutableHAMT<d.BondId, BondObservations>;
    // Currently turned off, should move to a HAMT if re-enabled.
    contemporaries: Record<d.BondId, Array<d.UserId>>;
    catchupKnowledge: ImmutableHAMT<d.BondId, CatchupKnowledge>;

    sidebarSummaryInfo: ImmutableHAMT<d.BondId, BondSidebarSummaryInfo>;

    followedUnreadBondsCount: number;
    personalBondInvites: ImmutableHAMT<string, BondInvite>;

    // Not persisted. Interpreted from the current URL.
    // Could now be removed in favour of the structural nav data?
    selectedBond?: d.BondId;

    updates: BondUpdate[];

    cardViewScrollTop: number;
    discoverViewScrollTop: number;
}

const formLiveCallIndex = (bonds: BondOverview[]): Record<d.CallId, d.BondId> =>
    bonds.reduce(
        (
            index,
            { id: bondId, liveCallIds },
        ) => {
            if (liveCallIds.length > 0) {
                index[liveCallIds[0]] = bondId;
            }
            return index;
        },
        {} as Record<d.CallId, d.BondId>,
    );

const getInitialState = (props?: {
    bonds?: Record<d.BondId, BondOverview>;
    heralds?: Record<d.BondId, BondHerald>;
    catchupKnowledge?: Array<CatchupKnowledge>;
    archivedBonds?: Array<d.BondId>;
    followedUnreadBondsCount?: number;
    personalBondInvites?: Array<BondInvite>;
}): BondState => ({
    bonds: bondsAdapter.getInitialState({}, props?.bonds ?? {}),
    heralds: heraldsAdapter.getInitialState({}, props?.heralds ?? {}),
    channelIndex: hamts.channelIndex.initial(props?.bonds),
    liveCallIndex: formLiveCallIndex(Object.values(props?.bonds ?? {})),
    archivedBonds: hamts.archivedBonds.initial(props?.archivedBonds ?? {}),
    observers: hamts.observers.initial(),
    contemporaries: {},
    catchupKnowledge: hamts.catchupKnowledge.initial(props?.catchupKnowledge),
    sidebarSummaryInfo: hamts.sidebarSummaryInfo.initial(),
    updates: [],
    cardViewScrollTop: 0,
    discoverViewScrollTop: 0,
    followedUnreadBondsCount: props?.followedUnreadBondsCount ?? 0,
    personalBondInvites: hamts.personalBondInvites.initial(props?.personalBondInvites),
});

export const bondsSlice = createSlice({
    name: "bonds",
    initialState: getInitialState(),
    selectors: {
        selectedBond: state => state.selectedBond,
        archivedBonds: state => state.archivedBonds,
        channel: (state, channelId: Optional<d.ChannelId>): Optional<d.BondId> =>
            channelId && hamtGet(state.channelIndex, channelId),
        liveCall: (state, callId: Optional<d.CallId>): Optional<d.BondId> =>
            callId && state.liveCallIndex[callId],
        observers: (state, bondId: Optional<d.BondId>): Optional<UserObservation[]> =>
            bondId && hamtGet(state.observers, bondId)?.observations,
        contemporaries: (state, bondId: Optional<d.BondId>): Optional<d.UserId[]> =>
            bondId && state.contemporaries[bondId],
        catchupKnowledge: (state, bondId: Optional<d.BondId>): Optional<CatchupKnowledge> =>
            bondId && hamtGet(state.catchupKnowledge, bondId),
        sidebarSummaryInfo: (
            state,
            bondId: Optional<d.BondId>,
        ): Optional<BondSidebarSummaryInfo> => bondId && hamtGet(state.sidebarSummaryInfo, bondId),
        cardsViewScrollTop: state => state.cardViewScrollTop,
        discoverViewScrollTop: state => state.discoverViewScrollTop,
        followedUnreadBondsCount: state => state.followedUnreadBondsCount,
        personalBondInvites: state => state.personalBondInvites,
    },
    reducers: {
        streamedMany: (state, { payload: bos }: PayloadAction<BondOverview[]>) => {
            const currentState = current(state);

            state.bonds = bondsAdapter.upsertMany(state.bonds, bos);

            state.channelIndex = hamts.channelIndex.setMany(currentState.channelIndex, bos);

            bos.forEach(bo => {
                bo.liveCallIds.forEach(callId => state.liveCallIndex[callId] = bo.id);
            });

            state.updates = bos.map(bo => [stores.bonds, bo.id, bo]);
        },
        removeMany: (state, { payload: ids }: PayloadAction<d.BondId[]>) => {
            const currentState = current(state);

            const bonds = ids.map(id => state.bonds.entities[id]).filter(bo => !!bo);

            const originalChannels = bonds.map(({ channelId }) => channelId);
            state.channelIndex = hamts.channelIndex.removeMany(
                currentState.channelIndex,
                originalChannels,
            );

            bonds.flatMap(({ liveCallIds }) => liveCallIds)
                .forEach(id => delete state.liveCallIndex[id]);

            state.bonds = bondsAdapter.removeMany(state.bonds, ids);

            state.updates = ids.map(bondId => [stores.bonds, bondId]);
        },
        streamedManyHeralds: (state, { payload: bos }: PayloadAction<BondHerald[]>) => {
            state.heralds = heraldsAdapter.upsertMany(state.heralds, bos);
            state.updates = bos.map(bh => [stores.heralds, bh.bondId, bh]);
        },
        removeManyHeralds: (state, { payload: ids }: PayloadAction<d.BondId[]>) => {
            state.heralds = heraldsAdapter.removeMany(state.heralds, ids);
            state.updates = ids.map(bondId => [stores.heralds, bondId]);
        },
        updateSelected: (state, { payload: id }: PayloadAction<Optional<d.BondId>>) => {
            if (state.selectedBond === id) return;

            state.selectedBond = id;
        },
        clearSelected: state => {
            delete state.selectedBond;
        },
        streamedArchivedBonds: (
            state,
            { payload: diffs }: PayloadAction<(Diff<d.BondId> & { overwrite?: boolean; })[]>,
        ) => {
            if (diffs.length === 0) return;

            const currentState = current(state);
            state.updates = [];

            const { toTransient, toImmutable, setMany, removeMany, removeAll } =
                hamts.archivedBonds;

            let transient = toTransient(currentState.archivedBonds);

            diffs.forEach(action => {
                if (action.overwrite) {
                    transient = removeAll(transient);

                    state.updates = [[
                        stores.archivedBonds,
                        clearKey,
                    ]];
                }

                if (action.added) {
                    transient = setMany(transient, action.added);
                }

                if (action.removed) {
                    transient = removeMany(transient, action.removed);
                }
            });

            state.archivedBonds = toImmutable(
                transient,
                valueUpdateToStoreUpdate(state.updates, stores.archivedBonds),
            );
        },
        streamedBondObservers: (state, { payload: observers }: PayloadAction<BondObservations>) => {
            state.observers = hamts.observers.set(current(state).observers, observers);
        },
        streamedPersonalBondInvites: (
            state,
            { payload: inviteDiffs }: PayloadAction<
                (Diff<BondInvite> & { overwrite?: boolean; })[]
            >,
        ) => {
            if (inviteDiffs.length === 0) return;

            const currentState = current(state);
            state.updates = [];

            const { toTransient, toImmutable, setMany, removeMany, removeAll } =
                hamts.personalBondInvites;

            let transient = toTransient(currentState.personalBondInvites);

            inviteDiffs.forEach(action => {
                if (action.overwrite) {
                    transient = removeAll(transient);

                    state.updates = [[
                        stores.personalBondInvites,
                        clearKey,
                    ]];
                }

                if (action.added) {
                    transient = setMany(transient, action.added);
                }

                if (action.removed) {
                    transient = removeMany(transient, action.removed.map(bi => bi.body.opaqueCode));
                }
            });

            state.personalBondInvites = toImmutable(
                transient,
                valueUpdateToStoreUpdate(state.updates, stores.personalBondInvites),
            );
        },
        streamedManyBondObservers: (state, { payload: obs }: PayloadAction<BondObservations[]>) => {
            state.observers = hamts.observers.setMany(current(state).observers, obs);
        },
        streamedBondContemporaries: (state, action: PayloadAction<BondContemporaries>) => {
            const { bondId, userIds } = action.payload;
            state.contemporaries[bondId] = userIds;
        },
        streamedManyBondContemporaries: (
            state,
            { payload: contemps }: PayloadAction<BondContemporaries[]>,
        ) => {
            state.updates = [];
            contemps.forEach(({ bondId, userIds }) => {
                state.contemporaries[bondId] = userIds;
            });
        },
        setCardsViewScrollTop: (state, action: PayloadAction<number>) => {
            state.cardViewScrollTop = action.payload;
        },
        setDiscoverViewScrollTop: (state, action: PayloadAction<number>) => {
            state.discoverViewScrollTop = action.payload;
        },
        upsertCatchupSummaries: (
            state,
            { payload }: PayloadAction<Array<CatchupKnowledge>>,
        ) => {
            if (payload.length === 0) return;

            state.updates = [];

            state.catchupKnowledge = hamts.catchupKnowledge.setMany(
                current(state).catchupKnowledge,
                payload,
                valueUpdateToStoreUpdate(state.updates, stores.catchupKnowledge),
            );
        },
        deleteCatchupSummaries: (
            state,
            { payload }: PayloadAction<Array<d.BondId>>,
        ) => {
            if (payload.length === 0) return;

            state.updates = [];

            state.catchupKnowledge = hamts.catchupKnowledge.removeMany(
                current(state).catchupKnowledge,
                payload,
                valueUpdateToStoreUpdate(state.updates, stores.catchupKnowledge),
            );
        },
        streamedFollowedUnreadBondsCount: (state, { payload }: PayloadAction<number>) => {
            state.followedUnreadBondsCount = payload;
            state.updates.push([
                stores.followedUnreadBondsCount,
                followedUnreadBondsCountKey,
                payload,
            ]);
        },
        clearDeltaKnowledgeBond: (
            state,
            { payload: { bondId } }: PayloadAction<{ bondId: d.BondId; }>,
        ) => {
            const currentState = current(state).sidebarSummaryInfo;
            const { setByKey } = hamts.sidebarSummaryInfo;

            const { deltaKnowledge: _, ...currentInfo } = hamtGet(currentState, bondId) ?? {};
            const newInfo = deepFreeze({
                ...currentInfo,
                status: SummaryRequestStatus.Unspecified,
            });
            state.sidebarSummaryInfo = setByKey(currentState, bondId, newInfo);
        },
        updateBondSidebarSummarySavedPublishedSeqNum: (
            state,
            { payload: { bondId, savedPublishedSeqNum } }: PayloadAction<
                { bondId: d.BondId; savedPublishedSeqNum: number | undefined; }
            >,
        ) => {
            const currentState = current(state).sidebarSummaryInfo;
            const { setByKey } = hamts.sidebarSummaryInfo;

            const currentInfo = hamtGet(currentState, bondId) ?? {};
            const newInfo = deepFreeze({ ...currentInfo, savedPublishedSeqNum });
            state.sidebarSummaryInfo = setByKey(currentState, bondId, newInfo);
        },
        updateDeltaKnowledgeBond: (
            state,
            { payload: { bondId, deltaKnowledge } }: PayloadAction<
                { bondId: d.BondId; deltaKnowledge: DeltaKnowledgeBond; }
            >,
        ) => {
            const currentState = current(state).sidebarSummaryInfo;
            const { setByKey } = hamts.sidebarSummaryInfo;

            const currentInfo = hamtGet(currentState, bondId) ?? {};
            const newInfo = deepFreeze({ ...currentInfo, deltaKnowledge });
            state.sidebarSummaryInfo = setByKey(currentState, bondId, newInfo);
        },
    },

    extraReducers: builder => {
        builder.addCase(createBondFromMessageThunk.fulfilled, (state, { payload: bo }) => {
            state.bonds = bondsAdapter.upsertOne(state.bonds, bo);
            state.channelIndex = hamts.channelIndex.set(current(state).channelIndex, bo);

            state.updates = [[stores.bonds, bo.id, bo]];
        });
        // Add call ID to bond when going live
        builder.addCase(startCall.fulfilled, (state, action) => {
            const { bondId } = action.meta.arg;
            const callId = action.payload;
            if (!callId) {
                return;
            }

            const bo = state.bonds.entities[bondId];
            if (bo) {
                const updated = {
                    ...bo,
                    liveCallIds: [...bo.liveCallIds, callId],
                };

                state.bonds = bondsAdapter.upsertOne(state.bonds, updated);
                state.liveCallIndex[callId] = bo.id;
            }
        });
        builder.addCase(resetStore, _state => {
            return getInitialState();
        });
        builder.addCase(updateConnectionStatus, (state, action) => {
            if (action.payload !== ConnectionStatus.Connected) {
                state.observers = hamts.observers.initial();
            }
        });
        builder.addCase(
            fetchDeltaKnowledgeBondThunk.fulfilled,
            (state, { payload: deltaKnowledge }) => {
                const { bondId } = deltaKnowledge;

                const currentState = current(state).sidebarSummaryInfo;
                const { setByKey } = hamts.sidebarSummaryInfo;

                const currentInfo = hamtGet(currentState, bondId) ?? {};
                const newInfo = deepFreeze({
                    ...currentInfo,
                    deltaKnowledge,
                    status: SummaryRequestStatus.Done,
                });
                state.sidebarSummaryInfo = setByKey(currentState, bondId, newInfo);
            },
        );
        builder.addCase(
            fetchDeltaKnowledgeBondThunk.pending,
            (state, { meta: { arg: { bondId } } }) => {
                const currentState = current(state).sidebarSummaryInfo;
                const { setByKey } = hamts.sidebarSummaryInfo;

                const currentInfo = hamtGet(currentState, bondId) ?? {};
                const newInfo = deepFreeze({
                    ...currentInfo,
                    status: SummaryRequestStatus.ProcessingRequest,
                });
                state.sidebarSummaryInfo = setByKey(currentState, bondId, newInfo);
            },
        );
        builder.addCase(
            fetchDeltaKnowledgeBondThunk.rejected,
            (state, { meta: { arg: { bondId } } }) => {
                const currentState = current(state).sidebarSummaryInfo;
                const { setByKey } = hamts.sidebarSummaryInfo;

                const currentInfo = hamtGet(currentState, bondId) ?? {};
                const newInfo = deepFreeze({
                    ...currentInfo,
                    status: SummaryRequestStatus.Error,
                });
                state.sidebarSummaryInfo = setByKey(currentState, bondId, newInfo);
            },
        );
    },
});

const sliceSelectors = bondsSlice.getSelectors<RootState>(state => state.bonds);

export const {
    selectedBond: selectSelectedBondId,
    cardsViewScrollTop: selectCardsViewScrollTop,
    discoverViewScrollTop: selectDiscoverViewScrollTop,
    channel: selectBondIdByChannelId,
    liveCall: selectBondIdByLiveCallId,
    followedUnreadBondsCount: selectFollowedUnreadBondsCount,
    catchupKnowledge: selectCatchupKnowledge,
    archivedBonds: selectArchivedBondsSet,
} = sliceSelectors;

export const {
    clearSelected: clearSelectedBond,
    streamedBondObservers,
    streamedManyBondObservers,
    streamedMany: streamedBonds,
    streamedManyHeralds: streamedBondHeralds,
    streamedArchivedBonds,
    streamedPersonalBondInvites,
    updateSelected: updateSelectedBond,
    removeMany: removeBonds,
    removeManyHeralds: removeBondHeralds,
    setCardsViewScrollTop,
    setDiscoverViewScrollTop,
    upsertCatchupSummaries,
    deleteCatchupSummaries,
    streamedBondContemporaries,
    streamedFollowedUnreadBondsCount,
    updateBondSidebarSummarySavedPublishedSeqNum,
    clearDeltaKnowledgeBond,
    updateDeltaKnowledgeBond,
} = bondsSlice.actions;

const bondsSelectors = bondsAdapter.getSelectors<RootState>(
    state => state.bonds.bonds,
);

export const startBondCatchupSummariesStream = (
    appendListener: AppAppendListener = appAppendListener,
) => startManagingInterest(
    selectInterestedAndFollowedHeraldedIds,
    subBondCatchupSummariesThunk,
    appendListener,
);

const heraldSelectors = heraldsAdapter.getSelectors<RootState>(
    state => state.bonds.heralds,
);
export const selectBondHeralds = heraldSelectors.selectAll;
export const selectBondHeraldsIds = heraldSelectors.selectIds;

export const selectHeraldsInSquad = createAppSelector(
    [
        selectBondHeralds,
        (_: RootState, squadId: Optional<d.SquadId>) => squadId,
    ],
    (heralds, squadId) => squadId && heralds.filter(h => h.squadIds.includes(squadId)),
    memoizeOptions.weakMapShallow,
);

export const selectFollowedHeraldIds = createAppSelector(
    [selectBondHeralds],
    heralds => heralds.filter(h => h.currentUserIsFollower).map(h => h.bondId),
    memoizeOptions.weakMapShallow,
);

export const selectInterestedAndFollowedHeraldedIds = createAppSelector(
    [
        selectBondInterest,
        selectFollowedHeraldIds,
    ],
    union,
    memoizeOptions.weakMapShallow,
);

export const selectBonds = bondsSelectors.selectAll;
export const selectBondEntities = partialRecord(bondsSelectors.selectEntities);
export const selectBondIds = bondsSelectors.selectIds;

export const selectBondById = optionalFriendly(bondsSelectors.selectById);

export const selectBondByDraftTarget = (
    state: RootState,
    dt: DraftTarget,
): Optional<BondOverview> => {
    if (dt.type !== DraftType.Channel) return;
    const bondId = selectBondIdByChannelId(state, dt.channelId);
    return selectBondById(state, bondId);
};

export const selectSelectedBond = (state: RootState): Optional<BondOverview> => {
    const id = sliceSelectors.selectedBond(state);
    if (!id) {
        return undefined;
    }
    return selectBondById(state, id);
};

export const selectIsBondLive = (state: RootState, id: Optional<d.BondId>): Optional<boolean> => {
    const bond = selectBondById(state, id);
    return bond && (bond.liveCallIds?.length ?? 0) > 0;
};

export const selectIsBondOneOnOne = (
    state: RootState,
    id: Optional<d.BondId>,
): Optional<boolean> => {
    const bond = selectBondById(state, id);
    if (!bond) return;
    const bondHasNoSquads = (bond?.squadIds || []).length == 0;
    const bondHasAtMostTwoFollowers = (bond?.followers || []).length <= 2;
    return bondHasNoSquads && bondHasAtMostTwoFollowers;
};

export const selectBondFollowerIds = createAppSelector(
    [selectBondById],
    bo => bo?.followers ?? [],
    memoizeOptions.weakMapShallow,
);

const followerAdjustments = { sort: userSortByPresence };
const selectSortedFollowers = (state: RootState, id: Optional<d.BondId>) => {
    const followers = selectBondFollowerIds(state, id);
    return selectUsers(state, followers, followerAdjustments);
};

export const selectSortedFollowerIdsPair = createSelectorPair( // unused?
    selectBondFollowerIds,
    createAppSelector(
        [selectSortedFollowers],
        uos => uos.map(uo => uo.id),
        memoizeOptions.weakMapShallow,
    ),
);

export const selectBondInvitedEmails = createAppSelector(
    [selectBondById],
    bo => bo?.invitedEmails ?? [],
    memoizeOptions.weakMapShallow,
);

export const selectBondInvitedPersons = createAppSelector(
    [selectBondById],
    bo => bo?.invitedPersonIds ?? [],
    memoizeOptions.weakMapShallow,
);

export const selectBondContemporaries = createAppSelector(
    [sliceSelectors.contemporaries],
    contemps => contemps?.toSet(),
);

export const selectChannelIdByBondId =
    (bondId: Optional<d.BondId>) => (state: RootState): Optional<d.ChannelId> =>
        selectBondById(state, bondId)?.channelId;
export const selectLiveCallIdByBondId = createAppSelector(
    [selectBondById],
    bo => bo?.liveCallIds?.[0],
);
export const selectOrgIdByBondId =
    (id: Optional<d.BondId>) => (state: RootState): Optional<d.OrgId> =>
        selectBondById(state, id)?.orgId;

export const selectBondIsArchived = createAppSelector(
    [sliceSelectors.archivedBonds, (_: RootState, id: Optional<d.BondId>) => id],
    (set, id) => !!(id && hamtGet(set, id)),
    memoizeOptions.weakMap,
);

export const selectPersonalBondInvites = createAppSelector(
    [sliceSelectors.personalBondInvites],
    invites => Array.from(hamtValues(invites)),
    memoizeOptions.weakMapShallow,
);

export const selectBondIsContemporary = createAppSelector(
    [
        selectBondContemporaries,
        selectCurrentUserId,
    ],
    (set, userId) => !!(userId && set && set.has(userId)),
    memoizeOptions.weakMap,
);

export const selectMostRecentBondId = (state: RootState): Optional<d.BondId> =>
    bondsSelectors.selectIds(state)[0];

export const selectSquadIdsFromBondId = shallowArrayEqualSelectorCreator(
    [selectBondById],
    bo => bo?.squadIds ?? [],
);

export const selectValidSquadIdsForExistingBondAudience: (
    state: RootState,
    dt: DraftTarget,
    id: d.BondId,
) => d.SquadId[] = createAppSelector(
    [
        (rs, dt: DraftTarget, _) => selectValidSquadIdsForMention(rs, dt),
        (rs, _, id: d.BondId) => selectSquadIdsFromBondId(rs, id),
    ],
    (ids, squads) => ids.filter(id => !squads.includes(id)),
    memoizeOptions.weakMapShallow,
);

export const selectBondIsFollower = createAppSelector(
    [(state, _) => selectCurrentUserId(state), selectBondById],
    (user, bo) => !!(user && bo?.followers?.includes(user)),
    memoizeOptions.weakMap,
);

export const selectBondIsPrivate = (
    state: RootState,
    id: Optional<d.BondId>,
): Optional<boolean> => {
    const bond = selectBondById(state, id);
    return bond && bond.privacy === "private";
};

export const selectPrivateBondmates = createAppSelector(
    [selectBonds],
    overviews =>
        new Set(
            overviews
                .filter(bo => bo.privacy === "private")
                .flatMap(bo => [bo.contributors, bo.followers])
                .flat(),
        ),
);

export const selectBondObserverIds = createAppSelector(
    [sliceSelectors.observers],
    obs => obs?.map(o => o.userId) ?? [],
    memoizeOptions.weakMapShallow,
);

const selectBondObserverSet = createAppSelector(
    [selectBondObserverIds],
    observerIds => new Set(observerIds),
    memoizeOptions.weakMapShallow,
);

const selectBondUserIdsWithMentions = (state: RootState, bondId: Optional<d.BondId>) => {
    const bo = selectBondById(state, bondId);
    return selectUserIdSetWithMentions(state, bo?.channelId);
};

const selectBondUserIdsWithUnreads = (state: RootState, bondId: Optional<d.BondId>) => {
    const bo = selectBondById(state, bondId);
    return selectUserIdSetWithUnread(state, bo?.channelId);
};

export interface LiveActivityArgs {
    videoUserSet?: Set<d.UserId>;
    liveUserSet?: Set<d.UserId>;
    orderCurrentUserFirst?: boolean;
}
const selectLiveActivityArgs = (
    _state: RootState,
    _id: Optional<d.BondId>,
    liveArgs?: LiveActivityArgs,
) => liveArgs;

const selectBondActivityAdjustments = createAppSelector(
    [
        selectBondObserverSet,
        selectBondUserIdsWithMentions,
        selectBondUserIdsWithUnreads,
        selectCurrentUserId,
        selectLiveActivityArgs,
    ],
    (observerUserSet, mentionUserSet, unreadUserSet, currentUserId, liveArgs) => ({
        sort: userSortByBondLiveActivity(
            {
                observerUserSet,
                mentionUserSet,
                unreadUserSet,
                videoUserSet: liveArgs?.videoUserSet,
                liveUserSet: liveArgs?.liveUserSet,
            },
            { currentUserId, orderCurrentUserFirst: liveArgs?.orderCurrentUserFirst },
        ),
    }),
    memoizeOptions.weakMap,
);

/**
 * Select the ID of the bond to which the current call belongs.
 */
export const selectCurrentLiveBondId = (state: RootState) => {
    const currentCallId = selectCurrentCallId(state);
    return selectBondIdByLiveCallId(state, currentCallId);
};

const selectBondLiveCall = (state: RootState, bondId: Optional<d.BondId>) => {
    const liveCallId = selectLiveCallIdByBondId(state, bondId);
    return selectCallById(state, liveCallId);
};

export const selectBondLiveParticipantIds = createAppSelector(
    [selectBondLiveCall],
    co => co?.participants.filter(p => !isNullRole(p.role)).map(p => p.userId),
    memoizeOptions.weakMapShallow,
);

const selectSortedBondParticipants = (
    state: RootState,
    id: Optional<d.BondId>,
    participants: Optional<d.UserId[]>,
    liveArgs?: LiveActivityArgs,
) => {
    const adjustments = selectBondActivityAdjustments(state, id, liveArgs);
    return selectUsers(state, participants, adjustments);
};

export const selectSortedBondParticipantIdsPair = createSelectorPair(
    (
        _state: RootState,
        _id: Optional<d.BondId>,
        participants: Optional<d.UserId[]>,
        _liveArgs?: LiveActivityArgs,
    ) => participants,
    createAppSelector(
        [selectSortedBondParticipants],
        uos => uos.map(uo => uo.id),
        memoizeOptions.weakMapShallow,
    ),
);

const selectSortedBondLiveParticipants = (state: RootState, id: Optional<d.BondId>) => {
    const participants = selectBondLiveParticipantIds(state, id);
    return selectSortedBondParticipants(state, id, participants);
};

export const selectSortedBondLiveParticipantIdsPair = createSelectorPair(
    selectBondLiveParticipantIds,
    createAppSelector(
        [selectSortedBondLiveParticipants],
        uos => uos.map(uo => uo.id),
        memoizeOptions.weakMapShallow,
    ),
);

const selectSortedBondObservers = (state: RootState, id: Optional<d.BondId>) => {
    const observerIds = selectBondObserverIds(state, id);
    return selectSortedBondParticipants(state, id, observerIds);
};

export const selectSortedBondObserverIdsPair = createSelectorPair(
    selectBondObserverIds,
    createAppSelector(
        [selectSortedBondObservers],
        uos => uos.map(uo => uo.id),
        memoizeOptions.weakMapShallow,
    ),
);

const bondDirectInvolvees = createAppSelector(
    [selectBondById],
    bo => union(bo?.contributors, bo?.followers),
    memoizeOptions.weakMapShallow,
);

const allBondInvolvees = createAppSelector(
    [
        (state: RootState, id: d.BondId, _squadIds: d.SquadId[]) => bondDirectInvolvees(state, id),
        (state: RootState, _: d.BondId, squadIds: d.SquadId[]) =>
            selectUserIdsBySquadIds(state, squadIds),
    ],
    (direct, indirect) => union(direct, indirect),
    memoizeOptions.weakMapShallow,
);

export const selectAllBondInvolvees = (state: RootState, id: Optional<d.BondId>) => {
    if (!id) return [];

    const squads = selectSquadIdsFromBondId(state, id);
    return allBondInvolvees(state, id, squads);
};

export const emojiRegex =
    /^(?:\p{Extended_Pictographic}|\p{Emoji_Component})+(?:\p{Emoji_Modifier}|\p{Emoji_Modifier_Base}|\p{Emoji_Presentation}|\u200d)*/u;

export const selectBondTitle = createAppSelector(
    [selectBondById],
    (bo: Optional<BondOverview>) => {
        const liveTitle = bo?.knowledge.userSpecifiedTitle || bo?.knowledge.aiGeneratedTitle || "";
        const aiGenerated = bo?.knowledge.userSpecifiedTitle === "";

        // split emoji from title ONLY if it's AI generated
        let emoji = "";
        if (aiGenerated) {
            emoji = liveTitle.match(emojiRegex)?.[0] || "";
        }
        return {
            title: liveTitle.replace(emoji, "").trim(),
            emoji: emoji,
            aiGenerated: aiGenerated,
        };
    },
    memoizeOptions.weakMap,
);

export const selectAllContentBondSummary = createAppSelector(
    [selectBondById],
    (bo: Optional<BondOverview>) => {
        if (!bo) return "";
        return bo.knowledge.summary;
    },
);

export const selectDetailedBondSummary = createAppSelector(
    [selectBondById],
    (bo: Optional<BondOverview>) => {
        if (!bo) return;

        return bo.knowledge.detailedSummary || bo.knowledge.summary;
    },
);

export const selectDeltaKnowledgeBond = createAppSelector(
    [sliceSelectors.sidebarSummaryInfo],
    info => info?.deltaKnowledge,
);

export const selectBondSummaryRequestStatus = createAppSelector(
    [sliceSelectors.sidebarSummaryInfo],
    info => info?.status,
);

export const selectBondSidebarSummarySavedPublishedSeqNum = createAppSelector(
    [sliceSelectors.sidebarSummaryInfo],
    info => info?.savedPublishedSeqNum,
);

export const selectBondImageUrl = createAppSelector(
    [selectBondById],
    (bo: Optional<BondOverview>) => {
        const noImage = "/assets/kakapo.webp";
        if (!bo) {
            return noImage;
        }
        return bo.knowledge.imageUrl || noImage;
    },
);

export const reducer = bondsSlice.reducer;

// Persistence.

const stores = {
    bonds: idbStore<d.BondId, BondOverview>("bonds", 1),
    heralds: idbStore<d.BondId, BondHerald>("heralds", 21),
    catchupKnowledge: idbStore<d.BondId, CatchupKnowledge>("catchup-knowledge", 1),
    _archivedBonds1: idbStore<d.BondId, true>("archived-bonds", 1, 30),
    archivedBonds: idbStore<d.BondId, d.BondId>("archived-bonds-2", 31),
    personalBondInvites: idbStore<string, BondInvite>("personal-bond-invites", 28),
    followedUnreadBondsCount: idbStore<string, number>("followed-unread-bonds-count", 25),
};

type BondUpdate =
    | IdbStoreUpdate<typeof stores.bonds>
    | IdbStoreUpdate<typeof stores.heralds>
    | IdbStoreUpdate<typeof stores.catchupKnowledge>
    | IdbStoreUpdateWithClear<typeof stores.archivedBonds>
    | IdbStoreUpdateWithClear<typeof stores.personalBondInvites>
    | IdbStoreUpdate<typeof stores.followedUnreadBondsCount>;

const persist = (previousState: BondState, currentState: BondState): PersistenceUpdate[] => {
    if (previousState.updates == currentState.updates) {
        return [];
    }

    return currentState.updates;
};

const hydrate = async ({ idb: conn }: HydrateArgs) => {
    if (!conn) return getInitialState();

    const [
        bonds,
        heralds,
        catchupKnowledge,
        archivedBonds,
        followedUnreadBondsCountTable,
        personalBondInvites,
    ] = await Promise.all([
        fetchKeyedIdbStore(conn, stores.bonds),
        fetchKeyedIdbStore(conn, stores.heralds),
        fetchKeyedIdbStore(conn, stores.catchupKnowledge),
        fetchKeyedIdbStore(conn, stores.archivedBonds),
        fetchIdbStore(conn, stores.followedUnreadBondsCount),
        fetchKeyedIdbStore(conn, stores.personalBondInvites),
    ]);

    const followedUnreadBondsCount = followedUnreadBondsCountTable[followedUnreadBondsCountKey];

    return getInitialState({
        bonds: makeKeyedObjectFromArray(bonds, bo => bo.id),
        heralds: makeKeyedObjectFromArray(heralds, ho => ho.bondId),
        catchupKnowledge,
        archivedBonds,
        followedUnreadBondsCount,
        personalBondInvites,
    });
};

const migrate = async (tx: RWTransaction, oldVersion: number) => {
    if (oldVersion < 31) {
        await simpleIdbTranslate(
            tx,
            stores._archivedBonds1,
            stores.archivedBonds,
            ([k, _]) => [k, k],
        );
    }
};

export const persistor = {
    stores,
    persist,
    hydrate,
    migrate,
};
checkPersistor<BondState>(persistor);
