import { AudienceMember } from "@/domain/audience";
import { Mention, MentionTarget, parseMentionTarget } from "@/domain/mentions";
import { MentionNode } from "@/misc/tiptap/MentionNode";
import { andThen } from "@/misc/utils";
import { Paragraph } from "@tiptap/extension-paragraph";
import { Fragment, Node } from "@tiptap/pm/model";
import { AllSelection } from "@tiptap/pm/state";
import { Step, Transform } from "@tiptap/pm/transform";
import isEqual from "lodash.isequal";
import { MentionDiff } from "../types";
import { currentSchemaVersion, schemas, SchemaVersion } from "./schema";

export { Node, Transform };

// region Types

export type InternalNode = { ver: SchemaVersion; root: Node; };
export type JSONNode = { ver: SchemaVersion; json: any; };
export type AnyNode = InternalNode | JSONNode;

export type InternalTransform = { ver: SchemaVersion; transform: Transform; };
export type JSONTransform = { ver: SchemaVersion; before: JSONNode; steps: any[]; };
export type AnyTransform = InternalTransform | JSONTransform;

// region Type guards

export const isAnyNode = (data: unknown): data is AnyNode =>
    typeof data == "object" &&
    data !== null &&
    "ver" in data &&
    typeof data.ver == "number" &&
    ("json" in data ||
        "root" in data && data.root instanceof Node);
export const isInternalNode = (node: AnyNode): node is InternalNode => "root" in node;
export const isJSONNode = (node: AnyNode): node is JSONNode => "json" in node;

export const isAnyTransform = (data: unknown): data is AnyTransform =>
    typeof data == "object" &&
    data !== null &&
    "ver" in data &&
    typeof data.ver == "number" &&
    ("transform" in data && data.transform instanceof Transform ||
        "before" in data &&
            isAnyNode(data.before) &&
            isJSONNode(data.before) &&
            "steps" in data &&
            Array.isArray(data.steps));
export const isInternalTransform = (transform: AnyTransform): transform is InternalTransform =>
    "transform" in transform;
export const isJSONTransform = (transform: AnyTransform): transform is JSONTransform =>
    "before" in transform;

// region Converters

const emptyInternalNode = (ver: SchemaVersion): InternalNode => {
    const root = schemas[ver].nodes?.["doc"].createAndFill();
    if (root === null) throw Error("Failed to create empty document");
    return { ver, root };
};

export const textInternalNode = (ver: SchemaVersion, text: string): InternalNode => {
    const schema = schemas[ver];
    const root = schema.nodes?.["doc"].createAndFill(
        null,
        schema.nodes?.["paragraph"].createAndFill(
            null,
            text == "" ?
                Fragment.empty :
                schema.text(text),
        ),
    );
    if (root === null) throw Error("Failed to create text document");
    return { ver, root };
};

export const asInternalNode = (
    ver: SchemaVersion,
    root: Node,
): InternalNode => ({ ver, root });

export const jsonNode = (input: AnyNode): JSONNode => {
    if (isInternalNode(input)) {
        return { ver: input.ver, json: input.root.toJSON() };
    }

    return input;
};

export const parseNode = (input: AnyNode): InternalNode => {
    if (isInternalNode(input)) {
        return input;
    }

    return { ver: input.ver, root: Node.fromJSON(schemas[input.ver], input.json) };
};

export const asInternalTransform = (
    ver: SchemaVersion,
    transform: Transform,
): InternalTransform => ({ ver, transform });

export const jsonTransform = (input: AnyTransform): JSONTransform => {
    if (isInternalTransform(input)) {
        return {
            ver: input.ver,
            before: jsonNode(asInternalNode(input.ver, input.transform.before)),
            steps: input.transform.steps.map(s => s.toJSON()),
        };
    }

    return input;
};

export const parseTransform = (input: AnyTransform): InternalTransform => {
    if (isInternalTransform(input)) {
        return input;
    }

    return {
        ver: input.ver,
        transform: input.steps.reduce<Transform>(
            (result, step) => result.step(Step.fromJSON(schemas[input.ver], step)),
            new Transform(parseNode(input.before).root),
        ),
    };
};

// region Builders

interface TransformBuilderBase<B extends TransformBuilderBase<any>> {
    spliceText: (
        text: string,
        from: number,
        to: number,
    ) => B;
    spliceMention: (
        mention: Mention,
        text: string,
        from: number,
        to: number,
    ) => B;
    replace: (newRoot: Fragment | InternalNode) => B;
    clear: () => B;
    trim: () => B;
    breakMentions: (target: AudienceMember) => B;
}

interface TransformBuilder extends TransformBuilderBase<TransformBuilder> {
    build: () => InternalTransform;
    buildJSON: () => JSONTransform;
}

interface LinkedTransformBuilder extends TransformBuilderBase<LinkedTransformBuilder> {
    apply: () => NodeBuilder;
    build: () => InternalNode;
    buildJSON: () => JSONNode;
}

interface NodeBuilder {
    build: () => InternalNode;
    buildJSON: () => JSONNode;
    beginTransform: () => LinkedTransformBuilder;
}

const transformBuilderBase = (
    from: AnyTransform | AnyNode,
): {
    transform: Transform;
    baseBuilder: TransformBuilderBase<any>;
} => {
    const schema = schemas[from.ver];
    const transform = isAnyNode(from) ?
        new Transform(parseNode(from).root) : // When `from` is a node, create a new transform based on it
        parseTransform(jsonTransform(from)).transform; // When `from` is a transform, copy it (parse via JSON)
    const baseBuilder: TransformBuilderBase<any> = {
        spliceText: (text, from, to) => {
            const fragment = (text == "") ?
                Fragment.empty :
                Fragment.from(schema.text(text));

            transform.replaceWith(
                transform.mapping.map(from),
                transform.mapping.map(to),
                fragment,
            );
            return baseBuilder;
        },
        spliceMention: (mention, text, from, to) => {
            const mappedFrom = transform.mapping.map(from);
            let mappedTo = transform.mapping.map(to);

            // Increase `to` by one when the next node is of type
            // "text" and starts with a space character
            const nodeAfter = transform.doc.resolve(mappedTo).nodeAfter;
            const overrideSpace = nodeAfter?.text?.startsWith(" ");
            if (overrideSpace) {
                mappedTo += 1;
            }

            transform.replaceWith(
                mappedFrom,
                mappedTo,
                Fragment.from([
                    schema.node("mention", { ...mention, text }),
                    schema.text(" "),
                ]),
            );
            return baseBuilder;
        },
        replace: newRoot => {
            const content = isAnyNode(newRoot) ? newRoot.root : newRoot;
            const selection = new AllSelection(transform.doc);
            transform.replaceWith(selection.from, selection.to, content);
            return baseBuilder;
        },
        clear: () => baseBuilder.replace(Fragment.empty),
        trim: () => {
            const size = transform.doc.content.size;

            // Iterate through the first contiguous block of text nodes, trimming each from
            // the start and continuing if the node was completely emptied. Stop when we
            // find a node than still has content after trimming, and store the trim position
            let trimStart = 0, firstParagraphSeen = false;
            iterate(transform.doc, (node, pos) => {
                // Ignore the first paragraph node
                if (!firstParagraphSeen && node.type.name == Paragraph.name) {
                    firstParagraphSeen = true;
                    return true;
                }
                // Break on the first non-text node
                if (!node.isText) return false;
                const lengthBefore = node.textContent.length;
                const lengthAfter = node.textContent.trimStart().length;
                trimStart = pos + lengthBefore - lengthAfter;
                return lengthAfter == 0;
            });

            // Similar to above, but in reverse from the end of the doc
            let trimEnd = size, lastParagraphSeen = false;
            iterate(transform.doc, (node, pos) => {
                // Ignore the last paragraph node
                if (!lastParagraphSeen && node.type.name == Paragraph.name) {
                    lastParagraphSeen = true;
                    return true;
                }
                // Break on the first non-text node
                if (!node.isText) return false;
                const lengthAfter = node.textContent.trimEnd().length;
                trimEnd = pos + lengthAfter;
                return lengthAfter == 0;
            }, true);

            // Replace the entire document with the slice from the trim start to trim end
            transform.replace(0, size, transform.doc.slice(trimStart, trimEnd, true));
            return baseBuilder;
        },
        breakMentions: target => {
            transform.doc.descendants((node, pos) => {
                if (node.type.name != MentionNode.name) return true;
                if (node.attrs.target != target) return true;
                transform.replaceWith(
                    pos,
                    pos + node.nodeSize,
                    schema.text(node.attrs.text.slice(1)),
                );
                return true;
            });
            return baseBuilder;
        },
    };
    return {
        transform,
        baseBuilder,
    };
};

export const transformBuilder = (from: AnyTransform | AnyNode): TransformBuilder => {
    const { transform, baseBuilder } = transformBuilderBase(from);
    return Object.assign(baseBuilder, {
        build: () => asInternalTransform(from.ver, transform),
        buildJSON: () => jsonTransform((baseBuilder as TransformBuilder).build()),
    });
};

const linkedTransformBuilder = (root: InternalNode): LinkedTransformBuilder => {
    const { transform, baseBuilder } = transformBuilderBase(root);
    return Object.assign(baseBuilder, {
        apply: () => nodeBuilderInternal(asInternalNode(root.ver, transform.doc)),
        build: () => (baseBuilder as LinkedTransformBuilder).apply().build(),
        buildJSON: () => (baseBuilder as LinkedTransformBuilder).apply().buildJSON(),
    });
};

const nodeBuilderInternal = (root: InternalNode): NodeBuilder => ({
    build: () => root,
    buildJSON: () => jsonNode(root),
    beginTransform: () => linkedTransformBuilder(root),
});

export const nodeBuilder = (ver: SchemaVersion = currentSchemaVersion): NodeBuilder =>
    nodeBuilderInternal(emptyInternalNode(ver));

// region Operations

/**
 * Recursively iterate over a node's children. Similar to `node.descendants` but:
 *  1. allows iterating in reverse order
 *  2. returning false from `f` stops all iteration (`descendants` just ignores that node's children)
 */
const iterate = (
    node: Node,
    f: (node: Node, pos: number) => void | boolean,
    reversed: boolean = false,
    nodeStart: number = 0,
): boolean => {
    let childOffset = reversed ? node.content.size : 0;
    for (let i = 0; i < node.childCount; i++) {
        const child = node.child(reversed ? node.childCount - 1 - i : i);

        if (reversed) childOffset -= child.nodeSize;

        const childStart = nodeStart + childOffset;
        if (!f(child, childStart)) return false;
        if (child.childCount) {
            if (!iterate(child, f, reversed, childStart + 1)) return false;
        }

        if (!reversed) childOffset += child.nodeSize;
    }
    return true;
};

export const nodeIsEmpty = (root: AnyNode): boolean => {
    let empty = true;
    iterate(parseNode(root).root, node => {
        if (
            node.isLeaf ||
            node.isInline && node.nodeSize > 0
        ) {
            empty = false;
            return false;
        }
        return true;
    });
    return empty;
};

export const compareNodes = (a: AnyNode, b: AnyNode): boolean =>
    isJSONNode(a) && isJSONNode(b) && isEqual(a, b) ||
    !!andThen(andThen(b, parseNode), b => parseNode(a).root.eq(b.root));

export const getTransformResult = (transform: AnyTransform): InternalNode => {
    const internalTransform = parseTransform(transform);
    return asInternalNode(internalTransform.ver, internalTransform.transform.doc);
};

export const renderNodeToText = (root: AnyNode, expandMentions: boolean): string => {
    let text = "";
    parseNode(root).root.descendants(node => {
        switch (node.type.name) {
            case "text":
                text += node.text;
                break;

            case MentionNode.name:
                text += expandMentions ? (node.attrs.text ?? "") : " ";
                break;
        }
        return true;
    });
    return text;
};

export const getMentions = (root: AnyNode): Mention[] => {
    const mentions: Mention[] = [];
    parseNode(root).root.descendants(node => {
        if (node.type.name == MentionNode.name) {
            const targetRaw = node.attrs.target;
            const target = parseMentionTarget(targetRaw);
            if (target !== undefined) mentions.push({ target });
        }
        return true;
    });
    return mentions;
};

const groupMentions = (root: AnyNode): Map<MentionTarget, Mention[]> => {
    const groups = new Map<MentionTarget, Mention[]>();
    getMentions(root).forEach(m => {
        let ms = groups.get(m.target);
        if (ms === undefined) {
            ms = [];
            groups.set(m.target, ms);
        }
        ms.push(m);
    });
    return groups;
};

/**
 * Return a list of mentions that were added between two markup deltas, along
 * with a list of mentions that were removed for the last time (i.e. there are
 * no remaining mentions with the same target)
 *
 * To avoid having to calculate a full diff between the two nodes, this algorithm
 * just works on the counts of mentions before and after. As a result, it may fail
 * to recognise when a mention for a given target is both added and removed in the
 * same step. This is not an issue now because 1) mentions are fungible as they
 * currently only contain the target and 2) most changes will only add or remove
 * a single mention anyway
 */
export const mentionDiff = (oldNode: AnyNode, newNode: AnyNode): MentionDiff => {
    const oldMentions = groupMentions(oldNode);
    const newMentions = groupMentions(newNode);

    const addedMentions: Mention[] = [];
    const lastRemovedMentions: Mention[] = [];
    oldMentions.forEach((mentions, target) => {
        if (!newMentions.has(target)) {
            mentions.forEach(m => lastRemovedMentions.push(m));
        }
    });
    newMentions.forEach((mentions, target) => {
        if ((oldMentions.get(target)?.length ?? 0) < mentions.length) {
            mentions.forEach(m => addedMentions.push(m));
        }
    });

    return {
        addedMentions,
        lastRemovedMentions,
    };
};

export const firstInsertionPos = (root: AnyNode): number => {
    let result = 0;
    iterate(parseNode(root).root, (node, pos) => {
        if (node.isInline) {
            // The first insertion point is either just before the first
            // inline node we find...
            result = pos;
            return false;
        }
        if (node.inlineContent && node.content.size == 0) {
            // ...or inside the first empty inline container
            result = pos + 1;
            return false;
        }
        return true;
    });
    return result;
};
