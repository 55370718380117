import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Extension } from "@tiptap/react";
import { Optional } from "../types";
interface TabIndexExtensionOptions {
    tabIndex: Optional<number>;
}

type HTMLAttributes = { [name: string]: string; };

export const TabIndexExtension = Extension.create<TabIndexExtensionOptions>({
    name: "tabIndexExtension",

    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey(this.name),
                props: {
                    attributes: (): HTMLAttributes =>
                        (this.editor.isEditable && this.options.tabIndex !== undefined) ?
                            { tabindex: this.options.tabIndex.toString() } :
                            {},
                },
            }),
        ];
    },
});
