import { useCallback, useEffect, useRef, useState } from "react";

import { associateUserWithAuthRequest, generatePasscode, getInviteByCode } from "@/api/auth";
import {
    beginAuthenticateWithAuthProvider,
    getLoginRedirectUri,
    relativeUrlForRedirectResponse,
} from "@/auth/login";
import { loadCodeVerifier } from "@/auth/storage";
import { isMobileBrowser } from "@/misc/mobile";
import { getOidcConfig } from "@/misc/oidcConfig";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import usePrevious from "../hooks/usePrevious";
import {
    getNativeTarget,
    isNativePlatform,
    nativePlatformTargetKey,
    setNativeTailscaleHost,
} from "../misc/capacitor";
import { getEnvironmentConfig, isDevEnv, nativeTargets } from "../misc/environment";
import log from "../misc/log";
import { LocationState } from "./state";
import useRefreshOnLogin from "./useRefreshOnLogin";

// TODO: find some way to hide the native target switcher by default

// NativeTargetSwitcher renders only if the frontend is running natively using
// Capacitor, i.e. using our mobile client.
// It allows users to switch between bo-nd.dev and bondtest.uk.
function NativeTargetSwitcher(): React.JSX.Element {
    const initialTarget = getNativeTarget();
    const [nativeTarget, setNativeTarget] = useState(initialTarget ?? nativeTargets.bondDev);
    const previousNativeTarget = usePrevious(nativeTarget);

    const toggleNativeTarget = useCallback(() => {
        setNativeTarget(currentTarget => {
            switch (currentTarget) {
                case nativeTargets.bondDev:
                    return nativeTargets.bondtestUk;
                case nativeTargets.bondtestUk:
                    return nativeTargets.tailscale;
                case nativeTargets.tailscale:
                    return nativeTargets.bondDev;
                default:
                    return nativeTargets.bondDev;
            }
        });
    }, []);

    useEffect(() => {
        if (previousNativeTarget && previousNativeTarget !== nativeTarget) {
            localStorage.setItem(nativePlatformTargetKey, nativeTarget!);

            // Refresh the page to cause the auth worker
            // to be recreated with the new OIDC authority.
            log.info("Reloading page due to the native target changing");
            window.location.reload();
        }
    }, [nativeTarget, previousNativeTarget]);

    const [tailscaleHost, setTailscaleHost] = useState(
        "YOUR_HOST_HERE.taildee6.ts.net",
    );

    useEffect(() => {
        setNativeTailscaleHost(tailscaleHost);
    }, [tailscaleHost]);

    return (
        <>
            <div className="c-signin__target">
                {nativeTarget ? nativeTarget : "Current target"}
            </div>
            <div className="cp-form-element">
                <button
                    aria-label="Switch target"
                    className="c-btn-main c-btn-main--centred c-btn-main--secondary"
                    onClick={toggleNativeTarget}
                >
                    Switch target
                </button>
            </div>
            {nativeTarget === nativeTargets.tailscale && (
                <div className="c-signin__target">
                    <input
                        className="c-signin__target"
                        value={tailscaleHost}
                        onChange={e => setTailscaleHost(e.target.value)}
                    />
                </div>
            )}
        </>
    );
}

async function beginSignIn() {
    const loginRedirectUri = getLoginRedirectUri();
    const [redirectUri, codeVerifier] = await beginAuthenticateWithAuthProvider(
        await getOidcConfig(loginRedirectUri),
    );

    const response = await fetch(redirectUri, {
        headers: {
            "X-Avos-302-To-200": "true",
        },
    });

    const [relativeUrl, _] = relativeUrlForRedirectResponse(response);

    log.info(`Login flow: next sign-in step ${relativeUrl}`);
    return { codeVerifier, loginRedirectUri, relativeUrl };
}

const invitePathPattern = /invite\/([^/]+\/?)$/;

const getInviteCode = (url?: string) => {
    // Get the invite code from the URL e.g. 'http://localhost:8080/invite/1234' -> '1234'
    return url?.match(invitePathPattern)?.[1];
};

async function getInvitedEmail() {
    try {
        // Start the sign-in flow to get the invited email via API
        const { codeVerifier, loginRedirectUri, relativeUrl } = await beginSignIn();
        const authRequestId = extractAuthRequestId(relativeUrl);

        const inviteCode = getInviteCode(loginRedirectUri);
        if (!inviteCode) {
            return;
        }

        log.info("Invite code found in redirect URL. Fetching invited email...");

        const response = await getInviteByCode(inviteCode, { authRequestId, codeVerifier });
        if (!response.isSuccess) {
            log.error(`Failed to get invited email: ${response.reason}`);
            return;
        }

        return response.response.email;
    }
    catch (e) {
        log.error(`Failed to get invited email`, e);
    }
}

async function continueSignInGeneratePasscode(
    authReqId: string,
    userEmail: string,
    isTargetedInvite: boolean,
): Promise<[string, LocationState]> {
    const codeVerifier = await loadCodeVerifier();

    if (!authReqId || !codeVerifier) {
        throw new Error("No auth request ID or code verifier provided");
    }

    const response = await associateUserWithAuthRequest(authReqId, userEmail, codeVerifier);
    if (!response.isSuccess) {
        throw new Error(`Failed to associate user with auth request: ${response.reason}`);
    }

    const { isNewUser, userId } = response.response;

    const passcodeResp = await generatePasscode(userId, {
        authRequestId: authReqId,
        codeVerifier,
    });
    if (!passcodeResp.isSuccess) {
        throw new Error(`Failed to generate passcode: ${passcodeResp.reason}`);
    }

    const passcodeUrl = `/login/passcode?` +
        new URLSearchParams({ auth_request_id: authReqId }).toString();

    log.info(`Login flow: passcode URL ${passcodeUrl}`);

    return [passcodeUrl, {
        codeVerifier,
        isNewUser,
        isTargetedInvite,
        userId,
        userEmail,
    }];
}

function extractAuthRequestId(relativeUrl: string): string {
    const url = new URL(relativeUrl, "https://example.com");
    const id = url.searchParams.get("auth_request_id");
    if (!id) {
        throw new Error("No auth request ID found in redirect URI: " + relativeUrl);
    }
    return id;
}

function maybeValidEmail(email: string) {
    return z.string().email().safeParse(email).success;
}

function saveDevUser(email: string) {
    if (!isDevEnv) return;
    localStorage.setItem("x-beyond-temp-userid", email);
}

function loadDevUser(): string {
    if (!isDevEnv) return "";
    return localStorage.getItem("x-beyond-temp-userid") || "";
}

export default function LoginView(): React.JSX.Element {
    const navigate = useNavigate();
    const [isFailed, setIsFailed] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const [continueButtonEnabled, setContinueButtonEnabled] = useState(false);
    const [isSigningIn, setIsSigningIn] = useState(false);

    const invitedEmailRef = useRef<string | undefined>();

    const signIn = useCallback(async () => {
        try {
            if (!userEmail) {
                log.error("No email to sign in with");
                return;
            }

            setIsFailed(false);
            setIsSigningIn(true);

            const { relativeUrl } = await beginSignIn();
            const authReqId = extractAuthRequestId(relativeUrl);

            const [passcodeUrl, state] = await continueSignInGeneratePasscode(
                authReqId,
                userEmail,
                invitedEmailRef.current === userEmail,
            );

            saveDevUser(userEmail);

            navigate(passcodeUrl, {
                replace: true,
                state: state,
            });
        }
        catch (e) {
            log.error(`Sign-in failed`, e);
            setIsFailed(true);
        }
        finally {
            setIsSigningIn(false);
        }
    }, [navigate, setIsFailed, userEmail]);

    // Try to autofill invited email on initial load
    useEffect(() => {
        const redirUri = getLoginRedirectUri();

        if (!getInviteCode(redirUri)) {
            // Make login on `task dev` a bit more user friendly by having the
            // email field auto-populated at least in the case an invite isn't
            // being followed.
            setUserEmail(loadDevUser());
            return;
        }

        getInvitedEmail().then(invitedEmail => {
            invitedEmailRef.current = invitedEmail;

            // If the user has already entered an email, don't overwrite it
            setUserEmail(currentEmail => currentEmail || invitedEmail || loadDevUser());
        });
    }, []);

    useEffect(() => {
        setContinueButtonEnabled(maybeValidEmail(userEmail));
    }, [userEmail]);

    useRefreshOnLogin();

    const emailInputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (!continueButtonEnabled || isSigningIn) return;

        if (e.key === "Enter") {
            signIn();
        }
    };

    const devEnvPlaceholder = isDevEnv ? " (For dev add @example.com)" : "";
    const isMobile = isMobileBrowser();
    const sectionClasses = classNames("l-signin", {
        "l-signin--desktop": !isMobile,
    });
    const brandColumnClasses = classNames("l-signin__brand-column l-signin-bg", {
        "l-signin__brand-column--desktop l-signin-bg--desktop": !isMobile,
    });
    const columnClasses = classNames("l-signin__column", {
        "l-signin__column--desktop": !isMobile,
    });
    const brandClasses = classNames("c-logo-signin", {
        "c-logo-signin--desktop": !isMobile,
    });
    const taglineClasses = classNames("c-signin-tagline", {
        "c-signin-tagline--desktop": !isMobile,
    });
    const signinClasses = classNames("c-signin", {
        "c-signin--desktop": !isMobile,
    });
    const legendClasses = classNames("c-signin__legend", {
        "c-signin__legend--desktop": !isMobile,
    });

    const nativePlatform = isNativePlatform();
    const [showTargetSwitcherClicks, setShowTargetSwitcherClicks] = useState(0);
    const handleShowTargetSwitcherClick = () => {
        if (!isNativePlatform()) return;

        setShowTargetSwitcherClicks(x => x + 1);
    };

    return (
        <main className="l-main-signin">
            <section className={sectionClasses}>
                <div className={brandColumnClasses}>
                    <h1
                        className={brandClasses}
                        onClick={handleShowTargetSwitcherClick}
                    >
                        Bond
                    </h1>
                    <h2 className={taglineClasses}>
                        <span>Interact</span> with impact
                    </h2>
                </div>
                <div className={columnClasses}>
                    <fieldset className={signinClasses}>
                        <h2 className={legendClasses}>
                            Continue with email
                        </h2>
                        <div className="cp-form-element">
                            <input
                                type="email"
                                id="userEmail"
                                value={userEmail}
                                autoComplete="off"
                                maxLength={100}
                                className="cp-input"
                                placeholder=" "
                                onChange={e => setUserEmail(e.target.value)}
                                onKeyDown={emailInputOnKeyDown}
                            />
                            <label htmlFor="userEmail" className="cp-label">
                                {"Email address" + devEnvPlaceholder}
                            </label>
                        </div>
                        <div className="cp-form-element">
                            <button
                                type="submit"
                                aria-label="Continue"
                                className="c-btn-main"
                                onClick={signIn}
                                disabled={!continueButtonEnabled || isSigningIn}
                            >
                                Continue
                                <span className="c-btn-main__icon c-btn-main__icon--arrow-right">
                                </span>
                            </button>
                        </div>
                        {isFailed && (
                            <div className="c-signin__error">
                                Error signing in. Please try again.
                            </div>
                        )}
                        <div className="c-signin__meta">
                            By continuing, you agree to our{" "}
                            <a
                                href={`${getEnvironmentConfig().baseUrl}/privacy-policy`}
                                target="_blank"
                            >
                                privacy policy
                            </a>
                            <br />and <a href="#" target="_blank">terms of use</a>.
                        </div>
                        {nativePlatform && (showTargetSwitcherClicks >= 10) && (
                            <NativeTargetSwitcher />
                        )}
                    </fieldset>
                </div>
            </section>
        </main>
    );
}
