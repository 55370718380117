import base64 from "base64-js";
import { Interval } from "luxon";
import { ChangeEventHandler, Dispatch, FC, ReactNode, SetStateAction } from "react";

import { UserOverview } from "@/domain/users";
import { MutableRefList, NumberRange, Optional, SerializableSet } from "@/misc/types";
import { emailSchema } from "./zod";

export function getDurationStr(interval: Interval): string {
    const dur = interval.toDuration();
    if (dur.shiftTo("hours").hours < 1) {
        return dur.toFormat("mm:ss");
    }
    else {
        return dur.toFormat("h:mm:ss");
    }
}

export const formatList = (items: string[], limit?: number) => {
    if (items.length <= 1) {
        return items.join("");
    }

    let parts = items;

    // Add "n others" string if items goes beyond limit by more than 1
    if (limit && items.length > limit + 1) {
        const extraCount = items.length - limit;
        parts = items.slice(0, limit).concat(`${extraCount} others`);
    }

    const oxfordComma = parts.length > 2;

    return `${parts.slice(0, -1).join(", ")}${oxfordComma ? "," : ""} and ${parts.at(-1)}`;
};

export const formatNodeList = (items: ReactNode[], limit?: number): ReactNode => {
    if (items.length <= 1) {
        return items;
    }

    let parts = items;

    // Add "n others" string if items goes beyond limit by more than 1
    if (limit && items.length > limit + 1) {
        const extraCount = items.length - limit;
        parts = items.slice(0, limit).concat(`${extraCount} others`);
    }

    const oxfordComma = parts.length > 2;

    return [
        ...parts.slice(0, -1).joinArray(", "),
        ...(oxfordComma ? [", and "] : [" and "]),
        parts.at(-1),
    ];
};

// Convert `the user overviews into a comma-separated string listing the users' nicknames,
// truncating to a limit, and using "and" as the final separator.
export function getUserNamesString(users: UserOverview[], maxUsers?: number) {
    const names = users.map(u => u.nickname);
    return formatList(names, maxUsers);
}

// Return one of the text strings `was` or `were` depending on the number of user ids
export function getWasOrWereString(numberOfItems: number): string {
    return numberOfItems > 1 ? "were" : "was";
}

/** Returns the numbers 0..n-1
 */
export const range = (n: number): number[] => Array.from({ length: n }).map((_, i) => i);

// Runs `filter` over an array, returning arrays for elements matching true or false respectively.
// Works over discriminated unions, with returned types matching the real types of the values.
// (A bit sad we can only do 2 different types with this setup...)
export const separateDiscriminatedUnion = <T0, T1>(
    filter: (x: T0 | T1) => boolean,
    xs: (T0 | T1)[],
): [T0[], T1[]] => {
    const t: T0[] = [], f: T1[] = [];
    xs.forEach(x => {
        if (filter(x)) {
            t.push(x as T0);
        }
        else {
            f.push(x as T1);
        }
    });
    return [t, f];
};

// Runs `filter` over an array, returning arrays for elements matching true or false respectively.
export const separate = <T,>(filter: (x: T) => boolean, xs: T[]): [T[], T[]] =>
    separateDiscriminatedUnion(filter, xs);

/**
   Take the number `n` modulo `m`
*/
export const modulo = (n: number, m: number) => ((n % m) + m) % m;

export const clamp = (lower: number, upper: number) => {
    if (lower > upper) {
        throw new Error(`clamp must have lower <= upper: ${lower} > ${upper}`);
    }

    return (n: number) => {
        if (n >= upper) return upper;
        if (n <= lower) return lower;
        return n;
    };
};

export const replaceAll = (str: string, search: string, replacement: string) =>
    str.split(search).join(replacement);

export const objectKeyedBy = <K, V>(keys: K[], vals: (key: K) => V) =>
    Object.fromEntries(
        keys.map(key => [key, vals(key)]),
    );

export const toSerializableSet = <T extends keyof any>(vals: T[]): SerializableSet<T> =>
    objectKeyedBy(vals, () => true);

export const escapeForRegex = (s: string): string => {
    return s.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

export const isDefined = <T,>(t: Optional<T>): boolean => t !== undefined;
export const isDefinedAndNotNull = <T,>(t: Optional<T>): boolean => isDefined(t) && t !== null;

export function uuidv4(): `${string}-${string}-${string}-${string}-${string}` {
    if ("randomUUID" in window.crypto) {
        return window.crypto.randomUUID();
    }

    // Source: https://stackoverflow.com/questions/105034/how-do-i-create-a-guid-uuid
    return "10000000-1000-4000-8000-100000000000".replace(
        /[018]/g,
        c => (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16),
    ) as `${string}-${string}-${string}-${string}-${string}`;
}

export function randomHexString(): string {
    return uuidv4().replace(/-/g, "");
}

/**
 * Tool for partially applying functional React components. Given a component which takes some
 * props type T & U, and a T instance, this function will produce a component that takes only U.
 *
 * @deprecated
 */
export const partialComponent =
    <T, U>(Component: FC<T & U>, partialPropsT: T & React.Attributes): FC<U> =>
    (partialPropsU: U) => <Component {...partialPropsT} {...partialPropsU} />;

/**
 * Globally index a nested array, e.g.:
 *
 * input = [
 *   [a, b, c],
 *   [x, y],
 *   [],
 *   [i, j, k, l],
 * ]
 *
 * result = [
 *   [[0, a], [1, b], [2, c]]
 *   [[3, x], [4, y]],
 *   [],
 *   [[5, i], [6, j], [7, k], [8, l]],
 * ]
 */
type IndexedItem<T> = [number, T];
export const indexNestedArray = <T,>(tss: T[][]): IndexedItem<T>[][] =>
    tss.reduce<[number, IndexedItem<T>[][]]>(
        ([i, acc], ts) => {
            acc.push(ts.map((t, j) => [i + j, t]));
            return [i + ts.length, acc];
        },
        [0, []],
    )[1];

export const rangesIntersect = (a: NumberRange, b: NumberRange) =>
    a.start < b.end && b.start < a.end;

export const createRandomBase64String = (n: number = 9): string => {
    const array = new Uint8Array(n);
    return base64.fromByteArray(crypto.getRandomValues(array));
};

export const setRefs = <T,>(val: T, ...refs: MutableRefList<T>) =>
    refs.forEach(ref => {
        if (typeof ref === "function") {
            ref(val);
        }
        else if (ref != null) {
            ref.current = val;
        }
    });

// TODO: move somewhere?
export const setOnChange =
    (setter: Dispatch<SetStateAction<string>>): ChangeEventHandler<HTMLInputElement> => e =>
        setter(e.target.value);

export function isEmailAddress(text: string): boolean {
    return emailSchema.safeParse(text).success;
}

export const stringCompare = (a: string, b: string): number => (a > b) ? 1 : (b > a) ? -1 : 0;

export const optionalToList = <T,>(t: Optional<T>): T[] => t === undefined ? [] : [t];

/**
 * Implementation of map for optionals. If the first argument is undefined, undefined is
 * always returned. Otherwise, returns the result of applying `f` to the first argument
 */
export const andThen = <T, S>(t: Optional<T>, f: (t: T) => S): Optional<S> =>
    t === undefined ? undefined : f(t);

export const makeKeyedObjectFromArray = <K extends string, V>(
    vs: V[],
    keyFn: (v: V) => K,
): Record<K, V> =>
    vs.reduce((s, v) => {
        s[keyFn(v)] = v;
        return s;
    }, {} as Record<K, V>);
